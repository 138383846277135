import _ from "lodash";
import typeActions from "redux/typeActions";
import CONSTANT from "constant";
import {
  isAccountantStaff,
  isBusinessStaff,
  isCustomer,
  isManageStaff,
  isSampleCollectorStaff,
  returnValue,
  isDoctorHT,
  isDoctorKH,
} from "common";

const initialState = {
  isAdmin: true,
  isHasChildrent: false,
  isSampleCollectorStaff: true,
  isBusinessStaff: true,
  isManageStaff: true,
  isAccountantStaff: true,
  isCustomer: true,
  isDoctorHT: true,
  isDoctorKH: true,
  // loading
  isLogIn: false,
  isLogOut: false,
  isForgotPassword: false,
  isResetPassword: false,
  isGetAccounts: false,
  isGetCurrentAccount: false,
  isGetCurrentAccountExtend: false,
  isGetAccountById: false,
  isCreateAccount: false,
  isUpdateAccount: false,
  isDeleteAccount: false,
  isConfigPasswordAccount: false,
  isAccountChangePassword: false,
  //
  isGetAccountsExtend: false,
  isGetAccountExtend: false,
  isCreateAccountExtend: false,
  isUpdateAccountExtend: false,
  isDeleteAccountExtend: false,
  isUpdateCurrentAccount: false,
  // data
  accounts: CONSTANT.DATA,
  account: {},
  currentAccount: {},
  currentAccountExtend: {},
  childrentByCurrentAccountExtend: [],
  //
  accountsExtend: CONSTANT.DATA,
  accountExtend: {},
  // permissions
  permissions: [],
  // error
  errors: {
    logIn: "",
    logOut: "",
    forgotPassword: "",
    resetPassword: "",
    getAccounts: "",
    createAccount: "",
    updateAccount: "",
    deleteAccount: "",
    configPasswordAccount: "",
    getCurrentAccount: "",
    getCurrentAccountExtend: "",
    getAccountById: "",
    accountChangePassword: "",
    //
    getAccountsExtend: "",
    getAccountExtend: "",
    createAccountExtend: "",
    updateAccountExtend: "",
    deleteAccountExtend: "",
    updateCurrentAccount: "",
  },
  page: 1,
  limit: 10,
  query: {},
};

const accountReducer = (state = initialState, actions) => {
  switch (actions.type) {
    case typeActions.SET_ACCOUNT_VALUE:
      const { name, value } = actions;
      if (typeof name !== "string") return { ...state };
      const obj = returnValue(state, name, value);
      return {
        ...state,
        ...obj,
      };
    case typeActions.ACCOUNT_LOGIN_REQUEST:
      return {
        ...state,
        isLogIn: true,
        errors: {
          ...state.errors,
          logIn: "",
        },
      };
    case typeActions.ACCOUNT_LOGIN_SUCCESS:
      return {
        ...state,
        isLogIn: false,
        errors: {
          ...state.errors,
          logIn: actions.data,
        },
      };
    case typeActions.ACCOUNT_LOGIN_FAILED:
      return {
        ...state,
        isLogIn: false,
        errors: {
          ...state.errors,
          logIn: actions.error,
        },
      };

    case typeActions.ACCOUNT_LOGOUT_REQUEST:
      return {
        ...state,
        isLogOut: true,
        errors: {
          ...state.errors,
          logOut: "",
        },
      };
    case typeActions.ACCOUNT_LOGOUT_SUCCESS:
      return {
        ...state,
        isLogOut: false,
        errors: {
          ...state.errors,
          logOut: "",
        },
      };
    case typeActions.ACCOUNT_LOGOUT_FAILED:
      return {
        ...state,
        isLogOut: false,
        errors: {
          ...state.errors,
          logOut: actions.error,
        },
      };

    case typeActions.ACCOUNT_FORGOT_PASSWORD_REQUEST:
      return {
        ...state,
        isForgotPassword: true,
        errors: {
          ...state.errors,
          forgotPassword: "",
        },
      };
    case typeActions.ACCOUNT_FORGOT_PASSWORD_SUCCESS:
      return {
        ...state,
        isForgotPassword: false,
        errors: {
          ...state.errors,
          forgotPassword: "",
        },
      };
    case typeActions.ACCOUNT_FORGOT_PASSWORD_FAILED:
      return {
        ...state,
        isForgotPassword: false,
        errors: {
          ...state.errors,
          forgotPassword: actions.error,
        },
      };

    case typeActions.ACCOUNT_RESET_PASSWORD_REQUEST:
      return {
        ...state,
        isResetPassword: true,
        errors: {
          ...state.errors,
          resetPassword: "",
        },
      };
    case typeActions.ACCOUNT_RESET_PASSWORD_SUCCESS:
      return {
        ...state,
        isResetPassword: false,
        errors: {
          ...state.errors,
          resetPassword: "",
        },
      };
    case typeActions.ACCOUNT_RESET_PASSWORD_FAILED:
      return {
        ...state,
        isResetPassword: false,
        errors: {
          ...state.errors,
          resetPassword: actions.error,
        },
      };

    case typeActions.GET_ACCOUNTS_REQUEST:
      return {
        ...state,
        // accounts: { results: [] },
        isGetAccounts: true,
        errors: {
          ...state.errors,
          getAccounts: "",
        },
      };
    case typeActions.GET_ACCOUNTS_SUCCESS:
      return {
        ...state,
        accounts: actions.data || { results: [] },
        isGetAccounts: false,
        errors: {
          ...state.errors,
          getAccounts: "",
        },
      };
    case typeActions.GET_ACCOUNTS_FAILED:
      return {
        ...state,
        accounts: { results: [] },
        isGetAccounts: false,
        errors: {
          ...state.errors,
          getAccounts: actions.error,
        },
      };

    case typeActions.GET_CURRENT_ACCOUNT_REQUEST:
      return {
        ...state,
        isGetCurrentAccount: true,
        errors: {
          ...state.errors,
          getCurrentAccount: "",
        },
      };
    case typeActions.GET_CURRENT_ACCOUNT_SUCCESS:
      return {
        ...state,
        currentAccount: actions.data || {},
        isGetCurrentAccount: false,

        errors: {
          ...state.errors,
          getCurrentAccount: "",
        },
      };
    case typeActions.GET_CURRENT_ACCOUNT_FAILED:
      return {
        ...state,
        currentAccount: {},
        isGetCurrentAccount: false,
        errors: {
          ...state.errors,
          getCurrentAccount: actions.error,
        },
      };

    case typeActions.GET_CURRENT_ACCOUNT_EXTEND_REQUEST:
      return {
        ...state,
        isGetCurrentAccountExtend: true,
        errors: {
          ...state.errors,
          getCurrentAccountExtend: "",
        },
      };
    case typeActions.GET_CURRENT_ACCOUNT_EXTEND_SUCCESS:
      const permissions = _.get(
        actions.data,
        "decentralization.permissions",
        "[]"
      );
      const isAdmin =
        _.difference(CONSTANT.PERMISSION_ADMIN, permissions).length === 0;
      const isHasChildrent =
        _.get(actions.data, "chidrent.count", -1) > 0 &&
        _.get(actions.data, "id", -1) !== CONSTANT.HTGEN_ID;
      return {
        ...state,
        isAdmin: isAdmin,
        isHasChildrent,
        currentAccountExtend: actions.data || {},
        isSampleCollectorStaff: isSampleCollectorStaff(actions.data),
        isBusinessStaff: isBusinessStaff(actions.data),
        isManageStaff: isManageStaff(actions.data),
        isAccountantStaff: isAccountantStaff(actions.data),
        isCustomer: isCustomer(actions.data),
        isDoctorHT: isDoctorHT(actions.data),
        isDoctorKH: isDoctorKH(actions.data),
        isGetCurrentAccountExtend: false,
        errors: {
          ...state.errors,
          getCurrentAccountExtend: "",
        },
      };
    case typeActions.GET_CURRENT_ACCOUNT_EXTEND_FAILED:
      return {
        ...state,
        currentAccountExtend: {},
        isGetCurrentAccountExtend: false,
        errors: {
          ...state.errors,
          getCurrentAccountExtend: actions.error,
        },
      };

    case typeActions.GET_ACCOUNT_BY_ID_REQUEST:
      return {
        ...state,
        isGetAccountById: true,
        errors: {
          ...state.errors,
          getAccountById: "",
        },
      };
    case typeActions.GET_ACCOUNT_BY_ID_SUCCESS:
      return {
        ...state,
        account: actions.data || {},
        isGetAccountById: false,
        errors: {
          ...state.errors,
          getAccountById: "",
        },
      };
    case typeActions.GET_ACCOUNT_BY_ID_FAILED:
      return {
        ...state,
        account: {},
        isGetAccountById: false,
        errors: {
          ...state.errors,
          getAccountById: actions.error,
        },
      };

    case typeActions.CREATE_ACCOUNT_REQUEST:
      return {
        ...state,
        isCreateAccount: true,
        errors: {
          ...state.errors,
          createAccount: "",
        },
      };
    case typeActions.CREATE_ACCOUNT_SUCCESS:
      return {
        ...state,
        isCreateAccount: false,
        errors: {
          ...state.errors,
          createAccount: "",
        },
      };
    case typeActions.CREATE_ACCOUNT_FAILED:
      return {
        ...state,
        isCreateAccount: false,
        errors: {
          ...state.errors,
          createAccount: actions.error,
        },
      };

    case typeActions.UPDATE_ACCOUNT_REQUEST:
      return {
        ...state,
        isUpdateAccount: true,
        errors: {
          ...state.errors,
          updateAccount: "",
        },
      };
    case typeActions.UPDATE_ACCOUNT_SUCCESS:
      return {
        ...state,
        isUpdateAccount: false,
        errors: {
          ...state.errors,
          updateAccount: "",
        },
      };
    case typeActions.UPDATE_ACCOUNT_FAILED:
      return {
        ...state,
        isUpdateAccount: false,
        errors: {
          ...state.errors,
          updateAccount: actions.error,
        },
      };

    case typeActions.DELETE_ACCOUNT_REQUEST:
      return {
        ...state,
        isDeleteAccount: true,
        errors: {
          ...state.errors,
          deleteAccount: "",
        },
      };
    case typeActions.DELETE_ACCOUNT_SUCCESS:
      return {
        ...state,
        isDeleteAccount: false,
        errors: {
          ...state.errors,
          deleteAccount: "",
        },
      };
    case typeActions.DELETE_ACCOUNT_FAILED:
      return {
        ...state,
        isDeleteAccount: false,
        errors: {
          ...state.errors,
          deleteAccount: actions.error,
        },
      };

    case typeActions.CONFIG_PASSWORD_ACCOUNT_REQUEST:
      return {
        ...state,
        isConfigPasswordAccount: true,
        errors: {
          ...state.errors,
          configPasswordAccount: "",
        },
      };
    case typeActions.CONFIG_PASSWORD_ACCOUNT_SUCCESS:
      return {
        ...state,
        isConfigPasswordAccount: false,
        errors: {
          ...state.errors,
          configPasswordAccount: "",
        },
      };
    case typeActions.CONFIG_PASSWORD_ACCOUNT_FAILED:
      return {
        ...state,
        isConfigPasswordAccount: false,
        errors: {
          ...state.errors,
          configPasswordAccount: actions.error,
        },
      };

    case typeActions.ACCOUNT_CHANGE_PASSWORD_REQUEST:
      return {
        ...state,
        isAccountChangePassword: true,
        errors: {
          ...state.errors,
          accountChangePassword: "",
        },
      };
    case typeActions.ACCOUNT_CHANGE_PASSWORD_SUCCESS:
      return {
        ...state,
        isAccountChangePassword: false,
        errors: {
          ...state.errors,
          accountChangePassword: "",
        },
      };
    case typeActions.ACCOUNT_CHANGE_PASSWORD_FAILED:
      return {
        ...state,
        isAccountChangePassword: false,
        errors: {
          ...state.errors,
          accountChangePassword: actions.error,
        },
      };

    case typeActions.GET_ACCOUNTS_EXTEND_REQUEST:
      return {
        ...state,
        isGetAccountsExtend: true,
        errors: {
          ...state.errors,
          accountsExtend: "",
        },
      };
    case typeActions.GET_ACCOUNTS_EXTEND_SUCCESS:
      return {
        ...state,
        isGetAccountsExtend: false,
        accountsExtend: actions.data,
        errors: {
          ...state.errors,
          accountsExtend: "",
        },
      };
    case typeActions.GET_ACCOUNTS_EXTEND_FAILED:
      return {
        ...state,
        isGetAccountsExtend: false,
        errors: {
          ...state.errors,
          accountsExtend: actions.error,
        },
      };

    case typeActions.GET_ACCOUNT_EXTEND_REQUEST:
      return {
        ...state,
        isGetAccountExtend: true,
        errors: {
          ...state.errors,
          getAccountExtend: "",
        },
      };
    case typeActions.GET_ACCOUNT_EXTEND_SUCCESS:
      return {
        ...state,
        isGetAccountExtend: false,
        accountExtend: actions.data,
        errors: {
          ...state.errors,
          getAccountExtend: "",
        },
      };
    case typeActions.GET_RELATION_CHILDRNT_BY_ACCOUNT_EXTEND_SUCCESS:
      return {
        ...state,
        childrentByCurrentAccountExtend: actions.data,
        errors: {
          ...state.errors,
          getAccountExtend: "",
        },
      };
    case typeActions.GET_ACCOUNT_EXTEND_FAILED:
      return {
        ...state,
        isGetAccountExtend: false,
        errors: {
          ...state.errors,
          getAccountExtend: actions.error,
        },
      };

    case typeActions.CREATE_ACCOUNT_EXTEND_REQUEST:
      return {
        ...state,
        isCreateAccountExtend: true,
        errors: {
          ...state.errors,
          createAccountExtend: "",
        },
      };
    case typeActions.CREATE_ACCOUNT_EXTEND_SUCCESS:
      return {
        ...state,
        isCreateAccountExtend: false,
        errors: {
          ...state.errors,
          createAccountExtend: "",
        },
      };
    case typeActions.CREATE_ACCOUNT_EXTEND_FAILED:
      return {
        ...state,
        isCreateAccountExtend: false,
        errors: {
          ...state.errors,
          createAccountExtend: actions.error,
        },
      };

    case typeActions.UPDATE_ACCOUNT_EXTEND_REQUEST:
      return {
        ...state,
        isUpdateAccountExtend: true,
        errors: {
          ...state.errors,
          updateAccountExtend: "",
        },
      };
    case typeActions.UPDATE_ACCOUNT_EXTEND_SUCCESS:
      return {
        ...state,
        isUpdateAccountExtend: false,
        errors: {
          ...state.errors,
          updateAccountExtend: "",
        },
      };
    case typeActions.UPDATE_ACCOUNT_EXTEND_FAILED:
      return {
        ...state,
        isUpdateAccountExtend: false,
        errors: {
          ...state.errors,
          updateAccountExtend: actions.error,
        },
      };

    case typeActions.DELETE_ACCOUNT_EXTEND_REQUEST:
      return {
        ...state,
        isDeleteAccountExtend: true,
        errors: {
          ...state.errors,
          deleteAccountExtend: "",
        },
      };
    case typeActions.DELETE_ACCOUNT_EXTEND_SUCCESS:
      return {
        ...state,
        isDeleteAccountExtend: false,
        errors: {
          ...state.errors,
          deleteAccountExtend: "",
        },
      };
    case typeActions.DELETE_ACCOUNT_EXTEND_FAILED:
      return {
        ...state,
        isDeleteAccountExtend: false,
        errors: {
          ...state.errors,
          deleteAccountExtend: actions.error,
        },
      };

    case typeActions.UPDATE_CURRENT_ACCOUNT_REQUEST:
      return {
        ...state,
        isUpdateCurrentAccount: true,
        errors: {
          ...state.errors,
          updateCurrentAccount: "",
        },
      };
    case typeActions.UPDATE_CURRENT_ACCOUNT_SUCCESS:
      return {
        ...state,
        isUpdateCurrentAccount: false,
        currentAccount: actions.data,
        errors: {
          ...state.errors,
          updateCurrentAccount: "",
        },
      };
    case typeActions.UPDATE_CURRENT_ACCOUNT_FAILED:
      return {
        ...state,
        isUpdateCurrentAccount: false,
        errors: {
          ...state.errors,
          updateCurrentAccount: actions.error,
        },
      };

    default:
      return {
        ...state,
      };
  }
};
export default accountReducer;
