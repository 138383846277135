import { returnValue } from 'common';
import typeActions from 'redux/typeActions';
const initialState = {
  areas: null,
  area: {},
  pagination: {},
  areasByAccountExtend: [],
  // form show/delete
  isModalVisible: false,
  modalInfo: {},
  page: 1,
  limit: 10,
  query: {
    populate: '*',
  },
  //
  isGettingArea: false,
  errors: {
    getArea: ' ',
  },
};
const areaReducer = (state = initialState, actions) => {
  switch (actions.type) {
    case typeActions.SET_AREA_VALUE:
      const nameArea = actions.name;
      const valueArea = actions.value;
      if (typeof nameArea !== 'string') return { ...state };
      const objArea = returnValue(state, nameArea, valueArea);
      return {
        ...state,
        ...objArea,
      };
    case typeActions.GET_AREAS_REQUEST:
      return {
        ...state,
        ...actions?.data,
      };
    case typeActions.GET_AREAS_SUCCESS:
      return {
        ...state,
        areas: actions.data,
        // pagination: actions.data.pagination,
        // page: actions.data.pagination.page,
        // limit: actions.data.pagination.pageSize,
      };
    case typeActions.GET_AREAS_FAILED:
      return {
        ...state,
      };

    case typeActions.GET_AREA_REQUEST:
      return {
        ...state,
        isGettingArea: true,
        errors: {
          ...state.errors,
          getArea: '',
        },
      };
    case typeActions.GET_AREA_SUCCESS:
      return {
        ...state,
        area: actions.data || {},
        isGettingArea: false,
        errors: {
          ...state.errors,
          getArea: '',
        },
      };
    case typeActions.GET_AREA_FAILED:
      return {
        ...state,
        area: {},
        isGettingArea: false,
        errors: {
          ...state.errors,
          getArea: actions.error,
        },
      };
    // create

    case typeActions.CREATE_AREA_REQUEST:
      return {
        ...state,
        errors: {
          ...state.errors,
          createAccount: '',
        },
      };
    case typeActions.CREATE_AREA_SUCCESS:
      return {
        ...state,
        errors: {
          ...state.errors,
          createAccount: '',
        },
      };
    case typeActions.CREATE_AREA_FAILED:
      return {
        ...state,
        errors: {
          ...state.errors,
          createAccount: actions.error,
        },
      };

    //delete
    case typeActions.DELETE_AREA_REQUEST:
      return {
        ...state,
      };
    case typeActions.DELETE_AREA_SUCCESS:
      return {
        ...state,
      };
    case typeActions.DELETE_AREA_FAILED:
      return {
        ...state,
      };

    //
    case typeActions.GET_AREAS_BY_ACCOUNT_EXTEND_REQUEST:
      return {
        ...state,
      };
    case typeActions.GET_AREAS_BY_ACCOUNT_EXTEND_SUCCESS:
      return {
        ...state,
        areasByAccountExtend: actions.data,
      };
    case typeActions.GET_AREAS_BY_ACCOUNT_EXTEND_FAILED:
      return {
        ...state,
      };
    default:
      return {
        ...state,
      };
  }
};

export default areaReducer;
