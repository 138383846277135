import _ from 'lodash';
import { takeLatest, call, put, all } from 'redux-saga/effects';

import typeActions from 'redux/typeActions';
import ServiceURL from 'services/ServiceURL';
import { DELETE, GET, POST, PUT } from 'services/ServiceBase';

export function* getAuthors(data) {
  const url = `${ServiceURL.author}?sort=createdAt:DESC&${data.query}`;
  const callback = data.callback;
  try {
    const res = yield call(GET, url);
    if (!!res.data?.error?.message) {
      yield put({
        type: typeActions.GET_AUTHORS_FAILED,
        error: res.data?.error?.message,
      });
      !!callback?.failed && callback.failed(res.data.error.message);
    } else {
      if (data.isUpdateReducer) {
        yield put({
          type: typeActions.GET_AUTHORS_SUCCESS,
          data: res.data,
        });
      }
      !!callback?.success && callback.success(res.data);
    }
  } catch (error) {
    yield put({
      type: typeActions.GET_AUTHORS_FAILED,
      error: error?.response?.data?.error?.message ?? '',
    });
    !!callback?.failed &&
      callback.failed(error?.response?.data?.error?.message ?? '');
  }
}

export function* getAuthor(data) {
  const url = `${ServiceURL.author}/${data.param}?${data.query}`;
  const callback = data.callback;
  try {
    const res = yield call(GET, url);
    if (!!res.data?.error?.message) {
      yield put({
        type: typeActions.GET_AUTHOR_FAILED,
        error: res.data.error.message,
      });
      !!callback?.failed && callback.failed(res.data.error.message);
    } else {
      yield put({
        type: typeActions.GET_AUTHOR_SUCCESS,
        data: res.data,
      });
      !!callback?.success && callback.success(res.data);
    }
  } catch (error) {
    yield put({
      type: typeActions.GET_AUTHOR_FAILED,
      error: error?.response?.data?.error?.message ?? '',
    });
    !!callback?.failed &&
      callback.failed(error?.response?.data?.error?.message ?? '');
  }
}

export function* createAuthor(data) {
  const url = `${ServiceURL.author}`;
  const callback = data.callback;
  try {
    const res = yield call(POST, url, data.body);
    if (!!res.data?.error?.message) {
      yield put({
        type: typeActions.CREATE_AUTHOR_FAILED,
        error: res.data.error.message,
      });
      !!callback?.failed && callback.failed(res.data.error.message);
    } else {
      yield put({
        type: typeActions.CREATE_AUTHOR_SUCCESS,
        data: res.data?.data,
      });
      !!callback?.success && callback.success(res.data?.data);
    }
  } catch (error) {
    yield put({
      type: typeActions.CREATE_AUTHOR_FAILED,
      error: error?.response?.data?.error?.message ?? '',
    });
    !!callback?.failed &&
      callback.failed(error?.response?.data?.error?.message ?? '');
  }
}

export function* updateAuthor(data) {
  const url = `${ServiceURL.author}/${data.param}`;
  const callback = data.callback;
  try {
    const res = yield call(PUT, url, data.body);
    if (!!res.data?.error?.message) {
      yield put({
        type: typeActions.UPDATE_AUTHOR_FAILED,
        error: res.data.error.message,
      });
      !!callback?.failed && callback.failed(res.data.error.message);
    } else {
      yield put({
        type: typeActions.UPDATE_AUTHOR_SUCCESS,
        data: res.data,
      });

      !!callback?.success && callback.success(res.data);
    }
  } catch (error) {
    yield put({
      type: typeActions.UPDATE_AUTHOR_FAILED,
      error: error?.response?.data?.error?.message ?? '',
    });
    !!callback?.failed &&
      callback.failed(error?.response?.data?.error?.message ?? '');
  }
}

export function* deleteAuthor(data) {
  const url = `${ServiceURL.author}/${data.param}`;
  const callback = data.callback;
  try {
    const res = yield call(DELETE, url);
    if (!!res.data?.error?.message) {
      yield put({
        type: typeActions.DELETE_AUTHOR_FAILED,
        error: res.data.error.message,
      });
      !!callback?.failed && callback.failed(res.data.error.message);
    } else {
      yield put({
        type: typeActions.DELETE_AUTHOR_SUCCESS,
        data: res.data,
      });
      !!callback?.success && callback.success(res.data);
    }
  } catch (error) {
    yield put({
      type: typeActions.DELETE_AUTHOR_FAILED,
      error: error?.response?.data?.error?.message ?? '',
    });
    !!callback?.failed &&
      callback.failed(error?.response?.data?.error?.message ?? '');
  }
}

function* getAuthorRelation(data) {
  try {
    const callback = data.callback;
    const urlRelation = `${ServiceURL.relation}/api::author.author/${data.id}/${data.relationTo}?pageSize=100`;

    const response = yield call(GET, urlRelation);

    const results = _.get(response, 'data.results', []);
    if (results.length === 0) return null;
    switch (data.relationTo) {
      case 'paymentHistories':
        const apis = results.map((item) => {
          const urlPaymentHistory = `${ServiceURL.paymentHistory}/${
            item.id ?? -1
          }`;
          return call(GET, urlPaymentHistory);
        });
        const res = yield all(apis);
        !!callback?.success &&
          callback.success(
            res.map((v) => {
              const r = _.get(v, 'data', {});
              return r;
            })
          );
        break;
      default:
        return null;
    }
  } catch (error) {}
}

export default function* authorSaga() {
  yield takeLatest(typeActions.GET_AUTHORS_REQUEST, getAuthors);
  yield takeLatest(typeActions.GET_AUTHOR_REQUEST, getAuthor);
  yield takeLatest(typeActions.CREATE_AUTHOR_REQUEST, createAuthor);
  yield takeLatest(typeActions.UPDATE_AUTHOR_REQUEST, updateAuthor);
  yield takeLatest(typeActions.DELETE_AUTHOR_REQUEST, deleteAuthor);
}
