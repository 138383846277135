import { useEffect, useRef } from "react";
import { useDispatch } from "react-redux";

export function useDidUpdateEffect(fn, inputs) {
  const fncRef = useRef();
  fncRef.current = fn;
  const didMountRef = useRef(false);

  useEffect(() => {
    if (!didMountRef.current) {
      didMountRef.current = true;
    } else {
      return fncRef.current();
    }
  }, inputs);
}

// const handleSearch = (callback) =>{
//   const loop = setTimeout(() => {
//    callback();
//   }, 500);
//   return () => {
//     clearTimeout(loop);
//   };
// }
//
export function useDelayInputEffect(fnAction, searchTerm, timeDelay) {
  const dispatch = useDispatch();
  useEffect(() => {
    const delayDebounceFn = setTimeout(() => {
      if (Number.isInteger(timeDelay)) dispatch(fnAction);
    }, timeDelay);
    return () => clearTimeout(delayDebounceFn);
  },Array.isArray(searchTerm)? searchTerm : [searchTerm]);
}
export function useDelayInputEffectFuntion(fn, searchTerm, timeDelay) {
  useEffect(() => {
    const delayDebounceFn = setTimeout(() => {
      if (Number.isInteger(timeDelay)) fn();
    }, timeDelay);
    return () => clearTimeout(delayDebounceFn);
  },Array.isArray(searchTerm)? searchTerm : [searchTerm]);
}
