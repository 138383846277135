import _ from 'lodash';
import { takeLatest, call, put, all } from 'redux-saga/effects';

import typeActions from 'redux/typeActions';
import ServiceURL from 'services/ServiceURL';
import { DELETE, GET, POST, PUT } from 'services/ServiceBase';

export function* getResults(data) {
  const url = `${ServiceURL.result}?filters[$and][0][deleted][$eq]=false&sort=createdAt:DESC&${data.query}`;
  const callback = data.callback;
  try {
    const res = yield call(GET, url);
    if (!!res.data?.error?.message) {
      yield put({
        type: typeActions.GET_RESULTS_FAILED,
        error: res.data?.error?.message,
      });
      !!callback?.failed && callback.failed(res.data.error.message);
    } else {
      if (data.isUpdateReducer) {
        yield put({
          type: typeActions.GET_RESULTS_SUCCESS,
          data: res.data,
        });
      }
      !!callback?.success && callback.success(res.data);
    }
  } catch (error) {
    yield put({
      type: typeActions.GET_RESULTS_FAILED,
      error: error?.response?.data?.error?.message ?? '',
    });
    !!callback?.failed &&
      callback.failed(error?.response?.data?.error?.message ?? '');
  }
}

export function* getResult(data) {
  const url = `${ServiceURL.result}/${data.param}?${data.query}`;
  const callback = data.callback;
  try {
    const res = yield call(GET, url);
    if (!!res.data?.error?.message) {
      yield put({
        type: typeActions.GET_RESULT_FAILED,
        error: res.data.error.message,
      });
      !!callback?.failed && callback.failed(res.data.error.message);
    } else {
      yield put({
        type: typeActions.GET_RESULT_SUCCESS,
        data: res.data,
      });
      !!callback?.success && callback.success(res.data);
    }
  } catch (error) {
    yield put({
      type: typeActions.GET_RESULT_FAILED,
      error: error?.response?.data?.error?.message ?? '',
    });
    !!callback?.failed &&
      callback.failed(error?.response?.data?.error?.message ?? '');
  }
}

export function* createResult(data) {
  const url = `${ServiceURL.result}`;
  const callback = data.callback;
  try {
    const res = yield call(POST, url, data.body);
    if (!!res.data?.error?.message) {
      yield put({
        type: typeActions.CREATE_RESULT_FAILED,
        error: res.data.error.message,
      });
      !!callback?.failed && callback.failed(res.data.error.message);
    } else {
      yield put({
        type: typeActions.CREATE_RESULT_SUCCESS,
        data: res.data?.data,
      });
      !!callback?.success && callback.success(res.data?.data);
    }
  } catch (error) {
    yield put({
      type: typeActions.CREATE_RESULT_FAILED,
      error: error?.response?.data?.error?.message ?? '',
    });
    !!callback?.failed &&
      callback.failed(error?.response?.data?.error?.message ?? '');
  }
}

export function* updateResult(data) {
  const url = `${ServiceURL.result}/${data.param}`;
  const callback = data.callback;
  try {
    const res = yield call(PUT, url, data.body);
    if (!!res.data?.error?.message) {
      yield put({
        type: typeActions.UPDATE_RESULT_FAILED,
        error: res.data.error.message,
      });
      !!callback?.failed && callback.failed(res.data.error.message);
    } else {
      yield put({
        type: typeActions.UPDATE_RESULT_SUCCESS,
        data: res.data,
      });

      !!callback?.success && callback.success(res.data);
    }
  } catch (error) {
    yield put({
      type: typeActions.UPDATE_RESULT_FAILED,
      error: error?.response?.data?.error?.message ?? '',
    });
    !!callback?.failed &&
      callback.failed(error?.response?.data?.error?.message ?? '');
  }
}

export function* deleteResult(data) {
  const url = `${ServiceURL.result}/${data.param}`;
  const callback = data.callback;
  try {
    const res = yield call(DELETE, url);
    if (!!res.data?.error?.message) {
      yield put({
        type: typeActions.DELETE_RESULT_FAILED,
        error: res.data.error.message,
      });
      !!callback?.failed && callback.failed(res.data.error.message);
    } else {
      yield put({
        type: typeActions.DELETE_RESULT_SUCCESS,
        data: res.data,
      });
      !!callback?.success && callback.success(res.data);
    }
  } catch (error) {
    yield put({
      type: typeActions.DELETE_RESULT_FAILED,
      error: error?.response?.data?.error?.message ?? '',
    });
    !!callback?.failed &&
      callback.failed(error?.response?.data?.error?.message ?? '');
  }
}

function* getResultRelation(data) {
  try {
    const callback = data.callback;
    const urlRelation = `${ServiceURL.relation}/api::result.result/${data.id}/${data.relationTo}?pageSize=100`;

    const response = yield call(GET, urlRelation);

    const results = _.get(response, 'data.results', []);
    if (results.length === 0) return null;
    switch (data.relationTo) {
      case 'paymentHistories':
        const apis = results.map((item) => {
          const urlPaymentHistory = `${ServiceURL.paymentHistory}/${
            item.id ?? -1
          }`;
          return call(GET, urlPaymentHistory);
        });
        const res = yield all(apis);
        !!callback?.success &&
          callback.success(
            res.map((v) => {
              const r = _.get(v, 'data', {});
              return r;
            })
          );
        break;
      default:
        return null;
    }
  } catch (error) {}
}

export default function* resultSaga() {
  yield takeLatest(typeActions.GET_RESULTS_REQUEST, getResults);
  yield takeLatest(typeActions.GET_RESULT_REQUEST, getResult);
  yield takeLatest(typeActions.GET_RESULT_RELATION_REQUEST, getResultRelation);
  yield takeLatest(typeActions.CREATE_RESULT_REQUEST, createResult);
  yield takeLatest(typeActions.UPDATE_RESULT_REQUEST, updateResult);
  yield takeLatest(typeActions.DELETE_RESULT_REQUEST, deleteResult);
}
