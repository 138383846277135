export const BASE_URL = process.env.REACT_APP_BASE_URL;
export const BASE_URL_IMAGE = process.env.REACT_APP_BASE_URL_IMAGE;
export const REACT_APP_BASE_GRAPHQL_URL =
  process.env.REACT_APP_BASE_GRAPHQL_URL;
export const BASE_URL_STRAPI = process.env.REACT_APP_BASE_URL_STRAPI;
export const CONVERT_BASE_URL = process.env.REACT_APP_CONVERT_BASE_URL;

const admin = 'admin';
const logIn = 'admin/login';
const logOut = 'auth/logout';
const forgotPassword = 'admin/forgot-password';
const resetPassword = 'admin/reset-password';
const accounts = 'admin/users';
const changePassword = 'change-password';
const configPasswordAccount = 'set-password';
const decentralization =
  'content-manager/collection-types/api::decentralization.decentralization';
const me = 'me';
const permissions = 'permissions';
const notification =
  'content-manager/collection-types/api::notification.notification';
const accountExtend =
  'content-manager/collection-types/api::account-extend.account-extend';
const upload = 'api/upload';
const area = 'content-manager/collection-types/api::area.area';
const staff = 'content-manager/collection-types/api::staff.staff';
const organization =
  'content-manager/collection-types/api::organization.organization';
const partner = 'content-manager/collection-types/api::partner.partner';
const roleExtend =
  'content-manager/collection-types/api::role-extend.role-extend';
const barcode = 'content-manager/collection-types/api::barcode.barcode';
const service = 'content-manager/collection-types/api::service.service';
const serviceGroup =
  'content-manager/collection-types/api::service-group.service-group';
const promotion = 'content-manager/collection-types/api::promotion.promotion';
const province = 'content-manager/collection-types/api::province.province';
const specifyvote =
  'content-manager/collection-types/api::specify-vote.specify-vote';
const templatepcd =
  'content-manager/collection-types/api::template-pcd.template-pcd';
const promotionDetail =
  'content-manager/collection-types/api::promotion-detail.promotion-detail';
const relation = 'content-manager/relations';
const patient = 'content-manager/collection-types/api::patient.patient';
const order = 'content-manager/collection-types/api::order.order';
const bill = 'content-manager/collection-types/api::bill.bill';
const medicalRecord =
  'content-manager/collection-types/api::medical-record.medical-record';
const paymentHistory =
  'content-manager/collection-types/api::payment-history.payment-history';
const result = 'content-manager/collection-types/api::result.result';
const exportOrder = 'api/exportOrder';
const exportOrderNeedUpdate = 'api/export-order-need-update';
const exportOrderUnapproved = 'api/export-order-wait-approve';
const exportOrderSynthesisSampleTransfer = 'api/export-order-synthesis-sample-transfer';
const exportOrderReceiveResult = 'api/export-order-receive-result';
const exportOrderApproveResult = 'api/export-order-approve-result';
const exportOrderPayResult = 'api/export-order-pay-result';
const exportAreas = 'api/export-area';
const exportOrganizations = 'api/export-organization';
const exportServiceGroups = 'api/export-service-group';
const exportServices = 'api/export-service';
const exportSpecifyVote = 'api/export-specify-vote';
const exportPatient = 'api/export-patient';
const exportPromotion = 'api/exportPromotion';
const exportPromotionDetail = 'api/export-promotion-detail';
const exportPartner = 'api/export-partner';
const exportAccount = 'api/export-account';
const exportBarcode = 'api/export-barcode';
const printPcd = 'api/printPcd';
const printPkq = 'api/printPkq';
const printPkqAdditionalSample = 'api/printPkqAdditionalSample';
const importAccount = 'api/import-account';
const serviceDetail =
  'content-manager/collection-types/api::service-detail.service-detail';
const contentXn = 'content-manager/collection-types/api::content-xn.content-xn';
const author = 'content-manager/collection-types/api::author.author';
const statistic = 'api/statistic';
const roleUI = 'content-manager/single-types/api::role-ui.role-ui';
const additionalSample =
  'content-manager/collection-types/api::additional-sample.additional-sample';
const exportAdditionalSample = 'api/exportAdditionalSample';
const exportAdditionalSampleAll = 'api/export-additional-sample';

// eslint-disable-next-line import/no-anonymous-default-export
export default {
  BASE_URL,
  admin,
  logIn,
  logOut,
  forgotPassword,
  resetPassword,
  accounts,
  changePassword,
  configPasswordAccount,
  decentralization,
  me,
  permissions,
  notification,
  accountExtend,
  upload,
  roleExtend,
  area,
  staff,
  organization,
  barcode,
  service,
  serviceGroup,
  promotion,
  partner,
  province,
  specifyvote,
  promotionDetail,
  relation,
  patient,
  order,
  medicalRecord,
  bill,
  paymentHistory,
  result,
  exportOrder,
  exportPromotion,
  printPcd,
  templatepcd,
  serviceDetail,
  contentXn,
  author,
  statistic,
  roleUI,
  printPkq,
  importAccount,
  additionalSample,
  printPkqAdditionalSample,
  exportAdditionalSample,
  exportAdditionalSampleAll,
  exportSpecifyVote,
  exportPatient,
  exportOrderNeedUpdate,
  exportOrderUnapproved,
  exportOrderSynthesisSampleTransfer,
  exportOrderReceiveResult,
  exportOrderApproveResult,
  exportOrderPayResult,
  exportAreas,
  exportOrganizations,
  exportServiceGroups,
  exportServices,
  exportPromotionDetail,
  exportPartner,
  exportBarcode,
  exportAccount
};
