import React, { memo, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Button, Col, Form, Modal, Row } from 'reactstrap';
import * as yup from 'yup';
import { Formik } from 'formik';
import _ from 'lodash';
import queryString from 'query-string';

import {
  isNumeric,
  notificationAlertRef,
  notify,
  relationOneToOne,
} from 'common';
import LabelCustom from 'views/pages/components/LabelCustom';
import {
  areaActions,
  formActions,
  additionalSampleActions,
  uploadActions,
  orderActions,
  barcodeActions,
} from 'redux/actions';
import CONSTANT from 'constant';
import HelperText from 'views/pages/components/HelperText';
import SelectCustom from 'views/pages/components/SelectCustom';
import InputCustom from 'views/pages/components/InputCustom';
import LoadingButtonCustom from 'views/pages/components/LoadingButtonCustom';
const CAdditionalSample = ({ handleGetAdditionalSamples }) => {
  const dispatch = useDispatch();

  // reducers
  const {
    isSampleCollectorStaff,
    isBusinessStaff,
    currentAccountExtend,
    childrentByCurrentAccountExtend,
    isAdmin,
    isHasChildrent,
  } = useSelector((state) => state.accountReducer);
  const { isModalCreateAdditionalSampleVisible, modalInfo } = useSelector(
    (state) => state.formReducer
  );
  const { areasByAccountExtend } = useSelector((state) => state.areaReducer);
  const { isCreatingAdditionalSample } = useSelector(
    (state) => state.additionalSampleReducer
  );
  // Khởi tạo dữ liêu
  const initInfo = {
    order: null,
    barcode: null,
    numOfSpecimen: 0,
    image: null,
    imageUrl: null,
  };

  const [barcodeSearch, setBarcodeSearch] = useState('');
  const [orderSearch, setOrderSearch] = useState('');

  const [orderValue, setOrderValue] = useState(null);
  const [barcodeValue, setBarcodeValue] = useState(null);

  const [orderResults, setOrderResults] = useState([]);
  const [barcodeResults, setBarcodeResults] = useState([]);

  // validate dữ liêu
  const infoSchema = yup.object().shape(
    !_.isNumber(modalInfo?.id)
      ? {
          order: yup
            .string()
            .required('Đơn hàng không được để trống!')
            .nullable(),
          numOfSpecimen: yup
            .number()
            .min(1, 'Số mẫu phải lớn hơn 0!')
            .max(3, 'Số mẫu nhỏ hơn 3'),
          barcode: yup
            .string()
            .required('Barcode không được để trống!')
            .nullable(),
          imageUrl: yup
            .string()
            .required('Ảnh phiếu xét nghiệm không được để trống!')
            .nullable(),
        }
      : {
          barcode: yup
            .string()
            .required('Barcode không được để trống!')
            .nullable(),
          imageUrl: yup
            .string()
            .required('Ảnh phiếu xét nghiệm không được để trống!')
            .nullable(),
        }
  );

  // Xử lý submit
  const onSubmit = (values, { resetForm }) => {
    if (!isSampleCollectorStaff && !isBusinessStaff && !isAdmin) return;
    const { order, barcode, numOfSpecimen } = values;
    const body = !_.isNumber(modalInfo?.id)
      ? {
          order: relationOneToOne(order, null),
          barcode: relationOneToOne(barcode, null),
          numOfSpecimen,
          areaName:
            areasByAccountExtend.length > 0
              ? areasByAccountExtend[0].name
              : orderValue?.areaName ?? '',
          codeStaff1: relationOneToOne(
            orderValue?.codeStaff1?.id ?? null,
            null
          ),
          codeStaff2: relationOneToOne(
            orderValue?.codeStaff2?.id ?? null,
            null
          ),
          codeStaff3: relationOneToOne(
            orderValue?.codeStaff3?.id ?? null,
            null
          ),
          organization: relationOneToOne(
            orderValue?.organization?.id ?? null,
            null
          ),
          bill: relationOneToOne(orderValue?.bill?.id ?? null, null),
          specifyVote: relationOneToOne(
            orderValue?.specifyVote?.id ?? null,
            null
          ),
        }
      : {
          barcode: relationOneToOne(barcode, null),
          status: CONSTANT.ORDER_STATUS[1].value,
          receptionStatus: false,
        };

    handleUploadFiles(values, body, resetForm);
  };

  const handleUploadFiles = (values, body, resetForm) => {
    const formData = new FormData();
    formData.append('files', values.image.file, values.image.file.name);
    if (!_.isEmpty(values.imagePayment)) {
      formData.append(
        'files',
        values.imagePayment.file,
        values.imagePayment.file.name
      );
    }
    dispatch(
      uploadActions.uploadFiles(formData, {
        success: (data) => {
          handleCreate(
            body,
            data.map((item) => item.id),
            resetForm
          );
        },
        failed: (mess) => {
          notify(
            notificationAlertRef,
            'danger',
            'Thông báo',
            `Tải ảnh lên thất bại!. Lỗi: ${mess}!`
          );
        },
      })
    );
  };

  const handleCreate = (body, imageIds, resetForm) => {
    body.image = imageIds[0];
    if (!_.isNumber(modalInfo?.id)) {
      dispatch(
        additionalSampleActions.createAdditionalSample(body, {
          success: () => {
            handleGetBarcodes();
            handleGetAdditionalSamples();
            notify(
              notificationAlertRef,
              'success',
              'Thông báo',
              `Thêm mới mẫu bổ sung thành công.`
            );
            resetForm();
            handleClose();
          },
          failed: (mess) => {
            notify(
              notificationAlertRef,
              'danger',
              'Thông báo',
              `Thêm mới mẫu bổ sung thất bại!. Lỗi: ${mess}!`
            );
          },
        })
      );
    } else {
      dispatch(
        additionalSampleActions.updateAdditionalSample(modalInfo.id, body, {
          success: () => {
            dispatch(
              barcodeActions.updateBarcode(
                barcodeValue.value,
                { status: true, usedDate: new Date().toISOString() },
                {
                  success: () => {
                    handleGetBarcodes();
                  },
                }
              )
            );
            handleGetAdditionalSamples();
            notify(
              notificationAlertRef,
              'success',
              'Thông báo',
              `Cập nhật mẫu bổ sung thành công.`
            );
            resetForm();
            handleClose();
          },
          failed: (mess) => {
            notify(
              notificationAlertRef,
              'danger',
              'Thông báo',
              `Cập nhật mẫu bổ sung thất bại!. Lỗi: ${mess}!`
            );
          },
        })
      );
    }
  };

  useEffect(() => {
    // Sau 500ms thì bắn api
    const delayDebounceFn = setTimeout(() => {
      handleGetOrders();
    }, 500);
    return () => clearTimeout(delayDebounceFn);
  }, [orderSearch, areasByAccountExtend]);

  const handleGetOrders = () => {
    const query = {
      _q: orderSearch,
    };
    if (!isBusinessStaff) {
      areasByAccountExtend.forEach((item, index) => {
        query[`filters[$and][2][$or][${index}][areaName][$eq]`] = item.name;
      });
    }
    query[
      `filters[$and][2][$or][${areasByAccountExtend.length}][createdBy][id][$eq]`
    ] = currentAccountExtend.id;
    if (!isAdmin) {
      if (isSampleCollectorStaff) {
        query[
          `filters[$and][2][$or][${
            areasByAccountExtend.length + 1
          }][codeStaff2][id][$eq]`
        ] = currentAccountExtend?.id;
      } else if (isBusinessStaff) {
        query[
          `filters[$and][2][$or][${
            areasByAccountExtend.length + 1
          }][codeStaff1][id][$eq]`
        ] = currentAccountExtend?.id;
      }
      if (isHasChildrent) {
        if (isSampleCollectorStaff) {
          childrentByCurrentAccountExtend.forEach((item, index) => {
            query[
              `filters[$and][2][$or][${
                areasByAccountExtend.length + 2 + index
              }][codeStaff2][id][$eq]`
            ] = item.id;
          });
        } else if (isBusinessStaff) {
          childrentByCurrentAccountExtend.forEach((item, index) => {
            query[
              `filters[$and][2][$or][${
                areasByAccountExtend.length + 2 + index
              }][codeStaff1][id][$eq]`
            ] = item.id;
          });
        }
      }
    }

    dispatch(
      orderActions.getOrders(
        queryString.stringify(query, {
          skipEmptyString: true,
          skipNull: true,
        }),
        {
          success: (data) => {
            setOrderResults(data?.results ?? []);
          },
        }
      )
    );
  };

  useEffect(() => {
    const delayDebounceFn = setTimeout(() => {
      handleGetBarcodes();
    }, 500);

    return () => clearTimeout(delayDebounceFn);
  }, [barcodeSearch]);

  const handleGetBarcodes = () => {
    const query = {
      'filters[$and][0][status][$eq]': false,
    };
    if (barcodeSearch !== '') {
      query['filters[$and][1][barcode][$containsi]'] = barcodeSearch;
    }

    dispatch(
      barcodeActions.getBarcodes(
        queryString.stringify(query),
        {
          success: (data) => {
            const results = _.get(data, 'results', []);
            setBarcodeResults(results);
          },
          failed: () => {},
        },
        false
      )
    );
  };

  // Xử lý đóng form
  const handleClose = () => {
    setOrderValue(null);
    setBarcodeValue(null);
    dispatch(
      formActions.setValue({
        name: 'isModalCreateAdditionalSampleVisible',
        value: false,
      })
    );
    dispatch(
      formActions.setValue({
        name: 'modalInfo',
        value: {},
      })
    );
  };

  return (
    <Modal
      size={'md'}
      isOpen={isModalCreateAdditionalSampleVisible}
      className="modal-dialog-centered modal-secondary"
      toggle={() => {
        handleClose();
      }}
    >
      <div className="modal-header badditionalSample-bottom bg-white pb-2">
        <h2>{!_.isNumber(modalInfo?.id) ? 'Thêm' : 'Cập nhật'} mẫu bổ sung</h2>
        <button
          aria-label="Close"
          className="close"
          data-dismiss="modal"
          type="button"
          onClick={() => {
            handleClose();
          }}
        >
          <span style={{ color: '#262626' }} aria-hidden={true}>
            ×
          </span>
        </button>
      </div>
      <div className="modal-body bg-white rounded">
        <Formik
          initialValues={initInfo}
          enableReinitialize
          onSubmit={onSubmit}
          validationSchema={infoSchema}
        >
          {({
            values,
            errors,
            touched,
            handleSubmit,
            handleBlur,
            handleChange,
            setFieldValue,
          }) => {
            return (
              <Form onSubmit={handleSubmit} className="">
                {!_.isNumber(modalInfo?.id) && (
                  <Row>
                    <Col xs={6}>
                      <SelectCustom
                        name="order"
                        label="Chọn mã đơn hàng"
                        placeholder="Chọn mã đơn hàng"
                        value={orderValue}
                        isRequired={true}
                        isClearable={false}
                        handleChange={(e) => {
                          setOrderValue(e);
                          setFieldValue('order', e?.value ?? -1);
                        }}
                        handleBlur={handleBlur}
                        onInputChange={(value) => {
                          setOrderSearch(value);
                        }}
                        error={errors?.order}
                        touched={touched?.order}
                        options={orderResults.map((item) => ({
                          value: item.id,
                          label: `${item.code}(${item.name})`,
                          ...item,
                        }))}
                      />
                    </Col>
                  </Row>
                )}

                <Row>
                  {!_.isNumber(modalInfo?.id) && (
                    <Col xs={6}>
                      <InputCustom
                        name="numOfSpecimen"
                        label="Số mẫu bổ sung"
                        type="number"
                        placeholder="Nhập"
                        value={values.numOfSpecimen}
                        isRequired={true}
                        handleChange={(e) => {
                          if (isNumeric(e.target.value)) {
                            handleChange(e);
                          }
                        }}
                        handleBlur={handleBlur}
                        error={errors?.numOfSpecimen}
                        touched={touched?.numOfSpecimen}
                      />
                    </Col>
                  )}
                  <Col xs={6}>
                    <SelectCustom
                      name="barcode"
                      label="Mã barcode mẫu bổ sung"
                      placeholder="Lựa chọn"
                      value={barcodeValue}
                      isRequired={true}
                      isClearable={false}
                      handleChange={(e) => {
                        setFieldValue('barcode', e?.value ?? null);
                        setBarcodeValue(e);
                      }}
                      handleBlur={handleBlur}
                      onInputChange={(value) => {
                        setBarcodeSearch(value);
                      }}
                      error={errors.barcode}
                      touched={touched.barcode}
                      options={barcodeResults.map((item) => ({
                        label: item.barcode,
                        value: item?.id,
                      }))}
                    />
                  </Col>
                </Row>

                <Row>
                  <Col className="d-flex flex-column" xs={12}>
                    <LabelCustom
                      isRequired={true}
                      label={'Ảnh phiếu xét nghiệm và mẫu'}
                      styleLabel={{ maxWidth: 120 }}
                    />
                    <input
                      className="mt-1"
                      style={{ fontSize: 12 }}
                      type="file"
                      accept="image/*"
                      onChange={(e) => {
                        try {
                          if (_.isEmpty(e)) return;

                          setFieldValue('image', {
                            fileUrl: URL.createObjectURL(e.target.files[0]),
                            file: e.target.files[0],
                          });
                          setFieldValue(
                            'imageUrl',
                            URL.createObjectURL(e.target.files[0])
                          );
                        } catch (error) {
                          //
                        }
                      }}
                    />
                    {errors.imageUrl && touched.imageUrl && (
                      <HelperText message={errors.imageUrl} />
                    )}
                  </Col>
                </Row>
                <div className="mb-0 pb-0 mt-4 d-flex justify-content-end">
                  <Button
                    className="text-nowrap mr-1"
                    onClick={() => {
                      handleClose();
                    }}
                  >
                    Hủy
                  </Button>
                  <LoadingButtonCustom
                    color="info"
                    className="text-nowrap "
                    type="submit"
                    loading={isCreatingAdditionalSample}
                  >
                    {!_.isNumber(modalInfo?.id) ? 'Thêm mới' : 'Cập nhật'}
                  </LoadingButtonCustom>
                </div>
              </Form>
            );
          }}
        </Formik>
      </div>
    </Modal>
  );
};

export default memo(CAdditionalSample);
