import React from 'react';
import _ from 'lodash';
import { CardHeader, Col, Row } from 'reactstrap';
import { useSelector } from 'react-redux';

import 'assets/css/custom-pages/react-bs-table.css';
import LabelValue from 'views/pages/components/LabelValue';
import CONSTANT from 'constant';

import moment from 'moment';

const XNInfo = ({}) => {
  const { order } = useSelector((state) => state.orderReducer);

  const specimensValue = CONSTANT.SPECIMENS.filter((item) =>
    (order?.specifyVote?.specimens ?? []).includes(item.value)
  );

  return (
    <>
      <Row>
        <CardHeader className="pb-2 d-flex justify-content-between rounded border-bottom w-100">
          <h3>Thông tin xét nghiệm</h3>
        </CardHeader>
      </Row>
      <Row>
        <Col xs={12} md={6}>
          <LabelValue
            labelWidth={160}
            label="Mã xét nghiệm"
            value={order?.specifyVote?.codeTest ?? ''}
          />
        </Col>
      </Row>
      <Row>
        <Col xs={12}>
          <LabelValue
            labelWidth={160}
            label="Tên xét nghiệm"
            value={order?.specifyVote?.nameTest ?? ''}
          />
        </Col>
      </Row>
      <Row>
        <Col xs={12}>
          <LabelValue
            labelWidth={160}
            label="Mẫu xét nghiệm"
            value={specimensValue.map((item, index) => {
              return index === specimensValue.length - 1
                ? item?.label ?? ''
                : `${item?.label ?? ''} - `;
            })}
          />
        </Col>
      </Row>
      <Row>
        <Col xs={12}>
          <LabelValue
            labelWidth={160}
            label="Nội dung xét nghiệm"
            value={order?.specifyVote?.content}
          />
        </Col>
      </Row>
      <Row>
        <Col xs={12}>
          <LabelValue
            labelWidth={160}
            label="Phòng khám"
            value={order?.specifyVote?.nameTC ?? ''}
          />
        </Col>
      </Row>
      <Row>
        <Col xs={12}>
          <LabelValue
            labelWidth={160}
            label="Bác sĩ chỉ định"
            value={`${order?.specifyVote?.lastNameBS ?? ''} ${
              order?.specifyVote?.firstNameBS ?? ''
            }`}
          />
        </Col>
      </Row>

      <Row>
        <Col xs={12}>
          <LabelValue
            labelWidth={160}
            label="Nơi thu mẫu"
            value={order?.specifyVote?.whereTM ?? ''}
          />
        </Col>
      </Row>
      <Row>
        <Col xs={12} md={12}>
          <LabelValue
            labelWidth={160}
            label={'Ngày thu mẫu'}
            value={
              !!order?.specifyVote?.dateTM
                ? moment(order.specifyVote.dateTM).format('DD/MM/YYYY')
                : ''
            }
          />
        </Col>
      </Row>
      <Row>
        <Col xs={12} md={12}>
          <LabelValue
            labelWidth={160}
            label={'Giờ thu mẫu'}
            value={
              !!order?.specifyVote?.dateTM
                ? moment(order.specifyVote.dateTM).format('HH:mm')
                : ''
            }
          />
        </Col>
      </Row>
    </>
  );
};

export default XNInfo;
