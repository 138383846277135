import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import {
  Card,
  CardBody,
  CardTitle,
  Container,
  Row,
  Col,
  Input,
} from 'reactstrap';
import moment from 'moment';
import queryString from 'query-string';
import LabelCustom from 'views/pages/components/LabelCustom';
import IconWaitUpdate from 'assets/svgs/dashboard_wait_update.svg';
import { useDispatch, useSelector } from 'react-redux';
import { statisticActions } from 'redux/actions';
import { currencyFormat } from 'common';
import SelectTime from './SelectTime';
import { useHistory } from 'react-router';
function StatisticSale({}) {
  const history = useHistory();
  const dispatch = useDispatch();
  const { areasByAccountExtend } = useSelector((state) => state.areaReducer);
  const { statistic } = useSelector((state) => state.statisticReducer);
  const { currentAccountExtend } = useSelector((state) => state.accountReducer);
  const [time, setTime] = useState(0);

  useEffect(() => {
    handleGetSatistic();
  }, [time, currentAccountExtend?.id]);

  const handleGetSatistic = () => {
    if (!currentAccountExtend?.id) return;
    const query = {
      type: 'sale',
      'filters[$and][0][codeStaff1][id][$eq]': currentAccountExtend?.id,
    };
    switch (time) {
      case 0:
        query['filters[$and][1][createdAt][$gte]'] = moment()
          .startOf('date')
          .toISOString();
        query['filters[$and][2][createdAt][$lte]'] = moment()
          .endOf('date')
          .toISOString();
        break;
      case 1:
        query['filters[$and][1][createdAt][$gte]'] = moment()
          .startOf('week')
          .toISOString();
        query['filters[$and][2][createdAt][$lte]'] = moment()
          .endOf('week')
          .toISOString();
        break;
      case 2:
        query['filters[$and][1][createdAt][$gte]'] = moment()
          .startOf('month')
          .toISOString();
        query['filters[$and][2][createdAt][$lte]'] = moment()
          .endOf('month')
          .toISOString();
        break;
    }
    // areasByAccountExtend.forEach((item, index) => {
    //   query[`filters[$and][3][$or][${index}][areaName][$eq]`] = item.name;
    // });
    dispatch(statisticActions.getStatistic(queryString.stringify(query)));
  };

  return (
    <>
      <div className="header mt--9 pb-6">
        <Container fluid>
          <div className="header-body">
            <Row>
              <Col sm={12} md={6}>
                <Card
                  style={{ height: 150 }}
                  className="card-stats position-relative"
                >
                  <div
                    className="p-3 position-absolute right-0"
                    style={{ zIndex: 9999 }}
                  >
                    <SelectTime time={time} setTime={setTime} />
                  </div>
                  <CardBody>
                    <div
                      style={{ height: '100%' }}
                      className="col d-flex flex-column justify-content-center align-items-center"
                    >
                      <CardTitle
                        tag="h1"
                        className="text-uppercase text-muted mb-0"
                      >
                        Tổng số
                      </CardTitle>
                      <span className="h1 font-weight-bold mb-0">
                        {currencyFormat(statistic?.orderTotal ?? 0)}
                      </span>
                    </div>
                  </CardBody>
                </Card>
              </Col>
              <Col sm={12} md={6}>
                <Card style={{ height: 150 }} className="card-stats">
                  <CardBody>
                    <Row style={{ height: '100%' }}>
                      <div className="col">
                        <div className="pt-2">
                          <Row>
                            <div className="col">
                              <CardTitle
                                tag="h5"
                                className="text-uppercase text-muted mb-4"
                              >
                                Chờ cập nhật
                              </CardTitle>

                              <span className="h1 font-weight-bold mb-0">
                                {currencyFormat(
                                  statistic?.waitUpdateOrder ?? 0
                                )}
                              </span>
                            </div>
                          </Row>
                        </div>
                      </div>
                      <Col className="col-auto d-flex flex-column justify-content-between align-items-center">
                        <div
                          style={{ width: '5rem', height: '5rem' }}
                          className="icon icon-shape bg-gradient-red text-white rounded-circle shadow"
                        >
                          <img style={{ zoom: 0.8 }} src={IconWaitUpdate} />
                        </div>
                        <span
                          onClick={() => {
                            history.push('/admin/order-init');
                          }}
                          style={{
                            lineHeight: 1.5,
                            color: '#0a66b2',
                            cursor: 'pointer',
                          }}
                          className="text-nowrap text-underline"
                        >
                          Chi tiết {'>>'}
                        </span>
                      </Col>
                    </Row>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </div>
        </Container>
      </div>
    </>
  );
}

StatisticSale.propTypes = {
  name: PropTypes.string,
  parentName: PropTypes.string,
};

export default StatisticSale;
