import _ from 'lodash';
import { takeLatest, call, put, all } from 'redux-saga/effects';

import typeActions from 'redux/typeActions';
import ServiceURL from 'services/ServiceURL';
import { DELETE, GET, POST, PUT } from 'services/ServiceBase';
import { convertMessageError } from 'common';

export function* getServiceGroups(data) {
  const url = `${ServiceURL.serviceGroup}?filters[$and][0][deleted][$eq]=false&sort=createdAt:DESC&${data.query}`;
  const callback = data.callback;
  try {
    const res = yield call(GET, url);
    if (!!res.data?.error?.message) {
      yield put({
        type: typeActions.GET_SERVICE_GROUPS_FAILED,
        error: res.data?.error?.message,
      });
      !!callback?.failed && callback.failed(res.data.error.message);
    } else {
      // const relations = yield handleGetRelation(
      //   res.data?.results ?? [],
      //   'children'
      // );

      // const results = _.get(res, 'data.results', []);
      // const resData = {
      //   ...res.data,
      //   results: results.map((item, index) => {
      //     const children = _.get(relations, `[${index}.data.results]`, []);
      //     return { ...item, children: children.filter((v) => !v.deleted) };
      //   }),
      // };

      if (data.isUpdateReducer) {
        yield put({
          type: typeActions.GET_SERVICE_GROUPS_SUCCESS,
          data: res.data,
        });
      }
      !!callback?.success && callback.success(res.data);
    }
  } catch (error) {
    yield put({
      type: typeActions.GET_SERVICE_GROUPS_FAILED,
      error: error?.response?.data?.error?.message ?? '',
    });
    !!callback?.failed &&
      callback.failed(error?.response?.data?.error?.message ?? '');
  }
}

export function* getServiceGroup(data) {
  const url = `${ServiceURL.serviceGroup}/${data.param}?${data.query}`;
  const callback = data.callback;
  try {
    const res = yield call(GET, url);
    if (!!res.data?.error?.message) {
      yield put({
        type: typeActions.GET_SERVICE_GROUP_FAILED,
        error: res.data.error.message,
      });
      !!callback?.failed && callback.failed(res.data.error.message);
    } else {
      // const relationsC = yield handleGetRelation([res.data] ?? [], 'children');
      // const relationsS = yield handleGetRelation(
      //   [res.data] ?? [],
      //   'serviceGroup'
      // );
      // const children = _.get(relationsC, '[0].data.results', []);
      // const serviceGroup = _.get(relationsS, '[0].data.data', {});
      // const resData = {
      //   ...res.data,
      //   children,
      //   serviceGroup,
      // };
      yield put({
        type: typeActions.GET_SERVICE_GROUP_SUCCESS,
        data: res.data,
      });
      !!callback?.success && callback.success(res.data);
    }
  } catch (error) {
    yield put({
      type: typeActions.GET_SERVICE_GROUP_FAILED,
      error: error?.response?.data?.error?.message ?? '',
    });
    !!callback?.failed &&
      callback.failed(error?.response?.data?.error?.message ?? '');
  }
}

export function* createServiceGroup(data) {
  const url = `${ServiceURL.serviceGroup}`;
  const callback = data.callback;
  try {
    const res = yield call(POST, url, data.body);
    if (!!res.data?.error?.message) {
      yield put({
        type: typeActions.CREATE_SERVICE_GROUP_FAILED,
        error: res.data.error.message,
      });
      !!callback?.failed && callback.failed(res.data.error.message);
    } else {
      yield put({
        type: typeActions.CREATE_SERVICE_GROUP_SUCCESS,
        data: res.data?.data,
      });
      !!callback?.success && callback.success(res.data?.data);
    }
  } catch (error) {
    const message = convertMessageError('c', error, ' nhóm dịch vụ ');
    yield put({
      type: typeActions.CREATE_SERVICE_GROUP_FAILED,
      error: message,
    });
    !!callback?.failed && callback.failed(message);
  }
}

export function* updateServiceGroup(data) {
  const url = `${ServiceURL.serviceGroup}/${data.param}`;
  const callback = data.callback;
  try {
    const res = yield call(PUT, url, data.body);
    if (!!res.data?.error?.message) {
      yield put({
        type: typeActions.UPDATE_SERVICE_GROUP_FAILED,
        error: res.data.error.message,
      });
      !!callback?.failed && callback.failed(res.data.error.message);
    } else {
      yield put({
        type: typeActions.UPDATE_SERVICE_GROUP_SUCCESS,
        data: res.data,
      });

      !!callback?.success && callback.success(res.data);
    }
  } catch (error) {
    yield put({
      type: typeActions.UPDATE_SERVICE_GROUP_FAILED,
      error: error?.response?.data?.error?.message ?? '',
    });
    !!callback?.failed &&
      callback.failed(error?.response?.data?.error?.message ?? '');
  }
}

export function* deleteServiceGroup(data) {
  const url = `${ServiceURL.serviceGroup}/${data.param}`;
  const callback = data.callback;
  try {
    const res = yield call(DELETE, url);
    if (!!res.data?.error?.message) {
      yield put({
        type: typeActions.DELETE_SERVICE_GROUP_FAILED,
        error: res.data.error.message,
      });
      !!callback?.failed && callback.failed(res.data.error.message);
    } else {
      yield put({
        type: typeActions.DELETE_SERVICE_GROUP_SUCCESS,
        data: res.data,
      });
      !!callback?.success && callback.success(res.data);
    }
  } catch (error) {
    yield put({
      type: typeActions.DELETE_SERVICE_GROUP_FAILED,
      error: error?.response?.data?.error?.message ?? '',
    });
    !!callback?.failed &&
      callback.failed(error?.response?.data?.error?.message ?? '');
  }
}

export function* getServiceGroupByIds(data) {
  const callback = data.callback;
  const apiGetByIds = data.ids.map((item) => {
    const url = `${ServiceURL.serviceGroup}/${item}?${data.query}`;
    return call(GET, url);
  });
  let res = [];
  try {
    res = yield all(apiGetByIds);
    const results = res.map((item) => {
      return item.data;
    });
    const relations = yield handleGetRelation(results ?? [], data.relationTo);
    const resData = results.map((item, index) => {
      const children = _.get(relations, `[${index}].data.results`, []);
      return {
        ...item,
        children,
      };
    });
    !!callback?.success && callback.success(resData);
  } catch (error) {}
}

function* handleGetRelation(results, relationTo) {
  const apiRelations =
    results.map((item) => {
      const urlRelation = `${ServiceURL.relation}/api::service-group.service-group/${item.id}/${relationTo}?pageSize=100`;
      return call(GET, urlRelation);
    }) ?? [];
  try {
    return yield all(apiRelations);
  } catch (error) {
    return [];
  }
}

export default function* serviceGroupSaga() {
  yield takeLatest(typeActions.GET_SERVICE_GROUPS_REQUEST, getServiceGroups);
  yield takeLatest(typeActions.GET_SERVICE_GROUP_REQUEST, getServiceGroup);
  yield takeLatest(
    typeActions.CREATE_SERVICE_GROUP_REQUEST,
    createServiceGroup
  );
  yield takeLatest(
    typeActions.UPDATE_SERVICE_GROUP_REQUEST,
    updateServiceGroup
  );
  yield takeLatest(
    typeActions.DELETE_SERVICE_GROUP_REQUEST,
    deleteServiceGroup
  );
  yield takeLatest(
    typeActions.GET_SERVICE_GROUP_BY_IDS_REQUEST,
    getServiceGroupByIds
  );
}
