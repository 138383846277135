import React from 'react';
// node.js library that concatenates classes (strings)
import classnames from 'classnames';
// javascipt plugin for creating charts
import Chart from 'chart.js';
// react plugin used to create charts
import { Line, Bar } from 'react-chartjs-2';
// reactstrap components
import {
  Badge,
  Button,
  Card,
  CardHeader,
  CardBody,
  DropdownMenu,
  DropdownItem,
  DropdownToggle,
  UncontrolledDropdown,
  Form,
  Input,
  ListGroupItem,
  ListGroup,
  Media,
  NavItem,
  NavLink,
  Nav,
  Progress,
  Table,
  Container,
  Row,
  Col,
  UncontrolledTooltip,
} from 'reactstrap';

// core components
import CardsHeader from 'components/Headers/CardsHeader.js';

import {
  chartOptions,
  parseOptions,
  chartExample1,
  chartExample2,
} from 'variables/charts.js';
import { useSelector } from 'react-redux';
import SatisticAdmin from './components/SatisticAdmin';
import SatisticSampleCollectionStaff from './components/SatisticSampleCollectionStaff';
import StatisticSale from './components/SatisticSale';
import SatisticAccountant from './components/SatisticAccountant';
import SatisticDoctor from './components/SatisticDoctor';

function Dashboard() {
  const {
    isAdmin,
    isSampleCollectorStaff,
    isBusinessStaff,
    isAccountantStaff,
    isDoctorKH,
    isDoctorHT,
  } = useSelector((state) => state.accountReducer);
  const [activeNav, setActiveNav] = React.useState(1);
  const [chartExample1Data, setChartExample1Data] = React.useState('data1');
  const toggleNavs = (e, index) => {
    e.preventDefault();
    setActiveNav(index);
    setChartExample1Data(chartExample1Data === 'data1' ? 'data2' : 'data1');
  };
  if (window.Chart) {
    parseOptions(Chart, chartOptions());
  }
  if (isAdmin || isDoctorHT) return <SatisticAdmin />;
  if (isSampleCollectorStaff) return <SatisticSampleCollectionStaff />;
  if (isBusinessStaff) return <StatisticSale />;
  if (isAccountantStaff) return <SatisticAccountant />;
  if (isDoctorKH) return <SatisticDoctor />;
  return null;
}

export default Dashboard;
