import _ from 'lodash';
import { takeLatest, call, put } from 'redux-saga/effects';

import typeActions from 'redux/typeActions';
import ServiceURL from 'services/ServiceURL';
import { DELETE, GET, GET_FILE, POST, PUT } from 'services/ServiceBase';
import { convertMessageError } from 'common';

export function* getServices(data) {
  const url = `${ServiceURL.service}?filters[$and][0][deleted][$eq]=false&sort=createdAt:DESC&${data.query}`;
  const callback = data.callback;
  try {
    const res = yield call(GET, url);
    if (!!res.data?.error?.message) {
      yield put({
        type: typeActions.GET_SERVICES_FAILED,
        error: res.data?.error?.message,
      });
      !!callback?.failed && callback.failed(res.data.error.message);
    } else {
      if (data.isUpdateReducer) {
        yield put({
          type: typeActions.GET_SERVICES_SUCCESS,
          data: res.data,
        });
      }

      !!callback?.success && callback.success(res.data);
    }
  } catch (error) {
    yield put({
      type: typeActions.GET_SERVICES_FAILED,
      error: error?.response?.data?.error?.message ?? '',
    });
    !!callback?.failed &&
      callback.failed(error?.response?.data?.error?.message ?? '');
  }
}

export function* getService(data) {
  const url = `${ServiceURL.service}/${data.param}?${data.query}`;
  const callback = data.callback;
  try {
    const res = yield call(GET, url);
    if (!!res.data?.error?.message) {
      yield put({
        type: typeActions.GET_SERVICE_FAILED,
        error: res.data.error.message,
      });
      !!callback?.failed && callback.failed(res.data.error.message);
    } else {
      yield put({
        type: typeActions.GET_SERVICE_SUCCESS,
        data: res.data,
      });
      !!callback?.success && callback.success(res.data);
    }
  } catch (error) {
    yield put({
      type: typeActions.GET_SERVICE_FAILED,
      error: error?.response?.data?.error?.message ?? '',
    });
    !!callback?.failed &&
      callback.failed(error?.response?.data?.error?.message ?? '');
  }
}

export function* createService(data) {
  const url = `${ServiceURL.service}`;
  const callback = data.callback;
  try {
    const res = yield call(POST, url, data.body);
    if (!!res.data?.error?.message) {
      yield put({
        type: typeActions.CREATE_SERVICE_FAILED,
        error:
          res.data.error.response.status === 400
            ? 'Mã dịch vụ đã tồn tại. Vui lòng nhập mã khác!'
            : res.data.error.message,
      });
      !!callback?.failed && callback.failed(res.data.error.message);
    } else {
      yield put({
        type: typeActions.CREATE_SERVICE_SUCCESS,
        data: res.data?.data,
      });
      !!callback?.success && callback.success(res.data?.data);
    }
  } catch (error) {
    const message = convertMessageError('c', error, ' dịch vụ ');
    yield put({
      type: typeActions.CREATE_SERVICE_FAILED,
      error: message,
    });
    !!callback?.failed && callback.failed(message);
  }
}

export function* updateService(data) {
  const url = `${ServiceURL.service}/${data.param}`;
  const callback = data.callback;
  try {
    const res = yield call(PUT, url, data.body);
    if (!!res.data?.error?.message) {
      yield put({
        type: typeActions.UPDATE_SERVICE_FAILED,
        error: res.data.error.message,
      });
      !!callback?.failed && callback.failed(res.data.error.message);
    } else {
      yield put({
        type: typeActions.UPDATE_SERVICE_SUCCESS,
        data: res.data,
      });

      !!callback?.success && callback.success(res.data);
    }
  } catch (error) {
    yield put({
      type: typeActions.UPDATE_SERVICE_FAILED,
      error: error?.response?.data?.error?.message ?? '',
    });
    !!callback?.failed &&
      callback.failed(error?.response?.data?.error?.message ?? '');
  }
}

export function* deleteService(data) {
  const url = `${ServiceURL.service}/${data.param}`;
  const callback = data.callback;
  try {
    const res = yield call(DELETE, url);
    if (!!res.data?.error?.message) {
      yield put({
        type: typeActions.DELETE_SERVICE_FAILED,
        error: res.data.error.message,
      });
      !!callback?.failed && callback.failed(res.data.error.message);
    } else {
      yield put({
        type: typeActions.DELETE_SERVICE_SUCCESS,
        data: res.data,
      });
      !!callback?.success && callback.success(res.data);
    }
  } catch (error) {
    yield put({
      type: typeActions.DELETE_SERVICE_FAILED,
      error: error?.response?.data?.error?.message ?? '',
    });
    !!callback?.failed &&
      callback.failed(error?.response?.data?.error?.message ?? '');
  }
}

export function* getServiceBy(data) {
  const url = `${ServiceURL.service}?${data.query}`;
  const callback = data.callback;
  try {
    const res = yield call(GET, url);
    if (!!res.data?.error?.message) {
      yield put({
        type: typeActions.GET_SERVICE_BY_FAILED,
        error: res.data.error.message,
      });
      !!callback?.failed && callback.failed(res.data.error.message);
    } else {
      const id = _.get(res.data, 'results[0].id', -1);

      const urlRelation = `${ServiceURL.contentXn}?filters[$and][0][services][id]=${id}&pageSize=99999`;

      let relations = [];
      try {
        relations = yield call(GET, urlRelation);
      } catch (error) { }
      const resData = {
        ..._.get(res.data, 'results[0]', {}),
        contentXns: relations.data?.results ?? [],
      };
      yield put({
        type: typeActions.GET_SERVICE_BY_SUCCESS,
        data: resData,
      });
      !!callback?.success && callback.success(resData);
    }
  } catch (error) {
    yield put({
      type: typeActions.GET_SERVICE_BY_FAILED,
      error: error?.response?.data?.error?.message ?? '',
    });
    !!callback?.failed &&
      callback.failed(error?.response?.data?.error?.message ?? '');
  }
}

export function* exportServiceGroups(data) {
  const url = `${ServiceURL.exportServiceGroups}?${data.query}`;
  const callback = data.callback;
  try {
    const res = yield call(GET_FILE, url);
    if (!!res.data?.error?.message) {
      yield put({
        type: typeActions.EXPORT_SERVICE_GROUP_FAILED,
        error: res.data?.error?.message,
      });
      !!callback?.failed && callback.failed(res.data.error.message);
    } else {
      yield put({
        type: typeActions.EXPORT_SERVICE_GROUP_SUCCESS,
        data: res.data,
      });

      !!callback?.success && callback.success(res.data);
    }
  } catch (error) {
    yield put({
      type: typeActions.EXPORT_SERVICE_GROUP_FAILED,
      error: error?.response?.data?.error?.message ?? '',
    });
    !!callback?.failed &&
      callback.failed(error?.response?.data?.error?.message ?? '');
  }
}
export function* exportServices(data) {
  const url = `${ServiceURL.exportServices}?${data.query}`;
  const callback = data.callback;
  try {
    const res = yield call(GET_FILE, url);
    if (!!res.data?.error?.message) {
      yield put({
        type: typeActions.EXPORT_SERVICE_FAILED,
        error: res.data?.error?.message,
      });
      !!callback?.failed && callback.failed(res.data.error.message);
    } else {
      yield put({
        type: typeActions.EXPORT_SERVICE_SUCCESS,
        data: res.data,
      });

      !!callback?.success && callback.success(res.data);
    }
  } catch (error) {
    yield put({
      type: typeActions.EXPORT_SERVICE_FAILED,
      error: error?.response?.data?.error?.message ?? '',
    });
    !!callback?.failed &&
      callback.failed(error?.response?.data?.error?.message ?? '');
  }
}

export default function* serviceSaga() {
  yield takeLatest(typeActions.GET_SERVICES_REQUEST, getServices);
  yield takeLatest(typeActions.GET_SERVICE_REQUEST, getService);
  yield takeLatest(typeActions.CREATE_SERVICE_REQUEST, createService);
  yield takeLatest(typeActions.UPDATE_SERVICE_REQUEST, updateService);
  yield takeLatest(typeActions.DELETE_SERVICE_REQUEST, deleteService);
  yield takeLatest(typeActions.GET_SERVICE_BY_REQUEST, getServiceBy);
  yield takeLatest(typeActions.EXPORT_SERVICE_GROUP_REQUEST, exportServiceGroups);
  yield takeLatest(typeActions.EXPORT_SERVICE_REQUEST, exportServices);
}
