import React, { memo, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Button, Col, Form, Modal, Row } from 'reactstrap';
import * as yup from 'yup';
import { Formik } from 'formik';
import _ from 'lodash';
import queryString from 'query-string';

import { notificationAlertRef, notify, relationOneToOne } from 'common';
import { areaActions, formActions } from 'redux/actions';
import LoadingButtonCustom from 'views/pages/components/LoadingButtonCustom';
import CONSTANT from 'constant';
import InputCustom from 'views/pages/components/InputCustom';
import SelectCustom from 'views/pages/components/SelectCustom';
import { useLocation, useParams } from 'react-router';

const CService = ({ handleGetArea }) => {
  const location = useLocation();
  const { id } = useParams();
  const search = new URLSearchParams(location.search);
  const dispatch = useDispatch();

  // reducers
  const { isModalVisible, modalInfo } = useSelector(
    (state) => state.formReducer
  );
  const { isCreatingService, isUpdatingService } = useSelector(
    (state) => state.areaReducer
  );
  const [areas, setAreas] = useState([]);

  const isUpdate = !_.isEmpty(modalInfo) && !modalInfo?.isAddAction;
  // Khởi tạo dữ liêu
  const initInfo = {
    code: modalInfo?.code ?? '',
    name: modalInfo?.name ?? '',
    parent:
      modalInfo?.parent?.id || modalInfo?.isAddAction
        ? modalInfo?.parent?.id
        : parseInt(id) ?? null,
    descriptions: modalInfo?.descriptions ?? '',
  };
  const [areaValue, setAreaValue] = useState(null);
  const [areaSearch, setAreaSearch] = useState('');

  // validate dữ liêu
  const infoSchema = yup.object().shape({
    code: yup.string().required('Mã khu vực không được để trống!'),
    name: yup.string().required('Tên khu vực không được để trống!'),
    // descriptions: yup.string().required('Mô tả khu vực không được để trống!'),
  });

  // Xử lý submit
  const onSubmit = (values) => {
    const body = {
      code: values.code,
      name: values.name,
      parent: relationOneToOne(values.parent, null),
      descriptions: values.descriptions,
      isOrigin: modalInfo?.isOrigin || values.parent === null,
    };

    if (modalInfo?.isOrigin) {
      delete body.parent;
    }

    if (isUpdate) {
      // update
      dispatch(
        areaActions.updateArea(modalInfo.id, body, {
          success: () => {
            handleGetArea();
            notify(
              notificationAlertRef,
              'success',
              'Thông báo',
              `Cập nhật khu vực thành công.`
            );
            handleClose();
          },
          failed: (mess) => {
            notify(
              notificationAlertRef,
              'danger',
              'Thông báo',
              `Cập nhật khu vực thất bại!. Lỗi: ${mess}!`
            );
          },
        })
      );
    } else {
      // create
      dispatch(
        areaActions.createArea(body, {
          success: () => {
            handleGetArea();
            notify(
              notificationAlertRef,
              'success',
              'Thông báo',
              `Thêm mới khu vực thành công.`
            );
            handleClose();
          },
          failed: (mess) => {
            notify(
              notificationAlertRef,
              'danger',
              'Thông báo',
              `Thêm mới khu vực thất bại!. Lỗi: ${mess}!`
            );
          },
        })
      );
    }
  };

  // Xử lý đóng form
  const handleClose = () => {
    dispatch(
      formActions.setValue({
        name: 'isModalVisible',
        value: false,
      })
    );
    dispatch(
      formActions.setValue({
        name: 'modalInfo',
        value: {},
      })
    );
  };

  // Lấy danh sách areas
  const handleGetAreas = () => {
    const query = {};
    if (areaSearch !== '') {
      query['filters[$and][1][code][$containsi]'] = areaSearch;
    }
    dispatch(
      areaActions.getAreas(
        queryString.stringify(query),
        {
          success: (data) => {
            const results = _.get(data, 'results', []);
            setAreas(results);
          },
        },
        false
      )
    );
  };

  useEffect(() => {
    // Sau 500ms thì bắn api
    const delayDebounceFn = setTimeout(() => {
      isModalVisible && handleGetAreas();
    }, 500);
    return () => clearTimeout(delayDebounceFn);
  }, [areaSearch]);

  useEffect(() => {
    if (isUpdate) {
      if (!!modalInfo?.parent?.count) {
        setAreaValue({
          label: search.get('code'),
          value: id,
        });
      } else if (!!modalInfo?.parent?.id) {
        setAreaValue({
          label: modalInfo.parent.code,
          value: modalInfo.parent.id,
        });
      }
    } else {
      if (modalInfo?.isAddAction) {
        setAreaValue({
          label: modalInfo?.parent.code,
          value: modalInfo?.parent.id,
        });
      } else {
        setAreaValue(
          modalInfo?.isOrigin
            ? null
            : {
                label: search.get('code'),
                value: id,
              }
        );
      }
    }
  }, [isUpdate, modalInfo]);
  return (
    <Modal
      size={'md'}
      isOpen={isModalVisible}
      className="modal-dialog-centered modal-secondary"
      toggle={() => {
        handleClose();
      }}
    >
      <div className="modal-header border-bottom bg-white pb-2">
        <h2>{isUpdate ? 'Cập nhật' : 'Thêm mới'} khu vực</h2>
        <button
          aria-label="Close"
          className="close"
          data-dismiss="modal"
          type="button"
          onClick={() => {
            handleClose();
          }}
        >
          <span style={{ color: '#262626' }} aria-hidden={true}>
            ×
          </span>
        </button>
      </div>
      <div className="modal-body bg-white rounded">
        <Formik
          initialValues={initInfo}
          // enableReinitialize
          onSubmit={onSubmit}
          validationSchema={infoSchema}
        >
          {({
            values,
            handleSubmit,
            errors,
            touched,
            handleBlur,
            handleChange,
            setFieldValue,
          }) => {
            return (
              <Form onSubmit={handleSubmit} className="">
                <Row>
                  <Col xs={6}>
                    <InputCustom
                      name="code"
                      label="Mã khu vực"
                      type="text"
                      placeholder="Nhập mã khu vực"
                      value={values.code}
                      isRequired={true}
                      handleChange={handleChange}
                      handleBlur={handleBlur}
                      error={errors?.code}
                      touched={touched?.code}
                      disabled={isUpdate}
                    />
                  </Col>
                  <Col xs={6}>
                    <InputCustom
                      name="name"
                      label="Tên khu vực"
                      type="text"
                      placeholder="Nhập tên khu vực"
                      value={values.name}
                      isRequired={true}
                      handleChange={handleChange}
                      handleBlur={handleBlur}
                      error={errors?.name}
                      touched={touched?.name}
                    />
                  </Col>
                </Row>
                <Row>
                  <Col xs={12}>
                    <SelectCustom
                      name="parent"
                      label="Cấp độ cha"
                      placeholder="Lựa chọn"
                      value={areaValue}
                      isRequired={false}
                      isClearable={true}
                      handleChange={(e) => {
                        setAreaValue(e);
                        setFieldValue('parent', e?.value ?? null);
                      }}
                      isDisabled={
                        // modalInfo?.isAddAction || _.isEmpty(modalInfo)
                        true
                      }
                      handleBlur={handleBlur}
                      error={errors.parent}
                      touched={touched.parent}
                      options={areas.map((item) => ({
                        label: item?.code ?? '',
                        value: item?.id ?? -1,
                      }))}
                      onInputChange={(value) => {
                        setAreaSearch(value);
                      }}
                    />
                  </Col>
                </Row>

                <Row>
                  <Col xs={12}>
                    <InputCustom
                      name="descriptions"
                      label="Mô tả"
                      type="textarea"
                      rows={4}
                      placeholder="Nhập nội dung"
                      value={values.descriptions}
                      isRequired={false}
                      handleChange={handleChange}
                      handleBlur={handleBlur}
                      error={errors?.descriptions}
                      touched={touched?.descriptions}
                    />
                  </Col>
                </Row>

                <div className="mb-0 pb-0 mt-4 d-flex justify-content-end">
                  <Button
                    className="text-nowrap mr-1"
                    onClick={() => {
                      handleClose();
                    }}
                  >
                    Hủy
                  </Button>
                  <LoadingButtonCustom
                    onClick={handleSubmit}
                    color="info"
                    className="text-nowrap "
                    type="submit"
                    loading={isCreatingService || isUpdatingService}
                  >
                    {isUpdate ? 'Cập nhật' : 'Thêm mới'}
                  </LoadingButtonCustom>
                </div>
              </Form>
            );
          }}
        </Formik>
      </div>
    </Modal>
  );
};

export default memo(CService);
