import React, { memo, useEffect, useRef, useState } from 'react';
import _ from 'lodash';
import { Card, CardBody, CardHeader, Col, Row } from 'reactstrap';
import { useDispatch, useSelector } from 'react-redux';
import queryString from 'query-string';

import 'assets/css/custom-pages/react-bs-table.css';
import InputCustom from 'views/pages/components/InputCustom';
// import SelectCustom from 'views/pages/components/SelectCustom';
import { barcodeActions, orderActions } from 'redux/actions';
import CONSTANT from 'constant';
import LabelValue from 'views/pages/components/LabelValue';
import SwitchCustom from 'views/pages/components/SwitchCustom';
import LabelCustom from 'views/pages/components/LabelCustom';

const OrderInfo = ({
  note,
  setNote,
  statusValue,
  setStatusValue,
  barcode,
  // barcodeValue,
  // setBarcodeValue,
}) => {
  const { order } = useSelector((state) => state.orderReducer);
  const dispatch = useDispatch();
  const inputRef = useRef(null);
  // const [barCodeSearch, setBarcodeSearch] = useState('');

  // const [barcodeResults, setBarcodeResults] = useState([]);

  // get barcodes
  // useEffect(() => {
  //   const delayDebounceFn = setTimeout(() => {
  //     handleGetBarcodes();
  //   }, 500);

  //   return () => clearTimeout(delayDebounceFn);
  // }, [barCodeSearch]);

  // const handleGetBarcodes = () => {
  // const query = {
  //   'filters[$and][0][status][$eq]': true,
  // };
  // if (barCodeSearch !== '') {
  //   query['filters[$and][1][barcode][$containsi]'] = barCodeSearch;
  // }
  // dispatch(
  //   barcodeActions.getBarcodes(
  //     queryString.stringify(query),
  //     {
  //       success: (data) => {
  //         const results = _.get(data, 'results', []);
  //         setBarcodeResults(results);
  //       },
  //       failed: () => {},
  //     },
  //     false
  //   )
  // );
  // };

  const handleGetOrderBy = (value) => {
    // Là đơn có barcode đã sử dụng, đơn đã thanh toán, khác đơn hủy và khác đơn đã trả
    dispatch(
      orderActions.getOrderBy(
        queryString.stringify({
          'filters[$or][1][barcode1][$eq]': value,
          'filters[$or][2][barcode2][$eq]': value,
          'filters[$or][3][barcode3][$eq]': value,
          'filters[$or][4][barcode4][$eq]': value,
          'filters[$or][5][barcode5][$eq]': value,
          'filters[$and][6][status][$ne]': CONSTANT.ORDER_STATUS[0].value,
          'filters[$and][7][status][$ne]': CONSTANT.ORDER_STATUS[11].value,
          'filters[$and][8][receptionStatus][$eq]': false,
          // 'filters[$and][4][bill][paymentStatus][$eq]':
          //   CONSTANT.PAYMENT_STATUS[1].value,
        })
      )
    );
  };

  useEffect(() => {
    if (!!inputRef?.current) {
      inputRef.current.focus();
    }
  }, [inputRef]);

  useEffect(() => {
    if (barcode === '') return;
    handleGetOrderBy(barcode);
  }, [barcode]);

  return (
    <Card>
      <CardHeader className="pb-2 d-flex justify-content-between rounded border-bottom">
        <h3>Đơn hàng</h3>
      </CardHeader>
      <CardBody>
        <Row>
          <Col className="border-right" xs={12} md={12} lg={6}>
            <Row>
              <Col xs={12} md={12} lg={10}>
                {/* <SelectCustom
                  autoFocus={true}
                  name="barcode1"
                  label="Mã Barcode"
                  placeholder="Lựa chọn"
                  value={barcodeValue}
                  isRequired={true}
                  isClearable={true}
                  onKeyDown={(e) => {
                    if (e.key === 'Enter') {
                      setBarcodeValue({ label: e.target.value, value: -1 });
                      handleGetOrderBy(e.target.value);
                    }
                  }}
                  handleChange={(e) => {
                    setBarcodeValue(e);
                    handleGetOrderBy(e?.label ?? '');
                  }}
                  onInputChange={(value) => {
                    setBarcodeSearch(value);
                  }}
                  options={barcodeResults.map((item) => ({
                    label: item?.barcode ?? '',
                    value: item?.id,
                  }))}
                  row
                  labelWidth={180}
                /> */}
                <InputCustom
                  row
                  labelWidth={180}
                  name="barcodePcd"
                  label="Mã barcode"
                  type="text"
                  placeholder="Quét barcode phiếu xét nghiệm"
                  value={barcode}
                  disabled={true}
                />
              </Col>
            </Row>
            <Row>
              <Col xs={12} md={12}>
                <div className="w-50">
                  <LabelValue
                    labelWidth={180}
                    label="Mã đơn hàng"
                    value={order?.code ?? ''}
                  />
                </div>
              </Col>
            </Row>
            <Row>
              <Col xs={12} md={12} lg={12}>
                <LabelValue
                  labelWidth={180}
                  label="Tên đơn hàng"
                  value={order?.name ?? ''}
                />
              </Col>
            </Row>
            <Row>
              <Col xs={12} md={12} lg={12}>
                <LabelValue
                  labelWidth={180}
                  label="Phòng khám/Bệnh viện"
                  value={order?.organization?.name ?? ''}
                />
              </Col>
            </Row>
            <Row>
              <Col xs={12} md={12} lg={12}>
                <LabelValue
                  labelWidth={180}
                  label="Số tiền"
                  value={order?.amountMoney ?? ''}
                />
              </Col>
            </Row>
            <Row>
              <Col xs={12} md={12} lg={6}>
                <LabelValue
                  labelWidth={180}
                  label="Người thu tiền"
                  value={`${order?.codeStaff3?.fullname ?? ''}`}
                />
              </Col>
            </Row>
            <Row>
              <Col xs={12} md={12} lg={12}>
                <LabelValue
                  labelWidth={180}
                  label="Nhân viên thu mẫu"
                  value={`${order?.codeStaff2?.fullname ?? ''}`}
                />
              </Col>
            </Row>
            <Row>
              <Col xs={12} md={12} lg={12}>
                <LabelValue
                  labelWidth={180}
                  label="Nhân viên phụ trách"
                  value={`${order?.codeStaff1?.fullname ?? ''}`}
                />
              </Col>
            </Row>
            <Row>
              <Col xs={12} md={12} lg={12}>
                <LabelValue
                  labelWidth={180}
                  label="Mã Barcode"
                  value={order?.barcode1 ?? ''}
                />
              </Col>
            </Row>
            <Row>
              <Col xs={12} md={12} lg={6}>
                <LabelValue
                  labelWidth={180}
                  label="Mã Barcode mẫu 1"
                  value={order?.barcode2 ?? ''}
                />
              </Col>
              <Col xs={12} md={12} lg={6}>
                <LabelValue
                  labelWidth={180}
                  label="Mã Barcode mẫu 2"
                  value={order?.barcode3 ?? ''}
                />
              </Col>
            </Row>
            <Row>
              <Col xs={12} md={12} lg={6}>
                <LabelValue
                  labelWidth={180}
                  label="Mã Barcode mẫu 3"
                  value={order?.barcode4 ?? ''}
                />
              </Col>
              <Col xs={12} md={12} lg={6}>
                <LabelValue
                  labelWidth={180}
                  label="Mã Barcode mẫu 4"
                  value={order?.barcode5 ?? ''}
                />
              </Col>
            </Row>
          </Col>

          <Col xs={12} md={12} lg={6}>
            <Row>
              <Col className="d-flex align-items-center" xs={12} md={12} lg={8}>
                {/* <SelectCustom
                  name="status"
                  label="Trạng thái"
                  placeholder="Lựa chọn"
                  value={statusValue}
                  isRequired={true}
                  isClearable={false}
                  handleChange={(e) => {
                    setStatusValue(e);
                    if (e.value === 4) {
                      setNote('');
                    }
                  }}
                  options={[CONSTANT.ORDER_STATUS[2], CONSTANT.ORDER_STATUS[4]]}
                  row
                  labelWidth={80}
                /> */}
                <LabelCustom
                  isRequired={false}
                  label={'Yêu cầu cập nhật'}
                  id={''}
                  style={{
                    whiteSpace: 'nowrap',
                    margin: '0px 8px 0px 0px',
                  }}
                  styleLabel={{
                    minWidth: 130,
                  }}
                />
                <SwitchCustom
                  className={`custom-toggle custom-toggle-primary mr-1 toggle-update ${
                    statusValue?.value === CONSTANT.ORDER_STATUS[2].value &&
                    'toggle-before'
                  }`}
                  value="update"
                  labelOff="Không"
                  labelOn="Có"
                  checked={
                    statusValue?.value === CONSTANT.ORDER_STATUS[2].value
                  }
                  onChange={(e) => {
                    setStatusValue({
                      label: '',
                      value: e.target.checked
                        ? CONSTANT.ORDER_STATUS[2].value
                        : null,
                    });
                  }}
                />
              </Col>
            </Row>
            {statusValue?.value === CONSTANT.ORDER_STATUS[2].value && (
              <Row>
                <Col xs={12} md={12} lg={12}>
                  <InputCustom
                    name="note"
                    label="Lý do yêu cầu cập nhật"
                    type="textarea"
                    placeholder="Nhập vào lý do"
                    value={note}
                    isRequired={false}
                    handleChange={(e) => {
                      setNote(e.target.value);
                    }}
                    styleInput={{
                      minHeight: 300,
                    }}
                    // handleBlur={handleBlur}
                    // error={errors?.amountMoney}
                    // touched={touched?.amountMoney}
                    // disabled={isDisabled}
                  />
                </Col>
              </Row>
            )}
          </Col>
        </Row>
      </CardBody>
    </Card>
  );
};

export default memo(OrderInfo);
