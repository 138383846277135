import React, { memo, useEffect, useState } from 'react';
import _ from 'lodash';
import { CardHeader, Col, Row } from 'reactstrap';
import 'assets/css/custom-pages/react-bs-table.css';
import LabelValue from 'views/pages/components/LabelValue';
import { useSelector } from 'react-redux';
import moment from 'moment';
import CONSTANT from 'constant';

const NXNInfo = ({}) => {
  const { order } = useSelector((state) => state.orderReducer);
  const typePregnancyValue = CONSTANT.TYPE_PREGNANCY.find(
    (item) => item.value === order?.specifyVote?.typePregnancy
  );
  const renderTestGroup = () => {
    switch (order?.specifyVote?.testGR) {
      case 0:
        return (
          <>
            <Row>
              <Col xs={12}>
                <LabelValue
                  labelWidth={160}
                  label="Tuổi thai(tuần)"
                  value={
                    order?.specifyVote?.weekPregnancy === 0
                      ? ''
                      : order?.specifyVote?.weekPregnancy
                  }
                />
              </Col>
            </Row>
            <Row>
              <Col xs={6}>
                <LabelValue
                  labelWidth={160}
                  label="Chiều cao (cm)"
                  value={
                    order?.specifyVote?.height1 === 0
                      ? ''
                      : order?.specifyVote?.height1
                  }
                />
              </Col>
              <Col xs={6}>
                <LabelValue
                  labelWidth={160}
                  label="Cân nặng (g)"
                  value={
                    order?.specifyVote?.weight1 === 0
                      ? ''
                      : order?.specifyVote?.weight1
                  }
                />
              </Col>
            </Row>
            <Row>
              <Col xs={6}>
                <LabelValue
                  labelWidth={160}
                  label="Số lượng thai"
                  value={typePregnancyValue?.label ?? ''}
                />
              </Col>
              <Col xs={6}>
                <LabelValue
                  labelWidth={160}
                  label="Độ mờ da gáy"
                  value={
                    order?.specifyVote?.nappySkin === 0
                      ? ''
                      : order?.specifyVote?.nappySkin
                  }
                />
              </Col>
            </Row>
            <Row>
              <Col xs={12}>
                <LabelValue
                  labelWidth={300}
                  label="Kết quả nguy cơ của combined test"
                  value={order?.specifyVote?.result3 ?? ''}
                />
              </Col>
            </Row>
            <Row>
              <Col xs={12}>
                <LabelValue
                  labelWidth={300}
                  label="Kết quả siêu âm"
                  value={order?.specifyVote?.result4 ?? ''}
                />
              </Col>
            </Row>
          </>
        );
      case 1:
        return (
          <>
            <Row>
              <Col xs={12} md={12}>
                <LabelValue
                  labelWidth={160}
                  label={'Ngày sinh thiết'}
                  value={
                    !!order?.specifyVote?.biopsyDay
                      ? moment(order?.specifyVote?.biopsyDay).format(
                          'DD/MM/YYYY'
                        )
                      : ''
                  }
                />
              </Col>
            </Row>
            <Row>
              <Col xs={12} md={12}>
                <LabelValue
                  labelWidth={160}
                  label="Dung dịch chứa tế bào"
                  value={order?.specifyVote?.cellSolution ?? ''}
                />
              </Col>
            </Row>
            <Row>
              <Col xs={12} md={12}>
                <LabelValue
                  labelWidth={160}
                  label="Số phôi tạo ra"
                  value={
                    order?.specifyVote?.numOfEmbryos1 === 0
                      ? ''
                      : order?.specifyVote?.numOfEmbryos1
                  }
                />
              </Col>
            </Row>
            <Row>
              <Col xs={12} md={6}>
                <LabelValue
                  labelWidth={160}
                  label="Số thứ tự"
                  value={
                    !!order?.specifyVote?.noPhoi
                      ? order?.specifyVote?.noPhoi + 1
                      : ''
                  }
                />
              </Col>
              <Col xs={12} md={6}>
                <LabelValue
                  labelWidth={160}
                  label="Trạng thái phôi"
                  value={order?.specifyVote?.embryoState ?? ''}
                />
              </Col>
            </Row>
            <Row>
              <Col xs={12} md={6}>
                <LabelValue
                  labelWidth={160}
                  label="Đánh giá hình thái phôi"
                  value={order?.specifyVote?.embryoEvaluation ?? ''}
                />
              </Col>
            </Row>
            <Row>
              <Col xs={12} className={'d-flex align-items-center'}>
                <LabelValue
                  labelWidth={160}
                  label="Nhân tế bào"
                  value={order?.specifyVote?.cellNucleus ? 'Có' : 'Không'}
                />
              </Col>
            </Row>
            <Row>
              <Col xs={12} md={6}>
                <LabelValue
                  labelWidth={160}
                  label="Đối chứng âm"
                  value={order?.specifyVote?.negativeControl ?? ''}
                />
              </Col>
            </Row>
          </>
        );
      case 2:
        return (
          <>
            <Row>
              <Col xs={12} md={12}>
                <LabelValue
                  labelWidth={160}
                  label="Triệu chứng"
                  value={order?.specifyVote?.symptom ?? ''}
                />
              </Col>
            </Row>
            <Row>
              <Col xs={12} md={12}>
                <LabelValue
                  labelWidth={160}
                  label="Chẩn đoán"
                  value={order?.specifyVote?.diagnose ?? ''}
                />
              </Col>
            </Row>
            <Row>
              <Col xs={12} md={12}>
                <LabelValue
                  labelWidth={160}
                  label="Chẩn đoán hình ảnh"
                  value={order?.specifyVote?.imageAnalysation ?? ''}
                />
              </Col>
            </Row>
            <Row>
              <Col xs={12} md={12}>
                <LabelValue
                  labelWidth={160}
                  label="Xét nghiệm liên quan"
                  value={order?.specifyVote?.test ?? ''}
                />
              </Col>
            </Row>
            <Row>
              <Col xs={12} md={12}>
                <LabelValue
                  labelWidth={160}
                  label="Cách thức điều trị"
                  value={order?.specifyVote?.treatment ?? ''}
                />
              </Col>
            </Row>
            <Row>
              <Col xs={12} md={12}>
                <LabelValue
                  labelWidth={160}
                  label="T.Gian điều trị (ngày)"
                  value={
                    order?.specifyVote?.timeTreatment === 0
                      ? ''
                      : order?.specifyVote?.timeTreatment
                  }
                />
              </Col>
            </Row>
            <Row>
              <Col xs={12} md={12}>
                <LabelValue
                  labelWidth={160}
                  label="Dấu hiệu kháng thuốc"
                  value={order?.specifyVote?.drugResistance ?? ''}
                />
              </Col>
            </Row>
            <Row>
              <Col xs={12} md={12}>
                <LabelValue
                  labelWidth={160}
                  label="Tái phát bệnh"
                  value={order?.specifyVote?.diseaseRecurrence ?? ''}
                />
              </Col>
            </Row>
          </>
        );
    }
  };

  return (
    <>
      <Row>
        <CardHeader className="pb-2 d-flex justify-content-between rounded border-bottom w-100">
          <h3>Thông tin nhóm xét nghiệm</h3>
        </CardHeader>
      </Row>

      {renderTestGroup()}
    </>
  );
};

export default NXNInfo;
