import React, { forwardRef } from 'react';

const CustomInputCalendar = forwardRef(
  ({ value, onClick, onChange, ...e }, ref) => {
    return (
      <input
        value={value}
        placeholder="DD/MM/YYYY"
        style={{ background: e?.disabled ? '#e9ecef' : undefined }}
        className="custom-input-calendar"
        onClick={onClick}
        onChange={onChange}
        disabled={e?.disabled ?? false}
        ref={ref}
      ></input>
    );
  }
);

export default CustomInputCalendar;
