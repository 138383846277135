import _ from 'lodash';
import { all, call, put, takeLatest } from 'redux-saga/effects';

import typeActions from 'redux/typeActions';
import ServiceURL from 'services/ServiceURL';
import { DELETE, GET, POST, PUT } from 'services/ServiceBase';
import { convertMessageError } from 'common';

/**
 *
 * @param {*} data
 * get list medicalrecords:
 * - failed -> call actions failed
 * - success ->
 *      + if (search in select-options) => return;
 *      + if (search in list medicalrecords) => call actions sucess
 */
export function* getMedicalRecords(data) {
  const url = `${ServiceURL.medicalRecord}?${data.query}&sort=createdAt:DESC&filters[$and][0][deleted][$eq]=false`;
  const callback = data.callback;
  const isUpdateReducer = data.isUpdateReducer;
  try {
    const res = yield call(GET, url);
    if (!!res.data?.error?.message) {
      yield put({
        type: typeActions.GET_MEDICALRECORD_FAILED,
        error: res.data?.error?.message,
      });
      !!callback?.failed && callback.failed(res.data.error.message);
    } else {
      if (isUpdateReducer) {
        yield put({
          type: typeActions.GET_MEDICALRECORD_SUCCESS,
          data: res.data,
        });
      }
      !!callback?.success && callback.success(res.data);
    }
  } catch (error) {
    yield put({
      type: typeActions.GET_MEDICALRECORD_FAILED,
      error: error?.response?.data?.error?.message ?? '',
    });
    !!callback?.failed &&
      callback.failed(error?.response?.data?.error?.message ?? '');
  }
}
export function* getMedicalRecordById(data) {
  const url = ServiceURL.medicalRecord + '/' + data.param + '?' + data.query;
  const callback = data.callback;
  try {
    const res = yield call(GET, url);
    if (!!res.data?.error?.message) {
      yield put({
        type: typeActions.GET_MEDICALRECORD_BY_ID_FAILED,
        error: res.data.error.message,
      });
      !!callback?.failed && callback.failed(res.data.error.message);
    } else {
      yield put({
        type: typeActions.GET_MEDICALRECORD_BY_ID_SUCCESS,
        data: res.data,
      });
      !!callback?.success && callback.success(res.data);
      return res.data;
    }
  } catch (error) {
    yield put({
      type: typeActions.GET_MEDICALRECORD_BY_ID_FAILED,
      error: error?.response?.data?.error?.message ?? '',
    });
    !!callback?.failed &&
      callback.failed(error?.response?.data?.error?.message ?? '');
  }
}

export function* createMedicalRecord(data) {
  const url = ServiceURL.medicalRecord;
  const callback = data.callback;
  try {
    const res = yield call(POST, url, data.params);
    if (!!res.data?.error?.message) {
      yield put({
        type: typeActions.CREATE_MEDICALRECORD_FAILED,
        error: res.data.error.message,
      });

      !!callback?.failed && callback.failed(res.data.error.message);
    } else {
      yield put({
        type: typeActions.CREATE_MEDICALRECORD_SUCCESS,
        data: res.data,
      });
      !!callback?.success && callback.success(res?.data);
    }
  } catch (error) {
    const message = convertMessageError('c', error, ' bệnh án ');
    yield put({
      type: typeActions.CREATE_MEDICALRECORD_FAILED,
      error: message,
    });
    !!callback?.failed && callback.failed(message);
  }
}

export function* deleteMedicalRecord(data) {
  const url = ServiceURL.medicalRecord + '/' + data.params;
  const callback = data.callback;
  try {
    const res = yield call(DELETE, url);
    if (!!res.data?.error?.message) {
      yield put({
        type: typeActions.DELETE_MEDICALRECORD_FAILED,
        error: res.data.error.message,
      });
      !!callback?.failed && callback.failed(res.data.error.message);
    } else {
      yield put({
        type: typeActions.DELETE_MEDICALRECORD_SUCCESS,
      });
      !!callback?.success && callback.success();
    }
  } catch (error) {
    yield put({
      type: typeActions.DELETE_MEDICALRECORD_FAILED,
      error: error?.response?.data?.error?.message ?? '',
    });
    !!callback?.failed &&
      callback.failed(error?.response?.data?.error?.message ?? '');
  }
}
export function* updateMedicalRecord(data) {
  const url = ServiceURL.medicalRecord + '/' + data.params;
  const callback = data.callback;
  try {
    const res = yield call(PUT, url, data.body);
    if (!!res.error?.message) {
      yield put({
        type: typeActions.UPDATE_MEDICALRECORD_FAILED,
        error: res.error.message,
      });
      !!callback?.failed && callback.failed(res.error.message);
    } else {
      yield put({
        type: typeActions.UPDATE_MEDICALRECORD_SUCCESS,
        data: res.data,
      });
      !!callback?.success && callback.success(res.data);
    }
  } catch (error) {
    const details = error?.response?.data?.error?.details?.errors ?? '';
    const m = details
      ? details.map((_) => {
          return `Trường ${_.path.join(',')} : ${_.message}`;
        })
      : error?.response?.data?.error?.message ?? '' ?? '';
    yield put({
      type: typeActions.UPDATE_MEDICALRECORD_FAILED,
      error: m,
    });
    !!callback?.failed && callback.failed(m);
  }
}
export default function* accountSaga() {
  yield takeLatest(typeActions.GET_MEDICALRECORD_REQUEST, getMedicalRecords);
  yield takeLatest(
    typeActions.GET_MEDICALRECORD_BY_ID_REQUEST,
    getMedicalRecordById
  );
  yield takeLatest(
    typeActions.CREATE_MEDICALRECORD_REQUEST,
    createMedicalRecord
  );
  yield takeLatest(
    typeActions.DELETE_MEDICALRECORD_REQUEST,
    deleteMedicalRecord
  );
  yield takeLatest(
    typeActions.UPDATE_MEDICALRECORD_REQUEST,
    updateMedicalRecord
  );
}
