import { returnValue } from "common";
import typeActions from "redux/typeActions";
const initialState = {
  staffs: null,
  pagination: {},
  // form show/delete
  isModalVisible: false,
  modalInfo: {},
  page: 1,
  limit: 10,
  query: {
    populate: "*",
  },
};
const staffReducer = (state = initialState, actions) => {
  switch (actions.type) {
    case typeActions.SET_STAFF_VALUE:
      const nameStaff = actions.name;
      const valueStaff = actions.value;
      if (typeof nameStaff !== "string") return { ...state };
      const objStaff = returnValue(state, nameStaff, valueStaff);
      return {
        ...state,
        ...objStaff,
      };
    case typeActions.GET_STAFF_REQUEST:
      return {
        ...state,
        ...actions?.data,
      };
    case typeActions.GET_STAFF_SUCCESS:
      return {
        ...state,
        staffs: actions.data.results,
        pagination: actions.data.pagination,
        page: actions.data.pagination.page,
        limit: actions.data.pagination.pageSize
      };
    case typeActions.GET_STAFF_FAILED:
      return {
        ...state,
      };
    // create
    
    case typeActions.CREATE_STAFF_REQUEST:
      return {
        ...state,
        errors: {
          ...state.errors,
          createAccount: '',
        },
      };
    case typeActions.CREATE_STAFF_SUCCESS:
      return {
        ...state,
        errors: {
          ...state.errors,
        },
      };
    case typeActions.CREATE_STAFF_FAILED:
      return {
        ...state,
        errors: {
          ...state.errors,
          createAccount: actions.error,
        },
      };

    //delete
    case typeActions.DELETE_STAFF_REQUEST:
      return {
        ...state,
      };
    case typeActions.DELETE_STAFF_SUCCESS:
      return {
        ...state,
      };
    case typeActions.DELETE_STAFF_FAILED:
      return {
        ...state,
      };
    default:
      return {
        ...state,
      };
  }
};

export default staffReducer;
