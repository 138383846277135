import _ from 'lodash';
import { call, put, takeLatest, all } from 'redux-saga/effects';
import { DELETE, GET, PATCH, POST, PUT } from 'services/ServiceBase';
import ServiceURL from 'services/ServiceURL';
import typeActions from '../typeActions';

export function* getRoles(data) {
  const url =
    ServiceURL.decentralization + '?' + data.query + '&sort=createdAt:DESC';
  const callback = data.callback;
  try {
    const res = yield call(GET, url);
    if (!!res.data?.error?.message) {
      yield put({
        type: typeActions.GET_ROLES_FAILED,
        error: res.data.error.message,
      });
      !!callback?.failed && callback.failed(res.data.error.message);
    } else {
      yield put({
        type: typeActions.GET_ROLES_SUCCESS,
        data: res.data,
      });
      !!callback?.success && callback.success(res.data);
    }
  } catch (error) {
    yield put({
      type: typeActions.GET_ROLES_FAILED,
      error: error?.response?.data?.error?.message ?? '',
    });
    !!callback?.failed &&
      callback.failed(error?.response?.data?.error?.message ?? '');
  }
}

export function* getRoleById(data) {
  const url = ServiceURL.decentralization + '/' + data.params;
  const callback = data.callback;
  try {
    const res = yield call(GET, url);
    if (!!res.data?.error?.message) {
      yield put({
        type: typeActions.GET_ROLE_BY_ID_FAILED,
        error: res.data.error.message,
      });
      !!callback?.failed && callback.failed(res.data.error.message);
    } else {
      yield put({
        type: typeActions.GET_ROLE_BY_ID_SUCCESS,
        data: res.data?.data,
      });
      !!callback?.success && callback.success(res.data?.data);
    }
  } catch (error) {
    yield put({
      type: typeActions.GET_ROLE_BY_ID_FAILED,
      error: error?.response?.data?.error?.message ?? '',
    });
    !!callback?.failed &&
      callback.failed(error?.response?.data?.error?.message ?? '');
  }
}

export function* createRole(data) {
  const url = `${ServiceURL.decentralization}`;
  const callback = data.callback;
  try {
    const res = yield call(POST, url, data.body);
    if (!!res.data?.error?.message) {
      yield put({
        type: typeActions.CREATE_ROLE_FAILED,
        error: res.data.error.message,
      });
      !!callback?.failed && callback.failed(res.data.error.message);
    } else {
      yield put({
        type: typeActions.CREATE_ROLE_SUCCESS,
        data: res.data?.data,
      });
      !!callback?.success && callback.success(res.data?.data);
    }
  } catch (error) {
    yield put({
      type: typeActions.CREATE_ROLE_FAILED,
      error: error?.response?.data?.error?.message ?? '',
    });
    !!callback?.failed &&
      callback.failed(error?.response?.data?.error?.message ?? '');
  }
}

export function* updateRole(data) {
  const url = ServiceURL.decentralization + '/' + data.param;
  const callback = data.callback;
  try {
    const res = yield call(PUT, url, data.body);
    if (!!res.data?.error?.message) {
      yield put({
        type: typeActions.UPDATE_ROLE_FAILED,
        error: res.data.error.message,
      });
      !!callback?.failed && callback.failed(res.data.error.message);
    } else {
      yield put({
        type: typeActions.UPDATE_ROLE_SUCCESS,
        data: res.data?.data,
      });
      !!callback?.success && callback.success(res.data?.data);
    }
  } catch (error) {
    yield put({
      type: typeActions.UPDATE_ROLE_FAILED,
      error: error?.response?.data?.error?.message ?? '',
    });
    !!callback?.failed &&
      callback.failed(error?.response?.data?.error?.message ?? '');
  }
}
export function* deleteRole(data) {
  const url = `${ServiceURL.decentralization}/${data.param}`;
  const callback = data.callback;
  try {
    const res = yield call(DELETE, url);
    if (!!res.data?.error?.message) {
      yield put({
        type: typeActions.DELETE_ROLE_FAILED,
        error: res.data.error.message,
      });
      !!callback?.failed && callback.failed(res.data.error.message);
    } else {
      yield put({
        type: typeActions.DELETE_ROLE_SUCCESS,
        data: res.data?.data,
      });
      !!callback?.success && callback.success(res.data?.data);
    }
  } catch (error) {
    yield put({
      type: typeActions.DELETE_ROLE_FAILED,
      error: error?.response?.data?.error?.message ?? '',
    });
    !!callback?.failed &&
      callback.failed(error?.response?.data?.error?.message ?? '');
  }
}

export function* getPermissions(data) {
  const url = `${ServiceURL.admin}/${ServiceURL.permissions}?${data.query}`;
  const callback = data.callback;
  try {
    const res = yield call(GET, url);
    if (!!res.data?.error?.message) {
      yield put({
        type: typeActions.GET_PERMISSIONS_FAILED,
        error: res.data.error.message,
      });
      !!callback?.failed && callback.failed(res.data.error.message);
    } else {
      yield put({
        type: typeActions.GET_PERMISSIONS_SUCCESS,
        data: res.data?.data,
      });
      !!callback?.success && callback.success(res.data?.data);
    }
  } catch (error) {
    yield put({
      type: typeActions.GET_PERMISSIONS_FAILED,
      error: error?.response?.data?.error?.message ?? '',
    });
    !!callback?.failed &&
      callback.failed(error?.response?.data?.error?.message ?? '');
  }
}

export function* getCurrentPermissions(data) {
  const url = `${ServiceURL.accounts}/${ServiceURL.me}/${ServiceURL.permissions}?${data.query}`;
  const callback = data.callback;
  try {
    const res = yield call(GET, url);
    if (!!res.data?.error?.message) {
      yield put({
        type: typeActions.GET_CURRENT_PERMISSIONS_FAILED,
        error: res.data.error.message,
      });
      !!callback?.failed && callback.failed(res.data.error.message);
    } else {
      yield put({
        type: typeActions.GET_CURRENT_PERMISSIONS_SUCCESS,
        data: res.data?.data,
      });
      !!callback?.success && callback.success(res.data?.data);
    }
  } catch (error) {
    yield put({
      type: typeActions.GET_CURRENT_PERMISSIONS_FAILED,
      error: error?.response?.data?.error?.message ?? '',
    });
    !!callback?.failed &&
      callback.failed(error?.response?.data?.error?.message ?? '');
  }
}

export function* updatePermission(data) {
  const url = `${ServiceURL.decentralization}/${data.param}/${ServiceURL.permissions}`;
  const callback = data.callback;
  try {
    const res = yield call(PUT, url, data.body);
    if (!!res.data?.error?.message) {
      yield put({
        type: typeActions.CREATE_PERMISSION_FAILED,
        error: res.data.error.message,
      });
      !!callback?.failed && callback.failed(res.data.error.message);
    } else {
      yield put({
        type: typeActions.CREATE_PERMISSION_SUCCESS,
        data: res.data?.data,
      });
      !!callback?.success && callback.success(res.data?.data);
    }
  } catch (error) {
    yield put({
      type: typeActions.CREATE_PERMISSION_FAILED,
      error: error?.response?.data?.error?.message ?? '',
    });
    !!callback?.failed &&
      callback.failed(error?.response?.data?.error?.message ?? '');
  }
}

export function* getRolePermissions(data) {
  const url = `${ServiceURL.decentralization}/${data.param}/${ServiceURL.permissions}`;
  const callback = data.callback;
  try {
    const res = yield call(GET, url);
    if (!!res.data?.error?.message) {
      yield put({
        type: typeActions.GET_ROLE_PERMISSIONS_FAILED,
        error: res.data.error.message,
      });
      !!callback?.failed && callback.failed(res.data.error.message);
    } else {
      yield put({
        type: typeActions.GET_ROLE_PERMISSIONS_SUCCESS,
        data: res.data?.data,
      });
      !!callback?.success && callback.success(res.data?.data);
    }
  } catch (error) {
    yield put({
      type: typeActions.GET_ROLE_PERMISSIONS_FAILED,
      error: error?.response?.data?.error?.message ?? '',
    });
    !!callback?.failed &&
      callback.failed(error?.response?.data?.error?.message ?? '');
  }
}

export function* getRolesExtend(data) {
  const url = `${ServiceURL.roleExtend}?${data.query}`;
  const callback = data.callback;
  try {
    const res = yield call(GET, url);
    if (!!res.data?.error?.message) {
      yield put({
        type: typeActions.GET_ROLES_EXTEND_FAILED,
        error: res.data?.error?.message,
      });
      !!callback?.failed && callback.failed(res.data.error.message);
    } else {
      yield put({
        type: typeActions.GET_ROLES_EXTEND_SUCCESS,
        data: res.data?.data,
      });
      !!callback?.success && callback.success(res.data?.data);
    }
  } catch (error) {
    yield put({
      type: typeActions.GET_ROLES_EXTEND_FAILED,
      error: error?.response?.data?.error?.message ?? '',
    });
    !!callback?.failed &&
      callback.failed(error?.response?.data?.error?.message ?? '');
  }
}

export function* getRoleExtend(data) {
  const url = `${ServiceURL.roleExtend}?filters[$and][0][roleId][$eq]=${data.param}`;
  const callback = data.callback;
  try {
    const res = yield call(GET, url);
    if (!!res.data?.error?.message) {
      yield put({
        type: typeActions.GET_ROLE_EXTEND_FAILED,
        error: res.data.error.message,
      });
      !!callback?.failed && callback.failed(res.data.error.message);
    } else {
      yield put({
        type: typeActions.GET_ROLE_EXTEND_SUCCESS,
        data: res.data?.results[0] ?? {},
      });
      !!callback?.success && callback.success(res.data?.results[0] ?? {});
    }
  } catch (error) {
    yield put({
      type: typeActions.GET_ROLE_EXTEND_FAILED,
      error: error?.response?.data?.error?.message ?? '',
    });
    !!callback?.failed &&
      callback.failed(error?.response?.data?.error?.message ?? '');
  }
}

export function* createRoleExtend(data) {
  const url = `${ServiceURL.roleExtend}`;
  const callback = data.callback;
  try {
    const res = yield call(POST, url, data.body);
    if (!!res.data?.error?.message) {
      yield put({
        type: typeActions.CREATE_ROLE_EXTEND_FAILED,
        error: res.data.error.message,
      });
      !!callback?.failed && callback.failed(res.data.error.message);
    } else {
      yield put({
        type: typeActions.CREATE_ROLE_EXTEND_SUCCESS,
      });
      !!callback?.success && callback.success();
    }
  } catch (error) {
    yield put({
      type: typeActions.CREATE_ROLE_EXTEND_FAILED,
      error: error?.response?.data?.error?.message ?? '',
    });
    !!callback?.failed &&
      callback.failed(error?.response?.data?.error?.message ?? '');
  }
}

export function* updateRoleExtend(data) {
  const url = `${ServiceURL.roleExtend}/${data.param}`;
  const callback = data.callback;
  try {
    const res = yield call(PUT, url, data.body);
    if (!!res.data?.error?.message) {
      yield put({
        type: typeActions.UPDATE_ROLE_EXTEND_FAILED,
        error: res.data.error.message,
      });
      !!callback?.failed && callback.failed(res.data.error.message);
    } else {
      yield put({
        type: typeActions.UPDATE_ROLE_EXTEND_SUCCESS,
      });
      !!callback?.success && callback.success();
    }
  } catch (error) {
    yield put({
      type: typeActions.UPDATE_ROLE_EXTEND_FAILED,
      error: error?.response?.data?.error?.message ?? '',
    });
    !!callback?.failed &&
      callback.failed(error?.response?.data?.error?.message ?? '');
  }
}

export function* deleteRoleExtend(data) {
  const url = `${ServiceURL.roleExtend}/${data.param}`;
  const callback = data.callback;
  try {
    const res = yield call(DELETE, url);
    if (!!res.data?.error?.message) {
      yield put({
        type: typeActions.DELETE_ROLE_EXTEND_FAILED,
        error: res.data.error.message,
      });
      !!callback?.failed && callback.failed(res.data.error.message);
    } else {
      yield put({
        type: typeActions.DELETE_ROLE_EXTEND_SUCCESS,
      });
      !!callback?.success && callback.success();
    }
  } catch (error) {
    yield put({
      type: typeActions.DELETE_ROLE_EXTEND_FAILED,
      error: error?.response?.data?.error?.message ?? '',
    });
    !!callback?.failed &&
      callback.failed(error?.response?.data?.error?.message ?? '');
  }
}

export default function* roleSaga() {
  yield takeLatest(typeActions.GET_ROLES_REQUEST, getRoles);
  yield takeLatest(typeActions.GET_ROLE_BY_ID_REQUEST, getRoleById);
  yield takeLatest(typeActions.CREATE_ROLE_REQUEST, createRole);
  yield takeLatest(typeActions.UPDATE_ROLE_REQUEST, updateRole);
  yield takeLatest(typeActions.DELETE_ROLE_REQUEST, deleteRole);
  yield takeLatest(typeActions.GET_PERMISSIONS_REQUEST, getPermissions);
  yield takeLatest(
    typeActions.GET_CURRENT_PERMISSIONS_REQUEST,
    getCurrentPermissions
  );
  yield takeLatest(typeActions.CREATE_PERMISSION_REQUEST, updatePermission);
  yield takeLatest(
    typeActions.GET_ROLE_PERMISSIONS_REQUEST,
    getRolePermissions
  );
  //
  yield takeLatest(typeActions.GET_ROLES_EXTEND_REQUEST, getRolesExtend);
  yield takeLatest(typeActions.GET_ROLE_EXTEND_REQUEST, getRoleExtend);
  yield takeLatest(typeActions.CREATE_ROLE_EXTEND_REQUEST, createRoleExtend);
  yield takeLatest(typeActions.UPDATE_ROLE_EXTEND_REQUEST, updateRoleExtend);
  yield takeLatest(typeActions.DELETE_ROLE_EXTEND_REQUEST, deleteRoleExtend);
}
