import typeActions from 'redux/typeActions';
import CONSTANT from 'constant';
import { returnValue } from 'common';

const initialState = {
  // loading
  isGettingServiceGroups: false,
  isGettingServiceGroup: false,
  isCreatingServiceGroup: false,
  isUpdatingServiceGroup: false,
  isDeletingServiceGroup: false,
  // data
  serviceGroups: {},
  serviceGroup: {},
  // error
  errors: {
    getServiceGroups: '',
    getServiceGroup: '',
    createServiceGroup: '',
    updateServiceGroup: '',
    deleteServiceGroup: '',
  },
  // query
  search: '',
  page: 1,
  limit: 10,
  sort: 'createdAt:asc',
};

const serviceGroupReducer = (state = initialState, actions) => {
  switch (actions.type) {
    case typeActions.SET_SERVICE_GROUP_VALUE:
      const { name, value } = actions;
      if (typeof name !== 'string') return { ...state };
      const obj = returnValue(state, name, value);

      return {
        ...state,
        ...obj,
      };

    case typeActions.GET_SERVICE_GROUPS_REQUEST:
      return {
        ...state,
        isGettingServiceGroups: true,
        errors: {
          ...state.errors,
          getServiceGroups: '',
        },
      };
    case typeActions.GET_SERVICE_GROUPS_SUCCESS:
      return {
        ...state,
        serviceGroups: actions.data ?? {},
        isGettingServiceGroups: false,
        errors: {
          ...state.errors,
          getServiceGroups: '',
        },
      };
    case typeActions.GET_SERVICE_GROUPS_FAILED:
      return {
        ...state,
        serviceGroups: {},
        isGettingServiceGroups: false,
        errors: {
          ...state.errors,
          getServiceGroups: actions.error,
        },
      };

    case typeActions.GET_SERVICE_GROUP_REQUEST:
      return {
        ...state,
        isGettingServiceGroup: true,
        errors: {
          ...state.errors,
          getServiceGroup: '',
        },
      };
    case typeActions.GET_SERVICE_GROUP_SUCCESS:
      return {
        ...state,
        serviceGroup: actions.data || {},
        isGettingServiceGroup: false,
        errors: {
          ...state.errors,
          getServiceGroup: '',
        },
      };
    case typeActions.GET_SERVICE_GROUP_FAILED:
      return {
        ...state,
        serviceGroup: {},
        isGettingServiceGroup: false,
        errors: {
          ...state.errors,
          getServiceGroup: actions.error,
        },
      };

    case typeActions.CREATE_SERVICE_GROUP_REQUEST:
      return {
        ...state,
        isCreatingServiceGroup: true,
        errors: {
          ...state.errors,
          createServiceGroup: '',
        },
      };
    case typeActions.CREATE_SERVICE_GROUP_SUCCESS:
      return {
        ...state,
        isCreatingServiceGroup: false,
        errors: {
          ...state.errors,
          createServiceGroup: '',
        },
      };
    case typeActions.CREATE_SERVICE_GROUP_FAILED:
      return {
        ...state,
        isCreatingServiceGroup: false,
        errors: {
          ...state.errors,
          createServiceGroup: actions.error,
        },
      };

    case typeActions.UPDATE_SERVICE_GROUP_REQUEST:
      return {
        ...state,
        isUpdatingServiceGroup: true,
        errors: {
          ...state.errors,
          updateServiceGroup: '',
        },
      };
    case typeActions.UPDATE_SERVICE_GROUP_SUCCESS:
      return {
        ...state,
        isUpdatingServiceGroup: false,
        errors: {
          ...state.errors,
          updateServiceGroup: '',
        },
      };
    case typeActions.UPDATE_SERVICE_GROUP_FAILED:
      return {
        ...state,
        isUpdatingServiceGroup: false,
        errors: {
          ...state.errors,
          updateServiceGroup: actions.error,
        },
      };

    case typeActions.DELETE_SERVICE_GROUP_REQUEST:
      return {
        ...state,
        isDeletingServiceGroup: true,
        errors: {
          ...state.errors,
          deleteServiceGroup: '',
        },
      };
    case typeActions.DELETE_SERVICE_GROUP_SUCCESS:
      return {
        ...state,
        isDeletingServiceGroup: false,
        errors: {
          ...state.errors,
          deleteServiceGroup: '',
        },
      };
    case typeActions.DELETE_SERVICE_GROUP_FAILED:
      return {
        ...state,
        isDeletingServiceGroup: false,
        errors: {
          ...state.errors,
          deleteServiceGroup: actions.error,
        },
      };

    default:
      return {
        ...state,
      };
  }
};
export default serviceGroupReducer;
