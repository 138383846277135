import typeActions from 'redux/typeActions';
import CONSTANT from 'constant';
import { returnValue } from 'common';

const initialState = {
  // loading
  isGettingPromotionDetails: false,
  isGettingPromotionDetail: false,
  isCreatingPromotionDetail: false,
  isUpdatingPromotionDetail: false,
  isDeletingPromotionDetail: false,
  // data
  promotionDetails: {},
  promotionDetail: {},
  // error
  errors: {
    getPromotionDetails: '',
    getPromotionDetail: '',
    createPromotionDetail: '',
    updatePromotionDetail: '',
    deletePromotionDetail: '',
  },
  // query
  page: 1,
  limit: 10,
};

const promotionDetailReducer = (state = initialState, actions) => {
  switch (actions.type) {
    case typeActions.SET_PROMOTION_DETAIL_VALUE:
      const { name, value } = actions;
      if (typeof name !== 'string') return { ...state };
      const obj = returnValue(state, name, value);

      return {
        ...state,
        ...obj,
      };

    case typeActions.GET_PROMOTION_DETAILS_REQUEST:
      return {
        ...state,
        isGettingPromotionDetails: true,
        errors: {
          ...state.errors,
          getPromotionDetails: '',
        },
      };
    case typeActions.GET_PROMOTION_DETAILS_SUCCESS:
      return {
        ...state,
        promotionDetails: actions.data ?? {},
        isGettingPromotionDetails: false,
        errors: {
          ...state.errors,
          getPromotionDetails: '',
        },
      };
    case typeActions.GET_PROMOTION_DETAILS_FAILED:
      return {
        ...state,
        promotionDetails: {},
        isGettingPromotionDetails: false,
        errors: {
          ...state.errors,
          getPromotionDetails: actions.error,
        },
      };

    case typeActions.GET_PROMOTION_DETAIL_REQUEST:
      return {
        ...state,
        isGettingPromotionDetail: true,
        errors: {
          ...state.errors,
          getPromotionDetail: '',
        },
      };
    case typeActions.GET_PROMOTION_DETAIL_SUCCESS:
      return {
        ...state,
        promotionDetail: actions.data || {},
        isGettingPromotionDetail: false,
        errors: {
          ...state.errors,
          getPromotionDetail: '',
        },
      };
    case typeActions.GET_PROMOTION_DETAIL_FAILED:
      return {
        ...state,
        promotionDetail: {},
        isGettingPromotionDetail: false,
        errors: {
          ...state.errors,
          getPromotionDetail: actions.error,
        },
      };

    case typeActions.CREATE_PROMOTION_DETAIL_REQUEST:
      return {
        ...state,
        isCreatingPromotionDetail: true,
        errors: {
          ...state.errors,
          createPromotionDetail: '',
        },
      };
    case typeActions.CREATE_PROMOTION_DETAIL_SUCCESS:
      return {
        ...state,
        isCreatingPromotionDetail: false,
        errors: {
          ...state.errors,
          createPromotionDetail: '',
        },
      };
    case typeActions.CREATE_PROMOTION_DETAIL_FAILED:
      return {
        ...state,
        isCreatingPromotionDetail: false,
        errors: {
          ...state.errors,
          createPromotionDetail: actions.error,
        },
      };

    case typeActions.UPDATE_PROMOTION_DETAIL_REQUEST:
      return {
        ...state,
        isUpdatingPromotionDetail: true,
        errors: {
          ...state.errors,
          updatePromotionDetail: '',
        },
      };
    case typeActions.UPDATE_PROMOTION_DETAIL_SUCCESS:
      return {
        ...state,
        isUpdatingPromotionDetail: false,
        errors: {
          ...state.errors,
          updatePromotionDetail: '',
        },
      };
    case typeActions.UPDATE_PROMOTION_DETAIL_FAILED:
      return {
        ...state,
        isUpdatingPromotionDetail: false,
        errors: {
          ...state.errors,
          updatePromotionDetail: actions.error,
        },
      };

    case typeActions.DELETE_PROMOTION_DETAIL_REQUEST:
      return {
        ...state,
        isDeletingPromotionDetail: true,
        errors: {
          ...state.errors,
          deletePromotionDetail: '',
        },
      };
    case typeActions.DELETE_PROMOTION_DETAIL_SUCCESS:
      return {
        ...state,
        isDeletingPromotionDetail: false,
        errors: {
          ...state.errors,
          deletePromotionDetail: '',
        },
      };
    case typeActions.DELETE_PROMOTION_DETAIL_FAILED:
      return {
        ...state,
        isDeletingPromotionDetail: false,
        errors: {
          ...state.errors,
          deletePromotionDetail: actions.error,
        },
      };

    default:
      return {
        ...state,
      };
  }
};
export default promotionDetailReducer;
