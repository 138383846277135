import React, { memo, useEffect, useState } from 'react';
import _ from 'lodash';
import { Container, Row, Spinner } from 'reactstrap';
import SimpleHeader from 'components/Headers/SimpleHeader.js';
import 'assets/css/custom-pages/react-bs-table.css';
import { useDispatch, useSelector } from 'react-redux';
import queryString from 'query-string';

import OrderInfo from './components/OrderInfo';
import PCD from './components/PCD';
import { orderActions } from 'redux/actions';
import { useHistory } from 'react-router';
import { notificationAlertRef, notify } from 'common';
import CONSTANT from 'constant';
import typeActions from 'redux/typeActions';

function SynthesisReceive({ location }) {
  const history = useHistory();
  const dispatch = useDispatch();
  const { isGettingOrder, order } = useSelector((state) => state.orderReducer);
  const [note, setNote] = useState('');

  const [statusValue, setStatusValue] = useState(null);
  const [barcodeValue, setBarcodeValue] = useState(null);
  const handleSubmit = ({ isOut }) => {
    if (!order?.id) {
      history.push('/admin/synthesis-sample-transfer');
      return;
    }
    const body = {
      note: note,
      receptionStatus: true,
      timeReceiveTest: new Date(),
    };

    if (statusValue !== null) {
      body.status = statusValue.value;
    } else if (
      order?.status === CONSTANT.ORDER_STATUS[3].value &&
      order?.approveStatus &&
      (_.get(order, 'organization.method', 1) !== 1 ||
        order?.bill?.paymentStatus === true)
    ) {
      body.status = CONSTANT.ORDER_STATUS[4].value;
    }

    if (note.trim() === '') delete body.note;

    dispatch(
      orderActions.updateOrder(order.id, body, {
        success: () => {
          notify(
            notificationAlertRef,
            'success',
            'Thông báo',
            `Cập nhật đơn hàng thành công!`
          );
          setStatusValue(null);
          setNote('');
          setBarcodeValue(null);
          dispatch({
            type: typeActions.GET_ORDER_BY_SUCCESS,
            data: {},
          });
          if (isOut) {
            history.push('/admin/synthesis-sample-transfer');
          }
        },
        failed: (mess) => {
          notify(
            notificationAlertRef,
            'danger',
            'Thông báo',
            `Cập nhật đơn hàng thất bại. Lỗi: ${mess}!`
          );
        },
      })
    );
  };

  useEffect(() => {
    dispatch({
      type: typeActions.GET_ORDER_BY_SUCCESS,
      data: {},
    });
  }, [location]);

  return (
    <>
      <SimpleHeader />
      <Container className="mt--9" fluid>
        <Row className="position-relative">
          <div className="col">
            <OrderInfo
              note={note}
              setNote={setNote}
              statusValue={statusValue}
              setStatusValue={setStatusValue}
              barcodeValue={barcodeValue}
              setBarcodeValue={setBarcodeValue}
              barcode={queryString.parse(location.search)?.barcode ?? ''}
            />
            <PCD handleSubmit={handleSubmit} />
          </div>
          {isGettingOrder && (
            <div
              className="position-absolute d-flex w-100 justify-content-center rounded"
              style={{
                top: 0,
                height: '100%',
                background: 'rgba(0,0,0,.1)',
              }}
            >
              <Spinner className="mt-8" color="info">
                Loading...
              </Spinner>
            </div>
          )}
        </Row>
      </Container>
    </>
  );
}

export default memo(SynthesisReceive);
