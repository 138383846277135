import React, { useEffect, useRef } from 'react';
import { useLocation, Route, Switch, Redirect } from 'react-router-dom';

import AdminNavbar from 'components/Navbars/AdminNavbar.js';
import AdminFooter from 'components/Footers/AdminFooter.js';
import Sidebar from 'components/Sidebar/Sidebar.js';
import {
  accountActions,
  areaActions,
  formActions,
  roleUIActions,
} from 'redux/actions';
import { useDispatch, useSelector } from 'react-redux';
import queryString from 'query-string';
import _ from 'lodash';

import routes from 'routes.js';
import ReactNotificationAlert from 'react-notification-alert';
import { timelineActions } from 'redux/actions';
import {
  setNotificationAlertRef,
  notify,
  checkRole,
  isSampleCollectorStaff,
  isBusinessStaff,
  isManageStaff,
  isAccountantStaff,
  isDoctorHT,
} from 'common';

function Admin(props) {
  const [sidenavOpen, setSidenavOpen] = React.useState(true);
  const { currentAccountExtend, childrentByCurrentAccountExtend } = useSelector(
    (state) => state.accountReducer
  );
  const { areasByAccountExtend } = useSelector((state) => state.areaReducer);
  const location = useLocation();
  const mainContentRef = React.useRef(null);
  const notificationAlertRef = useRef(null);
  const dispatch = useDispatch();

  setNotificationAlertRef(notificationAlertRef);

  useEffect(() => {
    try {
      document.documentElement.scrollTop = 0;
      document.scrollingElement.scrollTop = 0;
      mainContentRef.current.scrollTop = 0;
    } catch (error) {}
  }, [location]);
  const getRoutes = (routes) => {
    return routes.map((prop, key) => {
      if (prop.collapse) {
        return getRoutes(prop.views);
      }
      const validRole = checkRole(currentAccountExtend, prop);

      if (prop.layout === '/admin' && validRole) {
        return (
          <Route
            path={prop.layout + prop.path}
            component={prop.component}
            key={key}
          />
        );
      } else {
        return null;
      }
    });
  };
  const getBrandText = (path) => {
    if (Array.isArray(routes))
      for (let i = 0; i < routes.length; i++) {
        if (
          location.pathname.indexOf(routes[i].layout + routes[i].path) !== -1
        ) {
          return routes[i].name;
        }
      }
    return 'Brand';
  };
  // toggles collapse between mini sidenav and normal
  const toggleSidenav = (e) => {
    if (document.body.classList.contains('g-sidenav-pinned')) {
      document.body.classList.remove('g-sidenav-pinned');
      document.body.classList.add('g-sidenav-hidden');
    } else {
      document.body.classList.add('g-sidenav-pinned');
      document.body.classList.remove('g-sidenav-hidden');
    }
    setSidenavOpen(!sidenavOpen);
  };
  const getNavbarTheme = () => {
    return location.pathname.indexOf('admin/alternative-dashboard') === -1
      ? 'dark'
      : 'light';
  };
  const handleGetRoleUI = () => {
    dispatch(roleUIActions.getRoleUI(queryString.stringify({})));
  };

  useEffect(() => {
    handleGetRoleUI();
  }, []);
  useEffect(() => {
    dispatch(
      formActions.setValue({
        name: 'isFilterVisible',
        value: false,
      })
    );

    document.addEventListener('wheel', function (event) {
      if (document.activeElement.type === 'number') {
        document.activeElement.blur();
      }
    });
    dispatch(
      accountActions.getCurrentAccount(
        queryString.stringify({ populate: 'roleId' }),
        {
          success: (data) => {
            dispatch(
              accountActions.getCurrentAccountExtend(
                queryString.stringify({
                  'filters[$and][0][email][$eq]': data.email,
                }),
                {
                  success: (data) => {
                    dispatch(
                      timelineActions.updateTimeline({
                        path: props.location.pathname,
                        search: props.location.search,
                        currentAccountExtend: data,
                      })
                    );
                    if (_.isEmpty(childrentByCurrentAccountExtend)) {
                      dispatch(
                        accountActions.getChildrentRelationByAccountExtend(
                          data?.id,
                          '',
                          {
                            success: () => {},
                            failed: () => {},
                          }
                        )
                      );
                    }
                    if (_.isEmpty(areasByAccountExtend)) {
                      dispatch(areaActions.getAreasByAccountExtend(data.id));
                    }
                  },
                  failed: () => {},
                }
              )
            );
          },

          failed: (mess, status) => {
            if (status !== 401) {
              notify(
                notificationAlertRef,
                'danger',
                'Thông báo',
                `Lấy thông tin tài khoản thất bại! Lỗi: ${mess}`
              );
            }
          },
        }
      )
    );
  }, [window.location.href]);

  if (!localStorage.getItem('user') || !localStorage.getItem('token')) {
    return <Redirect to="/auth/login" />;
  }

  function getFirstRoute() {
    if (
      isAccountantStaff(currentAccountExtend) ||
      isDoctorHT(currentAccountExtend) ||
      isSampleCollectorStaff(currentAccountExtend) ||
      isBusinessStaff(currentAccountExtend)
    )
      return '/admin/order-all';

    return '/admin/specify-vote-all';
  }
  return (
    <>
      <div className="rna-wrapper">
        <ReactNotificationAlert ref={notificationAlertRef} />
      </div>
      <Sidebar
        toggleSidenav={toggleSidenav}
        sidenavOpen={sidenavOpen}
        logo={{
          innerLink: '/',
          imgSrc: require('assets/img/brand/argon-react.png').default,
          imgAlt: '...',
        }}
      />
      <div className="main-content" ref={mainContentRef}>
        <AdminNavbar
          theme={getNavbarTheme()}
          toggleSidenav={toggleSidenav}
          sidenavOpen={sidenavOpen}
          brandText={getBrandText(location.pathname)}
        />
        {!_.isEmpty(currentAccountExtend) && (
          <Switch>
            {getRoutes([
              ...routes.routesM,
              // ...routes.routesSR,
              ...routes.routeCF,
              ...routes.routesFA,
              ...routes.routesSM,
            ])}
            <Redirect from="*" to={getFirstRoute()} />
          </Switch>
        )}
        <AdminFooter />
      </div>
      {sidenavOpen ? (
        <div className="backdrop d-xl-none" onClick={toggleSidenav} />
      ) : null}
    </>
  );
}

export default Admin;
