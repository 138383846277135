import React, { memo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Col, Form, Modal, Row } from 'reactstrap';
import * as yup from 'yup';
import { Formik } from 'formik';
import _ from 'lodash';

import { notificationAlertRef, notify } from 'common';
import { formActions, accountActions } from 'redux/actions';
import LoadingButtonCustom from 'views/pages/components/LoadingButtonCustom';
import InputCustom from 'views/pages/components/InputCustom';

const ImportAccount = ({ handleGetAccountsExtendIndex }) => {
  const dispatch = useDispatch();
  const [excelFile, setFileExcel] = useState([]);
  const { isModalVisible, formType } = useSelector(
    (state) => state.formReducer
  );
  const token = localStorage.getItem('token');
  const { isCreateAccount, isUpdateAccount } = useSelector(
    (state) => state.accountReducer
  );

  // data
  const onSubmit = (values, resetForm) => {
    var formData = new FormData();
    formData.append('files', excelFile[0]);
    formData.append('token', JSON.parse(token));
    dispatch(
      accountActions.importAccount(formData, {
        success: () => {
          notify(
            notificationAlertRef,
            'success',
            'Thông báo',
            'Tải lên danh sách tài khoản thành công'
          );
          setFileExcel([]);
          resetForm();
          handleClose();
        },
        failed: (err) => {
          notify(
            notificationAlertRef,
            'danger',
            'Import danh sách tài khoản thất bại',
            err || 'Vui lòng upload lại'
          );
          setFileExcel([]);
          resetForm();
        },
      })
    );
  };

  const handleClose = () => {
    setFileExcel([]);
    handleGetAccountsExtendIndex();
    dispatch(
      formActions.setValue({
        name: 'isModalVisible',
        value: false,
      })
    );
    dispatch(
      formActions.setValue({
        name: 'modalInfo',
        value: {},
      })
    );
  };
  const infoSchema = yup.object().shape({
    fileName: yup.string().required('Vui lòng chọn file để tải lên!'),
  });
  return (
    <Modal
      isOpen={isModalVisible && formType === 0}
      className="modal-dialog-centered modal-secondary"
      toggle={() => {
        handleClose();
      }}
    >
      <div className="modal-body bg-white rounded py-0">
        <div className="bg-white d-flex py-2 border-bottom">
          <span style={{ fontSize: 20, fontWeight: 600 }}>
            Import danh sách tài khoản
          </span>
        </div>
        <Formik
          initialValues={{
            file: null,
            fileName: '',
          }}
          enableReinitialize
          onSubmit={(values, { resetForm }) => {
            try {
              onSubmit(values, resetForm);
            } catch (error) {}
          }}
          validationSchema={infoSchema}
        >
          {({ values, handleSubmit, errors, touched, setFieldValue }) => {
            return (
              <Form className="pb-3 pt-2 px-4 form-add-account bg-white">
                <Row>
                  <Col md={12} lg={12}>
                    <InputCustom
                      name="fileName"
                      label="Chọn file (.xlsx)"
                      type="file"
                      placeholder="Nhập mật khẩu mới"
                      value={values.fileName}
                      isRequired={true}
                      accept=".xlsx"
                      handleChange={async (e) => {
                        setFieldValue('fileName', _.get(e, 'target.value', ''));
                        try {
                          if (_.isEmpty(e)) return;
                          const files = await Promise.all([...e.target.files]);
                          setFileExcel(
                            files.map((file) => Object.assign(file))
                          );
                        } catch (error) {
                          //
                        }
                      }}
                      error={errors.fileName}
                      touched={touched.fileName}
                    />
                  </Col>
                </Row>
                <div className="mb-0 pb-0 mt-4 d-flex justify-content-end">
                  <LoadingButtonCustom
                    onClick={() => {
                      handleSubmit();
                    }}
                    color="info"
                    className="text-nowrap "
                    // type="submit"
                    loading={isCreateAccount || isUpdateAccount}
                  >
                    Xác nhận
                  </LoadingButtonCustom>
                </div>
              </Form>
            );
          }}
        </Formik>
      </div>
    </Modal>
  );
};

export default memo(ImportAccount);
