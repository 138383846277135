import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Button, Card, CardBody, CardHeader, CardFooter } from 'reactstrap';
import { formActions, timelineActions } from 'redux/actions';

const FilterCustom = ({
  children,
  handleFilter = () => {},
  handleClear = () => {},
  handleClose = () => {},
}) => {
  const dispatch = useDispatch();
  const { isFilterVisible } = useSelector((state) => state.formReducer);
  const closeFilter = () => {
    dispatch(
      formActions.setValue({
        name: 'isFilterVisible',
        value: false,
      })
    );
  };
  return (
    <Card className={`filter ${isFilterVisible && 'show-filter'}`}>
      <CardHeader>
        <span
          style={{ cursor: 'pointer' }}
          className="text-danger font-weight-bold"
          onClick={() => {
            closeFilter();
            handleClose();
          }}
        >
          Đóng
        </span>
      </CardHeader>
      <CardBody className="px-0">
        <div className="px-4" style={{ height: '78vh', overflowY: 'auto' }}>
          {children}
        </div>
      </CardBody>
      <CardFooter>
        <Button
          block="true"
          onClick={() => {
            closeFilter();
            handleClear();
          }}
        >
          Xóa bộ lọc
        </Button>
        <Button
          className="ml-0"
          block={true}
          color="primary"
          onClick={() => {
            closeFilter();
            handleFilter();
          }}
        >
          Áp dụng bộ lọc
        </Button>
      </CardFooter>
    </Card>
  );
};

export default FilterCustom;
