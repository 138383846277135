import _ from 'lodash';
import { call, put, takeLatest } from 'redux-saga/effects';
import typeActions from 'redux/typeActions';
import ServiceURL from 'services/ServiceURL';
import { DELETE, GET, PATCH, POST, PUT } from 'services/ServiceBase';

export function* getNotification(data) {
  const url = ServiceURL.notification + '?' + data.query;
  const callback = data.callback;
  try {
    const res = yield call(GET, url);
    if (!!res.data?.error?.message) {
      yield put({
        type: typeActions.GET_NOTIFICATION_FAILED,
        error: res.data?.error?.message,
      });
      !!callback?.failed && callback.failed(res.data.error.message);
    } else {
      yield put({
        type: typeActions.GET_NOTIFICATION_SUCCESS,
        data: {
          results: res.data.results,
          pagination: res.data.pagination,
        },
      });
      !!callback?.success && callback.success(res.data?.data);
    }
  } catch (error) {
    yield put({
      type: typeActions.GET_NOTIFICATION_FAILED,
      error: error?.response?.data?.error?.message ?? '',
    });
    !!callback?.failed &&
      callback.failed(error?.response?.data?.error?.message ?? '');
  }
}
export function* deleteNotification(data) {
  const url = ServiceURL.notification + '/' + data.params;
  const callback = data.callback;
  try {
    const res = yield call(DELETE, url);
    if (!!res.data?.error?.message) {
      yield put({
        type: typeActions.DELETE_NOTIFICATION_FAILED,
        error: res,
      });
      !!callback?.failed && callback.failed(res.data.error.message);
    } else {
      yield put({
        type: typeActions.DELETE_NOTIFICATION_SUCCESS,
      });
      !!callback?.success && callback.success(res.data?.data);
    }
  } catch (error) {
    yield put({
      type: typeActions.DELETE_NOTIFICATION_FAILED,
      error: error?.response?.data?.error?.message ?? '',
    });
    !!callback?.failed &&
      callback.failed(error?.response?.data?.error?.message ?? '');
  }
}
export default function* notificationSaga() {
  yield takeLatest(typeActions.GET_NOTIFICATION_REQUEST, getNotification);
  yield takeLatest(typeActions.DELETE_NOTIFICATION_REQUEST, deleteNotification);
}
