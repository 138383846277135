import React, { memo, useEffect, useRef, useState } from 'react';
import paginationFactory from 'react-bootstrap-table2-paginator';
import ReactBSAlert from 'react-bootstrap-sweetalert';
import _ from 'lodash';
import {
  Badge,
  Container,
  Row,
  UncontrolledTooltip,
  FormGroup,
  Label,
  Input,
} from 'reactstrap';
import queryString from 'query-string';
import { useDispatch, useSelector } from 'react-redux';
import classNames from 'classnames';

import {
  additionalSampleActions,
  formActions,
  specifyVotesActions,
  filterActions,
} from 'redux/actions';
import IconEye from 'assets/svgs/eye.svg';
import IconEdit from 'assets/svgs/edit.svg';
import SimpleHeader from 'components/Headers/SimpleHeader.js';
import 'assets/css/custom-pages/react-bs-table.css';
import { useDidUpdateEffect } from 'utils/HookUtils';
import CONSTANT from 'constant';

import IconPrintDisable from 'assets/svgs/print_disable.svg';
import IconPrintPCD from 'assets/svgs/print_pcd.svg';
import moment from 'moment';
import TableCustom from '../components/TableCustom';
import LoadingButtonCustom from '../components/LoadingButtonCustom';
import { checkRole, notificationAlertRef, notify } from 'common';
import UpdateStatusXN from './components/UpdateStatusXN';
import Filter from './components/Filter';
import CAdditionalSample from './components/CAdditionalSample';
import TransferAdditionalSample from './components/TransferAdditionalSample';
import TransferXN from './components/TransferXN';
import ConfirmBarcode from './components/ConfirmBarcode';
import DAdditionalSample from './components/DAdditionalSample';

function AdditionalSample() {
  const inputRef = useRef();
  const dispatch = useDispatch();
  const { areasByAccountExtend } = useSelector((state) => state.areaReducer);
  const {
    page,
    limit,
    additionalSamples,
    isUpdatingAdditionalSample,
    isGettingAdditionalSamples,
    status,
    queryAdditionalSample,
  } = useSelector((state) => state.additionalSampleReducer);
  const { isPrintSpecifyVote } = useSelector(
    (state) => state.specifyvoteReducer
  );
  const {
    currentAccountExtend,
    childrentByCurrentAccountExtend,
    isBusinessStaff,
  } = useSelector((state) => state.accountReducer);
  const [alert, setAlert] = useState(null);
  // const [status, setStatus] = useState(null);
  const [rowsSelected, setRowSelected] = useState([]);
  const [additionalSample, setAdditionalSample] = useState(null);
  const [isVisibleD, setIsVisibleD] = useState(false);
  const results = _.get(additionalSamples, 'results', []);
  const total = _.get(additionalSamples, 'pagination.total', 0);
  const dontShowStatus = [
    CONSTANT.ORDER_STATUS[0].value,
    CONSTANT.ORDER_STATUS[1].value,
    CONSTANT.ORDER_STATUS[2].value,
    CONSTANT.ORDER_STATUS[3].value,
    CONSTANT.ORDER_STATUS[4].value,
    CONSTANT.ORDER_STATUS[9].value,
    CONSTANT.ORDER_STATUS[10].value,
    CONSTANT.ORDER_STATUS[11].value,
  ];
  const dontShowSelectedItems = [
    null,
    CONSTANT.ORDER_STATUS[2].value,
    CONSTANT.ORDER_STATUS[5].value,
    CONSTANT.ORDER_STATUS[7].value,
  ];
  const [addtionalSampleSearch, setAdditionalSampleSearch] = useState('');

  const columns = [
    {
      dataField: 'barcode.barcode',
      text: 'MÃ BARCODE',
    },
    {
      dataField: 'areaName',
      text: 'KHU VỰC',
    },
    {
      dataField: 'departmentName',
      text: 'PHÒNG BAN',
      formatter: (cell) => {
        return cell ?? '';
      },
    },
    {
      dataField: 'order.code',
      text: 'MÃ',
      formatter: (cell, row) => {
        return CONSTANT.ORDER_STATUS[6].value === status
          ? `${cell}_${row.labCode}`
          : cell;
      },
    },
    {
      dataField: 'order.name',
      text: 'TÊN ĐƠN HÀNG',
    },
    {
      dataField: 'organization.name',
      text: 'PHÒNG KHÁM/ BỆNH VIỆN',
    },
    {
      dataField: 'specifyVote.nameTest',
      text: 'DỊCH VỤ',
    },
    {
      dataField: 'specifyVote',
      text: 'BÁC SĨ',
      formatter: (cell) => {
        return `${cell?.lastNameBS ?? ''} ${cell?.firstNameBS ?? ''} `;
      },
    },

    {
      dataField: 'codeStaff1',
      text: 'SALE',
      formatter: (cell) => {
        return `${cell?.fullname ?? ''} `;
      },
    },
    {
      dataField: 'codeStaff2',
      text: 'THU MẪU',
      formatter: (cell) => {
        return `${cell?.fullname ?? ''} `;
      },
    },
    {
      dataField: 'specifyVote',
      text: 'NGÀY CHỈ ĐỊNH',
      formatter: (cell) => {
        return !!cell.createdAt
          ? moment(cell.createdAt).format('DD/MM/YYYY')
          : '';
      },
    },

    {
      dataField: 'bill.paymentStatus',
      text: 'THANH TOÁN',
      formatter: (cell) => {
        const paymentStatusV = CONSTANT.PAYMENT_STATUS.find(
          (item) => item.value === cell
        );

        return paymentStatusV?.label ?? '';
      },
      headerStyle: {
        textAlign: 'center',
      },
      style: {
        textAlign: 'center',
      },
    },
    {
      dataField: 'status',
      text: 'TRẠNG THÁI',
      formatter: (cell) => {
        const statsV = CONSTANT.ORDER_STATUS.find(
          (item) => item.value === cell
        );
        return !!statsV ? (
          <Badge color={statsV.color}>{statsV.label}</Badge>
        ) : (
          ''
        );
      },
      headerStyle: {
        textAlign: 'center',
      },
      style: {
        textAlign: 'center',
      },
    },
    {
      dataField: 'actions',
      text: 'HÀNH ĐỘNG',
      headerClasses: 'fixed-column',
      formatter: boxActions,
      headerStyle: {
        textAlign: 'center',
        zIndex: 3,
      },
      classes: 'fixed-column',
    },
  ];
  function boxActions(__, row) {
    return (
      <div className="d-flex justify-content-center">
        {row?.status === CONSTANT.ORDER_STATUS[5].value ||
        row?.status === CONSTANT.ORDER_STATUS[6].value ||
        row?.status === CONSTANT.ORDER_STATUS[7].value ||
        row?.status === CONSTANT.ORDER_STATUS[8].value ? (
          <>
            <button
              id={'print' + row.id}
              onClick={() => {
                handlePrintPCD(row?.specifyVote?.id ?? -1);
              }}
              className="btn p-0 btn-no-shadow mr-0"
            >
              <img src={IconPrintPCD} className="mr-1" />
            </button>
            <UncontrolledTooltip
              delay={1}
              placement="bottom"
              target={'print' + row.id}
            >
              In phiếu xét nghiệm
            </UncontrolledTooltip>
          </>
        ) : (
          <button
            id={'print' + row.id}
            onClick={() => {}}
            className="btn p-0 btn-no-shadow mr-0"
          >
            <img src={IconPrintDisable} className="mr-1" />
          </button>
        )}

        {status === null ? (
          <>
            <button
              id={'view' + row.id}
              onClick={() => {
                setAdditionalSample(row);
                setIsVisibleD(true);
              }}
              className="btn p-0 btn-no-shadow mr-0"
            >
              <img src={IconEye} className="mr-1" />
            </button>
            <UncontrolledTooltip
              delay={1}
              placement="bottom"
              target={'view' + row.id}
            >
              Xem chi tiết
            </UncontrolledTooltip>
          </>
        ) : status === CONSTANT.ORDER_STATUS[7].value ? (
          <>
            <button
              id={'edit' + row.id}
              onClick={() => {
                dispatch(
                  formActions.setValue({
                    name: 'isModalCreateAdditionalSampleVisible',
                    value: true,
                  })
                );
                dispatch(
                  formActions.setValue({
                    name: 'modalInfo',
                    value: row,
                  })
                );
              }}
              className="btn p-0 btn-no-shadow mr-0"
            >
              <img src={IconEdit} className="mr-1" />
            </button>
            <UncontrolledTooltip
              delay={1}
              placement="bottom"
              target={'edit' + row.id}
            >
              Cập nhật mẫu
            </UncontrolledTooltip>
          </>
        ) : null}
      </div>
    );
  }

  const handlePrintPCD = (id) => {
    if (id === -1) return;
    dispatch(
      specifyVotesActions.printSpecifyVote(id, {
        success: (data) => {
          var blobURL = URL.createObjectURL(data);
          window.open(blobURL);
        },
        failed: (mess) => {
          notify(
            notificationAlertRef,
            'danger',
            'Thông báo',
            `In phiếu xét nghiệm lỗi!`
          );
        },
      })
    );
  };

  const onSizePerPageChange = (e) => {
    dispatch(additionalSampleActions.setValue({ name: 'page', value: 1 }));
    dispatch(
      additionalSampleActions.setValue({ name: 'limit', value: e.target.value })
    );
  };

  const onPageChange = (value) => {
    dispatch(additionalSampleActions.setValue({ name: 'page', value: value }));
  };

  const pagination = paginationFactory({
    page: page,
    sizePerPage: limit,
    totalSize: total,
    showTotal: false,
    withFirstAndLast: false,
    alwaysShowAllBtns: true,
    onPageChange: onPageChange,
    sizePerPageRenderer: () => {
      return (
        <div className="dataTables_length" id="datatable-basic_length">
          <label>
            Hiển thị{' '}
            {
              <select
                name="datatable-basic_length"
                aria-controls="datatable-basic"
                className="form-control form-control-sm"
                onChange={onSizePerPageChange}
              >
                <option value="10">10</option>
                <option value="25">25</option>
                <option value="50">50</option>
                <option value="100">100</option>
              </select>
            }{' '}
            dòng.
          </label>
        </div>
      );
    },
  });

  useDidUpdateEffect(() => {
    handleGetAdditionalSamples();
  }, [page, limit]);

  useDidUpdateEffect(() => {
    // Sau 500ms thì bắn api
    const delayDebounceFn = setTimeout(() => {
      handleGetAdditionalSamples();
    }, 500);
    return () => clearTimeout(delayDebounceFn);
  }, [addtionalSampleSearch, status, queryAdditionalSample]);

  useEffect(() => {
    handleGetAdditionalSamples();
  }, [childrentByCurrentAccountExtend, areasByAccountExtend]);

  const handleGetAdditionalSamples = () => {
    setRowSelected([]);
    const query = {
      pageSize: limit,
      page,
      ...queryAdditionalSample,
    };
    if (addtionalSampleSearch !== '') {
      query[`filters[$and][69][$or][0][order][name][$containsi]`] =
        addtionalSampleSearch;
      query[`filters[$and][69][$or][1][order][code][$containsi]`] =
        addtionalSampleSearch;
      query[`filters[$and][69][$or][2][organization][name][$containsi]`] =
        addtionalSampleSearch;
      query[`filters[$and][69][$or][3][specifyVote][lastNameBS][$containsi]`] =
        addtionalSampleSearch;
      query[`filters[$and][69][$or][3][specifyVote][firstNameBS][$containsi]`] =
        addtionalSampleSearch;
      query[`filters[$and][69][$or][4][barcode][barcode][$containsi]`] =
        addtionalSampleSearch;
    }
    if (status === null) {
      // dontShowStatus.forEach((item, index) => {
      //   if (
      //     item !== CONSTANT.ORDER_STATUS[3].value &&
      //     item !== CONSTANT.ORDER_STATUS[2].value &&
      //     item !== CONSTANT.ORDER_STATUS[1].value
      //   ) {
      //     query[`filters[$and][${index + 1}][status][$ne]`] = item;
      //   }
      // });
      // query['filters[$and][4][receptionStatus][$eq]'] = true;
    } else if (status === 'received') {
      // receptionStatus
      query['filters[$and][2][receptionStatus][$eq]'] = true;
      query['filters[$and][3][$or][0][status][$eq]'] =
        CONSTANT.ORDER_STATUS[4].value;
      query['filters[$and][3][$or][1][status][$eq]'] =
        CONSTANT.ORDER_STATUS[3].value;
      query['filters[$and][3][$or][2][status][$eq]'] =
        CONSTANT.ORDER_STATUS[2].value;
      query['filters[$and][3][$or][3][status][$eq]'] =
        CONSTANT.ORDER_STATUS[1].value;
    } else {
      query['filters[$and][1][status][$eq]'] = status;
    }
    if (!isBusinessStaff)
      areasByAccountExtend.forEach((item, index) => {
        query[`filters[$and][4][$or][${index}][areaName][$eq]`] = item.name;
      });
    dispatch(
      filterActions.setValueFilter({
        name: 'queryPage',
        value: {
          ...query,
          'filters[$and][0][deleted][$eq]': false,
        },
      })
    );
    console.log({ query });
    dispatch(
      additionalSampleActions.getAdditionalSamples(
        queryString.stringify(query, {
          skipEmptyString: true,
          skipNull: true,
        })
      )
    );
  };
  const selectRow = {
    mode: 'checkbox',
    selectColumnPosition: 'left',
    onSelect: (row, isSelect) => {
      try {
        if (isSelect) {
          setRowSelected((prev) => _.uniqBy([...prev, row], 'id'));
        } else {
          const tmp = rowsSelected.filter((item) => item.id !== row.id);
          setRowSelected(_.uniqBy(tmp));
        }
      } catch (error) {}
    },
    onSelectAll: (isSelect, rows) => {
      if (isSelect) {
        setRowSelected(rows);
      } else {
        setRowSelected([]);
      }
    },
    selectionHeaderRenderer: ({ indeterminate, ...rest }) => (
      <div className="" style={{ verticalAlign: 'inherit' }}>
        <input
          type="checkbox"
          style={{ height: 'unset', verticalAlign: 'inherit' }}
          ref={(input) => {
            if (input) input.indeterminate = indeterminate;
          }}
          {...rest}
        />
      </div>
    ),
    selectionRenderer: ({ mode, ...rest }) => (
      <div>
        <input type={mode} {...rest} />
      </div>
    ),
    selected: rowsSelected.map((item) => item.id),
  };

  const headerLeftComponent = () => {
    return (
      <FormGroup
        onChange={(e) => {
          dispatch(
            additionalSampleActions.setValue({
              name: 'status',
              value:
                e.target.value === 'all'
                  ? null
                  : e.target.value === 'received'
                  ? 'received'
                  : Number(e.target.value),
            })
          );
          dispatch(
            additionalSampleActions.setValue({ name: 'page', value: 1 })
          );
          dispatch(
            additionalSampleActions.setValue({ name: 'limit', value: 10 })
          );
        }}
        className="d-flex align-items-center radio-button-custom"
      >
        <FormGroup check className={classNames({ 'mr-3': true })}>
          <Label check style={{ fontSize: 13 }}>
            <Input
              checked={status === null}
              value={'all'}
              type="radio"
              name="status"
            />{' '}
            Tất cả
          </Label>
        </FormGroup>
        <FormGroup check className={classNames({ 'mr-3': true })}>
          <Label check style={{ fontSize: 13 }}>
            <Input
              checked={status === 'received'}
              value={'received'}
              type="radio"
              name="status"
            />{' '}
            Đã tiếp nhận
          </Label>
        </FormGroup>
        {CONSTANT.ORDER_STATUS.filter(
          (item) => !dontShowStatus.includes(item.value)
        ).map((item, index, self) => {
          return (
            <FormGroup
              check
              className={classNames({ 'mr-3': index !== self.length - 1 })}
            >
              <Label check style={{ fontSize: 13 }}>
                <Input
                  checked={status === item.value}
                  value={item.value}
                  type="radio"
                  name="status"
                />{' '}
                {item.label}
              </Label>
            </FormGroup>
          );
        })}
      </FormGroup>
    );
  };
  const headerSubComponent = () => {
    if (
      !checkRole(currentAccountExtend, {
        roles: ['update-additional-sample'],
      })
    ) {
      return;
    }
    // eslint-disable-next-line default-case
    switch (status) {
      case CONSTANT.ORDER_STATUS[2].value:
        return;
      case 'received':
        return (
          <LoadingButtonCustom
            onClick={() => {
              if (rowsSelected.length === 0)
                return notify(
                  notificationAlertRef,
                  'warning',
                  'Thông báo',
                  `Vui lòng chọn mẫu bổ sung cần chuyển xét nghiệm!`
                );
              dispatch(
                formActions.setValue({
                  name: 'isModalTranferAdditionalSampleVisible',
                  value: true,
                })
              );
              dispatch(
                formActions.setValue({
                  name: 'modalInfo',
                  value: { ids: rowsSelected.map((item) => item.id) },
                })
              );
            }}
            color="info"
            className="text-nowrap "
            type="button"
            loading={false}
          >
            Điều chuyển
          </LoadingButtonCustom>
        );
      case CONSTANT.ORDER_STATUS[3].value:
        return (
          <LoadingButtonCustom
            onClick={() => {
              if (rowsSelected.length === 0)
                return notify(
                  notificationAlertRef,
                  'warning',
                  'Thông báo',
                  `Vui lòng chọn mẫu bổ sung cần chuyển xét nghiệm!`
                );
              dispatch(
                formActions.setValue({
                  name: 'isModalVisible',
                  value: true,
                })
              );
              dispatch(
                formActions.setValue({
                  name: 'modalInfo',
                  value: { ids: rowsSelected.map((item) => item.id) },
                })
              );
            }}
            color="info"
            className="text-nowrap "
            type="button"
            loading={isUpdatingAdditionalSample}
          >
            Chuyển xét nghiệm
          </LoadingButtonCustom>
        );
      // case CONSTANT.ORDER_STATUS[4].value:
      //   return (
      //     <LoadingButtonCustom
      //       onClick={() => {
      //         if (rowsSelected.length === 0)
      //           return notify(
      //             notificationAlertRef,
      //             'warning',
      //             'Thông báo',
      //             `Vui lòng chọn mẫu bổ sung cần chuyển xét nghiệm!`
      //           );
      //         dispatch(
      //           formActions.setValue({
      //             name: 'isModalVisible',
      //             value: true,
      //           })
      //         );
      //         dispatch(
      //           formActions.setValue({
      //             name: 'modalInfo',
      //             value: { ids: rowsSelected.map((item) => item.id) },
      //           })
      //         );
      //       }}
      //       color="info"
      //       className="text-nowrap "
      //       type="button"
      //       loading={isUpdatingAdditionalSample}
      //     >
      //       Chuyển xét nghiệm
      //     </LoadingButtonCustom>
      //   );
      case CONSTANT.ORDER_STATUS[5].value:
        return (
          <>
            <input
              ref={inputRef}
              type="file"
              accept=".xlsx"
              style={{ display: 'none' }}
              onChange={async (e) => {
                try {
                  if (_.isEmpty(e)) return;
                  handleUpdateLabCodes(e.target.files[0]);
                } catch (error) {
                  //
                }
              }}
            />

            <LoadingButtonCustom
              onClick={() => {
                inputRef.current.click();
              }}
              color="info"
              className="text-nowrap "
              type="button"
            >
              Upload file
            </LoadingButtonCustom>
          </>
        );
      case CONSTANT.ORDER_STATUS[6].value:
        return (
          <LoadingButtonCustom
            onClick={() => {
              if (rowsSelected.length === 0)
                return notify(
                  notificationAlertRef,
                  'warning',
                  'Thông báo',
                  `Vui lòng chọn mẫu bổ sung cần cập nhật trạng thái!`
                );
              dispatch(
                formActions.setValue({
                  name: 'isModalVisible',
                  value: true,
                })
              );
              dispatch(
                formActions.setValue({
                  name: 'modalInfo',
                  value: { ids: rowsSelected.map((item) => item.id) },
                })
              );
            }}
            color="info"
            className="text-nowrap "
            type="button"
            loading={isUpdatingAdditionalSample}
          >
            Cập nhật trạng thái
          </LoadingButtonCustom>
        );
      case CONSTANT.ORDER_STATUS[7].value:
        return;
      // return (
      //   <LoadingButtonCustom
      //     onClick={() => {
      //       if (rowsSelected.length === 0)
      //         return notify(
      //           notificationAlertRef,
      //           'warning',
      //           'Thông báo',
      //           `Vui lòng chọn mẫu bổ sung đã nhận mẫu!`
      //         );
      //       infoAlert2();
      //     }}
      //     color="info"
      //     className="text-nowrap "
      //     type="button"
      //     loading={isUpdatingAdditionalSample}
      //   >
      //     Đã nhận mẫu
      //   </LoadingButtonCustom>
      // );
      case CONSTANT.ORDER_STATUS[8].value:
        return (
          <LoadingButtonCustom
            onClick={() => {
              if (rowsSelected.length === 0)
                return notify(
                  notificationAlertRef,
                  'warning',
                  'Thông báo',
                  `Vui lòng chọn mẫu bổ sung đã nhận mẫu!`
                );
              infoAlert1();
            }}
            color="info"
            className="text-nowrap "
            type="button"
            loading={isUpdatingAdditionalSample}
          >
            Cập nhật trạng thái
          </LoadingButtonCustom>
        );
    }
  };

  const handleConfirm1 = () => {
    const body = {
      status: CONSTANT.ORDER_STATUS[6].value,
      ids: rowsSelected.slice(1).map((item) => item.id),
    };
    dispatch(
      additionalSampleActions.updateAdditionalSample(rowsSelected[0].id, body, {
        success: () => {
          notify(
            notificationAlertRef,
            'success',
            'Thông báo',
            `Cập nhật mẫu bổ sung thành công!`
          );
          handleGetAdditionalSamples();
          setAlert(null);
        },
        failed: (mess) => {
          notify(
            notificationAlertRef,
            'danger',
            'Thông báo',
            `Cập nhật mẫu bổ sung thất bại. Lỗi: ${mess}!`
          );
          setAlert(null);
        },
      })
    );
  };

  const infoAlert1 = () => {
    setAlert(
      <ReactBSAlert
        info
        style={{ display: 'block', marginTop: '-100px' }}
        title="Thông báo"
        onConfirm={() => {
          handleConfirm1();
        }}
        onCancel={() => setAlert(null)}
        confirmBtnBsStyle="info"
        confirmBtnText="Xác nhận"
        cancelBtnText="Huỷ"
        btnSize=""
      >
        XÁC NHẬN ĐỐI TÁC ĐÃ TIẾP NHẬN {rowsSelected.length} MẪU XÉT NGHIỆM
      </ReactBSAlert>
    );
  };

  // const handleConfirm2 = () => {
  //   const body = {
  //     status: CONSTANT.ORDER_STATUS[1].value,
  //     ids: rowsSelected.slice(1).map((item) => item.id),
  //   };
  //   dispatch(
  //     additionalSampleActions.updateAdditionalSample(rowsSelected[0].id, body, {
  //       success: () => {
  //         notify(
  //           notificationAlertRef,
  //           'success',
  //           'Thông báo',
  //           `Cập nhật mẫu bổ sung thành công!`
  //         );
  //         handleGetAdditionalSamples();
  //         setAlert(null);
  //       },
  //       failed: (mess) => {
  //         notify(
  //           notificationAlertRef,
  //           'danger',
  //           'Thông báo',
  //           `Cập nhật mẫu bổ sung thất bại. Lỗi: ${mess}!`
  //         );
  //         setAlert(null);
  //       },
  //     })
  //   );
  // };

  // const infoAlert2 = () => {
  //   setAlert(
  //     <ReactBSAlert
  //       info
  //       style={{ display: 'block', marginTop: '-100px' }}
  //       title="Thông báo"
  //       onConfirm={() => {
  //         handleConfirm2();
  //       }}
  //       onCancel={() => setAlert(null)}
  //       confirmBtnBsStyle="info"
  //       confirmBtnText="Xác nhận"
  //       cancelBtnText="Huỷ"
  //       btnSize=""
  //     >
  //       XÁC NHẬN ĐỐI TÁC ĐÃ TIẾP NHẬN {rowsSelected.length} MẪU XÉT NGHIỆM
  //     </ReactBSAlert>
  //   );
  // };

  const handleUpdateLabCodes = (file) => {
    dispatch(
      additionalSampleActions.updateLabCodes(file, {
        success: () => {
          notify(
            notificationAlertRef,
            'success',
            'Thông báo',
            `Đã cập nhật labcodes`
          );
        },
        failed: (mess) => {
          notify(
            notificationAlertRef,
            'danger',
            'Thông báo',
            `Tải file lên lỗi: File không hợp lệ!`
          );
        },
      })
    );
  };

  return (
    <>
      <SimpleHeader />
      <ConfirmBarcode handleGetAdditionalSamples={handleGetAdditionalSamples} />
      <CAdditionalSample
        handleGetAdditionalSamples={handleGetAdditionalSamples}
      />
      <DAdditionalSample
        isVisibleD={isVisibleD}
        setIsVisibleD={setIsVisibleD}
        additionalSample={additionalSample}
        setAdditionalSample={setAdditionalSample}
      />
      {status === 'received' && (
        <TransferAdditionalSample
          handleGetAdditionalSamples={handleGetAdditionalSamples}
        />
      )}

      {(status === CONSTANT.ORDER_STATUS[3].value ||
        status === CONSTANT.ORDER_STATUS[4].value) && (
        <TransferXN handleGetAdditionalSamples={handleGetAdditionalSamples} />
      )}
      <Filter />

      {CONSTANT.ORDER_STATUS[6].value === status && (
        <UpdateStatusXN
          handleGetAdditionalSamples={handleGetAdditionalSamples}
        />
      )}
      {alert}
      <Container className="mt--9" fluid>
        <Row>
          <div className="col">
            <TableCustom
              tableName="Danh sách"
              data={results}
              columns={columns}
              page={page}
              limit={limit}
              total={total}
              searchBy={'Tìm kiếm'}
              pagination={pagination}
              search={addtionalSampleSearch}
              headerLeftComponent={headerLeftComponent}
              headerSubComponent={headerSubComponent}
              isHeaderSub={true}
              selectRow={selectRow}
              isHideSelectItems={dontShowSelectedItems.includes(status)}
              onChange={(e) => {
                setAdditionalSampleSearch(e.target.value);
              }}
              isLoading={isPrintSpecifyVote || isGettingAdditionalSamples}
            />
          </div>
        </Row>
      </Container>
    </>
  );
}

export default memo(AdditionalSample);
