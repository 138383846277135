import typeActions from 'redux/typeActions';
const setValueFilter = ({ name, value }) => {
  return {
    type: typeActions.SET_VALUE_FILTER,
    name,
    value,
  };
};

export default {
  setValueFilter,
};
