import React, { memo, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  Button,
  Col,
  Form,
  FormGroup,
  Input,
  Modal,
  Row,
  Table,
} from 'reactstrap';
import * as yup from 'yup';
import { Formik } from 'formik';
import _ from 'lodash';
import * as uuid from 'uuid';

import { notificationAlertRef, notify, checkSupperAdmin } from 'common';
import LabelCustom from 'views/pages/components/LabelCustom';
import { formActions, roleActions, roleUIActions } from 'redux/actions';
import HelperText from 'views/pages/components/HelperText';
import queryString from 'query-string';
import TableRow from './TableRow';
import LoadingButtonCustom from 'views/pages/components/LoadingButtonCustom';

const CUAccount = () => {
  const dispatch = useDispatch();
  const { isModalVisible, modalInfo } = useSelector(
    (state) => state.formReducer
  );

  const { query, isCreateRole, isUpdateRole, isUpdatePermission } = useSelector(
    (state) => state.roleReducer
  );
  const { roleUI } = useSelector((state) => state.roleUIReducer);

  const isUpdate = !_.isEmpty(modalInfo);
  const [body, setBody] = useState([]);
  const { isView, name } = modalInfo;
  const roleInfo = {
    name: name ?? '',
    description: modalInfo?.description ?? '',
  };

  const roleSchema = yup.object().shape({
    name: yup.string().required('Tên phân quyền không được để trống!'),
  });

  const onSubmit = (values) => {
    // if (checkSupperAdmin(modalInfo?.code))
    //   return notify(
    //     notificationAlertRef,
    //     'danger',
    //     'Thông báo',
    //     `Quyền Super Admin không được phép cập nhật!`
    //   );

    if (isUpdate) {
      dispatch(
        roleActions.updateRole(
          modalInfo?.id ?? '',
          { ...values, permissions: JSON.stringify(body) },
          {
            success: (__) => {
              handleClose();
              dispatch(roleActions.getRoles(queryString.stringify(query)));
              return notify(
                notificationAlertRef,
                'success',
                'Thông báo',
                `Cập nhật phân quyền thành công!`
              );
            },
            failed: (mess) => {
              return notify(
                notificationAlertRef,
                'danger',
                'Thông báo',
                `Cập nhật phân quyền thất bại. Lỗi: ${mess}!`
              );
            },
          }
        )
      );
    } else {
      dispatch(
        roleActions.createRole(
          { ...values, permissions: JSON.stringify(body) },
          {
            success: (__) => {
              handleClose();
              dispatch(roleActions.getRoles(queryString.stringify(query)));
              return notify(
                notificationAlertRef,
                'success',
                'Thông báo',
                `Thêm mới phân quyền thành công!`
              );
            },
            failed: (mess) => {
              return notify(
                notificationAlertRef,
                'danger',
                'Thông báo',
                `Thêm mới phân quyền thất bại. Lỗi: ${mess}!`
              );
            },
          }
        )
      );
    }
  };

  const handleClose = () => {
    dispatch(
      formActions.setValue({
        name: 'isModalVisible',
        value: false,
      })
    );
    dispatch(
      formActions.setValue({
        name: 'modalInfo',
        value: {},
      })
    );
    setBody([]);
  };

  useEffect(() => {
    setBody(modalInfo?.permissions ?? []);
  }, [modalInfo]);

  const subjects = _.get(roleUI, 'Permissions', []);

  const handleChecked = (value, isChecked) => {
    if (isView || modalInfo?.name === 'Admin_HTG') return;
    if (checkSupperAdmin(modalInfo?.code))
      return notify(
        notificationAlertRef,
        'warning',
        'Thông báo',
        `Quyền Super Admin không được phép cập nhật!`
      );
    try {
      if (!isChecked) {
        setBody((prev) => prev.filter((item) => item !== value));
      } else {
        setBody((prev) => _.uniq([...prev, value]));
      }
    } catch (error) {}
  };

  const handleCheckedMulti = (values, isChecked) => {
    if (isView || modalInfo?.name === 'Admin_HTG') return;
    if (checkSupperAdmin(modalInfo?.code))
      return notify(
        notificationAlertRef,
        'warning',
        'Thông báo',
        `Quyền Super Admin không được phép cập nhật!`
      );
    if (!isChecked) {
      setBody((prev) => {
        const newValue = prev.filter((item) => !values.includes(item));

        return _.uniq(newValue);
      });
    } else {
      setBody((prev) => _.uniq([...prev, ...values]));
    }
  };

  return (
    <Modal
      isOpen={isModalVisible}
      className="modal-dialog-centered modal-secondary"
      toggle={() => {
        handleClose();
      }}
      style={{ minWidth: 1240 }}
    >
      <div className="modal-body">
        <Formik
          initialValues={roleInfo}
          enableReinitialize
          onSubmit={onSubmit}
          validationSchema={roleSchema}
        >
          {({
            values,
            handleSubmit,
            errors,
            touched,
            handleBlur,
            handleChange,
          }) => {
            return (
              <Form
                onSubmit={() => {
                  handleSubmit();
                }}
                className="pt-4 pb-3 px-3 form-add-account"
              >
                <FormGroup className="mb-0">
                  <Row>
                    <Col md={12} lg={12}>
                      <h2>
                        {isView
                          ? 'Chi tiết'
                          : isUpdate
                          ? 'Cập nhật'
                          : 'Thêm mới'}{' '}
                        phân quyền
                      </h2>
                    </Col>
                  </Row>
                  <Row>
                    <Col xs={4}>
                      <LabelCustom isRequired={true} label="Tên" id="name" />
                      <Input
                        disabled={isView || modalInfo?.name === 'Admin_HTG'}
                        id="name"
                        placeholder="Nhập tên phân quyền"
                        type="text"
                        className="height-input"
                        name="name"
                        value={values.name}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        invalid={!!errors?.name && !!touched?.name}
                      />
                      {!!errors.name && !!touched.name && (
                        <HelperText message={errors.name} />
                      )}

                      <LabelCustom
                        isRequired={false}
                        style={{ marginTop: 8 }}
                        label="Mô tả"
                        id="name"
                      />
                      <Input
                        disabled={isView || modalInfo?.name === 'Admin_HTG'}
                        id="description"
                        placeholder="Nhập mô tả"
                        type="textarea"
                        rows="4"
                        name="description"
                        value={values.description}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        invalid={
                          !!errors?.description && !!touched?.description
                        }
                      />
                      {!!errors.description && !!touched.description && (
                        <HelperText message={errors.description} />
                      )}
                    </Col>
                    <Col xs={8}>
                      <div>
                        <h4>Thiết lập phân quyền</h4>
                        <h6>
                          <i>(Vui lòng bật chức năng cho phép)</i>
                        </h6>
                      </div>
                      <div style={{ maxHeight: 600, overflow: 'auto' }}>
                        <Table
                          className="align-items-center table-flush"
                          hover
                          responsive
                        >
                          <thead className="thead-light">
                            <tr>
                              <th>STT</th>
                              <th>Chức năng quản lý</th>
                              <th className="text-center">Tạo mới</th>
                              <th className="text-center">Xem</th>
                              <th className="text-center">Cập nhật</th>
                              <th className="text-center">Xóa</th>
                            </tr>
                          </thead>
                          <tbody>
                            {subjects.map((item, index) => {
                              const children = _.get(item, 'Child', []);
                              return (
                                <>
                                  <TableRow
                                    key={uuid.v4().toString()}
                                    item={item}
                                    index={index}
                                    indexP={index}
                                    body={body}
                                    handleChecked={handleChecked}
                                    handleCheckedMulti={handleCheckedMulti}
                                  />
                                  {children.map((c, iC) => {
                                    return (
                                      <TableRow
                                        key={uuid.v4().toString()}
                                        item={c}
                                        index={iC}
                                        indexP={index}
                                        body={body}
                                        handleChecked={handleChecked}
                                        isChild={true}
                                      />
                                    );
                                  })}
                                </>
                              );
                            })}
                          </tbody>
                        </Table>
                      </div>
                    </Col>
                  </Row>

                  <div className="mb-0 pb-0 mt-4 d-flex justify-content-end">
                    <Button
                      className="text-nowrap mr-1"
                      onClick={() => {
                        handleClose();
                      }}
                    >
                      Hủy
                    </Button>
                    <LoadingButtonCustom
                      onClick={handleSubmit}
                      color="info"
                      className="text-nowrap "
                      type="submit"
                      loading={
                        isCreateRole || isUpdateRole || isUpdatePermission
                      }
                    >
                      {isUpdate ? 'Cập nhật' : 'Thêm mới'}
                    </LoadingButtonCustom>
                  </div>
                </FormGroup>
              </Form>
            );
          }}
        </Formik>
      </div>
    </Modal>
  );
};

export default memo(CUAccount);
