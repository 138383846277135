import typeActions from 'redux/typeActions';
import { returnValue } from 'common';

const initialState = {
  // loading
  isGettingAdditionalSamples: false,
  isGettingAdditionalSample: false,
  isCreatingAdditionalSample: false,
  isUpdatingAdditionalSample: false,
  isDeletingAdditionalSample: false,
  isExportingAdditionalSample: false,
  isPrintPkq: false,
  isUploadFiles: false,
  isUpdatingLabcodes: false,
  // data
  additionalSamples: {},
  additionalSample: {},
  // error
  errors: {
    getAdditionalSamples: '',
    getAdditionalSample: '',
    createAdditionalSample: '',
    updateAdditionalSample: '',
    deleteAdditionalSample: '',
  },
  // query
  nameTC: '',
  search: '',
  page: 1,
  limit: 10,
  startDate: null,
  endDate: null,
  querySynthetic: {},
  queryUnpaid: {},
  queryReceivePay: {},
  querySynthesisSampleTransfer: {},

  // tiếp nhận
  status: null,
};

const additionalSampleReducer = (state = initialState, actions) => {
  switch (actions.type) {
    case typeActions.SET_ADDITIONAL_SAMPLE_VALUE:
      const { name, value } = actions;
      if (typeof name !== 'string') return { ...state };
      const obj = returnValue(state, name, value);

      return {
        ...state,
        ...obj,
      };

    case typeActions.GET_ADDITIONAL_SAMPLES_REQUEST:
      return {
        ...state,
        isGettingAdditionalSamples: true,
        errors: {
          ...state.errors,
          getAdditionalSamples: '',
        },
      };
    case typeActions.GET_ADDITIONAL_SAMPLES_SUCCESS:
      return {
        ...state,
        additionalSamples: actions.data ?? {},
        isGettingAdditionalSamples: false,
        errors: {
          ...state.errors,
          getAdditionalSamples: '',
        },
      };
    case typeActions.GET_ADDITIONAL_SAMPLES_FAILED:
      return {
        ...state,
        additionalSamples: {},
        isGettingAdditionalSamples: false,
        errors: {
          ...state.errors,
          getAdditionalSamples: actions.error,
        },
      };

    case typeActions.GET_ADDITIONAL_SAMPLE_BY_REQUEST:
    case typeActions.GET_ADDITIONAL_SAMPLE_REQUEST:
      return {
        ...state,
        isGettingAdditionalSample: true,
        errors: {
          ...state.errors,
          getAdditionalSample: '',
        },
      };

    case typeActions.GET_ADDITIONAL_SAMPLE_BY_SUCCESS:
    case typeActions.GET_ADDITIONAL_SAMPLE_SUCCESS:
      return {
        ...state,
        additionalSample: actions.data || {},
        isGettingAdditionalSample: false,
        errors: {
          ...state.errors,
          getAdditionalSample: '',
        },
      };
    case typeActions.GET_ADDITIONAL_SAMPLE_BY_FAILED:
    case typeActions.GET_ADDITIONAL_SAMPLE_FAILED:
      return {
        ...state,
        additionalSample: {},
        isGettingAdditionalSample: false,
        errors: {
          ...state.errors,
          getAdditionalSample: actions.error,
        },
      };

    case typeActions.CREATE_ADDITIONAL_SAMPLE_REQUEST:
      return {
        ...state,
        isCreatingAdditionalSample: true,
        errors: {
          ...state.errors,
          createAdditionalSample: '',
        },
      };
    case typeActions.CREATE_ADDITIONAL_SAMPLE_SUCCESS:
      return {
        ...state,
        isCreatingAdditionalSample: false,
        errors: {
          ...state.errors,
          createAdditionalSample: '',
        },
      };
    case typeActions.CREATE_ADDITIONAL_SAMPLE_FAILED:
      return {
        ...state,
        isCreatingAdditionalSample: false,
        errors: {
          ...state.errors,
          createAdditionalSample: actions.error,
        },
      };

    case typeActions.UPDATE_ADDITIONAL_SAMPLE_REQUEST:
      return {
        ...state,
        isUpdatingAdditionalSample: true,
        errors: {
          ...state.errors,
          updateAdditionalSample: '',
        },
      };
    case typeActions.UPDATE_ADDITIONAL_SAMPLE_SUCCESS:
      return {
        ...state,
        isUpdatingAdditionalSample: false,
        errors: {
          ...state.errors,
          updateAdditionalSample: '',
        },
      };
    case typeActions.UPDATE_ADDITIONAL_SAMPLE_FAILED:
      return {
        ...state,
        isUpdatingAdditionalSample: false,
        errors: {
          ...state.errors,
          updateAdditionalSample: actions.error,
        },
      };

    case typeActions.DELETE_ADDITIONAL_SAMPLE_REQUEST:
      return {
        ...state,
        isDeletingAdditionalSample: true,
        errors: {
          ...state.errors,
          deleteAdditionalSample: '',
        },
      };
    case typeActions.DELETE_ADDITIONAL_SAMPLE_SUCCESS:
      return {
        ...state,
        isDeletingAdditionalSample: false,
        errors: {
          ...state.errors,
          deleteAdditionalSample: '',
        },
      };
    case typeActions.DELETE_ADDITIONAL_SAMPLE_FAILED:
      return {
        ...state,
        isDeletingAdditionalSample: false,
        errors: {
          ...state.errors,
          deleteAdditionalSample: actions.error,
        },
      };

    case typeActions.EXPORT_ADDITIONAL_SAMPLE_REQUEST:
      return {
        ...state,
        isExportingAdditionalSample: true,
      };
    case typeActions.EXPORT_ADDITIONAL_SAMPLE_SUCCESS:
      return {
        ...state,
        isExportingAdditionalSample: false,
      };
    case typeActions.EXPORT_ADDITIONAL_SAMPLE_FAILED:
      return {
        ...state,
        isExportingAdditionalSample: false,
      };

    case typeActions.PRINT_PKQ_ADDITIONAL_SAMPLE_REQUEST:
      return {
        ...state,
        isPrintPkq: true,
      };
    case typeActions.PRINT_PKQ_ADDITIONAL_SAMPLE_SUCCESS:
      return {
        ...state,
        isPrintPkq: false,
      };
    case typeActions.PRINT_PKQ_ADDITIONAL_SAMPLE_FAILED:
      return {
        ...state,
        isPrintPkq: false,
      };

    case typeActions.UPLOAD_FILES_ADDITIONAL_SAMPLE_REQUEST:
      return {
        ...state,
        isUploadFiles: true,
      };
    case typeActions.UPLOAD_FILES_ADDITIONAL_SAMPLE_SUCCESS:
      return {
        ...state,
        isUploadFiles: false,
      };
    case typeActions.UPLOAD_FILES_ADDITIONAL_SAMPLE_FAILED:
      return {
        ...state,
        isUploadFiles: false,
      };

    case typeActions.UPDATE_ADDITIONAL_SAMPLE_LABCODES_REQUEST:
      return {
        ...state,
        isUpdatingLabcodes: true,
        errors: {
          ...state.errors,
        },
      };
    case typeActions.UPDATE_ADDITIONAL_SAMPLE_LABCODES_SUCCESS:
      return {
        ...state,
        isUpdatingLabcodes: false,
        errors: {
          ...state.errors,
        },
      };
    case typeActions.UPDATE_ADDITIONAL_SAMPLE_LABCODES_FAILED:
      return {
        ...state,
        isUpdatingLabcodes: false,
        errors: {
          ...state.errors,
        },
      };

    default:
      return {
        ...state,
      };
  }
};
export default additionalSampleReducer;
