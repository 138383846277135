import React from 'react';
import { Input } from 'reactstrap';

const SelectTime = ({ time, setTime }) => {
  return (
    <Input
      style={{ maxWidth: 160, paddingBottom: 0, paddingTop: 0 }}
      type="select"
      name="select"
      className="height-input"
      id="time"
      value={time}
      onChange={(e) => {
        setTime(Number(e.target.value));
      }}
    >
      <option className="py-2" value={0}>
        Hôm nay
      </option>
      <option className="py-2" value={1}>
        Tuần này
      </option>
      <option className="py-2" value={2}>
        Tháng này
      </option>
      <option className="py-2" value={3}>
        Năm này
      </option>
    </Input>
  );
};

export default SelectTime;
