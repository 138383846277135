import typeActions from 'redux/typeActions';

const setValue = ({ name, value }) => {
  return {
    type: typeActions.SET_SERVICE_VALUE,
    name,
    value,
  };
};

const getServices = (query, callback, isUpdateReducer = true) => {
  return {
    type: typeActions.GET_SERVICES_REQUEST,
    query,
    callback,
    isUpdateReducer,
  };
};

const getService = (param, query, callback) => {
  return {
    type: typeActions.GET_SERVICE_REQUEST,
    param,
    query,
    callback,
  };
};

const createService = (body, callback) => {
  return {
    type: typeActions.CREATE_SERVICE_REQUEST,
    body,
    callback,
  };
};
const updateService = (param, body, callback) => {
  return {
    type: typeActions.UPDATE_SERVICE_REQUEST,
    param,
    body,
    callback,
  };
};
const deleteService = (param, callback) => {
  return {
    type: typeActions.DELETE_SERVICE_REQUEST,
    param,
    callback,
  };
};

const getServiceBy = (query, callback) => {
  return {
    type: typeActions.GET_SERVICE_BY_REQUEST,
    query,
    callback,
  };
};
const exportServiceGroups = (query, callback) => {
  return {
    type: typeActions.EXPORT_SERVICE_GROUP_REQUEST,
    callback,
    query,
  };
};
const exportServices = (query, callback) => {
  return {
    type: typeActions.EXPORT_SERVICE_REQUEST,
    callback,
    query,
  };
};

export default {
  setValue,
  getServices,
  getService,
  createService,
  updateService,
  deleteService,
  getServiceBy,
  exportServiceGroups,
  exportServices
};
