import React, { useEffect, useState } from 'react';
import { FormGroup } from 'reactstrap';
import DatePicker from 'react-datepicker';
import queryString from 'query-string';
import _ from 'lodash';

import CustomInputCalendar from 'views/pages/components/CustomInputCalendar';
import FilterCustom from 'views/pages/components/FilterCustom';
import LabelCustom from 'views/pages/components/LabelCustom';
import { useDispatch, useSelector } from 'react-redux';
import {
  accountActions,
  areaActions,
  orderActions,
  organizationActions,
  serviceActions,
  timelineActions,
} from 'redux/actions';
import SelectCustom from 'views/pages/components/SelectCustom';
import CONSTANT from 'constant';
import moment from 'moment';

const Filter = () => {
  const dispatch = useDispatch();

  const { isSampleCollectorStaff } = useSelector(
    (state) => state.accountReducer
  );

  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [statusValue, setStatusValue] = useState(null);
  const [statusPayment, setStatusPayment] = useState(null);

  const [organizationSearch, setOrganizationSearch] = useState('');
  const [codeStaff1Search, setCodeStaff1Search] = useState('');
  const [codeStaff2Search, setCodeStaff2Search] = useState('');
  // const [codeStaff3Search, setCodeStaff3Search] = useState('');
  const [serviceSearch, setServiceSearch] = useState('');
  const [areaSearch, setAreaSearch] = useState('');

  const [organizationValue, setOrganizationValue] = useState(null);
  const [codeStaff1Value, setCodeStaff1Value] = useState(null);
  const [codeStaff2Value, setCodeStaff2Value] = useState(null);
  const [serviceValue, setServiceValue] = useState(null);
  const [areaValue, setAreaValue] = useState(null);

  const [organizationResults, setOrganizationResults] = useState([]);
  const [codeStaff1Results, setCodeStaff1Results] = useState([]);
  const [codeStaff2Results, setCodeStaff2Results] = useState([]);
  const [serviceResults, setServiceResults] = useState([]);
  const [areaResults, setAreaResults] = useState([]);

  // get orgs
  useEffect(() => {
    const delayDebounceFn = setTimeout(() => {
      handleGetOrganizations();
    }, 500);

    return () => clearTimeout(delayDebounceFn);
  }, [organizationSearch]);

  const handleGetOrganizations = () => {
    const query = {
      'filters[$and][1][organizationType][$eq]': 1,
      'filters[$and][2][status][$eq]': 0,
    };
    if (organizationSearch !== '') {
      query['filters[$and][3][code][$containsi]'] = organizationSearch;
    }
    dispatch(
      organizationActions.getOrganizations(
        queryString.stringify(query),
        {
          success: (data) => {
            setOrganizationResults(data ?? []);
          },
          failed: () => {},
        },
        false
      )
    );
  };

  useEffect(() => {
    const delayDebounceFn = setTimeout(() => {
      handleGetCodeStaff2s();
    }, 500);

    return () => clearTimeout(delayDebounceFn);
  }, [codeStaff2Search]);

  const handleGetCodeStaff2s = () => {
    const query = {
      'filters[$and][1][accountType][$eq]': 0,
      'filters[$and][2][isActive][$eq]': true,
    };
    if (codeStaff2Search !== '') {
      query['filters[$and][3][fullname][$containsi]'] = codeStaff2Search;
    }

    dispatch(
      accountActions.getAccountsExtend(
        queryString.stringify(query),
        {
          success: (data) => {
            const results = _.get(data, 'results', []);
            setCodeStaff2Results(results);
          },
          failed: () => {},
        },
        false
      )
    );
  };

  useEffect(() => {
    const delayDebounceFn = setTimeout(() => {
      handleGetCodeStaff1s();
    }, 500);

    return () => clearTimeout(delayDebounceFn);
  }, [codeStaff1Search]);

  const handleGetCodeStaff1s = () => {
    const query = {
      'filters[$and][1][accountType][$eq]': 1,
      'filters[$and][2][isActive][$eq]': true,
    };
    if (codeStaff1Search !== '') {
      query['filters[$and][3][fullname][$containsi]'] = codeStaff1Search;
    }

    dispatch(
      accountActions.getAccountsExtend(
        queryString.stringify(query),
        {
          success: (data) => {
            const results = _.get(data, 'results', []);
            setCodeStaff1Results(results);
          },
          failed: () => {},
        },
        false
      )
    );
  };

  // get services
  useEffect(() => {
    const delayDebounceFn = setTimeout(() => {
      handleGetServices();
    }, 500);

    return () => clearTimeout(delayDebounceFn);
  }, [serviceSearch]);

  const handleGetServices = () => {
    const query = {
      // 'filters[$and][0][accountType][$eq]': 1,
    };
    if (serviceSearch !== '') {
      query['filters[$and][1][name][$containsi]'] = serviceSearch;
    }

    dispatch(
      serviceActions.getServices(
        queryString.stringify(query),
        {
          success: (data) => {
            const results = _.get(data, 'results', []);
            setServiceResults(results);
          },
          failed: () => {},
        },
        false
      )
    );
  };

  // get areas
  useEffect(() => {
    const delayDebounceFn = setTimeout(() => {
      handleGetAreas();
    }, 500);

    return () => clearTimeout(delayDebounceFn);
  }, [areaSearch]);

  const handleGetAreas = () => {
    const query = {};
    if (areaSearch !== '') {
      query['filters[$and][1][name][$containsi]'] = areaSearch;
    }

    dispatch(
      areaActions.getAreas(
        queryString.stringify(query),
        {
          success: (data) => {
            const results = _.get(data, 'results', []);
            setAreaResults(results);
          },
          failed: () => {},
        },
        false
      )
    );
  };

  const handleClear = () => {
    setStartDate(null);
    setEndDate(null);
    setStatusValue(null);
    setStatusPayment(null);
    setOrganizationValue(null);
    setCodeStaff1Value(null);
    setCodeStaff2Value(null);
    setServiceValue(null);
    setAreaValue(null);

    dispatch(orderActions.setValue({ name: 'querySynthetic', value: {} }));
    dispatch(
      timelineActions.setValueTimelineReducer({
        name: 'filterTitle',
        value: [],
      })
    );
  };
  const handleClose = () => {};
  const handleFilter = () => {
    const query = {
      'filters[$and][3][createdAt][$gte]': !!startDate
        ? new Date(startDate).toISOString()
        : '',
      'filters[$and][4][createdAt][$lte]': !!endDate
        ? new Date(endDate).toISOString()
        : '',
      'filters[$and][5][organization][id]': organizationValue?.value ?? '',
      'filters[$and][6][codeStaff1][id]': codeStaff1Value?.value ?? '',
      'filters[$and][7][codeStaff2][id]': codeStaff2Value?.value ?? '',
      'filters[$and][8][bill][paymentStatus][$eq]': statusPayment?.value ?? '',
      'filters[$and][9][status][$eq]': statusValue?.value ?? '',
      'filters[$and][10][specifyVote][codeTest][$eq]':
        serviceValue?.value ?? '',
      'filters[$and][11][areaName][$containsi]': areaValue?.label ?? '',
    };
    dispatch(orderActions.setValue({ name: 'querySynthetic', value: query }));
    const filterTitle = [
      {
        title: 'Thời gian',
        value: `${!!startDate && !endDate ? 'Từ ' : ''}${
          !!startDate
            ? moment(startDate).format('DD/MM/YYYY')
            : !!endDate
            ? 'Đến '
            : ''
        }${!!startDate && !!endDate ? ' - ' : ''}${
          !!endDate ? moment(endDate).format('DD/MM/YYYY') : ''
        }`,
      },
      {
        title: 'Phòng khám/bệnh viện',
        value: organizationValue?.label ?? '',
      },
      { title: 'Sale', value: codeStaff1Value?.label ?? '' },
      { title: 'Thu mẫu', value: codeStaff2Value?.label ?? '' },
      { title: 'Trạng thái thanh toán', value: statusPayment?.label ?? '' },

      { title: 'Trạng thái đơn hàng', value: statusValue?.label ?? '' },

      { title: 'Dịch vụ', value: serviceValue?.label ?? '' },

      { title: 'Khu vực', value: areaValue?.label ?? '' },
    ];
    dispatch(
      timelineActions.setValueTimelineReducer({
        name: 'filterTitle',
        value: filterTitle,
      })
    );
  };
  return (
    <FilterCustom
      handleClear={handleClear}
      handleClose={handleClose}
      handleFilter={handleFilter}
    >
      <FormGroup>
        <LabelCustom
          label={'Chọn thời gian'}
          style={{ marginBottom: 0 }}
          styleLabel={{}}
        />
        <div className="date-picker-custom d-flex">
          <LabelCustom
            label={'Từ:'}
            style={{ textAlign: 'end', margin: '0.5em 8px 0px 0px' }}
            styleLabel={{}}
          />
          <DatePicker
            customInput={<CustomInputCalendar />}
            wrapperClassName="icon-calendar"
            dateFormat={'dd/MM/yyyy'}
            selected={startDate}
            maxDate={endDate}
            onChange={(date) => {
              const startOfDate = moment(date).startOf('date').toDate();
              setStartDate(startOfDate);
            }}
          />
          <LabelCustom
            label={'đến:'}
            style={{ textAlign: 'end', margin: '0.5em 8px 0px 0px' }}
            styleLabel={{
              minWidth: 60,
            }}
          />
          <DatePicker
            dateFormat={'dd/MM/yyyy'}
            customInput={<CustomInputCalendar />}
            wrapperClassName="icon-calendar"
            minDate={startDate}
            selected={endDate}
            onChange={(date) => {
              const endOfDate = moment(date).endOf('date').toDate();
              setEndDate(endOfDate);
            }}
          />
        </div>
      </FormGroup>
      <FormGroup>
        <SelectCustom
          name="organization"
          label="Chọn phòng khám/Bệnh viện"
          placeholder="Lựa chọn"
          value={organizationValue}
          isRequired={false}
          isClearable={true}
          handleChange={(e) => {
            setOrganizationValue(e);
          }}
          onInputChange={(value) => {
            setOrganizationSearch(value);
          }}
          options={organizationResults.map((item) => {
            return { label: item?.name ?? '', value: item?.id ?? '' };
          })}
        />
      </FormGroup>
      <FormGroup>
        <SelectCustom
          name="codeStaff1"
          label="Chọn Sale"
          placeholder="Lựa chọn"
          value={codeStaff1Value}
          isRequired={false}
          isClearable={true}
          handleChange={(e) => {
            setCodeStaff1Value(e);
          }}
          onInputChange={(value) => {
            setCodeStaff1Search(value);
          }}
          options={codeStaff1Results.map((item) => {
            return {
              label: `${item?.fullname ?? ''}`,
              value: item?.id ?? '',
            };
          })}
        />
      </FormGroup>
      {!isSampleCollectorStaff && (
        <FormGroup>
          <SelectCustom
            name="codeStaff2"
            label="Chọn nhân viên thu mẫu"
            placeholder="Lựa chọn"
            value={codeStaff2Value}
            isRequired={false}
            isClearable={true}
            handleChange={(e) => {
              setCodeStaff2Value(e);
            }}
            onInputChange={(value) => {
              setCodeStaff2Search(value);
            }}
            options={codeStaff2Results.map((item) => {
              return {
                label: `${item?.fullname ?? ''}`,
                value: item?.id ?? '',
              };
            })}
          />
        </FormGroup>
      )}
      <FormGroup>
        <SelectCustom
          name="statusPayment"
          label="Chọn trạng thái thanh toán"
          placeholder="Lựa chọn"
          value={statusPayment}
          isRequired={false}
          isClearable={true}
          handleChange={(e) => {
            setStatusPayment(e);
          }}
          options={CONSTANT.PAYMENT_STATUS}
        />
      </FormGroup>
      <FormGroup>
        <SelectCustom
          name="status"
          label="Chọn trạng thái"
          placeholder="Lựa chọn"
          value={statusValue}
          isRequired={false}
          isClearable={true}
          handleChange={(e) => {
            setStatusValue(e);
          }}
          options={CONSTANT.ORDER_STATUS}
        />
      </FormGroup>
      <FormGroup>
        <SelectCustom
          name="service"
          label="Chọn dịch vụ"
          placeholder="Lựa chọn"
          value={serviceValue}
          isRequired={false}
          isClearable={true}
          handleChange={(e) => {
            setServiceValue(e);
          }}
          onInputChange={(value) => {
            setServiceSearch(value);
          }}
          options={serviceResults.map((item) => {
            return {
              label: item.name,
              value: item?.code ?? '',
            };
          })}
        />
      </FormGroup>
      <FormGroup>
        <SelectCustom
          name="area"
          label="Chọn khu vực"
          placeholder="Lựa chọn"
          value={areaValue}
          isRequired={false}
          isClearable={true}
          handleChange={(e) => {
            setAreaValue(e);
          }}
          onInputChange={(value) => {
            setAreaSearch(value);
          }}
          options={areaResults.map((item) => {
            return {
              label: item.name,
              value: item?.id ?? '',
            };
          })}
        />
      </FormGroup>
    </FilterCustom>
  );
};

export default Filter;
