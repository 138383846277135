import React, { memo } from 'react';
import { Button, Col, Modal, Row } from 'reactstrap';
import _ from 'lodash';
import { useHistory } from 'react-router';

import { BASE_URL_IMAGE } from 'services/ServiceURL';
import LabelValue from 'views/pages/components/LabelValue';

const DAdditionalSample = ({
  isVisibleD,
  setIsVisibleD,
  additionalSample,
  setAdditionalSample,
}) => {
  const history = useHistory();
  // Xử lý đóng form
  const handleClose = () => {
    setIsVisibleD(false);
    setAdditionalSample(null);
  };

  const handleZoomPcd = () => {
    const modal = document.getElementById('modal-pcd');
    const img = document.getElementById('pcd');
    const modalImg = document.getElementById('zoom-pcd');
    img.onclick = function () {
      modal.style.display = 'block';
      modalImg.src = this.src;
      modalImg.alt = this.alt;
    };

    // When the user clicks on <span> (x), close the modal
    modal.onclick = function () {
      modalImg.className += ' out';
      setTimeout(function () {
        modal.style.display = 'none';
        modalImg.className = 'modal-content-pcd';
      }, 400);
    };
  };
  let image = `${BASE_URL_IMAGE}${_.get(additionalSample, 'image[0].url', '')}`;
  if (!_.get(additionalSample, 'image[0].url', undefined))
    image = require('assets/img/default/pcd_default.png').default;

  return (
    <Modal
      size={'lg'}
      isOpen={isVisibleD}
      className="modal-dialog-centered modal-secondary"
      toggle={() => {
        handleClose();
      }}
    >
      <div className="modal-header badditionalSample-bottom bg-white pb-2">
        <h2>Chi tiết mẫu bổ sung</h2>
        <button
          aria-label="Close"
          className="close"
          data-dismiss="modal"
          type="button"
          onClick={() => {
            handleClose();
          }}
        >
          <span style={{ color: '#262626' }} aria-hidden={true}>
            ×
          </span>
        </button>
      </div>
      <div className="modal-body bg-white rounded">
        <Row>
          <Col xs={4}>
            <LabelValue
              label={'Mã đơn hàng'}
              value={additionalSample?.order?.code ?? ''}
              labelWidth={120}
              textAlign={'left'}
            />

            <LabelValue
              label={'Số mẫu bổ sung'}
              value={additionalSample?.numOfSpecimen ?? ''}
              labelWidth={120}
              textAlign={'left'}
            />

            <LabelValue
              label={'Mã barcode'}
              value={additionalSample?.barcode?.barcode ?? ''}
              labelWidth={120}
              textAlign={'left'}
            />
            <Button
              onClick={() => {
                history.push(
                  `/admin/order-detail/${additionalSample.order.id}?tab=order-all`
                );
              }}
              style={{ fontSize: 10 }}
              color="info"
              size="sm"
            >
              Đi đến đơn hàng
            </Button>
          </Col>
          <Col xs={8}>
            <LabelValue
              label="Ảnh phiếu xét nghiệm và mẫu"
              value={''}
              labelWidth={180}
            />
            <img
              id="pcd"
              alt="..."
              className="shadow"
              onClick={handleZoomPcd}
              src={image}
              style={{
                width: '100%',
                height: 'auto',
                borderRadius: 4,
                cursor: 'pointer',
              }}
            />
            <div id="modal-pcd" class="modal-pcd">
              <img
                src={undefined}
                alt="zoom-pcd"
                class="modal-content-pcd"
                id="zoom-pcd"
              />
            </div>
          </Col>
        </Row>
        <div className="mb-0 pb-0 mt-4 d-flex justify-content-end">
          <Button
            className="text-nowrap"
            onClick={() => {
              handleClose();
            }}
          >
            Đóng
          </Button>
        </div>
      </div>
    </Modal>
  );
};

export default memo(DAdditionalSample);
