import React, { memo, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Button, Col, Form, Modal, Row } from 'reactstrap';
import * as yup from 'yup';
import { Formik } from 'formik';
import _ from 'lodash';
import queryString from 'query-string';

import { notificationAlertRef, notify, relationOneToOne } from 'common';
import {
  accountActions,
  formActions,
  orderActions,
  partnerActions,
} from 'redux/actions';
import LoadingButtonCustom from 'views/pages/components/LoadingButtonCustom';
import CONSTANT from 'constant';
import SelectCustom from 'views/pages/components/SelectCustom';

const TransferXN = ({ handleGetOrders }) => {
  const dispatch = useDispatch();

  // reducers
  const { currentAccountExtend } = useSelector((state) => state.accountReducer);
  const { isModalVisible, modalInfo } = useSelector(
    (state) => state.formReducer
  );
  const { isUpdatingOrder } = useSelector((state) => state.orderReducer);

  // Khởi tạo dữ liêu
  const initInfo = {
    doctor: null,
    partner: null,
  };

  const [doctorSearch, setDoctorSearch] = useState('');
  const [partnerSearch, setPartnerSearch] = useState('');

  const [doctorValue, setDoctorValue] = useState(null);
  const [partnerValue, setPartnerValue] = useState(null);

  const [doctorResults, setDoctorResults] = useState([]);
  const [partnerResults, setPartnerResults] = useState([]);

  // validate dữ liêu
  const infoSchema = yup.object().shape({
    // doctor: yup
    //   .number()
    //   .min(0, 'Bác sĩ chỉ định không được để trống')
    //   .required('Bác sĩ chỉ định không được để trống!')
    //   .nullable(),
    partner: yup
      .number()
      .min(0, 'Đối tác xét nghiệm không được để trống')
      .required('Đối tác xét nghiệm không được để trống!')
      .nullable(),
  });

  // Xử lý submit
  const onSubmit = (values) => {
    const body = {
      doctor: relationOneToOne(values.doctor, null),
      partner: relationOneToOne(values.partner, null),
      status: CONSTANT.ORDER_STATUS[5].value,
      ids: modalInfo.ids.slice(1),
    };
    dispatch(
      orderActions.updateOrder(modalInfo.ids[0], body, {
        success: () => {
          notify(
            notificationAlertRef,
            'success',
            'Thông báo',
            `Cập nhật đơn hàng thành công!`
          );
          handleGetOrders();
          handleClose();
        },
        failed: (mess) => {
          notify(
            notificationAlertRef,
            'danger',
            'Thông báo',
            `Cập nhật đơn hàng thất bại. Lỗi: ${mess}!`
          );
        },
      })
    );
  };

  // Xử lý đóng form
  const handleClose = () => {
    setPartnerValue(null);
    setDoctorValue(null);
    dispatch(
      formActions.setValue({
        name: 'isModalVisible',
        value: false,
      })
    );
    dispatch(
      formActions.setValue({
        name: 'modalInfo',
        value: {},
      })
    );
  };

  // get orgs
  // useEffect(() => {
  //   const delayDebounceFn = setTimeout(() => {
  //     handleGetDoctors();
  //   }, 500);

  //   return () => clearTimeout(delayDebounceFn);
  // }, [doctorSearch]);

  // const handleGetDoctors = () => {
  //   const query = {
  //     'filters[$and][1][accountType][$eq]': CONSTANT.ACCOUNT_TYPE[4].value,
  //     'filters[$and][2][organization][id]': CONSTANT.HTGEN_ID,
  //     'filters[$and][3][isActive][$eq]': true,
  //   };
  //   if (doctorSearch !== '') {
  //     query['filters[$and][4][code][$containsi]'] = doctorSearch;
  //   }

  //   dispatch(
  //     accountActions.getAccountsExtend(
  //       queryString.stringify(query),
  //       {
  //         success: (data) => {
  //           const results = _.get(data, 'results', []);
  //           setDoctorResults(results);
  //         },
  //         failed: () => {},
  //       },
  //       false
  //     )
  //   );
  // };

  // get Nhân viên thu mẫu
  useEffect(() => {
    const delayDebounceFn = setTimeout(() => {
      handleGetPartners();
    }, 500);

    return () => clearTimeout(delayDebounceFn);
  }, [partnerSearch]);

  const handleGetPartners = () => {
    const query = {
      // 'filters[$and][0][accountType][$eq]': 0,
    };
    if (partnerSearch !== '') {
      query['filters[$and][1][code][$containsi]'] = partnerSearch;
    }

    dispatch(
      partnerActions.getPartners(
        queryString.stringify(query),
        {
          success: (data) => {
            const results = _.get(data, 'results', []);
            setPartnerResults(results);
          },
          failed: () => {},
        },
        false
      )
    );
  };

  const getRelationPartners = (id) => {
    if (id === -1) return;
    dispatch(
      accountActions.getAccountExtendsByPartner(id, {
        success: (data) => {
          setDoctorResults(
            data.map((item) => ({
              label: `${item?.fullname ?? ''}`,
              value: item.id,
              ...item,
            }))
          );
        },
        failed: () => {},
      })
    );
  };

  return (
    <Modal
      style={{
        maxWidth: 400,
      }}
      isOpen={isModalVisible}
      className="modal-dialog-centered modal-secondary"
      toggle={() => {
        handleClose();
      }}
    >
      <div className="modal-header border-bottom bg-white pb-2">
        <h2>Chuyển mẫu xét nghiệm</h2>
        <button
          aria-label="Close"
          className="close"
          data-dismiss="modal"
          type="button"
          onClick={() => {
            handleClose();
          }}
        >
          <span style={{ color: '#262626' }} aria-hidden={true}>
            ×
          </span>
        </button>
      </div>
      <div className="modal-body bg-white rounded">
        <Formik
          initialValues={initInfo}
          enableReinitialize
          onSubmit={onSubmit}
          validationSchema={infoSchema}
        >
          {({ errors, touched, handleSubmit, handleBlur, setFieldValue }) => {
            return (
              <Form onSubmit={handleSubmit} className="">
                <Row>
                  <Col xs={12}>
                    <SelectCustom
                      name="partner"
                      label="Đối tác xét nghiệm (Lab)"
                      placeholder="Lựa chọn"
                      value={partnerValue}
                      isRequired={true}
                      isClearable={false}
                      handleChange={(e) => {
                        setFieldValue('partner', e?.value ?? null);
                        setPartnerValue(e);
                        getRelationPartners(e?.value ?? -1);
                      }}
                      handleBlur={handleBlur}
                      onInputChange={(value) => {
                        setPartnerSearch(value);
                      }}
                      error={errors.partner}
                      touched={touched.partner}
                      options={partnerResults.map((item) => ({
                        label: item.name,
                        value: item?.id,
                      }))}
                    />
                  </Col>
                </Row>
                <Row>
                  <Col xs={12}>
                    <SelectCustom
                      name="doctor"
                      label="Bác sĩ chỉ định"
                      placeholder="Lựa chọn"
                      value={doctorValue}
                      isClearable={false}
                      isDisabled={!partnerValue}
                      handleChange={(e) => {
                        setFieldValue('doctor', e?.value ?? null);
                        setDoctorValue(e);
                      }}
                      handleBlur={handleBlur}
                      onInputChange={(value) => {
                        setDoctorSearch(value);
                      }}
                      error={errors.doctor}
                      touched={touched.doctor}
                      options={doctorResults.map((item) => ({
                        label: `${item?.fullname ?? ''}`,
                        value: item?.id,
                      }))}
                    />
                  </Col>
                </Row>

                <div className="mb-0 pb-0 mt-4 d-flex justify-content-end">
                  <Button
                    className="text-nowrap mr-1"
                    onClick={() => {
                      handleClose();
                    }}
                  >
                    Hủy
                  </Button>
                  <LoadingButtonCustom
                    color="info"
                    className="text-nowrap "
                    type="submit"
                    loading={isUpdatingOrder}
                  >
                    Chuyển xét nghiệm
                  </LoadingButtonCustom>
                </div>
              </Form>
            );
          }}
        </Formik>
      </div>
    </Modal>
  );
};

export default memo(TransferXN);
