import { returnValue } from "common";
import typeActions from "redux/typeActions";
const initialState = {
  medicalrecords: null,
  currentMedicalRecord: null,
  pagination: {},
  // form show/delete
  isModalVisible: false,
  modalInfo: {},
  page: 1,
  limit: 10,
  query: {
    populate: "*",
  },
};
const medicalRecordReducer = (state = initialState, actions) => {
  switch (actions.type) {
    case typeActions.SET_MEDICALRECORD_VALUE:
      const nameMedicalRecord = actions.name;
      const valueMedicalRecord = actions.value;
      if (typeof nameMedicalRecord !== "string") return { ...state };
      const objMedicalRecord = returnValue(state, nameMedicalRecord, valueMedicalRecord);
      return {
        ...state,
        ...objMedicalRecord,
      };
    case typeActions.GET_MEDICALRECORD_REQUEST:
      return {
        ...state,
        ...actions?.data,
      };
    case typeActions.GET_MEDICALRECORD_SUCCESS:
      return {
        ...state,
        medicalrecords: actions.data.results,
        pagination: actions.data.pagination,
        page: actions.data.pagination.page,
        limit: actions.data.pagination.pageSize
      };
    case typeActions.GET_MEDICALRECORD_FAILED:
      return {
        ...state,
      };

    case typeActions.GET_MEDICALRECORD_BY_ID_SUCCESS: 
      return {
        ...state,
        currentMedicalRecord: actions.data
      }
    // create
    
    case typeActions.CREATE_MEDICALRECORD_REQUEST:
      return {
        ...state,
        errors: {
          ...state.errors,
          createAccount: '',
        },
      };
    case typeActions.CREATE_MEDICALRECORD_SUCCESS:
      return {
        ...state,
        errors: {
          ...state.errors,
          createAccount: '',
        },
      };
    case typeActions.CREATE_MEDICALRECORD_FAILED:
      return {
        ...state,
        errors: {
          ...state.errors,
          createAccount: actions.error,
        },
      };

    //delete
    case typeActions.DELETE_MEDICALRECORD_REQUEST:
      return {
        ...state,
      };
    case typeActions.DELETE_MEDICALRECORD_SUCCESS:
      return {
        ...state,
      };
    case typeActions.DELETE_MEDICALRECORD_FAILED:
      return {
        ...state,
      };
    default:
      return {
        ...state,
      };
  }
};

export default medicalRecordReducer;
