import React from 'react';
import _ from 'lodash';
import { CardHeader, Col, Row } from 'reactstrap';

import 'assets/css/custom-pages/react-bs-table.css';
import LabelValue from 'views/pages/components/LabelValue';
import CONSTANT from 'constant';
import { useSelector } from 'react-redux';

const LSInfo = ({}) => {
  const { order } = useSelector((state) => state.orderReducer);
  const medicationsUsingValue = CONSTANT.MEDICATIONS_USING.filter((item) =>
    (order?.specifyVote?.medicationsUsing ?? []).includes(item.value)
  );
  return (
    <>
      <Row>
        <CardHeader className="pb-2 d-flex justify-content-between rounded border-bottom w-100">
          <h3>Thông tin lâm sàng</h3>
        </CardHeader>
      </Row>
      <Row>
        <Col xs={6}>
          <LabelValue
            labelWidth={160}
            label="Chiều cao (cm)"
            value={
              order?.specifyVote?.height === 0 ? '' : order?.specifyVote?.height
            }
          />
        </Col>
        <Col xs={6}>
          <LabelValue
            labelWidth={160}
            label="Cân nặng (kg)"
            value={
              order?.specifyVote?.weight === 0 ? '' : order?.specifyVote?.weight
            }
          />
        </Col>
      </Row>
      <Row>
        <Col xs={12}>
          <LabelValue
            labelWidth={160}
            label="Tiền sử bệnh nhân"
            value={order?.specifyVote?.anamnesisBA ?? ''}
          />
        </Col>
      </Row>
      <Row>
        <Col xs={12}>
          <LabelValue
            labelWidth={160}
            label="Tiền sử gia đình"
            value={order?.specifyVote?.familyHistory ?? ''}
          />
        </Col>
      </Row>
      <Row>
        <Col xs={12}>
          <LabelValue
            labelWidth={160}
            label="Tiếp xúc độc hại"
            value={order?.specifyVote?.lifeStyle ?? ''}
          />
        </Col>
      </Row>

      <Row>
        <Col xs={12}>
          <LabelValue
            labelWidth={160}
            label="Bệnh lý cấp tính"
            value={order?.specifyVote?.pathological1 ?? ''}
          />
        </Col>
      </Row>

      <Row>
        <Col xs={12}>
          <LabelValue
            labelWidth={160}
            label="Bệnh lý mãn tính"
            value={order?.specifyVote?.pathological2 ?? ''}
          />
        </Col>
      </Row>
      <Row>
        <Col xs={12}>
          <LabelValue
            labelWidth={160}
            label="Tiền sử bệnh"
            value={order?.specifyVote?.anamnesis ?? ''}
          />
        </Col>
      </Row>
      <Row>
        <Col xs={12}>
          <LabelValue
            labelWidth={160}
            label="Thuốc đang dùng"
            value={medicationsUsingValue.map((item, index) => {
              return index === medicationsUsingValue.length - 1
                ? item?.label ?? ''
                : `${item?.label ?? ''} - `;
            })}
          />
          {/* <LabelValue
            labelWidth={160}
            label={"Thời gian bệnh (Ngày)"}
            value={
              order?.specifyVote?.time === 0 ? "" : order?.specifyVote?.time
            }
          /> */}
        </Col>
      </Row>
    </>
  );
};

export default LSInfo;
