import _ from 'lodash';

import CONSTANT from './constant';
import { BASE_URL_IMAGE } from 'services/ServiceURL';

export const emailRegex = /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/g;

export let notificationAlertRef = null;
export const setNotificationAlertRef = (ref) => {
  notificationAlertRef = ref;
};
export const notify = (notificationAlertRef, type, title, message) => {
  try {
    let options = {
      place: 'tc',
      message: (
        <div className="alert-text">
          <span className="alert-title" data-notify="title">
            {title}
          </span>
          <span data-notify="message">{message}</span>
        </div>
      ),
      type: type,
      icon: 'ni ni-bell-55',
      autoDismiss: 7,
    };
    notificationAlertRef.current.notificationAlert(options);
  } catch (error) {}
};

export const returnValue = (obj, name, value) => {
  const tmp = _.cloneDeep(obj);
  tmp[name] = value;
  return tmp;
};

export const checkSupperAdmin = (code = '') => {
  return code === CONSTANT.ROLE_SUPPER_ADMIN;
};

export const currencyFormat = (num) => {
  try {
    return num.toFixed(0).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1.');
  } catch (error) {
    return 0;
  }
};
export const paginationTitle = (page, limit, pagination) => {
  const from = (page - 1) * limit + 1;
  const remain = pagination.total - from + 1;
  const to = remain <= pagination?.total ? pagination?.total : page * limit;
  return (
    <p>
      Hiển thị từ {from} đến {to} trong số {pagination?.total} bản ghi.
    </p>
  );
};

/**
 *
 * @param {String | null} idConnect
 * @param {String | null} idDisconnect
 * @returns
 */
export const relationOneToOne = (idConnect = null, idDisconnect = null) => {
  if (idConnect === null && idDisconnect === null) return null;
  const relation = {};
  if (idDisconnect !== null) {
    relation.disconnect = [{ id: idDisconnect }];
  } else {
    relation.disconnect = [];
  }
  if (idConnect !== null) {
    relation.connect = [{ id: idConnect, position: { end: true } }];
  } else {
    relation.connect = [];
  }

  return relation;
};

export const relationOneToMany = (idsConnect = null, idsDisconnect = null) => {
  if (idsConnect === null && idsDisconnect === null) return null;
  const relation = {};
  if (idsDisconnect !== null) {
    relation.disconnect = idsDisconnect.map((item) => ({ id: item }));
  } else {
    relation.disconnect = [];
  }
  if (idsConnect !== null) {
    relation.connect = idsConnect.map((item, index) => {
      const value = { id: item };
      if (index === idsConnect.length - 1) {
        value.position = { end: true };
      } else {
        value.position = { before: idsConnect[index + 1] };
      }
      return value;
    });
  } else {
    relation.connect = [];
  }

  return relation;
};

export const isNumeric = (value) => {
  if (value === '') return true;

  return !isNaN(Number(value)) && /^-?\d+$/.test(value);
};

/**
 * Convert [{id:'',name:'',...}] to [{label: '',value: ''}]
 * @param {Array<Object>} arr : array data
 * @param {Array<string>} label : properties of an element object in "arr"
 * @param {Array<string>} value : properties of an element object in "arr"
 * iterate over each element in the "arr":
 *  -> label/value = ["firstname+lastname"] = element.firstname + element.lastname
 *  -> value = ["name","lastname"] = element.name.firstname
 * @returns {Array<Object>}
 */
export function getListSelectOption(arr, label, value, character = ' ') {
  if (!Array.isArray(arr) || !Array.isArray(label) || !Array.isArray(value))
    return [];
  try {
    return arr.map((item) => {
      let lb = item,
        vl = item;
      label.map((_) => {
        if (_.includes('+')) {
          let temp = _.split('+').map((__) => {
            return lb[__];
          });
          if (character.includes('()')) {
            temp[0] = `(${temp[0]})`;
            lb = temp.join(' ');
          } else {
            lb = temp.join(character);
          }
        } else lb = lb[_];
      });
      value.map((_) => {
        vl = vl[_];
      });
      return {
        label: lb,
        value: vl,
      };
    });
  } catch (error) {
    return [];
  }
}

export const deleteFieldEmpty = (body) => {
  for (const key in body) {
    if (body[key] === '') {
      body[key] = null;
    }
  }
};

export const isSampleCollectorStaff = (currentAccountExtend) => {
  const accountType = _.get(currentAccountExtend, 'accountType', -1);
  return accountType === CONSTANT.ACCOUNT_TYPE[0].value;
};

export const isBusinessStaff = (currentAccountExtend) => {
  const accountType = _.get(currentAccountExtend, 'accountType', -1);
  return accountType === CONSTANT.ACCOUNT_TYPE[1].value;
};

export const isManageStaff = (currentAccountExtend) => {
  const accountType = _.get(currentAccountExtend, 'accountType', -1);
  return accountType === CONSTANT.ACCOUNT_TYPE[2].value;
};

export const isAccountantStaff = (currentAccountExtend) => {
  const accountType = _.get(currentAccountExtend, 'accountType', -1);
  return accountType === CONSTANT.ACCOUNT_TYPE[3].value;
};

export const isDoctorHT = (currentAccountExtend) => {
  const accountType = _.get(currentAccountExtend, 'accountType', -1);
  return accountType === CONSTANT.ACCOUNT_TYPE[4].value;
};

export const isDoctorKH = (currentAccountExtend) => {
  const accountType = _.get(currentAccountExtend, 'accountType', -1);
  return accountType === CONSTANT.ACCOUNT_TYPE[5].value;
};

export const isCustomer = (currentAccountExtend) => {
  const accountType = _.get(currentAccountExtend, 'accountType', -1);
  return accountType === CONSTANT.ACCOUNT_TYPE[5].value;
};
/**
 *
 * @param {*} preObj
 * @param {*} nextObj
 * @returns true if preObj equals to nextObj
 */
export const checkObjectChange = (preObj, nextObj) => {
  return Object.keys(nextObj).every((key) => {
    if (!_.isEqual(nextObj[`${key}`], preObj[`${key}`])) {
    }
    return _.isEqual(nextObj[`${key}`], preObj[`${key}`]);
  });
};

export const queryFilter = (obj) => {
  const keys = Object.keys(obj);
  const newObj = {};
  keys.forEach((key) => {
    if (!!obj[`${key}`]) {
      newObj[`${key}`] = obj[`${key}`];
    }
  });
  return newObj;
};

const fieldError = [
  {
    en: 'code',
    vi: 'Mã',
  },
];

export const convertMessageError = (type, error, field = ' ') => {
  const status = _.get(error, 'response.data.error.status', -1);
  const errors = _.get(error, 'response.data.error.details.errors', '');
  switch (type) {
    case 'c':
      if (status === 400) {
        const mess = errors.map((item) => {
          const message = item?.message ?? '';
          if (message.includes('This attribute must be unique')) {
            const path = _.get(item, 'path', []);
            return path.map((v) => {
              const fe = fieldError.find((f) => f.en === v);
              if (!!fe) {
                return `${fe.vi}${field}đã tồn tại`;
              }
              return 'đã tồn tại';
            });
          }
          return '';
        });

        return mess.map((item, index) =>
          index === mess.length - 1 ? `${item}.` : `${item}, `
        );
      }
      break;
    case 'r':
      break;
    case 'u':
      break;
    case 'd':
      break;
  }
};

export const checkRole = (currentAccountExtend, prop) => {
  // return true;
  let validRole = false;
  const roles = _.get(prop, 'roles', []);
  const permissions = _.get(
    currentAccountExtend,
    'decentralization.permissions',
    []
  );
  permissions.every((item) => {
    if (roles.indexOf(item) !== -1 || _.isEmpty(prop.roles)) {
      validRole = true;
      return false;
    }
    return true;
  });
  return validRole;
};

/**
 * @param {*} weekPregnancy Tuần thai
 * @param {*} dayOfPregnancy  Ngày thai
 * @param {*} time "week" : "day" Lấy tuần hay ngày ?
 */
export function getPregnancy(weekPregnancy, dayOfPregnancy, time) {
  let numWeek = weekPregnancy;
  let numDay = dayOfPregnancy;
  if (typeof numWeek === 'string') {
    numWeek = parseInt(!!numWeek ? numWeek : '0');
  }
  if (typeof numDay === 'string') {
    numDay = parseInt(!!numDay ? numDay : '0');
  }
  if (time === 'week') return Math.floor(numDay / 7) + numWeek;
  return numDay % 7;
}

// /**
//  * Tính tuần thai dựa vào số ngày của thai
//  * @param {*} totalPregnancy : Số ngày thai
//  * @returns
//  */
// export const getDayOfPregnancy = (totalPregnancy) => {
//   let numWeek = totalPregnancy;
//   if (typeof numWeek === "string") {
//     numWeek = parseInt(!!numWeek ? numWeek : "0");
//     return numWeek % 7;
//   }
//   if (typeof numWeek === "number") return numWeek % 7;
//   return 0;
// };

// /**
//  * Tính ngày thai dựa vào số ngày của thai
//  * @param {*} weekPregnancy : số ngày thai
//  * @returns
//  */
// export const getWeekPregnancy = (weekPregnancy) => {
//   let numWeek = weekPregnancy;
//   if (typeof numWeek === "string") {
//     numWeek = parseInt(!!numWeek ? numWeek : "0");
//     return Math.floor(numWeek / 7);
//   }
//   if (typeof numWeek === "number") {
//     return Math.floor(numWeek / 7);
//   }
//   return 0;
// };
// /**
//  * Tính số ngày thai dựa vào số tuần và số ngày.
//  * @param {*} weekPregnancy
//  * @param {*} dayOfPregnancy
//  * @returns
//  */
// export const getTotalPregnancy = (weekPregnancy, dayOfPregnancy) => {
//   const week = parseInt(!!weekPregnancy ? weekPregnancy.toString() : "0");
//   const day = parseInt(!!dayOfPregnancy ? dayOfPregnancy.toString() : "0");
//   return week * 7 + day;
// };

export const isPCDNS = (values) => {
  if (values.toString() === '0') {
    return true;
  }
  return false;
};
export const isPCDBL = (values) => {
  if (values.toString() === '2') {
    return true;
  }
  return false;
};

/**
 * Lấy query để lấy danh sách phiếu xét nghiệm của các nhân viên cấp dưới
 * của tài khoản quản lý.
 * @param {*} id : tài khoản đang đăng nhập
 */
export function getQueryByChildrentRelationAccountExtendSP(
  otherQuery,
  childrentByCurrentAccountExtend
) {
  if (!_.isArray(childrentByCurrentAccountExtend)) return [];

  const arrIdBS = childrentByCurrentAccountExtend.map((relation, index) => {
    otherQuery[`filters[$and][69][$or][${index}][idBS][$eq]`] = _.get(
      relation,
      'id',
      ''
    );
  });
  return [...arrIdBS];
}

export const handleDownloadLink = (item) => {
  if (!item?.url) {
    return;
  }
  const pdfUrl = `${BASE_URL_IMAGE}${item?.url}`;
  window.open(pdfUrl, '_blank');
};
