import React, { memo, useEffect, useState } from 'react';
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory from 'react-bootstrap-table2-paginator';
import filterFactory from 'react-bootstrap-table2-filter';
import ToolkitProvider from 'react-bootstrap-table2-toolkit';
import _ from 'lodash';
import {
  Card,
  CardHeader,
  Container,
  Input,
  Row,
  UncontrolledTooltip,
} from 'reactstrap';
import queryString from 'query-string';

import { checkRole, notificationAlertRef, notify } from 'common';
import { serviceActions, formActions } from 'redux/actions';
import SimpleHeader from 'components/Headers/SimpleHeader.js';
import 'assets/css/custom-pages/react-bs-table.css';
import { useDispatch, useSelector } from 'react-redux';
import Loading from 'views/pages/components/Loading';
import CUService from './components/CUService';
import { useDidUpdateEffect } from 'utils/HookUtils';
import CONSTANT from 'constant';
import { currencyFormat } from 'common';
import ModalWarningCustom from 'views/pages/components/ModalWarningCustom';

import IconEdit from 'assets/svgs/edit.svg';
import IconBin from 'assets/svgs/bin.svg';
import TableCustom from '../components/TableCustom';

function Service() {
  const dispatch = useDispatch();
  const { page, limit, services, isDeletingService } = useSelector(
    (state) => state.serviceReducer
  );
  const { currentAccountExtend } = useSelector((state) => state.accountReducer);

  const results = _.get(services, 'results', []);
  const total = _.get(services, 'pagination.total', 0);

  const [serviceSearch, setServiceSearch] = useState('');
  const [notificationModal, setNotificationModal] = useState(false);
  const [service, setService] = useState(null);

  const columns = [
    {
      dataField: 'code',
      text: 'MÃ DỊCH VỤ',
    },
    {
      dataField: 'name',
      text: 'TÊN DỊCH VỤ',
    },
    {
      dataField: 'specimens',
      text: 'MẪU XÉT NGHIỆM',
      formatter: (cell) => {
        if (!cell) return;
        const specimensValue = CONSTANT.SPECIMENS.filter((item) =>
          cell.includes(item.value)
        );
        return specimensValue.map((item, index) => {
          return index === specimensValue.length - 1
            ? `${item.label}.`
            : `${item.label} - `;
        });
      },
    },
    {
      dataField: 'content',
      text: 'NỘI DUNG XÉT NGHIỆM',
    },
    {
      dataField: 'time',
      text: 'THỜI GIAN LÀM VIỆC(NGÀY)',
      headerStyle: {
        textAlign: 'center',
      },
      style: {
        textAlign: 'center',
      },
    },
    {
      dataField: 'serviceGroup.name',
      text: 'NHÓM DỊCH VỤ',
      style: {
        textAlign: 'center',
      },
      headerStyle: {
        textAlign: 'center',
      },
    },
    {
      dataField: 'unitPrice',
      text: 'ĐƠN GIÁ(VNĐ)',
      formatter: (cell) => {
        return `${currencyFormat(cell)}`;
      },
      headerStyle: {
        textAlign: 'right',
      },
      style: {
        textAlign: 'right',
      },
    },
    {
      dataField: 'actions',
      text: 'HÀNH ĐỘNG',
      formatter: boxActions,
      headerStyle: {
        textAlign: 'center',
      },
      headerClasses: 'fixed-column',
      classes: 'fixed-column',
    },
  ];

  function boxActions(__, row) {
    return (
      <div className="d-flex justify-content-center">
        {checkRole(currentAccountExtend, {
          roles: ['update-service'],
        }) && (
          <>
            <button
              id={'edit' + row.id}
              onClick={() => {
                dispatch(
                  formActions.setValue({
                    name: 'modalInfo',
                    value: row,
                  })
                );
                dispatch(
                  formActions.setValue({
                    name: 'isModalVisible',
                    value: true,
                  })
                );
              }}
              className="btn p-0 btn-no-shadow mr-0"
            >
              <img src={IconEdit} className="mr-1" />
            </button>
            <UncontrolledTooltip
              delay={1}
              placement="top"
              target={'edit' + row.id}
            >
              Cập nhật dịch vụ
            </UncontrolledTooltip>
          </>
        )}
        {checkRole(currentAccountExtend, {
          roles: ['delete-service'],
        }) && (
          <>
            <button
              id={'lock' + row.id}
              onClick={() => {
                setService(row);
                setNotificationModal(true);
              }}
              className="btn p-0 btn-no-shadow mr-0"
            >
              <img src={IconBin} />
            </button>
            <UncontrolledTooltip
              delay={1}
              placement="top"
              target={'lock' + row.id}
            >
              Xóa dịch vụ
            </UncontrolledTooltip>
          </>
        )}
      </div>
    );
  }

  const onSizePerPageChange = (e) => {
    dispatch(serviceActions.setValue({ name: 'page', value: 1 }));
    dispatch(serviceActions.setValue({ name: 'limit', value: e.target.value }));
  };

  const onPageChange = (value) => {
    dispatch(serviceActions.setValue({ name: 'page', value: value }));
  };

  const pagination = paginationFactory({
    page: page,
    sizePerPage: limit,
    totalSize: total,
    showTotal: false,
    withFirstAndLast: false,
    alwaysShowAllBtns: true,
    onPageChange: onPageChange,
    sizePerPageRenderer: () => {
      return (
        <div className="dataTables_length" id="datatable-basic_length">
          <label>
            Hiển thị{' '}
            {
              <select
                name="datatable-basic_length"
                aria-controls="datatable-basic"
                className="form-control form-control-sm"
                onChange={onSizePerPageChange}
              >
                <option value="10">10</option>
                <option value="25">25</option>
                <option value="50">50</option>
                <option value="100">100</option>
              </select>
            }{' '}
            dòng.
          </label>
        </div>
      );
    },
  });

  useDidUpdateEffect(() => {
    handleGetServices();
  }, [page, limit]);

  useDidUpdateEffect(() => {
    // Sau 500ms thì bắn api
    const delayDebounceFn = setTimeout(() => {
      handleGetServices();
    }, 500);
    return () => clearTimeout(delayDebounceFn);
  }, [serviceSearch]);

  useEffect(() => {
    if (!_.isEmpty(services)) return;
    handleGetServices();
  }, []);

  const handleGetServices = () => {
    if (serviceSearch !== '') {
      dispatch(
        serviceActions.getServices(
          queryString.stringify({
            pageSize: limit,
            page,
            _q: serviceSearch,
          })
        )
      );
    } else {
      dispatch(
        serviceActions.getServices(
          queryString.stringify({ pageSize: limit, page })
        )
      );
    }
  };

  const handleDelete = () => {
    if (service === null) return;
    dispatch(
      serviceActions.updateService(
        service.id,
        { deleted: true },
        {
          success: () => {
            setNotificationModal(false);
            notify(
              notificationAlertRef,
              'success',
              'Thông báo',
              `Xóa dịch vụ ${service?.name} thành công!`
            );
            handleGetServices();
          },
          failed: (mess) => {
            notify(
              notificationAlertRef,
              'danger',
              'Thông báo',
              `Xóa dịch vụ ${service?.name} thất bại!. Lỗi: ${mess}`
            );
          },
        }
      )
    );
  };

  return (
    <>
      <SimpleHeader />
      <CUService handleGetServices={handleGetServices} />
      <ModalWarningCustom
        notificationModal={notificationModal}
        setNotificationModal={setNotificationModal}
        name={`dịch vụ ${service?.name ?? ''}`}
        func={handleDelete}
        isDelete={isDeletingService}
      />
      <Container className="mt--9" fluid>
        <Row>
          <div className="col">
            <TableCustom
              tableName="Danh sách dịch vụ"
              data={results}
              columns={columns}
              page={page}
              limit={limit}
              total={total}
              searchBy={'Tìm kiếm'}
              pagination={pagination}
              search={serviceSearch}
              onChange={(e) => {
                setServiceSearch(e.target.value);
              }}
            />
          </div>
        </Row>
      </Container>
    </>
  );
}

export default memo(Service);
