//core-componens
import React, { useCallback, useEffect, useState } from "react";
import { Card, CardBody, CardHeader, Col, Row } from "reactstrap";
//custom components
import LabelCustom from "views/pages/components/LabelCustom";
import InputCustom from "views/pages/components/InputCustom";
//module
import _ from "lodash";
import queryString from "query-string";
import { getListSelectOption } from "common";
import { notificationAlertRef, notify } from "common";
//styles
import "assets/css/custom-pages/specify-votes.css";
import "assets/css/custom-pages/create-specify-votes.css";
import { useSelector } from "react-redux";
import { useDelayInputEffect } from "utils/HookUtils";
import { patientActions } from "redux/actions";
import GeneralInfomationRight from "./GeneralInfomationRight";
import GeneralInfomationLeft from "./GeneralInfomationLeft";
import { useFormikContext } from "formik";
import LabelValue from "views/pages/components/LabelValue";

function ReadGeneralInfomation({ specifyVotes }) {
  return (
    <Card className="custom-row">
      <CardHeader className="py-3">
        <h3 className="mb-0">Thông tin chung</h3>
      </CardHeader>
      <CardBody className="px-4 py-3">
        <Row>
          <Col xs={3}>
            <LabelValue
              lableTextAlign={"right"}
              label={"Mẫu xét nghiệm"}
              labelWidth={80}
              value={
                specifyVotes?.testGR === 0
                  ? "Nhóm sản"
                  : specifyVotes?.testGR === 1
                  ? "Nhóm phôi"
                  : specifyVotes?.testGR === 2
                  ? "Nhóm bệnh lý"
                  : ""
              }
              row={true}
            />
          </Col>
          <Col xs={3}>
            <LabelValue
              lableTextAlign={"right"}
              label={"Mã phiếu"}
              labelWidth={80}
              value={specifyVotes?.codePCD ?? ""}
              row={true}
            />
          </Col>
        </Row>
        <Row>
          <GeneralInfomationLeft specifyVotes={specifyVotes} />
          <GeneralInfomationRight specifyVotes={specifyVotes} />
        </Row>
        
      </CardBody>
    </Card>
  );
}

export default ReadGeneralInfomation;
