import _ from 'lodash';
import { all, call, put, takeLatest } from 'redux-saga/effects';

import typeActions from 'redux/typeActions';
import ServiceURL from 'services/ServiceURL';
import { DELETE, GET, POST, PUT } from 'services/ServiceBase';

/**
 *
 * @param {*} data
 * get list staffs:
 * - failed -> call actions failed
 * - success ->
 *      + if (search in select-options) => return;
 *      + if (search in list staffs) => call actions sucess
 */
export function* getStaffs(data) {
  const url = `${ServiceURL.staff}?${data.query}&sort[0]=createdAt:DESC`;
  const callback = data.callback;
  const isUpdateReducer = data.isUpdateReducer;
  try {
    const res = yield call(GET, url);
    if (!!res.data?.error?.message) {
      yield put({
        type: typeActions.GET_STAFF_FAILED,
        error: res.data?.error?.message,
      });
      !!callback?.failed && callback.failed(res.data.error.message);
    } else {
      if (isUpdateReducer) {
        yield put({
          type: typeActions.GET_STAFF_SUCCESS,
          data: res.data,
        });
      }
      !!callback?.success && callback.success(res.data);
    }
  } catch (error) {
    yield put({
      type: typeActions.GET_STAFF_FAILED,
      error: error?.response?.data?.error?.message ?? '',
    });
    !!callback?.failed &&
      callback.failed(error?.response?.data?.error?.message ?? '');
  }
}

export function* createStaff(data) {
  const url = ServiceURL.staff;
  const callback = data.callback;
  try {
    const res = yield call(POST, url, data.params);
    if (!!res.data?.error?.message) {
      yield put({
        type: typeActions.CREATE_STAFF_FAILED,
        error: res.data.error.message,
      });

      !!callback?.failed && callback.failed(res.data.error.message);
    } else {
      yield put({
        type: typeActions.CREATE_STAFF_SUCCESS,
        data: res.data,
      });
      !!callback?.success && callback.success(res.data?.data);
    }
  } catch (error) {
    yield put({
      type: typeActions.CREATE_STAFF_FAILED,
      error: error?.response?.data?.error?.message ?? '',
    });
    !!callback?.failed &&
      callback.failed(error?.response?.data?.error?.message ?? '');
  }
}

export function* deleteStaff(data) {
  const url = ServiceURL.staff + '/' + data.params;
  const callback = data.callback;
  try {
    const res = yield call(DELETE, url);
    if (!!res.data?.error?.message) {
      yield put({
        type: typeActions.DELETE_STAFF_FAILED,
        error: res.data.error.message,
      });
      !!callback?.failed && callback.failed(res.data.error.message);
    } else {
      yield put({
        type: typeActions.DELETE_STAFF_SUCCESS,
      });
      !!callback?.success && callback.success();
    }
  } catch (error) {
    yield put({
      type: typeActions.DELETE_STAFF_FAILED,
      error: error?.response?.data?.error?.message ?? '',
    });
    !!callback?.failed &&
      callback.failed(error?.response?.data?.error?.message ?? '');
  }
}
export function* updateStaff(data) {
  const url = ServiceURL.staff + '/' + data.params;
  const callback = data.callback;
  const res = yield call(PUT, url, data.body);
  try {
    if (!!res.data?.error?.message) {
      yield put({
        type: typeActions.UPDATE_STAFF_FAILED,
        error: res.data.error.message,
      });
      !!callback?.failed && callback.failed(res.data.error.message);
    } else {
      yield put({
        type: typeActions.UPDATE_STAFF_SUCCESS,
        data: res.data?.data,
      });
      !!callback?.success && callback.success(res?.data?.data);
    }
  } catch (error) {
    yield put({
      type: typeActions.UPDATE_STAFF_FAILED,
      error: res.data.error.message,
    });
    !!callback?.failed && callback.failed(res.data.error.message);
  }
}
export default function* accountSaga() {
  yield takeLatest(typeActions.GET_STAFF_REQUEST, getStaffs);
  yield takeLatest(typeActions.CREATE_STAFF_REQUEST, createStaff);
  yield takeLatest(typeActions.DELETE_STAFF_REQUEST, deleteStaff);
  yield takeLatest(typeActions.UPDATE_STAFF_REQUEST, updateStaff);
}
