// core componet
import React, { useEffect, useState } from 'react';
import BootstrapTable from 'react-bootstrap-table-next';
import filterFactory from 'react-bootstrap-table2-filter';
import ToolkitProvider from 'react-bootstrap-table2-toolkit';
import { Card, CardHeader, Input, CardBody, Button } from 'reactstrap';
import _ from 'lodash';

// custom components
import Loading from 'views/pages/components/Loading';
import { handleDownloadLink, notificationAlertRef, notify } from 'common';
// css
import 'assets/css/custom-pages/react-bs-table.css';
import 'assets/css/custom-components/input-title-table.css';
//other
import CONSTANT from 'constant';
//module
//styles
import 'assets/css/custom-pages/specify-votes.css';
import 'assets/css/custom-pages/create-specify-votes.css';
//icon
import IconArrowDown from 'assets/svgs/arrowdown.svg';
import moment from 'moment';
import { additionalSampleActions, orderActions } from 'redux/actions';
import { useSelector, useDispatch } from 'react-redux';
import queryString from 'query-string';

function ListService({ patient }) {
  const dispatch = useDispatch();
  const { isPrintPkq, isGettingOrder } = useSelector(
    (state) => state.orderReducer
  );
  const { isDoctorKH, currentAccountExtend } = useSelector(
    (state) => state.accountReducer
  );
  const additionalSampleReducer = useSelector(
    (state) => state.additionalSampleReducer
  );

  if (!patient) return <></>;
  const headerStyle = {
    textAlign: 'center',
  };

  const columns = [
    {
      dataField: 'no',
      text: 'STT',
      formatter: (row, cell, rowIndex) => {
        return <div style={headerStyle}>{rowIndex + 1}</div>;
      },
      headerStyle,
    },
    {
      dataField: 'nameTest',
      text: 'TÊN DỊCH VỤ',
    },
    {
      dataField: 'firstNameBS',
      text: 'Bác sĩ chỉ định',
      formatter: (cell, row) => {
        return `${row.lastNameBS} ${cell}`;
      },
    },
    {
      dataField: 'createdAt',
      text: 'NGÀY XÉT NGHIỆM',
      formatter: (cell) => {
        return cell ? moment(new Date(cell)).format('DD/MM/YYYY') : '';
      },
    },
    {
      dataField: 'specifyVoteStatus',
      text: 'PHIẾU KẾT QUẢ',
      formatter: (cell, row) => {
        if (CONSTANT.SPECIFY_VOTE_STATUS[4].value === cell)
          return (
            <div
              style={{
                display: 'flex',
                justifyContent: 'center',
              }}
            >
              <Button
                onClick={() => {
                  handleGetOrderBySpecifyVote(row.id);
                }}
                style={{
                  backgroundColor: ' #4690CD',
                  color: 'white',
                  borderRadius: '12px',
                  padding: '3px 12px',
                  fontSize: '12px',
                  display: 'flex',
                  alignItems: 'center',
                  border: 'none',
                }}
              >
                <span style={{ paddingRight: '8px' }}>
                  {isGettingOrder ||
                  isPrintPkq ||
                  additionalSampleReducer.isPrintPkq
                    ? 'Đang tải...'
                    : 'Tải xuống'}
                </span>
                <img src={IconArrowDown} />
              </Button>
            </div>
          );
      },
      headerStyle,
    },
  ];

  const handleGetOrderBySpecifyVote = (specifyVoteId) => {
    const query = {
      'filters[$and][1][specifyVote][id][$eq]': specifyVoteId,
    };
    dispatch(
      orderActions.getOrderBy(
        queryString.stringify(query),
        {
          success: (data) => {
            handleGetAdditionalSampleByOrder(data.id);
            if (data?.orderType === 'appendix') {
              (data?.fileAppendixs ?? []).forEach((item) => {
                handleDownloadLink(item);
              });
            } else {
              handlePrintPKQ(data?.id ?? -1, 'order');
              handleDownloadLink(data.fileResult);
            }
          },
          failed: () => {},
        },
        false
      )
    );
  };

  const handleGetAdditionalSampleByOrder = (orderId) => {
    const query = {
      'filters[$and][1][order][id][$eq]': orderId,
    };
    dispatch(
      additionalSampleActions.getAdditionalSamples(
        queryString.stringify(query),
        {
          success: (data) => {
            data.results.forEach((result) => {
              if (result?.orderType === 'appendix') {
                (result?.fileAppendixs ?? []).forEach((item) => {
                  handleDownloadLink(item);
                });
              } else {
                handlePrintPKQ(result?.id ?? -1, 'additional-sample');
                handleDownloadLink(result.fileResult);
              }
            });
          },
          failed: () => {},
        },
        false
      )
    );
  };

  const handlePrintPKQ = (id, type = 'order') => {
    if (id === -1) return;
    if (type === 'order') {
      dispatch(
        orderActions.printPkq(id, {
          success: (data) => {
            var blobURL = URL.createObjectURL(data);
            window.open(blobURL);
          },
          failed: (mess) => {
            notify(
              notificationAlertRef,
              'danger',
              'Thông báo',
              `In phiếu kết quả lỗi!`
            );
          },
        })
      );
    } else {
      dispatch(
        additionalSampleActions.printPkqAdditionalSample(id, {
          success: (data) => {
            var blobURL = URL.createObjectURL(data);
            window.open(blobURL);
          },
          failed: (mess) => {
            notify(
              notificationAlertRef,
              'danger',
              'Thông báo',
              `In phiếu kết quả lỗi!`
            );
          },
        })
      );
    }
  };

  return (
    <Card>
      <CardHeader className="py-3">
        <h3 className="mb-0">Danh sách dịch vụ sử dụng</h3>
      </CardHeader>
      <CardBody className="px-0 py-3">
        <ToolkitProvider
          data={_.get(patient, 'specifyVotes', []).filter(
            (specifyVote) =>
              !isDoctorKH || specifyVote.idBS === currentAccountExtend.id
          )}
          keyField="id"
          columns={columns}
          search={{
            searchFormatted: true,
          }}
        >
          {(props) => (
            <div className="pb-4 table-responsive">
              <BootstrapTable
                {...props.baseProps}
                noDataIndication={() => {
                  return (
                    <span className="font-weight-bold text-danger">
                      Không có dữ liệu!
                    </span>
                  );
                }}
                onTableChange={() => {
                  return <Loading />;
                }}
                remote
                hover
                bootstrap4={true}
                // pagination={paginations}
                bordered={false}
                filter={filterFactory()}
                rowStyle={{
                  cursor: 'pointer',
                }}
                keyField="id"
              />
            </div>
          )}
        </ToolkitProvider>
      </CardBody>
    </Card>
  );
}

export default ListService;
