import React from 'react';

const HelperText = ({ message, style }) => {
  return message !== '' ? (
    <p style={{ fontSize: 12, ...style }} className="text-danger mb-0">
      {message}
    </p>
  ) : null;
};

export default HelperText;
