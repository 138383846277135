import typeActions from 'redux/typeActions';
const setValueTemplatePCD = ({ name, value }) => {
  return {
    type: typeActions.SET_TEMPLATE_PCD_VALUE,
    name,
    value,
  };
};
const getTemplatePCDs = (query, callback, isUpdateReducer = true) => {
  return {
    type: typeActions.GET_TEMPLATE_PCDS_REQUEST,
    query,
    callback,
    isUpdateReducer,
  };
};

const updateTemplatePCDs = (param, body, callback) => {
  return {
    type: typeActions.UPDATE_TEMPLATE_PCD_REQUEST,
    param,
    body,
    callback,
  };
};

export default {
  setValueTemplatePCD,
  getTemplatePCDs,
  updateTemplatePCDs,
};
