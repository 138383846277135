import typeActions from 'redux/typeActions';

const getRoleUI = (query, callback, isUpdateReducer = true) => {
  return {
    type: typeActions.GET_ROLE_UI_REQUEST,
    query,
    callback,
    isUpdateReducer,
  };
};

export default {
  getRoleUI,
};
