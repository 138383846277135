import React from 'react';
// react library for routing
import { useLocation, NavLink as NavLinkRRD, Link } from 'react-router-dom';
// nodejs library that concatenates classes
import classnames from 'classnames';
// nodejs library to set properties for components
import { PropTypes } from 'prop-types';
// react library that creates nice scrollbar on windows devices
import PerfectScrollbar from 'react-perfect-scrollbar';
// reactstrap components
import {
  Collapse,
  NavbarBrand,
  Navbar,
  NavItem,
  NavLink,
  Nav,
} from 'reactstrap';
import { useDispatch, useSelector } from 'react-redux';
import queryString from 'query-string';
import _ from 'lodash';

import logoBlue from 'assets/img/logo/logoBlue.png';
import 'assets/css/custom-pages/sidebar.css';
import routes from 'routes';
import { checkRole } from 'common';
import CONSTANT from 'constant';
import { filterActions } from 'redux/actions';

function Sidebar({ toggleSidenav, sidenavOpen, logo, rtlActive }) {
  const [state, setState] = React.useState({});
  const location = useLocation();
  const { currentAccountExtend } = useSelector((state) => state.accountReducer);
  React.useEffect(() => {
    setState(
      getCollapseStates([
        ...routes.routesM,
        ...routes.routesFA,
        ...routes.routesSM,
      ])
    );
    // eslint-disable-next-line
  }, []);
  // verifies if routeName is the one active (in browser input)
  const activeRoute = (routeName) => {
    const { tab } = queryString.parse(location.search);
    try {
      return location.pathname.split('/').indexOf(routeName.replace('/', '')) >
        -1 || routeName === `/${tab}`
        ? 'active'
        : '';
    } catch (error) {
      return '';
    }
  };
  // makes the sidenav normal on hover (actually when mouse enters on it)
  const onMouseEnterSidenav = () => {
    if (!document.body.classList.contains('g-sidenav-pinned')) {
      document.body.classList.add('g-sidenav-show');
    }
  };
  // makes the sidenav mini on hover (actually when mouse leaves from it)
  const onMouseLeaveSidenav = () => {
    if (!document.body.classList.contains('g-sidenav-pinned')) {
      document.body.classList.remove('g-sidenav-show');
    }
  };
  // this creates the intial state of this component based on the collapse routes
  // that it gets through routes
  const getCollapseStates = (routes) => {
    let initialState = {};
    routes.map((prop, key) => {
      if (prop.collapse) {
        initialState = {
          [prop.state]: getCollapseInitialState(prop.views),
          ...getCollapseStates(prop.views),
          ...initialState,
        };
      }
      return null;
    });
    return initialState;
  };
  // this verifies if any of the collapses should be default opened on a rerender of this component
  // for example, on the refresh of the page,
  // while on the src/views/forms/RegularForms.js - route /admin/regular-forms
  const getCollapseInitialState = (routes) => {
    for (let i = 0; i < routes.length; i++) {
      if (routes[i].collapse && getCollapseInitialState(routes[i].views)) {
        return true;
      } else if (location.pathname.indexOf(routes[i].path) !== -1) {
        return true;
      }
    }
    return false;
  };
  const dispatch = useDispatch();
  function handleResetFilter() {
    dispatch(filterActions.setValueFilter({
      name: 'queryFilter',
      value: {}
    }))
    dispatch(filterActions.setValueFilter({
      name: 'queryPage',
      value: {}
    }))
  }
  // this is used on mobile devices, when a user navigates
  // the sidebar will autoclose
  const closeSidenav = () => {
    if (window.innerWidth < 1200) {
      toggleSidenav();
    }
  };
  // this function creates the links and collapses that appear in the sidebar (left menu)
  const createLinks = (routes) => {
    return routes.map((prop, key) => {
      const { showNav = true } = prop;

      const validRole = checkRole(currentAccountExtend, prop);

      if (prop.redirect || !showNav || !validRole) {
        return null;
      }
      if (prop.collapse) {
        var st = {};
        st[prop['state']] = !state[prop.state];
        return (
          <NavItem key={key}>
            <NavLink
              href="#pablo"
              data-toggle={'collapse'}
              aria-expanded={state[prop.state]}
              className={classnames(
                {
                  active: getCollapseInitialState(prop.views),
                  customActive: getCollapseInitialState(prop.views),
                },
                'navLinkHover'
              )}
              onClick={(e) => {
                e.preventDefault();
                setState(st);
                handleResetFilter()
              }}
            >
              {prop.icon ? (
                <>
                  {typeof prop.icon === 'string' ? (
                    <i className={prop.icon} />
                  ) : (
                    <div className="d-flex justify-content-center align-item-center mr-2 icon-container">
                      <img
                        src={prop.icon.type}
                        className={classnames(prop.icon.class)}
                      />
                    </div>
                  )}
                  <span className="nav-link-text  name-categories">
                    {prop.name}
                  </span>
                </>
              ) : prop.miniName ? (
                <>
                  <span className="sidenav-mini-icon"> {prop.miniName} </span>
                  <span className="sidenav-normal">{prop.name} </span>
                </>
              ) : null}
            </NavLink>
            {prop.views ? (
              <Collapse isOpen={state[prop.state]}>
                <Nav className="nav-sm flex-column">
                  {!_.isEmpty(currentAccountExtend) && createLinks(prop.views)}
                </Nav>
              </Collapse>
            ) : (
              ''
            )}
          </NavItem>
        );
      }

      return (
        <NavItem
          className={activeRoute(prop.path)}
          key={key}
          cssModule={{ active: 'customActiveNavItem' }}
        >
          <NavLink
            to={prop.layout + prop.path}
            activeClassName={!!prop.icon ? 'customActive2' : ''}
            onClick={() => {
              closeSidenav();
              handleResetFilter()
            }}
            tag={NavLinkRRD}
          >
            {prop.icon ? (
              <>
                <div className="d-flex justify-content-center align-item-center mr-2 icon-container">
                  <img
                    src={prop.icon.type}
                    className={classnames(prop.icon.class)}
                  />
                </div>
                <span className="nav-link-text name-categories">
                  {prop.name}
                </span>
              </>
            ) : prop.miniName !== undefined ? (
              <>
                <span className="sidenav-mini-icon">{prop.miniName} </span>
                <span className="sidenav-normal"> {prop.name} </span>
              </>
            ) : (
              <>
                <i className={prop.icon} />
                <span className="nav-link-text name-categories">
                  {prop.name}
                </span>
              </>
            )}
          </NavLink>
        </NavItem>
      );
    });
  };

  let navbarBrandProps;
  if (logo && logo.innerLink) {
    navbarBrandProps = {
      to: logo.innerLink,
      tag: Link,
    };
  } else if (logo && logo.outterLink) {
    navbarBrandProps = {
      href: logo.outterLink,
      target: '_blank',
    };
  }
  const scrollBarInner = (
    <div className="scrollbar-inner">
      <div className="">
        {logo ? (
          <NavbarBrand {...navbarBrandProps} className="px-3">
            <img
              alt={logo.imgAlt}
              className="navbar-brand-img mh-100"
              src={logoBlue}
              width={'100%'}
            />
            <hr className="my-0 mt-3 horizontalRuleLogo " />
          </NavbarBrand>
        ) : null}
      </div>
      <div className="navbar-inner">
        <Collapse isOpen={true}>
          <Nav navbar>
            {!_.isEmpty(currentAccountExtend) && createLinks(routes.routesM)}
          </Nav>
          {/* <h4 className="title-categories">Xử lý kết quả xét nghiệm</h4>
          <Nav navbar>{!_.isEmpty(currentAccountExtend) && createLinks(routes.routeCF)}</Nav> */}
          {checkPermissions('basis') && (
            <h4 className="title-categories">Quản trị cơ sở</h4>
          )}
          <Nav navbar>
            {!_.isEmpty(currentAccountExtend) && createLinks(routes.routesFA)}
          </Nav>
          {checkPermissions('system') && (
            <h4 className="title-categories">Quản trị hệ thống</h4>
          )}
          <Nav navbar>
            {!_.isEmpty(currentAccountExtend) && createLinks(routes.routesSM)}
          </Nav>
          <hr className="my-3" />
        </Collapse>
      </div>
    </div>
  );

  function checkPermissions(type) {
    switch (type) {
      case 'basis':
        return checkRole(currentAccountExtend, {
          roles: CONSTANT.PERMISSIONS_BASIS,
        });
      case 'system':
        return checkRole(currentAccountExtend, {
          roles: CONSTANT.PERMISSIONS_SYSTEM,
        });
    }
    return false;
  }

  return (
    <Navbar
      className={
        'sidenav navbar-vertical navbar-expand-xs navbar-light bg-white ' +
        (rtlActive ? '' : 'fixed-left')
      }
      onMouseEnter={onMouseEnterSidenav}
      onMouseLeave={onMouseLeaveSidenav}
    >
      {navigator.platform.indexOf('Win') > -1 ? (
        <PerfectScrollbar>{scrollBarInner}</PerfectScrollbar>
      ) : (
        scrollBarInner
      )}
    </Navbar>
  );
}

Sidebar.defaultProps = {
  routes: [{}],
  toggleSidenav: () => { },
  sidenavOpen: false,
  rtlActive: false,
};

Sidebar.propTypes = {
  // function used to make sidenav mini or normal
  toggleSidenav: PropTypes.func,
  // prop to know if the sidenav is mini or normal
  sidenavOpen: PropTypes.bool,
  // links that will be displayed inside the component
  routes: PropTypes.arrayOf(PropTypes.object),
  // logo
  logo: PropTypes.shape({
    // innerLink is for links that will direct the user within the app
    // it will be rendered as <Link to="...">...</Link> tag
    innerLink: PropTypes.string,
    // outterLink is for links that will direct the user outside the app
    // it will be rendered as simple <a href="...">...</a> tag
    outterLink: PropTypes.string,
    // the image src of the logo
    imgSrc: PropTypes.string.isRequired,
    // the alt for the img
    imgAlt: PropTypes.string.isRequired,
  }),
  // rtl active, this will make the sidebar to stay on the right side
  rtlActive: PropTypes.bool,
};

export default Sidebar;
