import typeActions from 'redux/typeActions';
import { returnValue } from 'common';

const initialState = {
  // loading
  isGettingOrders: false,
  isGettingOrder: false,
  isCreatingOrder: false,
  isUpdatingOrder: false,
  isDeletingOrder: false,
  isExportingOrder: false,
  isPrintPkq: false,
  isUploadFiles: false,
  isUpdatingLabcodes: false,
  // data
  orders: {},
  order: {},
  // error
  errors: {
    getdOrders: '',
    getdOrder: '',
    createdOrder: '',
    updatedOrder: '',
    deletedOrder: '',
  },
  // query
  nameTC: '',
  search: '',
  page: 1,
  limit: 10,
  startDate: null,
  endDate: null,
  //
  querySynthetic: {},
  querySyntheticOrigin: {},

  //
  queryUnpaid: {},
  queryUnpaidOrigin: {},

  //
  queryReceivePay: {},
  queryReceivePayOrigin: {},

  //
  querySynthesisSampleTransfer: {},
  querySynthesisSampleTransferOrigin: {},

  // tiếp nhận
  status: null,
  listType: 'order',
};

const orderReducer = (state = initialState, actions) => {
  switch (actions.type) {
    case typeActions.SET_ORDER_VALUE:
      const { name, value } = actions;
      if (typeof name !== 'string') return { ...state };
      const obj = returnValue(state, name, value);

      return {
        ...state,
        ...obj,
      };

    case typeActions.GET_ORDERS_REQUEST:
      return {
        ...state,
        isGettingOrders: true,
        errors: {
          ...state.errors,
          getdOrders: '',
        },
      };
    case typeActions.GET_ORDERS_SUCCESS:
      return {
        ...state,
        orders: actions.data ?? {},
        isGettingOrders: false,
        errors: {
          ...state.errors,
          getdOrders: '',
        },
      };
    case typeActions.GET_ORDERS_FAILED:
      return {
        ...state,
        orders: {},
        isGettingOrders: false,
        errors: {
          ...state.errors,
          getdOrders: actions.error,
        },
      };

    case typeActions.GET_ORDER_BY_REQUEST:
    case typeActions.GET_ORDER_REQUEST:
      return {
        ...state,
        isGettingOrder: true,
        errors: {
          ...state.errors,
          getdOrder: '',
        },
      };

    case typeActions.GET_ORDER_BY_SUCCESS:
    case typeActions.GET_ORDER_SUCCESS:
      return {
        ...state,
        order: actions.data || {},
        isGettingOrder: false,
        errors: {
          ...state.errors,
          getdOrder: '',
        },
      };
    case typeActions.GET_ORDER_BY_FAILED:
    case typeActions.GET_ORDER_FAILED:
      return {
        ...state,
        order: {},
        isGettingOrder: false,
        errors: {
          ...state.errors,
          getdOrder: actions.error,
        },
      };

    case typeActions.CREATE_ORDER_REQUEST:
      return {
        ...state,
        isCreatingOrder: true,
        errors: {
          ...state.errors,
          createdOrder: '',
        },
      };
    case typeActions.CREATE_ORDER_SUCCESS:
      return {
        ...state,
        isCreatingOrder: false,
        errors: {
          ...state.errors,
          createdOrder: '',
        },
      };
    case typeActions.CREATE_ORDER_FAILED:
      return {
        ...state,
        isCreatingOrder: false,
        errors: {
          ...state.errors,
          createdOrder: actions.error,
        },
      };

    case typeActions.UPDATE_ORDER_REQUEST:
      return {
        ...state,
        isUpdatingOrder: true,
        errors: {
          ...state.errors,
          updatedOrder: '',
        },
      };
    case typeActions.UPDATE_ORDER_SUCCESS:
      return {
        ...state,
        isUpdatingOrder: false,
        errors: {
          ...state.errors,
          updatedOrder: '',
        },
      };
    case typeActions.UPDATE_ORDER_FAILED:
      return {
        ...state,
        isUpdatingOrder: false,
        errors: {
          ...state.errors,
          updatedOrder: actions.error,
        },
      };

    case typeActions.DELETE_ORDER_REQUEST:
      return {
        ...state,
        isDeletingOrder: true,
        errors: {
          ...state.errors,
          deletedOrder: '',
        },
      };
    case typeActions.DELETE_ORDER_SUCCESS:
      return {
        ...state,
        isDeletingOrder: false,
        errors: {
          ...state.errors,
          deletedOrder: '',
        },
      };
    case typeActions.DELETE_ORDER_FAILED:
      return {
        ...state,
        isDeletingOrder: false,
        errors: {
          ...state.errors,
          deletedOrder: actions.error,
        },
      };

    case typeActions.EXPORT_ORDER_REQUEST:
      return {
        ...state,
        isExportingOrder: true,
      };
    case typeActions.EXPORT_ORDER_SUCCESS:
      return {
        ...state,
        isExportingOrder: false,
      };
    case typeActions.EXPORT_ORDER_FAILED:
      return {
        ...state,
        isExportingOrder: false,
      };

    case typeActions.PRINT_PKQ_REQUEST:
      return {
        ...state,
        isPrintPkq: true,
      };
    case typeActions.PRINT_PKQ_SUCCESS:
      return {
        ...state,
        isPrintPkq: false,
      };
    case typeActions.PRINT_PKQ_FAILED:
      return {
        ...state,
        isPrintPkq: false,
      };

    case typeActions.UPLOAD_FILES_ORDER_REQUEST:
      return {
        ...state,
        isUploadFiles: true,
      };
    case typeActions.UPLOAD_FILES_ORDER_SUCCESS:
      return {
        ...state,
        isUploadFiles: false,
      };
    case typeActions.UPLOAD_FILES_ORDER_FAILED:
      return {
        ...state,
        isUploadFiles: false,
      };

    case typeActions.UPDATE_LABCODES_REQUEST:
      return {
        ...state,
        isUpdatingLabcodes: true,
        errors: {
          ...state.errors,
        },
      };
    case typeActions.UPDATE_LABCODES_SUCCESS:
      return {
        ...state,
        isUpdatingLabcodes: false,
        errors: {
          ...state.errors,
        },
      };
    case typeActions.UPDATE_LABCODES_FAILED:
      return {
        ...state,
        isUpdatingLabcodes: false,
        errors: {
          ...state.errors,
        },
      };

    default:
      return {
        ...state,
      };
  }
};
export default orderReducer;
