import React, { memo, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Button, Col, Form, FormGroup, Input, Modal, Row } from 'reactstrap';
import * as yup from 'yup';
import { Formik } from 'formik';
import _ from 'lodash';
import Select from 'react-select';

import { isNumeric, notificationAlertRef, notify } from 'common';
import LabelCustom from 'views/pages/components/LabelCustom';
import { formActions, accountActions, uploadActions } from 'redux/actions';
import HelperText from 'views/pages/components/HelperText';
import queryString from 'query-string';
import DatePicker from 'react-datepicker';

import LoadingButtonCustom from 'views/pages/components/LoadingButtonCustom';
import { BASE_URL_IMAGE } from 'services/ServiceURL';
import CONSTANT from 'constant';
import SelectCustom from 'views/pages/components/SelectCustom';
import InputCustom from 'views/pages/components/InputCustom';
import CustomInputCalendar from 'views/pages/components/CustomInputCalendar';

const UAccountInfo = () => {
  const dispatch = useDispatch();
  const { isModalVisible } = useSelector((state) => state.formReducer);
  const { isCreateAccount, isUpdateAccount, currentAccountExtend } =
    useSelector((state) => state.accountReducer);

  const { email, gender, avatar, isMarried, channel } = currentAccountExtend;

  const avatarId = avatar?.id ?? '';

  const initInfo = {
    firstname: _.get(currentAccountExtend, 'adminUser.firstname', ''),
    lastname: _.get(currentAccountExtend, 'adminUser.lastname', ''),
    username: email,
    email: _.get(currentAccountExtend, 'email', ''),
    decentralization: _.get(currentAccountExtend, 'decentralization.id', null),
    // account extend
    avatar: _.get(currentAccountExtend, 'avatar.url', ''),
    address: _.get(currentAccountExtend, 'address', ''),
    phone: _.get(currentAccountExtend, 'phone', ''),
    code: _.get(currentAccountExtend, 'code', ''),
    dateOfBirth: !_.isEmpty(currentAccountExtend?.dateOfBirth)
      ? new Date(_.get(currentAccountExtend, 'dateOfBirth', null))
      : null,
    citizenId: _.get(currentAccountExtend, 'citizenId', ''),
    licenseDate: !_.isEmpty(currentAccountExtend?.licenseDate)
      ? new Date(_.get(currentAccountExtend, 'licenseDate', null))
      : null,
    isMarried: _.get(currentAccountExtend, 'isMarried', false),
    specialistGr: _.get(currentAccountExtend, 'specialistGr', null),
    licenseWhere: _.get(currentAccountExtend, 'licenseWhere', ''),
    channel: _.get(currentAccountExtend, 'channel', null),
    gender: _.get(currentAccountExtend, 'gender', 0),
    workAddress: _.get(currentAccountExtend, 'workAddress', ''),
    workWhere: _.get(currentAccountExtend, 'workWhere', ''),
    specialist: _.get(currentAccountExtend, 'specialist', ''),
    degree: _.get(currentAccountExtend, 'degree', ''),
    id: _.get(currentAccountExtend, 'id', ''),
    adminId: _.get(currentAccountExtend, 'adminUser.id', -1),
  };

  const [genderValue, setGenderValue] = useState(
    CONSTANT.ACCOUNT_GENDER.find((item) => item.value === gender) ?? null
  );
  const [isMarriedValue, setIsMarriedValue] = useState(CONSTANT.MARRIED[0]);
  const [channelValue, setChannelValue] = useState(null);
  const infoSchema = yup.object().shape({
    email: yup
      .string()
      .email('Email không đúng định dạng!')
      .required('Email không được để trống!'),

    firstname: yup.string().required('Họ tên không được để trống!'),
    phone: yup
      .string()
      // .length(10, 'Số điện thoại bao gồm 10 số!')
      .required('Số điện thoại không được để trống!')
      .matches('0[1-9][0-9]+', 'Vui lòng nhập đúng định dạng số điện thoại!'),
  });
  const onSubmit = (values) => {
    if (typeof values.avatar === 'object') {
      if (avatarId !== '')
        return dispatch(
          uploadActions.deleteFile(avatarId, {
            success: () => {
              handleUploadAvatar(values);
            },
            failed: (mess) => {
              notify(
                notificationAlertRef,
                'danger',
                'Thông báo',
                `Xóa ảnh thất bại!. Lỗi: ${mess}!`
              );
            },
          })
        );
      return handleUploadAvatar(values);
    } else {
      return handleCUAccount(values, avatarId);
    }
  };

  const handleUploadAvatar = (values) => {
    const formData = new FormData();
    formData.append('files', values.avatar.file, values.avatar.file.name);
    dispatch(
      uploadActions.uploadFiles(formData, {
        success: (data) => {
          handleCUAccount(values, data[0].id ?? '');
        },
        failed: (mess) => {
          notify(
            notificationAlertRef,
            'danger',
            'Thông báo',
            `Tải ảnh lên thất bại!. Lỗi: ${mess}!`
          );
        },
      })
    );
  };

  const handleCUAccount = (values, avatarId) => {
    const { firstname, lastname, username } = values;
    const body = _.cloneDeep({
      username,
      firstname: (firstname ?? '').trim(),
      lastname: (lastname ?? '').trim(),
    });

    dispatch(
      accountActions.updateAccount(values.adminId ?? '', body, {
        success: (data) => {
          handleCUAccountExtend(data.id, avatarId, values);
        },
        failed: (mess) => {
          notify(
            notificationAlertRef,
            'danger',
            'Thông báo',
            `Cập nhật tài khoản  thất bại!. Lỗi: ${mess}!`
          );
        },
      })
    );
  };

  const handleCUAccountExtend = (adminId, avatarId, values) => {
    const {
      firstname,
      lastname,
      channel,
      gender,
      specialistGr,
      isMarried,
      email,
      address,
      phone,
      code,
      dateOfBirth,
      citizenId,
      licenseDate,
      licenseWhere,
      workAddress,
      workWhere,
      specialist,
      degree,
      id,
    } = values;

    const body = {
      firstname: (firstname ?? '').trim(),
      lastname: (lastname ?? '').trim(),
      fullname: `${lastname ?? ''} ${firstname ?? ''}`.trim(),
      channel,
      gender,
      specialistGr,
      isMarried,
      email,
      address,
      phone,
      code,
      dateOfBirth,
      citizenId,
      licenseDate,
      licenseWhere,
      workAddress,
      workWhere,
      specialist,
      degree,
      avatar: avatarId,
    };

    dispatch(
      accountActions.updateAccountExtend(id, body, {
        success: (data) => {
          notify(
            notificationAlertRef,
            'success',
            'Thông báo',
            `Cập nhật tài khoản thành công.`
          );
          dispatch(
            accountActions.getCurrentAccountExtend(
              queryString.stringify({
                'filters[$and][0][email][$eq]': email,
              }),
              {
                success: (__) => {},
                failed: () => {},
              }
            )
          );
          handleClose();
        },
        failed: (mess) => {
          notify(
            notificationAlertRef,
            'danger',
            'Thông báo',
            `Cập nhật tài khoản mở rộng thất bại!. Lỗi: ${mess}!`
          );
        },
      })
    );
  };

  const handleClose = () => {
    dispatch(
      formActions.setValue({
        name: 'isModalVisible',
        value: false,
      })
    );
  };

  useEffect(() => {
    const ganderV = CONSTANT.ACCOUNT_GENDER.find(
      (item) => item.value === gender
    );

    const channelV = CONSTANT.CHANNEL.find((item) => item.value === channel);

    const isMarriedV = CONSTANT.MARRIED.find(
      (item) => item.value === isMarried
    );

    setIsMarriedValue(isMarriedV);
    setGenderValue(ganderV);
    setChannelValue(channelV);
  }, [isModalVisible]);

  return (
    <Modal
      isOpen={isModalVisible}
      size={'xl'}
      className="modal-dialog-centered modal-secondary"
      toggle={() => {
        handleClose();
      }}
    >
      <div className="modal-body bg-white rounded">
        <Formik
          initialValues={initInfo}
          enableReinitialize
          onSubmit={onSubmit}
          validationSchema={infoSchema}
        >
          {({
            values,
            handleSubmit,
            errors,
            touched,
            handleBlur,
            handleChange,
            setFieldValue,
          }) => {
            let avatar = null;
            avatar = !values.avatar?.fileUrl
              ? `${BASE_URL_IMAGE}${values.avatar}`
              : values.avatar.fileUrl;
            if (values.avatar === '')
              avatar = require('assets/img/icons/cards/avatar.jpg').default;
            return (
              <Form
                onSubmit={() => {
                  handleSubmit();
                }}
                className=" form-add-account"
              >
                <FormGroup className="mb-0">
                  <Row>
                    <Col lg={12}>
                      <h2>Cập nhật thông tin cá nhân</h2>
                    </Col>
                  </Row>
                  <div>
                    <div className="">
                      <Row>
                        <Col className="border-right" xs={8}>
                          <div>
                            <div className="d-flex align-items-center my-2">
                              <span
                                className=""
                                style={{
                                  fontSize: 15,
                                  fontWeight: 600,
                                }}
                              >
                                Thông tin cá nhân
                              </span>
                              <div className="border-bottom flex-grow-1 ml-2" />
                            </div>
                          </div>
                          <Row>
                            <Col xs={6}>
                              <InputCustom
                                name="lastname"
                                label="Họ và tên đệm "
                                type="text"
                                placeholder="Nhập vào họ"
                                value={values.lastname}
                                isRequired={false}
                                handleChange={handleChange}
                                handleBlur={handleBlur}
                                error={errors?.lastname}
                                touched={touched?.lastname}
                                row
                                labelWidth={120}
                              />
                            </Col>
                            <Col xs={6}>
                              <InputCustom
                                name="firstname"
                                label="Tên"
                                type="text"
                                placeholder="Nhập vào tên"
                                value={values.firstname}
                                isRequired={true}
                                handleChange={handleChange}
                                handleBlur={handleBlur}
                                error={errors?.firstname}
                                touched={touched?.firstname}
                                row
                                labelWidth={120}
                              />
                            </Col>
                          </Row>
                          <Row>
                            <Col xs={6} className="d-flex">
                              <LabelCustom
                                isRequired={false}
                                label={'Ngày sinh'}
                                id={'dateOfBirth'}
                                style={{
                                  whiteSpace: 'nowrap',
                                  margin: '0.5em 8px 0px 0px',
                                }}
                                styleLabel={{
                                  minWidth: 120,
                                }}
                              />
                              <div className="date-picker-custom row-date-picker-custom d-flex flex-column">
                                <DatePicker
                                  wrapperClassName="icon-calendar"
                                  customInput={<CustomInputCalendar />}
                                  dateFormat={'dd/MM/yyyy'}
                                  selected={values.dateOfBirth}
                                  maxDate={new Date()}
                                  onChange={(date) => {
                                    setFieldValue('dateOfBirth', date);
                                  }}
                                />
                                {errors.dateOfBirth &&
                                  !!touched.dateOfBirth && (
                                    <HelperText message={errors.dateOfBirth} />
                                  )}
                              </div>
                            </Col>
                            <Col xs={6}>
                              <SelectCustom
                                name="gender"
                                label="Giới tính"
                                placeholder="Lựa chọn"
                                value={genderValue}
                                isRequired={true}
                                isClearable={false}
                                handleChange={(e) => {
                                  setGenderValue(e);
                                  setFieldValue('gender', e?.value ?? -1);
                                }}
                                handleBlur={handleBlur}
                                onInputChange={(value) => {}}
                                error={errors.gender}
                                touched={touched.gender}
                                options={CONSTANT.ACCOUNT_GENDER}
                                row
                                labelWidth={120}
                              />
                            </Col>
                          </Row>
                          <Row>
                            <Col xs={6}>
                              <InputCustom
                                name="phone"
                                label="Số điện thoại"
                                type="text"
                                placeholder="Nhập số điện thoại"
                                value={values.phone}
                                isRequired={true}
                                handleChange={(e) => {
                                  if (isNumeric(e.target.value)) {
                                    setFieldValue('phone', e.target.value);
                                  }
                                }}
                                handleBlur={handleBlur}
                                error={errors?.phone}
                                touched={touched?.phone}
                                row
                                labelWidth={120}
                              />
                            </Col>
                            <Col xs={6}>
                              <SelectCustom
                                name="type"
                                label="Hôn nhân"
                                placeholder="Lựa chọn"
                                value={isMarriedValue}
                                isRequired={false}
                                isClearable={false}
                                handleChange={(e) => {
                                  setIsMarriedValue(e);
                                  setFieldValue('isMarried', e?.value ?? false);
                                }}
                                handleBlur={handleBlur}
                                onInputChange={(value) => {}}
                                error={errors.type}
                                touched={touched.type}
                                options={CONSTANT.MARRIED}
                                row
                                labelWidth={120}
                              />
                            </Col>
                          </Row>
                          <Row>
                            <Col xs={6}>
                              <InputCustom
                                name="citizenId"
                                label="Số CCCD"
                                type="text"
                                placeholder="Nhập số CCCD"
                                value={values.citizenId}
                                isRequired={false}
                                handleChange={(e) => {
                                  if (isNumeric(e.target.value)) {
                                    setFieldValue('citizenId', e.target.value);
                                  }
                                }}
                                handleBlur={handleBlur}
                                error={errors?.citizenId}
                                touched={touched?.citizenId}
                                row
                                labelWidth={120}
                              />
                            </Col>

                            <Col xs={6} className="d-flex">
                              <LabelCustom
                                isRequired={false}
                                label={'Ngày cấp '}
                                id={'licenseDate'}
                                style={{
                                  whiteSpace: 'nowrap',
                                  margin: '0.5em 8px 0px 0px',
                                }}
                                styleLabel={{
                                  minWidth: 120,
                                }}
                              />
                              <div className="date-picker-custom row-date-picker-custom d-flex flex-column">
                                <DatePicker
                                  customInput={<CustomInputCalendar />}
                                  wrapperClassName="icon-calendar"
                                  dateFormat={'dd/MM/yyyy'}
                                  selected={values.licenseDate}
                                  maxDate={new Date()}
                                  onChange={(date) => {
                                    setFieldValue('licenseDate', date);
                                  }}
                                />
                                {errors.licenseDate &&
                                  !!touched.licenseDate && (
                                    <HelperText message={errors.licenseDate} />
                                  )}
                              </div>
                            </Col>
                          </Row>
                          <Row>
                            <Col xs={12}>
                              <InputCustom
                                name="licenseWhere"
                                label="Nơi cấp"
                                type="textarea"
                                placeholder="Nhập nơi cấp CCCD"
                                value={values.licenseWhere}
                                isRequired={false}
                                handleChange={handleChange}
                                handleBlur={handleBlur}
                                styleInput={{
                                  minHeight: 100,
                                }}
                                error={errors?.licenseWhere}
                                touched={touched?.licenseWhere}
                                row
                                labelWidth={120}
                              />
                            </Col>
                          </Row>

                          <Row>
                            <Col xs={12}>
                              <InputCustom
                                name="address"
                                label="Đia chỉ"
                                type="textarea"
                                rows={4}
                                placeholder="Nhập địa chỉ"
                                value={values.address}
                                isRequired={false}
                                handleChange={handleChange}
                                handleBlur={handleBlur}
                                styleInput={{
                                  minHeight: 100,
                                }}
                                error={errors?.address}
                                touched={touched?.address}
                                row
                                labelWidth={120}
                              />
                            </Col>
                          </Row>
                        </Col>
                        <Col xs={4}>
                          <div className="d-flex align-items-center my-2">
                            <span
                              className=""
                              style={{
                                fontSize: 15,
                                fontWeight: 600,
                              }}
                            >
                              Thông tin bổ sung
                            </span>
                            <div className="border-bottom flex-grow-1 ml-2" />
                          </div>
                          <div className="d-flex justify-content-center align-items-center rounded-circle flex-column my-3">
                            <img
                              alt="..."
                              className="img-fluid rounded-circle shadow"
                              src={avatar}
                              style={{ width: '100px', height: '100px' }}
                            />
                            <input
                              className="mt-3"
                              style={{ fontSize: 12 }}
                              type="file"
                              accept="image/*"
                              onChange={(e) => {
                                try {
                                  if (_.isEmpty(e)) return;

                                  setFieldValue('avatar', {
                                    fileUrl: URL.createObjectURL(
                                      e.target.files[0]
                                    ),
                                    file: e.target.files[0],
                                  });
                                } catch (error) {}
                              }}
                            />
                          </div>
                          <div>
                            <Row>
                              <Col xs={12}>
                                <InputCustom
                                  name="specialist"
                                  label="Chuyên khoa"
                                  type="text"
                                  placeholder="Nhập chuyên khoa"
                                  value={values.specialist}
                                  isRequired={false}
                                  handleChange={handleChange}
                                  handleBlur={handleBlur}
                                  error={errors?.specialist}
                                  touched={touched?.specialist}
                                  row
                                  labelWidth={120}
                                />
                              </Col>
                            </Row>
                            <Row>
                              <Col xs={12}>
                                <InputCustom
                                  name="degree"
                                  label="Bằng cấp"
                                  type="text"
                                  placeholder="Nhập bằng cấp"
                                  value={values.degree}
                                  isRequired={false}
                                  handleChange={handleChange}
                                  handleBlur={handleBlur}
                                  error={errors?.degree}
                                  touched={touched?.degree}
                                  row
                                  labelWidth={120}
                                />
                              </Col>
                            </Row>
                            <Row>
                              <Col xs={12}>
                                <InputCustom
                                  name="workAddress"
                                  label="Nơi làm việc"
                                  type="text"
                                  placeholder="Nhập nơi làm việc"
                                  value={values.workAddress}
                                  isRequired={false}
                                  handleChange={handleChange}
                                  handleBlur={handleBlur}
                                  error={errors?.workAddress}
                                  touched={touched?.workAddress}
                                  row
                                  labelWidth={120}
                                />
                              </Col>
                            </Row>
                            <Row>
                              <Col xs={12}>
                                <SelectCustom
                                  name="channel"
                                  label="Kênh"
                                  placeholder="Chọn kênh"
                                  value={channelValue}
                                  isRequired={false}
                                  isClearable={true}
                                  handleChange={(e) => {
                                    setChannelValue(e);
                                    setFieldValue('channel', e?.value ?? null);
                                  }}
                                  handleBlur={handleBlur}
                                  onInputChange={(__) => {}}
                                  error={errors.channel}
                                  touched={touched.channel}
                                  options={CONSTANT.CHANNEL}
                                  row
                                  labelWidth={120}
                                />
                              </Col>
                            </Row>
                            <Row>
                              <Col xs={12}>
                                <InputCustom
                                  name="workWhere"
                                  label="Địa chỉ làm việc"
                                  type="textarea"
                                  rows={4}
                                  placeholder="Nhập địa chỉ"
                                  value={values.workWhere}
                                  isRequired={false}
                                  handleChange={handleChange}
                                  handleBlur={handleBlur}
                                  error={errors?.workWhere}
                                  touched={touched?.workWhere}
                                  row
                                  labelWidth={120}
                                />
                              </Col>
                            </Row>
                          </div>
                        </Col>
                      </Row>
                    </div>
                  </div>

                  <div className="mb-0 pb-0 mt-4 d-flex justify-content-end">
                    <Button
                      className="text-nowrap mr-1"
                      onClick={() => {
                        handleClose();
                      }}
                    >
                      Hủy
                    </Button>
                    <LoadingButtonCustom
                      onClick={handleSubmit}
                      color="info"
                      className="text-nowrap "
                      type="submit"
                      loading={isCreateAccount || isUpdateAccount}
                    >
                      Lưu lại
                    </LoadingButtonCustom>
                  </div>
                </FormGroup>
              </Form>
            );
          }}
        </Formik>
      </div>
    </Modal>
  );
};

export default memo(UAccountInfo);
