import React, { useRef, useEffect, useCallback, useState, memo } from 'react';
import { useReactToPrint } from 'react-to-print';
import { CardBody, CardHeader, Card, Modal, Button } from 'reactstrap';

import _ from 'lodash';
import { useDispatch, useSelector } from 'react-redux';
import bwipjs from 'bwip-js';
import { barcodeActions, formActions } from 'redux/actions';

const w = 37.5; // mm
const h = 22.5; // mm

const ModalPrintBarCode = ({ data, handleGetBarcodes }) => {
  const dispatch = useDispatch();
  const componentRef = useRef(null);
  const [nameFile, setNameFile] = useState('');
  const [isHandleBarcode, setIsHandleBarcode] = useState(false);
  const { isModalPrintVisible } = useSelector((state) => state.formReducer);
  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
    removeAfterPrint: true,
    documentTitle: nameFile,
  });

  const handleRenderBarcode = () => {
    try {
      if (data.length === 0) return;
      data
        .reduce(function (res, current) {
          return res.concat([current, current, current, current]);
        }, [])
        .forEach((item, idx) => {
          const divBarcode = document.createElement('div');
          divBarcode.classList.add('d-flex');
          divBarcode.classList.add('flex-column');
          divBarcode.classList.add('align-items-center');
          divBarcode.classList.add('justify-content-center');
          divBarcode.style.width = `${w}mm`;
          // divBarcode.style.paddingTop = `2mm`;
          divBarcode.style.height = `${h}mm`;
          // divBarcode.style.paddingTop = '10px';
          if (idx > 1) {
            // divBarcode.style.marginTop = '0.344cm';
          }
          const barcode = document.createElement('div');
          const canvas = document.createElement('canvas');
          canvas.setAttribute('id', 'barcode-' + idx);

          // barcode
          const pBarcode = document.createElement('span');
          pBarcode.innerHTML = item.barcode;
          pBarcode.style.fontSize = '14px';
          pBarcode.style.fontWeight = 600;
          pBarcode.classList.add('text-center');
          pBarcode.style.width = '100%';
          pBarcode.style.marginTop = '1mm';
          barcode.appendChild(canvas);
          barcode.style.width = '100%';
          // barcode.style.zoom = 0.98;
          barcode.style.transform = 'scaleX(1.4)';
          barcode.classList.add('d-flex');
          barcode.classList.add('justify-content-center');

          // append html
          divBarcode.appendChild(barcode);
          divBarcode.appendChild(pBarcode);

          componentRef.current.appendChild(divBarcode);
          bwipjs.toCanvas(`#barcode-${idx}`, {
            text: item.barcode,
            bcid: 'code128', // Barcode type
            scaleX: 1,
            height: 18, // Bar height, in millimeters
          });
        });

      // var blobURL = URL.createObjectURL(componentRef.current);

      // window.open(blobURL);

      setIsHandleBarcode(false);
      // handlePrint();
    } catch (error) {
      setIsHandleBarcode(false);
    }
  };
  const handleClose = () => {
    dispatch(
      formActions.setValue({
        name: 'isModalPrintVisible',
        value: false,
      })
    );
  };
  useEffect(() => {
    const delayDebounceFn = setTimeout(() => {
      if (!isModalPrintVisible || !componentRef?.current) return;
      handleRenderBarcode();
    }, 500);

    return () => clearTimeout(delayDebounceFn);
  }, [isModalPrintVisible, data, componentRef?.current]);

  return (
    <Modal
      className="modal-dialog-centered"
      isOpen={isModalPrintVisible}
      style={{ minWidth: `${w * 4}mm`, maxWidth: `${w * 4}mm` }}
      toggle={() => {
        handleClose();
      }}
    >
      <div className="modal-header border-bottom bg-white pb-2 justify-content-between align-items-center">
        <h2 className="mb-0">Xem trước</h2>
        <div>
          <Button
            onClick={() => {
              handleClose();
            }}
          >
            Huỷ
          </Button>
          <Button
            color="primary"
            onClick={() => {
              if (data.length === 0) return;
              dispatch(
                barcodeActions.printBarcodes(
                  data[0].id,
                  {
                    ids: data.slice(1).map((d) => d.id),
                    isPrinted: true,
                  },
                  {
                    success: () => {
                      handleGetBarcodes();
                    },
                  }
                )
              );
              handlePrint();
            }}
          >
            In Mã
          </Button>
        </div>
      </div>
      <div className="modal-body p-0">
        <Card className="bg-white border-0 mb-0 d-flex flex-column align-items-center">
          {isHandleBarcode && (
            <div className="d-flex justify-content-between flex-column align-items-center">
              <div className="spinner-border text-info" />
              <p className="text-center">Đang xử lý ...</p>
            </div>
          )}
          <CardBody
            className="p-0 pt-3"
            style={{ width: `${w * 4}mm`, paddingTop: 5 }}
          >
            <div
              style={{
                width: `${w * 4}mm`,
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'flex-start',
                flexWrap: 'wrap',
                alignItems: 'flex-start',
              }}
              ref={componentRef}
            ></div>
          </CardBody>
        </Card>
      </div>
    </Modal>
  );
};

export default memo(ModalPrintBarCode);
