/**
 * Render Group Service Testing page
 * Routes /admin/group-service
 */

// core componet
import React, { useEffect, useState } from 'react';
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory from 'react-bootstrap-table2-paginator';
import filterFactory from 'react-bootstrap-table2-filter';
import ToolkitProvider from 'react-bootstrap-table2-toolkit';
import _ from 'lodash';
import {
  Card,
  CardHeader,
  Col,
  Container,
  Input,
  Row,
  UncontrolledTooltip,
} from 'reactstrap';
import { notificationAlertRef, notify } from 'common';
import SimpleHeader from 'components/Headers/SimpleHeader.js';
import Loading from 'views/pages/components/Loading';
import 'assets/css/custom-pages/react-bs-table.css';
import 'assets/css/custom-components/input-title-table.css';
import { useDispatch, useSelector } from 'react-redux';
import { filterActions, formActions, patientActions } from 'redux/actions';
// custom hook
import { useDelayInputEffect, useDidUpdateEffect } from 'utils/HookUtils';
// icon
import IconEdit from 'assets/svgs/edit.svg';
import IconEye from 'assets/svgs/eye.svg';
//other
import CONSTANT from 'constant';
import queryString from 'query-string';
import { useHistory } from 'react-router';
import CUPatient from './components/CUPatient';

function Patient() {
  const history = useHistory();
  const [searchTerm, setSearchTerm] = useState('');
  const [isUpdateReducer, setIsUpdateReducer] = useState(true);
  const { page, pagination, search, limit, patients, query } = useSelector(
    (state) => state.patientReducer
  );
  const { isDoctorKH, isAdmin, currentAccountExtend } = useSelector(
    (state) => state.accountReducer
  );
  const dispatch = useDispatch();
  const nameTypeSearch = 'Tìm kiếm';
  const headerStyle = {
    textAlign: 'center',
  };

  const columns = [
    {
      dataField: 'code',
      text: 'MÃ',
    },
    {
      dataField: 'name',
      text: 'HỌ TÊN',
    },
    {
      dataField: 'dateOfBirth',
      text: 'NGÀY SINH',
    },
    {
      dataField: 'gender',
      text: 'GIỚI TÍNH',
      formatter: (cell, row) => {
        return (
          _.find(CONSTANT.ACCOUNT_GENDER, {
            value: cell,
          })?.label ?? ''
        );
      },
    },
    {
      dataField: 'email',
      text: 'EMAIL',
    },
    {
      dataField: 'job',
      text: 'NGHỀ NGHIỆP',
    },
    {
      dataField: 'nameContact',
      text: 'NGƯỜI LIÊN HỆ',
    },
    {
      dataField: 'phoneContact',
      text: 'SỐ ĐIỆN THOẠI LIÊN HỆ',
    },
    isAdmin
      ? {
          dataField: 'specifyVotes.count',
          text: 'SỐ LƯỢNG DỊCH VỤ',
          headerStyle,
          formatter: (cell, row) => {
            return <div className="d-flex justify-content-center">{cell}</div>;
          },
        }
      : null,
    {
      dataField: 'actions',
      text: 'HÀNH ĐỘNG',
      headerClasses: 'fixed-column',
      formatter: boxActions,
      headerStyle: {
        textAlign: 'center',
        zIndex: 3,
      },
      classes: 'fixed-column',
    },
  ].filter((column) => column !== null);
  function boxActions(__, row) {
    return (
      <div className="d-flex justify-content-center">
        <button
          id={'view' + row.id}
          onClick={() => {
            history.push(`/admin/patient/${row.id}?tab=patient`);
          }}
          className="btn p-0 btn-no-shadow mr-0"
        >
          <img src={IconEye} className="mr-1" />
        </button>
        <UncontrolledTooltip delay={1} placement="top" target={'view' + row.id}>
          Xem chi tiết
        </UncontrolledTooltip>
        <button
          id={'edit' + row.id}
          onClick={() => {
            dispatch(
              patientActions.getPatientById(row.id, 'populate=*', {
                success: (patient) => {
                  dispatch(
                    formActions.setValue({
                      name: 'modalInfo',
                      value: {
                        ...patient,
                        isAddAction: false,
                      },
                    })
                  );
                  dispatch(
                    formActions.setValue({
                      name: 'isModalVisible',
                      value: true,
                    })
                  );
                },
                failed: (err) => {
                  notify(
                    notificationAlertRef,
                    'danger',
                    'Thông báo',
                    `Lấy dữ liệu bệnh nhân để cập nhật thất bại! Lỗi: ${err}`
                  );
                },
              })
            );
          }}
          className="btn p-0 btn-no-shadow mr-0"
        >
          <img src={IconEdit} className="mr-1" />
        </button>
        <UncontrolledTooltip delay={1} placement="top" target={'edit' + row.id}>
          Cập nhật bệnh nhân
        </UncontrolledTooltip>
      </div>
    );
  }

  const onSizePerPageChange = (e) => {
    setIsUpdateReducer(true);
    dispatch(patientActions.setValuePatient({ name: 'page', value: 1 }));
    dispatch(
      patientActions.setValuePatient({
        name: 'limit',
        value: e.target.value,
      })
    );
  };

  const onPageChange = (value) => {
    setIsUpdateReducer(true);
    dispatch(patientActions.setValuePatient({ name: 'page', value: value }));
  };
  const paginations = paginationFactory({
    page: page,
    sizePerPage: limit,
    totalSize: pagination?.total,
    showTotal: false,
    withFirstAndLast: false,
    alwaysShowAllBtns: true,
    onPageChange: onPageChange,
    sizePerPageRenderer: () => {
      return (
        <div className="dataTables_length" id="datatable-basic_length">
          <label>
            Hiển thị{' '}
            {
              <select
                name="datatable-basic_length"
                aria-controls="datatable-basic"
                className="form-control form-control-sm"
                onChange={onSizePerPageChange}
              >
                <option value="10">10</option>
                <option value="25">25</option>
                <option value="50">50</option>
                <option value="100">100</option>
              </select>
            }{' '}
            dòng.
          </label>
        </div>
      );
    },
  });
  /**
   *  - Get the list of patients on the first render
   *  - Call api when pagination or search values change
   */
  function callListPatient() {
    const q = !!search
      ? {
          ...query,
          page: page,
          pageSize: limit,
          _q: search,
        }
      : {
          ...query,
          page: page,
          pageSize: limit,
        };
    dispatch(
      filterActions.setValueFilter({
        name: 'queryPage',
        value: q,
      })
    );
    dispatch(
      patientActions.getPatients(
        Object.assign(
          q,
          isDoctorKH
            ? {
                'filters[$and][1][$or][0][doctorAppointeds][code][$eq]':
                  currentAccountExtend.code,
              }
            : {}
        ),
        {
          success: (data) => {
            // if (!isUpdateReducer) {
            // }
          },
          failed: () => {},
        },
        isUpdateReducer
      )
    );
  }
  useEffect(() => {
    if (_.isEmpty(patients)) {
      callListPatient();
    }
  }, []);
  useDidUpdateEffect(() => {
    const q = !!search
      ? {
          ...query,
          page: isUpdateReducer ? page : 1,
          pageSize: isUpdateReducer ? limit : 10,
          _q: search,
        }
      : {
          ...query,
          page: isUpdateReducer ? page : 1,
          pageSize: isUpdateReducer ? limit : 10,
        };
    dispatch(
      filterActions.setValueFilter({
        name: 'queryPage',
        value: q,
      })
    );
    dispatch(
      patientActions.getPatients(
        queryString.stringify(
          Object.assign(
            q,
            isDoctorKH
              ? {
                  'filters[$and][1][$or][0][doctorAppointeds][code][$eq]':
                    currentAccountExtend.code,
                }
              : {}
          )
        ),
        {
          success: () => {},
          failed: () => {},
        },
        isUpdateReducer
      )
    );
  }, [page, limit, search, query]);
  useEffect(() => {}, [patients]);
  /**
   * Handle the search when the user finishes typing after 500 milliseconds
   */
  useDelayInputEffect(
    patientActions.setValuePatient({
      name: 'search',
      value: searchTerm,
    }),
    searchTerm,
    700
  );

  return (
    <>
      <SimpleHeader />
      <CUPatient />
      <Container className="mt--9" fluid>
        <Row>
          <Col xs={12}>
            <Card>
              <CardHeader className="pb-2">
                <h3>Danh sách</h3>
              </CardHeader>
              <ToolkitProvider
                data={_.isEmpty(patients) ? [] : patients}
                keyField="id"
                columns={columns}
                search={{
                  searchFormatted: true,
                }}
              >
                {(props) => (
                  <div className="pb-4 table-responsive">
                    <div className="d-flex ml-4 my-3 justify-content-between">
                      <div className="d-flex align-items-center">
                        <h5 style={{ fontWeight: 400, marginBottom: 0 }}>
                          Hiển thị từ {(page - 1) * limit + 1} đến{' '}
                          {patients?.length > page * limit
                            ? patients.length
                            : page * limit > pagination.total
                            ? pagination.total
                            : page * limit}{' '}
                          trong số {pagination?.total} bản ghi <br />
                        </h5>
                      </div>
                      <div
                        id="datatable-basic_filter"
                        className="dataTables_filter px-4 pb-1 d-flex align-items-center"
                      >
                        <h4 className="m-0 p-0">{nameTypeSearch}</h4>
                        <Input
                          type="text"
                          className="height-input"
                          placeholder="Nhập từ cần tìm"
                          onChange={(e) => {
                            if (searchTerm !== e) {
                              setIsUpdateReducer(true);
                              setSearchTerm(e.target.value);
                            }
                          }}
                        />
                      </div>
                    </div>

                    <BootstrapTable
                      {...props.baseProps}
                      noDataIndication={() => {
                        return (
                          <span className="font-weight-bold text-danger">
                            Không có dữ liệu!
                          </span>
                        );
                      }}
                      onTableChange={() => {
                        return <Loading />;
                      }}
                      remote
                      hover
                      bootstrap4={true}
                      pagination={paginations}
                      bordered={false}
                      filter={filterFactory()}
                      rowStyle={{
                        cursor: 'pointer',
                      }}
                      keyField="id"
                    />
                  </div>
                )}
              </ToolkitProvider>
            </Card>
          </Col>
        </Row>
      </Container>
    </>
  );
}

export default Patient;
