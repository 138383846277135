import typeActions from 'redux/typeActions';
import CONSTANT from 'constant';
import { returnValue } from 'common';

const initialState = {
  // loading
  isGettingBarcodes: false,
  isGettingBarcode: false,
  isCreatingBarcode: false,
  isUpdatingBarcode: false,
  isDeletingBarcode: false,
  isPrintingBarcode: false,
  // data
  barcodes: {},
  barcode: {},
  // error
  errors: {
    getBarcodes: '',
    getBarcode: '',
    createBarcode: '',
    updateBarcode: '',
    deleteBarcode: '',
  },
  search: '',
  page: 1,
  limit: 10,
  sort: 'createdAt:asc',
};

const barcodeReducer = (state = initialState, actions) => {
  switch (actions.type) {
    case typeActions.SET_BARCODE_VALUE:
      const { name, value } = actions;
      if (typeof name !== 'string') return { ...state };
      const obj = returnValue(state, name, value);

      return {
        ...state,
        ...obj,
      };

    case typeActions.GET_BARCODES_REQUEST:
      return {
        ...state,
        isGettingBarcodes: true,
        errors: {
          ...state.errors,
          getBarcodes: '',
        },
      };
    case typeActions.GET_BARCODES_SUCCESS:
      return {
        ...state,
        barcodes: actions.data ?? {},
        isGettingBarcodes: false,
        errors: {
          ...state.errors,
          getBarcodes: '',
        },
      };
    case typeActions.GET_BARCODES_FAILED:
      return {
        ...state,
        barcodes: {},
        isGettingBarcodes: false,
        errors: {
          ...state.errors,
          getBarcodes: actions.error,
        },
      };

    case typeActions.GET_BARCODE_REQUEST:
      return {
        ...state,
        isGettingBarcode: true,
        errors: {
          ...state.errors,
          getBarcode: '',
        },
      };
    case typeActions.GET_BARCODE_SUCCESS:
      return {
        ...state,
        barcode: actions.data || {},
        isGettingBarcode: false,
        errors: {
          ...state.errors,
          getBarcode: '',
        },
      };
    case typeActions.GET_BARCODE_FAILED:
      return {
        ...state,
        barcode: {},
        isGettingBarcode: false,
        errors: {
          ...state.errors,
          getBarcode: actions.error,
        },
      };

    case typeActions.CREATE_BARCODE_REQUEST:
      return {
        ...state,
        isCreatingBarcode: true,
        errors: {
          ...state.errors,
          createBarcode: '',
        },
      };
    case typeActions.CREATE_BARCODE_SUCCESS:
      return {
        ...state,
        isCreatingBarcode: false,
        errors: {
          ...state.errors,
          createBarcode: '',
        },
      };
    case typeActions.CREATE_BARCODE_FAILED:
      return {
        ...state,
        isCreatingBarcode: false,
        errors: {
          ...state.errors,
          createBarcode: actions.error,
        },
      };

    case typeActions.UPDATE_BARCODE_REQUEST:
      return {
        ...state,
        isUpdatingBarcode: true,
        errors: {
          ...state.errors,
          updateBarcode: '',
        },
      };
    case typeActions.UPDATE_BARCODE_SUCCESS:
      return {
        ...state,
        isUpdatingBarcode: false,
        errors: {
          ...state.errors,
          updateBarcode: '',
        },
      };
    case typeActions.UPDATE_BARCODE_FAILED:
      return {
        ...state,
        isUpdatingBarcode: false,
        errors: {
          ...state.errors,
          updateBarcode: actions.error,
        },
      };

    case typeActions.DELETE_BARCODE_REQUEST:
      return {
        ...state,
        isDeletingBarcode: true,
        errors: {
          ...state.errors,
          deleteBarcode: '',
        },
      };
    case typeActions.DELETE_BARCODE_SUCCESS:
      return {
        ...state,
        isDeletingBarcode: false,
        errors: {
          ...state.errors,
          deleteBarcode: '',
        },
      };
    case typeActions.DELETE_BARCODE_FAILED:
      return {
        ...state,
        isDeletingBarcode: false,
        errors: {
          ...state.errors,
          deleteBarcode: actions.error,
        },
      };

    case typeActions.PRINT_BARCODES_REQUEST:
      return {
        ...state,
        isPrintingBarcode: true,
      };
    case typeActions.PRINT_BARCODES_SUCCESS:
      return {
        ...state,
        isPrintingBarcode: false,
      };
    case typeActions.PRINT_BARCODES_FAILED:
      return {
        ...state,
        isPrintingBarcode: false,
      };

    default:
      return {
        ...state,
      };
  }
};
export default barcodeReducer;
