import typeActions from 'redux/typeActions';
const setValuePartner = ({ name, value }) => {
  return {
    type: typeActions.SET_PARTNER_VALUE,
    name,
    value,
  };
};
const getPartners = (query, callback, isUpdateReducer = true) => {
  return {
    type: typeActions.GET_PARTNERS_REQUEST,
    query,
    callback,
    isUpdateReducer,
  };
};
const createPartner = (params, callback) => {
  return {
    type: typeActions.CREATE_PARTNER_REQUEST,
    params,
    callback,
  };
};
const updatePartner = (params, body, callback) => {
  return {
    type: typeActions.UPDATE_PARTNER_REQUEST,
    params,
    body,
    callback,
  };
};
const deletePartner = (params, callback) => {
  return {
    type: typeActions.DELETE_PARTNER_REQUEST,
    params,
    callback,
  };
};
const exportPartners = (query, callback) => {
  return {
    type: typeActions.EXPORT_PARTNER_REQUEST,
    callback,
    query,
  };
};
export default {
  setValuePartner,
  getPartners,
  deletePartner,
  updatePartner,
  createPartner,
  exportPartners
};
