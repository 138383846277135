import React, { memo } from 'react';
import { FormGroup, Input, Label, Row } from 'reactstrap';
import { PropTypes } from 'prop-types';
import HelperText from './HelperText';
import LabelCustom from './LabelCustom';
import DatePicker from 'react-datepicker';
import 'assets/css/custom-components/date-picker-custom.css';
import classNames from 'classnames';
import CustomInputCalendar from './CustomInputCalendar';
const DatePickerCustom = ({
  name = '',
  label = '',
  row = false,
  type = 'text',
  placeholder = '',
  value = '',
  id = '',
  maxWidth = '',
  styleInput = {},
  isRequired = false,
  styleLabel = {},
  disabled = false,
  labelWrap = false,
  labelWidth = 120,
  lableTextAlign,
  showTimeSelect = false,
  handleChange = () => {},
  handleBlur = () => {},
  onClick = () => {},
  error,
  placeholderText = false,
  checked,
  touched,
  selected,
  dateFormat = 'dd/MM/yyyy',
  timeIntervals = 30,
  isClock = false,
  isFullTime = true,
  ...rest
}) => {
  return (
    <div
      style={
        row
          ? {
              display: 'flex',
            }
          : {}
      }
    >
      <LabelCustom
        isRequired={isRequired}
        label={label}
        id={name}
        style={{
          whiteSpace: labelWrap ? 'normal' : 'nowrap',
          margin: row ? '0.5em 8px 0px 0px' : undefined,
          textAlign: lableTextAlign,
        }}
        styleLabel={{
          minWidth: row ? labelWidth : undefined,
          textAlign: lableTextAlign,
        }}
      />
      <div
        className="w-100"
        style={{
          paddingLeft: row ? '16px' : undefined,
        }}
      >
        <div
          className="date-picker-custom d-flex"
          style={{
            paddingLeft: row ? '0px' : undefined,
          }}
        >
          <DatePicker
            dateFormat={dateFormat}
            selected={selected}
            onChange={handleChange}
            disabled={disabled}
            className={classNames({
              'input-date-picker-custom': true,
              'border-red': !!error && !!touched,
              'border-gray': !error && !touched,
            })}
            wrapperClassName={classNames({
              'icon-clock': isClock,
              'icon-calendar': !isClock,
            })}
            calendarClassName={classNames({
              'is-open-month-container': isClock || !isFullTime,
            })}
            showTimeSelect={showTimeSelect}
            timeIntervals={timeIntervals}
            placeholderText={placeholderText ? placeholderText : 'dd/mm/yyyy'}
            {...rest}
          />
          <div
            style={{
              display: !row ? 'none' : undefined,
            }}
          >
            {error && !!touched && !row && <HelperText message={error} />}
          </div>
        </div>
        {error && !!touched && (
          <HelperText
            style={{
              // marginLeft: row ? '8px' : undefined,
              paddingLeft: 8,
            }}
            message={error}
          />
        )}
      </div>
    </div>
  );
};
DatePickerCustom.propTypes = {
  row: PropTypes.bool,
};
export default memo(DatePickerCustom);
