import Login from 'views/pages/Login';
import Account from 'views/pages/Account';
import Area from 'views/pages/Area';
import AccountInfo from 'views/pages/AccountInfo';
import Role from 'views/pages/Role';
import Barcode from 'views/pages/Barcode';
import Organization from 'views/pages/Organization';
import Partner from 'views/pages/Partner';
import Service from 'views/pages/Service';
import Promotion from 'views/pages/Promotion';
import SpecifyVotes from 'views/pages/SpecifyVotes';
import SpecifyVotesCreate from 'views/pages/SpecifyVotes/components/pages/CUSpecifyVotes';
import SpecifyVotesRead from 'views/pages/SpecifyVotes/components/pages//ReadSpecifyVotes';
import Patient from 'views/pages/Patient';
import PatientView from 'views/pages/Patient/components/pages/ReadPatient';
//icon
import list from 'assets/img/icons/categories/list.svg';
import iconResult from 'assets/img/icons/categories/result.svg';
import iconAccount from 'assets/img/icons/categories/user.svg';
import iconDecentralization from 'assets/img/icons/categories/lock.svg';
import iconCategories from 'assets/img/icons/categories/categories.svg';
import iconEarn from 'assets/img/icons/categories/earn.png';
import iconFolder from 'assets/img/icons/categories/folder.svg';

import SynthesisSampleTransferIcon from 'assets/svgs/synthesis-sample-transfer.svg';
import BarcodeIcon from 'assets/svgs/barcode.svg';
import OrderIcon from 'assets/svgs/order.svg';
import PromotionIcon from 'assets/svgs/promotion.svg';
import StatisticsIcon from 'assets/svgs/statistics.svg';
import ResetPassword from 'views/pages/ResetPassword';
import PromotionDetail from 'views/pages/PromotionDetail';
import ServiceGroup from 'views/pages/ServiceGroup';
import Order from 'views/pages/Order';
import CUOrder from 'views/pages/CUOrder';
import ConvertFile from 'views/pages/ConvertFile';
import OrderInit from 'views/pages/OrderInit';
import OrderUnpaid from 'views/pages/OrderUnpaid';
import OrderDetail from 'views/pages/OrderDetail';
import AreaById from 'views/pages/AreaById';
import SynthesisSampleTransfer from 'views/pages/SynthesisSampleTransfer';
import SynthesisReceive from 'views/pages/SynthesisReceive';
import ReceivePay from 'views/pages/ReceivePay';
import ReceiveResults from 'views/pages/ReceiveResults';
import ReturnResults from 'views/pages/ReturnResults';
import Template from 'views/pages/Template';
import Dashboard from 'views/pages/dashboards/Dashboard';
import OrderUnapproved from 'views/pages/OrderUnapproved';
import AdditionalSample from 'views/pages/AdditionalSample';

// Route Nghiệp vụ
const routesM = [
  {
    showNav: false,
    name: 'Xem thông tin phiếu xét nghiệm',
    icon: {
      type: iconCategories,
      class: '',
    },
    path: '/specify-vote-all/:id',
    component: SpecifyVotesRead,
    layout: '/admin',
    roles: ['read-specify-vote-all'],
  },
  {
    showNav: false,
    name: 'Xem thông tin chi tiết bệnh nhân',
    icon: {
      type: iconCategories,
      class: '',
    },
    path: '/patient/:id',
    component: PatientView,
    layout: '/admin',
    roles: ['read-patient'],
  },
  {
    collapse: true,
    name: 'Quản lý phiếu xét nghiệm',
    icon: {
      type: list,
      class: '',
    },
    state: 'ordersCollapse',
    views: [
      {
        path: '/specify-vote-all',
        name: 'Danh sách phiếu xét nghiệm',
        miniName: 'B',
        component: SpecifyVotes,
        layout: '/admin',
        roles: ['read-specify-vote-all'],
      },
      {
        path: '/patient',
        name: 'Danh sách bệnh nhân',
        miniName: 'C',
        component: Patient,
        layout: '/admin',
        roles: ['read-patient'],
      },
      // {
      //   path: '/form-specify-votes',
      //   name: 'Danh sách mẫu phiếu xét nghiệm',
      //   miniName: 'C',
      //   component: Patient,
      //   layout: '/admin',
      // },
    ],
    roles: ['read-specify-vote-all', 'read-patient'],
  },

  {
    showNav: false,
    name: 'Thêm và cập nhật đơn hàng tổng hợp',
    icon: {
      type: iconCategories,
      class: '',
    },
    path: '/order/:id',
    component: CUOrder,
    layout: '/admin',
    roles: ['update-order-all'],
  },
  {
    showNav: false,
    name: 'Xem thông tin đơn hàng tổng hợp',
    icon: {
      type: iconCategories,
      class: '',
    },
    path: '/order-detail/:id',
    component: OrderDetail,
    layout: '/admin',
    roles: ['read-order-all', 'read-order-init', 'read-order-unpaid'],
  },
  {
    collapse: true,
    name: 'Quản lý đơn hàng',
    icon: {
      type: OrderIcon,
      class: '',
    },
    state: 'orderCollapse',
    views: [
      {
        path: '/order-all',
        name: 'Tổng hợp',
        miniName: 'O',
        component: Order,
        layout: '/admin',
        roles: ['read-order-all'],
      },
      {
        path: '/order-init',
        name: 'Cần cập nhật',
        miniName: 'OI',
        component: OrderInit,
        layout: '/admin',
        roles: ['read-order-init'],
      },
      {
        path: '/order-unpaid',
        name: 'Chờ thanh toán',
        miniName: 'OU',
        component: OrderUnpaid,
        layout: '/admin',
        roles: ['read-order-unpaid'],
      },
      {
        path: '/order-unapproved',
        name: 'Chờ duyệt',
        miniName: 'UA',
        component: OrderUnapproved,
        layout: '/admin',
        roles: ['read-order-unapproved'],
      },
      {
        path: '/additional-sample',
        name: 'Mẫu bổ sung',
        miniName: 'AS',
        component: AdditionalSample,
        layout: '/admin',
        roles: ['read-additional-sample'],
      },
    ],
    roles: ['read-order-all', 'read-order-init', 'read-order-unpaid'],
  },
  {
    showNav: false,
    collapse: false,
    name: 'Tiếp nhận đơn hàng',
    icon: {
      type: SynthesisSampleTransferIcon,
      class: '',
    },
    path: '/synthesis-sample-transfer/receive',
    component: SynthesisReceive,
    layout: '/admin',
    roles: ['update-synthesis-sample-transfer'],
  },
  {
    collapse: false,
    name: 'Tổng hợp - chuyển mẫu',
    icon: {
      type: SynthesisSampleTransferIcon,
      class: '',
    },
    path: '/synthesis-sample-transfer',
    component: SynthesisSampleTransfer,
    layout: '/admin',
    roles: ['read-synthesis-sample-transfer'],
  },
  {
    showNav: false,
    collapse: false,
    name: 'Tiếp nhận kết quả',
    icon: {
      type: SynthesisSampleTransferIcon,
      class: '',
    },
    state: 'orderCollapse',
    path: '/receive-results',
    component: ReceiveResults,
    layout: '/admin',
    roles: ['update-receive-result'],
  },
  {
    showNav: false,
    collapse: false,
    name: 'Tiếp nhận kết quả',
    icon: {
      type: SynthesisSampleTransferIcon,
      class: '',
    },
    path: '/return-results',
    component: ReturnResults,
    layout: '/admin',
    roles: ['update-receive-result'],
  },
  {
    collapse: true,
    name: 'Nhận và trả kết quả',
    icon: {
      type: iconResult,
      class: '',
    },
    state: 'receivePayCollapse',
    // path: '/receive-result',
    views: [
      {
        path: '/receive-result',
        name: 'Tiếp nhận',
        miniName: 'RPTN',
        component: ReceivePay,
        layout: '/admin',
        roles: ['read-receive-result'],
      },
      {
        path: '/approve-result',
        name: 'Chờ duyệt',
        miniName: 'RPCD',
        component: ReceivePay,
        layout: '/admin',
        roles: ['read-approve-result'],
      },
      {
        path: '/pay-result',
        name: 'Trả kết quả',
        miniName: 'RPTKQ',
        component: ReceivePay,
        layout: '/admin',
        roles: ['read-pay-result'],
      },
    ],
    roles: ['read-receive-result', 'read-approve-result', 'read-pay-result'],
  },
  {
    name: 'Thống kê',
    icon: {
      type: StatisticsIcon,
      class: '',
    },
    path: '/statistical',
    state: 'dashboardsCollapse',
    component: Dashboard,
    layout: '/admin',
    roles: [],
  },
];

// Route Auth
const routesA = [
  {
    collapse: true,
    name: 'Auth',
    icon: {
      type: list,
      class: '',
    },
    state: 'authCollapse',
    views: [
      {
        path: '/login',
        name: 'Đăng nhập',
        miniName: 'L',
        component: Login,
        layout: '/auth',
        roles: [],
      },
      {
        path: '/reset-password',
        name: 'Thiết lập mật khẩu',
        miniName: 'R',
        component: ResetPassword,
        layout: '/auth',
        roles: [],
      },
    ],
  },
];

const routeCF = [
  {
    name: 'Cập nhật kết quả',
    icon: '',
    path: '/convert-file',
    state: 'convertCollapse',
    component: ConvertFile,
    layout: '/admin',
    roles: [],
  },
];

// Route Quản trị cơ sở
const routesFA = [
  {
    showNav: false,
    name: 'Quản lý khu vực',
    icon: {
      type: iconCategories,
      class: '',
    },
    path: '/area/:id',
    component: AreaById,
    layout: '/admin',
    roles: ['read-area'],
  },
  {
    collapse: false,
    name: 'Quản lý khu vực',
    icon: {
      type: iconEarn,
      class: '',
    },
    path: '/area',
    component: Area,
    layout: '/admin',
    roles: ['read-area'],
  },
  {
    collapse: false,
    name: 'Quản lý tổ chức',
    icon: {
      type: iconAccount,
      class: '',
    },
    state: 'organizationCollapse',
    path: '/organizations',
    component: Organization,
    layout: '/admin',
    roles: ['read-organization'],
  },
  {
    collapse: true,
    name: 'Quản lý dịch vụ',
    icon: {
      type: iconCategories,
      class: '',
    },
    state: 'serviceCollapse',
    roles: ['read-service'],
    views: [
      {
        path: '/service-group',
        name: 'Nhóm dịch vụ',
        miniName: 'SG',
        component: ServiceGroup,
        layout: '/admin',
        roles: ['read-service'],
      },
      {
        path: '/service',
        name: 'Danh sách dịch vụ',
        miniName: 'S',
        component: Service,
        layout: '/admin',
        roles: ['read-service'],
      },
    ],
  },
  {
    collapse: true,
    name: 'Quản lý khuyến mại',
    icon: {
      type: PromotionIcon,
      class: '',
    },
    state: 'promotionCollapse',
    roles: ['read-promotion'],
    views: [
      {
        path: '/promotion',
        name: 'Chương trình khuyến mại',
        miniName: 'P',
        component: Promotion,
        layout: '/admin',
        roles: ['read-promotion'],
      },
      {
        path: '/promotion-detail',
        name: 'Chi tiết khuyến mại',
        miniName: 'PD',
        component: PromotionDetail,
        layout: '/admin',
        roles: ['read-promotion'],
      },
    ],
  },
  {
    showNav: false,
    name: 'Thêm mới phiếu xét nghiệm',
    icon: {
      type: iconAccount,
      class: '',
    },
    pathSub: '/specify-vote-all',
    path: '/specify-votes-create',
    component: SpecifyVotesCreate,
    layout: '/admin',
    roles: ['create-specify-vote-all'],
  },
  {
    showNav: false,
    name: 'Cập nhật phiếu xét nghiệm',
    icon: {
      type: iconAccount,
      class: '',
    },
    path: '/specify-votes-update/:id',
    component: SpecifyVotesCreate,
    layout: '/admin',
    roles: ['update-specify-vote-all'],
  },

  {
    collapse: false,
    name: 'Đối tác xét nghiệm',
    icon: {
      type: iconFolder,
      class: '',
    },
    component: Partner,
    state: 'partnerCollapse',
    path: '/partner',
    layout: '/admin',
    views: [],
    roles: ['read-partner'],
  },
  {
    collapse: false,
    name: 'Quản lý Barcode',
    icon: {
      type: BarcodeIcon,
      class: '',
    },
    component: Barcode,
    state: 'barcodeCollapse',
    path: '/barcode',
    layout: '/admin',
    roles: ['read-barcode'],
  },
  {
    collapse: false,
    name: 'Quản lý mẫu in',
    icon: {
      type: list,
      class: '',
    },
    component: Template,
    state: 'barcodeCollapse',
    path: '/template',
    layout: '/admin',
    roles: ['read-template'],
  },
];

// Route Quản trị hệ thống
const routesSM = [
  {
    name: 'Tài khoản',
    icon: {
      type: iconAccount,
      class: '',
    },
    path: '/account',
    component: Account,
    layout: '/admin',
    roles: ['read-account'],
  },
  {
    showNav: false,
    name: 'Tài khoản',
    icon: {
      type: iconAccount,
      class: '',
    },
    path: '/accountinfo',
    component: AccountInfo,
    layout: '/admin',
    roles: [],
  },
  {
    name: 'Phân quyền',
    icon: {
      type: iconDecentralization,
      class: '',
    },
    path: '/role',
    component: Role,
    layout: '/admin',
    roles: ['read-role'],
  },
  // {
  //   collapse: true,
  //   name: 'Thông báo',
  //   icon: {
  //     type: iconNotification,
  //     class: '',
  //   },
  //   state: 'notificationCollapse',
  //   views: [
  //     {
  //       path: '/notifications',
  //       name: 'Danh sách thông báo',
  //       miniName: 'D',
  //       component: Notifications,
  //       layout: '/admin',
  //     },
  //     {
  //       path: '/notice-formd',
  //       name: 'Mẫu thông báo',
  //       miniName: 'A',
  //       component: FormNotification,
  //       layout: '/admin',
  //     },
  //   ],
  // },
];

export default {
  routesM,
  routeCF,
  routesA,
  // routesSR,
  routesFA,
  routesSM,
};
