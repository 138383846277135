const constant = {
  // key page, limit, query
  SET_VALUE_GENERAL: 'SET_VALUE_GENERAL',
  SET_VALUE_FILTER: 'SET_VALUE_FILTER',
  // Auth
  ACCOUNT_LOGIN_REQUEST: 'ACCOUNT_LOGIN_REQUEST',
  ACCOUNT_LOGIN_SUCCESS: 'ACCOUNT_LOGIN_SUCCESS',
  ACCOUNT_LOGIN_FAILED: 'ACCOUNT_LOGIN_FAILED',

  ACCOUNT_LOGOUT_REQUEST: 'ACCOUNT_LOGOUT_REQUEST',
  ACCOUNT_LOGOUT_SUCCESS: 'ACCOUNT_LOGOUT_SUCCESS',
  ACCOUNT_LOGOUT_FAILED: 'ACCOUNT_LOGOUT_FAILED',

  ACCOUNT_FORGOT_PASSWORD_REQUEST: 'ACCOUNT_FORGOT_PASSWORD_REQUEST',
  ACCOUNT_FORGOT_PASSWORD_SUCCESS: 'ACCOUNT_FORGOT_PASSWORD_SUCCESS',
  ACCOUNT_FORGOT_PASSWORD_FAILED: 'ACCOUNT_FORGOT_PASSWORD_FAILED',

  ACCOUNT_RESET_PASSWORD_REQUEST: 'ACCOUNT_RESET_PASSWORD_REQUEST',
  ACCOUNT_RESET_PASSWORD_SUCCESS: 'ACCOUNT_RESET_PASSWORD_SUCCESS',
  ACCOUNT_RESET_PASSWORD_FAILED: 'ACCOUNT_RESET_PASSWORD_FAILED',
  ACCOUNT_IMPORT_REQUEST: 'ACCOUNT_IMPORT_REQUEST',
  ACCOUNT_IMPORT_SUCCESS: 'ACCOUNT_IMPORT_SUCCESS',
  ACCOUNT_IMPORT_FAILED: 'ACCOUNT_IMPORT_FAILED',

  // Account
  SET_ACCOUNT_VALUE: 'SET_ACCOUNT_VALUE',
  GET_ACCOUNTS_REQUEST: 'GET_ACCOUNTS_REQUEST',
  GET_ACCOUNTS_SUCCESS: 'GET_ACCOUNTS_SUCCESS',
  GET_ACCOUNTS_FAILED: 'GET_ACCOUNTS_FAILED',

  GET_CURRENT_ACCOUNT_REQUEST: 'GET_CURRENT_ACCOUNT_REQUEST',
  GET_CURRENT_ACCOUNT_SUCCESS: 'GET_CURRENT_ACCOUNT_SUCCESS',
  GET_CURRENT_ACCOUNT_FAILED: 'GET_CURRENT_ACCOUNT_FAILED',

  GET_CURRENT_ACCOUNT_EXTEND_REQUEST: 'GET_CURRENT_ACCOUNT_EXTEND_REQUEST',
  GET_CURRENT_ACCOUNT_EXTEND_SUCCESS: 'GET_CURRENT_ACCOUNT_EXTEND_SUCCESS',
  GET_CURRENT_ACCOUNT_EXTEND_FAILED: 'GET_CURRENT_ACCOUNT_EXTEND_FAILED',

  GET_ACCOUNT_BY_ID_REQUEST: 'GET_ACCOUNT_BY_ID_REQUEST',
  GET_ACCOUNT_BY_ID_SUCCESS: 'GET_ACCOUNT_BY_ID_SUCCESS',
  GET_ACCOUNT_BY_ID_FAILED: 'GET_ACCOUNT_BY_ID_FAILED',

  GET_ACCOUNT_GROUP_BY_ID_REQUEST: 'GET_ACCOUNT_GROUP_BY_ID_REQUEST',
  GET_ACCOUNT_GROUP_BY_ID_SUCCESS: 'GET_ACCOUNT_GROUP_BY_ID_SUCCESS',
  GET_ACCOUNT_GROUP_BY_ID_FAILED: 'GET_ACCOUNT_GROUP_BY_ID_FAILED',

  CREATE_ACCOUNT_REQUEST: 'CREATE_ACCOUNT_REQUEST',
  CREATE_ACCOUNT_SUCCESS: 'CREATE_ACCOUNT_SUCCESS',
  CREATE_ACCOUNT_FAILED: 'CREATE_ACCOUNT_FAILED',

  UPDATE_ACCOUNT_REQUEST: 'UPDATE_ACCOUNT_REQUEST',
  UPDATE_ACCOUNT_SUCCESS: 'UPDATE_ACCOUNT_SUCCESS',
  UPDATE_ACCOUNT_FAILED: 'UPDATE_ACCOUNT_FAILED',

  DELETE_ACCOUNT_REQUEST: 'DELETE_ACCOUNT_REQUEST',
  DELETE_ACCOUNT_SUCCESS: 'DELETE_ACCOUNT_SUCCESS',
  DELETE_ACCOUNT_FAILED: 'DELETE_ACCOUNT_FAILED',

  CONFIG_PASSWORD_ACCOUNT_REQUEST: 'CONFIG_PASSWORD_ACCOUNT_REQUEST',
  CONFIG_PASSWORD_ACCOUNT_SUCCESS: 'CONFIG_PASSWORD_ACCOUNT_SUCCESS',
  CONFIG_PASSWORD_ACCOUNT_FAILED: 'CONFIG_PASSWORD_ACCOUNT_FAILED',

  ACCOUNT_CHANGE_PASSWORD_REQUEST: 'ACCOUNT_CHANGE_PASSWORD_REQUEST',
  ACCOUNT_CHANGE_PASSWORD_SUCCESS: 'ACCOUNT_CHANGE_PASSWORD_SUCCESS',
  ACCOUNT_CHANGE_PASSWORD_FAILED: 'ACCOUNT_CHANGE_PASSWORD_FAILED',

  GET_ACCOUNT_EXTEND_BY_ORGANIZATION_REQUEST:
    'GET_ACCOUNT_EXTEND_BY_ORGANIZATION_REQUEST',

  GET_ACCOUNT_EXTEND_BY_PARTNER_REQUEST:
    'GET_ACCOUNT_EXTEND_BY_PARTNER_REQUEST',

  // Role
  SET_ROLE_VALUE: 'SET_ROLE_VALUE',
  GET_ROLES_REQUEST: 'GET_ROLES_REQUEST',
  GET_ROLES_SUCCESS: 'GET_ROLES_SUCCESS',
  GET_ROLES_FAILED: 'GET_ROLES_FAILED',

  GET_ROLE_BY_ID_REQUEST: 'GET_ROLE_BY_ID_REQUEST',
  GET_ROLE_BY_ID_SUCCESS: 'GET_ROLE_BY_ID_SUCCESS',
  GET_ROLE_BY_ID_FAILED: 'GET_ROLE_BY_ID_FAILED',

  GET_PERMISSIONS_REQUEST: 'GET_PERMISSIONS_REQUEST',
  GET_PERMISSIONS_SUCCESS: 'GET_PERMISSIONS_SUCCESS',
  GET_PERMISSIONS_FAILED: 'GET_PERMISSIONS_FAILED',

  GET_CURRENT_PERMISSIONS_REQUEST: 'GET_CURRENT_PERMISSIONS_REQUEST',
  GET_CURRENT_PERMISSIONS_SUCCESS: 'GET_CURRENT_PERMISSIONS_SUCCESS',
  GET_CURRENT_PERMISSIONS_FAILED: 'GET_CURRENT_PERMISSIONS_FAILED',

  CREATE_ROLE_REQUEST: 'CREATE_ROLE_REQUEST',
  CREATE_ROLE_SUCCESS: 'CREATE_ROLE_SUCCESS',
  CREATE_ROLE_FAILED: 'CREATE_ROLE_FAILED',

  UPDATE_ROLE_REQUEST: 'UPDATE_ROLE_REQUEST',
  UPDATE_ROLE_SUCCESS: 'UPDATE_ROLE_SUCCESS',
  UPDATE_ROLE_FAILED: 'UPDATE_ROLE_FAILED',

  DELETE_ROLE_REQUEST: 'DELETE_ROLE_REQUEST',
  DELETE_ROLE_SUCCESS: 'DELETE_ROLE_SUCCESS',
  DELETE_ROLE_FAILED: 'DELETE_ROLE_FAILED',

  // timeline
  UPDATE_TEXT_TIMELINE: 'UPDATE_TEXT_TIMELINE',
  SET_VALUE_TIMELINE_REDUCER: 'SET_VALUE_TIMELINE_REDUCER',

  // notification
  SET_NOTIFICATION_VALUE: 'SET_NOTIFICATION_VALUE',
  GET_NOTIFICATION_REQUEST: 'GET_NOTIFICATION_REQUEST',
  GET_NOTIFICATION_SUCCESS: 'GET_NOTIFICATION_SUCCESS',
  GET_NOTIFICATION_FAILED: 'GET_NOTIFICATION_FAILED',

  CREATE_PERMISSION_REQUEST: 'CREATE_PERMISSION_REQUEST',
  CREATE_PERMISSION_SUCCESS: 'CREATE_PERMISSION_SUCCESS',
  CREATE_PERMISSION_FAILED: 'CREATE_PERMISSION_FAILED',

  GET_ROLE_PERMISSIONS_REQUEST: 'GET_ROLE_PERMISSIONS_REQUEST',
  GET_ROLE_PERMISSIONS_SUCCESS: 'GET_ROLE_PERMISSIONS_SUCCESS',
  GET_ROLE_PERMISSIONS_FAILED: 'GET_ROLE_PERMISSIONS_FAILED',

  DELETE_NOTIFICATION_REQUEST: 'DELETE_NOTIFICATION_REQUEST',
  DELETE_NOTIFICATION_SUCCESS: 'DELETE_NOTIFICATION_SUCCESS',
  DELETE_NOTIFICATION_FAILED: 'DELETE_NOTIFICATION_FAILED',

  OPEN_FORM_NOTIFICATION: 'OPEN_FORM_NOTIFICATION',

  // Account Extend
  GET_ACCOUNTS_EXTEND_REQUEST: 'GET_ACCOUNTS_EXTEND_REQUEST',
  GET_ACCOUNTS_EXTEND_SUCCESS: 'GET_ACCOUNTS_EXTEND_SUCCESS',
  GET_ACCOUNTS_EXTEND_FAILED: 'GET_ACCOUNTS_EXTEND_FAILED',

  GET_ACCOUNT_EXTEND_REQUEST: 'GET_ACCOUNT_EXTEND_REQUEST',
  GET_ACCOUNT_EXTEND_SUCCESS: 'GET_ACCOUNT_EXTEND_SUCCESS',
  GET_ACCOUNT_EXTEND_FAILED: 'GET_ACCOUNT_EXTEND_FAILED',

  GET_RELATION_CHILDRNT_BY_ACCOUNT_EXTEND_REQUEST:
    'GET_RELATION_CHILDRNT_BY_ACCOUNT_EXTEND_REQUEST',
  GET_RELATION_CHILDRNT_BY_ACCOUNT_EXTEND_SUCCESS:
    'GET_RELATION_CHILDRNT_BY_ACCOUNT_EXTEND_SUCCESS',
  GET_RELATION_CHILDRNT_BY_ACCOUNT_EXTEND_FAILED:
    'GET_RELATION_CHILDRNT_BY_ACCOUNT_EXTEND_FAILED',

  CREATE_ACCOUNT_EXTEND_REQUEST: 'CREATE_ACCOUNT_EXTEND_REQUEST',
  CREATE_ACCOUNT_EXTEND_SUCCESS: 'CREATE_ACCOUNT_EXTEND_SUCCESS',
  CREATE_ACCOUNT_EXTEND_FAILED: 'CREATE_ACCOUNT_EXTEND_FAILED',

  UPDATE_ACCOUNT_EXTEND_REQUEST: 'UPDATE_ACCOUNT_EXTEND_REQUEST',
  UPDATE_ACCOUNT_EXTEND_SUCCESS: 'UPDATE_ACCOUNT_EXTEND_SUCCESS',
  UPDATE_ACCOUNT_EXTEND_FAILED: 'UPDATE_ACCOUNT_EXTEND_FAILED',

  DELETE_ACCOUNT_EXTEND_REQUEST: 'DELETE_ACCOUNT_EXTEND_REQUEST',
  DELETE_ACCOUNT_EXTEND_SUCCESS: 'DELETE_ACCOUNT_EXTEND_SUCCESS',
  DELETE_ACCOUNT_EXTEND_FAILED: 'DELETE_ACCOUNT_EXTEND_FAILED',

  UPLOAD_AVATAR_REQUEST: 'UPLOAD_AVATAR_REQUEST',
  UPLOAD_AVATAR_SUCCESS: 'UPLOAD_AVATAR_SUCCESS',
  UPLOAD_AVATAR_FAILED: 'UPLOAD_AVATAR_FAILED',

  DELETE_AVATAR_REQUEST: 'DELETE_AVATAR_REQUEST',
  DELETE_AVATAR_SUCCESS: 'DELETE_AVATAR_SUCCESS',
  DELETE_AVATAR_FAILED: 'DELETE_AVATAR_FAILED',

  UPDATE_CURRENT_ACCOUNT_REQUEST: 'UPDATE_CURRENT_ACCOUNT_REQUEST',
  UPDATE_CURRENT_ACCOUNT_SUCCESS: 'UPDATE_CURRENT_ACCOUNT_SUCCESS',
  UPDATE_CURRENT_ACCOUNT_FAILED: 'UPDATE_CURRENT_ACCOUNT_FAILED',

  // Role extend
  GET_ROLES_EXTEND_REQUEST: 'GET_ROLES_EXTEND_REQUEST',
  GET_ROLES_EXTEND_SUCCESS: 'GET_ROLES_EXTEND_SUCCESS',
  GET_ROLES_EXTEND_FAILED: 'GET_ROLES_EXTEND_FAILED',

  GET_ROLE_EXTEND_REQUEST: 'GET_ROLE_EXTEND_REQUEST',
  GET_ROLE_EXTEND_SUCCESS: 'GET_ROLE_EXTEND_SUCCESS',
  GET_ROLE_EXTEND_FAILED: 'GET_ROLE_EXTEND_FAILED',

  CREATE_ROLE_EXTEND_REQUEST: 'CREATE_ROLE_EXTEND_REQUEST',
  CREATE_ROLE_EXTEND_SUCCESS: 'CREATE_ROLE_EXTEND_SUCCESS',
  CREATE_ROLE_EXTEND_FAILED: 'CREATE_ROLE_EXTEND_FAILED',

  UPDATE_ROLE_EXTEND_REQUEST: 'UPDATE_ROLE_EXTEND_REQUEST',
  UPDATE_ROLE_EXTEND_SUCCESS: 'UPDATE_ROLE_EXTEND_SUCCESS',
  UPDATE_ROLE_EXTEND_FAILED: 'UPDATE_ROLE_EXTEND_FAILED',

  DELETE_ROLE_EXTEND_REQUEST: 'DELETE_ROLE_EXTEND_REQUEST',
  DELETE_ROLE_EXTEND_SUCCESS: 'DELETE_ROLE_EXTEND_SUCCESS',
  DELETE_ROLE_EXTEND_FAILED: 'DELETE_ROLE_EXTEND_FAILED',
  //areas
  GET_AREAS_REQUEST: 'GET_AREAS_REQUEST',
  GET_AREAS_SUCCESS: 'GET_AREAS_SUCCESS',
  GET_AREAS_FAILED: 'GET_AREAS_FAILED',

  GET_AREA_REQUEST: 'GET_AREA_REQUEST',
  GET_AREA_SUCCESS: 'GET_AREA_SUCCESS',
  GET_AREA_FAILED: 'GET_AREA_FAILED',

  UPDATE_AREA_REQUEST: 'UPDATE_AREA_REQUEST',
  UPDATE_AREA_SUCCESS: 'UPDATE_AREA_SUCCESS',
  UPDATE_AREA_FAILED: 'UPDATE_AREA_FAILED',

  DELETE_AREA_REQUEST: 'DELETE_AREA_REQUEST',
  DELETE_AREA_SUCCESS: 'DELETE_AREA_SUCCESS',
  DELETE_AREA_FAILED: 'DELETE_AREA_FAILED',

  CREATE_AREA_REQUEST: 'CREATE_AREA_REQUEST',
  CREATE_AREA_SUCCESS: 'CREATE_AREA_SUCCESS',
  CREATE_AREA_FAILED: 'CREATE_AREA_FAILED',

  GET_AREA_BY_IDS_REQUEST: 'GET_AREA_BY_IDS_REQUEST',
  GET_AREA_BY_IDS_SUCCESS: 'GET_AREA_BY_IDS_SUCCESS',
  GET_AREA_BY_IDS_FAILED: 'GET_AREA_BY_IDS_FAILED',

  GET_AREAS_BY_ACCOUNT_EXTEND_REQUEST: 'GET_AREAS_BY_ACCOUNT_EXTEND_REQUEST',
  GET_AREAS_BY_ACCOUNT_EXTEND_SUCCESS: 'GET_AREAS_BY_ACCOUNT_EXTEND_SUCCESS',
  GET_AREAS_BY_ACCOUNT_EXTEND_FAILED: 'GET_AREAS_BY_ACCOUNT_EXTEND_FAILED',

  SET_AREA_VALUE: 'SET_AREA_VALUE',

  // Barcode
  SET_BARCODE_VALUE: 'SET_BARCODE_VALUE',
  GET_BARCODES_REQUEST: 'GET_BARCODES_REQUEST',
  GET_BARCODES_SUCCESS: 'GET_BARCODES_SUCCESS',
  GET_BARCODES_FAILED: 'GET_BARCODES_FAILED',

  GET_BARCODE_REQUEST: 'GET_BARCODE_REQUEST',
  GET_BARCODE_SUCCESS: 'GET_BARCODE_SUCCESS',
  GET_BARCODE_FAILED: 'GET_BARCODE_FAILED',

  CREATE_BARCODE_REQUEST: 'CREATE_BARCODE_REQUEST',
  CREATE_BARCODE_SUCCESS: 'CREATE_BARCODE_SUCCESS',
  CREATE_BARCODE_FAILED: 'CREATE_BARCODE_FAILED',

  UPDATE_BARCODE_REQUEST: 'UPDATE_BARCODE_REQUEST',
  UPDATE_BARCODE_SUCCESS: 'UPDATE_BARCODE_SUCCESS',
  UPDATE_BARCODE_FAILED: 'UPDATE_BARCODE_FAILED',

  DELETE_BARCODE_REQUEST: 'DELETE_BARCODE_REQUEST',
  DELETE_BARCODE_SUCCESS: 'DELETE_BARCODE_SUCCESS',
  DELETE_BARCODE_FAILED: 'DELETE_BARCODE_FAILED',

  PRINT_BARCODES_REQUEST: 'PRINT_BARCODES_REQUEST',
  PRINT_BARCODES_SUCCESS: 'PRINT_BARCODES_SUCCESS',
  PRINT_BARCODES_FAILED: 'PRINT_BARCODES_FAILED',
  //organizations
  GET_ORGANIZATION_BY_ID_REQUEST: 'GET_ORGANIZATION_BY_ID_REQUEST',
  GET_ORGANIZATION_BY_ID_SUCCESS: 'GET_ORGANIZATION_BY_ID_SUCCESS',
  GET_ORGANIZATION_BY_ID_FAILED: 'GET_ORGANIZATION_BY_ID_FAILED',

  GET_ORGANIZATION_REQUEST: 'GET_ORGANIZATION_REQUEST',
  GET_ORGANIZATION_SUCCESS: 'GET_ORGANIZATION_SUCCESS',
  GET_ORGANIZATION_FAILED: 'GET_ORGANIZATION_FAILED',

  UPDATE_ORGANIZATION_REQUEST: 'UPDATE_ORGANIZATION_REQUEST',
  UPDATE_ORGANIZATION_SUCCESS: 'UPDATE_ORGANIZATION_SUCCESS',
  UPDATE_ORGANIZATION_FAILED: 'UPDATE_ORGANIZATION_FAILED',

  DELETE_ORGANIZATION_REQUEST: 'DELETE_ORGANIZATION_REQUEST',
  DELETE_ORGANIZATION_SUCCESS: 'DELETE_ORGANIZATION_SUCCESS',
  DELETE_ORGANIZATION_FAILED: 'DELETE_ORGANIZATION_FAILED',

  CREATE_ORGANIZATION_REQUEST: 'CREATE_ORGANIZATION_REQUEST',
  CREATE_ORGANIZATION_SUCCESS: 'CREATE_ORGANIZATION_SUCCESS',
  CREATE_ORGANIZATION_FAILED: 'CREATE_ORGANIZATION_FAILED',

  SET_ORGANIZATION_VALUE: 'SET_ORGANIZATION_VALUE',

  GET_ORGANIZATION_BY_REQUEST: 'GET_ORGANIZATION_BY_REQUEST',
  GET_ORGANIZATION_BY_SUCCESS: 'GET_ORGANIZATION_BY_SUCCESS',
  GET_ORGANIZATION_BY_FAILED: 'GET_ORGANIZATION_BY_FAILED',

  GET_ORGANIZATIONS_BY_ACCOUNT_EXTEND_REQUEST:
    'GET_ORGANIZATIONS_BY_ACCOUNT_EXTEND_REQUEST',
  GET_ORGANIZATIONS_BY_ACCOUNT_EXTEND_SUCCESS:
    'GET_ORGANIZATIONS_BY_ACCOUNT_EXTEND_SUCCESS',
  GET_ORGANIZATIONS_BY_ACCOUNT_EXTEND_FAILED:
    'GET_ORGANIZATIONS_BY_ACCOUNT_EXTEND_FAILED',

  GET_AREAS_BY_ACCOUNT_EXTEND_REQUEST: 'GET_AREAS_BY_ACCOUNT_EXTEND_REQUEST',
  GET_AREAS_BY_ACCOUNT_EXTEND_SUCCESS: 'GET_AREAS_BY_ACCOUNT_EXTEND_SUCCESS',
  GET_AREAS_BY_ACCOUNT_EXTEND_FAILED: 'GET_AREAS_BY_ACCOUNT_EXTEND_FAILED',

  GET_ORGANIZATIONS_BY_ACCOUNT_BS_REQUEST:
    'GET_ORGANIZATIONS_BY_ACCOUNT_BS_REQUEST',
  GET_ORGANIZATIONS_BY_ACCOUNT_BS_SUCCESS:
    'GET_ORGANIZATIONS_BY_ACCOUNT_BS_SUCCESS',
  GET_ORGANIZATIONS_BY_ACCOUNT_BS_FAILED:
    'GET_ORGANIZATIONS_BY_ACCOUNT_BS_FAILED',
  //staff
  GET_STAFF_REQUEST: 'GET_STAFF_REQUEST',
  GET_STAFF_SUCCESS: 'GET_STAFF_SUCCESS',
  GET_STAFF_FAILED: 'GET_STAFF_FAILED',

  UPDATE_STAFF_REQUEST: 'UPDATE_STAFF_REQUEST',
  UPDATE_STAFF_SUCCESS: 'UPDATE_STAFF_SUCCESS',
  UPDATE_STAFF_FAILED: 'UPDATE_STAFF_FAILED',

  DELETE_STAFF_REQUEST: 'DELETE_STAFF_REQUEST',
  DELETE_STAFF_SUCCESS: 'DELETE_STAFF_SUCCESS',
  DELETE_STAFF_FAILED: 'DELETE_STAFF_FAILED',

  CREATE_STAFF_REQUEST: 'CREATE_STAFF_REQUEST',
  CREATE_STAFF_SUCCESS: 'CREATE_STAFF_SUCCESS',
  CREATE_STAFF_FAILED: 'CREATE_STAFF_FAILED',

  SET_STAFF_VALUE: 'SET_STAFF_VALUE',

  // Service
  SET_SERVICE_VALUE: 'SET_SERVICE_VALUE',
  GET_SERVICES_REQUEST: 'GET_SERVICES_REQUEST',
  GET_SERVICES_SUCCESS: 'GET_SERVICES_SUCCESS',
  GET_SERVICES_FAILED: 'GET_SERVICES_FAILED',

  GET_SERVICE_REQUEST: 'GET_SERVICE_REQUEST',
  GET_SERVICE_SUCCESS: 'GET_SERVICE_SUCCESS',
  GET_SERVICE_FAILED: 'GET_SERVICE_FAILED',

  CREATE_SERVICE_REQUEST: 'CREATE_SERVICE_REQUEST',
  CREATE_SERVICE_SUCCESS: 'CREATE_SERVICE_SUCCESS',
  CREATE_SERVICE_FAILED: 'CREATE_SERVICE_FAILED',

  UPDATE_SERVICE_REQUEST: 'UPDATE_SERVICE_REQUEST',
  UPDATE_SERVICE_SUCCESS: 'UPDATE_SERVICE_SUCCESS',
  UPDATE_SERVICE_FAILED: 'UPDATE_SERVICE_FAILED',

  DELETE_SERVICE_REQUEST: 'DELETE_SERVICE_REQUEST',
  DELETE_SERVICE_SUCCESS: 'DELETE_SERVICE_SUCCESS',
  DELETE_SERVICE_FAILED: 'DELETE_SERVICE_FAILED',

  GET_SERVICE_BY_REQUEST: 'GET_SERVICE_BY_REQUEST',
  GET_SERVICE_BY_SUCCESS: 'GET_SERVICE_BY_SUCCESS',
  GET_SERVICE_BY_FAILED: 'GET_SERVICE_BY_FAILED',

  // Service Group
  SET_SERVICE_GROUP_VALUE: 'SET_SERVICE_GROUP_VALUE',
  GET_SERVICE_GROUPS_REQUEST: 'GET_SERVICE_GROUPS_REQUEST',
  GET_SERVICE_GROUPS_SUCCESS: 'GET_SERVICE_GROUPS_SUCCESS',
  GET_SERVICE_GROUPS_FAILED: 'GET_SERVICE_GROUPS_FAILED',

  GET_SERVICE_GROUP_REQUEST: 'GET_SERVICE_GROUP_REQUEST',
  GET_SERVICE_GROUP_SUCCESS: 'GET_SERVICE_GROUP_SUCCESS',
  GET_SERVICE_GROUP_FAILED: 'GET_SERVICE_GROUP_FAILED',

  CREATE_SERVICE_GROUP_REQUEST: 'CREATE_SERVICE_GROUP_REQUEST',
  CREATE_SERVICE_GROUP_SUCCESS: 'CREATE_SERVICE_GROUP_SUCCESS',
  CREATE_SERVICE_GROUP_FAILED: 'CREATE_SERVICE_GROUP_FAILED',

  UPDATE_SERVICE_GROUP_REQUEST: 'UPDATE_SERVICE_GROUP_REQUEST',
  UPDATE_SERVICE_GROUP_SUCCESS: 'UPDATE_SERVICE_GROUP_SUCCESS',
  UPDATE_SERVICE_GROUP_FAILED: 'UPDATE_SERVICE_GROUP_FAILED',

  DELETE_SERVICE_GROUP_REQUEST: 'DELETE_SERVICE_GROUP_REQUEST',
  DELETE_SERVICE_GROUP_SUCCESS: 'DELETE_SERVICE_GROUP_SUCCESS',
  DELETE_SERVICE_GROUP_FAILED: 'DELETE_SERVICE_GROUP_FAILED',

  GET_SERVICE_GROUP_BY_IDS_REQUEST: 'GET_SERVICE_GROUP_BY_IDS_REQUEST',
  GET_SERVICE_GROUP_BY_IDS_SUCCESS: 'GET_SERVICE_GROUP_BY_IDS_SUCCESS',
  GET_SERVICE_GROUP_BY_IDS_FAILED: 'GET_SERVICE_GROUP_BY_IDS_FAILED',

  // Promotion
  SET_PROMOTION_VALUE: 'SET_PROMOTION_VALUE',
  GET_PROMOTIONS_REQUEST: 'GET_PROMOTIONS_REQUEST',
  GET_PROMOTIONS_SUCCESS: 'GET_PROMOTIONS_SUCCESS',
  GET_PROMOTIONS_FAILED: 'GET_PROMOTIONS_FAILED',

  GET_PROMOTION_REQUEST: 'GET_PROMOTION_REQUEST',
  GET_PROMOTION_SUCCESS: 'GET_PROMOTION_SUCCESS',
  GET_PROMOTION_FAILED: 'GET_PROMOTION_FAILED',

  CREATE_PROMOTION_REQUEST: 'CREATE_PROMOTION_REQUEST',
  CREATE_PROMOTION_SUCCESS: 'CREATE_PROMOTION_SUCCESS',
  CREATE_PROMOTION_FAILED: 'CREATE_PROMOTION_FAILED',

  UPDATE_PROMOTION_REQUEST: 'UPDATE_PROMOTION_REQUEST',
  UPDATE_PROMOTION_SUCCESS: 'UPDATE_PROMOTION_SUCCESS',
  UPDATE_PROMOTION_FAILED: 'UPDATE_PROMOTION_FAILED',

  DELETE_PROMOTION_REQUEST: 'DELETE_PROMOTION_REQUEST',
  DELETE_PROMOTION_SUCCESS: 'DELETE_PROMOTION_SUCCESS',
  DELETE_PROMOTION_FAILED: 'DELETE_PROMOTION_FAILED',

  EXPORT_PROMOTION_REQUEST: 'EXPORT_PROMOTION_REQUEST',
  EXPORT_PROMOTION_SUCCESS: 'EXPORT_PROMOTION_SUCCESS',
  EXPORT_PROMOTION_FAILED: 'EXPORT_PROMOTION_FAILED',

  EXPORT_PROMOTION_DETAIL_REQUEST: 'EXPORT_PROMOTION_DETAIL_REQUEST',
  EXPORT_PROMOTION_DETAIL_SUCCESS: 'EXPORT_PROMOTION_DETAIL_SUCCESS',
  EXPORT_PROMOTION_DETAIL_FAILED: 'EXPORT_PROMOTION_DETAIL_FAILED',

  //partner
  GET_PARTNERS_REQUEST: 'GET_PARTNERS_REQUEST',
  GET_PARTNERS_SUCCESS: 'GET_PARTNERS_SUCCESS',
  GET_PARTNERS_FAILED: 'GET_PARTNERS_FAILED',

  GET_PARTNER_REQUEST: 'GET_PARTNER_REQUEST',
  GET_PARTNER_SUCCESS: 'GET_PARTNER_SUCCESS',
  GET_PARTNER_FAILED: 'GET_PARTNER_FAILED',

  UPDATE_PARTNER_REQUEST: 'UPDATE_PARTNER_REQUEST',
  UPDATE_PARTNER_SUCCESS: 'UPDATE_PARTNER_SUCCESS',
  UPDATE_PARTNER_FAILED: 'UPDATE_PARTNER_FAILED',

  DELETE_PARTNER_REQUEST: 'DELETE_PARTNER_REQUEST',
  DELETE_PARTNER_SUCCESS: 'DELETE_PARTNER_SUCCESS',
  DELETE_PARTNER_FAILED: 'DELETE_PARTNER_FAILED',

  CREATE_PARTNER_REQUEST: 'CREATE_PARTNER_REQUEST',
  CREATE_PARTNER_SUCCESS: 'CREATE_PARTNER_SUCCESS',
  CREATE_PARTNER_FAILED: 'CREATE_PARTNER_FAILED',

  SET_PARTNER_VALUE: 'SET_PARTNER_VALUE',
  //province
  GET_PROVINCE_REQUEST: 'GET_PROVINCE_REQUEST',
  GET_PROVINCE_SUCCESS: 'GET_PROVINCE_SUCCESS',
  GET_PROVINCE_FAILED: 'GET_PROVINCE_FAILED',

  UPDATE_PROVINCE_REQUEST: 'UPDATE_PROVINCE_REQUEST',
  UPDATE_PROVINCE_SUCCESS: 'UPDATE_PROVINCE_SUCCESS',
  UPDATE_PROVINCE_FAILED: 'UPDATE_PROVINCE_FAILED',

  DELETE_PROVINCE_REQUEST: 'DELETE_PROVINCE_REQUEST',
  DELETE_PROVINCE_SUCCESS: 'DELETE_PROVINCE_SUCCESS',
  DELETE_PROVINCE_FAILED: 'DELETE_PROVINCE_FAILED',

  CREATE_PROVINCE_REQUEST: 'CREATE_PROVINCE_REQUEST',
  CREATE_PROVINCE_SUCCESS: 'CREATE_PROVINCE_SUCCESS',
  CREATE_PROVINCE_FAILED: 'CREATE_PROVINCE_FAILED',

  SET_PROVINCE_VALUE: 'SET_PROVINCE_VALUE',
  // specify
  GET_SPECIFYVOTE_REQUEST: 'GET_SPECIFYVOTE_REQUEST',
  GET_SPECIFYVOTE_SUCCESS: 'GET_SPECIFYVOTE_SUCCESS',
  GET_SPECIFYVOTE_FAILED: 'GET_SPECIFYVOTE_FAILED',

  GET_SPECIFYVOTE_BY_ID_REQUEST: 'GET_SPECIFYVOTE_BY_ID_REQUEST',
  GET_SPECIFYVOTE_BY_ID_SUCCESS: 'GET_SPECIFYVOTE_BY_ID_SUCCESS',
  GET_SPECIFYVOTE_BY_ID_FAILED: 'GET_SPECIFYVOTE_BY_ID_FAILED',

  UPDATE_SPECIFYVOTE_REQUEST: 'UPDATE_SPECIFYVOTE_REQUEST',
  UPDATE_SPECIFYVOTE_SUCCESS: 'UPDATE_SPECIFYVOTE_SUCCESS',
  UPDATE_SPECIFYVOTE_FAILED: 'UPDATE_SPECIFYVOTE_FAILED',

  DELETE_SPECIFYVOTE_REQUEST: 'DELETE_SPECIFYVOTE_REQUEST',
  DELETE_SPECIFYVOTE_SUCCESS: 'DELETE_SPECIFYVOTE_SUCCESS',
  DELETE_SPECIFYVOTE_FAILED: 'DELETE_SPECIFYVOTE_FAILED',

  CREATE_SPECIFYVOTE_REQUEST: 'CREATE_SPECIFYVOTE_REQUEST',
  CREATE_SPECIFYVOTE_SUCCESS: 'CREATE_SPECIFYVOTE_SUCCESS',
  CREATE_SPECIFYVOTE_FAILED: 'CREATE_SPECIFYVOTE_FAILED',

  SET_SPECIFYVOTE_VALUE: 'SET_SPECIFYVOTE_VALUE',

  PRINT_SPECIFYVOTE_REQUEST: 'PRINT_SPECIFYVOTE_REQUEST',
  PRINT_SPECIFYVOTE_SUCCESS: 'PRINT_SPECIFYVOTE_SUCCESS',
  PRINT_SPECIFYVOTE_FAILED: 'PRINT_SPECIFYVOTE_FAILED',
  //template specifyvote
  SET_TEMPLATE_PCD_VALUE: 'SET_TEMPLATE_PCD_VALUE',

  GET_TEMPLATE_PCDS_REQUEST: 'GET_TEMPLATE_PCDS_REQUEST',
  GET_TEMPLATE_PCDS_SUCCESS: 'GET_TEMPLATE_PCDS_SUCCESS',
  GET_TEMPLATE_PCDS_FAILED: 'GET_TEMPLATE_PCDS_FAILED',

  UPDATE_TEMPLATE_PCD_REQUEST: 'UPDATE_TEMPLATE_PCD_REQUEST',
  UPDATE_TEMPLATE_PCD_SUCCESS: 'UPDATE_TEMPLATE_PCD_SUCCESS',
  UPDATE_TEMPLATE_PCD_FAILED: 'UPDATE_TEMPLATE_PCD_FAILED',

  // Promotion detail
  SET_PROMOTION_DETAIL_VALUE: 'SET_PROMOTION_DETAIL_VALUE',
  GET_PROMOTION_DETAIL_RELATION_REQUEST:
    'GET_PROMOTION_DETAIL_RELATION_REQUEST',
  GET_PROMOTION_DETAIL_RELATION_SUCCESS:
    'GET_PROMOTION_DETAIL_RELATION_SUCCESS',
  GET_PROMOTION_DETAIL_RELATION_FAILED: 'GET_PROMOTION_DETAIL_RELATION_FAILED',
  GET_PROMOTION_DETAILS_REQUEST: 'GET_PROMOTION_DETAILS_REQUEST',
  GET_PROMOTION_DETAILS_SUCCESS: 'GET_PROMOTION_DETAILS_SUCCESS',
  GET_PROMOTION_DETAILS_FAILED: 'GET_PROMOTION_DETAILS_FAILED',

  GET_PROMOTION_DETAIL_REQUEST: 'GET_PROMOTION_DETAIL_REQUEST',
  GET_PROMOTION_DETAIL_SUCCESS: 'GET_PROMOTION_DETAIL_SUCCESS',
  GET_PROMOTION_DETAIL_FAILED: 'GET_PROMOTION_DETAIL_FAILED',

  CREATE_PROMOTION_DETAIL_REQUEST: 'CREATE_PROMOTION_DETAIL_REQUEST',
  CREATE_PROMOTION_DETAIL_SUCCESS: 'CREATE_PROMOTION_DETAIL_SUCCESS',
  CREATE_PROMOTION_DETAIL_FAILED: 'CREATE_PROMOTION_DETAIL_FAILED',

  UPDATE_PROMOTION_DETAIL_REQUEST: 'UPDATE_PROMOTION_DETAIL_REQUEST',
  UPDATE_PROMOTION_DETAIL_SUCCESS: 'UPDATE_PROMOTION_DETAIL_SUCCESS',
  UPDATE_PROMOTION_DETAIL_FAILED: 'UPDATE_PROMOTION_DETAIL_FAILED',

  DELETE_PROMOTION_DETAIL_REQUEST: 'DELETE_PROMOTION_DETAIL_REQUEST',
  DELETE_PROMOTION_DETAIL_SUCCESS: 'DELETE_PROMOTION_DETAIL_SUCCESS',
  DELETE_PROMOTION_DETAIL_FAILED: 'DELETE_PROMOTION_DETAIL_FAILED',

  //patient
  GET_PATIENT_REQUEST: 'GET_PATIENT_REQUEST',
  GET_PATIENT_SUCCESS: 'GET_PATIENT_SUCCESS',
  GET_PATIENT_FAILED: 'GET_PATIENT_FAILED',

  GET_PATIENT_BY_ID_REQUEST: 'GET_PATIENT_BY_ID_REQUEST',
  GET_PATIENT_BY_ID_SUCCESS: 'GET_PATIENT_BY_ID_SUCCESS',
  GET_PATIENT_BY_ID_FAILED: 'GET_PATIENT_BY_ID_FAILED',

  UPDATE_PATIENT_REQUEST: 'UPDATE_PATIENT_REQUEST',
  UPDATE_PATIENT_SUCCESS: 'UPDATE_PATIENT_SUCCESS',
  UPDATE_PATIENT_FAILED: 'UPDATE_PATIENT_FAILED',

  DELETE_PATIENT_REQUEST: 'DELETE_PATIENT_REQUEST',
  DELETE_PATIENT_SUCCESS: 'DELETE_PATIENT_SUCCESS',
  DELETE_PATIENT_FAILED: 'DELETE_PATIENT_FAILED',

  CREATE_PATIENT_REQUEST: 'CREATE_PATIENT_REQUEST',
  CREATE_PATIENT_SUCCESS: 'CREATE_PATIENT_SUCCESS',
  CREATE_PATIENT_FAILED: 'CREATE_PATIENT_FAILED',

  SET_PATIENT_VALUE: 'SET_PATIENT_VALUE',

  GET_PATIENT_BY_REQUEST: 'GET_PATIENT_BY_REQUEST',
  GET_PATIENT_BY_SUCCESS: 'GET_PATIENT_BY_SUCCESS',
  GET_PATIENT_BY_FAILED: 'GET_PATIENT_BY_FAILED',
  //patient
  GET_MEDICALRECORD_REQUEST: 'GET_MEDICALRECORD_REQUEST',
  GET_MEDICALRECORD_SUCCESS: 'GET_MEDICALRECORD_SUCCESS',
  GET_MEDICALRECORD_FAILED: 'GET_MEDICALRECORD_FAILED',

  GET_MEDICALRECORD_BY_ID_REQUEST: 'GET_MEDICALRECORD_BY_ID_REQUEST',
  GET_MEDICALRECORD_BY_ID_SUCCESS: 'GET_MEDICALRECORD_BY_ID_SUCCESS',
  GET_MEDICALRECORD_BY_ID_FAILED: 'GET_MEDICALRECORD_BY_ID_FAILED',

  UPDATE_MEDICALRECORD_REQUEST: 'UPDATE_MEDICALRECORD_REQUEST',
  UPDATE_MEDICALRECORD_SUCCESS: 'UPDATE_MEDICALRECORD_SUCCESS',
  UPDATE_MEDICALRECORD_FAILED: 'UPDATE_MEDICALRECORD_FAILED',

  DELETE_MEDICALRECORD_REQUEST: 'DELETE_MEDICALRECORD_REQUEST',
  DELETE_MEDICALRECORD_SUCCESS: 'DELETE_MEDICALRECORD_SUCCESS',
  DELETE_MEDICALRECORD_FAILED: 'DELETE_MEDICALRECORD_FAILED',

  CREATE_MEDICALRECORD_REQUEST: 'CREATE_MEDICALRECORD_REQUEST',
  CREATE_MEDICALRECORD_SUCCESS: 'CREATE_MEDICALRECORD_SUCCESS',
  CREATE_MEDICALRECORD_FAILED: 'CREATE_MEDICALRECORD_FAILED',

  SET_MEDICALRECORD_VALUE: 'SET_MEDICALRECORD_VALUE',
  //relation
  GET_RELATION_REQUEST: 'GET_RELATION_REQUEST',
  GET_RELATION_FAILED: 'GET_RELATION_FAILED',
  GET_RELATION_SUCCESS: 'GET_RELATION_SUCCESS',

  GET_SINGLE_RELATION_REQUEST: 'GET_SINGLE_RELATION_REQUEST',
  GET_SINGLE_RELATION_FAILED: 'GET_SINGLE_RELATION_FAILED',
  GET_SINGLE_RELATION_SUCCESS: 'GET_SINGLE_RELATION_SUCCESS',

  // Promotion
  SET_ORDER_VALUE: 'SET_ORDER_VALUE',
  GET_ORDERS_REQUEST: 'GET_ORDERS_REQUEST',
  GET_ORDERS_SUCCESS: 'GET_ORDERS_SUCCESS',
  GET_ORDERS_FAILED: 'GET_ORDERS_FAILED',

  GET_ORDER_REQUEST: 'GET_ORDER_REQUEST',
  GET_ORDER_SUCCESS: 'GET_ORDER_SUCCESS',
  GET_ORDER_FAILED: 'GET_ORDER_FAILED',

  CREATE_ORDER_REQUEST: 'CREATE_ORDER_REQUEST',
  CREATE_ORDER_SUCCESS: 'CREATE_ORDER_SUCCESS',
  CREATE_ORDER_FAILED: 'CREATE_ORDER_FAILED',

  UPDATE_ORDER_REQUEST: 'UPDATE_ORDER_REQUEST',
  UPDATE_ORDER_SUCCESS: 'UPDATE_ORDER_SUCCESS',
  UPDATE_ORDER_FAILED: 'UPDATE_ORDER_FAILED',

  DELETE_ORDER_REQUEST: 'DELETE_ORDER_REQUEST',
  DELETE_ORDER_SUCCESS: 'DELETE_ORDER_SUCCESS',
  DELETE_ORDER_FAILED: 'DELETE_ORDER_FAILED',

  EXPORT_ORDER_REQUEST: 'EXPORT_ORDER_REQUEST',
  EXPORT_ORDER_SUCCESS: 'EXPORT_ORDER_SUCCESS',
  EXPORT_ORDER_FAILED: 'EXPORT_ORDER_FAILED',

  SEND_TO_ZALO_REQUEST: 'SEND_TO_ZALO_REQUEST',
  SEND_TO_ZALO_SUCCESS: 'SEND_TO_ZALO_SUCCESS',
  SEND_TO_ZALO_FAILED: 'SEND_TO_ZALO_FAILED',

  EXPORT_ORDER_NEED_UPDATE_REQUEST: 'EXPORT_ORDER_NEED_UPDATE_REQUEST',
  EXPORT_ORDER_NEED_UPDATE_SUCCESS: 'EXPORT_ORDER_NEED_UPDATE_SUCCESS',
  EXPORT_ORDER_NEED_UPDATE_FAILED: 'EXPORT_ORDER_NEED_UPDATE_FAILED',

  EXPORT_ORDER_UNAPPROVED_REQUEST: 'EXPORT_ORDER_UNAPPROVED_REQUEST',
  EXPORT_ORDER_UNAPPROVED_SUCCESS: 'EXPORT_ORDER_UNAPPROVED_SUCCESS',
  EXPORT_ORDER_UNAPPROVED_FAILED: 'EXPORT_ORDER_UNAPPROVED_FAILED',

  EXPORT_ORDER_SYNTHESIS_SAMPLE_TRANSFER_REQUEST:
    'EXPORT_ORDER_SYNTHESIS_SAMPLE_TRANSFER_REQUEST',
  EXPORT_ORDER_SYNTHESIS_SAMPLE_TRANSFER_SUCCESS:
    'EXPORT_ORDER_SYNTHESIS_SAMPLE_TRANSFER_SUCCESS',
  EXPORT_ORDER_SYNTHESIS_SAMPLE_TRANSFER_FAILED:
    'EXPORT_ORDER_SYNTHESIS_SAMPLE_TRANSFER_FAILED',

  EXPORT_ORDER_RECEIVE_RESULT_REQUEST: 'EXPORT_ORDER_RECEIVE_RESULT_REQUEST',
  EXPORT_ORDER_RECEIVE_RESULT_SUCCESS: 'EXPORT_ORDER_RECEIVE_RESULT_SUCCESS',
  EXPORT_ORDER_RECEIVE_RESULT_FAILED: 'EXPORT_ORDER_RECEIVE_RESULT_FAILED',

  EXPORT_ORDER_APPROVE_RESULT_REQUEST: 'EXPORT_ORDER_APPROVE_RESULT_REQUEST',
  EXPORT_ORDER_APPROVE_RESULT_SUCCESS: 'EXPORT_ORDER_APPROVE_RESULT_SUCCESS',
  EXPORT_ORDER_APPROVE_RESULT_FAILED: 'EXPORT_ORDER_APPROVE_RESULT_FAILED',

  EXPORT_ORDER_PAY_RESULT_REQUEST: 'EXPORT_ORDER_PAY_RESULT_REQUEST',
  EXPORT_ORDER_PAY_RESULT_SUCCESS: 'EXPORT_ORDER_PAY_RESULT_SUCCESS',
  EXPORT_ORDER_PAY_RESULT_FAILED: 'EXPORT_ORDER_PAY_RESULT_FAILED',

  EXPORT_AREA_REQUEST: 'EXPORT_AREA_REQUEST',
  EXPORT_AREA_SUCCESS: 'EXPORT_AREA_SUCCESS',
  EXPORT_AREA_FAILED: 'EXPORT_AREA_FAILED',

  EXPORT_SERVICE_GROUP_REQUEST: 'EXPORT_SERVICE_GROUP_REQUEST',
  EXPORT_SERVICE_GROUP_SUCCESS: 'EXPORT_SERVICE_GROUP_SUCCESS',
  EXPORT_SERVICE_GROUP_FAILED: 'EXPORT_SERVICE_GROUP_FAILED',

  EXPORT_SERVICE_REQUEST: 'EXPORT_SERVICE_REQUEST',
  EXPORT_SERVICE_SUCCESS: 'EXPORT_SERVICE_SUCCESS',
  EXPORT_SERVICE_FAILED: 'EXPORT_SERVICE_FAILED',

  EXPORT_PARTNER_REQUEST: 'EXPORT_PARTNER_REQUEST',
  EXPORT_PARTNER_SUCCESS: 'EXPORT_PARTNER_SUCCESS',
  EXPORT_PARTNER_FAILED: 'EXPORT_PARTNER_FAILED',

  EXPORT_BARCODE_REQUEST: 'EXPORT_BARCODE_REQUEST',
  EXPORT_BARCODE_SUCCESS: 'EXPORT_BARCODE_SUCCESS',
  EXPORT_BARCODE_FAILED: 'EXPORT_BARCODE_FAILED',

  EXPORT_ACCOUNT_REQUEST: 'EXPORT_ACCOUNT_REQUEST',
  EXPORT_ACCOUNT_SUCCESS: 'EXPORT_ACCOUNT_SUCCESS',
  EXPORT_ACCOUNT_FAILED: 'EXPORT_ACCOUNT_FAILED',

  EXPORT_ORGANIZATION_REQUEST: 'EXPORT_ORGANIZATION_REQUEST',
  EXPORT_ORGANIZATION_SUCCESS: 'EXPORT_ORGANIZATION_SUCCESS',
  EXPORT_ORGANIZATION_FAILED: 'EXPORT_ORGANIZATION_FAILED',

  EXPORT_SPECIFYVOTE_REQUEST: 'EXPORT_SPECIFYVOTE_REQUEST',
  EXPORT_SPECIFYVOTE_SUCCESS: 'EXPORT_SPECIFYVOTE_SUCCESS',
  EXPORT_SPECIFYVOTE_FAILED: 'EXPORT_SPECIFYVOTE_FAILED',

  EXPORT_PATIENT_REQUEST: 'EXPORT_PATIENT_REQUEST',
  EXPORT_PATIENT_SUCCESS: 'EXPORT_PATIENT_SUCCESS',
  EXPORT_PATIENT_FAILED: 'EXPORT_PATIENT_FAILED',

  GET_ORDER_BY_REQUEST: 'GET_ORDER_BY_REQUEST',
  GET_ORDER_BY_SUCCESS: 'GET_ORDER_BY_SUCCESS',
  GET_ORDER_BY_FAILED: 'GET_ORDER_BY_FAILED',

  UPLOAD_FILES_ORDER_REQUEST: 'UPLOAD_FILES_ORDER_REQUEST',
  UPLOAD_FILES_ORDER_SUCCESS: 'UPLOAD_FILES_ORDER_SUCCESS',
  UPLOAD_FILES_ORDER_FAILED: 'UPLOAD_FILES_ORDER_FAILED',

  UPDATE_LABCODES_REQUEST: 'UPDATE_LABCODES_REQUEST',
  UPDATE_LABCODES_SUCCESS: 'UPDATE_LABCODES_SUCCESS',
  UPDATE_LABCODES_FAILED: 'UPDATE_LABCODES_FAILED',

  UPDATE_ADDITIONAL_SAMPLE_LABCODES_REQUEST:
    'UPDATE_ADDITIONAL_SAMPLE_LABCODES_REQUEST',
  UPDATE_ADDITIONAL_SAMPLE_LABCODES_SUCCESS:
    'UPDATE_ADDITIONAL_SAMPLE_LABCODES_SUCCESS',
  UPDATE_ADDITIONAL_SAMPLE_LABCODES_FAILED:
    'UPDATE_ADDITIONAL_SAMPLE_LABCODES_FAILED',

  // bill
  SET_BILL_VALUE: 'SET_BILL_VALUE',

  GET_BILL_RELATION_REQUEST: 'GET_BILL_RELATION_REQUEST',
  GET_BILL_RELATION_SUCCESS: 'GET_BILL_RELATION_SUCCESS',
  GET_BILL_RELATION_FAILED: 'GET_BILL_RELATION_FAILED',

  GET_BILLS_REQUEST: 'GET_BILLS_REQUEST',
  GET_BILLS_SUCCESS: 'GET_BILLS_SUCCESS',
  GET_BILLS_FAILED: 'GET_BILLS_FAILED',

  GET_BILL_REQUEST: 'GET_BILL_REQUEST',
  GET_BILL_SUCCESS: 'GET_BILL_SUCCESS',
  GET_BILL_FAILED: 'GET_BILL_FAILED',

  CREATE_BILL_REQUEST: 'CREATE_BILL_REQUEST',
  CREATE_BILL_SUCCESS: 'CREATE_BILL_SUCCESS',
  CREATE_BILL_FAILED: 'CREATE_BILL_FAILED',

  UPDATE_BILL_REQUEST: 'UPDATE_BILL_REQUEST',
  UPDATE_BILL_SUCCESS: 'UPDATE_BILL_SUCCESS',
  UPDATE_BILL_FAILED: 'UPDATE_BILL_FAILED',

  DELETE_BILL_REQUEST: 'DELETE_BILL_REQUEST',
  DELETE_BILL_SUCCESS: 'DELETE_BILL_SUCCESS',
  DELETE_BILL_FAILED: 'DELETE_BILL_FAILED',

  GET_BILL_BY_REQUEST: 'GET_BILL_BY_REQUEST',
  GET_BILL_BY_SUCCESS: 'GET_BILL_BY_SUCCESS',
  GET_BILL_BY_FAILED: 'GET_BILL_BY_FAILED',

  // payment history
  SET_PAYMENT_HISTORY_VALUE: 'SET_PAYMENT_HISTORY_VALUE',
  GET_PAYMENT_HISTORYS_REQUEST: 'GET_PAYMENT_HISTORYS_REQUEST',
  GET_PAYMENT_HISTORYS_SUCCESS: 'GET_PAYMENT_HISTORYS_SUCCESS',
  GET_PAYMENT_HISTORYS_FAILED: 'GET_PAYMENT_HISTORYS_FAILED',

  GET_PAYMENT_HISTORY_REQUEST: 'GET_PAYMENT_HISTORY_REQUEST',
  GET_PAYMENT_HISTORY_SUCCESS: 'GET_PAYMENT_HISTORY_SUCCESS',
  GET_PAYMENT_HISTORY_FAILED: 'GET_PAYMENT_HISTORY_FAILED',

  CREATE_PAYMENT_HISTORY_REQUEST: 'CREATE_PAYMENT_HISTORY_REQUEST',
  CREATE_PAYMENT_HISTORY_SUCCESS: 'CREATE_PAYMENT_HISTORY_SUCCESS',
  CREATE_PAYMENT_HISTORY_FAILED: 'CREATE_PAYMENT_HISTORY_FAILED',

  UPDATE_PAYMENT_HISTORY_REQUEST: 'UPDATE_PAYMENT_HISTORY_REQUEST',
  UPDATE_PAYMENT_HISTORY_SUCCESS: 'UPDATE_PAYMENT_HISTORY_SUCCESS',
  UPDATE_PAYMENT_HISTORY_FAILED: 'UPDATE_PAYMENT_HISTORY_FAILED',

  DELETE_PAYMENT_HISTORY_REQUEST: 'DELETE_PAYMENT_HISTORY_REQUEST',
  DELETE_PAYMENT_HISTORY_SUCCESS: 'DELETE_PAYMENT_HISTORY_SUCCESS',
  DELETE_PAYMENT_HISTORY_FAILED: 'DELETE_PAYMENT_HISTORY_FAILED',

  GET_PAYMENT_HISTORY_BY_REQUEST: 'GET_PAYMENT_HISTORY_BY_REQUEST',
  GET_PAYMENT_HISTORY_BY_SUCCESS: 'GET_PAYMENT_HISTORY_BY_SUCCESS',
  GET_PAYMENT_HISTORY_BY_FAILED: 'GET_PAYMENT_HISTORY_BY_FAILED',

  // result
  SET_RESULT_VALUE: 'SET_RESULT_VALUE',

  GET_RESULT_RELATION_REQUEST: 'GET_RESULT_RELATION_REQUEST',
  GET_RESULT_RELATION_SUCCESS: 'GET_RESULT_RELATION_SUCCESS',
  GET_RESULT_RELATION_FAILED: 'GET_RESULT_RELATION_FAILED',

  GET_RESULTS_REQUEST: 'GET_RESULTS_REQUEST',
  GET_RESULTS_SUCCESS: 'GET_RESULTS_SUCCESS',
  GET_RESULTS_FAILED: 'GET_RESULTS_FAILED',

  GET_RESULT_REQUEST: 'GET_RESULT_REQUEST',
  GET_RESULT_SUCCESS: 'GET_RESULT_SUCCESS',
  GET_RESULT_FAILED: 'GET_RESULT_FAILED',

  CREATE_RESULT_REQUEST: 'CREATE_RESULT_REQUEST',
  CREATE_RESULT_SUCCESS: 'CREATE_RESULT_SUCCESS',
  CREATE_RESULT_FAILED: 'CREATE_RESULT_FAILED',

  UPDATE_RESULT_REQUEST: 'UPDATE_RESULT_REQUEST',
  UPDATE_RESULT_SUCCESS: 'UPDATE_RESULT_SUCCESS',
  UPDATE_RESULT_FAILED: 'UPDATE_RESULT_FAILED',

  DELETE_RESULT_REQUEST: 'DELETE_RESULT_REQUEST',
  DELETE_RESULT_SUCCESS: 'DELETE_RESULT_SUCCESS',
  DELETE_RESULT_FAILED: 'DELETE_RESULT_FAILED',

  GET_RESULT_BY_REQUEST: 'GET_RESULT_BY_REQUEST',
  GET_RESULT_BY_SUCCESS: 'GET_RESULT_BY_SUCCESS',
  GET_RESULT_BY_FAILED: 'GET_RESULT_BY_FAILED',

  // pdf
  CONVERT_FILE_PDF_REQUEST: 'CONVERT_FILE_PDF_REQUEST',
  CONVERT_FILE_PDF_SUCCESS: 'CONVERT_FILE_PDF_SUCCESS',
  CONVERT_FILE_PDF_FAILED: 'CONVERT_FILE_PDF_FAILED',

  // service detail
  SET_SERVICE_DETAIL_VALUE: 'SET_SERVICE_DETAIL_VALUE',
  GET_SERVICE_DETAILS_REQUEST: 'GET_SERVICE_DETAILS_REQUEST',
  GET_SERVICE_DETAILS_SUCCESS: 'GET_SERVICE_DETAILS_SUCCESS',
  GET_SERVICE_DETAILS_FAILED: 'GET_SERVICE_DETAILS_FAILED',

  GET_SERVICE_DETAIL_REQUEST: 'GET_SERVICE_DETAIL_REQUEST',
  GET_SERVICE_DETAIL_SUCCESS: 'GET_SERVICE_DETAIL_SUCCESS',
  GET_SERVICE_DETAIL_FAILED: 'GET_SERVICE_DETAIL_FAILED',

  CREATE_SERVICE_DETAIL_REQUEST: 'CREATE_SERVICE_DETAIL_REQUEST',
  CREATE_SERVICE_DETAIL_SUCCESS: 'CREATE_SERVICE_DETAIL_SUCCESS',
  CREATE_SERVICE_DETAIL_FAILED: 'CREATE_SERVICE_DETAIL_FAILED',

  UPDATE_SERVICE_DETAIL_REQUEST: 'UPDATE_SERVICE_DETAIL_REQUEST',
  UPDATE_SERVICE_DETAIL_SUCCESS: 'UPDATE_SERVICE_DETAIL_SUCCESS',
  UPDATE_SERVICE_DETAIL_FAILED: 'UPDATE_SERVICE_DETAIL_FAILED',

  DELETE_SERVICE_DETAIL_REQUEST: 'DELETE_SERVICE_DETAIL_REQUEST',
  DELETE_SERVICE_DETAIL_SUCCESS: 'DELETE_SERVICE_DETAIL_SUCCESS',
  DELETE_SERVICE_DETAIL_FAILED: 'DELETE_SERVICE_DETAIL_FAILED',

  GET_SERVICE_DETAIL_BY_REQUEST: 'GET_SERVICE_DETAIL_BY_REQUEST',
  GET_SERVICE_DETAIL_BY_SUCCESS: 'GET_SERVICE_DETAIL_BY_SUCCESS',
  GET_SERVICE_DETAIL_BY_FAILED: 'GET_SERVICE_DETAIL_BY_FAILED',

  // author
  SET_AUTHOR_VALUE: 'SET_AUTHOR_VALUE',
  GET_AUTHORS_REQUEST: 'GET_AUTHORS_REQUEST',
  GET_AUTHORS_SUCCESS: 'GET_AUTHORS_SUCCESS',
  GET_AUTHORS_FAILED: 'GET_AUTHORS_FAILED',

  GET_AUTHOR_REQUEST: 'GET_AUTHOR_REQUEST',
  GET_AUTHOR_SUCCESS: 'GET_AUTHOR_SUCCESS',
  GET_AUTHOR_FAILED: 'GET_AUTHOR_FAILED',

  CREATE_AUTHOR_REQUEST: 'CREATE_AUTHOR_REQUEST',
  CREATE_AUTHOR_SUCCESS: 'CREATE_AUTHOR_SUCCESS',
  CREATE_AUTHOR_FAILED: 'CREATE_AUTHOR_FAILED',

  UPDATE_AUTHOR_REQUEST: 'UPDATE_AUTHOR_REQUEST',
  UPDATE_AUTHOR_SUCCESS: 'UPDATE_AUTHOR_SUCCESS',
  UPDATE_AUTHOR_FAILED: 'UPDATE_AUTHOR_FAILED',

  DELETE_AUTHOR_REQUEST: 'DELETE_AUTHOR_REQUEST',
  DELETE_AUTHOR_SUCCESS: 'DELETE_AUTHOR_SUCCESS',
  DELETE_AUTHOR_FAILED: 'DELETE_AUTHOR_FAILED',

  GET_AUTHOR_BY_REQUEST: 'GET_AUTHOR_BY_REQUEST',
  GET_AUTHOR_BY_SUCCESS: 'GET_AUTHOR_BY_SUCCESS',
  GET_AUTHOR_BY_FAILED: 'GET_AUTHOR_BY_FAILED',

  // contentXns
  SET_CONTENT_XN_VALUE: 'SET_CONTENT_XN_VALUE',
  GET_CONTENT_XNS_REQUEST: 'GET_CONTENT_XNS_REQUEST',
  GET_CONTENT_XNS_SUCCESS: 'GET_CONTENT_XNS_SUCCESS',
  GET_CONTENT_XNS_FAILED: 'GET_CONTENT_XNS_FAILED',

  GET_CONTENT_XN_REQUEST: 'GET_CONTENT_XN_REQUEST',
  GET_CONTENT_XN_SUCCESS: 'GET_CONTENT_XN_SUCCESS',
  GET_CONTENT_XN_FAILED: 'GET_CONTENT_XN_FAILED',

  CREATE_CONTENT_XN_REQUEST: 'CREATE_CONTENT_XN_REQUEST',
  CREATE_CONTENT_XN_SUCCESS: 'CREATE_CONTENT_XN_SUCCESS',
  CREATE_CONTENT_XN_FAILED: 'CREATE_CONTENT_XN_FAILED',

  UPDATE_CONTENT_XN_REQUEST: 'UPDATE_CONTENT_XN_REQUEST',
  UPDATE_CONTENT_XN_SUCCESS: 'UPDATE_CONTENT_XN_SUCCESS',
  UPDATE_CONTENT_XN_FAILED: 'UPDATE_CONTENT_XN_FAILED',

  DELETE_CONTENT_XN_REQUEST: 'DELETE_CONTENT_XN_REQUEST',
  DELETE_CONTENT_XN_SUCCESS: 'DELETE_CONTENT_XN_SUCCESS',
  DELETE_CONTENT_XN_FAILED: 'DELETE_CONTENT_XN_FAILED',

  GET_CONTENT_XN_BY_REQUEST: 'GET_CONTENT_XN_BY_REQUEST',
  GET_CONTENT_XN_BY_SUCCESS: 'GET_CONTENT_XN_BY_SUCCESS',
  GET_CONTENT_XN_BY_FAILED: 'GET_CONTENT_XN_BY_FAILED',

  // satistic
  GET_STATISTIC_REQUEST: 'GET_STATISTIC_REQUEST',
  GET_STATISTIC_SUCCESS: 'GET_STATISTIC_SUCCESS',
  GET_STATISTIC_FAILED: 'GET_STATISTIC_FAILED',

  // role ui
  GET_ROLE_UI_REQUEST: 'GET_ROLE_UI_REQUEST',
  GET_ROLE_UI_SUCCESS: 'GET_ROLE_UI_SUCCESS',
  GET_ROLE_UI_FAILED: 'GET_ROLE_UI_FAILED',

  // print pkq
  PRINT_PKQ_REQUEST: 'PRINT_PKQ_REQUEST',
  PRINT_PKQ_SUCCESS: 'PRINT_PKQ_SUCCESS',
  PRINT_PKQ_FAILED: 'PRINT_PKQ_FAILED',

  // additional-sample
  SET_ADDITIONAL_SAMPLE_VALUE: 'SET_ADDITIONAL_SAMPLE_VALUE',
  GET_ADDITIONAL_SAMPLES_REQUEST: 'GET_ADDITIONAL_SAMPLES_REQUEST',
  GET_ADDITIONAL_SAMPLES_SUCCESS: 'GET_ADDITIONAL_SAMPLES_SUCCESS',
  GET_ADDITIONAL_SAMPLES_FAILED: 'GET_ADDITIONAL_SAMPLES_FAILED',

  GET_ADDITIONAL_SAMPLE_REQUEST: 'GET_ADDITIONAL_SAMPLE_REQUEST',
  GET_ADDITIONAL_SAMPLE_SUCCESS: 'GET_ADDITIONAL_SAMPLE_SUCCESS',
  GET_ADDITIONAL_SAMPLE_FAILED: 'GET_ADDITIONAL_SAMPLE_FAILED',

  CREATE_ADDITIONAL_SAMPLE_REQUEST: 'CREATE_ADDITIONAL_SAMPLE_REQUEST',
  CREATE_ADDITIONAL_SAMPLE_SUCCESS: 'CREATE_ADDITIONAL_SAMPLE_SUCCESS',
  CREATE_ADDITIONAL_SAMPLE_FAILED: 'CREATE_ADDITIONAL_SAMPLE_FAILED',

  UPDATE_ADDITIONAL_SAMPLE_REQUEST: 'UPDATE_ADDITIONAL_SAMPLE_REQUEST',
  UPDATE_ADDITIONAL_SAMPLE_SUCCESS: 'UPDATE_ADDITIONAL_SAMPLE_SUCCESS',
  UPDATE_ADDITIONAL_SAMPLE_FAILED: 'UPDATE_ADDITIONAL_SAMPLE_FAILED',

  DELETE_ADDITIONAL_SAMPLE_REQUEST: 'DELETE_ADDITIONAL_SAMPLE_REQUEST',
  DELETE_ADDITIONAL_SAMPLE_SUCCESS: 'DELETE_ADDITIONAL_SAMPLE_SUCCESS',
  DELETE_ADDITIONAL_SAMPLE_FAILED: 'DELETE_ADDITIONAL_SAMPLE_FAILED',

  EXPORT_ADDITIONAL_SAMPLE_REQUEST: 'EXPORT_ADDITIONAL_SAMPLE_REQUEST',
  EXPORT_ADDITIONAL_SAMPLE_SUCCESS: 'EXPORT_ADDITIONAL_SAMPLE_SUCCESS',
  EXPORT_ADDITIONAL_SAMPLE_FAILED: 'EXPORT_ADDITIONAL_SAMPLE_FAILED',

  EXPORT_ADDITIONAL_SAMPLE_ALL_REQUEST: 'EXPORT_ADDITIONAL_SAMPLE_ALL_REQUEST',
  EXPORT_ADDITIONAL_SAMPLE_ALL_SUCCESS: 'EXPORT_ADDITIONAL_SAMPLE_ALL_SUCCESS',
  EXPORT_ADDITIONAL_SAMPLE_ALL_FAILED: 'EXPORT_ADDITIONAL_SAMPLE_ALL_FAILED',

  GET_ADDITIONAL_SAMPLE_BY_REQUEST: 'GET_ADDITIONAL_SAMPLE_BY_REQUEST',
  GET_ADDITIONAL_SAMPLE_BY_SUCCESS: 'GET_ADDITIONAL_SAMPLE_BY_SUCCESS',
  GET_ADDITIONAL_SAMPLE_BY_FAILED: 'GET_ADDITIONAL_SAMPLE_BY_FAILED',

  PRINT_PKQ_ADDITIONAL_SAMPLE_REQUEST: 'PRINT_PKQ_ADDITIONAL_SAMPLE_REQUEST',
  PRINT_PKQ_ADDITIONAL_SAMPLE_SUCCESS: 'PRINT_PKQ_ADDITIONAL_SAMPLE_SUCCESS',
  PRINT_PKQ_ADDITIONAL_SAMPLE_FAILED: 'PRINT_PKQ_ADDITIONAL_SAMPLE_FAILED',

  UPLOAD_FILES_ADDITIONAL_SAMPLE_REQUEST:
    'UPLOAD_FILES_ADDITIONAL_SAMPLE_REQUEST',
  UPLOAD_FILES_ADDITIONAL_SAMPLE_SUCCESS:
    'UPLOAD_FILES_ADDITIONAL_SAMPLE_SUCCESS',
  UPLOAD_FILES_ADDITIONAL_SAMPLE_FAILED:
    'UPLOAD_FILES_ADDITIONAL_SAMPLE_FAILED',
};

export default constant;
