import React, { memo, useEffect, useState } from 'react';
import _ from 'lodash';
import { Card, CardBody, CardHeader, Col, Row } from 'reactstrap';
import { useDispatch, useSelector } from 'react-redux';
import queryString from 'query-string';

import 'assets/css/custom-pages/react-bs-table.css';
import InputCustom from 'views/pages/components/InputCustom';
import SelectCustom from 'views/pages/components/SelectCustom';
import {
  accountActions,
  barcodeActions,
  organizationActions,
  promotionDetailActions,
} from 'redux/actions';
import CONSTANT from 'constant';
import { useFormikContext } from 'formik';
import { useParams } from 'react-router';
import moment from 'moment';
import LabelCustom from 'views/pages/components/LabelCustom';
import SwitchCustom from 'views/pages/components/SwitchCustom';

const OrderInfo = ({ accountValue, setAccountValue, setSpecifyVoteValue }) => {
  const { id } = useParams();
  const dispatch = useDispatch();

  const {
    setFieldValue,
    values,
    errors,
    touched,
    handleBlur,
    handleChange,
    setValues,
  } = useFormikContext();

  const { order } = useSelector((state) => state.orderReducer);
  const {
    isBusinessStaff,
    currentAccountExtend,
    childrentByCurrentAccountExtend,
  } = useSelector((state) => state.accountReducer);
  const isDisabled =
    order?.status === CONSTANT.ORDER_STATUS[0].value ||
    order?.status === CONSTANT.ORDER_STATUS[3].value ||
    order?.status === CONSTANT.ORDER_STATUS[4].value ||
    order?.status === CONSTANT.ORDER_STATUS[5].value ||
    order?.status === CONSTANT.ORDER_STATUS[6].value ||
    order?.status === CONSTANT.ORDER_STATUS[7].value ||
    order?.status === CONSTANT.ORDER_STATUS[8].value ||
    order?.status === CONSTANT.ORDER_STATUS[9].value ||
    order?.status === CONSTANT.ORDER_STATUS[10].value ||
    order?.status === CONSTANT.ORDER_STATUS[11].value;
  // order.status !== CONSTANT.ORDER_STATUS[1].value &&
  // order.status !== CONSTANT.ORDER_STATUS[2].value &&
  // id !== '-1';

  const [organizationSearch, setOrganizationSearch] = useState('');
  const [barCodeSearch, setBarcodeSearch] = useState('');
  const [promotionDetailSearch, setPromotionDetailSearch] = useState('');
  const [codeStaff1Search, setCodeStaff1Search] = useState('');
  const [codeStaff2Search, setCodeStaff2Search] = useState('');
  const [codeStaff3Search, setCodeStaff3Search] = useState('');
  const [accountSearch, setAccountSearch] = useState('');

  const [statusValue, setStatusValue] = useState(null);
  const [organizationValue, setOrganizationValue] = useState(null);
  const [codeStaff1Value, setCodeStaff1Value] = useState(null);
  const [codeStaff2Value, setCodeStaff2Value] = useState(null);
  const [codeStaff3Value, setCodeStaff3Value] = useState(null);
  const [barcode1Value, setBarcode1Value] = useState(null); // PCD
  // const [barcode2Value, setBarcode2Value] = useState(null);
  // const [barcode3Value, setBarcode3Value] = useState(null);
  // const [barcode4Value, setBarcode4Value] = useState(null);
  // const [barcode5Value, setBarcode5Value] = useState(null);
  const [promotionDetailValue, setPromotionDetailValue] = useState(null);
  // const [statusValue, setStatusValue] = useState(null);

  const [accountResults, setAccountResults] = useState([]);
  const [organizationResults, setOrganizationResults] = useState([]);
  const [codeStaff1Results, setCodeStaff1Results] = useState([]);
  const [codeStaff2Results, setCodeStaff2Results] = useState([]);
  const [codeStaff3Results, setCodeStaff3Results] = useState([]);
  const [barcodeResults, setBarcodeResults] = useState([]);
  const [promotionDetailResults, setPromotionDetailResults] = useState([]);

  // get orgs
  useEffect(() => {
    if (!!order?.specifyVote?.idBS) {
      setAccountValue({
        value: order?.specifyVote?.id ?? -1,
        label: `${order?.specifyVote?.lastNameBS ?? ''} ${
          order?.specifyVote?.firstNameBS ?? ''
        }`,
      });
    }
  }, [order.idBS]);

  // get Nhân viên thu mẫu
  useEffect(() => {
    const delayDebounceFn = setTimeout(() => {
      handleGetCodeStaff1s();
    }, 500);

    return () => clearTimeout(delayDebounceFn);
  }, [codeStaff1Search]);

  const handleGetCodeStaff1s = () => {
    if (isBusinessStaff) {
      setCodeStaff1Results([
        {
          label: `${currentAccountExtend?.fullname ?? ''}`,
          value: currentAccountExtend?.id,
          ...currentAccountExtend,
        },
      ]);

      return;
    }
    const query = {
      'filters[$and][1][accountType][$eq]': 1,
      'filters[$and][2][isActive][$eq]': true,
    };
    if (codeStaff1Search !== '') {
      query['_q'] = codeStaff1Search;
    }

    dispatch(
      accountActions.getAccountsExtend(
        queryString.stringify(query),
        {
          success: (data) => {
            const results = _.get(data, 'results', []);
            setCodeStaff1Results(results);
          },
          failed: () => {},
        },
        false
      )
    );
  };

  // get Nhân viên thu mẫu
  useEffect(() => {
    const delayDebounceFn = setTimeout(() => {
      handleGetCodeStaff2s();
    }, 500);

    return () => clearTimeout(delayDebounceFn);
  }, [codeStaff2Search]);

  const handleGetCodeStaff2s = () => {
    const query = {
      'filters[$and][1][accountType][$eq]': 0,
      'filters[$and][2][isActive][$eq]': true,
    };
    if (codeStaff2Search !== '') {
      query['_q'] = codeStaff2Search;
    }

    dispatch(
      accountActions.getAccountsExtend(
        queryString.stringify(query),
        {
          success: (data) => {
            const results = _.get(data, 'results', []);
            setCodeStaff2Results(results);
          },
          failed: () => {},
        },
        false
      )
    );
  };

  // get Nhân viên thu tien
  useEffect(() => {
    const delayDebounceFn = setTimeout(() => {
      handleGetCodeStaff3s();
    }, 500);

    return () => clearTimeout(delayDebounceFn);
  }, [codeStaff3Search]);

  const handleGetCodeStaff3s = () => {
    const query = {
      'filters[$and][1][accountType][$eq]': 0,
      'filters[$and][2][isActive][$eq]': true,
    };
    if (codeStaff3Search !== '') {
      query['_q'] = codeStaff3Search;
    }

    dispatch(
      accountActions.getAccountsExtend(
        queryString.stringify(query),
        {
          success: (data) => {
            const results = _.get(data, 'results', []);
            setCodeStaff3Results(results);
          },
          failed: () => {},
        },
        false
      )
    );
  };

  // get barcodes
  useEffect(() => {
    const delayDebounceFn = setTimeout(() => {
      handleGetBarcodes();
    }, 500);

    return () => clearTimeout(delayDebounceFn);
  }, [barCodeSearch]);

  const handleGetBarcodes = () => {
    const query = {
      'filters[$and][0][status][$eq]': false,
    };
    if (barCodeSearch !== '') {
      query['filters[$and][1][barcode][$containsi]'] = barCodeSearch;
    }

    dispatch(
      barcodeActions.getBarcodes(
        queryString.stringify(query),
        {
          success: (data) => {
            const results = _.get(data, 'results', []);
            setBarcodeResults(results);
          },
          failed: () => {},
        },
        false
      )
    );
  };

  // get promotionDetails
  useEffect(() => {
    const delayDebounceFn = setTimeout(() => {
      handleGetPromotionDetails();
    }, 500);

    return () => clearTimeout(delayDebounceFn);
  }, [promotionDetailSearch]);

  const handleGetPromotionDetails = () => {
    const query = {
      'filters[$and][1][startDate][$lte]': moment().toISOString(),
      'filters[$and][2][endDate][$gte]': moment().toISOString(),
      'filters[$and][3][isOut][$eq]': false,
    };

    if (promotionDetailSearch !== '') {
      query['filters[$and][4][code][$containsi]'] = promotionDetailSearch;
    }

    dispatch(
      promotionDetailActions.getPromotionDetails(
        queryString.stringify(query),
        {
          success: (data) => {
            const results = _.get(data, 'results', []);
            setPromotionDetailResults(results);
          },
          failed: () => {},
        },
        false
      )
    );
  };

  // get promotion detail by organization
  const handleGetPromotionDetailsByOrganization = (id) => {
    const query = {
      'filters[$and][1][organization][id][$eq]': id,
      'filters[$and][2][isOut][$eq]': false,
      'filters[$and][3][startDate][$lte]': moment().toISOString(),
      'filters[$and][4][endDate][$gte]': moment().toISOString(),
    };

    dispatch(
      promotionDetailActions.getPromotionDetails(
        queryString.stringify(query),
        {
          success: (data) => {
            const results = _.get(data, 'results', []);
            // setPromotionDetailResults(results);

            const totalMoney = 100000000000; // temp
            const promotions = results.map((item) =>
              handlePromotions(item, totalMoney)
            );
            const promotion = _.maxBy(promotions, 'reduce'); // Lấy giá giảm nhiều nhất

            setFieldValue('promotionDetail', promotion?.id ?? null);
            setFieldValue('codeCTKM', promotion?.code ?? '');

            setPromotionDetailValue(
              !!promotion && promotion?.status
                ? {
                    label: promotion?.code ?? '',
                    value: promotion?.id ?? -1,
                  }
                : null
            );
          },
          failed: () => {},
        },
        false
      )
    );
  };

  // Xử lý các chi tiết khuyến mại được áp dụng
  function handlePromotions(item, totalMoney) {
    let reduce = 0;

    if (
      !item.promotion ||
      !item?.promotion?.valuePromotion ||
      totalMoney === 0 ||
      !item.status ||
      !item?.isOut
    ) {
      return { ...item, reduce };
    }

    // check hết hạn khuyên mại, chi tiết khuyến mại đã bị xóa hay chưa, trạng thái hoạt động
    const now = new Date().getTime();
    const startDate = !!item?.startDate
      ? new Date(item.startDate).getTime()
      : 0;
    const endDate = !!item?.endDate ? new Date(item.endDate).getTime() : 0;

    if (
      now < startDate ||
      now > endDate ||
      item?.promotion?.deleted ||
      !item?.promotion?.status
    ) {
      return { ...item, reduce };
    }

    // tính số tiền được giảm
    if (
      item.promotion.form === 0 &&
      Number(item.promotion.valuePromotion) <= 100
    ) {
      // Giảm theo %
      reduce = (totalMoney * Number(item.promotion.valuePromotion)) / 100;
    } else if (item.promotion.form === 1) {
      //Giảm trực tiếp tiền
      reduce = Number(item.promotion.valuePromotion);
    }
    return {
      ...item,
      reduce,
    };
  }

  useEffect(() => {
    if (_.isEmpty(order.codeStaff1)) return setCodeStaff1Value(null);
    setCodeStaff1Value({
      label: `${order?.codeStaff1?.fullname ?? ''} (${
        order?.codeStaff1?.code
      })`,
      value: order.codeStaff1.id,
    });
  }, [order?.codeStaff1]);
  useEffect(() => {
    if (_.isEmpty(order.codeStaff2)) return setCodeStaff2Value(null);
    setCodeStaff2Value({
      label: `${order?.codeStaff2?.fullname ?? ''} (${
        order?.codeStaff2?.code
      })`,
      value: order?.codeStaff2?.id,
    });
  }, [order?.codeStaff2]);
  useEffect(() => {
    if (_.isEmpty(order.codeStaff3)) return setCodeStaff3Value(null);
    setCodeStaff3Value({
      label: `${order?.codeStaff3?.fullname ?? ''} (${
        order?.codeStaff3?.code
      })`,
      value: order?.codeStaff3.id,
    });
  }, [order?.codeStaff3]);

  useEffect(() => {
    if (_.isEmpty(order.promotionDetail)) return setPromotionDetailValue(null);
    setPromotionDetailValue({
      label: order?.promotionDetail?.code ?? '',
      value: order?.promotionDetail.id,
    });
  }, [order?.promotionDetail]);

  useEffect(() => {
    if (_.isEmpty(order.organization)) return setOrganizationValue(null);
    setOrganizationValue({
      label: order?.organization.name,
      value: order?.organization.id,
    });
    if (!order?.isUpdated) {
      handleGetPromotionDetailsByOrganization(order?.organization.id);
    }
  }, [order?.organization]);

  useEffect(() => {
    if (_.isEmpty(order.barcode1)) return setBarcode1Value(null);
    setBarcode1Value({ value: -1, label: order.barcode1 });
  }, [order?.barcode1]);

  useEffect(() => {
    const statsV = CONSTANT.ORDER_STATUS.find(
      (item) => item.value === order?.status
    );
    setStatusValue(statsV ?? null);
  }, [order?.status]);

  useEffect(() => {
    const delayDebounceFn = setTimeout(() => {
      handleGetAccountBS();
    }, 500);

    return () => clearTimeout(delayDebounceFn);
  }, [accountSearch, childrentByCurrentAccountExtend]);

  useEffect(() => {
    if (_.get(values, 'specifyVote.idBS', '') !== '') {
      getRelationOrganizations(values?.specifyVote?.idBS);
    }
  }, [values?.specifyVote?.idBS]);

  const handleGetAccountBS = () => {
    const query = {
      'filters[$and][1][accountType][$eq]': CONSTANT.ACCOUNT_TYPE[5].value,
      'filters[$and][2][isActive][$eq]': true,
    };
    if (accountSearch !== '') {
      query[`filters[$and][3][fullname][$containsi]`] = accountSearch;
    }

    if (isBusinessStaff) {
      query['filters[$and][4][$or][4][staffInCharge][id][$eq]'] =
        currentAccountExtend.id;

      if (!_.isEmpty(childrentByCurrentAccountExtend)) {
        childrentByCurrentAccountExtend.forEach((c, i) => {
          query[`filters[$and][4][$or][${5 + i}][staffInCharge][id][$eq]`] =
            c.id;
        });
      }
    }

    dispatch(
      accountActions.getAccountsExtend(
        queryString.stringify(query),
        {
          success: (data) => {
            const results = _.get(data, 'results', []);
            setAccountResults(results);
          },
          failed: () => {},
        },
        false
      )
    );
  };

  const getRelationOrganizations = (id) => {
    dispatch(
      organizationActions.getOrganizationsByAccountExtend(id, {
        success: (data) => {
          setOrganizationResults(data);
        },
        failed: () => {},
      })
    );
  };

  const statsV = CONSTANT.ORDER_STATUS.find(
    (item) => item.value === order?.status
  );
  let statusOptions = [CONSTANT.ORDER_STATUS[0]];

  if (statsV?.value !== CONSTANT.ORDER_STATUS[0].value) {
    if (statsV?.value === CONSTANT.ORDER_STATUS[7].value) {
      statusOptions = [
        CONSTANT.ORDER_STATUS[0],
        CONSTANT.ORDER_STATUS[1],
        statsV,
      ];
    } else {
      statusOptions = [CONSTANT.ORDER_STATUS[0], statsV];
    }
  }

  if (
    order.status === CONSTANT.ORDER_STATUS[1].value ||
    order.status === CONSTANT.ORDER_STATUS[2].value
  ) {
    statusOptions = [...statusOptions, CONSTANT.ORDER_STATUS[3]];
  }

  // if (order.status === CONSTANT.ORDER_STATUS[3].value) {
  //   statusOptions = [...statusOptions, CONSTANT.ORDER_STATUS[4]];
  // }

  return (
    <Card>
      <CardHeader className="pb-2 d-flex justify-content-between rounded border-bottom">
        <h3>Thông tin cơ bản đơn hàng</h3>
      </CardHeader>
      <CardBody>
        {id !== '-1' && (
          <Row className="justify-content-center">
            <Col xs={12} md={6} lg={5}>
              <InputCustom
                name="code"
                label="Mã đơn hàng"
                type="text"
                placeholder="Nhập"
                value={values.code}
                isRequired={true}
                disabled={true}
                handleChange={handleChange}
                handleBlur={handleBlur}
                error={errors?.code}
                touched={touched?.code}
                row
                labelWidth={160}
              />
            </Col>
            <Col xs={12} md={6} lg={5}></Col>
          </Row>
        )}
        <Row className="justify-content-center">
          <Col xs={12} md={6} lg={5}>
            <InputCustom
              name="name"
              label="Tên đơn hàng"
              type="text"
              placeholder="Nhập"
              value={values.name}
              isRequired={true}
              handleChange={handleChange}
              handleBlur={handleBlur}
              error={errors?.name}
              touched={touched?.name}
              disabled={isDisabled}
              row
              labelWidth={160}
            />
          </Col>
          <Col xs={12} md={6} lg={5}>
            <SelectCustom
              name="specifyVote.firstNameBS"
              label="Bác sĩ chỉ định"
              placeholder="Lựa chọn"
              value={accountValue}
              isRequired={true}
              isClearable={false}
              handleChange={(e) => {
                setSpecifyVoteValue(null);
                setOrganizationValue(null);
                setAccountValue(e);

                setValues(
                  {
                    ...values,
                    specifyVote: {
                      ...values.specifyVote,
                      idBS: e?.value ?? -1,
                      firstNameBS: e?.firstname ?? '',
                      lastNameBS: e?.lastname ?? '',
                      codePCD: '',
                      addressBN: '',
                      anamnesis: '',
                      anamnesisBA: '',
                      biopsyDay: new Date(),
                      biopsyPerson: '',
                      cellNucleus: false,
                      cellSolution: '',
                      codeBN: '',
                      codePKQ: '',
                      codeTest: '',
                      dateOfBirthBN: '',
                      diagnose: '',
                      diseaseRecurrence: '',
                      drugResistance: '',
                      emailBN: '',
                      embryoEvaluation: '',
                      embryoState: '',
                      familyHistory: '',
                      genderBN: -1,
                      height: 0,
                      height1: 0,
                      imageAnalysation: null,
                      jobBN: '',
                      lifeStyle: '',
                      nameBN: '',
                      nameContact: '',
                      nameTest: '',
                      nappySkin: 0,
                      negativeControl: '',
                      noPhoi: null,
                      numOfEmployment: 0,
                      numOfEmbryos1: 0,
                      numOfEmbryos2: 0,
                      numOfEmbryos3: 0,
                      numOfEmbryos4: 0,
                      pathological1: '',
                      pathological2: '',
                      patient: null,
                      phoneBN: '',
                      phoneContact: '',
                      result1: '',
                      result2: '',
                      result3: '',
                      result4: '',
                      resultStatus: false,
                      specifyVoteStatus: 0,
                      specimens: [],
                      symptom: '',
                      test: '',
                      testGR: 0,
                      time: 0,
                      timeTreatment: 0,
                      treatment: '',
                      typePregnancy: 0,
                      weekPregnancy: 0,
                      weight: 0,
                      weight1: 0,
                      codeCTKM: '',
                      unitPrice: 0,
                      id: -1,
                      content: '',
                    },
                  },
                  true
                );

                if (!!e && e?.staffInCharge?.isActive) {
                  setFieldValue('codeStaff1', e?.staffInCharge?.id ?? null);
                  setCodeStaff1Value({
                    label: `${e?.staffInCharge?.fullname ?? ''}(${
                      e?.staffInCharge?.code ?? ''
                    })`,
                    value: e?.staffInCharge?.id,
                  });
                } else {
                  setFieldValue('codeStaff1', null);
                  setCodeStaff1Value(null);
                }
              }}
              handleBlur={handleBlur}
              onInputChange={(value) => {
                setAccountSearch(value);
              }}
              error={errors?.specifyVote?.firstNameBS}
              touched={touched?.specifyVote?.firstNameBS}
              options={accountResults.map((item) => ({
                value: item.id,
                label: `${item?.fullname ?? ''} (${item?.code})`,
                ...item,
              }))}
              isDisabled={isDisabled}
              row
              labelWidth={160}
            />
          </Col>
        </Row>
        <Row className="justify-content-center">
          <Col xs={12} md={12} lg={10}>
            <SelectCustom
              name="organization"
              label="P.khám/Bệnh viện"
              placeholder="Lựa chọn"
              value={organizationValue}
              // isRequired={true}
              isClearable={false}
              handleChange={(e) => {
                setFieldValue('organization', e?.value ?? null);
                setFieldValue('method', e?.method ?? 1);
                setFieldValue('areaName', e?.areaName ?? '');
                setFieldValue('specifyVote.nameTC', e?.label ?? '');
                setFieldValue('specifyVote.whereTM', e?.address ?? '');

                setOrganizationValue(e);
                if (!!e) {
                  handleGetPromotionDetailsByOrganization(e.value);
                } else {
                  setFieldValue('codeCTKM', '');
                }
              }}
              handleBlur={handleBlur}
              onInputChange={(value) => {
                setOrganizationSearch(value);
              }}
              error={errors.organization}
              touched={touched.organization}
              options={organizationResults.map((item) => ({
                value: item?.id,
                label: `(${item?.code ?? ''}) ${item?.name ?? ''}`,
                areaName: `${item?.area?.name}`,
                mainResponsible: item?.mainResponsible ?? null,
                name: item?.name ?? '',
                address: item?.address ?? '',
                method: item?.method ?? 1,
              }))}
              isDisabled={
                isDisabled ||
                values?.specifyVote?.idBS === '' ||
                values?.specifyVote?.idBS === -1
              }
              row
              labelWidth={160}
            />
          </Col>
        </Row>

        <Row className="justify-content-center">
          <Col xs={12} md={6} lg={5}>
            <SelectCustom
              name="codeStaff3"
              label="Người thu tiền"
              placeholder="Lựa chọn"
              value={codeStaff3Value}
              isRequired={false}
              isClearable={true}
              handleChange={(e) => {
                setFieldValue('codeStaff3', e?.value ?? null);
                setCodeStaff3Value(e);
              }}
              handleBlur={handleBlur}
              onInputChange={(value) => {
                setCodeStaff3Search(value);
              }}
              error={errors.codeStaff3}
              touched={touched.codeStaff3}
              options={codeStaff3Results.map((item) => ({
                value: item?.id,
                label: `${item?.fullname ?? ''} (${item?.code})`,
              }))}
              isDisabled={isDisabled}
              row
              labelWidth={160}
            />
          </Col>
          <Col xs={12} md={6} lg={5}>
            <InputCustom
              name="amountMoney"
              label="Số tiền đã thu"
              type="number"
              placeholder="Nhập vào số tiền"
              value={values.amountMoney}
              isRequired={false}
              handleChange={handleChange}
              handleBlur={handleBlur}
              error={errors?.amountMoney}
              touched={touched?.amountMoney}
              disabled={isDisabled}
              row
              labelWidth={160}
            />
          </Col>
        </Row>
        <Row className="justify-content-center">
          <Col xs={12} md={6} lg={5}>
            <SelectCustom
              name="codeStaff1"
              label="Nhân viên phụ trách"
              placeholder="Lựa chọn"
              value={codeStaff1Value}
              isRequired={true}
              isClearable={true}
              handleChange={(e) => {
                setCodeStaff1Value(e);
                setFieldValue('codeStaff1', e?.value ?? null);
              }}
              handleBlur={handleBlur}
              onInputChange={(value) => {
                setCodeStaff1Search(value);
              }}
              error={errors.codeStaff1}
              touched={touched.codeStaff1}
              options={codeStaff1Results.map((item) => ({
                label: `${item?.fullname ?? ''} (${item?.code})`,
                value: item?.id,
              }))}
              isDisabled={isDisabled}
              row
              labelWidth={160}
            />
          </Col>
          <Col xs={12} md={6} lg={5}>
            <SelectCustom
              name="codeStaff2"
              label="Nhân viên thu mẫu"
              placeholder="Lựa chọn"
              value={codeStaff2Value}
              isRequired={true}
              isClearable={false}
              handleChange={(e) => {
                setCodeStaff2Value(e);
                setFieldValue('codeStaff2', e?.value ?? null);
              }}
              handleBlur={handleBlur}
              onInputChange={(value) => {
                setCodeStaff2Search(value);
              }}
              error={errors.codeStaff2}
              touched={touched.codeStaff2}
              options={codeStaff2Results.map((item) => ({
                value: item?.id,
                label: `${item?.fullname ?? ''} (${item?.code})`,
              }))}
              isDisabled={isDisabled}
              row
              labelWidth={160}
            />
          </Col>
        </Row>
        <Row className="justify-content-center">
          <Col xs={12} md={6} lg={5}>
            <SelectCustom
              name="barcode1"
              label="Mã Barcode"
              placeholder="Lựa chọn"
              value={barcode1Value}
              isRequired={true}
              isClearable={false}
              handleChange={(e) => {
                setFieldValue('barcode1', e?.label ?? '');
                setBarcode1Value(e);
              }}
              handleBlur={handleBlur}
              onInputChange={(value) => {
                setBarcodeSearch(value);
              }}
              error={errors.barcode1}
              touched={touched.barcode1}
              options={barcodeResults.map((item) => ({
                label: item?.barcode ?? '',
                value: item?.id,
              }))}
              isDisabled={isDisabled}
              row
              labelWidth={160}
            />
          </Col>
          <Col xs={12} md={6} lg={5}>
            <SelectCustom
              name="promotionDetail"
              label="Mã khuyến mại"
              placeholder="Lựa chọn"
              value={promotionDetailValue}
              isRequired={false}
              isClearable={true}
              handleChange={(e) => {
                setValues({
                  ...values,
                  promotionDetail: e?.value ?? null,
                  codeCTKM: e?.code ?? '',
                  // promotionDetailStartDate: e?.startDate ?? null,
                  // promotionDetailEndDate: e?.endDate ?? null,
                  // codePromotion: e?.promotion?.code ?? '',
                  // form: e?.promotion?.form ?? 0,
                  // valuePromotion: e?.promotion?.valuePromotion ?? 0,
                  // promotionStatus: e?.promotion?.status ?? false,
                  // promotionDeleted: e?.promotion?.deleted ?? true,
                });
                setPromotionDetailValue(e);
              }}
              handleBlur={handleBlur}
              onInputChange={(value) => {
                setPromotionDetailSearch(value);
              }}
              error={errors.promotionDetail}
              touched={touched.promotionDetail}
              options={promotionDetailResults.map((item) => ({
                value: item?.id,
                label: item.code,
                ...item,
              }))}
              isDisabled={isDisabled}
              row
              labelWidth={160}
            />
          </Col>
        </Row>
        {/* <Row className="justify-content-center">
          <Col xs={12} md={6} lg={5}>
            <SelectCustom
              name="barcode2"
              label="Mã Barcode mẫu 1"
              placeholder="Lựa chọn"
              value={barcode2Value}
              isRequired={false}
              isClearable={false}
              handleChange={(e) => {
                setFieldValue('barcode2', e?.label ?? '');
                setBarcode2Value(e);
              }}
              handleBlur={handleBlur}
              onInputChange={(value) => {
                setBarcodeSearch(value);
              }}
              error={errors.barcode2}
              touched={touched.barcode2}
              options={barcodeResults.map((item) => ({
                value: item?.id,
                label: item?.barcode ?? '',
              }))}
              isDisabled={isDisabled}
              row
              labelWidth={160}
            />
          </Col>
          <Col xs={12} md={6} lg={5}>
            <SelectCustom
              name="barcode3"
              label="Mã Barcode mẫu 2"
              placeholder="Lựa chọn"
              value={barcode3Value}
              isRequired={false}
              isClearable={true}
              handleChange={(e) => {
                setFieldValue('barcode3', e?.label ?? '');
                setBarcode3Value(e);
              }}
              handleBlur={handleBlur}
              onInputChange={(value) => {
                setBarcodeSearch(value);
              }}
              error={errors.barcode3}
              touched={touched.barcode3}
              options={barcodeResults.map((item) => ({
                value: item?.id,
                label: item?.barcode ?? '',
              }))}
              isDisabled={isDisabled}
              row
              labelWidth={160}
            />
          </Col>
        </Row>
        <Row className="justify-content-center">
          <Col xs={12} md={6} lg={5}>
            <SelectCustom
              name="barcode4"
              label="Mã Barcode mẫu 3"
              placeholder="Lựa chọn"
              value={barcode4Value}
              isRequired={false}
              isClearable={true}
              handleChange={(e) => {
                setFieldValue('barcode4', e?.label ?? '');
                setBarcode4Value(e);
              }}
              handleBlur={handleBlur}
              onInputChange={(value) => {
                setBarcodeSearch(value);
              }}
              error={errors.barcode4}
              touched={touched.barcode4}
              options={barcodeResults.map((item) => ({
                value: item?.id,
                label: item?.barcode ?? '',
              }))}
              isDisabled={isDisabled}
              row
              labelWidth={160}
            />
          </Col>
          <Col xs={12} md={6} lg={5}>
            <SelectCustom
              name="barcode5"
              label="Mã Barcode mẫu 4"
              placeholder="Lựa chọn"
              value={barcode5Value}
              isRequired={false}
              isClearable={true}
              handleChange={(e) => {
                setFieldValue('barcode5', e?.label ?? '');
                setBarcode5Value(e);
              }}
              handleBlur={handleBlur}
              onInputChange={(value) => {
                setBarcodeSearch(value);
              }}
              error={errors.barcode5}
              touched={touched.barcode5}
              options={barcodeResults.map((item) => ({
                value: item?.id,
                label: item?.barcode ?? '',
              }))}
              isDisabled={isDisabled}
              row
              labelWidth={160}
            />
          </Col>
        </Row> */}
        {id !== '-1' && (
          <Row className="justify-content-center">
            <Col xs={12} md={6} lg={5}>
              <SelectCustom
                name="status"
                label="Trạng thái"
                placeholder="Lựa chọn"
                value={statusValue}
                isRequired={false}
                isClearable={false}
                handleChange={(e) => {
                  setFieldValue('status', e?.value ?? '');
                  setStatusValue(e);
                }}
                handleBlur={handleBlur}
                error={errors.status}
                touched={touched.status}
                options={statusOptions}
                isDisabled={false}
                row
                labelWidth={160}
              />
              {(order.status === CONSTANT.ORDER_STATUS[1].value ||
                order.status === CONSTANT.ORDER_STATUS[2].value) && (
                <p
                  style={{ fontSize: 12, fontStyle: 'italic' }}
                  className="mb-0 text-right"
                >
                  Vui lòng chuyển trạng thái đã cập nhật khi cập nhật đầy đủ
                  thông tin
                </p>
              )}
            </Col>
            <Col xs={12} md={6} lg={5}>
              <InputCustom
                name="note"
                label={'Ghi chú'}
                type="textarea"
                placeholder={'Nhập ghi chú'}
                value={values.note}
                isRequired={false}
                handleChange={handleChange}
                handleBlur={handleBlur}
                error={errors?.note}
                touched={touched?.note}
                disabled={
                  isDisabled &&
                  order.status !== CONSTANT.ORDER_STATUS[0].value &&
                  statusValue?.value !== CONSTANT.ORDER_STATUS[0].value
                }
                row
                labelWidth={160}
              />
            </Col>
          </Row>
        )}
        <Row className="justify-content-center">
          <Col xs={12} md={12} lg={10} className="d-flex align-items-center">
            <LabelCustom
              label={`Gửi kết quả cho bệnh nhân: `}
              style={{
                whiteSpace: 'nowrap',
                margin: '0px 8px 0px 0px',

                lineHeight: '16.5px',
              }}
              styleLabel={{}}
            />
            <SwitchCustom
              className="custom-toggle custom-toggle-primary mr-1"
              value={values.sendResultForPatient}
              labelOn="Bật"
              labelOff={'Tắt'}
              checked={values.sendResultForPatient}
              onChange={(e) => {
                setFieldValue('sendResultForPatient', e.target.checked);
              }}
            />
          </Col>
        </Row>
      </CardBody>
    </Card>
  );
};

export default memo(OrderInfo);
