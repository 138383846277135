import typeActions from "redux/typeActions";
const setValueProvince = ({ name, value }) => {
  return {
    type: typeActions.SET_PROVINCE_VALUE,
    name,
    value,
  };
};
const getProvinces = (query, callback,isUpdateReducer) => {
  return {
    type: typeActions.GET_PROVINCE_REQUEST,
    query,
    callback,
    isUpdateReducer
  };
};
const createProvince = (params, callback) => {
  return {
    type: typeActions.CREATE_PROVINCE_REQUEST,
    params,
    callback
  };
};
const updateProvince = (params,body, callback) => {
  return {
    type: typeActions.UPDATE_PROVINCE_REQUEST,
    params,
    body,
    callback,
  };
};
const deleteProvince = (params, callback) => {
  return {
    type: typeActions.DELETE_PROVINCE_REQUEST,
    params,
    callback,
  };
};
export default {
  setValueProvince,
  getProvinces,
  deleteProvince,
  updateProvince,
  createProvince
};
