import { returnValue } from 'common';
import typeActions from 'redux/typeActions';
const initialState = {
  specifyvotes: {},
  specifyvote: {},
  currentSpecifyVotes: {},
  pagination: {},
  // form show/delete
  isModalVisible: false,
  modalInfo: {},
  page: 1,
  limit: 10,
  query: {
    populate: '*',
  },
  // loading
  isPrintSpecifyVote: false,
  idPCD: -1,
  isCreatingSpecifyvote: false,
};
const specifyvoteReducer = (state = initialState, actions) => {
  switch (actions.type) {
    case typeActions.SET_SPECIFYVOTE_VALUE:
      const nameSpecifyvote = actions.name;
      const valueSpecifyvote = actions.value;
      if (typeof nameSpecifyvote !== 'string') return { ...state };
      const objSpecifyvote = returnValue(
        state,
        nameSpecifyvote,
        valueSpecifyvote
      );
      return {
        ...state,
        ...objSpecifyvote,
      };
    case typeActions.GET_SPECIFYVOTE_REQUEST:
      return {
        ...state,
        ...actions?.data,
      };
    case typeActions.GET_SPECIFYVOTE_SUCCESS:
      return {
        ...state,
        specifyvotes: actions.data.results,
        pagination: actions.data.pagination,
        page: actions.data.pagination.page,
        limit: actions.data.pagination.pageSize,
      };
    case typeActions.GET_SPECIFYVOTE_FAILED:
      return {
        ...state,
      };
    case typeActions.GET_SPECIFYVOTE_BY_ID_SUCCESS:
      return {
        ...state,
        currentSpecifyVotes: actions.data,
      };
    // create

    case typeActions.CREATE_SPECIFYVOTE_REQUEST:
      return {
        ...state,
        isCreatingSpecifyvote: true,
        errors: {
          ...state.errors,
        },
      };
    case typeActions.CREATE_SPECIFYVOTE_SUCCESS:
      return {
        ...state,
        isCreatingSpecifyvote: false,
        errors: {
          ...state.errors,
          createAccount: '',
        },
      };
    case typeActions.CREATE_SPECIFYVOTE_FAILED:
      return {
        ...state,
        isCreatingSpecifyvote: false,
        errors: {
          ...state.errors,
          createAccount: actions.error,
        },
      };

    //delete
    case typeActions.DELETE_SPECIFYVOTE_REQUEST:
      return {
        ...state,
      };
    case typeActions.DELETE_SPECIFYVOTE_SUCCESS:
      return {
        ...state,
      };
    case typeActions.DELETE_SPECIFYVOTE_FAILED:
      return {
        ...state,
      };

    case typeActions.PRINT_SPECIFYVOTE_REQUEST:
      return {
        ...state,
        isPrintSpecifyVote: true,
      };
    case typeActions.PRINT_SPECIFYVOTE_SUCCESS:
      return {
        ...state,
        isPrintSpecifyVote: false,
      };
    case typeActions.PRINT_SPECIFYVOTE_FAILED:
      return {
        ...state,
        isPrintSpecifyVote: false,
      };
    default:
      return {
        ...state,
      };
  }
};

export default specifyvoteReducer;
