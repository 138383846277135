import typeActions from '../typeActions';

const setValue = ({ name, value }) => {
  return {
    type: typeActions.SET_ROLE_VALUE,
    name,
    value,
  };
};

export const getRoles = (query, callback) => {
  return {
    type: typeActions.GET_ROLES_REQUEST,
    query,
    callback,
  };
};
export const getRoleById = (params, callback) => {
  return {
    type: typeActions.GET_ROLE_BY_ID_REQUEST,
    params,
    callback,
  };
};
export const getPermissions = (query, callback) => {
  return {
    type: typeActions.GET_PERMISSIONS_REQUEST,
    query,
    callback,
  };
};

export const getCurrentPermissions = (query, callback) => {
  return {
    type: typeActions.GET_CURRENT_PERMISSIONS_REQUEST,
    query,
    callback,
  };
};

export const createRole = (body, callback) => {
  return {
    type: typeActions.CREATE_ROLE_REQUEST,
    body,
    callback,
  };
};
export const updateRole = (param, body, callback) => {
  return {
    type: typeActions.UPDATE_ROLE_REQUEST,
    body,
    param,
    callback,
  };
};
export const deleteRole = (param, callback) => {
  return {
    type: typeActions.DELETE_ROLE_REQUEST,
    param,
    callback,
  };
};

export const updatePermission = (param, body, callback) => {
  return {
    type: typeActions.CREATE_PERMISSION_REQUEST,
    param,
    body,
    callback,
  };
};

export const getRolePermissions = (param, callback) => {
  return {
    type: typeActions.GET_ROLE_PERMISSIONS_REQUEST,
    param,
    callback,
  };
};

const getRolesExtend = (query, callback) => {
  return {
    type: typeActions.GET_ROLES_EXTEND_REQUEST,
    query,
    callback,
  };
};
const getRoleExtend = (param, query, callback) => {
  return {
    type: typeActions.GET_ROLE_EXTEND_REQUEST,
    param,
    query,
    callback,
  };
};

const createRoleExtend = (body, callback) => {
  return {
    type: typeActions.CREATE_ROLE_EXTEND_REQUEST,
    body,
    callback,
  };
};

const updateRoleExtend = (param, body, callback) => {
  return {
    type: typeActions.UPDATE_ROLE_EXTEND_REQUEST,
    param,
    body,
    callback,
  };
};

const deleteRoleExtend = (param, callback) => {
  return {
    type: typeActions.DELETE_ROLE_EXTEND_REQUEST,
    param,
    callback,
  };
};

export default {
  setValue,
  getRoles,
  createRole,
  updateRole,
  deleteRole,
  getPermissions,
  getRoleById,
  getCurrentPermissions,
  updatePermission,
  getRolePermissions,
  //
  getRolesExtend,
  getRoleExtend,
  createRoleExtend,
  updateRoleExtend,
  deleteRoleExtend,
};
