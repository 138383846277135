import typeActions from 'redux/typeActions';
import CONSTANT from 'constant';
import { returnValue } from 'common';

const initialState = {
  // loading
  isGettingPaymentHistorys: false,
  isGettingPaymentHistory: false,
  isCreatingPaymentHistory: false,
  isUpdatingPaymentHistory: false,
  isDeletingPaymentHistory: false,
  // data
  paymentHistorys: {},
  paymentHistory: {},
  // error
  errors: {
    getPaymentHistorys: '',
    getPaymentHistory: '',
    createPaymentHistory: '',
    updatePaymentHistory: '',
    deletePaymentHistory: '',
  },
  // query
  search: '',
  page: 1,
  limit: 10,
  startDate: null,
  endDate: null,
};

const paymentHistoryReducer = (state = initialState, actions) => {
  switch (actions.type) {
    case typeActions.SET_PAYMENT_HISTORY_VALUE:
      const { name, value } = actions;
      if (typeof name !== 'string') return { ...state };
      const obj = returnValue(state, name, value);

      return {
        ...state,
        ...obj,
      };

    case typeActions.GET_PAYMENT_HISTORYS_REQUEST:
      return {
        ...state,
        isGettingPaymentHistorys: true,
        errors: {
          ...state.errors,
          getPaymentHistorys: '',
        },
      };
    case typeActions.GET_PAYMENT_HISTORYS_SUCCESS:
      return {
        ...state,
        paymentHistorys: actions.data ?? {},
        isGettingPaymentHistorys: false,
        errors: {
          ...state.errors,
          getPaymentHistorys: '',
        },
      };
    case typeActions.GET_PAYMENT_HISTORYS_FAILED:
      return {
        ...state,
        paymentHistorys: {},
        isGettingPaymentHistorys: false,
        errors: {
          ...state.errors,
          getPaymentHistorys: actions.error,
        },
      };

    case typeActions.GET_PAYMENT_HISTORY_REQUEST:
      return {
        ...state,
        isGettingPaymentHistory: true,
        errors: {
          ...state.errors,
          getPaymentHistory: '',
        },
      };
    case typeActions.GET_PAYMENT_HISTORY_SUCCESS:
      return {
        ...state,
        paymentHistory: actions.data || {},
        isGettingPaymentHistory: false,
        errors: {
          ...state.errors,
          getPaymentHistory: '',
        },
      };
    case typeActions.GET_PAYMENT_HISTORY_FAILED:
      return {
        ...state,
        paymentHistory: {},
        isGettingPaymentHistory: false,
        errors: {
          ...state.errors,
          getPaymentHistory: actions.error,
        },
      };

    case typeActions.CREATE_PAYMENT_HISTORY_REQUEST:
      return {
        ...state,
        isCreatingPaymentHistory: true,
        errors: {
          ...state.errors,
          createPaymentHistory: '',
        },
      };
    case typeActions.CREATE_PAYMENT_HISTORY_SUCCESS:
      return {
        ...state,
        isCreatingPaymentHistory: false,
        errors: {
          ...state.errors,
          createPaymentHistory: '',
        },
      };
    case typeActions.CREATE_PAYMENT_HISTORY_FAILED:
      return {
        ...state,
        isCreatingPaymentHistory: false,
        errors: {
          ...state.errors,
          createPaymentHistory: actions.error,
        },
      };

    case typeActions.UPDATE_PAYMENT_HISTORY_REQUEST:
      return {
        ...state,
        isUpdatingPaymentHistory: true,
        errors: {
          ...state.errors,
          updatePaymentHistory: '',
        },
      };
    case typeActions.UPDATE_PAYMENT_HISTORY_SUCCESS:
      return {
        ...state,
        isUpdatingPaymentHistory: false,
        errors: {
          ...state.errors,
          updatePaymentHistory: '',
        },
      };
    case typeActions.UPDATE_PAYMENT_HISTORY_FAILED:
      return {
        ...state,
        isUpdatingPaymentHistory: false,
        errors: {
          ...state.errors,
          updatePaymentHistory: actions.error,
        },
      };

    case typeActions.DELETE_PAYMENT_HISTORY_REQUEST:
      return {
        ...state,
        isDeletingPaymentHistory: true,
        errors: {
          ...state.errors,
          deletePaymentHistory: '',
        },
      };
    case typeActions.DELETE_PAYMENT_HISTORY_SUCCESS:
      return {
        ...state,
        isDeletingPaymentHistory: false,
        errors: {
          ...state.errors,
          deletePaymentHistory: '',
        },
      };
    case typeActions.DELETE_PAYMENT_HISTORY_FAILED:
      return {
        ...state,
        isDeletingPaymentHistory: false,
        errors: {
          ...state.errors,
          deletePaymentHistory: actions.error,
        },
      };

    default:
      return {
        ...state,
      };
  }
};
export default paymentHistoryReducer;
