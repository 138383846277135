import classNames from "classnames";
import React, { memo } from "react";

const LabelCustom = ({
  label = "",
  id = "",
  styleLabel = {},
  style = {},
  isRequired = false,
  isDisplayLine = false,
  horizontal,
  styleHeading = {},
}) => {
  return (
    <label
      htmlFor={id}
      style={{
        display: isDisplayLine ? "flex" : undefined,
        fontSize: 15,
        color: "#32325D",
        ...styleLabel,
      }}
    >
      <h4
        // className={classNames(isRequired ? 'required' : '')}
        style={{
          marginTop: isDisplayLine ? 0 : "unset",
          whiteSpace: "nowrap",
          marginBottom: horizontal ? 0 : undefined,
          ...style,
        }}
      >
        {label} {isRequired && <span style={{ color: "red" }}>*</span>}
      </h4>
      <hr
        style={{
          display: isDisplayLine ? undefined : "none",
          flex: 1,
          marginTop: "20px",
          marginLeft: "8px",
          marginBottom: "0px",
        }}
      />
    </label>
  );
};

export default memo(LabelCustom);
