//core-componens
import React from 'react';
import { Col, Container, Row } from 'reactstrap';
//custom components
import LabelCustom from 'views/pages/components/LabelCustom';
//module
import CONSTANT from 'constant';
import _ from 'lodash';
//styles
import 'assets/css/custom-pages/specify-votes.css';
import 'assets/css/custom-pages/create-specify-votes.css';
import LabelValue from 'views/pages/components/LabelValue';
const moment = require('moment');
function GeneralInfomationLeft({ specifyVotes }) {
  return (
    <Col
      lg={6}
      className="pr-0"
      style={{
        borderRight: '1px solid rgba(0, 0, 0, 0.1)',
      }}
    >
      <Row>
        <Col xs={12}>
          <Row>
            <Col xs={12} className="pr-0">
              <LabelCustom
                label="Thông tin người làm xét nghiệm"
                horizontal={true}
                style={{
                  fontSize: '17px',
                  fontSize: '14px',
                  fontWeight: 400,
                  lineHeight: '19px',
                  textTransform: 'uppercase',
                }}
              />
            </Col>
          </Row>
          <Row>
            <Col
              xs={12}
              className="customSelect disable-react-select__dropdown-indicator"
            >
              <div className="pl-0">
                <LabelValue
                  lableTextAlign={'left'}
                  label={'Mã bệnh nhân'}
                  labelWidth={130}
                  value={specifyVotes?.codeBN ?? ''}
                  row={true}
                />
              </div>
            </Col>
          </Row>
          <Row>
            <Col xs={12}>
              <LabelValue
                lableTextAlign={'left'}
                label={'Họ tên'}
                labelWidth={130}
                value={specifyVotes?.nameBN ?? ''}
                row={true}
              />
            </Col>
          </Row>
          <Row>
            <Col xs={6}>
              <LabelValue
                lableTextAlign={'left'}
                labelWidth={130}
                label={'Ngày sinh'}
                value={specifyVotes?.dateOfBirthBN ?? ''}
                row={true}
              />
            </Col>
            <Col xs={6} className="customSelect pr-2">
              <div className="">
                <LabelValue
                  lableTextAlign={'left'}
                  label={'Giới tính'}
                  labelWidth={150}
                  value={
                    _.find(CONSTANT.ACCOUNT_GENDER, {
                      value: specifyVotes?.genderBN,
                    })?.label ?? ''
                  }
                  row={true}
                />
              </div>
            </Col>
          </Row>
          <Row>
            <Col xs={6}>
              <LabelValue
                lableTextAlign={'left'}
                label={'Email'}
                labelWidth={130}
                value={specifyVotes?.emailBN ?? ''}
                row={true}
              />
            </Col>
            <Col xs={6}>
              <LabelValue
                lableTextAlign={'left'}
                label={'Số điện thoại'}
                labelWidth={150}
                value={specifyVotes?.phoneBN ?? ''}
                row={true}
              />
            </Col>
          </Row>
          <Row>
            <Col xs={12}>
              <LabelValue
                lableTextAlign={'left'}
                label={'Nghề nghiệp'}
                labelWidth={130}
                value={specifyVotes?.jobBN ?? ''}
                row={true}
              />
            </Col>
          </Row>
          <Row>
            <Col xs={6}>
              <LabelValue
                lableTextAlign={'left'}
                label={'Người liên hệ'}
                labelWidth={130}
                value={specifyVotes?.nameContact ?? ''}
                row={true}
              />
            </Col>
            <Col xs={6}>
              <LabelValue
                lableTextAlign={'left'}
                label={'Số điện thoại liên hệ'}
                labelWidth={130}
                value={specifyVotes?.phoneContact ?? ''}
                row={true}
              />
            </Col>
          </Row>
          <Row>
            <Col xs={12}>
              <LabelValue
                lableTextAlign={'left'}
                label={'Địa chỉ bệnh nhân'}
                labelWidth={130}
                value={specifyVotes?.addressBN ?? ''}
                row={true}
              />
            </Col>
          </Row>
          <Row>
            <Col xs={12}>
              <LabelValue
                lableTextAlign={'left'}
                label={'Ghi chú'}
                labelWidth={130}
                value={specifyVotes?.note ?? ''}
                row={true}
              />
            </Col>
          </Row>
          <Row>
            <Col xs={12}>
              <LabelValue
                lableTextAlign={'left'}
                label={'Trạng thái'}
                labelWidth={130}
                value={
                  CONSTANT.SPECIFY_VOTE_STATUS.find((item) => {
                    return item.value === specifyVotes?.specifyVoteStatus;
                  })?.label ?? ''
                }
                row={true}
              />
            </Col>
          </Row>
        </Col>
      </Row>
    </Col>
  );
}

export default GeneralInfomationLeft;
