import _ from 'lodash';
import { takeLatest, call, put, takeEvery } from 'redux-saga/effects';

import typeActions from 'redux/typeActions';
import ServiceURL, { BASE_URL } from 'services/ServiceURL';
import { DELETE, GET, GET_FILE, POST, PUT } from 'services/ServiceBase';
import { custom } from 'services/axiosClient';

export function* getAdditionalSamples(data) {
  const url = `${ServiceURL.additionalSample}?filters[$and][0][deleted][$eq]=false&sort=createdAt:DESC&${data.query}`;
  const callback = data.callback;
  try {
    const res = yield call(GET, url);
    if (!!res.data?.error?.message) {
      yield put({
        type: typeActions.GET_ADDITIONAL_SAMPLES_FAILED,
        error: res.data?.error?.message,
      });
      !!callback?.failed && callback.failed(res.data.error.message);
    } else {
      if (data.isUpdateReducer) {
        yield put({
          type: typeActions.GET_ADDITIONAL_SAMPLES_SUCCESS,
          data: res.data,
        });
      }
      !!callback?.success && callback.success(res.data);
    }
  } catch (error) {
    yield put({
      type: typeActions.GET_ADDITIONAL_SAMPLES_FAILED,
      error: error?.response?.data?.error?.message ?? '',
    });
    !!callback?.failed &&
      callback.failed(error?.response?.data?.error?.message ?? '');
  }
}

export function* getAdditionalSample(data) {
  const url = `${ServiceURL.additionalSample}/${data.param}?${data.query}`;
  const callback = data.callback;
  try {
    const res = yield call(GET, url);
    if (!!res.data?.error?.message) {
      yield put({
        type: typeActions.GET_ADDITIONAL_SAMPLE_FAILED,
        error: res.data.error.message,
      });
      !!callback?.failed && callback.failed(res.data.error.message);
    } else {
      let relationToCodeStaff1 = {};
      let relationToCodeStaff2 = {};
      let relationToCodeStaff3 = {};
      let relationToPromotionDetail = {};
      let relationToOrganization = {};
      let relationToSpecifyVote = {};
      let relationToBill = {};
      let relationToPatient = {};
      let relationToMedicalRecord = {};
      const additionalSampleId = _.get(res, 'data.id', -1);
      const additionalSampleCode = _.get(res, 'data.code', '');
      try {
        relationToCodeStaff1 = yield handleGetRelation(
          additionalSampleId,
          additionalSampleCode,
          'codeStaff1'
        );
        relationToCodeStaff2 = yield handleGetRelation(
          additionalSampleId,
          additionalSampleCode,
          'codeStaff2'
        );
        relationToCodeStaff3 = yield handleGetRelation(
          additionalSampleId,
          additionalSampleCode,
          'codeStaff3'
        );
        relationToPromotionDetail = yield handleGetRelation(
          additionalSampleId,
          additionalSampleCode,
          'promotionDetail'
        );
        relationToOrganization = yield handleGetRelation(
          additionalSampleId,
          additionalSampleCode,
          'organization'
        );
        relationToSpecifyVote = yield handleGetRelation(
          additionalSampleId,
          additionalSampleCode,
          'specifyVote'
        );
        relationToBill = yield handleGetRelation(
          additionalSampleId,
          additionalSampleCode,
          'bill'
        );
        const specifyVoteId = _.get(
          relationToSpecifyVote,
          'data.results[0].id',
          -1
        );
        relationToPatient = yield handleGetRelation(
          specifyVoteId,
          additionalSampleCode,
          'patient'
        );
        relationToMedicalRecord = yield handleGetRelation(
          specifyVoteId,
          additionalSampleCode,
          'medicalRecord'
        );
      } catch (error) { }
      const codeStaff1 = _.get(relationToCodeStaff1, 'data.results[0]', null);
      const codeStaff2 = _.get(relationToCodeStaff2, 'data.results[0]', null);
      const codeStaff3 = _.get(relationToCodeStaff3, 'data.results[0]', null);
      const promotionDetail = _.get(
        relationToPromotionDetail,
        'data.results[0]',
        null
      );
      const organization = _.get(
        relationToOrganization,
        'data.results[0]',
        null
      );
      const specifyVote = _.get(relationToSpecifyVote, 'data.results[0]', null);
      const bill = _.get(relationToBill, 'data.results[0]', null);
      const patient = _.get(relationToPatient, 'data.results[0]', null);
      const medicalRecord = _.get(
        relationToMedicalRecord,
        'data.results[0]',
        null
      );
      const resData = {
        ...res.data,
        codeStaff1,
        codeStaff2,
        codeStaff3,
        promotionDetail,
        organization,
        specifyVote: !!specifyVote
          ? {
            ...specifyVote,
            patient,
            medicalRecord,
          }
          : null,
        bill,
      };
      yield put({
        type: typeActions.GET_ADDITIONAL_SAMPLE_SUCCESS,
        data: resData,
      });
      !!callback?.success && callback.success(resData);
    }
  } catch (error) {
    yield put({
      type: typeActions.GET_ADDITIONAL_SAMPLE_FAILED,
      error: error?.response?.data?.error?.message ?? '',
    });
    !!callback?.failed &&
      callback.failed(error?.response?.data?.error?.message ?? '');
  }
}

export function* getAdditionalSampleBy(data) {
  const url = `${ServiceURL.additionalSample}?${data.query}`;
  const callback = data.callback;
  try {
    const res = yield call(GET, url);
    if (!!res.data?.error?.message) {
      yield put({
        type: typeActions.GET_ADDITIONAL_SAMPLE_FAILED,
        error: res.data.error.message,
      });
      !!callback?.failed && callback.failed(res.data.error.message);
    } else {
      const resData = _.get(res, 'data.results[0]', {});
      yield put({
        type: typeActions.GET_ADDITIONAL_SAMPLE_SUCCESS,
        data: resData,
      });
      !!callback?.success && callback.success(resData);
    }
  } catch (error) {
    yield put({
      type: typeActions.GET_ADDITIONAL_SAMPLE_FAILED,
      error: error?.response?.data?.error?.message ?? '',
    });
  }
}

export function* createAdditionalSample(data) {
  const url = `${ServiceURL.additionalSample}`;
  const callback = data.callback;
  try {
    const res = yield call(POST, url, data.body);
    if (!!res.data?.error?.message) {
      yield put({
        type: typeActions.CREATE_ADDITIONAL_SAMPLE_FAILED,
        error: res.data.error.message,
      });
      !!callback?.failed && callback.failed(res.data.error.message);
    } else {
      yield put({
        type: typeActions.CREATE_ADDITIONAL_SAMPLE_SUCCESS,
        data: res.data,
      });
      !!callback?.success && callback.success(res.data);
    }
  } catch (error) {
    yield put({
      type: typeActions.CREATE_ADDITIONAL_SAMPLE_FAILED,
      error: error?.response?.data?.error?.message ?? '',
    });
    !!callback?.failed &&
      callback.failed(error?.response?.data?.error?.message ?? '');
  }
}

export function* updateAdditionalSample(data) {
  const url = `${ServiceURL.additionalSample}/${data.param}`;
  const callback = data.callback;
  try {
    const res = yield call(PUT, url, data.body);
    if (!!res.data?.error?.message) {
      yield put({
        type: typeActions.UPDATE_ADDITIONAL_SAMPLE_FAILED,
        error: res.data.error.message,
      });
      !!callback?.failed && callback.failed(res.data.error.message);
    } else {
      yield put({
        type: typeActions.UPDATE_ADDITIONAL_SAMPLE_SUCCESS,
        data: res.data,
      });

      !!callback?.success && callback.success(res.data);
    }
  } catch (error) {
    yield put({
      type: typeActions.UPDATE_ADDITIONAL_SAMPLE_FAILED,
      error: error?.response?.data?.error?.message ?? '',
    });
    !!callback?.failed &&
      callback.failed(error?.response?.data?.error?.message ?? '');
  }
}

export function* deleteAdditionalSample(data) {
  const url = `${ServiceURL.additionalSample}/${data.param}`;
  const callback = data.callback;
  try {
    const res = yield call(DELETE, url);
    if (!!res.data?.error?.message) {
      yield put({
        type: typeActions.DELETE_ADDITIONAL_SAMPLE_FAILED,
        error: res.data.error.message,
      });
      !!callback?.failed && callback.failed(res.data.error.message);
    } else {
      yield put({
        type: typeActions.DELETE_ADDITIONAL_SAMPLE_SUCCESS,
        data: res.data,
      });
      !!callback?.success && callback.success(res.data);
    }
  } catch (error) {
    yield put({
      type: typeActions.DELETE_ADDITIONAL_SAMPLE_FAILED,
      error: error?.response?.data?.error?.message ?? '',
    });
    !!callback?.failed &&
      callback.failed(error?.response?.data?.error?.message ?? '');
  }
}

function* handleGetRelation(id, code, relationTo) {
  try {
    // const urlRelation = `${ServiceURL.relation}/api::additionalSample.additionalSample/${additionalSampleId}/${relationTo}?pageSize=100`;
    // const response = yield call(GET, urlRelation);
    // const id = _.get(response, 'data.data.id', -1);
    // if (id === -1) return null;
    switch (relationTo) {
      case 'codeStaff1':
        const urlCodeStaff1 = `${ServiceURL.accountExtend}?filters[$and][0][additionalSample1s][id]=${id}&pageSize=99999`;
        return yield call(GET, urlCodeStaff1);
      case 'codeStaff2':
        const urlCodeStaff2 = `${ServiceURL.accountExtend}?filters[$and][0][additionalSample2s][id]=${id}&pageSize=99999`;
        return yield call(GET, urlCodeStaff2);
      case 'codeStaff3':
        const urlCodeStaff3 = `${ServiceURL.accountExtend}?filters[$and][0][additionalSample3s][id]=${id}&pageSize=99999`;
        return yield call(GET, urlCodeStaff3);
      case 'promotionDetail':
        const urlPromotionDetail = `${ServiceURL.promotionDetail}?filters[$and][0][additionalSamples][code]=${code}&pageSize=99999`;
        return yield call(GET, urlPromotionDetail);
      case 'organization':
        const urlOrganization = `${ServiceURL.organization}?filters[$and][0][additionalSamples][code]=${code}&pageSize=99999`;
        return yield call(GET, urlOrganization);
      case 'specifyVote':
        const urlSpecifyVote = `${ServiceURL.specifyvote}?filters[$and][0][additionalSample][id]=${id}&pageSize=99999`;
        return yield call(GET, urlSpecifyVote);
      case 'bill':
        const urlBill = `${ServiceURL.bill}?filters[$and][0][additionalSample][id]=${id}&pageSize=99999`;
        return yield call(GET, urlBill);
      case 'patient':
        const urlPatient = `${ServiceURL.patient}?filters[$and][0][specifyVotes][id]=${id}&pageSize=99999`;
        return yield call(GET, urlPatient);
      case 'medicalRecord':
        const urlMedicalRecord = `${ServiceURL.medicalRecord}?filters[$and][0][specifyVote][id]=${id}&pageSize=99999`;
        return yield call(GET, urlMedicalRecord);
      default:
        return null;
    }
  } catch (error) {
    return null;
  }
}

export function* exportAdditionalSample(data) {
  const url = `${ServiceURL.exportAdditionalSample}?${data.query}`;
  const callback = data.callback;
  try {
    const res = yield call(GET_FILE, url);
    if (!!res.data?.error?.message) {
      yield put({
        type: typeActions.EXPORT_ADDITIONAL_SAMPLE_FAILED,
        error: res.data?.error?.message,
      });
      !!callback?.failed && callback.failed(res.data.error.message);
    } else {
      yield put({
        type: typeActions.EXPORT_ADDITIONAL_SAMPLE_SUCCESS,
        data: res.data,
      });

      !!callback?.success && callback.success(res.data);
    }
  } catch (error) {
    yield put({
      type: typeActions.EXPORT_ADDITIONAL_SAMPLE_FAILED,
      error: error?.response?.data?.error?.message ?? '',
    });
    !!callback?.failed &&
      callback.failed(error?.response?.data?.error?.message ?? '');
  }
}
export function* exportAdditionalSampleAll(data) {
  const url = `${ServiceURL.exportAdditionalSampleAll}?${data.query}`;
  const callback = data.callback;
  try {
    const res = yield call(GET_FILE, url);
    if (!!res.data?.error?.message) {
      yield put({
        type: typeActions.EXPORT_ADDITIONAL_SAMPLE_ALL_FAILED,
        error: res.data?.error?.message,
      });
      !!callback?.failed && callback.failed(res.data.error.message);
    } else {
      yield put({
        type: typeActions.EXPORT_ADDITIONAL_SAMPLE_ALL_SUCCESS,
        data: res.data,
      });

      !!callback?.success && callback.success(res.data);
    }
  } catch (error) {
    yield put({
      type: typeActions.EXPORT_ADDITIONAL_SAMPLE_ALL_FAILED,
      error: error?.response?.data?.error?.message ?? '',
    });
    !!callback?.failed &&
      callback.failed(error?.response?.data?.error?.message ?? '');
  }
}

export function* printPkqAdditionalSample(data) {
  const url = `${ServiceURL.printPkqAdditionalSample}/${data.param}`;
  const callback = data.callback;
  try {
    const res = yield call(GET_FILE, url);
    if (!!res.data?.error?.message) {
      yield put({
        type: typeActions.PRINT_PKQ_ADDITIONAL_SAMPLE_FAILED,
        error: res.data?.error?.message,
      });
      !!callback?.failed && callback.failed(res.data.error.message);
    } else {
      yield put({
        type: typeActions.PRINT_PKQ_ADDITIONAL_SAMPLE_SUCCESS,
        data: res.data,
      });

      !!callback?.success && callback.success(res.data);
    }
  } catch (error) {
    yield put({
      type: typeActions.PRINT_PKQ_ADDITIONAL_SAMPLE_FAILED,
      error: error?.response?.data?.error?.message ?? '',
    });
    !!callback?.failed &&
      callback.failed(error?.response?.data?.error?.message ?? '');
  }
}

export function* updateLabCodes(data) {
  const callback = data.callback;
  try {
    const formData = new FormData();
    formData.append(`files`, data.file);

    const res = yield custom(
      'api/update-labcodes-additional-sample',
      formData,
      'post',
      BASE_URL
    );
    if (!!res.data?.error?.message) {
      yield put({
        type: typeActions.UPDATE_LABCODES_FAILED,
        error: res.data?.error?.message,
      });
      !!callback?.failed && callback.failed(res.data.error.message);
    } else {
      yield put({
        type: typeActions.UPDATE_LABCODES_SUCCESS,
        data: res,
      });

      !!callback?.success && callback.success(res);
    }
  } catch (error) {
    yield put({
      type: typeActions.UPDATE_LABCODES_FAILED,
      error: error?.response?.data?.error?.message ?? '',
    });
    !!callback?.failed &&
      callback.failed(error?.response?.data?.error?.message ?? '');
  }
}

export default function* additionalSampleSaga() {
  yield takeLatest(
    typeActions.GET_ADDITIONAL_SAMPLES_REQUEST,
    getAdditionalSamples
  );
  yield takeLatest(
    typeActions.GET_ADDITIONAL_SAMPLE_REQUEST,
    getAdditionalSample
  );
  yield takeLatest(
    typeActions.CREATE_ADDITIONAL_SAMPLE_REQUEST,
    createAdditionalSample
  );
  yield takeEvery(
    typeActions.UPDATE_ADDITIONAL_SAMPLE_REQUEST,
    updateAdditionalSample
  );
  yield takeLatest(
    typeActions.DELETE_ADDITIONAL_SAMPLE_REQUEST,
    deleteAdditionalSample
  );
  yield takeEvery(
    typeActions.GET_ADDITIONAL_SAMPLE_BY_REQUEST,
    getAdditionalSampleBy
  );
  yield takeLatest(
    typeActions.EXPORT_ADDITIONAL_SAMPLE_REQUEST,
    exportAdditionalSample
  );
  yield takeLatest(
    typeActions.EXPORT_ADDITIONAL_SAMPLE_ALL_REQUEST,
    exportAdditionalSampleAll
  );
  yield takeEvery(
    typeActions.PRINT_PKQ_ADDITIONAL_SAMPLE_REQUEST,
    printPkqAdditionalSample
  );
  yield takeLatest(
    typeActions.UPDATE_ADDITIONAL_SAMPLE_LABCODES_REQUEST,
    updateLabCodes
  );
}
