import _ from 'lodash';
import { call, put, takeEvery, takeLatest } from 'redux-saga/effects';
import { DELETE, GET, PATCH, POST, PUT } from 'services/ServiceBase';
import ServiceURL from 'services/ServiceURL';
import typeActions from '../typeActions';

export function* uploadFiles(data) {
  const url = `${ServiceURL.upload}`;
  const callback = data.callback;
  try {
    const res = yield call(POST, url, data.body);
    if (!!res.data?.error?.message) {
      yield put({
        type: typeActions.UPLOAD_AVATAR_FAILED,
        error: res.data.error.message,
      });
      !!callback?.failed && callback.failed(res.data.error.message);
    } else {
      yield put({
        type: typeActions.UPLOAD_AVATAR_SUCCESS,
        data: res.data,
      });
      !!callback?.success && callback.success(res.data);
    }
  } catch (error) {
    yield put({
      type: typeActions.UPLOAD_AVATAR_FAILED,
      error: error?.response?.data?.error?.message ?? '',
    });
    !!callback?.failed &&
      callback.failed(error?.response?.data?.error?.message ?? '');
  }
}

export function* deleteFile(data) {
  const url = `api/upload/files/${data.param}`;
  const callback = data.callback;
  try {
    const res = yield call(DELETE, url);
    if (!!res.data?.error?.message) {
      yield put({
        type: typeActions.DELETE_AVATAR_FAILED,
        error: res.data.error.message,
      });
      !!callback?.failed && callback.failed(res.data.error.message);
    } else {
      yield put({
        type: typeActions.DELETE_AVATAR_SUCCESS,
        data: res.data,
      });
      !!callback?.success && callback.success(res.data);
    }
  } catch (error) {
    yield put({
      type: typeActions.DELETE_AVATAR_FAILED,
      error: error?.response?.data?.error?.message ?? '',
    });
    !!callback?.failed &&
      callback.failed(error?.response?.data?.error?.message ?? '');
  }
}

export default function* roleSaga() {
  yield takeEvery(typeActions.UPLOAD_AVATAR_REQUEST, uploadFiles);
  yield takeEvery(typeActions.DELETE_AVATAR_REQUEST, deleteFile);
}
