import typeActions from "redux/typeActions";
const setValue = ({ name, value }) => {
  return {
    type: typeActions.SET_VALUE_GENERAL,
    name,
    value,
  };
};

export default {
  setValue,
};
