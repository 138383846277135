import typeActions from 'redux/typeActions';
import { checkRole, isSampleCollectorStaff, returnValue } from 'common';

const initialState = {
  title: '',
  subTitle: '',
  path: '',
  isDisplayAddBtn: false,
  isDisplayFilterBtn: false,
  isDisplayCancel: false,
  isDisplayPrintListBtn: false,
  isDisplaySaveAndPrint: false,
  isDisplaySaveAndExit: false,
  isDisplayAddFastBtn: false,
  filterTitle: [],
  isSubmitSaveAndPrint: false,
  isSubmitSaveAndExit: false,
  isSubmitForm: {
    name: 'submitAndPrint',
    value: false,
  },
  isReceiveBtn: false,
  isDisplayCreateAdditionalSampleBtn: false,
  isDisplayGoBackBtn: false,
  isDisplayPrintBtn: false,
  isDisplayExportBtn: false,
  isDisplayImportBtn: false,
  isDisplayApproveBtn: false,
  // permissions: [],
};
const defaultState = {
  isDisplayAddBtn: false,
  isDisplayFilterBtn: false,
  isDisplayCancel: false,
  isDisplayPrintListBtn: false,
  isDisplaySaveAndPrint: false,
  isDisplaySaveAndExit: false,
  isDisplayAddFastBtn: false,
  isReceiveBtn: false,
  isDisplayCreateAdditionalSampleBtn: false,
  isDisplayGoBackBtn: false,
  isDisplayPrintBtn: false,
  isDisplayExportBtn: false,
  isDisplayImportBtn: false,
  isDisplayApproveBtn: false,
  filterTitle: [],
  subTitle: '',
  title: '',
};
const timelineReducer = (state = initialState, actions) => {
  switch (actions.type) {
    case typeActions.SET_VALUE_TIMELINE_REDUCER:
      const { name, value } = actions;
      if (typeof name !== 'string') return { ...state };
      const obj = returnValue(state, name, value);
      return {
        ...state,
        ...obj,
      };
    case typeActions.UPDATE_TEXT_TIMELINE:
      const { path, search, currentAccountExtend } = actions;

      const regSpecifyVotesDetail = /(\/admin\/specify-vote-all\/)(\d+)/g;
      const regSpecifyVotesUpdate = /(\/admin\/specify-votes-update\/)(\d+)/g;
      const regPatientDetail = /(\/admin\/patient\/)(\d+)/g;
      const orderDetail = /(\/admin\/order-detail\/)(\d+)/g;

      const area = /(\/admin\/area\/)(\d+)/g;

      if (regSpecifyVotesDetail.test(path)) {
        return {
          ...state,
          ...defaultState,
          title: 'Xem thông tin phiếu xét nghiệm',
          path: path,
          isDisplayGoBackBtn: true,
          isDisplayPrintBtn: checkRole(currentAccountExtend, {
            roles: ['read-specify-vote-all'],
          }),
        };
      }
      if (regPatientDetail.test(path)) {
        return {
          ...state,
          ...defaultState,
          title: 'Xem thông tin chi tiết bệnh nhân',
          path: path,
          isDisplayGoBackBtn: true,
        };
      }
      if (regSpecifyVotesUpdate.test(path)) {
        return {
          ...state,
          ...defaultState,
          title: 'Cập nhật thông tin phiếu xét nghiệm',
          path: path,
          isDisplayCancel: true,
          isDisplaySaveAndExit: checkRole(currentAccountExtend, {
            roles: ['update-specify-vote-all'],
          }),
          isDisplaySaveAndPrint: checkRole(currentAccountExtend, {
            roles: ['update-specify-vote-all'],
          }),
        };
      }
      if (orderDetail.test(path)) {
        return {
          ...state,
          ...defaultState,
          title: search.includes('order-init')
            ? 'Xem thông tin đơn hàng cần cập nhật'
            : 'Xem thông tin đơn hàng tổng hợp',
          path: path,
        };
      }

      if (path.includes('/order/-1')) {
        return {
          ...state,
          ...defaultState,
          title: 'Thêm mới đơn hàng',
          path: path,
        };
      }
      if (path.includes('/order/')) {
        return {
          ...state,
          ...defaultState,
          title: 'Cập nhật đơn hàng',
          path: path,
        };
      }
      if (area.test(path)) {
        return {
          ...state,
          ...defaultState,
          isDisplayAddBtn: checkRole(currentAccountExtend, {
            roles: ['read-area'],
          }),
          title: 'Chi tiết khu vực',
          path: path,
        };
      }

      switch (path) {
        case '/admin/receive-results':
        case '/admin/return-results':
          return {
            ...state,
            ...defaultState,
            title: 'Tiếp nhận tập tin kết quả',
          };
        case '/admin/order-unpaid':
          return {
            ...state,
            ...defaultState,
            title: 'Đơn hàng chờ thanh toán',
            isDisplayExportBtn: checkRole(currentAccountExtend, {
              roles: ['read-order-unpaid'],
            }),
            isDisplayApproveBtn: checkRole(currentAccountExtend, {
              roles: ['update-order-unpaid'],
            }),
            isDisplayFilterBtn: checkRole(currentAccountExtend, {
              roles: ['read-order-unpaid'],
            }),
          };
        case '/admin/order-unapproved':
          return {
            ...state,
            ...defaultState,
            title: 'Đơn hàng chờ kế toán duyệt',
            isDisplayFilterBtn: checkRole(currentAccountExtend, {
              roles: ['read-order-unapproved'],
            }),
            isDisplayApproveBtn: checkRole(currentAccountExtend, {
              roles: ['update-order-unapproved'],
            }),
          };
        case '/admin/order-all':
          return {
            ...state,
            ...defaultState,
            title: 'Quản lý đơn hàng tổng hợp',
            path: path,
            isDisplayAddBtn:
              !isSampleCollectorStaff(currentAccountExtend) &&
              checkRole(currentAccountExtend, {
                roles: ['create-order-all'],
              }),
            isDisplayFilterBtn: checkRole(currentAccountExtend, {
              roles: ['read-order-all'],
            }),
            isDisplayExportBtn: checkRole(currentAccountExtend, {
              roles: ['read-order-all'],
            }),
            isDisplayAddFastBtn: checkRole(currentAccountExtend, {
              roles: ['create-order-all'],
            }),
          };
        case '/admin/synthesis-sample-transfer':
          return {
            ...state,
            ...defaultState,
            title: 'Quản lý tổng hợp - chuyển mẫu',
            path: path,
            isReceiveBtn: checkRole(currentAccountExtend, {
              roles: ['update-synthesis-sample-transfer'],
            }),
            isDisplayFilterBtn: checkRole(currentAccountExtend, {
              roles: ['read-synthesis-sample-transfer'],
            }),
            isDisplayExportBtn: checkRole(currentAccountExtend, {
              roles: ['read-synthesis-sample-transfer'],
            }),
          };
        case '/admin/additional-sample':
          return {
            ...state,
            ...defaultState,
            title: 'Mẫu bổ sung',
            path: path,
            isDisplayCreateAdditionalSampleBtn: checkRole(
              currentAccountExtend,
              {
                roles: ['create-additional-sample'],
              }
            ),
            isReceiveBtn: checkRole(currentAccountExtend, {
              roles: ['update-additional-sample'],
            }),
            isDisplayFilterBtn: checkRole(currentAccountExtend, {
              roles: ['read-additional-sample'],
            }),
          };
        case '/admin/synthesis-sample-transfer/receive':
          return {
            ...state,
            ...defaultState,
            title: 'Tiếp nhận đơn hàng',
            subTitle:
              'Vui lòng sử dụng máy đọc mã vạch để tiếp nhận hoặc nhâp thông tin tiếp nhận theo form',
            path: path,
          };
        case '/admin/order-init':
          return {
            ...state,
            ...defaultState,
            title: 'Quản lý đơn hàng cần cập nhật',
            path: path,
          };
        case '/admin/receive-result':
          return {
            ...state,
            ...defaultState,
            isDisplayFilterBtn: checkRole(currentAccountExtend, {
              roles: ['read-receive-result'],
            }),
            isReceiveBtn: checkRole(currentAccountExtend, {
              roles: ['update-receive-result'],
            }),
            title: 'Tiếp nhận kết quả',
            path: path,
          };
        case '/admin/approve-result':
          return {
            ...state,
            ...defaultState,
            isDisplayFilterBtn: checkRole(currentAccountExtend, {
              roles: ['read-approve-result'],
            }),
            isReceiveBtn: false,
            title: 'Đơn hàng chờ duyệt kết quả',
            path: path,
          };
        case '/admin/pay-result':
          return {
            ...state,
            ...defaultState,
            isDisplayFilterBtn: checkRole(currentAccountExtend, {
              roles: ['read-pay-result'],
            }),
            isReceiveBtn: false,
            title: 'Đơn hàng đã trả',
            path: path,
          };
        case '/admin/account':
          return {
            ...state,
            ...defaultState,
            title: 'Quản lý tài khoản người dùng',
            path: path,
            isDisplayAddBtn: checkRole(currentAccountExtend, {
              roles: ['create-account'],
            }),
            isDisplayImportBtn: checkRole(currentAccountExtend, {
              roles: ['create-account'],
            }),
          };
        case '/admin/role':
          return {
            ...state,
            ...defaultState,
            title: 'Quản lý phân quyền người dùng',
            path: path,
            isDisplayAddBtn: checkRole(currentAccountExtend, {
              roles: ['create-role'],
            }),
          };

        case '/admin/accountInfo':
          return {
            ...state,
            ...defaultState,
            title: 'Thông tin tài khoản',
            path: path,
            isDisplayAddBtn: false,
          };
        case '/admin/group-service':
          return {
            ...state,
            ...defaultState,
            title: 'Quản lý nhóm dịch vụ',
            path: path,
            isDisplayAddBtn: checkRole(currentAccountExtend, {
              roles: ['create-service'],
            }),
          };
        case '/admin/area':
          return {
            ...state,
            ...defaultState,
            title: 'Quản lý khu vực',
            path: path,
            isDisplayAddBtn: checkRole(currentAccountExtend, {
              roles: ['create-area'],
            }),
          };
        case '/admin/barcode':
          return {
            ...state,
            ...defaultState,
            title: 'Quản lý danh mục Barcode',
            path: path,
            isDisplayAddBtn: checkRole(currentAccountExtend, {
              roles: ['create-barcode'],
            }),
            isDisplayPrintListBtn: checkRole(currentAccountExtend, {
              roles: ['read-barcode'],
            }),
          };
        case '/admin/organizations':
          return {
            ...state,
            ...defaultState,
            title: 'Quản lý danh sách tổ chức',
            path: path,
            isDisplayAddBtn: checkRole(currentAccountExtend, {
              roles: ['create-organization'],
            }),
          };
        case '/admin/partner':
          return {
            ...state,
            ...defaultState,
            title: 'Quản lý đối tác xét nghiệm',
            path: path,
            isDisplayAddBtn: checkRole(currentAccountExtend, {
              roles: ['create-partner'],
            }),
          };
        case '/admin/specify-vote-all':
          return {
            ...state,
            ...defaultState,
            title: 'Quản lý phiếu xét nghiệm',
            path: path,
            isDisplayAddBtn: checkRole(currentAccountExtend, {
              roles: ['create-specify-vote-all'],
            }),
            isDisplayFilterBtn: checkRole(currentAccountExtend, {
              roles: ['read-specify-vote-all'],
            }),
          };
        case '/admin/specify-votes-create':
          return {
            ...state,
            ...defaultState,
            title: 'Thêm mới phiếu xét nghiệm',
            path: path,
            isDisplayCancel: true,
            isDisplaySaveAndExit: checkRole(currentAccountExtend, {
              roles: ['create-specify-vote-all'],
            }),
            isDisplaySaveAndPrint: checkRole(currentAccountExtend, {
              roles: ['create-specify-vote-all'],
            }),
          };
        case '/admin/patient':
          return {
            ...state,
            ...defaultState,
            title: 'Quản lý bệnh nhân',
            path: path,
          };
        case '/admin/promotion':
          return {
            ...state,
            ...defaultState,
            title: 'Quản lý chương trình khuyến mại',
            path: path,
            isDisplayAddBtn: checkRole(currentAccountExtend, {
              roles: ['create-promotion'],
            }),
            isDisplayExportBtn: checkRole(currentAccountExtend, {
              roles: ['read-promotion'],
            }),
          };
        case '/admin/promotion-detail':
          return {
            ...state,
            ...defaultState,
            title: 'Quản lý chi tiết khuyến mại',
            path: path,
            isDisplayAddBtn: checkRole(currentAccountExtend, {
              roles: ['create-promotion'],
            }),
          };

        case '/admin/service-group':
          return {
            ...state,
            ...defaultState,
            title: 'Quản lý nhóm dịch vụ',
            path: path,
            isDisplayAddBtn: checkRole(currentAccountExtend, {
              roles: ['create-service'],
            }),
          };
        case '/admin/service':
          return {
            ...state,
            ...defaultState,
            title: 'Quản lý danh mục dịch vụ',
            path: path,
            isDisplayAddBtn: checkRole(currentAccountExtend, {
              roles: ['create-service'],
            }),
          };
        case '/admin/template':
          return {
            ...state,
            ...defaultState,
            title: 'Quản lý mẫu in',
            path: path,
          };

        case '/admin/dashboard':
          return {
            ...state,
            ...defaultState,
            title: 'Báo cáo thống kê theo đơn hàng',
            path: path,
          };
        default:
          return {
            ...state,
            title: '',
            path: '',
            ...defaultState,
          };
      }

    default:
      return {
        ...state,
      };
  }
};
export default timelineReducer;
