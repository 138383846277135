import typeActions from 'redux/typeActions';
import CONSTANT from 'constant';
import { returnValue } from 'common';

const initialState = {
  // loading
  isGettingStatistic: false,

  // data
  statistic: {},
  // error
  errors: {
    getStatistic: '',
  },
};

const statisticReducer = (state = initialState, actions) => {
  switch (actions.type) {
    case typeActions.GET_STATISTIC_REQUEST:
      return {
        ...state,
        isGettingStatistic: true,
        errors: {
          ...state.errors,
          getStatistic: '',
        },
      };
    case typeActions.GET_STATISTIC_SUCCESS:
      return {
        ...state,
        statistic: actions.data || {},
        isGettingStatistic: false,
        errors: {
          ...state.errors,
          getStatistic: '',
        },
      };
    case typeActions.GET_STATISTIC_FAILED:
      return {
        ...state,
        statistic: {},
        isGettingStatistic: false,
        errors: {
          ...state.errors,
          getStatistic: actions.error,
        },
      };

    default:
      return {
        ...state,
      };
  }
};
export default statisticReducer;
