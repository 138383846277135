import typeActions from 'redux/typeActions';
import _ from 'lodash';

const initialState = {
  isModalVisible: false,
  modalInfo: {},
  queryFilter: {}, // Cái này để cho xuất file trên thanh Navbar
  queryPage: {} // Cái này để lưu query của danh sách hiện tại
};

const returnValue = (obj, name, value) => {
  const tmp = _.cloneDeep(obj);
  tmp[name] = value;
  return tmp;
};

const filterReducer = (state = initialState, actions) => {
  switch (actions.type) {
    case typeActions.SET_VALUE_FILTER:
      const { name, value } = actions;
      if (typeof name !== 'string') return { ...state };
      const obj = returnValue(state, name, value);
      return {
        ...state,
        ...obj,
      };

    default:
      return {
        ...state,
      };
  }
};
export default filterReducer;
