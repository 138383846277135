import _ from 'lodash';
import { all, call, put, takeEvery, takeLatest } from 'redux-saga/effects';

import typeActions from 'redux/typeActions';
import ServiceURL from 'services/ServiceURL';
import { DELETE, GET, GET_FILE, POST, PUT } from 'services/ServiceBase';
import { convertMessageError } from 'common';

/**
 *
 * @param {*} data
 * get list areas:
 * - failed -> call actions failed
 * - success ->
 *      + if (search in select-options) => return;
 *      + if (search in list areas) => call actions sucess
 */
export function* getAreas(data) {
  const url = `${ServiceURL.area}?${data.query}&sort=createdAt:DESC&filters[$and][0][deleted][$eq]=false`;
  const callback = data.callback;
  const isUpdateReducer = data.isUpdateReducer;
  try {
    const res = yield call(GET, url);
    if (!!res.data?.error?.message) {
      yield put({
        type: typeActions.GET_AREAS_FAILED,
        error: res.data?.error?.message,
      });
      !!callback?.failed && callback.failed(res.data.error.message);
    } else {
      const resData = {
        ...res.data,
      };
      if (isUpdateReducer) {
        const relations = yield handleGetRelation(
          res.data?.results ?? [],
          'children'
        );

        const results = _.get(res, 'data.results', []);
        resData.results = results.map((item, index) => {
          const children = _.get(
            relations,
            `[${index}.data.results]`,
            []
          ).filter((v) => !v.deleted);
          return { ...item, children: children };
        });
        yield put({
          type: typeActions.GET_AREAS_SUCCESS,
          data: resData,
        });
      }

      !!callback?.success && callback.success(resData);
    }
  } catch (error) {
    yield put({
      type: typeActions.GET_AREAS_FAILED,
      error: error?.response?.data?.error?.message ?? '',
    });
    !!callback?.failed &&
      callback.failed(error?.response?.data?.error?.message ?? '');
  }
}

export function* getArea(data) {
  const url = `${ServiceURL.area}/${data.param}?${data.query}`;
  const callback = data.callback;
  try {
    const res = yield call(GET, url);
    if (!!res.data?.error?.message) {
      yield put({
        type: typeActions.GET_AREA_FAILED,
        error: res.data.error.message,
      });
      !!callback?.failed && callback.failed(res.data.error.message);
    } else {
      const relationsC = yield handleGetRelation([res.data] ?? [], 'children');
      const relationsP = yield handleGetRelation([res.data] ?? [], 'parent');
      const children = _.get(relationsC, '[0].data.results', []).filter(
        (v) => !v.deleted
      );
      const parent = _.get(relationsP, '[0].data.data', {});
      const resData = {
        ...res.data,
        children,
        parent,
      };
      yield put({
        type: typeActions.GET_AREA_SUCCESS,
        data: resData,
      });
      !!callback?.success && callback.success(resData);
    }
  } catch (error) {
    yield put({
      type: typeActions.GET_AREA_FAILED,
      error: error?.response?.data?.error?.message ?? '',
    });
    !!callback?.failed &&
      callback.failed(error?.response?.data?.error?.message ?? '');
  }
}

export function* getAreaByIds(data) {
  const callback = data.callback;
  const apiGetByIds = data.ids.map((item) => {
    const url = `${ServiceURL.area}/${item}?${data.query}`;
    return call(GET, url);
  });
  let res = [];
  try {
    res = yield all(apiGetByIds);
    const results = res.map((item) => {
      return item.data;
    });
    const relations = yield handleGetRelation(results ?? [], data.relationTo);
    const resData = results.map((item, index) => {
      const children = _.get(relations, `[${index}].data.results`, []).filter(
        (v) => !v.deleted
      );
      return {
        ...item,
        children,
      };
    });
    !!callback?.success && callback.success(resData);
  } catch (error) { }
}

export function* createArea(data) {
  const url = ServiceURL.area;
  const callback = data.callback;
  try {
    const res = yield call(POST, url, data.params);
    if (!!res.data?.error?.message) {
      yield put({
        type: typeActions.CREATE_AREA_FAILED,
        error: res.data.error.message,
      });

      !!callback?.failed && callback.failed(res.data.error.message);
    } else {
      yield put({
        type: typeActions.CREATE_AREA_SUCCESS,
        data: res.data,
      });
      !!callback?.success && callback.success(res.data?.data);
    }
  } catch (error) {
    const message = convertMessageError('c', error, ' khu vực ');
    yield put({
      type: typeActions.CREATE_AREA_FAILED,
      error: message,
    });
    !!callback?.failed && callback.failed(message);
  }
}

export function* deleteArea(data) {
  const url = ServiceURL.area + '/' + data.params;
  const callback = data.callback;
  try {
    const res = yield call(DELETE, url);
    if (!!res.data?.error?.message) {
      yield put({
        type: typeActions.DELETE_AREA_FAILED,
        error: res.data.error.message,
      });
      !!callback?.failed && callback.failed(res.data.error.message);
    } else {
      yield put({
        type: typeActions.DELETE_AREA_SUCCESS,
      });
      !!callback?.success && callback.success();
    }
  } catch (error) {
    yield put({
      type: typeActions.DELETE_AREA_FAILED,
      error: error?.response?.data?.error?.message ?? '',
    });
    !!callback?.failed &&
      callback.failed(error?.response?.data?.error?.message ?? '');
  }
}
export function* updateArea(data) {
  const url = ServiceURL.area + '/' + data.params;
  const callback = data.callback;
  try {
    const res = yield call(PUT, url, data.body);
    if (!!res.error?.message) {
      yield put({
        type: typeActions.UPDATE_AREA_FAILED,
        error: res.error.message,
      });
      !!callback?.failed && callback.failed(res.error.message);
    } else {
      yield put({
        type: typeActions.UPDATE_AREA_SUCCESS,
        data: res.data,
      });
      !!callback?.success && callback.success(res.data);
    }
  } catch (error) {
    const details = error?.response?.data?.error?.details?.errors ?? '';
    const m = details
      ? details.map((_) => {
        return `Trường ${_.path.join(',')} : ${_.message}`;
      })
      : error?.response?.data?.error?.message ?? '' ?? '';
    yield put({
      type: typeActions.UPDATE_AREA_FAILED,
      error: m,
    });
    !!callback?.failed && callback.failed(m);
  }
}

function* handleGetRelation(results, relationTo) {
  const apiRelations =
    results.map((item) => {
      const urlRelation = `${ServiceURL.relation}/api::area.area/${item.id}/${relationTo}?pageSize=100`;
      return call(GET, urlRelation);
    }) ?? [];
  try {
    return yield all(apiRelations);
  } catch (error) {
    return [];
  }
}

export function* getAreasByAccountExtend({ param, callback }) {
  try {
    const urlRelation = `${ServiceURL.relation}/api::account-extend.account-extend/${param}/areas?pageSize=100`;
    const res = yield call(GET, urlRelation);
    const results = _.get(res, 'data.results', []);
    const apiAreas =
      results.map((item) => {
        const url = `${ServiceURL.area}?filters[$and][0][deleted][$eq]=false&filters[$and][1][status][$eq]=0&filters[$and][2][code][$eq]=${item.code}&sort[0]=createdAt:DESC`;
        return call(GET, url);
      }) ?? [];
    let resAreas = [];
    try {
      resAreas = yield all(apiAreas);
    } catch (error) {
      //
    }
    !!callback?.success &&
      callback.success(
        resAreas
          .map((item) => _.get(item, 'data.results[0]', null))
          .filter((item) => item !== null)
      );

    yield put({
      type: typeActions.GET_AREAS_BY_ACCOUNT_EXTEND_SUCCESS,
      data: resAreas
        .map((item) => _.get(item, 'data.results[0]', null))
        .filter((item) => item !== null),
    });
  } catch (error) {
    yield put({
      type: typeActions.GET_AREAS_BY_ACCOUNT_EXTEND_FAILED,
      error: error,
    });
    // !!callback?.failed && callback.failed(error);
  }
}

export function* exportAreas(data) {
  const url = `${ServiceURL.exportAreas}?${data.query}`;
  const callback = data.callback;
  try {
    const res = yield call(GET_FILE, url);
    if (!!res.data?.error?.message) {
      yield put({
        type: typeActions.EXPORT_AREA_FAILED,
        error: res.data?.error?.message,
      });
      !!callback?.failed && callback.failed(res.data.error.message);
    } else {
      yield put({
        type: typeActions.EXPORT_AREA_SUCCESS,
        data: res.data,
      });

      !!callback?.success && callback.success(res.data);
    }
  } catch (error) {
    yield put({
      type: typeActions.EXPORT_AREA_FAILED,
      error: error?.response?.data?.error?.message ?? '',
    });
    !!callback?.failed &&
      callback.failed(error?.response?.data?.error?.message ?? '');
  }
}

export default function* accountSaga() {
  yield takeLatest(typeActions.GET_AREAS_REQUEST, getAreas);
  yield takeLatest(typeActions.GET_AREA_REQUEST, getArea);
  yield takeLatest(typeActions.CREATE_AREA_REQUEST, createArea);
  yield takeLatest(typeActions.DELETE_AREA_REQUEST, deleteArea);
  yield takeLatest(typeActions.UPDATE_AREA_REQUEST, updateArea);
  yield takeLatest(typeActions.GET_AREA_BY_IDS_REQUEST, getAreaByIds);
  yield takeLatest(typeActions.EXPORT_AREA_REQUEST, exportAreas);
  yield takeEvery(
    typeActions.GET_AREAS_BY_ACCOUNT_EXTEND_REQUEST,
    getAreasByAccountExtend
  );
}
