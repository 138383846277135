import React, { memo, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Button, Col, Form, FormGroup, Input, Modal, Row } from 'reactstrap';
import * as yup from 'yup';
import { Formik } from 'formik';
import _ from 'lodash';
import queryString from 'query-string';
import DatePicker from 'react-datepicker';

import { isNumeric, notificationAlertRef, notify } from 'common';
import LabelCustom from 'views/pages/components/LabelCustom';
import { formActions, promotionActions } from 'redux/actions';
import HelperText from 'views/pages/components/HelperText';
import LoadingButtonCustom from 'views/pages/components/LoadingButtonCustom';
import CONSTANT from 'constant';
import InputCustom from 'views/pages/components/InputCustom';
import SelectCustom from 'views/pages/components/SelectCustom';
import CustomInputCalendar from 'views/pages/components/CustomInputCalendar';
import moment from 'moment';

const CPromotion = ({ handleGetPromotions }) => {
  const dispatch = useDispatch();

  // reducers
  const { isModalVisible, modalInfo } = useSelector(
    (state) => state.formReducer
  );
  const { isCreatingPromotion, isUpdatingPromotion } = useSelector(
    (state) => state.promotionReducer
  );

  const isUpdate = !_.isEmpty(modalInfo);

  // Khởi tạo dữ liêu
  const initInfo = {
    name: modalInfo?.name ?? '',
    form: modalInfo?.form ?? CONSTANT.PROMOTION_FORMS[0].value,
    valuePromotion: modalInfo?.valuePromotion ?? 0,
    startDate: !!modalInfo?.startDate ? new Date(modalInfo.startDate) : null,
    endDate: !!modalInfo?.endDate ? new Date(modalInfo.endDate) : null,
    descriptions: modalInfo?.descriptions ?? '',
    status: modalInfo?.status ?? CONSTANT.PROMOTION_STATUS[0].value,
  };

  const [formValue, setFormValue] = useState(CONSTANT.PROMOTION_FORMS[0]);
  const [statusValue, setStatusValue] = useState(CONSTANT.PROMOTION_STATUS[0]);

  // validate dữ liêu
  const infoSchema = yup.object().shape({
    name: yup
      .string()
      .required('Tên chương trình khuyến mại không được để trống!'),
    form: yup
      .number()
      .min(0, 'Hình thức khuyến mại không được để trống!')
      .required('Hình thức khuyến mại phải là số!'),
    valuePromotion: yup
      .number()
      .min(0.1, 'Giá trị khuyến mại lớn hơn 0')
      .required('Giá trị khuyến mại phải là số!'),
    startDate: yup
      .date()
      .required('Ngày bắt đầu không được để trống!')
      .nullable(),
    endDate: yup
      .date()
      .required('Ngày kết thúc không được để trống!')
      .nullable(),
    status: yup.boolean().required('Trạng thái không được để trống!'),
  });

  // Xử lý submit
  const onSubmit = (values) => {
    if (isUpdate) {
      // update
      dispatch(
        promotionActions.updatePromotion(modalInfo.id, values, {
          success: () => {
            handleGetPromotions();
            notify(
              notificationAlertRef,
              'success',
              'Thông báo',
              `Cập nhật chương trình khuyến mại thành công.`
            );
            handleClose();
          },
          failed: (mess) => {
            notify(
              notificationAlertRef,
              'danger',
              'Thông báo',
              `Cập nhật chương trình khuyến mại thất bại!. Lỗi: ${mess}!`
            );
          },
        })
      );
    } else {
      // create
      dispatch(
        promotionActions.createPromotion(values, {
          success: () => {
            handleGetPromotions();
            notify(
              notificationAlertRef,
              'success',
              'Thông báo',
              `Thêm mới chương trình khuyến mại thành công.`
            );
            handleClose();
          },
          failed: (mess) => {
            notify(
              notificationAlertRef,
              'danger',
              'Thông báo',
              `Thêm mới chương trình khuyến mại thất bại!. Lỗi: ${mess}!`
            );
          },
        })
      );
    }
  };

  // Xử lý đóng form
  const handleClose = () => {
    dispatch(
      formActions.setValue({
        name: 'isModalVisible',
        value: false,
      })
    );
    dispatch(
      formActions.setValue({
        name: 'modalInfo',
        value: {},
      })
    );
  };

  useEffect(() => {
    if (isUpdate) {
      const formV =
        CONSTANT.PROMOTION_FORMS.find(
          (item) => item.value === modalInfo.form
        ) ?? CONSTANT.PROMOTION_FORMS[0];
      const statusV =
        CONSTANT.PROMOTION_STATUS.find(
          (item) => item.value === modalInfo.status
        ) ?? CONSTANT.PROMOTION_STATUS[0];
      setFormValue(formV);
      setStatusValue(statusV);
    } else {
      setFormValue(CONSTANT.PROMOTION_FORMS[0]);
      setStatusValue(CONSTANT.PROMOTION_STATUS[0]);
    }
  }, [isUpdate]);

  return (
    <Modal
      size={'md'}
      isOpen={isModalVisible}
      className="modal-dialog-centered modal-secondary"
      toggle={() => {
        handleClose();
      }}
    >
      <div className="modal-header border-bottom bg-white pb-2">
        <h2> {isUpdate ? 'Cập nhật' : 'Thêm mới'} chương trình khuyến mại</h2>
        <button
          aria-label="Close"
          className="close"
          data-dismiss="modal"
          type="button"
          onClick={() => {
            handleClose();
          }}
        >
          <span style={{ color: '#262626' }} aria-hidden={true}>
            ×
          </span>
        </button>
      </div>
      <div className="modal-body bg-white rounded">
        <Formik
          initialValues={initInfo}
          // enableReinitialize
          onSubmit={onSubmit}
          validationSchema={infoSchema}
        >
          {({
            values,
            errors,
            touched,
            handleSubmit,
            handleBlur,
            handleChange,
            setFieldValue,
          }) => {
            return (
              <Form onSubmit={handleSubmit} className="">
                <Row>
                  <Col xs={12}>
                    <InputCustom
                      name="name"
                      label="Tên chương trình"
                      type="text"
                      placeholder="Nhập vào tên chương trình khuyến mại"
                      value={values.name}
                      isRequired={true}
                      handleChange={handleChange}
                      handleBlur={handleBlur}
                      error={errors?.name}
                      touched={touched?.name}
                    />
                  </Col>
                </Row>

                <Row>
                  <Col xs={6}>
                    <SelectCustom
                      name="form"
                      label="Hình thức"
                      placeholder="Lựa chọn"
                      value={formValue}
                      isRequired={true}
                      isClearable={false}
                      handleChange={(e) => {
                        setFieldValue(
                          'form',
                          e?.value ?? CONSTANT.PROMOTION_FORMS[0].value
                        );
                        setFormValue(e);

                        if (
                          e?.value === CONSTANT.PROMOTION_FORMS[0].value &&
                          parseInt(values.valuePromotion) > 100
                        ) {
                          setFieldValue('valuePromotion', 0);
                        }
                      }}
                      handleBlur={handleBlur}
                      onInputChange={(value) => {}}
                      error={errors.form}
                      touched={touched.form}
                      options={CONSTANT.PROMOTION_FORMS}
                    />
                  </Col>
                  <Col xs={6}>
                    <InputCustom
                      name="valuePromotion"
                      label="Giá trị"
                      type="number"
                      placeholder="Nhập vào giá trị giảm tối đa"
                      value={values.valuePromotion}
                      isRequired={true}
                      handleChange={(e) => {
                        if (isNumeric(e.target.value)) {
                          if (
                            values.form === CONSTANT.PROMOTION_FORMS[0].value &&
                            e.target.value <= 100
                          ) {
                            setFieldValue(
                              'valuePromotion',
                              Number(e.target.value)
                            );
                          } else if (
                            values.form === CONSTANT.PROMOTION_FORMS[1].value
                          ) {
                            setFieldValue(
                              'valuePromotion',
                              Number(e.target.value)
                            );
                          }
                        }
                      }}
                      handleBlur={handleBlur}
                      error={errors?.valuePromotion}
                      touched={touched?.valuePromotion}
                    />
                  </Col>
                </Row>

                <Row>
                  <Col xs={12}>
                    <LabelCustom
                      isRequired={true}
                      label={'Thời gian'}
                      id={'start'}
                    />
                    <div className="d-flex justify-content-between">
                      <div className="d-flex align-items-center">
                        <span
                          style={{ minWidth: 60 }}
                          className="mr-1 text-right"
                        >
                          Bắt đầu:
                        </span>
                        <div className="date-picker-custom row-date-picker-custom d-flex flex-column">
                          <DatePicker
                            customInput={<CustomInputCalendar />}
                            wrapperClassName="icon-calendar"
                            dateFormat={'dd/MM/yyyy'}
                            selected={values.startDate}
                            maxDate={values.endDate}
                            onChange={(date) => {
                              const startOfDate = moment(date)
                                .startOf('date')
                                .toDate();
                              setFieldValue('startDate', startOfDate);
                            }}
                          />
                          {errors.startDate && !!touched.startDate && (
                            <HelperText message={errors.startDate} />
                          )}
                        </div>
                      </div>
                      <div className="d-flex align-items-center">
                        <span
                          style={{ minWidth: 60 }}
                          className="mr-1 text-right"
                        >
                          Kết thúc:
                        </span>
                        <div className="date-picker-custom row-date-picker-custom d-flex flex-column">
                          <DatePicker
                            customInput={<CustomInputCalendar />}
                            wrapperClassName="icon-calendar"
                            dateFormat={'dd/MM/yyyy'}
                            selected={values.endDate}
                            minDate={values.startDate}
                            onChange={(date) => {
                              const endOfDate = moment(date)
                                .endOf('date')
                                .toDate();
                              setFieldValue('endDate', endOfDate);
                            }}
                          />
                          {errors.endDate && !!touched.endDate && (
                            <HelperText message={errors.endDate} />
                          )}
                        </div>
                      </div>
                    </div>
                  </Col>
                </Row>

                <Row>
                  <Col xs={12}>
                    <InputCustom
                      name="descriptions"
                      label="Mô tả chương trình"
                      type="textarea"
                      placeholder="Nhập mô tả chương trình khuyến mại"
                      value={values.descriptions}
                      isRequired={false}
                      handleChange={handleChange}
                      handleBlur={handleBlur}
                      error={errors?.descriptions}
                      touched={touched?.descriptions}
                    />
                  </Col>
                </Row>

                {isUpdate && (
                  <Row>
                    <Col xs={5}>
                      <SelectCustom
                        name="status"
                        label="Trạng thái"
                        placeholder="Lựa chọn"
                        value={statusValue}
                        isRequired={true}
                        isClearable={false}
                        handleChange={(e) => {
                          setFieldValue('status', e?.value ?? false);
                          setStatusValue(e);
                        }}
                        handleBlur={handleBlur}
                        onInputChange={(value) => {}}
                        error={errors.status}
                        touched={touched.status}
                        options={CONSTANT.PROMOTION_STATUS}
                      />
                    </Col>
                  </Row>
                )}

                <div className="mb-0 pb-0 mt-4 d-flex justify-content-end">
                  <Button
                    className="text-nowrap mr-1"
                    onClick={() => {
                      handleClose();
                    }}
                  >
                    Hủy
                  </Button>
                  <LoadingButtonCustom
                    onClick={handleSubmit}
                    color="info"
                    className="text-nowrap "
                    type="submit"
                    loading={isCreatingPromotion || isUpdatingPromotion}
                  >
                    {isUpdate ? 'Cập nhật' : 'Thêm mới'}
                  </LoadingButtonCustom>
                </div>
              </Form>
            );
          }}
        </Formik>
      </div>
    </Modal>
  );
};

export default memo(CPromotion);
