/**
 * Render Group Service Testing page
 * Routes /admin/group-service
 */

// core componet
import React, { useEffect, useMemo, useRef, useState } from 'react';
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory from 'react-bootstrap-table2-paginator';
import filterFactory, {
  Comparator,
  customFilter,
  textFilter,
} from 'react-bootstrap-table2-filter';
import ToolkitProvider, { Search } from 'react-bootstrap-table2-toolkit';
import _ from 'lodash';
import {
  Badge,
  Card,
  CardBody,
  CardHeader,
  Col,
  Container,
  Input,
  Label,
  Row,
  UncontrolledTooltip,
} from 'reactstrap';

import { checkRole, notificationAlertRef, notify } from 'common';
// custom components
import SimpleHeader from 'components/Headers/SimpleHeader.js';
import ModalWarningCustom from 'views/pages/components/ModalWarningCustom';
import Loading from 'views/pages/components/Loading';
import CUOrganization from './components/CUOrganization';
// css
import 'assets/css/custom-pages/react-bs-table.css';
import 'assets/css/custom-components/input-title-table.css';
// actions,hook - redux
import { useDispatch, useSelector } from 'react-redux';
import { organizationActions, formActions, filterActions } from 'redux/actions';
// custom hook
import { useDelayInputEffect, useDidUpdateEffect } from 'utils/HookUtils';
// icon
import IconEdit from 'assets/svgs/edit.svg';
import IconBin from 'assets/svgs/bin.svg';
import IconKey from 'assets/svgs/key.svg';
import IconEye from 'assets/svgs/eye.svg';
//other
import CONSTANT from 'constant';
import queryString from 'query-string';
import * as yup from 'yup';
function Organization() {
  const [notificationModal, setNotificationModal] = useState(false);
  const [organization, setOrganization] = useState(null);
  const [searchTerm, setSearchTerm] = useState('');
  const [searchOption, setSearchOption] = useState('');
  const [isUpdateReducer, setIsUpdateReducer] = useState(true);
  const [dataOptions, setDataOptions] = useState([]);

  const {
    page,
    pagination,
    search,
    limit,
    query,
    organizations,
    isDeleteOrganization,
  } = useSelector((state) => state.organizationReducer);
  const { currentAccountExtend } = useSelector((state) => state.accountReducer);
  const dispatch = useDispatch();
  const nameTypeSearch = 'Tìm kiếm';

  const customFilterColumn = (key, value) => {
    let newQuery = query;
    newQuery[`${key}`] = value;
    if (!value) {
      delete newQuery[`${key}`];
    }
    if (!isUpdateReducer) setIsUpdateReducer(true);
    dispatch(
      organizationActions.setValueOrganization({
        name: 'query',
        value: newQuery,
      })
    );
    callListOrganization();
  };
  const columns = [
    {
      dataField: 'code',
      text: 'MÃ TỔ CHỨC',
    },
    {
      dataField: 'name',
      text: 'TÊN TỔ CHỨC',
    },
    {
      dataField: 'organizationType',
      text: 'LOẠI TỔ CHỨC',
      formatter: (cell, row) => {
        return (
          <div style={{ textAlign: 'center' }}>
            {CONSTANT.ORGANIZATION_TYPE[cell]}
          </div>
        );
      },
      headerStyle: {
        textAlign: 'center',
      },
    },
    {
      dataField: 'area.name',
      filter: customFilter(),
      filterRenderer: (onFilter, column) => {
        return (
          <>
            <Input
              onChange={(e) => {
                customFilterColumn(
                  'filters[area][name][$containsi]',
                  e.target.value
                );
              }}
              className="input-title-tale"
              placeholder="KHU VỰC"
              style={{
                width: 70,
              }}
            />
          </>
        );
      },
      formatter: (cell, row) => {
        return <div style={{ textAlign: 'center' }}>{cell}</div>;
      },
    },
    {
      dataField: 'province.name',
      filter: customFilter(),
      filterRenderer: (onFilter, column) => {
        return (
          <>
            <Input
              onChange={(e) => {
                customFilterColumn(
                  'filters[province][name][$containsi]',
                  e.target.value
                );
              }}
              className="input-title-tale"
              placeholder="TỈNH"
              style={{
                width: 60,
              }}
            />
          </>
        );
      },
      formatter: (cell, row) => {
        return <div style={{ textAlign: 'center' }}>{cell}</div>;
      },
    },
    {
      dataField: 'nameRepresentative',
      text: 'NGƯỜI ĐẠI DIỆN',
    },
    {
      dataField: 'emailRepresentative',
      text: 'EMAIL',
    },
    {
      dataField: 'address',
      text: 'ĐỊA CHỈ',
    },
    {
      dataField: 'status',
      text: 'TRẠNG THÁI',
      formatter: (cell, row) => {
        const obj = CONSTANT.ORGANIZATION_STATUS_TYPE.find(
          (_) => _.value === cell
        );
        if (!!obj)
          return (
            <div className="d-flex justify-content-center">
              <Badge
                color={
                  obj?.value == 2
                    ? 'secondary'
                    : obj?.value == 1
                      ? 'danger'
                      : 'success'
                }
              >
                {obj?.label ?? ''}
              </Badge>
            </div>
          );
      },
      headerStyle: {
        textAlign: 'center',
      },
    },
    {
      dataField: 'actions',
      text: 'HÀNH ĐỘNG',
      formatter: boxActions,
      headerStyle: {
        textAlign: 'center',
      },
      headerClasses: 'fixed-column',
      classes: 'fixed-column',
    },
  ];
  function boxActions(__, row) {
    return (
      <div className="d-flex justify-content-center">
        <button
          id={'view' + row.id}
          onClick={() => {
            dispatch(
              formActions.setValue({
                name: 'modalInfo',
                value: { ...row, isView: true },
              })
            );
            dispatch(
              formActions.setValue({
                name: 'isModalVisible',
                value: true,
              })
            );
          }}
          className="btn p-0 btn-no-shadow mr-0"
        >
          <img src={IconEye} className="mr-1" />
        </button>
        <UncontrolledTooltip delay={1} placement="top" target={'view' + row.id}>
          Xem chi tiết
        </UncontrolledTooltip>
        {checkRole(currentAccountExtend, {
          roles: ['update-organization'],
        }) && (
            <>
              <button
                id={'edit' + row.id}
                onClick={() => {
                  dispatch(
                    formActions.setValue({
                      name: 'modalInfo',
                      value: row,
                    })
                  );
                  dispatch(
                    formActions.setValue({
                      name: 'isModalVisible',
                      value: true,
                    })
                  );
                }}
                className="btn p-0 btn-no-shadow mr-0"
              >
                <img src={IconEdit} className="mr-1" />
              </button>
              <UncontrolledTooltip
                delay={1}
                placement="top"
                target={'edit' + row.id}
              >
                Cập nhật tổ chức
              </UncontrolledTooltip>
            </>
          )}

        {checkRole(currentAccountExtend, {
          roles: ['delete-organization'],
        }) && (
            <>
              <button
                id={'lock' + row.id}
                onClick={() => {
                  setOrganization(row);
                  setNotificationModal(true);
                }}
                className="btn p-0 btn-no-shadow mr-0"
              >
                <img src={IconBin} />
              </button>
              <UncontrolledTooltip
                delay={1}
                placement="top"
                target={'lock' + row.id}
              >
                Xóa tổ chức
              </UncontrolledTooltip>
            </>
          )}
      </div>
    );
  }

  const onSizePerPageChange = (e) => {
    setIsUpdateReducer(true);
    dispatch(
      organizationActions.setValueOrganization({ name: 'page', value: 1 })
    );
    dispatch(
      organizationActions.setValueOrganization({
        name: 'limit',
        value: e.target.value,
      })
    );
  };

  const onPageChange = (value) => {
    setIsUpdateReducer(true);
    dispatch(
      organizationActions.setValueOrganization({ name: 'page', value: value })
    );
  };
  const paginations = paginationFactory({
    page: page,
    sizePerPage: limit,
    totalSize: pagination?.total,
    showTotal: false,
    withFirstAndLast: false,
    alwaysShowAllBtns: true,
    onPageChange: onPageChange,
    sizePerPageRenderer: () => {
      return (
        <div className="dataTables_length" id="datatable-basic_length">
          <label>
            Hiển thị{' '}
            {
              <select
                name="datatable-basic_length"
                aria-controls="datatable-basic"
                className="form-control form-control-sm"
                onChange={onSizePerPageChange}
              >
                <option value="10">10</option>
                <option value="25">25</option>
                <option value="50">50</option>
                <option value="100">100</option>
              </select>
            }{' '}
            dòng.
          </label>
        </div>
      );
    },
  });

  const handleDelete = () => {
    if (organization === null) return;
    if ((organization?.orders?.count ?? 0) > 0) {
      return notify(
        notificationAlertRef,
        'warning',
        'Thông báo',
        `Tổ chức có đơn hàng. Không được xóa!`
      );
    }
    if ((organization?.accountExtends?.count ?? 0) > 0) {
      notify(
        notificationAlertRef,
        'warning',
        'Thông báo',
        `Xóa tổ chức ${organization.name} thất bại! Không thể xóa tổ chức có tài khoản!`
      );
      return;
    }
    dispatch(
      organizationActions.deleteOrganization(organization.id, {
        success: () => {
          setNotificationModal(false);
          notify(
            notificationAlertRef,
            'success',
            'Thông báo',
            `Xóa tổ chức ${organization?.name ?? ''} thành công!`
          );
          callListOrganization();
        },
        failed: () => {
          notify(
            notificationAlertRef,
            'danger',
            'Thông báo',
            `Xóa tổ chức ${organization?.name ?? ''} thất bại!`
          );
        },
      })
    );
  };
  /**
   *  - Get the list of organizations on the first render
   *  - Call api when pagination or search values change
   */
  function callListOrganization() {
    const q = !!search
      ? {
        ...query,
        page: page,
        pageSize: limit,
        _q: search,
      }
      : {
        ...query,
        page: page,
        pageSize: limit,
      }
    dispatch(filterActions.setValueFilter({
      name: 'queryPage',
      value: q
    }))
    dispatch(
      organizationActions.getOrganizations(
        queryString.stringify(
          q
        ),
        {
          success: (data) => {
            // if (!isUpdateReducer) {
            // }
          },
          failed: () => { },
        },
        isUpdateReducer
      )
    );
  }
  useEffect(() => {
    if (_.isEmpty(organizations)) {
      callListOrganization();
    }
  }, []);

  useDidUpdateEffect(() => {
    const q = !!search
      ? {
        ...query,
        page: isUpdateReducer ? page : 1,
        pageSize: isUpdateReducer ? limit : 10,
        _q: search,
      }
      : {
        ...query,
        page: isUpdateReducer ? page : 1,
        pageSize: isUpdateReducer ? limit : 10,
      }
    dispatch(filterActions.setValueFilter({
      name: 'queryPage',
      value: q
    }))
    dispatch(
      organizationActions.getOrganizations(
        queryString.stringify(
          q
        ),
        {
          success: (dataOrganization) => {
            if (!isUpdateReducer) {
              setDataOptions(dataOrganization.data);
            }
          },
          failed: () => { },
        },
        isUpdateReducer
      )
    );
  }, [page, limit, search, query]);
  /**
   * Handle the search when the user finishes typing after 500 milliseconds
   */
  useDelayInputEffect(
    organizationActions.setValueOrganization({
      name: 'search',
      value: searchTerm,
    }),
    searchTerm,
    700
  );
  useDelayInputEffect(
    organizationActions.setValueOrganization({
      name: 'search',
      value: searchOption,
    }),
    searchOption,
    700
  );
  //validate add form
  const initInfo = {
    code: '',
    nameParent: '',
    nameOrganization: '',
    descriptionsOrganization: '',
  };
  const addFormSchema = yup.object().shape({
    code: yup.string().required('Trường này không được bỏ trống!'),
    nameOrganization: yup.string().required('Trường này không được bỏ trống!'),
  });
  /**
   * Initial option in select box
   * @returns
   */
  function handleGetValuesOption() {
    let arr = Array.isArray(organizations) ? organizations.slice(0, 10) : [];
    if (Array.isArray(dataOptions) && dataOptions.length != 0)
      arr = dataOptions;
    const options = arr.map((item) => {
      return {
        label: item.name,
        value: item.id + '',
      };
    });
    return options;
  }
  const optionNameParents = handleGetValuesOption();
  // return <></>
  return (
    <>
      <SimpleHeader />
      <CUOrganization
        optionNameParent={optionNameParents}
        callListOrganization={callListOrganization}
      />
      <ModalWarningCustom
        notificationModal={notificationModal}
        setNotificationModal={setNotificationModal}
        name={`Tổ chức ${organization?.name ?? ''}`}
        func={handleDelete}
        isDelete={isDeleteOrganization}
      />
      <Container className="mt--9" fluid>
        <Row>
          <Col xs={12}>
            <Card>
              <CardHeader className="pb-2">
                <h3>Danh sách</h3>
              </CardHeader>
              <ToolkitProvider
                data={_.isEmpty(organizations) ? [] : organizations}
                keyField="id"
                columns={columns}
                search={{
                  searchFormatted: true,
                }}
              >
                {(props) => (
                  <div className="py-4 table-responsive">
                    <div className="d-flex ml-4 justify-content-between mb-4">
                      <div className="d-flex align-items-center">
                        <p>
                          Hiển thị từ {(page - 1) * limit + 1} đến{' '}
                          {organizations?.length < page * limit
                            ? organizations?.length
                            : page * limit}{' '}
                          trong số {pagination?.total} bản ghi
                        </p>
                      </div>
                      <div
                        id="datatable-basic_filter"
                        className="dataTables_filter px-4 pb-1 d-flex align-items-center"
                      >
                        <h4 className="m-0 p-0">{nameTypeSearch}</h4>
                        <Input
                          type="text"
                          className="height-input"
                          placeholder="Nhập từ cần tìm"
                          onChange={(e) => {
                            if (searchTerm !== e) {
                              setIsUpdateReducer(true);
                              setSearchTerm(e.target.value);
                            }
                          }}
                        />
                      </div>
                    </div>

                    <BootstrapTable
                      {...props.baseProps}
                      noDataIndication={() => {
                        return (
                          <span className="font-weight-bold text-danger">
                            Không có dữ liệu!
                          </span>
                        );
                      }}
                      onTableChange={() => {
                        return <Loading />;
                      }}
                      remote
                      hover
                      bootstrap4={true}
                      pagination={paginations}
                      bordered={false}
                      filter={filterFactory()}
                      rowStyle={{
                        cursor: 'pointer',
                      }}
                      keyField="id"
                    />
                  </div>
                )}
              </ToolkitProvider>
            </Card>
          </Col>
        </Row>
      </Container>
    </>
  );
}

export default Organization;
