import typeActions from 'redux/typeActions';

const setValue = ({ name, value }) => {
  return {
    type: typeActions.SET_RESULT_VALUE,
    name,
    value,
  };
};

const getResultRelation = (id, relationTo, callback) => {
  return {
    type: typeActions.GET_RESULT_RELATION_REQUEST,
    id,
    relationTo,
    callback,
  };
};

const getResults = (query, callback, isUpdateReducer = true) => {
  return {
    type: typeActions.GET_RESULTS_REQUEST,
    query,
    callback,
    isUpdateReducer,
  };
};

const getResult = (param, query, callback) => {
  return {
    type: typeActions.GET_RESULT_REQUEST,
    param,
    query,
    callback,
  };
};

const createResult = (body, callback) => {
  return {
    type: typeActions.CREATE_RESULT_REQUEST,
    body,
    callback,
  };
};
const updateResult = (param, body, callback) => {
  return {
    type: typeActions.UPDATE_RESULT_REQUEST,
    param,
    body,
    callback,
  };
};
const deleteResult = (param, callback) => {
  return {
    type: typeActions.DELETE_RESULT_REQUEST,
    param,
    callback,
  };
};

export default {
  setValue,
  getResults,
  getResult,
  createResult,
  updateResult,
  deleteResult,
  getResultRelation,
};
