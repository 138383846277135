import { notificationAlertRef, notify } from 'common';
import { Formik } from 'formik';
import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Button, CardBody, Col, Form, Input, Row } from 'reactstrap';
import { accountActions } from 'redux/actions';
import HelperText from 'views/pages/components/HelperText';
import LabelCustom from 'views/pages/components/LabelCustom';
import LoadingButtonCustom from 'views/pages/components/LoadingButtonCustom';
import * as yup from 'yup';

const ChangePassword = () => {
  const dispatch = useDispatch();
  const { isUpdateCurrentAccount } = useSelector(
    (state) => state.accountReducer
  );

  const info = {
    currentPassword: '',
    password: '',
    rePassword: '',
  };
  const passwordSchema = yup.object().shape({
    currentPassword: yup.string().required('Vui lòng nhập mật khẩu hiện tại!'),
    password: yup
      .string()
      .required('Vui lòng nhập mật khẩu mới!')
      .test(
        'password',
        'Mật khẩu phải dài ít nhất 8 kí tự bao gồm chữ thường, chữ hoa và số!',
        (password) => {
          const reg = /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{8,}$/g;
          // /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/g;
          return reg.test(password);
        }
      ),
    rePassword: yup
      .string()
      .oneOf([yup.ref('password')], 'Mật khẩu không trùng khớp!')
      .required('Vui lòng xác nhận mật khẩu!'),
  });

  const onSubmit = (values, { resetForm }) => {
    dispatch(
      accountActions.updateCurrentAccount(
        {
          currentPassword: values.currentPassword,
          password: values.password,
        },
        {
          success: () => {
            notify(
              notificationAlertRef,
              'success',
              'Thông báo',
              `Thay đổi mật khẩu thành công`
            );
            resetForm();
          },
          failed: (mess) => {
            notify(
              notificationAlertRef,
              'danger',
              'Thông báo',
              `Thay đổi mật khẩu thất bại!. Lỗi: ${mess}!`
            );
          },
        }
      )
    );
  };

  return (
    <CardBody className="px-5">
      <Row className="py-4 lg-12 d-flex justify-content-center">
        <Formik
          initialValues={info}
          onSubmit={onSubmit}
          validationSchema={passwordSchema}
          enableReinitialize
        >
          {({
            errors,
            touched,
            values,
            handleChange,
            handleSubmit,
            handleBlur,
          }) => (
            <Form
              style={{
                display: 'flex',
                justifyContent: 'center',
              }}
            >
              <div className="py-3">
                <div style={{ minWidth: 300 }} className="mt-1">
                  <LabelCustom
                    isRequired={true}
                    label="Mật khẩu hiện tại"
                    id="currentPassword"
                  />

                  <Input
                    id="currentPassword"
                    placeholder="Nhập khẩu hiện tại"
                    type="password"
                    className="height-input"
                    name="currentPassword"
                    value={values.currentPassword}
                    onChange={handleChange}
                    onBlur={handleBlur}
                  />
                  {!!errors.currentPassword && !!touched.currentPassword && (
                    <HelperText message={errors.currentPassword} />
                  )}
                </div>
                <div style={{ minWidth: 300 }} className="mt-1">
                  <LabelCustom
                    isRequired={true}
                    label="Mật khẩu mới"
                    id="oldPassword"
                  />

                  <Input
                    id="password"
                    placeholder="Nhập mật khẩu mới"
                    type="password"
                    className="height-input"
                    name="password"
                    value={values.password}
                    onChange={handleChange}
                    onBlur={handleBlur}
                  />
                  {!!errors.password && !!touched.password && (
                    <HelperText message={errors.password} />
                  )}
                </div>
                <div style={{ minWidth: 300 }} className="mt-1">
                  <LabelCustom
                    isRequired={true}
                    label="Xác nhận mật khẩu mới"
                    id="rePassword"
                  />

                  <Input
                    id="rePassword"
                    placeholder="Nhập mật khẩu mới"
                    type="password"
                    className="height-input"
                    name="rePassword"
                    value={values.rePassword}
                    onChange={handleChange}
                    onBlur={handleBlur}
                  />
                  {!!errors.rePassword && !!touched.rePassword && (
                    <HelperText message={errors.rePassword} />
                  )}
                </div>
                <div className="d-flex justify-content-center mt-2">
                  <LoadingButtonCustom
                    onClick={handleSubmit}
                    color="info"
                    className="text-nowrap "
                    type="submit"
                    loading={isUpdateCurrentAccount}
                  >
                    Cập nhật
                  </LoadingButtonCustom>
                </div>
              </div>
            </Form>
          )}
        </Formik>
      </Row>
    </CardBody>
  );
};

export default ChangePassword;
