import React from 'react';
import BootstrapTable from 'react-bootstrap-table-next';
import filterFactory from 'react-bootstrap-table2-filter';
import ToolkitProvider from 'react-bootstrap-table2-toolkit';
import _ from 'lodash';
import { Card, CardHeader, Input, Spinner } from 'reactstrap';
import classNames from 'classnames';

import 'assets/css/custom-pages/react-bs-table.css';
import Loading from 'views/pages/components/Loading';

const TableCustom = ({
  tableName = '',
  keyField = 'id',
  data = [],
  columns = [],
  page = 1,
  limit = 10,
  total = 0,
  searchBy = '',
  pagination,
  search = '',
  onChange = () => {},
  selectRow = undefined,
  headerLeftComponent = () => <></>,
  headerSubComponent = () => <></>,
  isHeaderSub = false,
  isHideSelectItems = false,
  isLoading = false,
  showTableHeader = true,
  ...rest
}) => {
  return (
    <Card className="position-relative">
      <CardHeader className="pb-2 d-flex justify-content-between">
        <h3>{tableName}</h3>
        {headerLeftComponent()}
      </CardHeader>
      <ToolkitProvider
        data={data}
        keyField={keyField}
        columns={columns}
        search={{
          searchFormatted: true,
        }}
      >
        {(props) => (
          <div
            className={classNames({
              'py-4 table-responsive': true,
              'hide-select-items': isHideSelectItems,
            })}
          >
            {!!pagination && showTableHeader && (
              <div className="d-flex ml-4 justify-content-between mb-3">
                <div className="d-flex align-items-center">
                  {isHeaderSub ? (
                    headerSubComponent()
                  ) : (
                    <p>
                      Hiển thị từ {(page - 1) * limit + 1} đến {page * limit}{' '}
                      trong số {total} bản ghi
                    </p>
                  )}
                </div>

                <div
                  id="datatable-basic_filter"
                  className="dataTables_filter px-4 pb-1 d-flex align-items-center"
                >
                  <h4 className="m-0 p-0">{searchBy}</h4>
                  <Input
                    className="height-input"
                    type="text"
                    placeholder="Nhập từ cần tìm"
                    value={search}
                    onChange={onChange}
                  />
                </div>
              </div>
            )}

            <BootstrapTable
              {...props.baseProps}
              noDataIndication={() => {
                return (
                  <span className="font-weight-bold text-danger">
                    Không có dữ liệu!
                  </span>
                );
              }}
              onTableChange={() => {
                return <Loading />;
              }}
              remote
              hover
              bootstrap4={true}
              pagination={pagination}
              bordered={false}
              filter={filterFactory()}
              rowStyle={{
                cursor: 'pointer',
              }}
              keyField={keyField}
              selectRow={selectRow}
              {...rest}
            />
          </div>
        )}
      </ToolkitProvider>
      {isLoading && (
        <div
          className="position-absolute d-flex w-100 justify-content-center rounded"
          style={{
            top: 0,
            height: '100%',
            background: 'rgba(0,0,0,.1)',
            zIndex: 999,
          }}
        >
          <Spinner className="mt-8" color="info">
            Loading...
          </Spinner>
        </div>
      )}
    </Card>
  );
};

export default TableCustom;
