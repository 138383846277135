import typeActions from "redux/typeActions";
const setValueOrganization = ({ name, value, callback }) => {
  return {
    type: typeActions.SET_ORGANIZATION_VALUE,
    name,
    value,
  };
};
const getOrganizations = (query, callback, isUpdateReducer = true) => {
  return {
    type: typeActions.GET_ORGANIZATION_REQUEST,
    query,
    callback,
    isUpdateReducer,
  };
};
const getOrganizationById = (param, callback) => {
  return {
    type: typeActions.GET_ORGANIZATION_BY_ID_REQUEST,
    param,
    callback,
  };
};
const createOrganization = (params, callback) => {
  return {
    type: typeActions.CREATE_ORGANIZATION_REQUEST,
    params,
    callback,
  };
};
const updateOrganization = (params, body, callback) => {
  return {
    type: typeActions.UPDATE_ORGANIZATION_REQUEST,
    params,
    body,
    callback,
  };
};
const deleteOrganization = (params, callback) => {
  return {
    type: typeActions.DELETE_ORGANIZATION_REQUEST,
    params,
    callback,
  };
};

const getOrganizationsByAccountExtend = (param, callback) => {
  return {
    type: typeActions.GET_ORGANIZATIONS_BY_ACCOUNT_EXTEND_REQUEST,
    param,
    callback,
  };
};
const getOrganizationsByAccountBS = (param, query, callback) => {
  return {
    type: typeActions.GET_ORGANIZATIONS_BY_ACCOUNT_BS_REQUEST,
    param,
    query,
    callback,
  };
};
const exportOrganizations = (query, callback) => {
  return {
    type: typeActions.EXPORT_ORGANIZATION_REQUEST,
    callback,
    query,
  };
};

export default {
  setValueOrganization,
  getOrganizations,
  deleteOrganization,
  updateOrganization,
  createOrganization,
  getOrganizationById,
  getOrganizationsByAccountExtend,
  getOrganizationsByAccountBS,
  exportOrganizations
};
