import typeActions from 'redux/typeActions';

const setValue = ({ name, value }) => {
  return {
    type: typeActions.SET_SERVICE_GROUP_VALUE,
    name,
    value,
  };
};

const getServiceGroups = (query, callback, isUpdateReducer = true) => {
  return {
    type: typeActions.GET_SERVICE_GROUPS_REQUEST,
    query,
    callback,
    isUpdateReducer,
  };
};

const getServiceGroup = (param, query, callback) => {
  return {
    type: typeActions.GET_SERVICE_GROUP_REQUEST,
    param,
    query,
    callback,
  };
};

const createServiceGroup = (body, callback) => {
  return {
    type: typeActions.CREATE_SERVICE_GROUP_REQUEST,
    body,
    callback,
  };
};
const updateServiceGroup = (param, body, callback) => {
  return {
    type: typeActions.UPDATE_SERVICE_GROUP_REQUEST,
    param,
    body,
    callback,
  };
};
const deleteServiceGroup = (param, callback) => {
  return {
    type: typeActions.DELETE_SERVICE_GROUP_REQUEST,
    param,
    callback,
  };
};

const getServiceGroupByIds = (ids, relationTo, query, callback) => {
  return {
    type: typeActions.GET_SERVICE_GROUP_BY_IDS_REQUEST,
    ids,
    relationTo,
    query,
    callback,
  };
};

export default {
  setValue,
  getServiceGroups,
  getServiceGroup,
  createServiceGroup,
  updateServiceGroup,
  deleteServiceGroup,
  getServiceGroupByIds,
};
