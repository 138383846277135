import React, { memo, useEffect, useState } from 'react';
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory from 'react-bootstrap-table2-paginator';
import filterFactory from 'react-bootstrap-table2-filter';
import ToolkitProvider from 'react-bootstrap-table2-toolkit';
import _ from 'lodash';
import {
  Badge,
  Button,
  Card,
  CardHeader,
  Col,
  Container,
  Input,
  Row,
  UncontrolledTooltip,
} from 'reactstrap';
import queryString from 'query-string';

import { checkRole, notificationAlertRef, notify } from 'common';
import {
  areaActions,
  formActions,
  organizationActions,
  patientActions,
} from 'redux/actions';
import SimpleHeader from 'components/Headers/SimpleHeader.js';
import 'assets/css/custom-pages/react-bs-table.css';
import { useDispatch, useSelector } from 'react-redux';
import Loading from 'views/pages/components/Loading';
import CUArea from './components/CUArea';
import { useDidUpdateEffect } from 'utils/HookUtils';
import CONSTANT from 'constant';
import { currencyFormat } from 'common';
import ModalWarningCustom from 'views/pages/components/ModalWarningCustom';
import LabelCustom from '../components/LabelCustom';
import IconEdit from 'assets/svgs/edit.svg';
import IconBin from 'assets/svgs/bin.svg';
import IconAdd from 'assets/svgs/add.svg';
import TableCustom from '../components/TableCustom';
import { Link, useHistory, useLocation, useParams } from 'react-router-dom';
import moment from 'moment';

function AreaById({ location }) {
  const history = useHistory();
  const dispatch = useDispatch();
  const search = useLocation().search;

  const urlSearch = new URLSearchParams(search);

  const { id } = useParams();
  const { page, limit, area, isDeletingArea } = useSelector(
    (state) => state.areaReducer
  );
  const { currentAccountExtend } = useSelector((state) => state.accountReducer);

  const [areaIds, setAreaIds] = useState([]);
  const [organizations, setOrganizations] = useState([]);

  const total = _.get(area, 'pagination.total', 0);

  const [areaSearch, setAreaSearch] = useState('');
  const [notificationModal, setNotificationModal] = useState(false);
  const [areaG, setAreaG] = useState(null);

  const columns1 = [
    {
      dataField: 'code',
      text: 'MÃ KHU VỰC',
    },
    {
      dataField: 'name',
      text: 'TÊN KHU VỰC',
    },
    {
      dataField: 'children',
      text: 'CẤP CON',
      formatter: (cell, row) => {
        return cell.map((item, index) => (
          <div
            key={index}
            style={{ color: '#4690CD' }}
            onClick={() => {
              history.push(
                `/admin/area/${item?.id ?? ''}?code=${item.code}&parent-name=${
                  row?.name ?? ''
                }`
              );
            }}
          >
            {item.code}
            {index === cell.length - 1 ? '. ' : ', '}
          </div>
        ));
      },
    },
    {
      dataField: 'descriptions',
      text: 'MÔ TẢ',
    },
    {
      dataField: 'actions',
      text: 'HÀNH ĐỘNG',
      formatter: boxActions,
      headerStyle: {
        textAlign: 'center',
      },
      headerClasses: 'fixed-column',
      classes: 'fixed-column',
    },
  ];

  // const columns3 = [
  //   {
  //     dataField: 'code',
  //     text: 'MÃ ĐỐI TÁC',
  //   },
  //   {
  //     dataField: 'name',
  //     text: 'TÊN ĐỐI TÁC XÉT NGHIỆM',
  //   },
  //   {
  //     dataField: 'phone',
  //     text: 'SỐ ĐIỆN THOẠI',
  //   },
  //   {
  //     dataField: 'email',
  //     text: 'EMAIL',
  //   },
  //   {
  //     dataField: 'email',
  //     text: 'NGƯỜI ĐẠI DIỆN',
  //   },
  //   {
  //     dataField: 'email',
  //     text: 'NGƯỜI LIÊN HỆ',
  //   },
  // ];

  const columns2 = [
    {
      dataField: 'code',
      text: 'MÃ TỔ CHỨC',
    },
    {
      dataField: 'name',
      text: 'PHÒNG KHÁM / BỆNH VIỆN',
    },
    {
      dataField: 'organizationType',
      text: 'LOẠI TỔ CHỨC',
      formatter: (cell, row) => {
        return (
          <div style={{ minWidth: 120 }}>
            {CONSTANT.ORGANIZATION_TYPE[cell]}
          </div>
        );
      },
    },
    {
      dataField: 'province.name',
      text: 'TỈNH',
    },
    {
      dataField: 'nameRepresentative',
      text: 'NGƯỜI ĐẠI DIỆN',
    },
    {
      dataField: 'emailRepresentative',
      text: 'EMAIL',
    },
    {
      dataField: 'address',
      text: 'ĐỊA CHỈ',
    },
    {
      dataField: 'status',
      text: 'TRẠNG THÁI',
      formatter: (cell, row) => {
        const obj = CONSTANT.ORGANIZATION_STATUS_TYPE.find(
          (_) => _.value === cell
        );
        if (!!obj)
          return (
            <div className="d-flex justify-content-center">
              <Badge
                color={
                  obj?.value == 2
                    ? 'secondary'
                    : obj?.value == 1
                    ? 'danger'
                    : 'success'
                }
              >
                {obj?.label ?? ''}
              </Badge>
            </div>
          );
      },
      headerStyle: {
        textAlign: 'center',
      },
    },
  ];

  function boxActions(__, row) {
    return (
      <div className="d-flex justify-content-center">
        {checkRole(currentAccountExtend, {
          roles: ['create-area'],
        }) && (
          <>
            <button
              id={'add' + row.id}
              onClick={() => {
                dispatch(
                  formActions.setValue({
                    name: 'modalInfo',
                    value: {
                      parent: {
                        id: row?.id ?? '',
                        code: row?.code ?? '',
                      },
                      isAddAction: true,
                    },
                  })
                );
                dispatch(
                  formActions.setValue({
                    name: 'isModalVisible',
                    value: true,
                  })
                );
              }}
              className="btn p-0 btn-no-shadow mr-0"
            >
              <img src={IconAdd} className="mr-1" />
            </button>
            <UncontrolledTooltip
              delay={1}
              placement="top"
              target={'add' + row.id}
            >
              Thêm khu vực con
            </UncontrolledTooltip>
          </>
        )}
        {checkRole(currentAccountExtend, {
          roles: ['update-area'],
        }) && (
          <>
            <button
              id={'edit' + row.id}
              onClick={() => {
                dispatch(
                  formActions.setValue({
                    name: 'modalInfo',
                    value: row,
                  })
                );
                dispatch(
                  formActions.setValue({
                    name: 'isModalVisible',
                    value: true,
                  })
                );
              }}
              className="btn p-0 btn-no-shadow mr-0"
            >
              <img src={IconEdit} className="mr-1" />
            </button>
            <UncontrolledTooltip
              delay={1}
              placement="top"
              target={'edit' + row.id}
            >
              Cập nhật khu vực
            </UncontrolledTooltip>
          </>
        )}
        {checkRole(currentAccountExtend, {
          roles: ['delete-area'],
        }) && (
          <>
            <button
              id={'lock' + row.id}
              onClick={() => {
                setAreaG(row);
                setNotificationModal(true);
              }}
              className="btn p-0 btn-no-shadow mr-0"
            >
              <img src={IconBin} />
            </button>
            <UncontrolledTooltip
              delay={1}
              placement="top"
              target={'lock' + row.id}
            >
              Xóa khu vực
            </UncontrolledTooltip>
          </>
        )}
      </div>
    );
  }

  useEffect(() => {
    if (!id) return;

    handleGetArea();
  }, [id]);

  useEffect(() => {
    if (!id) return;
    handleGetAreaByIds();
  }, [area]);

  const handleGetArea = () => {
    dispatch(areaActions.getArea(id, queryString.stringify({})));
  };

  const handleGetAreaByIds = () => {
    const ids = _.get(area, 'children', []).map((item) => item.id);
    dispatch(
      areaActions.getAreaByIds(ids, 'children', queryString.stringify({}), {
        success: (data) => {
          setAreaIds(data);
        },
      })
    );
  };

  useEffect(() => {
    if (!id) return;
    handleGetOrganizationsByArea();
  }, [area]);

  const handleGetOrganizationsByArea = () => {
    dispatch(
      organizationActions.getOrganizations(
        queryString.stringify({
          'filters[$and][1][area][id][$eq]': id,
          pageSize: 999999,
        }),
        {
          success: (data) => {
            setOrganizations(data);
          },
        },
        false
      )
    );
  };

  const handleDelete = () => {
    if (areaG === null) return;
    if ((areaG?.children?.count ?? 0) > 0) {
      return notify(
        notificationAlertRef,
        'warning',
        'Thông báo',
        `Khu vực có các khu vực trực thuộc. Không được xóa!`
      );
    }
    if ((areaG?.accountsExtend?.count ?? 0) > 0) {
      return notify(
        notificationAlertRef,
        'warning',
        'Thông báo',
        `Khu vực có tài khoản trực thuộc. Không được xóa!`
      );
    }
    if ((areaG?.organizations?.count ?? 0) > 0) {
      return notify(
        notificationAlertRef,
        'warning',
        'Thông báo',
        `Khu vực có tổ chức trực thuộc. Không được xóa!`
      );
    }
    dispatch(
      areaActions.updateArea(
        areaG.id,
        { deleted: true, parent: null },
        {
          success: () => {
            setNotificationModal(false);
            notify(
              notificationAlertRef,
              'success',
              'Thông báo',
              `Xóa khu vực ${areaG?.name} thành công!`
            );
            history.goBack();
            // handleGetArea();
          },
          failed: (mess) => {
            notify(
              notificationAlertRef,
              'danger',
              'Thông báo',
              `Xóa khu vực ${areaG?.name} thất bại!. Lỗi: ${mess}`
            );
          },
        }
      )
    );
  };

  return (
    <>
      <SimpleHeader />
      <CUArea handleGetArea={handleGetArea} />
      <ModalWarningCustom
        notificationModal={notificationModal}
        setNotificationModal={setNotificationModal}
        name={`khu vực ${area?.name ?? ''}`}
        func={handleDelete}
        isDelete={isDeletingArea}
      />
      <Container className="mt--9" fluid>
        <Row>
          <div className="col">
            <Card className="rounded">
              <CardHeader className="pb-2 rounded">
                <h3>Chi tiết khu vực</h3>
                <Row>
                  <Col className="d-flex" xs={12} md={4}>
                    <LabelCustom
                      label={'Mã khu vực'}
                      styleLabel={{ minWidth: 120 }}
                    />
                    : {area?.code ?? ''}
                  </Col>
                  <Col className="d-flex" xs={12} md={4}>
                    <LabelCustom
                      label={'Tên khu vực'}
                      styleLabel={{ minWidth: 120 }}
                    />
                    : {area?.name ?? ''}
                  </Col>
                </Row>
                <Row>
                  <Col className="d-flex" xs={12} md={4}>
                    <LabelCustom
                      label={'Cấp độ cha'}
                      styleLabel={{ minWidth: 120 }}
                    />
                    :{' '}
                    {!!urlSearch.get('parent-name')
                      ? `${urlSearch.get('parent-name')} (${
                          area?.parent?.code ?? ''
                        })`
                      : 'Không có'}
                  </Col>
                  <Col className="d-flex" xs={12} md={4}>
                    <LabelCustom
                      label={'Ngày tạo'}
                      styleLabel={{ minWidth: 120 }}
                    />
                    :{' '}
                    {!!area?.createdAt
                      ? moment(area.createdAt).format('DD/MM/YYYY')
                      : ''}
                  </Col>
                </Row>
                <Row>
                  <Col className="d-flex" xs={12} md={4}>
                    <LabelCustom
                      label={'Mô tả'}
                      styleLabel={{ minWidth: 120 }}
                    />
                    : {area?.descriptions ?? ''}
                  </Col>
                  <Col className="d-flex" xs={12} md={4}>
                    <LabelCustom
                      label={'Người tạo'}
                      styleLabel={{ minWidth: 120 }}
                    />
                    : {area?.createdBy?.fullname ?? ''}
                  </Col>
                </Row>
                <Row>
                  <Col className="d-flex justify-content-end" xs={12}>
                    <Button
                      className="mr-2 text-info"
                      onClick={() => {
                        history.goBack();
                      }}
                      style={{
                        backgroundColor: 'transparent',
                        color: 'white',
                        width: '130px',
                      }}
                    >
                      Quay lại
                    </Button>
                    <Button
                      color="info"
                      className="mr-2"
                      onClick={() => {
                        dispatch(
                          formActions.setValue({
                            name: 'modalInfo',
                            value: area,
                          })
                        );
                        dispatch(
                          formActions.setValue({
                            name: 'isModalVisible',
                            value: true,
                          })
                        );
                      }}
                    >
                      Cập nhật
                    </Button>
                    <Button
                      color="danger"
                      className=""
                      onClick={() => {
                        setAreaG(area);
                        setNotificationModal(true);
                      }}
                    >
                      Xóa
                    </Button>
                  </Col>
                </Row>
              </CardHeader>
            </Card>
            <TableCustom
              tableName="Danh sách khu vực con"
              data={areaIds.filter((item) => !item.deleted)}
              columns={columns1}
              page={page}
              limit={limit}
              total={total}
              searchBy={'Tìm kiếm'}
              pagination={undefined}
              onChange={(e) => {
                // setAreaSearch(e.target.value);
              }}
            />
            <TableCustom
              tableName="Danh sách phòng khám/bệnh viện"
              data={organizations.filter((item) => !item.deleted)}
              columns={columns2}
              page={page}
              limit={limit}
              total={total}
              searchBy={'Tìm kiếm'}
              pagination={undefined}
              onChange={(e) => {
                // setAreaSearch(e.target.value);
              }}
            />
            {/* <TableCustom
              tableName="Danh sách phòng khám/bệnh viện"
              data={areaIds.filter((item) => !item.deleted)}
              columns={columns1}
              page={page}
              limit={limit}
              total={total}
              searchBy={'Tìm kiếm'}
              pagination={undefined}
              onChange={(e) => {
              }}
            /> */}
          </div>
        </Row>
      </Container>
    </>
  );
}

export default memo(AreaById);
