import React, { memo, useEffect, useState } from 'react';
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory from 'react-bootstrap-table2-paginator';
import filterFactory from 'react-bootstrap-table2-filter';
import ToolkitProvider from 'react-bootstrap-table2-toolkit';
import _ from 'lodash';
import {
  Card,
  CardHeader,
  Container,
  Input,
  Row,
  UncontrolledTooltip,
} from 'reactstrap';
import queryString from 'query-string';

import { checkRole, notificationAlertRef, notify } from 'common';
import { serviceGroupActions, formActions, filterActions } from 'redux/actions';
import SimpleHeader from 'components/Headers/SimpleHeader.js';
import 'assets/css/custom-pages/react-bs-table.css';
import { useDispatch, useSelector } from 'react-redux';
import CUServiceGroup from './components/CUServiceGroup';
import { useDidUpdateEffect } from 'utils/HookUtils';
import ModalWarningCustom from 'views/pages/components/ModalWarningCustom';

import IconEdit from 'assets/svgs/edit.svg';
import IconBin from 'assets/svgs/bin.svg';
import TableCustom from '../components/TableCustom';
import { useHistory } from 'react-router-dom';

function ServiceGroup() {
  const dispatch = useDispatch();
  const history = useHistory();
  const { page, limit, serviceGroups, isDeletingService } = useSelector(
    (state) => state.serviceGroupReducer
  );
  const { currentAccountExtend } = useSelector((state) => state.accountReducer);

  const results = _.get(serviceGroups, 'results', []);
  const total = _.get(serviceGroups, 'pagination.total', 0);

  const [serviceGroupSearch, setServiceGroupSearch] = useState('');
  const [notificationModal, setNotificationModal] = useState(false);
  const [serviceGroup, setService] = useState(null);

  const columns = [
    {
      dataField: 'code',
      text: 'MÃ NHÓM DỊCH VỤ',
    },
    {
      dataField: 'name',
      text: 'TÊN NHÓM DỊCH VỤ',
    },
    // {
    //   dataField: 'services',
    //   text: 'DỊCH VỤ',
    //   formatter: (cell) => {
    //     return cell.map((item, index) => (
    //       <Link key={index} to={`/service-group/${item.id}`}>
    //         {item.code}
    //         {index === cell.length - 1 ? '. ' : ', '}
    //       </Link>
    //     ));
    //   },
    // },
    {
      dataField: 'descriptions',
      text: 'MÔ TẢ',
    },
    {
      dataField: 'actions',
      text: 'HÀNH ĐỘNG',
      formatter: boxActions,
      headerStyle: {
        textAlign: 'center',
      },
      headerClasses: 'fixed-column',
      classes: 'fixed-column',
    },
  ];

  function boxActions(__, row) {
    return (
      <div className="d-flex justify-content-center">
        {checkRole(currentAccountExtend, {
          roles: ['update-service'],
        }) && (
            <>
              <button
                id={'edit' + row.id}
                onClick={() => {
                  dispatch(
                    formActions.setValue({
                      name: 'modalInfo',
                      value: row,
                    })
                  );
                  dispatch(
                    formActions.setValue({
                      name: 'isModalVisible',
                      value: true,
                    })
                  );
                }}
                className="btn p-0 btn-no-shadow mr-0"
              >
                <img src={IconEdit} className="mr-1" />
              </button>
              <UncontrolledTooltip
                delay={1}
                placement="top"
                target={'edit' + row.id}
              >
                Cập nhật nhóm dịch vụ
              </UncontrolledTooltip>
            </>
          )}
        {checkRole(currentAccountExtend, {
          roles: ['delete-service'],
        }) && (
            <>
              <button
                id={'lock' + row.id}
                onClick={() => {
                  setService(row);
                  setNotificationModal(true);
                }}
                className="btn p-0 btn-no-shadow mr-0"
              >
                <img src={IconBin} />
              </button>
              <UncontrolledTooltip
                delay={1}
                placement="top"
                target={'lock' + row.id}
              >
                Xóa nhóm dịch vụ
              </UncontrolledTooltip>
            </>
          )}
      </div>
    );
  }

  const onSizePerPageChange = (e) => {
    dispatch(serviceGroupActions.setValue({ name: 'page', value: 1 }));
    dispatch(
      serviceGroupActions.setValue({ name: 'limit', value: e.target.value })
    );
  };

  const onPageChange = (value) => {
    dispatch(serviceGroupActions.setValue({ name: 'page', value: value }));
  };

  const pagination = paginationFactory({
    page: page,
    sizePerPage: limit,
    totalSize: total,
    showTotal: false,
    withFirstAndLast: false,
    alwaysShowAllBtns: true,
    onPageChange: onPageChange,
    sizePerPageRenderer: () => {
      return (
        <div className="dataTables_length" id="datatable-basic_length">
          <label>
            Hiển thị{' '}
            {
              <select
                name="datatable-basic_length"
                aria-controls="datatable-basic"
                className="form-control form-control-sm"
                onChange={onSizePerPageChange}
              >
                <option value="10">10</option>
                <option value="25">25</option>
                <option value="50">50</option>
                <option value="100">100</option>
              </select>
            }{' '}
            dòng.
          </label>
        </div>
      );
    },
  });

  // useDidUpdateEffect(() => {
  //   handleGetServiceGroups();
  // }, [page, limit]);

  useDidUpdateEffect(() => {
    // Sau 500ms thì bắn api
    const delayDebounceFn = setTimeout(() => {
      handleGetServiceGroups();
    }, 500);
    return () => clearTimeout(delayDebounceFn);
  }, [serviceGroupSearch, page, limit]);

  useEffect(() => {
    // if (!_.isEmpty(serviceGroups)) return;
    handleGetServiceGroups();
  }, []);

  const handleGetServiceGroups = () => {
    if (serviceGroupSearch !== '') {
      const q = {
        pageSize: limit,
        page,
        _q: serviceGroupSearch,
      }
      dispatch(filterActions.setValueFilter({
        name: 'queryPage',
        value: q
      }))
      dispatch(
        serviceGroupActions.getServiceGroups(
          queryString.stringify(q)
        )
      );
    } else {
      dispatch(filterActions.setValueFilter({
        name: 'queryPage',
        value: {}
      }))
      dispatch(
        serviceGroupActions.getServiceGroups(
          queryString.stringify({ pageSize: limit, page })
        )
      );
    }
  };

  const handleDelete = () => {
    if (serviceGroup === null) return;
    if ((serviceGroup?.services?.count ?? 0) > 0)
      return notify(
        notificationAlertRef,
        'warning',
        'Thông báo',
        `Nhóm dịch vụ đang có các dịch vụ. Không được phép xóa!`
      );
    dispatch(
      serviceGroupActions.updateServiceGroup(
        serviceGroup.id,
        { deleted: true },
        {
          success: () => {
            setNotificationModal(false);
            notify(
              notificationAlertRef,
              'success',
              'Thông báo',
              `Xóa nhóm dịch vụ ${serviceGroup?.name} thành công!`
            );
            handleGetServiceGroups();
          },
          failed: (mess) => {
            notify(
              notificationAlertRef,
              'danger',
              'Thông báo',
              `Xóa nhóm dịch vụ ${serviceGroup?.name} thất bại!. Lỗi: ${mess}`
            );
          },
        }
      )
    );
  };

  return (
    <>
      <SimpleHeader />
      <CUServiceGroup handleGetServiceGroups={handleGetServiceGroups} />
      <ModalWarningCustom
        notificationModal={notificationModal}
        setNotificationModal={setNotificationModal}
        name={`nhóm dịch vụ ${serviceGroup?.name ?? ''}`}
        func={handleDelete}
        isDelete={isDeletingService}
      />
      <Container className="mt--9" fluid>
        <Row>
          <div className="col">
            <TableCustom
              tableName="Danh sách nhóm dịch vụ"
              data={results}
              columns={columns}
              page={page}
              limit={limit}
              total={total}
              searchBy={'Tìm kiếm'}
              pagination={pagination}
              search={serviceGroupSearch}
              onChange={(e) => {
                setServiceGroupSearch(e.target.value);
              }}
            />
          </div>
        </Row>
      </Container>
    </>
  );
}

export default memo(ServiceGroup);
