import React, { memo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Button, Col, Form, Modal, Row } from 'reactstrap';
import * as yup from 'yup';
import { Formik } from 'formik';
import _ from 'lodash';
import { notificationAlertRef, notify } from 'common';
import { serviceGroupActions, formActions } from 'redux/actions';
import LoadingButtonCustom from 'views/pages/components/LoadingButtonCustom';
import InputCustom from 'views/pages/components/InputCustom';

const CUServiceGroup = ({ handleGetServiceGroups }) => {
  const dispatch = useDispatch();

  // reducers
  const { isModalVisible, modalInfo } = useSelector(
    (state) => state.formReducer
  );
  const { isCreatingService, isUpdatingService } = useSelector(
    (state) => state.serviceReducer
  );

  const isUpdate = !_.isEmpty(modalInfo);

  // Khởi tạo dữ liêu
  const initInfo = {
    code: modalInfo?.code ?? '',
    name: modalInfo?.name ?? '',
    descriptions: modalInfo?.descriptions ?? '',
  };

  // validate dữ liêu
  const infoSchema = yup.object().shape({
    code: yup.string().required('Mã nhóm dịch vụ không được để trống!'),
    name: yup.string().required('Tên nhóm dịch vụ không được để trống!'),
    // descriptions: yup
    //   .string()
    //   .required('Mô tả nhóm dịch vụ không được để trống!'),
  });

  // Xử lý submit
  const onSubmit = (values) => {
    const body = {
      code: values.code,
      name: values.name,
      descriptions: values.descriptions,
    };

    for (const key in body) {
      if (body[key] === '') {
        delete body[key];
      }
    }

    if (isUpdate) {
      // update
      dispatch(
        serviceGroupActions.updateServiceGroup(modalInfo.id, body, {
          success: () => {
            handleGetServiceGroups();
            notify(
              notificationAlertRef,
              'success',
              'Thông báo',
              `Cập nhật nhóm dịch vụ thành công.`
            );
            handleClose();
          },
          failed: (mess) => {
            notify(
              notificationAlertRef,
              'danger',
              'Thông báo',
              `Cập nhật nhóm dịch vụ thất bại!. Lỗi: ${mess}!`
            );
          },
        })
      );
    } else {
      // create
      dispatch(
        serviceGroupActions.createServiceGroup(body, {
          success: () => {
            handleGetServiceGroups();
            notify(
              notificationAlertRef,
              'success',
              'Thông báo',
              `Thêm mới nhóm dịch vụ thành công.`
            );
            handleClose();
          },
          failed: (mess) => {
            notify(
              notificationAlertRef,
              'danger',
              'Thông báo',
              `Thêm mới nhóm dịch vụ thất bại!. Lỗi: ${mess}!`
            );
          },
        })
      );
    }
  };

  // Xử lý đóng form
  const handleClose = () => {
    dispatch(
      formActions.setValue({
        name: 'isModalVisible',
        value: false,
      })
    );
    dispatch(
      formActions.setValue({
        name: 'modalInfo',
        value: {},
      })
    );
  };

  return (
    <Modal
      size={'md'}
      isOpen={isModalVisible}
      className="modal-dialog-centered modal-secondary"
      toggle={() => {
        handleClose();
      }}
    >
      <div className="modal-header border-bottom bg-white pb-2">
        <h2>{isUpdate ? 'Cập nhật' : 'Thêm mới'} nhóm dịch vụ</h2>
        <button
          aria-label="Close"
          className="close"
          data-dismiss="modal"
          type="button"
          onClick={() => {
            handleClose();
          }}
        >
          <span style={{ color: '#262626' }} aria-hidden={true}>
            ×
          </span>
        </button>
      </div>
      <div className="modal-body bg-white rounded">
        <Formik
          initialValues={initInfo}
          // enableReinitialize
          onSubmit={onSubmit}
          validationSchema={infoSchema}
        >
          {({
            values,
            handleSubmit,
            errors,
            touched,
            handleBlur,
            handleChange,
            setFieldValue,
          }) => {
            return (
              <Form onSubmit={handleSubmit} className="">
                <Row>
                  <Col xs={6}>
                    <InputCustom
                      name="code"
                      label="Mã nhóm dịch vụ"
                      type="text"
                      placeholder="Nhập mã nhóm dịch vụ"
                      value={values.code}
                      isRequired={true}
                      handleChange={handleChange}
                      handleBlur={handleBlur}
                      error={errors?.code}
                      touched={touched?.code}
                      disabled={isUpdate}
                    />
                  </Col>
                  <Col xs={6}>
                    <InputCustom
                      name="name"
                      label="Tên nhóm dịch vụ"
                      type="text"
                      placeholder="Nhập tên nhóm dịch vụ"
                      value={values.name}
                      isRequired={true}
                      handleChange={handleChange}
                      handleBlur={handleBlur}
                      error={errors?.name}
                      touched={touched?.name}
                    />
                  </Col>
                </Row>

                <Row>
                  <Col xs={12}>
                    <InputCustom
                      name="descriptions"
                      label="Mô tả"
                      type="textarea"
                      rows={4}
                      placeholder="Nhập nội dung"
                      value={values.descriptions}
                      isRequired={false}
                      handleChange={handleChange}
                      handleBlur={handleBlur}
                      error={errors?.descriptions}
                      touched={touched?.descriptions}
                    />
                  </Col>
                </Row>

                <div className="mb-0 pb-0 mt-4 d-flex justify-content-end">
                  <Button
                    className="text-nowrap mr-1"
                    onClick={() => {
                      handleClose();
                    }}
                  >
                    Hủy
                  </Button>
                  <LoadingButtonCustom
                    onClick={handleSubmit}
                    color="info"
                    className="text-nowrap "
                    type="submit"
                    loading={isCreatingService || isUpdatingService}
                  >
                    {isUpdate ? 'Cập nhật' : 'Thêm mới'}
                  </LoadingButtonCustom>
                </div>
              </Form>
            );
          }}
        </Formik>
      </div>
    </Modal>
  );
};

export default memo(CUServiceGroup);
