import _ from 'lodash';
import { takeLatest, call, put, all } from 'redux-saga/effects';

import typeActions from 'redux/typeActions';
import ServiceURL from 'services/ServiceURL';
import { DELETE, GET, POST, PUT } from 'services/ServiceBase';
import { convertMessageError } from 'common';

export function* getPromotionDetails(data) {
  const url = `${ServiceURL.promotionDetail}?filters[$and][0][deleted][$eq]=false&sort=createdAt:DESC&${data.query}`;
  const callback = data.callback;
  try {
    const res = yield call(GET, url);
    if (!!res.data?.error?.message) {
      yield put({
        type: typeActions.GET_PROMOTION_DETAILS_FAILED,
        error: res.data?.error?.message,
      });
      !!callback?.failed && callback.failed(res.data.error.message);
    } else {
      const apiRelations =
        res.data?.results.map((item) => {
          const urlRelation = `${ServiceURL.service}?filters[$and][0][promotionDetail][deleted]=false&filters[$and][1][promotionDetail][id]=${item.id}&pageSize=99999`;
          return call(GET, urlRelation);
        }) ?? [];
      let relations = [];
      try {
        if (data.isUpdateReducer) {
          relations = yield all(apiRelations);
        }
      } catch (error) {}

      const results = _.get(res, 'data.results', []);
      const resData = {
        ...res.data,
        results: results.map((item, index) => {
          const services = _.get(relations, `[${index}.data.results]`, []);
          return { ...item, services };
        }),
      };

      if (data.isUpdateReducer) {
        yield put({
          type: typeActions.GET_PROMOTION_DETAILS_SUCCESS,
          data: resData,
        });
      }
      !!callback?.success && callback.success(resData);
    }
  } catch (error) {
    yield put({
      type: typeActions.GET_PROMOTION_DETAILS_FAILED,
      error: error?.response?.data?.error?.message ?? '',
    });
    !!callback?.failed &&
      callback.failed(error?.response?.data?.error?.message ?? '');
  }
}

export function* getPromotionDetail(data) {
  const url = `${ServiceURL.promotionDetail}/${data.param}?${data.query}`;
  const callback = data.callback;
  try {
    const res = yield call(GET, url);
    if (!!res.data?.error?.message) {
      yield put({
        type: typeActions.GET_PROMOTION_DETAIL_FAILED,
        error: res.data.error.message,
      });
      !!callback?.failed && callback.failed(res.data.error.message);
    } else {
      yield put({
        type: typeActions.GET_PROMOTION_DETAIL_SUCCESS,
        data: res.data,
      });
      !!callback?.success && callback.success(res.data);
    }
  } catch (error) {
    yield put({
      type: typeActions.GET_PROMOTION_DETAIL_FAILED,
      error: error?.response?.data?.error?.message ?? '',
    });
    !!callback?.failed &&
      callback.failed(error?.response?.data?.error?.message ?? '');
  }
}

export function* createPromotionDetail(data) {
  const url = `${ServiceURL.promotionDetail}`;
  const callback = data.callback;
  try {
    const res = yield call(POST, url, data.body);
    if (!!res.data?.error?.message) {
      yield put({
        type: typeActions.CREATE_PROMOTION_DETAIL_FAILED,
        error: res.data.error.message,
      });
      !!callback?.failed && callback.failed(res.data.error.message);
    } else {
      yield put({
        type: typeActions.CREATE_PROMOTION_DETAIL_SUCCESS,
        data: res.data?.data,
      });
      !!callback?.success && callback.success(res.data?.data);
    }
  } catch (error) {
    const mess = convertMessageError('c', error, ' chi tiết khuyến mại ');
    yield put({
      type: typeActions.CREATE_PROMOTION_DETAIL_FAILED,
      error: mess,
    });
    !!callback?.failed && callback.failed(mess);
  }
}

export function* updatePromotionDetail(data) {
  const url = `${ServiceURL.promotionDetail}/${data.param}`;
  const callback = data.callback;
  try {
    const res = yield call(PUT, url, data.body);
    if (!!res.data?.error?.message) {
      yield put({
        type: typeActions.UPDATE_PROMOTION_DETAIL_FAILED,
        error: res.data.error.message,
      });
      !!callback?.failed && callback.failed(res.data.error.message);
    } else {
      yield put({
        type: typeActions.UPDATE_PROMOTION_DETAIL_SUCCESS,
        data: res.data,
      });

      !!callback?.success && callback.success(res.data);
    }
  } catch (error) {
    yield put({
      type: typeActions.UPDATE_PROMOTION_DETAIL_FAILED,
      error: error?.response?.data?.error?.message ?? '',
    });
    !!callback?.failed &&
      callback.failed(error?.response?.data?.error?.message ?? '');
  }
}

export function* deletePromotionDetail(data) {
  const url = `${ServiceURL.promotionDetail}/${data.param}`;
  const callback = data.callback;
  try {
    const res = yield call(DELETE, url);
    if (!!res.data?.error?.message) {
      yield put({
        type: typeActions.DELETE_PROMOTION_DETAIL_FAILED,
        error: res.data.error.message,
      });
      !!callback?.failed && callback.failed(res.data.error.message);
    } else {
      yield put({
        type: typeActions.DELETE_PROMOTION_DETAIL_SUCCESS,
        data: res.data,
      });
      !!callback?.success && callback.success(res.data);
    }
  } catch (error) {
    yield put({
      type: typeActions.DELETE_PROMOTION_DETAIL_FAILED,
      error: error?.response?.data?.error?.message ?? '',
    });
    !!callback?.failed &&
      callback.failed(error?.response?.data?.error?.message ?? '');
  }
}

function* getRelation(data) {
  try {
    const callback = data.callback;
    const urlRelation = `${ServiceURL.relation}/api::promotion-detail.promotion-detail/${data.id}/${data.relationTo}?pageSize=100`;

    const response = yield call(GET, urlRelation);
    const id = _.get(response, 'data.data.id', -1);
    if (id === -1) return null;
    switch (data.relationTo) {
      case 'promotion':
        const urlPromotion = `${ServiceURL.promotion}/${id}`;
        const res = yield call(GET, urlPromotion);
        !!callback?.success && callback.success(res.data);
        break;
      default:
        return null;
    }
  } catch (error) {}
}

export default function* promotionDetailSaga() {
  yield takeLatest(
    typeActions.GET_PROMOTION_DETAILS_REQUEST,
    getPromotionDetails
  );
  yield takeLatest(
    typeActions.GET_PROMOTION_DETAIL_REQUEST,
    getPromotionDetail
  );
  yield takeLatest(
    typeActions.CREATE_PROMOTION_DETAIL_REQUEST,
    createPromotionDetail
  );
  yield takeLatest(
    typeActions.UPDATE_PROMOTION_DETAIL_REQUEST,
    updatePromotionDetail
  );
  yield takeLatest(
    typeActions.DELETE_PROMOTION_DETAIL_REQUEST,
    deletePromotionDetail
  );
  yield takeLatest(
    typeActions.GET_PROMOTION_DETAIL_RELATION_REQUEST,
    getRelation
  );
}
