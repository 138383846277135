import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import {
  Card,
  CardBody,
  CardTitle,
  Container,
  Row,
  Col,
  Input,
} from 'reactstrap';
import moment from 'moment';
import queryString from 'query-string';
import LabelCustom from 'views/pages/components/LabelCustom';
import IconPcd from 'assets/svgs/dashboard_pcd.svg';
import { useDispatch, useSelector } from 'react-redux';
import { statisticActions } from 'redux/actions';
import { currencyFormat } from 'common';
import SelectTime from './SelectTime';
import { useHistory } from 'react-router';
function SatisticDoctor({}) {
  const history = useHistory();
  const dispatch = useDispatch();
  const { areasByAccountExtend } = useSelector((state) => state.areaReducer);
  const { statistic } = useSelector((state) => state.statisticReducer);
  const { currentAccountExtend } = useSelector((state) => state.accountReducer);
  const [time, setTime] = useState(0);

  useEffect(() => {
    handleGetSatistic();
  }, [time, currentAccountExtend?.id]);

  const handleGetSatistic = () => {
    const query = {
      type: 'doctor',
      'filters[$and][3][specifyVote][idBS][$eq]': currentAccountExtend?.id,
    };

    switch (time) {
      case 0:
        query['filters[$and][0][createdAt][$gte]'] = moment()
          .startOf('date')
          .toISOString();
        query['filters[$and][1][createdAt][$lte]'] = moment()
          .endOf('date')
          .toISOString();
        break;
      case 1:
        query['filters[$and][0][createdAt][$gte]'] = moment()
          .startOf('week')
          .toISOString();
        query['filters[$and][1][createdAt][$lte]'] = moment()
          .endOf('week')
          .toISOString();
        break;
      case 2:
        query['filters[$and][0][createdAt][$gte]'] = moment()
          .startOf('month')
          .toISOString();
        query['filters[$and][1][createdAt][$lte]'] = moment()
          .endOf('month')
          .toISOString();
        break;
      case 3:
        query['filters[$and][0][createdAt][$gte]'] = moment()
          .startOf('year')
          .toISOString();
        query['filters[$and][1][createdAt][$lte]'] = moment()
          .endOf('year')
          .toISOString();
        break;
    }

    // areasByAccountExtend.forEach((item, index) => {
    //   query[`filters[$and][4][$or][${index}][areaName][$eq]`] = item.name;
    // });
    dispatch(statisticActions.getStatistic(queryString.stringify(query)));
  };

  return (
    <>
      <div className="header mt--9 pb-6">
        <Container fluid>
          <div className="header-body">
            <Row>
              <Col sm={12} md={8}>
                <Card className="card-stats position-relative">
                  <div className="p-3 position-absolute right-0">
                    <SelectTime time={time} setTime={setTime} />
                  </div>
                  <CardBody
                    className="d-flex justify-content-center align-items-center"
                    style={{ minHeight: 257 }}
                  >
                    <div
                      style={{}}
                      className="col d-flex flex-column justify-content-center align-items-center"
                    >
                      <CardTitle
                        tag="h1"
                        className="text-uppercase text-muted mb-0"
                      >
                        Tổng số
                      </CardTitle>
                      <span className="h1 font-weight-bold mb-0">
                        {currencyFormat(statistic?.orderTotal ?? 0)}
                      </span>
                    </div>
                  </CardBody>
                </Card>
              </Col>
              <Col sm={12} md={4}>
                <Card className="card-stats">
                  <CardBody>
                    <Row>
                      <div className="col">
                        <div className="pt-2">
                          <Row>
                            <div className="col">
                              <CardTitle
                                tag="h5"
                                className="text-uppercase text-muted mb-4"
                              >
                                Số phiếu xét nghiệm đã gửi
                              </CardTitle>

                              <span className="h2 font-weight-bold mb-0">
                                {currencyFormat(statistic?.sendOrder ?? 0)}
                              </span>
                            </div>
                          </Row>
                        </div>
                      </div>
                      <Col className="col-auto d-flex flex-column justify-content-between align-items-center">
                        <div className="icon icon-shape bg-gradient-info text-white rounded-circle shadow">
                          <img style={{ zoom: 0.8 }} src={IconPcd} />
                        </div>
                        <span
                          onClick={() => {
                            history.push('/admin/specify-vote-all');
                          }}
                          style={{
                            lineHeight: 1.5,
                            color: '#0a66b2',
                            cursor: 'pointer',
                          }}
                          className="text-nowrap text-underline"
                        >
                          Chi tiết {'>>'}
                        </span>
                      </Col>
                    </Row>
                  </CardBody>
                </Card>
                <Card className="card-stats">
                  <CardBody>
                    <Row>
                      <div className="col">
                        <div className="pt-2">
                          <Row>
                            <div className="col">
                              <CardTitle
                                tag="h5"
                                className="text-uppercase text-muted mb-4"
                              >
                                Số phiếu xét nghiệm đã có kết quả
                              </CardTitle>

                              <span className="h2 font-weight-bold mb-0">
                                {currencyFormat(statistic?.payResultOrder ?? 0)}
                              </span>
                            </div>
                          </Row>
                        </div>
                      </div>
                      <Col className="col-auto d-flex flex-column justify-content-between align-items-center">
                        <div className="icon icon-shape bg-gradient-success text-white rounded-circle shadow">
                          <img style={{ zoom: 0.8 }} src={IconPcd} />
                        </div>
                        <span
                          onClick={() => {
                            history.push('/admin/specify-vote-all');
                          }}
                          style={{
                            lineHeight: 1.5,
                            color: '#0a66b2',
                            cursor: 'pointer',
                          }}
                          className="text-nowrap text-underline"
                        >
                          Chi tiết {'>>'}
                        </span>
                      </Col>
                    </Row>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </div>
        </Container>
      </div>
    </>
  );
}

SatisticDoctor.propTypes = {
  name: PropTypes.string,
  parentName: PropTypes.string,
};

export default SatisticDoctor;
