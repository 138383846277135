import React, { memo, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  Button,
  Col,
  Form,
  FormGroup,
  Input,
  Modal,
  Row,
  Table,
} from 'reactstrap';
import * as yup from 'yup';
import { Formik } from 'formik';
import _ from 'lodash';
import queryString from 'query-string';
import classNames from 'classnames';

import { currencyFormat } from 'common';
import LabelCustom from 'views/pages/components/LabelCustom';
import { billActions, formActions } from 'redux/actions';
import CONSTANT from 'constant';
import LabelValue from 'views/pages/components/LabelValue';
import moment from 'moment';
import { BASE_URL_IMAGE } from 'services/ServiceURL';

const DPayment = ({}) => {
  const dispatch = useDispatch();

  // reducers
  const { isModalDVisible, modalInfo } = useSelector(
    (state) => state.formReducer
  );

  const [paymentHistories, setPaymentHistories] = useState([]);

  const paid = paymentHistories.reduce(
    (preV, curV) => preV + Number(curV?.amountMoney ?? 0),
    0
  );

  // Xử lý đóng form
  const handleClose = () => {
    setPaymentHistories([]);
    dispatch(
      formActions.setValue({
        name: 'isModalDVisible',
        value: false,
      })
    );
    dispatch(
      formActions.setValue({
        name: 'modalInfo',
        value: {},
      })
    );
  };

  useEffect(() => {
    if (!modalInfo?.bill?.id) return;
    handleGetRelation(modalInfo.bill.id);
  }, [modalInfo]);

  const handleGetRelation = (id) => {
    dispatch(
      billActions.getBillRelation(id, 'paymentHistories', {
        success: (data) => {
          setPaymentHistories(data);
        },
        failed: () => {},
      })
    );
  };
  let image = `${BASE_URL_IMAGE}${modalInfo?.imagePayment?.url}`;
  if (!modalInfo?.imagePayment?.url)
    image = require('assets/img/default/pcd_default.png').default;
  return (
    <Modal
      size={'xl'}
      isOpen={isModalDVisible}
      className="modal-dialog-centered modal-secondary"
      toggle={() => {
        handleClose();
      }}
    >
      <div className="modal-header border-bottom bg-white pb-2">
        <h2>Xem chi tiết đơn hàng chưa thanh toán</h2>
        <button
          aria-label="Close"
          className="close"
          data-dismiss="modal"
          type="button"
          onClick={() => {
            handleClose();
          }}
        >
          <span style={{ color: '#262626' }} aria-hidden={true}>
            ×
          </span>
        </button>
      </div>
      <div className="modal-body bg-white rounded">
        <p
          className="text-uppercase"
          style={{
            fontSize: 14,
            fontWeight: '400',
            color: 'rgba(140, 151, 174, 1)',
          }}
        >
          Thông tin đơn hàng
        </p>
        <Row>
          <Col xs={8}>
            <Row>
              <Col xs={6}>
                <LabelValue
                  label={'Mã đơn hàng'}
                  value={modalInfo?.code ?? ''}
                />
              </Col>
              <Col xs={6}>
                <LabelValue
                  label={'Dịch vụ'}
                  value={modalInfo?.specifyVote?.nameTest ?? ''}
                  labelWidth={170}
                />
              </Col>
            </Row>
            <Row>
              <Col xs={6}>
                <LabelValue
                  label={'Tên đơn hàng'}
                  value={modalInfo?.name ?? ''}
                />
              </Col>
              <Col xs={6}>
                <LabelValue
                  label={'Số tiền phải thanh toán'}
                  value={`${currencyFormat(
                    Number(modalInfo?.bill?.totalMoney ?? 0) -
                      Number(modalInfo?.bill?.reduce ?? 0)
                  )} VNĐ`}
                  labelWidth={170}
                />
              </Col>
            </Row>
            <Row>
              <Col xs={6}>
                <LabelValue
                  label={'Khách hàng'}
                  value={modalInfo?.organization?.name ?? ''}
                />
              </Col>
            </Row>
          </Col>
          <Col xs={4}>
            <LabelValue
              label="Hình ảnh chuyển khoản"
              value={''}
              labelWidth={180}
            />
            <img
              alt="..."
              className="shadow"
              src={image}
              style={{ width: '100%', height: 'auto', borderRadius: 4 }}
            />
          </Col>
        </Row>

        <p
          className="text-uppercase"
          style={{
            fontSize: 14,
            fontWeight: '400',
            color: 'rgba(140, 151, 174, 1)',
          }}
        >
          Lịch sử giao dịch
        </p>
        <Table>
          <thead>
            <th
              className="h3 font-weight-600 p-2"
              style={{
                paddingRight: 16,
                minWidth: 50,
                color: 'rgba(136, 152, 170, 1)',
              }}
            ></th>
            <th
              style={{ minWidth: 80, color: 'rgba(136, 152, 170, 1)' }}
              className="h3 font-weight-600 p-2 text-start"
            >
              HÌNH THỨC THANH TOÁN
            </th>
            <th
              style={{ color: 'rgba(136, 152, 170, 1)' }}
              className="h3 font-weight-600 p-2 text-center"
            >
              NGÀY NHẬN TIỀN
            </th>
            <th
              style={{ color: 'rgba(136, 152, 170, 1)' }}
              className="h3 font-weight-600 p-2 text-right"
            >
              TÀI KHOẢN NHẬN
            </th>
            <th
              style={{ color: 'rgba(136, 152, 170, 1)' }}
              className="h3 font-weight-600 p-2 text-center"
            >
              NGƯỜI NỘP
            </th>
            <th
              style={{ color: 'rgba(136, 152, 170, 1)' }}
              className="h3 font-weight-600 p-2 text-right"
            >
              SỐ TIỀN NHẬN
            </th>
          </thead>
          <tbody>
            {paymentHistories.map((item, index) => {
              const paymentMethodValue = CONSTANT.PAYMENT_METHOD.find(
                (pm) => pm.value === item.paymentMethod
              ) ?? { label: '' };
              const payerValue = CONSTANT.SUBMITTER.find(
                (s) => s.value === item.payer
              ) ?? { label: '' };
              return (
                <tr key={index}>
                  <td className="p-2"></td>
                  <td style={{ fontWeight: '900' }} className="p-2 text-start">
                    {paymentMethodValue?.label}
                  </td>
                  <td className="p-2 text-center">
                    {moment(item.createdAt).format('DD/MM/YYYY')}
                  </td>
                  <td className="p-2 text-right">{item?.bankAccount ?? ''}</td>
                  <td className="p-2 text-center">{payerValue?.label ?? ''}</td>
                  <td className="p-2 text-right">
                    {currencyFormat(Number(item?.amountMoney ?? 0))} VNĐ
                  </td>
                </tr>
              );
            })}
          </tbody>
          <thead>
            <th
              className="h3 font-weight-600 p-2"
              style={{ paddingRight: 16, minWidth: 50 }}
            ></th>
            <th
              style={{ minWidth: 80 }}
              className="h3 font-weight-600 p-2 text-start"
            ></th>
            <th className="h3 font-weight-600 p-2 text-center"></th>
            <th className="h3 font-weight-600 p-2 text-right">TỔNG TIỀN</th>
            <th className="h3 font-weight-600 p-2 text-center"></th>
            <th
              style={{ fontSize: 14 }}
              className="font-weight-600 p-2 text-right"
            >
              {currencyFormat(paid)} VNĐ
            </th>
          </thead>
        </Table>
        <div className="mt-2 d-flex justify-content-end">
          <Button
            className="text-nowrap "
            onClick={() => {
              handleClose();
            }}
          >
            Đóng
          </Button>
        </div>
      </div>
    </Modal>
  );
};

export default memo(DPayment);
