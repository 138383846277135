import { all, fork } from 'redux-saga/effects';

import accountSaga from './accountSaga';
import notificationSaga from './notificationSaga';
import roleSaga from './roleSaga';
import uploadSaga from './uploadSaga';
import areaSaga from './areaSaga';
import barcodeSaga from './barcodeSaga';
import organizationSaga from './organizationSaga';
import staffSaga from './staffSaga';
import serviceSaga from './serviceSaga';
import serviceGroupSaga from './serviceGroupSaga';
import promotionSaga from './promotionSaga';
import partnerSaga from './partnerSaga';
import provinceSaga from './provinceSaga';
import specifyVoteSaga from './specifyVoteSaga';
import promotionDetailSaga from './promotionDetailSaga';
import patientSaga from './patientSaga';
import relationSaga from './relationSaga';
import orderSaga from './orderSaga';
import medicalRecordSaga from './medicalRecordSaga';
import paymentHistorySaga from './paymentHistorySaga';
import billSaga from './billSaga';
import resultSaga from './resultSaga';
import convertFileSaga from './convertFileSaga';
import templatepcdsSaga from './templatePCDSaga';
import serviceDetailSaga from './serviceDetailSaga';
import authorSaga from './authorSaga';
import statisticSaga from './statisticSaga';
import roleUISaga from './roleUISaga';
import additionalSampleSaga from './additionalSampleSaga';

export function* rootSagas() {
  yield all([fork(accountSaga)]);
  yield all([fork(roleSaga)]);
  yield all([fork(notificationSaga)]);
  yield all([fork(uploadSaga)]);
  yield all([fork(areaSaga)]);
  yield all([fork(barcodeSaga)]);
  yield all([fork(organizationSaga)]);
  yield all([fork(staffSaga)]);
  yield all([fork(serviceSaga)]);
  yield all([fork(serviceGroupSaga)]);
  yield all([fork(promotionSaga)]);
  yield all([fork(partnerSaga)]);
  yield all([fork(provinceSaga)]);
  yield all([fork(specifyVoteSaga)]);
  yield all([fork(promotionDetailSaga)]);
  yield all([fork(patientSaga)]);
  yield all([fork(relationSaga)]);
  yield all([fork(orderSaga)]);
  yield all([fork(medicalRecordSaga)]);
  yield all([fork(billSaga)]);
  yield all([fork(paymentHistorySaga)]);
  yield all([fork(resultSaga)]);
  yield all([fork(convertFileSaga)]);
  yield all([fork(templatepcdsSaga)]);
  yield all([fork(serviceDetailSaga)]);
  yield all([fork(authorSaga)]);
  yield all([fork(statisticSaga)]);
  yield all([fork(statisticSaga)]);
  yield all([fork(roleUISaga)]);
  yield all([fork(additionalSampleSaga)]);
}
export default rootSagas;
