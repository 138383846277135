import typeActions from 'redux/typeActions';

const setValue = ({ name, value }) => {
  return {
    type: typeActions.SET_BILL_VALUE,
    name,
    value,
  };
};

const getBillRelation = (id, relationTo, callback) => {
  return {
    type: typeActions.GET_BILL_RELATION_REQUEST,
    id,
    relationTo,
    callback,
  };
};

const getBills = (query, callback, isUpdateReducer = true) => {
  return {
    type: typeActions.GET_BILLS_REQUEST,
    query,
    callback,
    isUpdateReducer,
  };
};

const getBill = (param, query, callback) => {
  return {
    type: typeActions.GET_BILL_REQUEST,
    param,
    query,
    callback,
  };
};

const createBill = (body, callback) => {
  return {
    type: typeActions.CREATE_BILL_REQUEST,
    body,
    callback,
  };
};
const updateBill = (param, body, callback) => {
  return {
    type: typeActions.UPDATE_BILL_REQUEST,
    param,
    body,
    callback,
  };
};
const deleteBill = (param, callback) => {
  return {
    type: typeActions.DELETE_BILL_REQUEST,
    param,
    callback,
  };
};

export default {
  setValue,
  getBills,
  getBill,
  createBill,
  updateBill,
  deleteBill,
  getBillRelation,
};
