import React, { memo, useEffect, useState } from 'react';
import _ from 'lodash';
import {
  Badge,
  Card,
  CardBody,
  CardHeader,
  Col,
  Container,
  Input,
  Row,
  UncontrolledTooltip,
} from 'reactstrap';
import FileDownload from 'js-file-download';

import { notificationAlertRef, notify } from 'common';
import { convertFileActions } from 'redux/actions';
import SimpleHeader from 'components/Headers/SimpleHeader.js';
import 'assets/css/custom-pages/react-bs-table.css';
import { useDispatch, useSelector } from 'react-redux';
import CONSTANT from 'constant';
import LabelCustom from '../components/LabelCustom';
import SelectCustom from '../components/SelectCustom';
import LoadingButtonCustom from '../components/LoadingButtonCustom';

function ConvertFile() {
  const dispatch = useDispatch();
  const { isConverting } = useSelector((state) => state.convertFileReducer);

  const [filePdfs, setFilePdfs] = useState([]);
  const [testTypeValue, setTestTypeValue] = useState(CONSTANT.TEST_TYPE[1]);

  const handleConvertFile = () => {
    if (filePdfs.length === 0) return;
    dispatch(
      convertFileActions.convertFilePdf(filePdfs, {
        success: (data) => {
          FileDownload(data, `Ket_qua_xet_nghiem_${new Date().getTime()}.xlsx`);
          notify(
            notificationAlertRef,
            'success',
            'Thông báo',
            `Xuất danh sách thành công!`
          );
        },
        failed: (mess) => {
          notify(
            notificationAlertRef,
            'danger',
            'Thông báo',
            `Cập nhật kết quả xét nghiệm thất bại. Lỗi: ${mess}!`
          );
        },
      })
    );
  };

  // const handleDownload = () => {
  //   FileDownload(data, 'Ket_qua_xet_nghiem.xlsx');
  //   notify(
  //     notificationAlertRef,
  //     'success',
  //     'Thông báo',
  //     `Xuất danh sách thành công!`
  //   );
  // };

  return (
    <>
      <SimpleHeader />
      <Container className="mt--9" fluid>
        <Card>
          <CardHeader className="pb-2 d-flex justify-content-between rounded border-bottom">
            <h3>Cập nhật kết quả xét nghiệm</h3>
          </CardHeader>
          <CardBody>
            <Row>
              <Col xs={7}>
                <SelectCustom
                  name="testType"
                  label="Chọn loại xét nghiệm"
                  placeholder="Lựa chọn"
                  value={testTypeValue}
                  isRequired={false}
                  isClearable={false}
                  handleChange={(e) => {
                    if (e?.value === 5) {
                      setTestTypeValue(e);
                    } else {
                      notify(
                        notificationAlertRef,
                        'warning',
                        'Thông báo',
                        `Hiện tại phần mềm chỉ đang hỗ trợ NIPT 5. Vui lòng quay lại sau!`
                      );
                    }
                  }}
                  options={CONSTANT.TEST_TYPE}
                  row
                  labelWidth={220}
                />
              </Col>
            </Row>
            <Row>
              <Col xs={12} md={12} className="d-flex">
                <LabelCustom
                  isRequired={false}
                  label={'Chọn một hoặc nhiều tập tin'}
                  styleLabel={{ minWidth: 220 }}
                  style={{
                    textAlign: 'end',
                    margin: '0.5em 8px 0px 0px',
                  }}
                />
                <div className="ml-3">
                  <input
                    style={{ fontSize: 12 }}
                    type="file"
                    multiple
                    accept=".pdf"
                    onChange={async (e) => {
                      try {
                        if (_.isEmpty(e)) return;
                        const files = await Promise.all([...e.target.files]);
                        setFilePdfs(files.map((file) => Object.assign(file)));
                      } catch (error) {
                        //
                      }
                    }}
                  />
                </div>
              </Col>
            </Row>
            <Row>
              <Col xs={12}>
                <div style={{ marginLeft: 220 }}>
                  <LoadingButtonCustom
                    onClick={handleConvertFile}
                    color="info"
                    className="text-nowrap ml-3"
                    type="submit"
                    loading={isConverting}
                  >
                    Xử lý tập tin & Tải xuống
                  </LoadingButtonCustom>
                </div>
              </Col>
            </Row>
            {/* {data !== null && (
              <Row>
                <Col xs={12} md={12} className="d-flex">
                  <LabelCustom
                    isRequired={false}
                    label={'Tải kết quả'}
                    styleLabel={{ minWidth: 220 }}
                    style={{
                      textAlign: 'end',
                      margin: '0.5em 8px 0px 0px',
                    }}
                  />
                  <div className="ml-3">
                    <LoadingButtonCustom
                      onClick={handleDownload}
                      color="info"
                      className="text-nowrap "
                      type="submit"
                      loading={false}
                    >
                      Tải xuống
                    </LoadingButtonCustom>
                  </div>
                </Col>
              </Row>
            )} */}
          </CardBody>
        </Card>
      </Container>
    </>
  );
}

export default memo(ConvertFile);
