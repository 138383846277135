import React from 'react';
import _ from 'lodash';
import { CardHeader, Col, Row } from 'reactstrap';

import 'assets/css/custom-pages/react-bs-table.css';
import InputCustom from 'views/pages/components/InputCustom';
import { useFormikContext } from 'formik';

const KQInfo = ({ isDisabled }) => {
  const { values, errors, touched, handleBlur, handleChange } =
    useFormikContext();
  return (
    <>
      <Row>
        <CardHeader className="pb-2 d-flex justify-content-between rounded border-bottom w-100">
          <h3>Kết quả xét nghiệm di truyền</h3>
        </CardHeader>
      </Row>
      <Row>
        <Col xs={12}>
          <InputCustom
            name="specifyVote.result1"
            label="Bản thân"
            type="text"
            placeholder="Nhập kết quả xét nghiệm bản thân"
            value={values.specifyVote.result1}
            isRequired={false}
            handleChange={handleChange}
            handleBlur={handleBlur}
            error={errors?.specifyVote?.result1}
            touched={touched?.specifyVote?.result1}
            disabled={isDisabled}
            row
            labelWidth={160}
          />
        </Col>
      </Row>
      <Row>
        <Col xs={12}>
          <InputCustom
            name="specifyVote.result2"
            label="Người thân"
            type="text"
            placeholder="Nhập kết quả xét nghiệm người thân"
            value={values.specifyVote.result2}
            isRequired={false}
            handleChange={handleChange}
            handleBlur={handleBlur}
            error={errors?.specifyVote?.result2}
            touched={touched?.specifyVote?.result2}
            disabled={isDisabled}
            row
            labelWidth={160}
          />
        </Col>
      </Row>
    </>
  );
};

export default KQInfo;
