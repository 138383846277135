import { useState } from 'react';
import { useDispatch } from 'react-redux';
import { specifyVotesActions } from 'redux/actions';

const _ = require('lodash');
export const randomIDSpecifyVotes = (testValue) => {
  const date = new Date();
  const r = _.random(0, 10000);
  let group = 'S'; //Nhóm sản
  if (testValue == 1) {
    group = 'P'; //Nhóm phôi
  } else if (testValue == 2) {
    group = 'B'; // Nhóm bệnh lý
  }
  const codePCD = `${group}${date.getFullYear().toString().slice(-2)}${String(
    r
  ).padStart(4, '0')}`;

  return codePCD;
};

export const initInfoCreate = {
  codePCD: '',
  testGRTemplate: '',
  //patient info
  codeBN: '',
  nameBN: '',
  dateOfBirthBN: '',
  genderBN: '',
  emailBN: '',
  phoneBN: '',
  jobBN: '',
  nameContact: '',
  phoneContact: '',
  addressBN: '',
  codeTest: '',
  nameTest: '',
  soPhoi: '',
  //service info - organization info
  content: '',
  nameTC: '',
  idBS: '',
  lastNameBS: '',
  firstNameBS: '',
  nameBS: '',
  specimens: '[]',
  numOfEmbryos: '',
  whereTM: '',
  dateTM: new Date().toISOString(),
  hourTM: new Date().toISOString(),
  codeCTKM: '',
  codePromotion: '',
  form: '',
  valuePromotion: '',
  promotionDetailStartDate: '',
  promotionDetailEndDate: '',
  promotionStatus: '',
  promotionDeleted: '',
  unitPrice: '',
  medicationsUsing: [],
  //test group info
  height: '',
  weight: '',
  anamnesisBA: '',
  familyHistory: '',
  lifeStyle: '',
  pathological1: '',
  anamnesis: '',
  time: '',
  result1: '',
  result2: '',
  //-Nhom San
  weekPregnancy: '',
  height1: '',
  weight1: '',
  typePregnancy: '',
  nappySkin: '',
  result3: '',
  result4: '',
  //nhom phoi
  biopsyPerson: '',
  biopsyDay: new Date().toISOString(),
  cellSolution: '',
  numOfEmbryos1: '',
  numOfEmbryos2: '',
  numOfEmbryos3: '',
  numOfEmbryos4: '',
  noPhoi: '',
  embryoState: '',
  embryoEvaluation: '',
  cellNucleus: true,
  negativeControl: '',
  //nhom benh ly
  symptom: '',
  diagnose: '',
  imageAnalysation: '',
  test: '',
  treatment: '',
  timeTreatment: '',
  drugResistance: '',
  diseaseRecurrence: '',
  timeTest: 0,
};
/**
 *
 * @param {Object} prePatient
 * @param {Object} nextPatient
 * @returns Trả về object nết patient mới. true: khác nhau, false: giống nhau
 */
export default function checkPatientInfomationChange(prePatient, nextPatient) {
  //Khác mã thì tạo mới
  if (!_.isEqual(prePatient.code, nextPatient.code)) {
    return nextPatient;
  }
  //Cùng mã khác thuộc tính còn lại thì cập nhật hoặc không.
  return !_.isEqual(prePatient, nextPatient);
}
