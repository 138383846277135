import React, { useState } from "react";
import ReactDatePicker from "react-datepicker";
import {
  Button,
  Card,
  CardBody,
  CardHeader,
  Col,
  FormGroup,
  Row,
} from "reactstrap";
import "assets/css/custom-pages/specify-votes.css";
import "assets/css/custom-pages/create-specify-votes.css";
import CONSTANT from "constant";
import _ from "lodash";
import LabelValue from "views/pages/components/LabelValue";
import moment from "moment";
function NXN({ specifyVotes }) {
  //Nhóm phôi
  if (parseInt(specifyVotes?.testGR ?? "") === 1) {
    return (
      <div className="custom-row">
        <Row className="custom-row">
          <Col xs={3}>
            <LabelValue
              lableTextAlign={"left"}
              label={"Người sinh thiết"}
              value={specifyVotes?.biopsyPerson ?? ""}
              row={true}
            />
          </Col>
          <Col xs={3}>
            <LabelValue
              lableTextAlign={"left"}
              label={"Ngày sinh thiết"}
              value={moment(
                new Date(specifyVotes?.biopsyDay ?? new Date())
              ).format("DD/MM/YYYY")}
              row={true}
            />
          </Col>
          <Col xs={3}>
            <LabelValue
              lableTextAlign={"left"}
              label={"Dung dịch chứa tế bào"}
              value={specifyVotes?.cellSolution ?? ""}
              row={true}
            />
          </Col>
        </Row>
        <Row>
          <Col xs={3}>
            <LabelValue
              lableTextAlign={"left"}
              label={"Số phôi tạo ra"}
              value={specifyVotes?.numOfEmbryos1 ?? ""}
              row={true}
            />
          </Col>
          <Col xs={3}>
            <LabelValue
              lableTextAlign={"left"}
              label={"Trạng thái phôi"}
              value={specifyVotes?.embryoState ?? ""}
              row={true}
            />
          </Col>
        </Row>
        <Row>
          <Col xs={3}>
            <LabelValue
              lableTextAlign={"left"}
              label={"Đánh giá hình thái phôi"}
              value={specifyVotes?.embryoEvaluation ?? ""}
              row={true}
            />
          </Col>
          <Col xs={3}>
            <LabelValue
              lableTextAlign={"left"}
              label={"Số thứ tự"}
              value={specifyVotes?.noPhoi ?? ""}
              row={true}
            />
          </Col>
        </Row>
        <Row>
          <Col xs={12}>
            <LabelValue
              lableTextAlign={"left"}
              label={"Nhân tế bào"}
              value={specifyVotes?.cellNucleus ? "Có" : "Không"}
              row={true}
            />
          </Col>
        </Row>
        <Row>
          <Col xs={12}>
            <LabelValue
              lableTextAlign={"left"}
              label={"Đối chứng âm"}
              value={specifyVotes?.negativeControl ?? ""}
              row={true}
            />
          </Col>
        </Row>
      </div>
    );
  }
  // Nhóm sản
  if (parseInt(specifyVotes?.testGR ?? "") === 2) {
    return (
      <div className="custom-row">
        <Row className="custom-row">
          <Col xs={12}>
            <LabelValue
              lableTextAlign={"left"}
              label={"Triệu chứng"}
              labelWidth={175}
              value={specifyVotes?.symptom ?? ""}
              row={true}
            />
          </Col>
        </Row>
        <Row>
          <Col xs={6}>
            <LabelValue
              lableTextAlign={"left"}
              label={"Chẩn đoán"}
              value={specifyVotes?.diagnose ?? ""}
              row={true}
            />
          </Col>
          <Col xs={6}>
            <LabelValue
              lableTextAlign={"left"}
              label={"Chẩn đoán hình ảnh"}
              value={specifyVotes?.imageAnalysation ?? ""}
              row={true}
            />
          </Col>
        </Row>
        <Row>
          <Col xs={12}>
            <LabelValue
              lableTextAlign={"left"}
              labelWidth={175}
              label={"Xét nghiệm liên quan"}
              value={specifyVotes?.test ?? ""}
              row={true}
            />
          </Col>
        </Row>
        <Row>
          <Col xs={12}>
            <LabelValue
              lableTextAlign={"left"}
              labelWidth={175}
              label={"Cách thức điều trị"}
              value={specifyVotes?.treatment ?? ""}
              row={true}
            />
          </Col>
        </Row>
        <Row>
          <Col xs={12}>
            <LabelValue
              lableTextAlign={"left"}
              label={"Thời gian điều trị(ngày)"}
              labelWidth={175}
              value={specifyVotes?.timeTreatment}
              row={true}
            />
          </Col>
        </Row>
        <Row>
          <Col xs={6}>
            <LabelValue
              lableTextAlign={"left"}
              labelWidth={175}
              label={"Dấu hiệu kháng thuốc"}
              value={specifyVotes?.drugResistance ?? ""}
              row={true}
            />
          </Col>
        </Row>
        <Row>
          <Col xs={12}>
            <LabelValue
              lableTextAlign={"left"}
              labelWidth={175}
              label={"Tái phát bệnh"}
              value={specifyVotes?.diseaseRecurrence ?? ""}
              row={true}
            />
          </Col>
        </Row>
      </div>
    );
  }
  // Nhóm bệnh lý
  return (
    <div className="custom-row">
      <Row>
        <Col xs={3}>
          <Row>
            <Col xs={6}>
              <LabelValue
                lableTextAlign={"left"}
                label={"Tuần thai"}
                value={
                  specifyVotes?.weekPregnancy > -1
                    ? specifyVotes?.weekPregnancy
                    : ""
                }
                labelWidth={80}
                row={true}
              />
            </Col>
            <Col xs={6}>
              <LabelValue
                lableTextAlign={"left"}
                label={"Ngày thai"}
                value={
                  specifyVotes?.dayOfPregnancy > -1
                    ? specifyVotes?.dayOfPregnancy
                    : ""
                }
                labelWidth={80}
                row={true}
              />
            </Col>
          </Row>
        </Col>
        <Col xs={3}>
          <Row>
            <Col xs={7}>
              <LabelValue
                lableTextAlign={"left"}
                label={"Ngày siêu âm"}
                labelWidth={100}
                value={
                  !!specifyVotes?.ultrasoundDays
                    ? moment(specifyVotes?.ultrasoundDays).format("DD/MM/YYYY")
                    : ""
                }
                row={true}
              />
            </Col>
          </Row>
        </Col>
        <Col xs={3}>
          <LabelValue
            lableTextAlign={"left"}
            label={"Số lượng thai"}
            labelWidth={100}
            value={
              CONSTANT.SPECIFY_VOTES_TYPE_PREGNANCY.find((_) => {
                return _.value === specifyVotes?.typePregnancy;
              })?.label ?? ""
            }
            row={true}
          />
        </Col>
      </Row>
      <Row>
        <Col xs={3}>
          <LabelValue
            lableTextAlign={"left"}
            label={"Chiều dài đầu mông (mm)"}
            value={
              specifyVotes?.height1 > -1
                ? specifyVotes?.height1
                : CONSTANT.DEFAULT_EMPTY_STRING
            }
            labelWidth={200}
            row={true}
          />
        </Col>
        <Col xs={3}>
          <LabelValue
            lableTextAlign={"left"}
            label={"Độ mờ da gáy"}
            value={specifyVotes?.nappySkin > -1 ? specifyVotes?.nappySkin : ""}
            row={true}
          />
        </Col>
      </Row>
      <Row>
        <Col xs={12}>
          <LabelValue
            lableTextAlign={"left"}
            label={"Kết quả nguy cơ của combined test"}
            value={specifyVotes?.result3 ?? ""}
            row={true}
          />
        </Col>
      </Row>
      <Row>
        <Col xs={12}>
          <LabelValue
            lableTextAlign={"left"}
            label={"Kết quả siêu âm"}
            value={specifyVotes?.result4 ?? ""}
            row={true}
          />
        </Col>
      </Row>
    </div>
  );
}
function ReadTestGroupInformation({ testValue, setTestValue, specifyVotes }) {
  return (
    <Card>
      <CardHeader>
        <h3 className="mb-0">Thông tin nhóm xét nghiệm</h3>
      </CardHeader>
      <CardBody>
        <Row>
          <Col xs={12}>
            <NXN testValue={testValue} specifyVotes={specifyVotes} />
          </Col>
        </Row>
      </CardBody>
    </Card>
  );
}

export default ReadTestGroupInformation;
