import React, { memo, useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  Button,
  Card,
  CardBody,
  CardFooter,
  CardHeader,
  Col,
  Form,
  Input,
  Modal,
  Row,
} from 'reactstrap';
import * as yup from 'yup';
import { Formik } from 'formik';
import _ from 'lodash';

import CONSTANT from 'constant';
import { notificationAlertRef, notify, relationOneToMany } from 'common';
import LabelCustom from 'views/pages/components/LabelCustom';
import { accountActions, formActions, partnerActions } from 'redux/actions';
import HelperText from 'views/pages/components/HelperText';
import queryString from 'query-string';
import LoadingButtonCustom from 'views/pages/components/LoadingButtonCustom';
import SelectCustom from 'views/pages/components/SelectCustom';
import LabelValue from 'views/pages/components/LabelValue';

const CUPartner = () => {
  //redux
  const dispatch = useDispatch();
  const { page, search, limit, query } = useSelector(
    (state) => state.areaReducer
  );

  const { isModalVisible, modalInfo } = useSelector(
    (state) => state.formReducer
  );

  const [accountSearch, setAccountSearch] = useState('');
  const [accountsValue, setAccountsValue] = useState(null);
  const [accountResults, setAccountResults] = useState([]);
  const [accountValuesOld, setAccountValuesOld] = useState([]);
  //type form
  const isUpdate = !_.isEmpty(modalInfo);
  const { isView } = modalInfo;

  const handleClose = () => {
    dispatch(
      formActions.setValue({
        name: 'isModalVisible',
        value: false,
      })
    );
    dispatch(
      formActions.setValue({
        name: 'modalInfo',
        value: {},
      })
    );
  };
  // get data select-options
  function callListPartner() {
    dispatch(
      partnerActions.getPartners(
        queryString.stringify({
          ...query,
          page: page,
          pageSize: limit,
          _q: search,
        }),
        {
          success: (data) => {},
          failed: () => {},
        },
        true
      )
    );
  }

  const handleGetAccountBS = () => {
    const query = {
      'filters[$and][1][accountType][$eq]': CONSTANT.ACCOUNT_TYPE[4].value,
      'filters[$and][2][isActive][$eq]': true,
    };

    if (accountSearch !== '') {
      query[`filters[$and][3][fullname][$containsi]`] = accountSearch;
    }

    dispatch(
      accountActions.getAccountsExtend(
        queryString.stringify(query),
        {
          success: (data) => {
            const results = _.get(data, 'results', []);
            setAccountResults(results);
          },
          failed: () => {},
        },
        false
      )
    );
  };

  useEffect(() => {
    const delayDebounceFn = setTimeout(() => {
      handleGetAccountBS();
    }, 500);

    return () => clearTimeout(delayDebounceFn);
  }, [accountSearch]);

  const getRelationPartners = () => {
    dispatch(
      accountActions.getAccountExtendsByPartner(modalInfo.id, {
        success: (data) => {
          setAccountsValue(
            data.map((item) => ({
              label: `${item?.fullname ?? ''}`,
              value: item.id,
              ...item,
            }))
          );
          const ids = data.map((item) => item.id);
          setAccountValuesOld(ids);
        },
        failed: () => {},
      })
    );
  };

  useEffect(() => {
    if (isUpdate) {
      getRelationPartners();
    } else {
      setAccountsValue(null);
    }
  }, [isUpdate, modalInfo]);

  const initInfo = isUpdate
    ? {
        code: modalInfo?.code ?? '',
        name: modalInfo?.name ?? '',
        nameRepresentative: modalInfo?.nameRepresentative ?? '',
        phoneRepresentative: modalInfo?.phoneRepresentative ?? '',
        email: modalInfo?.email ?? '',
        phone: modalInfo?.phone ?? '',
        address: modalInfo?.address ?? '',
        nameContact: modalInfo?.nameContact ?? '',
        phoneContact: modalInfo?.phoneContact ?? '',
        emailContact: modalInfo?.emailContact ?? '',
      }
    : {
        code: '',
        name: '',
        nameRepresentative: '',
        phoneRepresentative: '',
        email: '',
        phone: '',
        address: '',
        nameContact: '',
        phoneContact: '',
        emailContact: '',
      };
  const partnerSchema = yup.object().shape({
    code: yup.string().required('Mã đối tác không được để trống!'),
    name: yup.string().required('Tên đối tác không được để trống!'),
    email: yup
      .string()
      .required('Email đối tác không được để trống!')
      .email('Email không đúng định dạng!'),
    emailContact: yup.string().email('Email không đúng định dạng!'),
    email: yup
      .string()
      .required('Email đối tác không được để trống!')
      .email('Email không đúng định dạng!'),
    phone: yup
      .string()
      .required('Số điện thoại đối tác không được để trống!')
      // .length(10, 'Số điện thoại bao gồm 10 số!')
      .matches('0[1-9][0-9]+', 'Vui lòng nhập đúng định dạng số điện thoại!'),
    phoneContact: yup
      .string()
      .length(10, 'Số điện thoại bao gồm 10 số!')
      .matches('0[1-9][0-9]{8}', 'Vui lòng nhập đúng định dạng số điện thoại!'),
    phoneRepresentative: yup
      .string()
      .length(10, 'Số điện thoại bao gồm 10 số!')
      .matches('0[1-9][0-9]{8}', 'Vui lòng nhập đúng định dạng số điện thoại!'),
    address: yup.string().required('Địa chỉ đối tác không được để trống!'),
  });
  const styleValueIsView = {
    textAlign: 'left',
    // fontSize: 13,
    fontWeight: 300,
    color: '#32325D',
  };
  return (
    <Modal
      isOpen={isModalVisible}
      className="modal-dialog-centered"
      toggle={() => {
        handleClose();
      }}
      size={isView ? 'xl' : 'lg'}
    >
      <Card className="mb-0">
        {isView ? (
          <>
            <CardHeader className="d-flex justify-content-between py-3">
              <h2 className="mb-0">Xem thông tin đối tác xét nghiệm</h2>
              <button
                aria-label="Close"
                className="close"
                data-dismiss="modal"
                type="button"
                onClick={() => {
                  handleClose();
                }}
              >
                <span aria-hidden={true}>×</span>
              </button>
            </CardHeader>
            <CardBody className="pb-0">
              <Row>
                <Col xs={6} className="border-right">
                  <Row>
                    <Col xs={12}>
                      <LabelCustom
                        label="Đối tác xét nghiệm"
                        id="code"
                        isDisplayLine={true}
                      />
                    </Col>
                  </Row>
                  <Row>
                    <Col xs={6}>
                      <LabelValue
                        labelWidth={120}
                        label="Mã đối tác"
                        value={modalInfo.code}
                      />
                    </Col>
                    <Col xs={6}>
                      <LabelValue
                        labelWidth={120}
                        label="Tên đối tác"
                        value={modalInfo.name}
                      />
                    </Col>
                  </Row>
                  <Row>
                    <Col xs={6}>
                      <LabelValue
                        labelWidth={120}
                        label="Email"
                        value={modalInfo.email}
                      />
                    </Col>
                    <Col xs={6}>
                      <LabelValue
                        labelWidth={120}
                        label="Số điện thoại"
                        value={modalInfo.phone}
                      />
                    </Col>
                  </Row>
                  <Row>
                    <Col xs={12}>
                      <LabelValue
                        labelWidth={120}
                        label="Địa chỉ"
                        value={modalInfo.address}
                      />
                    </Col>
                  </Row>
                  <Row>
                    <Col xs={12}>
                      <LabelValue
                        labelWidth={120}
                        label="Bác sĩ chỉ định"
                        value={(accountsValue ?? []).map((acc) => acc.label)}
                      />
                    </Col>
                  </Row>
                </Col>
                <Col xs={6}>
                  <Row>
                    <Col xs={12}>
                      <LabelCustom
                        label="Người đại diện"
                        id="code"
                        isDisplayLine={true}
                      />
                    </Col>
                  </Row>
                  <Row>
                    <Col xs={6}>
                      <LabelValue
                        labelWidth={120}
                        label="Họ tên"
                        value={modalInfo?.nameRepresentative ?? ''}
                      />
                    </Col>
                    <Col xs={6}>
                      <LabelValue
                        labelWidth={120}
                        label="Số điện thoại"
                        value={modalInfo?.phoneRepresentative ?? ''}
                      />
                    </Col>
                  </Row>
                  <Row>
                    <Col xs={12}>
                      <LabelCustom
                        label="Người liên hệ"
                        id="code"
                        isDisplayLine={true}
                      />
                    </Col>
                  </Row>
                  <Row>
                    <Col xs={6}>
                      <LabelValue
                        labelWidth={120}
                        label="Họ tên"
                        value={modalInfo?.nameContact ?? ''}
                      />
                    </Col>
                    <Col xs={6}>
                      <LabelValue
                        labelWidth={120}
                        label="Số điện thoại"
                        value={modalInfo?.phoneContact ?? ''}
                      />
                    </Col>
                  </Row>
                  <Row>
                    <Col xs={12}>
                      <LabelValue
                        labelWidth={120}
                        label="Email"
                        value={modalInfo?.emailContact ?? ''}
                      />
                    </Col>
                  </Row>
                </Col>
              </Row>
            </CardBody>
            <CardFooter className="border-0 pt-0">
              <Row className="d-flex justify-content-end">
                <Button
                  onClick={() => {
                    handleClose();
                  }}
                  className="mr-4"
                  style={{
                    boxShadow: 'none',
                    border: 'none',
                    backgroundColor: 'transparent',
                    color: '#7A7A7A',
                  }}
                >
                  Đóng
                </Button>
              </Row>
            </CardFooter>
          </>
        ) : (
          <Formik
            initialValues={initInfo}
            enableReinitialize
            onSubmit={(values) => {
              // create or update
              const body = _.cloneDeep({
                ...values,
                doctorPrescribes: relationOneToMany(
                  (accountsValue ?? [])
                    .map((item) => item.value)
                    .filter((item) => !(accountValuesOld ?? []).includes(item)),
                  (accountValuesOld ?? []).filter(
                    (item) =>
                      !(accountsValue ?? [])
                        .map((item) => item.value)
                        .includes(item)
                  )
                ),
              });
              if (body.emailContact === '') {
                body.emailContact = null;
              }

              if (!isUpdate) {
                dispatch(
                  partnerActions.createPartner(body, {
                    success: (data) => {
                      notify(
                        notificationAlertRef,
                        'success',
                        '',
                        `Thêm đối tác ${data.name} thành công !`
                      );
                      dispatch(
                        formActions.setValue({
                          name: 'isModalVisible',
                          value: false,
                        })
                      );
                      callListPartner();
                    },
                    failed: (error) => {
                      notify(
                        notificationAlertRef,
                        'danger',
                        'Xảy ra lỗi tạo mới đối tác!',
                        `Lỗi: ${error}`
                      );
                    },
                  })
                );
              } else {
                dispatch(
                  partnerActions.updatePartner(modalInfo.id, body, {
                    success: (data) => {
                      notify(
                        notificationAlertRef,
                        'success',
                        '',
                        `Cập nhật đối tác ${data?.name ?? ''} thành công !`
                      );
                      dispatch(
                        formActions.setValue({
                          name: 'isModalVisible',
                          value: false,
                        })
                      );
                      callListPartner();
                    },
                    failed: (error) => {
                      notify(
                        notificationAlertRef,
                        'danger',
                        'Xảy ra lỗi cập nhật đối tác!',
                        `Lỗi: ${error}`
                      );
                    },
                  })
                );
              }
            }}
            validationSchema={partnerSchema}
          >
            {({
              values,
              handleSubmit,
              errors,
              touched,
              handleBlur,
              setFieldValue,
              handleChange,
            }) => {
              return (
                <Form
                  onSubmit={() => {
                    handleSubmit();
                  }}
                  className=" form-add-account"
                >
                  <Row>
                    <Col md={12} lg={12}>
                      <CardHeader className="py-2 d-flex justify-content-between">
                        <h2>
                          {isView
                            ? 'Xem thông tin'
                            : isUpdate
                            ? 'Sửa thông tin'
                            : 'Thêm mới'}{' '}
                          đối tác xét nghiệm
                        </h2>
                        <button
                          aria-label="Close"
                          className="close"
                          data-dismiss="modal"
                          type="button"
                          onClick={() => {
                            handleClose();
                          }}
                        >
                          <span aria-hidden={true}>×</span>
                        </button>
                      </CardHeader>
                    </Col>
                  </Row>

                  <CardBody className="py-2">
                    <Row>
                      <Col xs={6} className="border-right">
                        <Row>
                          <Col xs={12}>
                            <LabelCustom
                              label="Đối tác xét nghiệm"
                              id="code"
                              isDisplayLine={true}
                            />
                          </Col>
                        </Row>
                        <Row>
                          <Col xs={6}>
                            <LabelCustom
                              isRequired={true}
                              label="Mã đối tác"
                              id="code"
                            />
                            <Input
                              id="code"
                              placeholder="Nhập mã"
                              type="text"
                              className="height-input"
                              name="code"
                              value={values.code}
                              onChange={handleChange}
                              onBlur={handleBlur}
                              disabled={isUpdate}
                              invalid={!!errors?.code && !!touched?.code}
                            />
                            {!!errors.code && !!touched.code && (
                              <HelperText message={errors.code} />
                            )}
                          </Col>
                          <Col xs={6}>
                            <LabelCustom
                              isRequired={true}
                              style={{ marginTop: 8 }}
                              label="Tên đối tác"
                              id="name"
                            />
                            <Input
                              disabled={isView}
                              id="name"
                              placeholder="Nhập tên"
                              type="text"
                              className="height-input"
                              name="name"
                              value={values.name}
                              onChange={handleChange}
                              onBlur={handleBlur}
                              invalid={!!errors?.name && !!touched?.name}
                            />
                            {!!errors.name && !!touched.name && (
                              <HelperText message={errors.name} />
                            )}
                          </Col>
                        </Row>
                        <Row>
                          <Col xs={6}>
                            <LabelCustom
                              isRequired={true}
                              style={{ marginTop: 8 }}
                              label="Email"
                              id="email"
                            />
                            <Input
                              disabled={isView}
                              id="name"
                              placeholder="Nhập email"
                              type="text"
                              className="height-input"
                              name="email"
                              value={values.email}
                              onChange={handleChange}
                              onBlur={handleBlur}
                              invalid={!!errors?.email && !!touched?.email}
                            />
                            {!!errors.email && !!touched.email && (
                              <HelperText message={errors.email} />
                            )}
                          </Col>
                          <Col xs={6}>
                            <LabelCustom
                              isRequired={true}
                              style={{ marginTop: 8 }}
                              label="Số điện thoại"
                              id="phone"
                            />
                            <Input
                              disabled={isView}
                              id="phone"
                              placeholder="Nhập số điện thoại"
                              type="text"
                              className="height-input"
                              name="phone"
                              value={values.phone}
                              onChange={handleChange}
                              onBlur={handleBlur}
                              invalid={!!errors?.phone && !!touched?.phone}
                            />
                            {!!errors.phone && !!touched.phone && (
                              <HelperText message={errors.phone} />
                            )}
                          </Col>
                        </Row>
                        <Row>
                          <Col xs={12}>
                            <LabelCustom
                              style={{ marginTop: 8 }}
                              label="Địa chỉ"
                              isRequired={true}
                              id="address"
                            />
                            <Input
                              disabled={isView}
                              id="address"
                              placeholder="Nhập địa chỉ"
                              type="textarea"
                              className="height-input"
                              name="address"
                              style={{
                                minHeight: 100,
                              }}
                              value={values.address}
                              onChange={handleChange}
                              onBlur={handleBlur}
                              invalid={!!errors?.address && !!touched?.address}
                            />
                            {!!errors.address && !!touched.address && (
                              <HelperText message={errors.address} />
                            )}
                          </Col>
                        </Row>
                        <Row>
                          <Col xs={12}>
                            <SelectCustom
                              id={'doctorPrescribes'}
                              name={'doctorPrescribes'}
                              label="Bác sĩ chỉ định"
                              isRequired
                              // lableTextAlign="left"
                              // labelWidth={150}
                              // row={true}
                              placeholder="Lựa chọn"
                              options={accountResults.map((item) => ({
                                ...item,
                                value: item.id,
                                label: `${item?.fullname ?? ''}`,
                              }))}
                              value={accountsValue}
                              handleChange={(e, remove) => {
                                if (_.isEmpty(remove?.removedValue)) {
                                  setAccountsValue(e);
                                  // setFieldValue(
                                  //   'doctorPrescribes',
                                  //   e.map((item) => item.value)
                                  // );
                                } else {
                                  const tmp = accountsValue.filter(
                                    (item) =>
                                      item.value !== remove.removedValue.value
                                  );
                                  setAccountsValue(tmp);

                                  // const doctorPrescribes =
                                  //   values.doctorPrescribes.filter(
                                  //     (item) =>
                                  //       item !== remove.removedValue.value
                                  //   );
                                  // setFieldValue(
                                  //   'doctorPrescribes',
                                  //   doctorPrescribes
                                  // );
                                }
                              }}
                              isMulti={true}
                              onInputChange={(value) => {
                                setAccountSearch(value);
                              }}
                              handleBlur={handleBlur}
                              error={errors.doctorPrescribes}
                              touched={touched.doctorPrescribes}
                            />
                          </Col>
                        </Row>
                      </Col>
                      <Col xs={6}>
                        <Row>
                          <Col xs={12}>
                            <LabelCustom
                              label="Người đại diện"
                              id="code"
                              isDisplayLine={true}
                            />
                          </Col>
                        </Row>
                        <Row>
                          <Col xs={6}>
                            <LabelCustom
                              label="Họ tên"
                              id="nameRepresentative"
                            />
                            <Input
                              id="nameRepresentative"
                              placeholder="Nhập họ tên "
                              type="text"
                              className="height-input"
                              name="nameRepresentative"
                              value={values.nameRepresentative}
                              onChange={handleChange}
                              onBlur={handleBlur}
                              invalid={
                                !!errors?.nameRepresentative &&
                                !!touched?.nameRepresentative
                              }
                            />
                            {!!errors.nameRepresentative &&
                              !!touched.nameRepresentative && (
                                <HelperText
                                  message={errors.nameRepresentative}
                                />
                              )}
                          </Col>
                          <Col xs={6}>
                            <LabelCustom
                              style={{ marginTop: 8 }}
                              label="Số điện thoại"
                              id="phoneRepresentative"
                            />
                            <Input
                              disabled={isView}
                              id="phoneRepresentative"
                              placeholder="Nhập số điện thoại"
                              type="text"
                              className="height-input"
                              name="phoneRepresentative"
                              value={values.phoneRepresentative}
                              onChange={handleChange}
                              onBlur={handleBlur}
                              invalid={
                                !!errors?.phoneRepresentative &&
                                !!touched?.phoneRepresentative
                              }
                            />
                            {!!errors.phoneRepresentative &&
                              !!touched.phoneRepresentative && (
                                <HelperText
                                  message={errors.phoneRepresentative}
                                />
                              )}
                          </Col>
                        </Row>
                        <Row>
                          <Col xs={12}>
                            <LabelCustom
                              label="Người liên hệ"
                              id="code"
                              isDisplayLine={true}
                            />
                          </Col>
                        </Row>
                        <Row>
                          <Col xs={6}>
                            <LabelCustom label="Họ tên" id="nameContact" />
                            <Input
                              id="nameContact"
                              placeholder="Nhập họ tên"
                              type="text"
                              className="height-input"
                              name="nameContact"
                              value={values.nameContact}
                              onChange={handleChange}
                              onBlur={handleBlur}
                              invalid={
                                !!errors?.nameContact && !!touched?.nameContact
                              }
                            />
                            {!!errors.nameContact && !!touched.nameContact && (
                              <HelperText message={errors.nameContact} />
                            )}
                          </Col>
                          <Col xs={6}>
                            <LabelCustom
                              style={{ marginTop: 8 }}
                              label="Số điện thoại"
                              id="phoneContact"
                            />
                            <Input
                              disabled={isView}
                              id="phoneContact"
                              placeholder="Nhập số điện thoại"
                              type="text"
                              className="height-input"
                              name="phoneContact"
                              value={values.phoneContact}
                              onChange={handleChange}
                              onBlur={handleBlur}
                              invalid={
                                !!errors?.phoneContact &&
                                !!touched?.phoneContact
                              }
                            />
                            {!!errors.phoneContact &&
                              !!touched.phoneContact && (
                                <HelperText message={errors.phoneContact} />
                              )}
                          </Col>
                        </Row>
                        <Row>
                          <Col xs={12}>
                            <LabelCustom
                              style={{ marginTop: 8 }}
                              label="Email"
                              id="emailContact"
                            />
                            <Input
                              disabled={isView}
                              id="emailContact"
                              placeholder="Nhập email"
                              type="email"
                              className="height-input"
                              name="emailContact"
                              value={values.emailContact}
                              onChange={handleChange}
                              onBlur={handleBlur}
                              invalid={
                                !!errors?.emailContact &&
                                !!touched?.emailContact
                              }
                            />
                            {!!errors.emailContact &&
                              !!touched.emailContact && (
                                <HelperText message={errors.emailContact} />
                              )}
                          </Col>
                        </Row>
                      </Col>
                    </Row>
                  </CardBody>
                  <CardFooter className="border-0 pt-0">
                    <div className="mb-0 pb-0 mt-1 d-flex justify-content-end">
                      <Button
                        className="text-nowrap mr-1"
                        style={{
                          boxShadow: 'none',
                          backgroundColor: 'transparent',
                          borderWidth: 0,
                        }}
                        onClick={() => {
                          handleClose();
                        }}
                      >
                        Hủy
                      </Button>
                      <LoadingButtonCustom
                        onClick={handleSubmit}
                        color="info"
                        className="text-nowrap "
                        type="submit"
                        // loading={
                        //   isCreateRole || isUpdateRole || isUpdatePermission
                        // }
                      >
                        {isUpdate ? 'Lưu lại' : 'Thêm mới'}
                      </LoadingButtonCustom>
                    </div>
                  </CardFooter>
                </Form>
              );
            }}
          </Formik>
        )}
      </Card>
    </Modal>
  );
};

export default memo(CUPartner);
