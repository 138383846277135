import React, { memo, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Button, Col, Form, Input, Modal, Row } from 'reactstrap';
import * as yup from 'yup';
import { Formik } from 'formik';
import _ from 'lodash';

import { notificationAlertRef, notify } from 'common';
import { formActions, additionalSampleActions } from 'redux/actions';
import LoadingButtonCustom from 'views/pages/components/LoadingButtonCustom';
import CONSTANT from 'constant';
import InputCustom from 'views/pages/components/InputCustom';
import SelectCustom from 'views/pages/components/SelectCustom';

const UpdateStatusXN = ({ handleGetAdditionalSamples }) => {
  const dispatch = useDispatch();

  // reducers
  const { isModalVisible, modalInfo } = useSelector(
    (state) => state.formReducer
  );
  const { isUpdatingAdditionalSample } = useSelector(
    (state) => state.additionalSampleReducer
  );
  const [conclusionFormValue, setConclusionFormValue] = useState(null);
  // Khởi tạo dữ liêu
  const initInfo = {
    status: CONSTANT.ORDER_STATUS[7].value,
    note: '',
  };

  const [statusValue, setStatusValue] = useState(CONSTANT.ORDER_STATUS[7]);

  // validate dữ liêu
  const infoSchema = yup.object().shape({
    status: yup
      .number()
      .min(0, 'Trạng thái không được để trống')
      .required('Trạng thái không được để trống!')
      .nullable(),
    // note: yup
    //   .number()
    //   .min(0, 'Đối tác xét nghiệm không được để trống')
    //   .required('Đối tác xét nghiệm không được để trống!')
    //   .nullable(),
  });

  // Xử lý submit
  const onSubmit = (values) => {
    const body = {
      status: values.status,
      note: values.note,
      ids: modalInfo.ids.slice(1),
    };
    dispatch(
      additionalSampleActions.updateAdditionalSample(modalInfo.ids[0], body, {
        success: () => {
          notify(
            notificationAlertRef,
            'success',
            'Thông báo',
            `Cập nhật thành công!`
          );
          handleGetAdditionalSamples();
          handleClose();
        },
        failed: (mess) => {
          notify(
            notificationAlertRef,
            'danger',
            'Thông báo',
            `Cập nhật thất bại. Lỗi: ${mess}!`
          );
        },
      })
    );
  };

  // Xử lý đóng form
  const handleClose = () => {
    setStatusValue(null);
    dispatch(
      formActions.setValue({
        name: 'isModalVisible',
        value: false,
      })
    );
    dispatch(
      formActions.setValue({
        name: 'modalInfo',
        value: {},
      })
    );
  };

  return (
    <Modal
      size={'md'}
      isOpen={isModalVisible}
      className="modal-dialog-centered modal-secondary"
      toggle={() => {
        handleClose();
      }}
    >
      <div className="modal-header badditionalSample-bottom bg-white pb-2">
        <h2>Cập nhật trạng thái đang xét nghiệm</h2>
        <button
          aria-label="Close"
          className="close"
          data-dismiss="modal"
          type="button"
          onClick={() => {
            handleClose();
          }}
        >
          <span style={{ color: '#262626' }} aria-hidden={true}>
            ×
          </span>
        </button>
      </div>
      <div className="modal-body bg-white rounded">
        <Formik
          initialValues={initInfo}
          enableReinitialize
          onSubmit={onSubmit}
          validationSchema={infoSchema}
        >
          {({
            errors,
            touched,
            handleSubmit,
            handleBlur,
            setFieldValue,
            values,
          }) => {
            return (
              <Form onSubmit={handleSubmit} className="">
                <Row>
                  <Col xs={6}>
                    <SelectCustom
                      name="status"
                      label="Trạng thái"
                      placeholder="Lựa chọn"
                      value={statusValue}
                      isRequired={true}
                      isClearable={false}
                      handleChange={(e) => {
                        setFieldValue('status', e?.value ?? null);
                        setStatusValue(e);
                      }}
                      handleBlur={handleBlur}
                      onInputChange={(___) => {}}
                      error={errors.status}
                      touched={touched.status}
                      options={[
                        CONSTANT.ORDER_STATUS[7],
                        CONSTANT.ORDER_STATUS[8],
                      ]}
                    />
                  </Col>
                </Row>
                <Row>
                  <Col xs={12}>
                    <SelectCustom
                      name="conclusionForm"
                      label="Mẫu ghi chú luận"
                      placeholder="Lựa chọn"
                      value={conclusionFormValue}
                      isRequired={false}
                      isClearable={false}
                      handleChange={(e) => {
                        setFieldValue('note', e.label);
                        setConclusionFormValue(e);
                      }}
                      options={CONSTANT.CONCLUSION_FORMS}
                    />
                  </Col>
                </Row>
                <Row>
                  <Col xs={12}>
                    <InputCustom
                      name="note"
                      label="Ghi chú"
                      type="textarea"
                      placeholder="Nhập"
                      value={values.note}
                      isRequired={false}
                      handleChange={(e) => {
                        setFieldValue('note', e.target.value);
                      }}
                      handleBlur={handleBlur}
                      error={errors?.note}
                      touched={touched?.note}
                    />
                  </Col>
                </Row>
                <div className="mb-0 pb-0 mt-4 d-flex justify-content-end">
                  <Button
                    className="text-nowrap mr-1"
                    onClick={() => {
                      handleClose();
                    }}
                  >
                    Hủy
                  </Button>
                  <LoadingButtonCustom
                    color="info"
                    className="text-nowrap "
                    type="submit"
                    loading={isUpdatingAdditionalSample}
                  >
                    Xác nhận
                  </LoadingButtonCustom>
                </div>
              </Form>
            );
          }}
        </Formik>
      </div>
    </Modal>
  );
};

export default memo(UpdateStatusXN);
