//core-componens
import React, { useEffect, useState } from 'react';
import { Button, Card, CardBody, CardHeader, Col, Container } from 'reactstrap';
import SimpleHeader from 'components/Headers/SimpleHeader.js';
//custom components
//module
import * as yup from 'yup';
import _ from 'lodash';
import { notificationAlertRef, notify } from 'common';
//styles
import 'assets/css/custom-pages/specify-votes.css';
import 'assets/css/custom-pages/create-specify-votes.css';
import { useDispatch, useSelector } from 'react-redux';
import { patientActions } from 'redux/actions';
import GeneralInfomation from './components/ReadGeneralInfomation';
import { useHistory, useLocation, useParams } from 'react-router';
import ListService from './components/ReadListService';
function PatientView() {
  const history = useHistory();
  const param = useParams();

  const [patient, setPatient] = useState(null);
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(
      patientActions.getPatientById(param.id, 'populate=*', {
        success: (patient) => {
          setPatient(patient);
        },
        failed: (message) => {
          notify(
            notificationAlertRef,
            'danger',
            'Thông báo',
            `Lỗi: ${message}`
          );
          history.push('/admin/specify-votes');
        },
      })
    );
  }, []);
  return (
    <>
      <SimpleHeader />
      <Container className="mt--9 custom-specify-votes " fluid>
        <GeneralInfomation patient={patient} />
        <ListService patient={patient} />
      </Container>
    </>
  );
}

export default PatientView;
