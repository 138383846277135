import React from 'react';
import _ from 'lodash';
import { Input } from 'reactstrap';
import { isNumeric } from 'common';

const RowTable = ({ item, idxR, handleUpdateResultDetail }) => {
  return (
    <tr
      style={{
        background:
          item?.value > item?.abnormal ? 'rgba(192, 6, 6, 0.1)' : 'transparent',
      }}
    >
      <td className="p-2">{item?.content ?? ''}</td>
      <td className="p-2 d-flex align-items-center">
        <Input
          type={'number'}
          className="height-input mr-2"
          style={{ maxWidth: 100 }}
          value={item?.value}
          onChange={(e) => {
            if (!isNumeric(e.target.value)) return;
            handleUpdateResultDetail('value', e.target.value, idxR);
          }}
          placeholder="Nhập"
        />
        {item?.normal !== '' && item?.abnormal !== '' && item?.suspect !== ''
          ? ''
          : '%'}
      </td>
      {/* <td style={{ color: '#037FBE' }} className="p-2 text-center">
        &lt; {item?.normal ?? ''}
      </td>
      <td style={{ color: '#FB6340' }} className="p-2 text-center">
        {item?.suspect ?? ''}
      </td>
      <td style={{ color: '#EC0C38' }} className="p-2 text-center">
        &gt;{item?.abnormal ?? ''}
      </td> */}
      <td className="p-2">
        {item?.normal !== '' &&
          item?.abnormal !== '' &&
          item?.suspect !== '' && (
            <Input
              type="text"
              className="height-input"
              value={item?.result}
              onChange={(e) => {
                handleUpdateResultDetail('result', e.target.value, idxR);
              }}
              placeholder="Nhập"
            />
          )}
      </td>
    </tr>
  );
};

export default RowTable;
