import typeActions from 'redux/typeActions';
import CONSTANT from 'constant';
import { returnValue } from 'common';

const initialState = {
  // loading
  isGettingResults: false,
  isGettingResult: false,
  isCreatingResult: false,
  isUpdatingResult: false,
  isDeletingResult: false,
  // data
  results: {},
  result: {},
  // error
  errors: {
    getResults: '',
    getResult: '',
    createResult: '',
    updateResult: '',
    deleteResult: '',
  },
  // query
  search: '',
  page: 1,
  limit: 10,
  startDate: null,
  endDate: null,
};

const resultReducer = (state = initialState, actions) => {
  switch (actions.type) {
    case typeActions.SET_RESULT_VALUE:
      const { name, value } = actions;
      if (typeof name !== 'string') return { ...state };
      const obj = returnValue(state, name, value);

      return {
        ...state,
        ...obj,
      };

    case typeActions.GET_RESULTS_REQUEST:
      return {
        ...state,
        isGettingResults: true,
        errors: {
          ...state.errors,
          getResults: '',
        },
      };
    case typeActions.GET_RESULTS_SUCCESS:
      return {
        ...state,
        results: actions.data ?? {},
        isGettingResults: false,
        errors: {
          ...state.errors,
          getResults: '',
        },
      };
    case typeActions.GET_RESULTS_FAILED:
      return {
        ...state,
        results: {},
        isGettingResults: false,
        errors: {
          ...state.errors,
          getResults: actions.error,
        },
      };

    case typeActions.GET_RESULT_REQUEST:
      return {
        ...state,
        isGettingResult: true,
        errors: {
          ...state.errors,
          getResult: '',
        },
      };
    case typeActions.GET_RESULT_SUCCESS:
      return {
        ...state,
        result: actions.data || {},
        isGettingResult: false,
        errors: {
          ...state.errors,
          getResult: '',
        },
      };
    case typeActions.GET_RESULT_FAILED:
      return {
        ...state,
        result: {},
        isGettingResult: false,
        errors: {
          ...state.errors,
          getResult: actions.error,
        },
      };

    case typeActions.CREATE_RESULT_REQUEST:
      return {
        ...state,
        isCreatingResult: true,
        errors: {
          ...state.errors,
          createResult: '',
        },
      };
    case typeActions.CREATE_RESULT_SUCCESS:
      return {
        ...state,
        isCreatingResult: false,
        errors: {
          ...state.errors,
          createResult: '',
        },
      };
    case typeActions.CREATE_RESULT_FAILED:
      return {
        ...state,
        isCreatingResult: false,
        errors: {
          ...state.errors,
          createResult: actions.error,
        },
      };

    case typeActions.UPDATE_RESULT_REQUEST:
      return {
        ...state,
        isUpdatingResult: true,
        errors: {
          ...state.errors,
          updateResult: '',
        },
      };
    case typeActions.UPDATE_RESULT_SUCCESS:
      return {
        ...state,
        isUpdatingResult: false,
        errors: {
          ...state.errors,
          updateResult: '',
        },
      };
    case typeActions.UPDATE_RESULT_FAILED:
      return {
        ...state,
        isUpdatingResult: false,
        errors: {
          ...state.errors,
          updateResult: actions.error,
        },
      };

    case typeActions.DELETE_RESULT_REQUEST:
      return {
        ...state,
        isDeletingResult: true,
        errors: {
          ...state.errors,
          deleteResult: '',
        },
      };
    case typeActions.DELETE_RESULT_SUCCESS:
      return {
        ...state,
        isDeletingResult: false,
        errors: {
          ...state.errors,
          deleteResult: '',
        },
      };
    case typeActions.DELETE_RESULT_FAILED:
      return {
        ...state,
        isDeletingResult: false,
        errors: {
          ...state.errors,
          deleteResult: actions.error,
        },
      };

    default:
      return {
        ...state,
      };
  }
};
export default resultReducer;
