import typeActions from 'redux/typeActions';
import CONSTANT from 'constant';
import { returnValue } from 'common';

const initialState = {
  // loading
  isGettingPromotions: false,
  isGettingPromotion: false,
  isCreatingPromotion: false,
  isUpdatingPromotion: false,
  isDeletingPromotion: false,
  isExportingPromotion: false,
  // data
  promotions: {},
  promotion: {},
  // error
  errors: {
    getPromotions: '',
    getPromotion: '',
    createPromotion: '',
    updatePromotion: '',
    deletePromotion: '',
  },
  // query
  search: '',
  page: 1,
  limit: 10,
  sort: 'createdAt:asc',
  startDate: null,
  endDate: null,
};

const promotionReducer = (state = initialState, actions) => {
  switch (actions.type) {
    case typeActions.SET_PROMOTION_VALUE:
      const { name, value } = actions;
      if (typeof name !== 'string') return { ...state };
      const obj = returnValue(state, name, value);

      return {
        ...state,
        ...obj,
      };

    case typeActions.GET_PROMOTIONS_REQUEST:
      return {
        ...state,
        isGettingPromotions: true,
        errors: {
          ...state.errors,
          getPromotions: '',
        },
      };
    case typeActions.GET_PROMOTIONS_SUCCESS:
      return {
        ...state,
        promotions: actions.data ?? {},
        isGettingPromotions: false,
        errors: {
          ...state.errors,
          getPromotions: '',
        },
      };
    case typeActions.GET_PROMOTIONS_FAILED:
      return {
        ...state,
        promotions: {},
        isGettingPromotions: false,
        errors: {
          ...state.errors,
          getPromotions: actions.error,
        },
      };

    case typeActions.GET_PROMOTION_REQUEST:
      return {
        ...state,
        isGettingPromotion: true,
        errors: {
          ...state.errors,
          getPromotion: '',
        },
      };
    case typeActions.GET_PROMOTION_SUCCESS:
      return {
        ...state,
        promotion: actions.data || {},
        isGettingPromotion: false,
        errors: {
          ...state.errors,
          getPromotion: '',
        },
      };
    case typeActions.GET_PROMOTION_FAILED:
      return {
        ...state,
        promotion: {},
        isGettingPromotion: false,
        errors: {
          ...state.errors,
          getPromotion: actions.error,
        },
      };

    case typeActions.CREATE_PROMOTION_REQUEST:
      return {
        ...state,
        isCreatingPromotion: true,
        errors: {
          ...state.errors,
          createPromotion: '',
        },
      };
    case typeActions.CREATE_PROMOTION_SUCCESS:
      return {
        ...state,
        isCreatingPromotion: false,
        errors: {
          ...state.errors,
          createPromotion: '',
        },
      };
    case typeActions.CREATE_PROMOTION_FAILED:
      return {
        ...state,
        isCreatingPromotion: false,
        errors: {
          ...state.errors,
          createPromotion: actions.error,
        },
      };

    case typeActions.UPDATE_PROMOTION_REQUEST:
      return {
        ...state,
        isUpdatingPromotion: true,
        errors: {
          ...state.errors,
          updatePromotion: '',
        },
      };
    case typeActions.UPDATE_PROMOTION_SUCCESS:
      return {
        ...state,
        isUpdatingPromotion: false,
        errors: {
          ...state.errors,
          updatePromotion: '',
        },
      };
    case typeActions.UPDATE_PROMOTION_FAILED:
      return {
        ...state,
        isUpdatingPromotion: false,
        errors: {
          ...state.errors,
          updatePromotion: actions.error,
        },
      };

    case typeActions.DELETE_PROMOTION_REQUEST:
      return {
        ...state,
        isDeletingPromotion: true,
        errors: {
          ...state.errors,
          deletePromotion: '',
        },
      };
    case typeActions.DELETE_PROMOTION_SUCCESS:
      return {
        ...state,
        isDeletingPromotion: false,
        errors: {
          ...state.errors,
          deletePromotion: '',
        },
      };
    case typeActions.DELETE_PROMOTION_FAILED:
      return {
        ...state,
        isDeletingPromotion: false,
        errors: {
          ...state.errors,
          deletePromotion: actions.error,
        },
      };

    case typeActions.EXPORT_PROMOTION_REQUEST:
      return {
        ...state,
        isExportingPromotion: true,
      };
    case typeActions.EXPORT_PROMOTION_SUCCESS:
      return {
        ...state,
        isExportingPromotion: false,
      };
    case typeActions.EXPORT_PROMOTION_FAILED:
      return {
        ...state,
        isExportingPromotion: false,
      };

    default:
      return {
        ...state,
      };
  }
};
export default promotionReducer;
