import React, { memo, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Button, Modal } from 'reactstrap';
import _ from 'lodash';

import { currencyFormat, notificationAlertRef, notify } from 'common';
import { formActions, orderActions } from 'redux/actions';
import LoadingButtonCustom from 'views/pages/components/LoadingButtonCustom';
import CONSTANT from 'constant';
import TableCustom from 'views/pages/components/TableCustom';

import paginationFactory from 'react-bootstrap-table2-paginator';

const UApprove = ({ data, setData, handleGetOrders }) => {
  const dispatch = useDispatch();

  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(10);
  const { isModalApproveVisible } = useSelector((state) => state.formReducer);

  const [rowsSelected, setRowSelected] = useState([]);

  const total = data.length;

  const selectRow = {
    mode: 'checkbox',
    selectColumnPosition: 'left',
    onSelect: (row, isSelect) => {
      try {
        if (isSelect) {
          setRowSelected((prev) => _.uniqBy([...prev, row], 'id'));
        } else {
          const tmp = rowsSelected.filter((item) => item.id !== row.id);
          setRowSelected(_.uniqBy(tmp));
        }
      } catch (error) {}
    },
    onSelectAll: (isSelect, rows) => {
      if (isSelect) {
        setRowSelected(rows);
      } else {
        setRowSelected([]);
      }
    },
    selectionHeaderRenderer: ({ indeterminate, ...rest }) => {
      return (
        <div className="" style={{ verticalAlign: 'inherit' }}>
          <input
            type="checkbox"
            style={{ height: 'unset', verticalAlign: 'inherit' }}
            ref={(input) => {
              if (input) input.indeterminate = indeterminate;
            }}
            {...rest}
          />
        </div>
      );
    },
    selectionRenderer: ({ mode, ...rest }) => {
      return (
        <div>
          <input type={mode} {...rest} />
        </div>
      );
    },
    selected: rowsSelected.map((item) => item.id),
  };

  const columns = [
    {
      dataField: 'code',
      text: 'MÃ',
    },
    {
      dataField: 'name',
      text: 'TÊN ĐƠN HÀNG',
    },
    {
      dataField: 'organization.name',
      text: 'PHÒNG KHÁM/BỆNH VIỆN',
    },
    {
      dataField: 'specifyVote.nameTest',
      text: 'DỊCH VỤ',
    },
    {
      dataField: 'bill',
      text: 'TỔNG TIỀN(VNĐ)',
      formatter: (cell) => {
        return currencyFormat(
          Number(cell?.totalMoney ?? 0) - Number(cell?.reduce ?? 0)
        );
      },
      headerStyle: {
        textAlign: 'right',
      },
      style: {
        textAlign: 'right',
      },
    },
    {
      dataField: 'specifyVote',
      text: 'BÁC SĨ',
      formatter: (cell) => {
        return `${cell?.lastNameBS ?? ''} ${cell?.firstNameBS ?? ''} `;
      },
    },
    {
      dataField: 'codeStaff1',
      text: 'SALE',
      formatter: (cell) => {
        return `${cell?.fullname ?? ''} `;
      },
    },
    {
      dataField: 'codeStaff2',
      text: 'THU MẪU',
      formatter: (cell) => {
        return `${cell?.fullname ?? ''} `;
      },
    },
    {
      dataField: 'bill',
      text: 'ĐÃ THANH TOÁN',
      formatter: (cell) => {
        return currencyFormat(Number(cell?.paid ?? 0));
      },
      headerStyle: {
        textAlign: 'right',
      },
      style: {
        textAlign: 'right',
      },
    },
    {
      dataField: 'organization.method',
      text: 'HÌNH THỨC',
      formatter: (cell) => {
        const paymentMethodV = CONSTANT.ORGANIZATION_METHOD_TYPE.find(
          (item) => item.value === cell
        );
        return !!paymentMethodV ? paymentMethodV.label : '';
      },
      headerStyle: {
        textAlign: 'center',
      },
      style: {
        textAlign: 'center',
      },
    },
  ];

  const onSizePerPageChange = (e) => {
    setPage(1);
    setLimit(e.target.value);
  };

  const onPageChange = (value) => {
    setPage(value);
  };

  const pagination = paginationFactory({
    page: page,
    sizePerPage: limit,
    totalSize: total,
    showTotal: false,
    withFirstAndLast: false,
    alwaysShowAllBtns: true,
    onPageChange: onPageChange,
    sizePerPageRenderer: () => {
      return (
        <div className="dataTables_length" id="datatable-basic_length">
          <label>
            Hiển thị{' '}
            {
              <select
                name="datatable-basic_length"
                aria-controls="datatable-basic"
                className="form-control form-control-sm"
                onChange={onSizePerPageChange}
              >
                <option value="10">10</option>
                <option value="25">25</option>
                <option value="50">50</option>
                <option value="100">100</option>
              </select>
            }{' '}
            dòng.
          </label>
        </div>
      );
    },
  });

  useEffect(() => {
    if (isModalApproveVisible) {
      setRowSelected(data);
    }
  }, [data, isModalApproveVisible]);

  const handleClose = () => {
    setRowSelected([]);
    dispatch(
      formActions.setValue({
        name: 'isModalApproveVisible',
        value: false,
      })
    );
    dispatch(
      formActions.setValue({
        name: 'modalInfo',
        value: {},
      })
    );
  };

  const onSubmit = async () => {
    dispatch(
      orderActions.updateOrder(
        rowsSelected[0].id,
        { approveOrderIds: rowsSelected.slice(1).map((i) => i.id) },
        {
          success: () => {
            setData([]);
            handleClose();
            handleGetOrders();
            notify(
              notificationAlertRef,
              'success',
              'Thông báo',
              `Duyệt đơn hàng và gửi tin nhắn zalo thành công!`
            );
          },
          failed: (mess) => {
            notify(notificationAlertRef, 'danger', 'Thông báo', `${mess}!`);
          },
        }
      )
    );
  };

  return (
    <Modal
      size={'xl'}
      isOpen={isModalApproveVisible}
      className="modal-dialog-centered modal-secondary"
      toggle={() => {
        handleClose();
      }}
    >
      <div className="modal-header border-bottom bg-white pb-2">
        <h2>Xác nhận đơn hàng chờ duyệt</h2>
        <button
          aria-label="Close"
          className="close"
          data-dismiss="modal"
          type="button"
          onClick={() => {
            handleClose();
          }}
        >
          <span style={{ color: '#262626' }} aria-hidden={true}>
            ×
          </span>
        </button>
      </div>
      <div className="modal-body bg-white rounded">
        <TableCustom
          tableName={`Bạn chắc chắn muốn xác nhận ${total} đơn hàng này ?`}
          data={data.slice((page - 1) * limit, page * limit)}
          columns={columns}
          page={page}
          limit={limit}
          total={total}
          searchBy={'Tìm kiếm'}
          pagination={pagination}
          showTableHeader={false}
          selectRow={selectRow}
        />
        <div className="mb-0 pb-0 mt-4 d-flex justify-content-end">
          <Button
            className="text-nowrap mr-1"
            onClick={() => {
              handleClose();
            }}
          >
            Hủy
          </Button>
          <LoadingButtonCustom
            color="info"
            className="text-nowrap "
            onClick={onSubmit}
            loading={false}
          >
            Cập nhật
          </LoadingButtonCustom>
        </div>
      </div>
    </Modal>
  );
};

export default memo(UApprove);
