import React, { memo, useEffect, useState } from 'react';
import paginationFactory from 'react-bootstrap-table2-paginator';
import _ from 'lodash';
import {
  Badge,
  Container,
  FormGroup,
  Input,
  Label,
  Row,
  UncontrolledTooltip,
} from 'reactstrap';
import queryString from 'query-string';
import { useHistory } from 'react-router';
import { useDispatch, useSelector } from 'react-redux';

import {
  additionalSampleActions,
  filterActions,
  orderActions,
} from 'redux/actions';
import SimpleHeader from 'components/Headers/SimpleHeader.js';
import 'assets/css/custom-pages/react-bs-table.css';
import { useDidUpdateEffect } from 'utils/HookUtils';
import CONSTANT from 'constant';

import IconShare from 'assets/svgs/share.svg';
import IconApprove from 'assets/svgs/approve.svg';
import IconTransfer from 'assets/svgs/transfer.svg';
import IconPrintPCD from 'assets/svgs/print_pcd.svg';
import moment from 'moment';
import TableCustom from '../components/TableCustom';
import TransferXN from './components/TransferXN';
import Filter from './components/Filter';
import {
  checkRole,
  handleDownloadLink,
  notificationAlertRef,
  notify,
} from 'common';
import classNames from 'classnames';
import SelectCustom from '../components/SelectCustom';

function ReceivePay({ location }) {
  const dispatch = useDispatch();
  const history = useHistory();
  const { page, limit, listType, queryReceivePay, isPrintPkq, isGettingOrder } =
    useSelector((state) => state.orderReducer);
  const { areasByAccountExtend } = useSelector((state) => state.areaReducer);
  const additionalSampleReducer = useSelector(
    (state) => state.additionalSampleReducer
  );

  const {
    currentAccountExtend,
    isAdmin,
    childrentByCurrentAccountExtend,
    isDoctorHT,
    isDoctorKH,
    isBusinessStaff,
    isSampleCollectorStaff,
    isHasChildrent,
  } = useSelector((state) => state.accountReducer);

  const [statusValue, setStatusValue] = useState(null);
  const [orderSearch, setOrderSearch] = useState('');
  const [results, setResults] = useState([]);
  const [total, setTotal] = useState([]);

  const columns = [
    {
      dataField: 'code',
      text: 'MÃ ĐƠN HÀNG',
      formatter: (cell, row) => {
        const code = listType === 'order' ? cell ?? '' : row?.order?.code ?? '';
        if (row.status === CONSTANT.ORDER_STATUS[6].value) {
          return `${code}_${row.labCode}`;
        }
        return code;
      },
    },
    {
      dataField: 'barcode1',
      text: 'MÃ BARCODE',
      formatter: (cell, row) => {
        return row?.barcode?.barcode ?? cell ?? '';
      },
    },
    {
      dataField: 'areaName',
      text: 'KHU VỰC',
    },
    {
      dataField: 'organization.name',
      text: 'TÊN TỔ CHỨC',
    },
    {
      dataField: 'specifyVote.nameTest',
      text: 'DỊCH VỤ',
    },
    {
      dataField: 'specifyVote.nameBN',
      text: 'BỆNH NHÂN',
    },
    {
      dataField: 'specifyVote.dateOfBirthBN',
      text: 'NGÀY SINH',
      formatter: (cell) => {
        return cell ?? '';
      },
    },
    {
      dataField: 'timeReceiveResult',
      text: 'NGÀY TIẾP NHẬN',
      formatter: (cell) => {
        return !!cell ? moment(cell).format('DD/MM/YYYY') : '';
      },
    },
    {
      dataField: 'note',
      text: 'GHI CHÚ',
    },
    {
      dataField: 'status',
      text: 'TRẠNG THÁI',
      formatter: (cell) => {
        const statsV = CONSTANT.ORDER_STATUS.find(
          (item) => item.value === cell
        );
        return !!statsV ? (
          <Badge color={statsV.color}>{statsV.label}</Badge>
        ) : (
          ''
        );
      },
      headerStyle: {
        textAlign: 'center',
      },
      style: {
        textAlign: 'center',
      },
    },
  ];

  if (
    location.pathname === '/admin/approve-result' ||
    location.pathname === '/admin/pay-result'
  ) {
    columns.push({
      dataField: 'actions',
      text: 'HÀNH ĐỘNG',
      headerClasses: 'fixed-column',
      formatter: boxActions,
      headerStyle: {
        textAlign: 'center',
        zIndex: 3,
      },
      classes: 'fixed-column',
    });
  }

  const handleUpdateOrder = (id, messages, callList = false) => {
    if (listType === 'order') {
      dispatch(
        orderActions.updateOrder(
          id,
          {
            status: CONSTANT.ORDER_STATUS[11].value,
          },
          {
            success: () => {
              if (callList) handleGetListType();
              notify(
                notificationAlertRef,
                'success',
                'Thông báo',
                messages.success
              );
            },
          }
        )
      );
    } else {
      dispatch(
        additionalSampleActions.updateAdditionalSample(
          id,
          {
            status: CONSTANT.ORDER_STATUS[11].value,
          },
          {
            success: () => {
              if (callList) handleGetListType();
              notify(
                notificationAlertRef,
                'success',
                'Thông báo',
                messages.success
              );
            },
          }
        )
      );
    }
  };

  function boxActions(__, row) {
    if (row.status === CONSTANT.ORDER_STATUS[6].value) return null;
    return (
      <div className="d-flex justify-content-center">
        {row.status === CONSTANT.ORDER_STATUS[11].value &&
          location.pathname === '/admin/pay-result' && (
            <>
              <button
                id={'print' + row.id}
                onClick={() => {
                  if (listType === 'order') {
                    handleGetAdditionalSampleByOrder(row.id);
                  } else {
                    const query = {
                      'filters[$and][1][id][$eq]': row.order.id,
                    };
                    dispatch(
                      orderActions.getOrderBy(
                        queryString.stringify(query),
                        {
                          success: (data) => {
                            if (data?.orderType === 'appendix') {
                              (data?.fileAppendixs ?? []).forEach((item) => {
                                handleDownloadLink(item);
                              });
                            } else {
                              handlePrintPKQ(data?.id ?? -1, 'order');
                              handleDownloadLink(data.fileResult);
                            }
                          },
                          failed: () => {},
                        },
                        false
                      )
                    );
                  }
                  if (row?.orderType === 'appendix') {
                    (row?.fileAppendixs ?? []).forEach((item) => {
                      handleDownloadLink(item);
                    });
                  } else {
                    handlePrintPKQ(row?.id ?? -1, listType);
                    handleDownloadLink(row.fileResult);
                  }
                }}
                className="btn p-0 btn-no-shadow mr-0"
              >
                <img src={IconPrintPCD} className="mr-1" />
              </button>
              <UncontrolledTooltip
                delay={1}
                placement="bottom"
                target={'print' + row.id}
              >
                In phiếu kết quả
              </UncontrolledTooltip>
              <button
                id={'send' + row.id}
                onClick={() => {
                  handleUpdateOrder(row.id, {
                    success:
                      'Mail đã được gửi. Quá trình gửi có thể mất vài phút!',
                  });
                }}
                className="btn p-0 btn-no-shadow mr-0"
              >
                <img src={IconTransfer} className="mr-1" />
              </button>
              <UncontrolledTooltip
                delay={1}
                placement="bottom"
                target={'send' + row.id}
              >
                Gửi mail kết quả
              </UncontrolledTooltip>
            </>
          )}

        {row.status === CONSTANT.ORDER_STATUS[9].value && (
          <>
            <button
              id={'send' + row.id}
              onClick={() => {
                handleUpdateOrder(
                  row.id,
                  {
                    success: 'Đã trả kết quả. Mail sẽ được gửi trong vài phút!',
                  },
                  true
                );
              }}
              className="btn p-0 btn-no-shadow mr-0"
            >
              <img src={IconShare} className="mr-1" />
            </button>
            <UncontrolledTooltip
              delay={1}
              placement="bottom"
              target={'send' + row.id}
            >
              Trả kết quả
            </UncontrolledTooltip>
          </>
        )}

        {(row.status === CONSTANT.ORDER_STATUS[10].value ||
          row.status === CONSTANT.ORDER_STATUS[12].value) &&
          checkRole(currentAccountExtend, {
            roles: ['update-approve-result'],
          }) &&
          location.pathname === '/admin/approve-result' && (
            <>
              <button
                id={'share' + row.id}
                onClick={() => {
                  history.push(
                    `/admin/return-results?tab=${location.pathname.replace(
                      '/admin/',
                      ''
                    )}`,
                    {
                      fromPage: location.pathname,
                      listType,
                      order: row,
                    }
                  );
                }}
                className="btn p-0 btn-no-shadow mr-0"
              >
                <img src={IconApprove} className="mr-1" />
              </button>
              <UncontrolledTooltip
                delay={1}
                placement="bottom"
                target={'share' + row.id}
              >
                Duyệt kết quả
              </UncontrolledTooltip>
            </>
          )}
      </div>
    );
  }

  const handleGetAdditionalSampleByOrder = (orderId) => {
    const query = {
      'filters[$and][1][order][id][$eq]': orderId,
    };
    dispatch(
      additionalSampleActions.getAdditionalSamples(
        queryString.stringify(query),
        {
          success: (data) => {
            data.results.forEach((result) => {
              if (result?.orderType === 'appendix') {
                (result?.fileAppendixs ?? []).forEach((item) => {
                  handleDownloadLink(item);
                });
              } else {
                handlePrintPKQ(result?.id ?? -1, 'additional-sample');
                handleDownloadLink(result?.fileResult);
              }
            });
          },
          failed: () => {},
        },
        false
      )
    );
  };

  const handlePrintPKQ = (id, type = 'order') => {
    if (id === -1) return;
    if (type === 'order') {
      dispatch(
        orderActions.printPkq(id, {
          success: (data) => {
            var blobURL = URL.createObjectURL(data);
            window.open(blobURL);
          },
          failed: (mess) => {
            notify(
              notificationAlertRef,
              'danger',
              'Thông báo',
              `In phiếu kết quả lỗi!`
            );
          },
        })
      );
    } else {
      dispatch(
        additionalSampleActions.printPkqAdditionalSample(id, {
          success: (data) => {
            var blobURL = URL.createObjectURL(data);
            window.open(blobURL);
          },
          failed: (mess) => {
            notify(
              notificationAlertRef,
              'danger',
              'Thông báo',
              `In phiếu kết quả lỗi!`
            );
          },
        })
      );
    }
  };

  const onSizePerPageChange = (e) => {
    dispatch(orderActions.setValue({ name: 'page', value: 1 }));
    dispatch(orderActions.setValue({ name: 'limit', value: e.target.value }));
  };

  const onPageChange = (value) => {
    dispatch(orderActions.setValue({ name: 'page', value: value }));
  };

  const pagination = paginationFactory({
    page: page,
    sizePerPage: limit,
    totalSize: total,
    showTotal: false,
    withFirstAndLast: false,
    alwaysShowAllBtns: true,
    onPageChange: onPageChange,
    sizePerPageRenderer: () => {
      return (
        <div className="dataTables_length" id="datatable-basic_length">
          <label>
            Hiển thị{' '}
            {
              <select
                name="datatable-basic_length"
                aria-controls="datatable-basic"
                className="form-control form-control-sm"
                onChange={onSizePerPageChange}
              >
                <option value="10">10</option>
                <option value="25">25</option>
                <option value="50">50</option>
                <option value="100">100</option>
              </select>
            }{' '}
            dòng.
          </label>
        </div>
      );
    },
  });

  useDidUpdateEffect(() => {
    handleGetListType();
  }, [page, limit, queryReceivePay]);

  useDidUpdateEffect(() => {
    // Sau 500ms thì bắn api
    const delayDebounceFn = setTimeout(() => {
      handleGetListType();
    }, 500);
    return () => clearTimeout(delayDebounceFn);
  }, [orderSearch, statusValue]);

  useDidUpdateEffect(() => {
    dispatch(orderActions.setValue({ name: 'page', value: 1 }));
    dispatch(orderActions.setValue({ name: 'limit', value: 10 }));
  }, [listType]);

  useEffect(() => {
    // if (!_.isEmpty(orders)) return;
    handleGetListType();
  }, [childrentByCurrentAccountExtend, areasByAccountExtend]);

  const handleGetListType = () => {
    const query = {
      pageSize: limit,
      page,
      'filters[$and][3][receptionStatus][$eq]': true,
      ...queryReceivePay,
    };
    switch (location.pathname) {
      case '/admin/approve-result':
        query['filters[$and][1][$or][1][status][$eq]'] =
          CONSTANT.ORDER_STATUS[10].value;
        query['filters[$and][1][$or][2][status][$eq]'] =
          CONSTANT.ORDER_STATUS[12].value;

        break;
      case '/admin/pay-result':
        delete query['sort'];
        query['filters[$and][1][$or][0][status][$eq]'] =
          CONSTANT.ORDER_STATUS[9].value;
        query['filters[$and][1][$or][1][status][$eq]'] =
          CONSTANT.ORDER_STATUS[11].value;
        query['sort'] = 'status:ASC';
        break;
      default:
        query['filters[$and][1][$or][0][status][$eq]'] =
          CONSTANT.ORDER_STATUS[9].value;
        query['filters[$and][1][$or][1][status][$eq]'] =
          CONSTANT.ORDER_STATUS[10].value;
        query['filters[$and][1][$or][2][status][$eq]'] =
          CONSTANT.ORDER_STATUS[12].value;
        query['filters[$and][1][$or][3][status][$eq]'] =
          CONSTANT.ORDER_STATUS[13].value;

        break;
    }

    if (!!statusValue) {
      delete query['filters[$and][1][$or][0][status][$eq]'];
      delete query['filters[$and][1][$or][1][status][$eq]'];
      delete query['filters[$and][1][$or][2][status][$eq]'];
      delete query['filters[$and][1][$or][3][status][$eq]'];
      query['filters[$and][1][status][$eq]'] = statusValue.value;
    }
    if (!isBusinessStaff) {
      areasByAccountExtend.forEach((item, index) => {
        query[`filters[$and][4][$or][${index}][areaName][$eq]`] = item.name;
      });
    }
    query[
      `filters[$and][4][$or][${areasByAccountExtend.length}][createdBy][id][$eq]`
    ] = currentAccountExtend.id;

    if (!isAdmin) {
      if (isSampleCollectorStaff) {
        query[
          `filters[$and][4][$or][${
            areasByAccountExtend.length + 1
          }][codeStaff2][id][$eq]`
        ] = currentAccountExtend?.id;
      } else if (isBusinessStaff) {
        query[
          `filters[$and][4][$or][${
            areasByAccountExtend.length + 1
          }][codeStaff1][id][$eq]`
        ] = currentAccountExtend?.id;
      }
      if (isHasChildrent) {
        if (isSampleCollectorStaff) {
          childrentByCurrentAccountExtend.forEach((item, index) => {
            query[
              `filters[$and][4][$or][${
                areasByAccountExtend.length + 2 + index
              }][codeStaff2][id][$eq]`
            ] = item.id;
          });
        } else if (isBusinessStaff) {
          childrentByCurrentAccountExtend.forEach((item, index) => {
            query[
              `filters[$and][4][$or][${
                areasByAccountExtend.length + 2 + index
              }][codeStaff1][id][$eq]`
            ] = item.id;
          });
        }
      }
    } else if (isDoctorHT || isDoctorKH) {
      query[
        `filters[$and][4][$or][${
          areasByAccountExtend.length + 1
        }][specifyVote][idBS][$eq]`
      ] = currentAccountExtend?.id;
      query[
        `filters[$and][4][$or][${
          areasByAccountExtend.length + 2
        }][specifyVote][createdBy][id][$eq]`
      ] = currentAccountExtend?.id;
    }
    if (listType === 'order') {
      query['_q'] = orderSearch;
      dispatch(
        filterActions.setValueFilter({
          name: 'queryPage',
          value: query,
        })
      );
      dispatch(
        orderActions.getOrders(
          queryString.stringify(query, {
            skipEmptyString: true,
            skipNull: true,
          }),
          {
            success: (data) => {
              setTotal(_.get(data, 'pagination.total', 0));
              setResults(data?.results ?? []);
            },
          }
        )
      );
    } else {
      query['filters[$and][5][$or][1][order][code][$containsi]'] = orderSearch;
      dispatch(
        filterActions.setValueFilter({
          name: 'queryPage',
          value: query,
        })
      );
      dispatch(
        additionalSampleActions.getAdditionalSamples(
          queryString.stringify(query, {
            skipEmptyString: true,
            skipNull: true,
          }),
          {
            success: (data) => {
              setTotal(_.get(data, 'pagination.total', 0));
              setResults(data?.results ?? []);
            },
          }
        )
      );
    }
  };

  const headerLeftComponent = () => {
    return (
      <div>
        <FormGroup
          onChange={(e) => {
            dispatch(
              orderActions.setValue({ name: 'listType', value: e.target.value })
            );
          }}
          className="d-flex align-items-center radio-button-custom mb-0"
        >
          <FormGroup check className={classNames({ 'mr-3': true })}>
            <Label check style={{ fontSize: 13 }}>
              <Input
                checked={listType === 'order'}
                value={'order'}
                type="radio"
                name="listType"
              />{' '}
              Đơn hàng
            </Label>
          </FormGroup>
          <FormGroup check className={classNames({ 'mr-3': true })}>
            <Label check style={{ fontSize: 13 }}>
              <Input
                checked={listType === 'additional-sample'}
                value={'additional-sample'}
                type="radio"
                name="listType"
              />{' '}
              Mẫu bổ sung
            </Label>
          </FormGroup>
          {(location.pathname === '/admin/pay-result' ||
            location.pathname === '/admin/approve-result') && (
            <div style={{ zIndex: 6666, width: 200 }}>
              <FormGroup className="mb-1">
                <SelectCustom
                  name="status"
                  label="Chọn trạng thái"
                  placeholder="Lựa chọn"
                  value={statusValue}
                  isRequired={false}
                  isClearable={true}
                  handleChange={(e) => {
                    setStatusValue(e);
                  }}
                  options={
                    location.pathname === '/admin/pay-result'
                      ? [CONSTANT.ORDER_STATUS[9], CONSTANT.ORDER_STATUS[11]]
                      : [CONSTANT.ORDER_STATUS[10], CONSTANT.ORDER_STATUS[12]]
                  }
                />
              </FormGroup>
            </div>
          )}
        </FormGroup>
      </div>
    );
  };

  return (
    <>
      <SimpleHeader />
      <TransferXN handleGetListType={handleGetListType} />
      <Filter />
      <Container className="mt--9" fluid>
        <Row>
          <div className="col">
            <TableCustom
              tableName="Danh sách"
              data={results}
              columns={columns}
              page={page}
              limit={limit}
              total={total}
              searchBy={'Tìm kiếm'}
              pagination={pagination}
              search={orderSearch}
              onChange={(e) => {
                setOrderSearch(e.target.value);
              }}
              headerLeftComponent={headerLeftComponent}
              isLoading={
                isPrintPkq ||
                isGettingOrder ||
                additionalSampleReducer.isPrintPkq
              }
            />
          </div>
        </Row>
      </Container>
    </>
  );
}

export default memo(ReceivePay);
