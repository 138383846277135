import React, { useEffect, useState } from 'react';
import classnames from 'classnames';
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  FormGroup,
  Form,
  Input,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
  Container,
  Row,
  Col,
} from 'reactstrap';
import { useDispatch } from 'react-redux';
import { Formik } from 'formik';
import * as yup from 'yup';
import queryString from 'query-string';

import { notificationAlertRef, notify, emailRegex } from 'common';
import HelperText from 'views/pages/components/HelperText';
import AuthHeader from 'components/Headers/AuthHeader.js';
import { accountActions } from 'redux/actions';
import 'assets/css/pages/login.css';
import { useHistory } from 'react-router';
import ImgBack from 'assets/img/background/background.jpg';
const Login = () => {
  const dispatch = useDispatch();
  const history = useHistory();

  const [focusedEmail, setfocusedEmail] = useState(false);
  const [focusedPassword, setfocusedPassword] = useState(false);
  const [formType, setFormType] = useState('login');
  const accountSchema = yup.object().shape({
    email: yup
      .string()
      .email('Email không đúng định dạng!')
      .required('Email không được để trống!'),
    password: yup.string().required('Mật khẩu không được để trống!'),
  });

  const forgotPasswordSchema = yup.object().shape({
    email: yup
      .string()
      .email('Email không đúng định dạng!')
      .required('Email không được để trống!'),
  });

  const accountInfo = {
    email: '',
    password: '',
  };
  const [forgotInfo, setForgotInfo] = useState({ email: '' });

  const handleChangeFormType = () => {
    if (formType === 'login') setFormType('forgot');
    else setFormType('login');
    setfocusedEmail(false);
  };

  const onSubmit = (values) => {
    dispatch(
      accountActions.accountLogin(
        { email: values.email, password: values.password },
        {
          success: (data) => {
            localStorage.setItem('token', JSON.stringify(data.token));
            localStorage.setItem('user', JSON.stringify(data.user));
            history.push('/admin');
          },
          failed: (mess) => {
            notify(
              notificationAlertRef,
              'danger',
              'Thông báo',
              `Đăng nhập thất bại. Lỗi: ${mess}!`
            );
          },
        }
      )
    );
  };

  const handleSendEmail = (values) => {
    dispatch(
      accountActions.forgotPassword(
        { email: values.email },
        {
          success: () => {
            notify(
              notificationAlertRef,
              'success',
              'Thông báo',
              `Có thể mất vài phút để nhận được liên kết khôi phục mật khẩu của bạn.`
            );
            setTimeout(() => {
              handleChangeFormType();
            }, 2000);
          },
          failed: (mess) => {
            notify(
              notificationAlertRef,
              'danger',
              'Thông báo',
              `Gửi yêu cầu thất bại. Lỗi: ${mess}!`
            );
          },
        }
      )
    );
  };

  useEffect(() => {
    localStorage.clear();
  }, []);

  return (
    <div className="container-login">
      <AuthHeader title="HỆ THỐNG QUẢN LÝ DỊCH VỤ XÉT NGHIỆM" lead="" />
      <div style={{ position: 'relative' }}>
        <div
          style={{
            position: 'absolute',
            top: 0,
            zIndex: 2,
            width: '100%',
          }}
        >
          <Container className="mt-6">
            <Row className="justify-content-center">
              {formType === 'login' ? (
                <Formik
                  initialValues={accountInfo}
                  enableReinitialize
                  onSubmit={onSubmit}
                  validationSchema={accountSchema}
                >
                  {({
                    values,
                    handleSubmit,
                    errors,
                    touched,
                    handleBlur,
                    handleChange,
                  }) => {
                    return (
                      <Col lg="5" md="8">
                        <Card className="bg-secondary border-0 mb-0">
                          <CardHeader className="bg-transparent p-0 border-bottom-0">
                            <div className="text-muted text-center mt-4 mb-4">
                              <h5 className="p-0 m-0 textHeadForgot">
                                Đăng nhập để sử dụng các chức năng của hệ thống
                              </h5>
                            </div>
                          </CardHeader>
                          <CardBody className="px-lg-4 pb-lg-3 pt-lg-0 ">
                            <div className="text-center text-muted">
                              <small>
                                <h4>ĐĂNG NHẬP</h4>
                              </small>
                            </div>
                            <Form onSubmit={handleSubmit} role="form">
                              <FormGroup
                                className={classnames('mb-3', {
                                  focused: focusedEmail,
                                })}
                              >
                                <InputGroup className="input-group-merge input-group-alternative">
                                  <InputGroupAddon addonType="prepend">
                                    <InputGroupText>
                                      <i className="ni ni-email-83" />
                                    </InputGroupText>
                                  </InputGroupAddon>
                                  <Input
                                    value={values.email}
                                    placeholder="Email"
                                    type="email"
                                    id="email"
                                    name="email"
                                    onChange={handleChange}
                                    onFocus={() => setfocusedEmail(true)}
                                    onBlur={(e) => {
                                      setfocusedEmail(true);
                                      handleBlur(e);
                                    }}
                                  />
                                </InputGroup>
                                {errors.email && touched.email && (
                                  <HelperText message={errors.email} />
                                )}
                              </FormGroup>
                              <FormGroup
                                className={classnames({
                                  focused: focusedPassword,
                                })}
                              >
                                <InputGroup className="input-group-merge input-group-alternative">
                                  <InputGroupAddon addonType="prepend">
                                    <InputGroupText>
                                      <i className="ni ni-lock-circle-open" />
                                    </InputGroupText>
                                  </InputGroupAddon>
                                  <Input
                                    value={values.password}
                                    placeholder="Password"
                                    type="password"
                                    id="password"
                                    name="password"
                                    onChange={handleChange}
                                    onFocus={() => setfocusedPassword(true)}
                                    onBlur={(e) => {
                                      setfocusedPassword(true);
                                      handleBlur(e);
                                    }}
                                  />
                                </InputGroup>
                                {errors.password && touched.password && (
                                  <HelperText message={errors.password} />
                                )}
                              </FormGroup>
                              <div className="text-center">
                                <Button
                                  onClick={handleSubmit}
                                  className="mb-4"
                                  color="info"
                                  type="submit"
                                >
                                  ĐĂNG NHẬP
                                </Button>
                              </div>
                              <FormGroup
                                className={classnames(
                                  {
                                    focused: focusedPassword,
                                  },
                                  'text-right mb-0'
                                )}
                              >
                                <a
                                  className="text-right"
                                  onClick={(e) => handleChangeFormType()}
                                >
                                  <h5 className="textForgot p-0 m-0 textHeadForgot">
                                    Quên mật khẩu?
                                  </h5>
                                </a>
                              </FormGroup>
                            </Form>
                          </CardBody>
                        </Card>
                      </Col>
                    );
                  }}
                </Formik>
              ) : (
                <Formik
                  initialValues={forgotInfo}
                  enableReinitialize
                  onSubmit={handleSendEmail}
                  validationSchema={forgotPasswordSchema}
                >
                  {({
                    values,
                    handleSubmit,
                    errors,
                    touched,
                    handleBlur,
                    handleChange,
                  }) => {
                    return (
                      <Col lg="5" md="8">
                        <Card className="bg-secondary border-0 mb-0">
                          <CardHeader className="bg-transparent p-0 border-bottom-0">
                            <div className="text-muted text-center mt-4 mb-4">
                              <h5 className="p-0 m-0 textHeadForgot">
                                Nhập email để cấp lại mật khẩu
                              </h5>
                            </div>
                          </CardHeader>
                          <CardBody className="px-lg-4 pb-lg-3 pt-lg-0 ">
                            <div className="text-center text-muted">
                              <small>
                                <h4>QUÊN MẬT KHẨU</h4>
                              </small>
                            </div>
                            <Form onSubmit={handleSendEmail} role="form">
                              <FormGroup
                                className={classnames('mb-3', {
                                  focused: focusedEmail,
                                })}
                              >
                                <InputGroup className="input-group-merge input-group-alternative">
                                  <InputGroupAddon addonType="prepend">
                                    <InputGroupText>
                                      <i className="ni ni-email-83" />
                                    </InputGroupText>
                                  </InputGroupAddon>
                                  <Input
                                    value={values.email}
                                    placeholder="Email"
                                    type="email"
                                    id="email"
                                    name="email"
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                  />
                                </InputGroup>
                                {errors.email && touched.email && (
                                  <HelperText message={errors.email} />
                                )}
                              </FormGroup>

                              <div className="text-center">
                                <Button
                                  onClick={handleSubmit}
                                  className="mb-4"
                                  color="info"
                                  type="submit"
                                >
                                  GỬI
                                </Button>
                              </div>
                              <FormGroup
                                className={classnames(
                                  {
                                    focused: focusedPassword,
                                  },
                                  'text-right mb-0'
                                )}
                              >
                                <a
                                  className="text-right"
                                  onClick={(e) => handleChangeFormType()}
                                >
                                  <h5 className="textForgot p-0 m-0 textHeadForgot">
                                    Đăng nhập?
                                  </h5>
                                </a>
                              </FormGroup>
                            </Form>
                          </CardBody>
                        </Card>
                      </Col>
                    );
                  }}
                </Formik>
              )}
            </Row>
          </Container>
        </div>
        <div
          // src={ImgBack}
          // style={{
          //   width: '100%',
          //   height: '80.5vh',
          //   backgroundImage: ,
          //   backgroundRepeat: 'no-repeat',
          //   backgroundSize: 'cover',
          //   position: "absolute",
          //   top: 10,
          // }}
          className="bg-under"
        />
      </div>
    </div>
  );
};

export default Login;
