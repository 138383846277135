import React, { useState } from "react";
import ReactDatePicker from "react-datepicker";
import {
  Button,
  Card,
  CardBody,
  CardHeader,
  Col,
  FormGroup,
  Row,
} from "reactstrap";
import InputCustom from "views/pages/components/InputCustom";
import LabelCustom from "views/pages/components/LabelCustom";
import SelectCustom from "views/pages/components/SelectCustom";
import DatePickerCustom from "views/pages/components/DatePickerCustom";
import { useFormikContext } from "formik";
import CONSTANT from "constant";
import _ from "lodash";
import { useHistory } from "react-router";
import { useDispatch } from "react-redux";
import { specifyVotesActions } from "redux/actions";
import moment from "moment";
function Header({ testValue, formik, specify, isDisabled }) {
  // const [cellNucleus, setCellNucleus] = useState(null);
  //Nhóm phôi

  if (testValue == 1) {
    return (
      <>
        <Row>
          <Col xs={3}>
            <InputCustom
              label="Người sinh thiết"
              labelWidth={120}
              lableTextAlign={"left"}
              disabled={isDisabled}
              placeholder="Nhập họ tên"
              row={true}
              value={formik.values.biopsyPerson}
              handleChange={(e) => {
                formik.setFieldValue("biopsyPerson", e.target.value);
              }}
              error={formik.errors.biopsyPerson}
              touched={formik.touched.biopsyPerson}
            />
          </Col>
          <Col xs={3} className={"pr-4"}>
            <DatePickerCustom
              row={true}
              label={"Ngày sinh thiết"}
              disabled={isDisabled}
              labelWidth={150}
              maxDate={new Date()}
              lableTextAlign={"left"}
              selected={
                formik.values.biopsyDay
                  ? new Date(formik.values.biopsyDay)
                  : new Date()
              }
              handleChange={(date) => {
                formik.setFieldValue("biopsyDay", new Date(date).toISOString());
              }}
            />
          </Col>
          <Col xs={6}>
            <InputCustom
              label="Dung dịch chứa tế bào"
              labelWidth={270}
              placeholder="Nhập vào dung dịch"
              row={true}
              lableTextAlign={"left"}
              disabled={isDisabled}
              value={formik.values.cellSolution}
              handleChange={(e) => {
                formik.setFieldValue("cellSolution", e.target.value);
              }}
              error={formik.errors.cellSolution}
              touched={formik.touched.cellSolution}
            />
          </Col>
        </Row>
        <Row>
          <Col xs={3}>
            <InputCustom
              label="Số phôi tạo ra"
              labelWidth={120}
              lableTextAlign={"left"}
              disabled={isDisabled}
              id="numOfEmbryos1"
              name="numOfEmbryos1"
              placeholder="Nhập số"
              type="number"
              row={true}
              value={formik.values.numOfEmbryos1}
              handleChange={(e) => {
                formik.setFieldValue("numOfEmbryos1", e.target.value);
              }}
              handleBlur={formik.handleBlur}
              error={formik.errors.numOfEmbryos1}
              touched={formik.touched.numOfEmbryos1}
            />
          </Col>
          <Col xs={3}>
            <SelectCustom
              label="Số thứ tự"
              labelWidth={150}
              lableTextAlign={"left"}
              placeholder="Lựa chọn"
              row={true}
              styles={{
                control: (baseStyles, state) => ({
                  ...baseStyles,
                  fontSize: "0.875rem",
                }),
              }}
              isDisabled={isDisabled}
              isClearable={false}
              isRequired={false}
              className="react-select-container"
              classNamePrefix="react-select"
              value={_.find(CONSTANT.SPECIFY_VOTES_NO_PHOI, {
                value: formik.values.noPhoi,
              })}
              handleChange={(e) => {
                formik.setFieldValue("noPhoi", e.value);
              }}
              options={CONSTANT.SPECIFY_VOTES_NO_PHOI}
            />
          </Col>
          <Col xs={6}>
            <InputCustom
              label="Trạng thái phôi"
              labelWidth={270}
              disabled={isDisabled}
              lableTextAlign={"left"}
              placeholder="Nhập vào trạng thái"
              row={true}
              value={formik.values.embryoState}
              handleChange={(e) => {
                formik.setFieldValue("embryoState", e.target.value);
              }}
              error={formik.errors.embryoState}
              touched={formik.touched.embryoState}
            />
          </Col>
        </Row>
        <Row>
          <Col xs={6}>
            <InputCustom
              label="Đánh giá hình thái phôi"
              labelWidth={120}
              disabled={isDisabled}
              lableTextAlign={"left"}
              placeholder="Nhập vào hình thái"
              row={true}
              value={formik.values.embryoEvaluation}
              handleChange={(e) => {
                formik.setFieldValue("embryoEvaluation", e.target.value);
              }}
              error={formik.errors.embryoEvaluation}
              touched={formik.touched.embryoEvaluation}
            />
          </Col>
        </Row>
        <Row className="d-flex align-items-center mb-0">
          <Col xs={12}>
            <FormGroup
              id="cellNucleoradio"
              onChange={(e) => {
                formik.setFieldValue(
                  "cellNucleus",
                  e.target.value === "true" ? true : false
                );
              }}
            >
              <Row className="mb-0">
                <Col xs={1}>
                  <LabelCustom
                    label="Nhân tế bào"
                    horizontal={true}
                    style={{
                      minWidth: 120,
                      textAlign: "left",
                      paddingRight: "8px",
                    }}
                    // styleLabel={{
                    //   minWidth: 120,
                    //   textAlign: 'right'
                    // }}
                  />
                </Col>
                <Col xs={4} className={"d-flex align-items-center ml-4"}>
                  <InputCustom
                    name="cellNucleusRadio"
                    id={"cellNucleusRadio1"}
                    disabled={isDisabled}
                    type="radio"
                    label="Có"
                    value={true}
                    checked={formik.values.cellNucleus}
                    row={true}
                  />
                  <InputCustom
                    name="cellNucleusRadio"
                    id={"cellNucleusRadio2"}
                    type="radio"
                    disabled={isDisabled}
                    label="Không"
                    row={true}
                    value={false}
                    checked={!formik.values.cellNucleus}
                  />
                </Col>
              </Row>
            </FormGroup>
          </Col>
        </Row>
        <Row>
          <Col xs={6}>
            <InputCustom
              label="Đối chứng âm"
              labelWidth={120}
              lableTextAlign={"left"}
              disabled={isDisabled}
              placeholder="Nhập vào đối chứng"
              row={true}
              value={formik.values.negativeControl}
              handleChange={(e) => {
                formik.setFieldValue("negativeControl", e.target.value);
              }}
              error={formik.errors.negativeControl}
              touched={formik.touched.negativeControl}
            />
          </Col>
        </Row>
      </>
    );
  }
  // Nhóm sản
  if (testValue == 2) {
    return (
      <>
        <Row>
          <Col xs={12}>
            <InputCustom
              label="Triệu chứng"
              labelWidth={120}
              placeholder="Nhập triệu chứng"
              lableTextAlign={"left"}
              disabled={isDisabled}
              type="textarea"
              row={true}
              value={formik.values.symptom}
              handleChange={(e) => {
                formik.setFieldValue("symptom", e.target.value);
              }}
              error={formik.errors.symptom}
              touched={formik.touched.symptom}
            />
          </Col>
        </Row>
        <Row>
          <Col xs={12}>
            <InputCustom
              label="Chẩn đoán"
              lableTextAlign={"left"}
              disabled={isDisabled}
              labelWidth={120}
              placeholder="Nhập chẩn đoán"
              type="textarea"
              row={true}
              value={formik.values.diagnose}
              handleChange={(e) => {
                formik.setFieldValue("diagnose", e.target.value);
              }}
              error={formik.errors.diagnose}
              touched={formik.touched.diagnose}
            />
          </Col>
        </Row>
        <Row>
          <Col xs={12}>
            <InputCustom
              label="Chẩn đoán hình ảnh"
              labelWidth={120}
              placeholder="Nhập chẩn đoán"
              disabled={isDisabled}
              type="textarea"
              labelWrap={true}
              row={true}
              value={formik.values.imageAnalysation}
              handleChange={(e) => {
                formik.setFieldValue("imageAnalysation", e.target.value);
              }}
              error={formik.errors.imageAnalysation}
              touched={formik.touched.imageAnalysation}
            />
          </Col>
        </Row>
        <Row>
          <Col xs={12}>
            <InputCustom
              label="Xét nghiệm liên quan"
              labelWrap={true}
              lableTextAlign={"left"}
              disabled={isDisabled}
              placeholder="Nhập xét nghiệm"
              labelWidth={120}
              type="textarea"
              row={true}
              value={formik.values.test}
              handleChange={(e) => {
                formik.setFieldValue("test", e.target.value);
              }}
              error={formik.errors.test}
              touched={formik.touched.test}
            />
          </Col>
        </Row>
        <Row>
          <Col xs={12}>
            <InputCustom
              label="Cách thức điều trị"
              labelWidth={120}
              placeholder="Nhập cách thức"
              labelWrap={true}
              type="textarea"
              lableTextAlign={"left"}
              disabled={isDisabled}
              row={true}
              alignRow={"flex-start"}
              value={formik.values.treatment}
              handleChange={(e) => {
                formik.setFieldValue("treatment", e.target.value);
              }}
              error={formik.errors.treatment}
              touched={formik.touched.treatment}
            />
          </Col>
        </Row>
        <Row>
          <Col xs={3}>
            <InputCustom
              row
              label="Thời gian điều trị (ngày)"
              labelWrap={true}
              type="number"
              lableTextAlign={"left"}
              disabled={isDisabled}
              value={formik.values.timeTreatment}
              placeholder="Nhập số"
              handleChange={(e) => {
                formik.setFieldValue("timeTreatment", e.target.value);
              }}
              error={formik.errors.timeTreatment}
              touched={formik.touched.timeTreatment}
            />
          </Col>
        </Row>
        <Row>
          <Col xs={12}>
            <InputCustom
              label="Dấu hiệu kháng thuốc"
              labelWidth={120}
              placeholder="Nhập dấu hiệu"
              labelWrap={true}
              lableTextAlign={"left"}
              disabled={isDisabled}
              type="textarea"
              row={true}
              value={formik.values.drugResistance}
              handleChange={(e) => {
                formik.setFieldValue("drugResistance", e.target.value);
              }}
              error={formik.errors.drugResistance}
              touched={formik.touched.drugResistance}
            />
          </Col>
        </Row>
        <Row>
          <Col xs={12}>
            <InputCustom
              label="Tái phát bệnh"
              labelWidth={120}
              placeholder="Nhập vào"
              row={true}
              value={formik.values.diseaseRecurrence}
              disabled={isDisabled}
              handleChange={(e) => {
                formik.setFieldValue("diseaseRecurrence", e.target.value);
              }}
              error={formik.errors.diseaseRecurrence}
              touched={formik.touched.diseaseRecurrence}
            />
          </Col>
        </Row>
      </>
    );
  }
  // Nhóm sản
  return (
    <>
      <Row>
        <Col xs={3}>
          <SelectCustom
            id={"typePregnancy"}
            name={"typePregnancy"}
            isRequired={true}
            label="Số lượng thai"
            isDisabled={isDisabled}
            placeholder="Chọn loại"
            labelWidth={200}
            type="number"
            row={true}
            value={_.find(CONSTANT.SPECIFY_VOTES_TYPE_PREGNANCY, {
              value: parseInt(formik.values.typePregnancy),
            })}
            handleChange={(e) => {
              formik.setFieldValue("typePregnancy", e?.value ?? -1);
            }}
            error={formik.errors.typePregnancy || formik.errors.typePregnancy}
            touched={formik.touched.typePregnancy}
            options={CONSTANT.SPECIFY_VOTES_TYPE_PREGNANCY}
          />
        </Col>
        <Col xs={3}>
          <InputCustom
            label="Tuần thai"
            placeholder="Nhập số"
            isRequired={true}
            labelWidth={120}
            disabled={isDisabled}
            row={true}
            type="number"
            id="weekPregnancy"
            name="weekPregnancy"
            value={
              !!formik?.values?.weekPregnancy
                ? formik?.values?.weekPregnancy
                : ""
            }
            handleChange={(e) => {
              formik.setFieldValue("weekPregnancy", e?.target?.value ?? -1);
            }}
            handleBlur={formik.handleBlur}
            error={formik.errors.weekPregnancy }
            touched={!!formik.touched.weekPregnancy}
          />
        </Col>
        <Col xs={3}>
          <InputCustom
            label="Ngày thai"
            placeholder="Nhập số"
            isRequired={true}
            labelWidth={120}
            disabled={isDisabled}
            row={true}
            type="number"
            id="dayOfPregnancy"
            name="dayOfPregnancy"
            value={formik?.values?.dayOfPregnancy}
            handleChange={(e) => {
              formik.setFieldValue("dayOfPregnancy", e?.target?.value ?? -1);
            }}
            handleBlur={formik.handleBlur}
            error={formik.errors.dayOfPregnancy}
            touched={formik.touched.dayOfPregnancy}
          />
        </Col>

        <Col xs={3}>
          <DatePickerCustom
            label="Ngày siêu âm"
            labelWidth={120}
            disabled={isDisabled}
            row={true}
            type="number"
            id="ultrasoundDays"
            name="ultrasoundDays"
            selected={
              !!formik?.values?.ultrasoundDays
                ? new Date(formik?.values?.ultrasoundDays)
                : null
            }
            handleChange={(date) => {
              formik.setFieldValue(
                "ultrasoundDays",
                moment(date).toISOString()
              );
            }}
            handleBlur={formik.handleBlur}
            error={formik.errors.ultrasoundDays}
            touched={formik.touched.ultrasoundDays}
          />
        </Col>
      </Row>
      <Row>
        <Col xs={3} className="align-content-between">
          <InputCustom
            isDisplayLine={true}
            label="Chiều dài đầu mông (mm)"
            labelWidth={200}
            disabled={isDisabled}
            type="number"
            id="height1"
            name="height1"
            placeholder="Nhập số"
            row={true}
            labelWrap={true}
            value={formik.values.height1}
            handleChange={(e) => {
              formik.setFieldValue("height1", e?.target?.value ?? -1);
            }}
            handleBlur={formik.handleBlur}
            error={formik.errors.height1}
            touched={formik.touched.height1}
          />
        </Col>
        <Col xs={3}>
          <InputCustom
            label="Độ mờ da gáy"
            disabled={isDisabled}
            type="number"
            placeholder="Nhập số"
            id="nappySkin"
            name="nappySkin"
            labelWidth={120}
            row={true}
            value={formik.values.nappySkin}
            handleChange={(e) => {
              formik.setFieldValue("nappySkin", e?.target?.value ?? -1);
            }}
            handleBlur={formik.handleBlur}
            error={formik.errors.nappySkin}
            touched={formik.touched.nappySkin}
          />
        </Col>
      </Row>
      <Row>
        <Col xs={12}>
          <InputCustom
            label="Kết quả nguy cơ của combined test"
            disabled={isDisabled}
            type="textarea"
            id="result3"
            name="result3"
            styleInput={{
              minHeight: 100,
            }}
            placeholder={"Nhập vào kết quả"}
            value={formik.values.result3}
            handleChange={(e) => {
              formik.setFieldValue("result3", e.target.value);
            }}
            handleBlur={formik.handleBlur}
            error={formik.errors.result3}
            touched={formik.touched.result3}
          />
        </Col>
      </Row>
      <Row>
        <Col xs={12}>
          <InputCustom
            label="Kết quả siêu âm"
            disabled={isDisabled}
            type="textarea"
            id="result4"
            name="result4"
            styleInput={{
              minHeight: 100,
            }}
            placeholder={"Nhập vào kết quả"}
            value={formik.values.result4}
            handleChange={(e) => {
              formik.setFieldValue("result4", e.target.value);
            }}
            handleBlur={formik.handleBlur}
            error={formik.errors.result4}
            touched={formik.touched.result4}
          />
        </Col>
      </Row>
    </>
  );
}
function TestGroupInfomation({ testValue, setTestValue, formik, isDisabled }) {
  // const { submitForm, formik.errors } = useFormikContext();
  const history = useHistory();
  const dispatch = useDispatch();
  return (
    <Card>
      <CardHeader>
        <h3 className="mb-0">Thông tin nhóm xét nghiệm</h3>
      </CardHeader>
      <CardBody>
        <Row>
          <Col xs={12}>
            <Header
              testValue={testValue}
              formik={formik}
              isDisabled={isDisabled}
            />
            <Row className="mt-4 mb-4">
              <Col xs={12} className="d-flex justify-content-end mt-2">
                <Button
                  onClick={() => {
                    history.push("/admin/specify-vote-all");
                  }}
                >
                  Hủy bỏ
                </Button>

                <Button
                  style={{ backgroundColor: "#4690CD", color: "white" }}
                  onClick={() => {
                    dispatch(
                      specifyVotesActions.setValueSpecifyVote({
                        name: "isSubmitForm",
                        value: {
                          name: "submitAndPrint",
                          value: true,
                        },
                      })
                    );
                  }}
                >
                  Lưu và in phiếu
                </Button>
                <Button
                  style={{ backgroundColor: "#4690CD", color: "white" }}
                  onClick={async () => {
                    try {
                      await formik.validateField();
                      dispatch(
                        specifyVotesActions.setValueSpecifyVote({
                          name: "isSubmitForm",
                          value: {
                            name: "submitAndExit",
                            value: true,
                          },
                        })
                      );
                    } catch (error) {}
                  }}
                >
                  Lưu và thoát
                </Button>
              </Col>
            </Row>
          </Col>
        </Row>
      </CardBody>
    </Card>
  );
}

export default TestGroupInfomation;
