import typeActions from 'redux/typeActions';

const updateTimeline = ({ path, search = '', currentAccountExtend }) => {
  return {
    type: typeActions.UPDATE_TEXT_TIMELINE,
    path,
    search,
    currentAccountExtend,
  };
};

const setValueTimelineReducer = ({ name, value }) => {
  return {
    type: typeActions.SET_VALUE_TIMELINE_REDUCER,
    name,
    value,
  };
};

export default {
  updateTimeline,
  setValueTimelineReducer,
};
