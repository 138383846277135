import React, { useEffect, useRef, useState } from 'react';
import _ from 'lodash';
import queryString from 'query-string';
import { useHistory } from 'react-router';
import { useDispatch, useSelector } from 'react-redux';
import { Button, Col, Modal, Row } from 'reactstrap';
import { formActions, additionalSampleActions } from 'redux/actions';
import InputCustom from 'views/pages/components/InputCustom';
import { notificationAlertRef, notify } from 'common';
import CONSTANT from 'constant';

const ConfirmBarcode = ({ handleGetAdditionalSamples }) => {
  const refBarcode1 = useRef();
  const refBarcode2 = useRef();
  const refBarcode3 = useRef();
  const { isModalConfirmBarcodeVisible } = useSelector(
    (state) => state.formReducer
  );
  const { additionalSample } = useSelector(
    (state) => state.additionalSampleReducer
  );
  const dispatch = useDispatch();
  const [barcodes, setBarcodes] = useState({
    barcode1: '',
    barcode2: '',
    barcode3: '',
  });

  const handleClose = () => {
    dispatch(
      formActions.setValue({
        name: 'isModalConfirmBarcodeVisible',
        value: false,
      })
    );
    dispatch(
      formActions.setValue({
        name: 'additionalSample',
        value: {},
      })
    );
    setBarcodes({
      barcode1: '',
      barcode2: '',
      barcode3: '',
    });
  };

  const checkValidBarcodes = async (
    position,
    length = [],
    additionalSampleId
  ) => {
    const isValid =
      _.uniq(
        Object.keys(barcodes)
          .map((key) => barcodes[key])
          .filter((barcode) => barcode !== '')
      ).length === 1 ||
      _.uniq(
        Object.keys(barcodes)
          .map((key) => barcodes[key])
          .filter((barcode) => barcode !== '')
      ).length === 0;

    if (!isValid) {
      notify(
        notificationAlertRef,
        'danger',
        'Thông báo',
        `Mã barcode không khớp!`
      );
    } else {
      if (
        Object.keys(barcodes)
          .map((key) => barcodes[key])
          .filter((item) => item !== '').length === length ||
        length === 1
      ) {
        handleClose();
        dispatch(
          additionalSampleActions.updateAdditionalSample(
            additionalSampleId,
            {
              status: CONSTANT.ORDER_STATUS[3].value,
              receptionStatus: true,
              timeReceiveTest: new Date(),
            },
            {
              success: () => {
                handleGetAdditionalSamples();
                notify(
                  notificationAlertRef,
                  'success',
                  'Thông báo',
                  `Tiếp nhận thành công!`
                );
              },
              failed: () => {
                notify(
                  notificationAlertRef,
                  'danger',
                  'Thông báo',
                  `Tiếp nhận không thành công!`
                );
              },
            }
          )
        );

        return;
      }
    }

    if (!additionalSample || length <= position) return;
    switch (position + 1) {
      case 1:
        refBarcode2.current.focus();
        break;
      case 2:
        refBarcode3.current.focus();
        break;
      default:
        break;
    }
  };

  const handleGetAdditionalSampleBy = (value) => {
    // Là đơn có barcode đã sử dụng, đơn đã thanh toán, khác đơn hủy và khác đơn đã trả
    const delayDebounceFn = setTimeout(() => {
      dispatch(
        additionalSampleActions.getAdditionalSampleBy(
          queryString.stringify({
            'filters[$and][1][$or][0][status][$eq]':
              CONSTANT.ORDER_STATUS[1].value,
            'filters[$and][1][$or][1][status][$eq]':
              CONSTANT.ORDER_STATUS[3].value,
            'filters[$or][2][barcode][barcode][$eq]': value,
            'filters[$and][3][receptionStatus][$eq]': false,
          }),
          {
            success: (data) => {
              if (_.isEmpty(data)) return;
              checkValidBarcodes(0, data.numOfSpecimen, data.id);
            },
          }
        )
      );
    }, 500);
    return () => clearTimeout(delayDebounceFn);
  };

  useEffect(() => {
    if (refBarcode1?.current) {
      refBarcode1.current.focus();
    }
  }, [refBarcode1?.current?.focus, isModalConfirmBarcodeVisible]);

  return (
    <Modal
      onOpened={() => {
        if (refBarcode1?.current) {
          refBarcode1.current.focus();
        }
      }}
      size={'md'}
      isOpen={isModalConfirmBarcodeVisible}
      className="modal-dialog-centered modal-secondary"
      toggle={() => {
        handleClose();
      }}
    >
      <div className="modal-header badditionalSample-bottom bg-white pb-2">
        <h2>Tiếp nhận mẫu bổ sung</h2>
        <button
          aria-label="Close"
          className="close"
          data-dismiss="modal"
          type="button"
          onClick={() => {
            handleClose();
          }}
        >
          <span style={{ color: '#262626' }} aria-hidden={true}>
            ×
          </span>
        </button>
      </div>
      <div className="modal-body bg-white rounded">
        <Row>
          <Col xs={6}>
            <InputCustom
              ref={refBarcode1}
              name="barcode1"
              label="Mã barcode mẫu xét nghiệm 1"
              type="text"
              placeholder="Quét barcode mẫu xét nghiệm 1"
              value={barcodes.barcode1}
              handleChange={(e) => {
                setBarcodes({
                  ...barcodes,
                  barcode1: e.target.value,
                });
                handleGetAdditionalSampleBy(e.target.value);
              }}
              onKeyDown={(e) => {
                if (e.key === 'Enter') {
                }
              }}
            />
          </Col>
          <Col xs={6}>
            <InputCustom
              ref={refBarcode2}
              name="barcode2"
              label="Mã barcode mẫu xét nghiệm 2"
              type="text"
              placeholder="Quét barcode mẫu xét nghiệm 2"
              value={barcodes.barcode2}
              onKeyDown={(e) => {
                if (e.key === 'Enter') {
                  checkValidBarcodes(
                    1,
                    additionalSample?.numOfSpecimen ?? 0,
                    additionalSample.id
                  );
                }
              }}
              styleInput={{
                display:
                  additionalSample && (additionalSample?.numOfSpecimen ?? 0) > 1
                    ? 'block'
                    : 'none',
              }}
              styleLabel={{
                display:
                  additionalSample && (additionalSample?.numOfSpecimen ?? 0) > 1
                    ? 'block'
                    : 'none',
              }}
              handleChange={(e) => {
                setBarcodes({
                  ...barcodes,
                  barcode2: e.target.value,
                });
              }}
            />
          </Col>
        </Row>
        {additionalSample && (additionalSample?.numOfSpecimen ?? 0) > 2 && (
          <Row>
            <Col xs={6}>
              <InputCustom
                ref={refBarcode3}
                name="barcode3"
                label="Mã barcode mẫu xét nghiệm 3"
                type="text"
                placeholder="Quét barcode mẫu xét nghiệm 3"
                value={barcodes.barcode3}
                onKeyDown={(e) => {
                  if (e.key === 'Enter') {
                    checkValidBarcodes(
                      2,
                      additionalSample?.numOfSpecimen ?? 0,
                      additionalSample.id
                    );
                  }
                }}
                styleInput={{
                  display:
                    additionalSample &&
                    (additionalSample?.numOfSpecimen ?? 0) > 2
                      ? 'block'
                      : 'none',
                }}
                styleLabel={{
                  display:
                    additionalSample &&
                    (additionalSample?.numOfSpecimen ?? 0) > 2
                      ? 'block'
                      : 'none',
                }}
                handleChange={(e) => {
                  setBarcodes({
                    ...barcodes,
                    barcode3: e.target.value,
                  });
                }}
              />
            </Col>
          </Row>
        )}
        <Row>
          <Col xs={12} className="d-flex justify-content-end">
            <Button
              onClick={() => {
                handleClose();
              }}
            >
              <span>Huỷ</span>
            </Button>
          </Col>
        </Row>
      </div>
    </Modal>
  );
};

export default ConfirmBarcode;
