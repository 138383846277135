import typeActions from 'redux/typeActions';

const setValue = ({ name, value }) => {
  return {
    type: typeActions.SET_PROMOTION_VALUE,
    name,
    value,
  };
};

const getPromotions = (query, callback, isUpdateReducer = true) => {
  return {
    type: typeActions.GET_PROMOTIONS_REQUEST,
    query,
    callback,
    isUpdateReducer,
  };
};

const getPromotion = (param, query, callback) => {
  return {
    type: typeActions.GET_PROMOTION_REQUEST,
    param,
    query,
    callback,
  };
};

const createPromotion = (body, callback) => {
  return {
    type: typeActions.CREATE_PROMOTION_REQUEST,
    body,
    callback,
  };
};
const updatePromotion = (param, body, callback) => {
  return {
    type: typeActions.UPDATE_PROMOTION_REQUEST,
    param,
    body,
    callback,
  };
};
const deletePromotion = (param, callback) => {
  return {
    type: typeActions.DELETE_PROMOTION_REQUEST,
    param,
    callback,
  };
};

const exportPromotion = (query, callback) => {
  return {
    type: typeActions.EXPORT_PROMOTION_REQUEST,
    callback,
    query,
  };
};
const exportPromotionDetail = (query, callback) => {
  return {
    type: typeActions.EXPORT_PROMOTION_DETAIL_REQUEST,
    callback,
    query,
  };
};

export default {
  setValue,
  getPromotions,
  getPromotion,
  createPromotion,
  updatePromotion,
  deletePromotion,
  exportPromotion,
  exportPromotionDetail
};
