import { returnValue } from "common";
import typeActions from "redux/typeActions";
const initialState = {
  patients: null,
  currentPatient: null,
  pagination: {},
  // form show/delete
  isModalVisible: false,
  modalInfo: {},
  page: 1,
  limit: 10,
  query: {
    populate: "*",
  },
};
const patientReducer = (state = initialState, actions) => {
  switch (actions.type) {
    case typeActions.SET_PATIENT_VALUE:
      const namePatient = actions.name;
      const valuePatient = actions.value;
      if (typeof namePatient !== "string") return { ...state };
      const objPatient = returnValue(state, namePatient, valuePatient);
      return {
        ...state,
        ...objPatient,
      };
    case typeActions.GET_PATIENT_REQUEST:
      return {
        ...state,
        ...actions?.data,
      };
    case typeActions.GET_PATIENT_SUCCESS:
      return {
        ...state,
        patients: actions.data.results,
        pagination: actions.data.pagination,
        page: actions.data.pagination.page,
        limit: actions.data.pagination.pageSize
      };
    case typeActions.GET_PATIENT_FAILED:
      return {
        ...state,
      };

    case typeActions.GET_PATIENT_BY_ID_SUCCESS: 
      return {
        ...state,
        currentPatient: actions.data
      }
    // create
    
    case typeActions.CREATE_PATIENT_REQUEST:
      return {
        ...state,
        errors: {
          ...state.errors,
          createAccount: '',
        },
      };
    case typeActions.CREATE_PATIENT_SUCCESS:
      return {
        ...state,
        errors: {
          ...state.errors,
          createAccount: '',
        },
      };
    case typeActions.CREATE_PATIENT_FAILED:
      return {
        ...state,
        errors: {
          ...state.errors,
          createAccount: actions.error,
        },
      };

    //delete
    case typeActions.DELETE_PATIENT_REQUEST:
      return {
        ...state,
      };
    case typeActions.DELETE_PATIENT_SUCCESS:
      return {
        ...state,
      };
    case typeActions.DELETE_PATIENT_FAILED:
      return {
        ...state,
      };
    default:
      return {
        ...state,
      };
  }
};

export default patientReducer;
