import React, { memo, useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  Button,
  CardBody,
  CardHeader,
  Col,
  Form,
  Input,
  Label,
  Modal,
  Row,
} from 'reactstrap';
import * as yup from 'yup';
import { Formik } from 'formik';
import _ from 'lodash';

import { notificationAlertRef, notify } from 'common';
import LabelCustom from 'views/pages/components/LabelCustom';
import {
  accountActions,
  areaActions,
  formActions,
  organizationActions,
  provinceActions,
} from 'redux/actions';
import HelperText from 'views/pages/components/HelperText';
import queryString from 'query-string';
import LoadingButtonCustom from 'views/pages/components/LoadingButtonCustom';
import CONSTANT from 'constant';
import { useDelayInputEffect } from 'utils/HookUtils';
import SelectCustom from 'views/pages/components/SelectCustom';
import 'assets/css/custom-pages/grid.css';
const CUOrganization = (props) => {
  //redux
  const dispatch = useDispatch();
  const { page, search, total, limit, query } = useSelector(
    (state) => state.areaReducer
  );
  const { isModalVisible, modalInfo } = useSelector(
    (state) => state.formReducer
  );
  /**
   * Convert [{id:'',name:'',...}] to [{label: '',value: ''}]
   * @param {Array<Object>} arr : array data
   * @param {Array<string>} label : properties of an element object in "arr"
   * @param {Array<string>} value : properties of an element object in "arr"
   * iterate over each element in the "arr":
   *  -> label/value = ["firstname+lastname"] = element.firstname + element.lastname
   *  -> value = ["name","lastname"] = element.name.firstname
   * @returns {Array<Object>}
   */
  function getListSelectOption(arr, label, value) {
    if (!Array.isArray(arr) || !Array.isArray(label) || !Array.isArray(value))
      return [];
    try {
      return arr.map((item) => {
        let lb = item,
          vl = item;
        label.map((_) => {
          if (_.includes('+'))
            lb = _.split('+')
              .map((__) => {
                if (__.includes('code')) return `(${lb[__]})`;
                else return lb[__];
              })
              .join(' ');
          else lb = lb[_];
        });
        value.map((_) => {
          vl = vl[_];
        });
        return {
          label: lb,
          value: vl,
        };
      });
    } catch (error) {
      return [];
    }
  }
  //state
  const [provinceSelectInput, setProvinceSelectInput] = useState('');
  const [staffSelectInput, setStaffSelectInput] = useState('');
  const [areaSelectInput, setAreaSelectInput] = useState('');
  const [saleSearch, setSaleSearch] = useState('');
  const [provinceOptions, setProvinceOptions] = useState([]);
  const [staffOptions, setStaffOptions] = useState([]);
  const [areaOptions, setAreaOptions] = useState([]);

  const [provinceValue, setProvinceValue] = useState(null);
  const [statusValue, setStatusValue] = useState(null);
  const [methodValue, setMethodValue] = useState(null);
  const [areaValue, setAreaValue] = useState(null);

  const [organizationTypeVal, setOrganizationTypeVal] = useState(0);

  //type form
  const isUpdate = !_.isEmpty(modalInfo);
  const [body, setBody] = useState([]);
  const { isView, name } = modalInfo;

  const handleClose = () => {
    dispatch(
      formActions.setValue({
        name: 'isModalVisible',
        value: false,
      })
    );
    dispatch(
      formActions.setValue({
        name: 'modalInfo',
        value: {},
      })
    );
    setBody([]);
  };
  //actions:  handle search in select options
  const areaAC = areaActions.getAreas(
    queryString.stringify({
      _q: areaSelectInput,
      ...query,
      page: 1,
      pageSize: 10,
    }),
    {
      success: (data) => {
        setAreaOptions(getListSelectOption(data?.results, ['name'], ['id']));
      },
      failed: (error) => {
        notify(
          notificationAlertRef,
          'failed',
          'Lỗi tìm dữ liệu khu vực',
          `Lỗi: ${error}`
        );
      },
    },
    false
  );
  useEffect(() => {}, [isUpdate, modalInfo]);

  const handleGetSales = () => {
    const query = {
      'filters[$and][1][accountType][$eq]': CONSTANT.ACCOUNT_TYPE[1].value,
      'filters[$and][2][isActive][$eq]': true,
    };
    if (saleSearch !== '') {
      query['filters[$and][3][fullname][$containsi]'] = saleSearch;
    }

    dispatch(
      accountActions.getAccountsExtend(
        queryString.stringify(query),
        {
          success: (data) => {
            const results = _.get(data, 'results', []);
            setStaffOptions(
              results.map((item) => ({
                label: `${item?.code ?? ''} - ${item?.fullname ?? ''} `,
                value: item.id,
              }))
            );
          },
          failed: () => {},
        },
        false
      )
    );
  };
  const provinceAC = provinceActions.getProvinces(
    queryString.stringify({
      _q: provinceSelectInput,
      ...query,
      page: 1,
      pageSize: 10,
    }),
    {
      success: (data) => {
        setProvinceOptions(
          getListSelectOption(data?.results, ['name'], ['id'])
        );
        if (isUpdate) {
        }
      },
      failed: (error) => {
        notify(
          notificationAlertRef,
          'danger',
          'Lỗi tìm dữ liệu tỉnh',
          `Lỗi: ${error}`
        );
        setProvinceOptions([]);
      },
    },
    false
  );
  // init add form
  useEffect(() => {
    setStatusValue(CONSTANT.ORGANIZATION_STATUS_TYPE[0]);
    setMethodValue(CONSTANT.ORGANIZATION_METHOD_TYPE[0]);
  }, []);
  useDelayInputEffect(areaAC, areaSelectInput, 700);
  // useDelayInputEffect(accountExtendAC, staffSelectInput, 700);
  useDelayInputEffect(provinceAC, provinceSelectInput, 700);

  function callListOrganization() {
    dispatch(
      organizationActions.getOrganizations(
        queryString.stringify({
          ...query,
          page: page,
          pageSize: limit,
          _q: search,
        }),
        {
          success: (data) => {},
          failed: () => {},
        },
        true
      )
    );
  }
  //init form
  const initInfo = isUpdate
    ? {
        code: modalInfo?.code ?? '',
        name: modalInfo?.name ?? '',
        area: modalInfo?.area?.id ?? '',
        province: modalInfo?.province?.id ?? '',
        organizationType: modalInfo?.organizationType ?? '',
        staff: modalInfo?.staff ?? '',
        status: modalInfo?.status ?? '',
        method: modalInfo?.method ?? 1,
        address: modalInfo?.address ?? '',

        nameRepresentative: modalInfo?.nameRepresentative ?? '',
        positionRepresentative: modalInfo?.positionRepresentative ?? '',
        emailRepresentative: modalInfo?.emailRepresentative ?? '',
        phoneRepresentative: modalInfo?.phoneRepresentative ?? '',

        nameContact: modalInfo?.nameContact ?? '',
        positionContact: modalInfo?.positionContact ?? '',
        emailContact: modalInfo?.emailContact ?? '',
        phoneContact: modalInfo?.phoneContact ?? '',
        // mainResponsible: modalInfo?.mainResponsible?.id ?? null,
      }
    : {
        code: '',
        name: '',
        area: '',
        province: '',
        organizationType: 1,
        staff: '',
        status: CONSTANT.ORGANIZATION_STATUS_TYPE[0].value,
        method: CONSTANT.ORGANIZATION_METHOD_TYPE[0].value,
        address: '',

        nameRepresentative: '',
        positionRepresentative: '',
        emailRepresentative: '',
        phoneRepresentative: '',

        nameContact: '',
        positionContact: '',
        emailContact: '',
        phoneContact: '',
        // mainResponsible: null,
      };
  //validate form
  const organizationSchema = yup.object().shape({
    code: yup
      .string()
      // .length(10, 'Số điện thoại đặt làm mã tổ chức phải bao gồm 10 số!')
      .required('Mã tổ chức không được để trống!'),
    // .matches('[0-9]+', 'Vui lòng nhập đúng định dạng số cho mã tổ chức!')
    name: yup.string().required('Tên tổ chức không được để trống!'),
    area: yup.string().required('Khu vực không được để trống!'),
    province: yup.string().required('Tỉnh không được để trống!'),
    organizationType: yup
      .string()
      .required('Loại tổ chức không được để trống!'),
    status: yup.string().required('Trạng thái không được để trống!'),
    method:
      organizationTypeVal === 1
        ? yup.string().required('Phương thức thanh toán không được để trống!')
        : '',
    // nameRepresentative: yup
    //   .string()
    //   .required('Họ tên người đại diện không được để trống!'),
    // positionRepresentative: yup
    //   .string()
    //   .required('Chức vụ người đại diện không được để trống!'),
    // nameRepresentative: yup.string(),
    // nameRepresentative: yup
    //   .string()
    //   .required('Họ tên người đại diện không được để trống!'),

    // emailRepresentative: yup
    //   .string()
    //   .required('Email người đại diện không được để trống!')
    //   .email('Email không đúng định dạng!'),
    // phoneRepresentative: yup
    //   .string()
    //   .required('Số điện thoại người đại diện không được để trống!')
    //   .length(10, 'Số điện thoại bao gồm 10 số!')
    //   .matches('0[1-9][0-9]{8}', 'Vui lòng nhập đúng định dạng số điện thoại!'),
    emailContact: yup.string().email('Email không đúng định dạng!'),
    phoneContact: yup
      .string()
      .length(10, 'Số điện thoại bao gồm 10 số!')
      .matches('0[1-9][0-9]{8}', 'Vui lòng nhập đúng định dạng số điện thoại!'),
  });
  const styleTitleIsView = {
    textAlign: 'right',
  };
  const styleValueIsView = {
    textAlign: 'left',
    fontSize: 13,
  };
  //init value select box
  useEffect(() => {
    // dispatch(accountExtendAC);
    dispatch(provinceAC);
    if (isUpdate) {
      //Get organization "HT Gen"
      //Get relation organization "HT Gen" to account-extend
      //Get account by id of relation

      setAreaValue({
        label: modalInfo?.area?.name,
        value: modalInfo?.area?.code,
      });
      setProvinceValue({
        label: modalInfo?.province?.name,
        value: modalInfo?.province?.code,
      });
      setStatusValue(
        _.find(CONSTANT.ORGANIZATION_STATUS_TYPE, { value: modalInfo.status })
      );
      setMethodValue(
        _.find(CONSTANT.ORGANIZATION_METHOD_TYPE, { value: modalInfo.method })
      );
    } else {
      setAreaValue(null);
      setProvinceValue(null);
      setStatusValue(CONSTANT.ORGANIZATION_STATUS_TYPE[0]);
    }
  }, [isUpdate, modalInfo]);

  useEffect(() => {
    const delayDebounceFn = setTimeout(() => {
      handleGetSales();
    }, 1000);
    return () => clearTimeout(delayDebounceFn);
  }, [saleSearch]);

  return (
    <Modal
      isOpen={isModalVisible}
      // isOpen={true}
      className="modal-dialog-centered"
      toggle={() => {
        handleClose();
      }}
      style={{ minWidth: 1240 }}
      size={'lg'}
    >
      {isView ? (
        <>
          <CardHeader className="p-0">
            <div className="modal-header">
              <h2 className="mb-0">Xem thông tin tổ chức</h2>
              <button
                aria-label="Close"
                className="close"
                data-dismiss="modal"
                type="button"
                onClick={() => {
                  handleClose();
                }}
              >
                <span aria-hidden={true}>×</span>
              </button>
            </div>
          </CardHeader>
          <div className="modal-body">
            <Row>
              <Col
                xs={6}
                style={{ borderRight: '1px solid rgba(0, 0, 0, 0.1)' }}
              >
                <Row>
                  <Col xs={12}>
                    <LabelCustom
                      isDisplayLine={true}
                      label={'Thông tin tổ chức'}
                      style={{
                        fontSize: 15,
                      }}
                    />
                  </Col>
                </Row>
                <Row>
                  <Col xs={4} style={styleTitleIsView}>
                    <h5>Mã tổ chức:</h5>
                  </Col>
                  <Col xs={6}>
                    <div style={styleValueIsView}>{modalInfo.code}</div>
                  </Col>
                </Row>
                <Row>
                  <Col xs={4} style={styleTitleIsView}>
                    <h5>Tên tổ chức:</h5>
                  </Col>
                  <Col xs={6}>
                    <div style={styleValueIsView}>{modalInfo.name}</div>
                  </Col>
                </Row>
                <Row>
                  <Col xs={4} style={styleTitleIsView}>
                    <h5>Khu vực:</h5>
                  </Col>
                  <Col xs={3}>
                    <div style={styleValueIsView}>
                      {modalInfo?.area?.name ?? ''}
                    </div>
                  </Col>
                  <Col xs={1}>
                    <h5>Tỉnh:</h5>
                  </Col>
                  <Col xs={3}>
                    <div style={styleValueIsView}>
                      {modalInfo?.province?.name ?? ''}
                    </div>
                  </Col>
                </Row>
                <Row>
                  <Col xs={4} style={styleTitleIsView}>
                    <h5>Loại tổ chức:</h5>
                  </Col>
                  <Col xs={6}>
                    <div style={styleValueIsView}>
                      {CONSTANT.ORGANIZATION_TYPE[modalInfo.organizationType]}
                    </div>
                  </Col>
                </Row>
                <Row>
                  <Col
                    xs={modalInfo.organizationType === 1 ? 2 : 4}
                    style={styleTitleIsView}
                    className="ml-1"
                  >
                    <h5>Trạng thái:</h5>
                  </Col>
                  <Col xs={3} style={{ paddingLeft: 12 }}>
                    <div style={styleValueIsView}>
                      {CONSTANT.ORGANIZATION_STATUS_TYPE.find((_) => {
                        return _.value === parseInt(modalInfo.status);
                      })?.label ?? ''}
                    </div>
                  </Col>
                </Row>
                <Row>
                  <Col xs={4} style={styleTitleIsView}>
                    <h5>Phương thức thanh toán:</h5>
                  </Col>
                  <Col xs={6}>
                    <div style={styleValueIsView}>
                      {CONSTANT.ORGANIZATION_METHOD_TYPE.find(
                        (_) => _.value === modalInfo.method
                      )?.label ?? ''}
                    </div>
                  </Col>
                </Row>
                <Row>
                  <Col xs={4} style={styleTitleIsView}>
                    <h5>Địa chỉ:</h5>
                  </Col>
                  <Col xs={6}>
                    <div style={styleValueIsView}>
                      {modalInfo?.address ?? ''}
                    </div>
                  </Col>
                </Row>
              </Col>
              <Col xs={6}>
                <Row>
                  <Col xs={12}>
                    <LabelCustom
                      isDisplayLine={true}
                      label={'Thông tin đại diện'}
                      style={{
                        fontSize: 15,
                      }}
                    />
                  </Col>
                </Row>
                <Row>
                  <Col xs={3} style={styleTitleIsView}>
                    <h5>Họ tên:</h5>
                  </Col>
                  <Col xs={3}>
                    <div style={styleValueIsView}>
                      {modalInfo?.nameRepresentative ?? ''}
                    </div>
                  </Col>
                  <Col xs={3} style={styleTitleIsView}>
                    <h5>Chức vụ:</h5>
                  </Col>
                  <Col xs={3}>
                    <div style={styleValueIsView}>
                      {modalInfo?.positionRepresentative ?? ''}
                    </div>
                  </Col>
                </Row>
                <Row>
                  <Col xs={3} style={styleTitleIsView}>
                    <h5>Email:</h5>
                  </Col>
                  <Col xs={3}>
                    <div style={styleValueIsView}>
                      {modalInfo?.emailRepresentative ?? ''}
                    </div>
                  </Col>
                  <Col xs={3} style={styleTitleIsView}>
                    <h5>Số điện thoại:</h5>
                  </Col>
                  <Col xs={3}>
                    <div style={styleValueIsView}>
                      {modalInfo?.phoneRepresentative ?? ''}
                    </div>
                  </Col>
                </Row>
                <Row>
                  <Col xs={12}>
                    <LabelCustom
                      isDisplayLine={true}
                      label={'Thông tin liên hệ'}
                      style={{
                        fontSize: 15,
                      }}
                    />
                  </Col>
                </Row>
                <Row>
                  <Col xs={3} style={styleTitleIsView}>
                    <h5>Họ tên:</h5>
                  </Col>
                  <Col xs={3}>
                    <div style={styleValueIsView}>
                      {modalInfo?.nameContact ?? ''}
                    </div>
                  </Col>
                  <Col xs={3} style={styleTitleIsView}>
                    <h5>Chức vụ:</h5>
                  </Col>
                  <Col xs={3}>
                    <div style={styleValueIsView}>
                      {modalInfo?.positionContact ?? ''}
                    </div>
                  </Col>
                </Row>
                <Row>
                  <Col xs={3} style={styleTitleIsView}>
                    <h5>Email:</h5>
                  </Col>
                  <Col xs={3}>
                    <div style={styleValueIsView}>
                      {modalInfo?.emailContact ?? ''}
                    </div>
                  </Col>
                  <Col xs={3} style={styleTitleIsView}>
                    <h5>Số điện thoại:</h5>
                  </Col>
                  <Col xs={3}>
                    <div style={styleValueIsView}>
                      {modalInfo?.phoneContact ?? ''}
                    </div>
                  </Col>
                </Row>
              </Col>
              <Col xs={12}>
                <Row className="d-flex justify-content-end">
                  <Button
                    onClick={() => {
                      handleClose();
                    }}
                    className="mr-4"
                  >
                    Đóng
                  </Button>
                </Row>
              </Col>
            </Row>
          </div>
        </>
      ) : (
        <Formik
          initialValues={initInfo}
          enableReinitialize
          validationSchema={organizationSchema}
          onSubmit={(values, { resetForm }) => {
            //create or update
            const body = _.cloneDeep(values);
            //if (organizationType === "Quản lý"), remove payment method.
            if (+body.organizationType === 0) {
              values.method = null;
            }

            if (body.emailContact === '') {
              body.emailContact = null;
            }

            if (body.phoneContact === '') {
              body.phoneContact = null;
            }

            if (!isUpdate) {
              dispatch(
                organizationActions.createOrganization(
                  {
                    ...body,
                    area: {
                      disconnect: [],
                      connect: [
                        {
                          id: body.area,
                          position: {
                            end: true,
                          },
                        },
                      ],
                    },
                    // mainResponsible: relationOneToOne(
                    //   body?.mainResponsible ?? null,
                    //   null
                    // ),
                  },
                  {
                    success: (data) => {
                      notify(
                        notificationAlertRef,
                        'success',
                        '',
                        `Thêm tổ chức ${data.name} thành công !`
                      );
                      dispatch(
                        formActions.setValue({
                          name: 'isModalVisible',
                          value: false,
                        })
                      );
                      callListOrganization();
                      resetForm();
                      setAreaValue(null);
                      setProvinceValue(null);
                      // setStaffValue(null);
                    },
                    failed: (error) => {
                      notify(
                        notificationAlertRef,
                        'danger',
                        'Xảy ra lỗi tạo mới tổ chức!',
                        `Lỗi: ${error}`
                      );
                    },
                  }
                )
              );
            } else {
              dispatch(
                organizationActions.updateOrganization(
                  modalInfo.id,
                  {
                    ...body,
                    area: {
                      disconnect: [],
                      connect: [
                        {
                          id: body.area,
                          position: {
                            end: true,
                          },
                        },
                      ],
                    },
                    // mainResponsible: relationOneToOne(
                    //   body?.mainResponsible ?? null,
                    //   null
                    // ),
                  },
                  {
                    success: (data) => {
                      notify(
                        notificationAlertRef,
                        'success',
                        '',
                        `Sửa tổ chức  thành công !`
                      );
                      dispatch(
                        formActions.setValue({
                          name: 'isModalVisible',
                          value: false,
                        })
                      );
                      callListOrganization();
                    },
                    failed: (error) => {
                      notify(
                        notificationAlertRef,
                        'danger',
                        'Xảy ra lỗi sửa tổ chức!',
                        `Lỗi: ${error}`
                      );
                    },
                  }
                )
              );
            }
          }}
        >
          {({
            values,
            handleSubmit,
            errors,
            touched,
            handleBlur,
            handleChange,
            setFieldValue,
          }) => {
            return (
              <Form
                onSubmit={() => {
                  handleSubmit();
                }}
                className=" form-add-account custom-row"
              >
                <Row>
                  <Col md={12} lg={12}>
                    <CardHeader className="p-0">
                      <div className="modal-header" style={{ padding: 13 }}>
                        <h2>
                          {isView
                            ? 'Xem thông tin'
                            : isUpdate
                            ? 'Sửa thông tin'
                            : 'Thêm mới'}{' '}
                          tổ chức
                        </h2>
                        <button
                          aria-label="Close"
                          className="close"
                          data-dismiss="modal"
                          type="button"
                          onClick={() => {
                            handleClose();
                          }}
                        >
                          <span aria-hidden={true}>×</span>
                        </button>
                      </div>
                    </CardHeader>
                  </Col>
                </Row>
                <CardBody className="py-3">
                  <Row>
                    <Col
                      xs={6}
                      style={{ borderRight: '1px solid rgba(0, 0, 0, 0.1)' }}
                    >
                      <Row>
                        <Col xs={12}>
                          <LabelCustom
                            isDisplayLine={true}
                            label="Thông tin tổ chức"
                          />
                        </Col>
                      </Row>
                      <Row>
                        <Col xs={12}>
                          <LabelCustom
                            isRequired={true}
                            label="Mã tổ chức"
                            id="code"
                          />
                          <Input
                            disabled={isView || isUpdate}
                            id="code"
                            placeholder="Nhập số điện thoại"
                            type="text"
                            className="height-input"
                            name="code"
                            value={values.code}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            invalid={!!errors?.code && !!touched?.code}
                          />
                          {!!errors.code && !!touched.code && (
                            <HelperText message={errors.code} />
                          )}
                        </Col>
                      </Row>
                      <Row className="custom-row-8">
                        <Col xs={12}>
                          <LabelCustom
                            isRequired={true}
                            style={{ marginTop: 8 }}
                            label="Tên tổ chức"
                            id="name"
                          />
                          <Input
                            disabled={isView}
                            id="name"
                            placeholder="Nhập tên tổ chức"
                            type="text"
                            className="height-input"
                            name="name"
                            value={values.name}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            invalid={!!errors?.name && !!touched?.name}
                          />
                          {!!errors.name && !!touched.name && (
                            <HelperText message={errors.name} />
                          )}
                        </Col>
                      </Row>
                      <Row>
                        <Col xs={6}>
                          <SelectCustom
                            label="Khu vực"
                            id="area"
                            placeholder="Chọn khu vực"
                            styles={{
                              control: (baseStyles, state) => ({
                                ...baseStyles,
                                fontSize: '0.875rem',
                              }),
                            }}
                            name="area"
                            isClearable={false}
                            isRequired={true}
                            onBlur={handleBlur}
                            value={areaValue}
                            onChange={(e) => {
                              setAreaValue(e);
                              setFieldValue('area', e.value);
                            }}
                            error={errors.area}
                            touched={touched.area}
                            // defaultInputValue={
                            //   isUpdate
                            //     ? organizations.find(
                            //         (_) => !!_.area && _.area.id === values.area
                            //       )?.area?.name ?? ""
                            //     : ""
                            // }
                            onInputChange={(str) => {
                              setAreaSelectInput(str);
                            }}
                            options={areaOptions}
                          />
                        </Col>
                        <Col xs={6}>
                          <SelectCustom
                            name="province"
                            label="Tỉnh"
                            placeholder="Chọn tỉnh"
                            styles={{
                              control: (baseStyles, state) => ({
                                ...baseStyles,
                                fontSize: '0.875rem',
                              }),
                            }}
                            value={provinceValue}
                            isRequired={true}
                            isClearable={true}
                            handleChange={(e) => {
                              if (e && e.value) {
                                setProvinceValue(e);
                                setFieldValue('province', e.value);
                              }
                            }}
                            handleBlur={handleBlur}
                            onInputChange={(str) => {
                              if (provinceSelectInput !== str) {
                                setProvinceSelectInput(str);
                              }
                            }}
                            error={errors.province}
                            touched={touched.province}
                            options={provinceOptions}
                          />
                        </Col>
                      </Row>
                      <Row>
                        <Col xs={12}>
                          <LabelCustom
                            isRequired={true}
                            style={{ marginTop: 8 }}
                            label="Loại tổ chức"
                            id="name"
                          />
                        </Col>
                        <Col xs={12} className="d-flex">
                          <Col xs={6} className="ml-2">
                            <Label check style={{ cursor: 'pointer' }}>
                              <Input
                                type="radio"
                                name="organizationType"
                                value={1}
                                defaultChecked={
                                  isUpdate
                                    ? values.organizationType === 1
                                    : true
                                }
                                onChange={(e) => {
                                  setFieldValue(
                                    'organizationType',
                                    Number(e.target.value)
                                  );
                                  setOrganizationTypeVal(1);
                                }}
                              />
                              Phòng khám / Bệnh viện
                            </Label>
                          </Col>
                          <Col xs={6} className="ml-2">
                            <Label
                              className="ml-1"
                              check
                              style={{ cursor: 'pointer' }}
                            >
                              <Input
                                type="radio"
                                name="organizationType"
                                value={0}
                                defaultChecked={
                                  isUpdate
                                    ? values.organizationType === 0
                                    : false
                                }
                                onChange={(e) => {
                                  setFieldValue(
                                    'organizationType',
                                    Number(e.target.value)
                                  );
                                  setOrganizationTypeVal(0);
                                }}
                              />
                              Quản lý
                            </Label>
                          </Col>
                        </Col>
                        <Col xs={12}>
                          {!!errors.organizationType &&
                            !!touched.organizationType && (
                              <HelperText message={errors.organizationType} />
                            )}
                        </Col>
                      </Row>
                      <Row>
                        {/* {values.organizationType === 1 ? (
                          <Col xs={6}>
                            <SelectCustom
                              name="staff"
                              label="Sale phụ trách"
                              placeholder="Nhập tên để tìm kiếm"
                              value={staffValue}
                              isClearable={true}
                              isRequired={false}
                              onChange={(e) => {
                                if (!!e && e.value) {
                                  setStaffValue(e);
                                  setFieldValue(
                                    'mainResponsible',
                                    e?.value ?? null
                                  );
                                }
                              }}
                              onBlur={handleBlur}
                              handleBlur={handleBlur}
                              onInputChange={(str) => {
                                if (str !== staffSelectInput) {
                                  setStaffSelectInput(str);
                                }
                              }}
                              error={errors.staff}
                              touched={touched.staff}
                              options={staffOptions}
                            />
                          </Col>
                        ) : (
                          ''
                        )} */}
                        <Col xs={6}>
                          <SelectCustom
                            id={'status'}
                            name="status"
                            label="Trạng thái"
                            placeholder="Chọn trạng thái"
                            value={statusValue}
                            isRequired={true}
                            isClearable={false}
                            onChange={(e) => {
                              setStatusValue(e);
                              setFieldValue('status', e.value);
                            }}
                            handleBlur={handleBlur}
                            onInputChange={() => {}}
                            error={errors.status}
                            touched={touched.status}
                            options={CONSTANT.ORGANIZATION_STATUS_TYPE}
                            styles={{
                              control: (baseStyles, state) => ({
                                ...baseStyles,
                                fontSize: '0.875rem',
                              }),
                            }}
                          />
                        </Col>
                      </Row>
                      {values.organizationType == 1 ? (
                        <Row>
                          <Col xs={6}>
                            <SelectCustom
                              id={'method'}
                              name="method"
                              label="Phương thức thanh toán"
                              placeholder="Chọn trạng thái"
                              value={methodValue}
                              isRequired={true}
                              isClearable={false}
                              onChange={(e) => {
                                setMethodValue(e);
                                setFieldValue('method', e.value);
                              }}
                              handleBlur={handleBlur}
                              onInputChange={() => {}}
                              error={errors.method}
                              touched={touched.method}
                              defaultValue={
                                CONSTANT.ORGANIZATION_METHOD_TYPE[0]
                              }
                              options={CONSTANT.ORGANIZATION_METHOD_TYPE}
                              styles={{
                                control: (baseStyles, state) => ({
                                  ...baseStyles,
                                  fontSize: '13px',
                                }),
                              }}
                            />
                          </Col>
                        </Row>
                      ) : (
                        ''
                      )}
                      <Row>
                        <Col xs={12}>
                          <LabelCustom
                            style={{ marginTop: 8 }}
                            label="Địa chỉ"
                            id="address"
                          />
                          <Input
                            disabled={isView}
                            id="address"
                            placeholder="Nhập địa chỉ tổ chức"
                            type="textarea"
                            className="height-input"
                            name="address"
                            style={{
                              minHeight: 100,
                            }}
                            value={values.address}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            invalid={!!errors?.address && !!touched?.address}
                          />
                          {!!errors.address && !!touched.address && (
                            <HelperText message={errors.address} />
                          )}
                        </Col>
                      </Row>
                    </Col>
                    <Col xs={6}>
                      <Row>
                        <Col xs={12}>
                          <LabelCustom
                            isDisplayLine={true}
                            label="Người đại diện"
                          />
                        </Col>
                      </Row>
                      <Row>
                        <Col xs={6}>
                          <LabelCustom
                            style={{ marginTop: 8 }}
                            label="Họ tên "
                            id="nameRepresentative"
                          />
                          <Input
                            disabled={isView}
                            id="nameRepresentative"
                            placeholder="Nhập tên người đại diện"
                            type="text"
                            className="height-input"
                            name="nameRepresentative"
                            value={values.nameRepresentative}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            invalid={
                              !!errors?.nameRepresentative &&
                              !!touched?.nameRepresentative
                            }
                          />
                          {!!errors.nameRepresentative &&
                            !!touched.nameRepresentative && (
                              <HelperText message={errors.nameRepresentative} />
                            )}
                        </Col>
                        <Col xs={6}>
                          <LabelCustom
                            style={{ marginTop: 8 }}
                            label="Chức vụ"
                            id="positionRepresentative"
                          />
                          <Input
                            disabled={isView}
                            id="positionRepresentative"
                            placeholder="Nhập tên chức vụ"
                            type="text"
                            className="height-input"
                            name="positionRepresentative"
                            value={values.positionRepresentative}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            invalid={
                              !!errors?.positionRepresentative &&
                              !!touched?.positionRepresentative
                            }
                          />
                          {!!errors.positionRepresentative &&
                            !!touched.positionRepresentative && (
                              <HelperText
                                message={errors.positionRepresentative}
                              />
                            )}
                        </Col>
                      </Row>
                      <Row className="custom-row-8">
                        <Col xs={6}>
                          <LabelCustom
                            style={{ marginTop: 8 }}
                            label="Email"
                            id="emailRepresentative"
                          />
                          <Input
                            disabled={isView}
                            id="emailRepresentative"
                            placeholder="Nhập email"
                            type="text"
                            className="height-input"
                            name="emailRepresentative"
                            value={values.emailRepresentative}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            invalid={
                              !!errors?.emailRepresentative &&
                              !!touched?.emailRepresentative
                            }
                          />
                          {!!errors.emailRepresentative &&
                            !!touched.emailRepresentative && (
                              <HelperText
                                message={errors.emailRepresentative}
                              />
                            )}
                        </Col>
                        <Col xs={6}>
                          <LabelCustom
                            style={{ marginTop: 8 }}
                            label="Số điện thoại"
                            id="phoneRepresentative"
                          />
                          <Input
                            disabled={isView}
                            id="phoneRepresentative"
                            placeholder="Nhập số điện thoại"
                            type="text"
                            className="height-input"
                            name="phoneRepresentative"
                            value={values.phoneRepresentative}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            invalid={
                              !!errors?.phoneRepresentative &&
                              !!touched?.phoneRepresentative
                            }
                          />
                          {!!errors.phoneRepresentative &&
                            !!touched.phoneRepresentative && (
                              <HelperText
                                message={errors.phoneRepresentative}
                              />
                            )}
                        </Col>
                      </Row>
                      <Row>
                        <Col xs={12}>
                          <LabelCustom
                            isDisplayLine={true}
                            label="Người liên hệ"
                          />
                        </Col>
                      </Row>
                      <Row>
                        <Col xs={6}>
                          <LabelCustom
                            style={{ marginTop: 8 }}
                            label="Họ tên "
                            id="nameContact"
                          />
                          <Input
                            disabled={isView}
                            id="nameContact"
                            placeholder="Nhập tên người đại diện"
                            type="text"
                            className="height-input"
                            name="nameContact"
                            value={values.nameContact}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            invalid={
                              !!errors?.nameContact && !!touched?.nameContact
                            }
                          />
                          {!!errors.nameContact && !!touched.nameContact && (
                            <HelperText message={errors.nameContact} />
                          )}
                        </Col>
                        <Col xs={6}>
                          <LabelCustom
                            isRequired={false}
                            style={{ marginTop: 8 }}
                            label="Chức vụ"
                            id="positionContact"
                          />
                          <Input
                            disabled={isView}
                            id="positionContact"
                            placeholder="Nhập tên chức vụ"
                            type="text"
                            className="height-input"
                            name="positionContact"
                            value={values.positionContact}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            invalid={
                              !!errors?.positionContact &&
                              !!touched?.positionContact
                            }
                          />
                          {!!errors.positionContact &&
                            !!touched.positionContact && (
                              <HelperText message={errors.positionContact} />
                            )}
                        </Col>
                      </Row>
                      <Row>
                        <Col xs={6}>
                          <LabelCustom
                            isRequired={false}
                            style={{ marginTop: 8 }}
                            label="Email"
                            id="emailContact"
                          />
                          <Input
                            disabled={isView}
                            id="emailContact"
                            placeholder="Nhập email"
                            type="text"
                            className="height-input"
                            name="emailContact"
                            value={values.emailContact}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            invalid={
                              !!errors?.emailContact && !!touched?.emailContact
                            }
                          />
                          {!!errors.emailContact && !!touched.emailContact && (
                            <HelperText message={errors.emailContact} />
                          )}
                        </Col>
                        <Col xs={6}>
                          <LabelCustom
                            isRequired={false}
                            style={{ marginTop: 8 }}
                            label="Số điện thoại"
                            id="phoneContact"
                          />
                          <Input
                            disabled={isView}
                            id="phoneContact"
                            placeholder="Nhập số điện thoại"
                            type="text"
                            className="height-input"
                            name="phoneContact"
                            value={values.phoneContact}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            invalid={
                              !!errors?.phoneContact && !!touched?.phoneContact
                            }
                          />
                          {!!errors.phoneContact && !!touched.phoneContact && (
                            <HelperText message={errors.phoneContact} />
                          )}
                        </Col>
                      </Row>
                    </Col>
                  </Row>
                  <Row>
                    <Col xs={12}>
                      <div className="mb-0 pb-0 mt-1 d-flex justify-content-end">
                        <Button
                          className="text-nowrap mr-1"
                          onClick={() => {
                            handleClose();
                          }}
                        >
                          Hủy
                        </Button>
                        <LoadingButtonCustom
                          onClick={handleSubmit}
                          color="info"
                          className="text-nowrap "
                          type="submit"
                          // loading={
                          //   isCreateRole || isUpdateRole || isUpdatePermission
                          // }
                        >
                          {isUpdate ? 'Lưu lại' : 'Thêm mới'}
                        </LoadingButtonCustom>
                      </div>
                    </Col>
                  </Row>
                </CardBody>
              </Form>
            );
          }}
        </Formik>
      )}
    </Modal>
  );
};

export default memo(CUOrganization);
