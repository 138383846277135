import React, { memo, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Button, Col, Form, Modal, Row } from 'reactstrap';
import * as yup from 'yup';
import { Formik } from 'formik';
import _ from 'lodash';
import queryString from 'query-string';

import { notificationAlertRef, notify, relationOneToOne } from 'common';
import LabelCustom from 'views/pages/components/LabelCustom';
import {
  formActions,
  orderActions,
  accountActions,
  partnerActions,
} from 'redux/actions';
import CONSTANT from 'constant';
import SelectCustom from 'views/pages/components/SelectCustom';
import InputCustom from 'views/pages/components/InputCustom';
import LoadingButtonCustom from 'views/pages/components/LoadingButtonCustom';
const TransferOrder = ({ handleGetOrders, isDepartment }) => {
  const dispatch = useDispatch();

  // reducers
  const {} = useSelector((state) => state.accountReducer);
  const { isModalTranferOrderVisible, modalInfo } = useSelector(
    (state) => state.formReducer
  );
  const { isCreatingOrder } = useSelector((state) => state.orderReducer);
  // Khởi tạo dữ liêu
  const initInfo = {
    doctor: null,
    partner: null,
    department: null,
  };
  const [orderTransferTo, setOrderTransferTo] = useState(0);

  const [partnerSearch, setPartnerSearch] = useState('');
  const [departmentValue, setDepartmentValue] = useState(null);

  const [doctorValue, setDoctorValue] = useState(null);
  const [partnerValue, setPartnerValue] = useState(null);

  const [doctorResults, setDoctorResults] = useState([]);
  const [partnerResults, setPartnerResults] = useState([]);

  // validate dữ liêu
  const infoSchema = yup.object().shape({
    partner: yup.lazy((partner) => {
      if (orderTransferTo === 1) {
        if (!_.isEmpty(partner))
          return yup
            .string()
            .required('Đối tác xét nghiệm không được để trống')
            .nullable();
      }
      return yup.string().nullable();
    }),
    department: yup.lazy((department) => {
      if (orderTransferTo === 0) {
        if (!_.isEmpty(department))
          return yup
            .string()
            .required('Văn phòng không được để trống')
            .nullable();
      }
      return yup.string().nullable();
    }),
  });

  // Xử lý submit
  const onSubmit = (values) => {
    const body =
      orderTransferTo === 0
        ? {
            areaName: departmentValue.areaName,
            departmentName: departmentValue.value,
            receptionStatus: false,
            ids: modalInfo.ids.slice(1),
          }
        : {
            doctor: relationOneToOne(values.doctor, null),
            partner: relationOneToOne(values.partner, null),
            status: CONSTANT.ORDER_STATUS[5].value,
            ids: modalInfo.ids.slice(1),
          };
    dispatch(
      orderActions.updateOrder(modalInfo.ids[0], body, {
        success: () => {
          notify(
            notificationAlertRef,
            'success',
            'Thông báo',
            `Cập nhật mẫu bổ sung thành công!`
          );
          handleGetOrders();
          handleClose();
        },
        failed: (mess) => {
          notify(
            notificationAlertRef,
            'danger',
            'Thông báo',
            `Cập nhật mẫu bổ sung thất bại. Lỗi: ${mess}!`
          );
        },
      })
    );
  };

  // Xử lý đóng form
  const handleClose = () => {
    setDoctorValue(null);
    setPartnerValue(null);
    setDepartmentValue(null);
    dispatch(
      formActions.setValue({
        name: 'isModalTranferOrderVisible',
        value: false,
      })
    );
    dispatch(
      formActions.setValue({
        name: 'modalInfo',
        value: {},
      })
    );
  };

  useEffect(() => {
    const delayDebounceFn = setTimeout(() => {
      handleGetPartners();
    }, 500);

    return () => clearTimeout(delayDebounceFn);
  }, [partnerSearch]);

  const handleGetPartners = () => {
    const query = {
      // 'filters[$and][0][accountType][$eq]': 0,
    };
    if (partnerSearch !== '') {
      query['filters[$and][1][code][$containsi]'] = partnerSearch;
    }

    dispatch(
      partnerActions.getPartners(
        queryString.stringify(query),
        {
          success: (data) => {
            const results = _.get(data, 'results', []);
            setPartnerResults(results);
          },
          failed: () => {},
        },
        false
      )
    );
  };

  const getRelationPartners = (id) => {
    if (id === -1) return;
    dispatch(
      accountActions.getAccountExtendsByPartner(id, {
        success: (data) => {
          setDoctorResults(
            data.map((item) => ({
              label: `${item?.fullname ?? ''}`,
              value: item.id,
              ...item,
            }))
          );
        },
        failed: () => {},
      })
    );
  };

  useEffect(() => {
    if (isModalTranferOrderVisible) {
      setOrderTransferTo(isDepartment ? 0 : 1);
    }
  }, [isModalTranferOrderVisible]);

  return (
    <Modal
      size={'md'}
      isOpen={isModalTranferOrderVisible}
      className="modal-dialog-centered modal-secondary"
      toggle={() => {
        handleClose();
      }}
    >
      <div className="modal-header border-bottom bg-white pb-2">
        <h2>Điều chuyển mẫu xét nghiệm</h2>
        <button
          aria-label="Close"
          className="close"
          data-dismiss="modal"
          type="button"
          onClick={() => {
            handleClose();
          }}
        >
          <span style={{ color: '#262626' }} aria-hidden={true}>
            ×
          </span>
        </button>
      </div>
      <div className="modal-body bg-white rounded">
        <Formik
          initialValues={initInfo}
          enableReinitialize
          onSubmit={onSubmit}
          validationSchema={infoSchema}
        >
          {({ errors, touched, handleSubmit, handleBlur, setFieldValue }) => {
            return (
              <Form onSubmit={handleSubmit} className="">
                <Row>
                  <Col xs={6}>
                    <LabelCustom
                      isRequired={true}
                      label={'Mẫu chuyển đến'}
                      style={{
                        lineHeight: '16.5px',
                      }}
                      styleLabel={{}}
                    />
                  </Col>
                </Row>
                <Row className="d-flex align-items-center justify-content-center">
                  <InputCustom
                    name="orderTransferTo"
                    id={'orderTransferTo1'}
                    type="radio"
                    label="Văn phòng"
                    value={0}
                    row={true}
                    checked={orderTransferTo === 0}
                    onClick={() => {
                      // setOrderTransferTo(0);
                    }}
                  />
                  <div className="w-25" />
                  <InputCustom
                    name="orderTransferTo"
                    id={'orderTransferTo2'}
                    type="radio"
                    label="Đối tác xét nghiệm"
                    row={true}
                    value={1}
                    checked={orderTransferTo === 1}
                    onClick={() => {
                      // setOrderTransferTo(1);
                    }}
                  />
                </Row>
                {orderTransferTo === 0 ? (
                  <Row>
                    <Col xs={6}>
                      <SelectCustom
                        label="Văn phòng"
                        placeholder="Lựa chọn văn phòng"
                        name="department"
                        value={departmentValue}
                        isRequired={true}
                        isClearable={false}
                        handleChange={(e) => {
                          setDepartmentValue(e);
                          setFieldValue('department', e?.value ?? -1);
                        }}
                        handleBlur={handleBlur}
                        // onInputChange={(value) => {
                        //   // setOrderSearch(value);
                        // }}
                        error={errors?.department}
                        touched={touched?.department}
                        options={CONSTANT.DEPARTMENTS}
                      />
                    </Col>
                  </Row>
                ) : (
                  <>
                    {' '}
                    <Row>
                      <Col xs={12}>
                        <SelectCustom
                          name="partner"
                          label="Đối tác xét nghiệm (Lab)"
                          placeholder="Lựa chọn"
                          value={partnerValue}
                          isRequired={true}
                          isClearable={false}
                          handleChange={(e) => {
                            setFieldValue('partner', e?.value ?? null);
                            setPartnerValue(e);
                            getRelationPartners(e?.value ?? -1);
                          }}
                          handleBlur={handleBlur}
                          onInputChange={(value) => {
                            setPartnerSearch(value);
                          }}
                          error={errors.partner}
                          touched={touched.partner}
                          options={partnerResults.map((item) => ({
                            label: item.name,
                            value: item?.id,
                          }))}
                        />
                      </Col>
                    </Row>
                    <Row>
                      <Col xs={12}>
                        <SelectCustom
                          name="doctor"
                          label="Bác sĩ chỉ định"
                          placeholder="Lựa chọn"
                          value={doctorValue}
                          isClearable={false}
                          isDisabled={!partnerValue}
                          handleChange={(e) => {
                            setFieldValue('doctor', e?.value ?? null);
                            setDoctorValue(e);
                          }}
                          handleBlur={handleBlur}
                          error={errors.doctor}
                          touched={touched.doctor}
                          options={doctorResults.map((item) => ({
                            label: `${item?.fullname ?? ''}`,
                            value: item?.id,
                          }))}
                        />
                      </Col>
                    </Row>
                  </>
                )}

                <div className="mb-0 pb-0 mt-4 d-flex justify-content-end">
                  <Button
                    className="text-nowrap mr-1"
                    onClick={() => {
                      handleClose();
                    }}
                  >
                    Hủy
                  </Button>
                  <LoadingButtonCustom
                    color="info"
                    className="text-nowrap "
                    type="submit"
                    loading={isCreatingOrder}
                  >
                    Điều chuyển
                  </LoadingButtonCustom>
                </div>
              </Form>
            );
          }}
        </Formik>
      </div>
    </Modal>
  );
};

export default memo(TransferOrder);
