import typeActions from 'redux/typeActions';
const setValueArea = ({ name, value }) => {
  return {
    type: typeActions.SET_AREA_VALUE,
    name,
    value,
  };
};
const getAreas = (query, callback, isUpdateReducer = true) => {
  return {
    type: typeActions.GET_AREAS_REQUEST,
    query,
    callback,
    isUpdateReducer,
  };
};

const getArea = (param, query, callback) => {
  return {
    type: typeActions.GET_AREA_REQUEST,
    query,
    param,
    callback,
  };
};

const createArea = (params, callback) => {
  return {
    type: typeActions.CREATE_AREA_REQUEST,
    params,
    callback,
  };
};
const updateArea = (params, body, callback) => {
  return {
    type: typeActions.UPDATE_AREA_REQUEST,
    params,
    body,
    callback,
  };
};
const deleteArea = (params, callback) => {
  return {
    type: typeActions.DELETE_AREA_REQUEST,
    params,
    callback,
  };
};

const getAreaByIds = (ids, relationTo, query, callback) => {
  return {
    type: typeActions.GET_AREA_BY_IDS_REQUEST,
    ids,
    relationTo,
    query,
    callback,
  };
};

const getAreasByAccountExtend = (param, callback) => {
  return {
    type: typeActions.GET_AREAS_BY_ACCOUNT_EXTEND_REQUEST,
    param,
    callback,
  };
};

const exportAreas = (query, callback) => {
  return {
    type: typeActions.EXPORT_AREA_REQUEST,
    callback,
    query,
  };
};
export default {
  setValueArea,
  getAreas,
  getArea,
  deleteArea,
  updateArea,
  createArea,
  getAreaByIds,
  getAreasByAccountExtend,
  exportAreas
};
