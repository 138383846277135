import _ from 'lodash';
import { all, call, put, takeLatest } from 'redux-saga/effects';

import typeActions from 'redux/typeActions';
import ServiceURL from 'services/ServiceURL';
import { DELETE, GET, GET_FILE, POST, PUT } from 'services/ServiceBase';
import { convertMessageError } from 'common';

/**
 *
 * @param {*} data
 * get list partners:
 * - failed -> call actions failed
 * - success ->
 *      + if (search in select-options) => return;
 *      + if (search in list partners) => call actions sucess
 */
export function* getPartners(data) {
  const url = `${ServiceURL.partner}?${data.query}&filters[$and][0][deleted][$eq]=false&sort[1]=createdAt:DESC`;

  const callback = data.callback;
  const isUpdateReducer = data.isUpdateReducer;
  try {
    const res = yield call(GET, url);
    if (!!res.data?.error?.message) {
      yield put({
        type: typeActions.GET_PARTNERS_FAILED,
        error: res.data?.error?.message,
      });
      !!callback?.failed && callback.failed(res.data.error.message);
    } else {
      if (isUpdateReducer) {
        yield put({
          type: typeActions.GET_PARTNERS_SUCCESS,
          data: res.data,
        });
      }
      !!callback?.success && callback.success(res.data);
    }
  } catch (error) {
    yield put({
      type: typeActions.GET_PARTNERS_FAILED,
      error: error?.response?.data?.error?.message ?? '',
    });
    !!callback?.failed &&
      callback.failed(error?.response?.data?.error?.message ?? '');
  }
}

export function* createPartner(data) {
  const url = ServiceURL.partner;
  const callback = data.callback;
  try {
    const res = yield call(POST, url, data.params);
    if (!!res.data?.error?.message) {
      yield put({
        type: typeActions.CREATE_PARTNER_FAILED,
        error: res.data.error.message,
      });

      !!callback?.failed && callback.failed(res.data.error.message);
    } else {
      yield put({
        type: typeActions.CREATE_PARTNER_SUCCESS,
        data: res.data,
      });
      !!callback?.success && callback.success(res.data);
    }
  } catch (error) {
    const message = convertMessageError('c', error, ' đối tác ');
    yield put({
      type: typeActions.CREATE_PARTNER_FAILED,
      error: message,
    });
    !!callback?.failed && callback.failed(message);
  }
}

export function* deletePartner(data) {
  const url = ServiceURL.partner + '/' + data.params;
  const callback = data.callback;
  try {
    const res = yield call(DELETE, url);
    if (!!res.data?.error?.message) {
      yield put({
        type: typeActions.DELETE_PARTNER_FAILED,
        error: res.data.error.message,
      });
      !!callback?.failed && callback.failed(res.data.error.message);
    } else {
      yield put({
        type: typeActions.DELETE_PARTNER_SUCCESS,
      });
      !!callback?.success && callback.success();
    }
  } catch (error) {
    yield put({
      type: typeActions.DELETE_PARTNER_FAILED,
      error: error?.response?.data?.error?.message ?? '',
    });
    !!callback?.failed &&
      callback.failed(error?.response?.data?.error?.message ?? '');
  }
}
export function* updatePartner(data) {
  const url = ServiceURL.partner + '/' + data.params;
  const callback = data.callback;
  try {
    const res = yield call(PUT, url, data.body);
    if (!!res.error?.message) {
      yield put({
        type: typeActions.UPDATE_PARTNER_FAILED,
        error: res?.error?.message ?? '',
      });
      !!callback?.failed && callback.failed(res?.error?.message ?? '');
    } else {
      yield put({
        type: typeActions.UPDATE_PARTNER_SUCCESS,
        data: res?.data,
      });
      !!callback?.success && callback.success(res?.data);
    }
  } catch (error) {
    const details = error?.response?.data?.error?.details?.errors ?? '';
    const m = details
      ? details.map((_) => {
        return `Trường ${_.path.join(',')} : ${_.message}`;
      })
      : error?.response?.data?.error?.message ?? '' ?? '';
    yield put({
      type: typeActions.UPDATE_PARTNER_FAILED,
      error: m,
    });
    !!callback?.failed && callback.failed(m);
  }
}
export function* exportPartner(data) {
  const url = `${ServiceURL.exportPartner}?${data.query}`;
  const callback = data.callback;
  try {
    const res = yield call(GET_FILE, url);
    if (!!res.data?.error?.message) {
      yield put({
        type: typeActions.EXPORT_PARTNER_FAILED,
        error: res.data?.error?.message,
      });
      !!callback?.failed && callback.failed(res.data.error.message);
    } else {
      yield put({
        type: typeActions.EXPORT_PARTNER_SUCCESS,
        data: res.data,
      });

      !!callback?.success && callback.success(res.data);
    }
  } catch (error) {
    yield put({
      type: typeActions.EXPORT_PARTNER_FAILED,
      error: error?.response?.data?.error?.message ?? '',
    });
    !!callback?.failed &&
      callback.failed(error?.response?.data?.error?.message ?? '');
  }
}
export default function* partnerSaga() {
  yield takeLatest(typeActions.GET_PARTNERS_REQUEST, getPartners);
  yield takeLatest(typeActions.CREATE_PARTNER_REQUEST, createPartner);
  yield takeLatest(typeActions.DELETE_PARTNER_REQUEST, deletePartner);
  yield takeLatest(typeActions.UPDATE_PARTNER_REQUEST, updatePartner);
  yield takeLatest(typeActions.EXPORT_PARTNER_REQUEST, exportPartner);
}
