import React from "react";
import ReactDOM from "react-dom";
import { BrowserRouter, Route, Switch, Redirect } from "react-router-dom";

import "react-notification-alert/dist/animate.css";
import "react-perfect-scrollbar/dist/css/styles.css";
import "@fullcalendar/common/main.min.css";
import "@fullcalendar/daygrid/main.min.css";
import "sweetalert2/dist/sweetalert2.min.css";
import "select2/dist/css/select2.min.css";
import "quill/dist/quill.core.css";
import "@fortawesome/fontawesome-free/css/all.min.css";
import "assets/vendor/nucleo/css/nucleo.css";
import "assets/scss/argon-dashboard-pro-react.scss?v1.2.0";
import "react-datepicker/dist/react-datepicker.css";
import "./App.css";
import { Provider as StoreProvider } from "react-redux";
import Admin from "layouts/Admin.js";
import AuthLayout from "layouts/Auth.js";
import store from "./store";
ReactDOM.render(
  <StoreProvider store={store}>
    <BrowserRouter>
      <Switch>
        <Route path="/admin" render={(props) => <Admin {...props} />} />
        <Route path="/auth" render={(props) => <AuthLayout {...props} />} />
        <Route
          path="/"
          render={(props) => {
            return <Admin {...props} />;
          }}
        />
        <Redirect from="*" to="/admin/specify-vote-all" />
      </Switch>
    </BrowserRouter>
  </StoreProvider>,
  document.getElementById("root")
);
