import React, { memo, useEffect, useState } from 'react';
import _ from 'lodash';
import { Card, CardBody, CardHeader, Col, Row } from 'reactstrap';
import DatePicker from 'react-datepicker';

import 'assets/css/custom-pages/react-bs-table.css';
import { notificationAlertRef, notify } from 'common';
import LabelValue from 'views/pages/components/LabelValue';
import SelectCustom from 'views/pages/components/SelectCustom';
import LabelCustom from 'views/pages/components/LabelCustom';
import CONSTANT from 'constant';
import HelperText from 'views/pages/components/HelperText';
import { useSelector } from 'react-redux';

const KQInfo = ({}) => {
  const { order } = useSelector((state) => state.orderReducer);
  return (
    <>
      <Row>
        <CardHeader className="pb-2 d-flex justify-content-between rounded border-bottom w-100">
          <h3>Kết quả xét nghiệm di truyền</h3>
        </CardHeader>
      </Row>
      <Row>
        <Col xs={12}>
          <LabelValue
            labelWidth={160}
            label="Bản thân"
            value={order?.specifyVote?.result1 ?? ''}
          />
        </Col>
      </Row>
      <Row>
        <Col xs={12}>
          <LabelValue
            labelWidth={160}
            label="Người thân"
            value={order?.specifyVote?.result2 ?? ''}
          />
        </Col>
      </Row>
    </>
  );
};

export default KQInfo;
