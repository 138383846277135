import React, { useState, useEffect } from 'react';
import classnames from 'classnames';
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  FormGroup,
  Form,
  Input,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
  Container,
  Row,
  Col,
} from 'reactstrap';
import { useDispatch } from 'react-redux';
import { Formik } from 'formik';
import * as yup from 'yup';

import { notificationAlertRef, notify } from 'common';
import HelperText from 'views/pages/components/HelperText';
import AuthHeader from 'components/Headers/AuthHeader.js';
import { accountActions } from 'redux/actions';
import 'assets/css/pages/login.css';
import { useHistory, useLocation } from 'react-router';

const ResetPassword = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const location = useLocation();

  const [focusedRePassword, setFocusedRePassword] = useState(false);
  const [focusedPassword, setFocusedPassword] = useState(false);
  const accountSchema = yup.object().shape({
    password: yup
      .string()
      .required('Vui lòng nhập mật khẩu mới!')
      .test(
        'password',
        'Mật khẩu phải dài ít nhất 8 kí tự bao gồm chữ thường, chữ hoa và số!',
        (password) => {
          const reg = /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{8,}$/g;
          // /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/g;
          return reg.test(password);
        }
      ),
    rePassword: yup
      .string()
      .oneOf([yup.ref('password')], 'Mật khẩu không trùng khớp!')
      .required('Vui lòng xác nhận mật khẩu!'),
  });

  const accountInfo = {
    password: '',
    rePassword: '',
  };

  const onSubmit = (values, actions) => {
    dispatch(
      accountActions.resetPassword(
        {
          password: values.password,
          resetPasswordToken: window.location.href.split('token=')[1],
        },
        '',
        {
          success: () => {
            notify(
              notificationAlertRef,
              'success',
              'Thông báo',
              `Thiết lập mật khẩu thành công`
            );
            actions.resetForm();
            setTimeout(() => {
              history.push('auth/login');
            }, 2000);
          },
          failed: (mess) => {
            notify(
              notificationAlertRef,
              'danger',
              'Thông báo',
              `Thiết lập mật khẩu thất bại! Lỗi: ${mess}`
            );
          },
        }
      )
    );
  };

  useEffect(() => {
    if (location.search.includes('?code=')) {
      history.push(
        `/auth/reset-password?token=${window.location.href.split('code=')[1]}`
      );
    }
  }, [location]);

  return (
    <div className="container-login">
      <AuthHeader title="HỆ THỐNG QUẢN LÝ DỊCH VỤ XÉT NGHIỆM" lead="" />
      <div style={{ position: 'relative' }}>
        <div
          style={{
            position: 'absolute',
            top: 0,
            zIndex: 2,
            width: '100%',
          }}
        >
          <Container className="mt-6">
            <Row className="justify-content-center">
              <Formik
                initialValues={accountInfo}
                enableReinitialize
                onSubmit={onSubmit}
                validationSchema={accountSchema}
              >
                {({
                  values,
                  handleSubmit,
                  errors,
                  touched,
                  handleBlur,
                  handleChange,
                }) => {
                  return (
                    <Col lg="5" md="8">
                      <Card className="bg-secondary border-0 mb-0">
                        <CardHeader className="bg-transparent p-0 border-bottom-0">
                          <div className="text-center text-muted pt-5">
                            <small>
                              <h4 className="text-uppercase">
                                Thiết lập mật khẩu
                              </h4>
                            </small>
                          </div>
                        </CardHeader>
                        <CardBody className="px-lg-4 pb-lg-3 pt-lg-0 ">
                          <Form onSubmit={handleSubmit} role="form">
                            <FormGroup
                              className={classnames({
                                focused: focusedPassword,
                              })}
                            >
                              <InputGroup className="input-group-merge input-group-alternative">
                                <InputGroupAddon addonType="prepend">
                                  <InputGroupText>
                                    <i className="ni ni-lock-circle-open" />
                                  </InputGroupText>
                                </InputGroupAddon>
                                <Input
                                  value={values.password}
                                  placeholder="Mật khẩu mới"
                                  type="password"
                                  id="password"
                                  name="password"
                                  onChange={handleChange}
                                  onFocus={() => setFocusedPassword(true)}
                                  onBlur={(e) => {
                                    setFocusedPassword(true);
                                    handleBlur(e);
                                  }}
                                />
                              </InputGroup>
                              {errors.password && touched.password && (
                                <HelperText message={errors.password} />
                              )}
                            </FormGroup>
                            <FormGroup
                              className={classnames({
                                focused: focusedRePassword,
                              })}
                            >
                              <InputGroup className="input-group-merge input-group-alternative">
                                <InputGroupAddon addonType="prepend">
                                  <InputGroupText>
                                    <i className="ni ni-lock-circle-open" />
                                  </InputGroupText>
                                </InputGroupAddon>
                                <Input
                                  value={values.rePassword}
                                  placeholder="Nhập lại mật khẩu mới"
                                  type="password"
                                  id="rePassword"
                                  name="rePassword"
                                  onChange={handleChange}
                                  onFocus={() => setFocusedRePassword(true)}
                                  onBlur={(e) => {
                                    setFocusedRePassword(true);
                                    handleBlur(e);
                                  }}
                                />
                              </InputGroup>
                              {errors.rePassword && touched.rePassword && (
                                <HelperText message={errors.rePassword} />
                              )}
                            </FormGroup>
                            <div className="text-center">
                              <Button
                                onClick={handleSubmit}
                                className="mb-4"
                                color="info"
                                type="submit"
                              >
                                Xác nhận
                              </Button>
                            </div>
                          </Form>
                        </CardBody>
                      </Card>
                    </Col>
                  );
                }}
              </Formik>
            </Row>
          </Container>
        </div>
        <div className="bg-under" />
      </div>
    </div>
  );
};

export default ResetPassword;
