import React, { useEffect, useState } from 'react';
import paginationFactory from 'react-bootstrap-table2-paginator';
import _ from 'lodash';
import { Container, Row, UncontrolledTooltip } from 'reactstrap';

import {
  notificationAlertRef,
  notify,
  checkSupperAdmin,
  checkRole,
} from 'common';
import { roleActions, formActions } from 'redux/actions';
import SimpleHeader from 'components/Headers/SimpleHeader.js';
import 'assets/css/custom-pages/react-bs-table.css';
import { useDispatch, useSelector } from 'react-redux';

import IconEdit from 'assets/svgs/edit.svg';
import IconBin from 'assets/svgs/bin.svg';
import IconEye from 'assets/svgs/eye.svg';
import queryString from 'query-string';
import CURole from './components/CURole';
import ModalWarningCustom from 'views/pages/components/ModalWarningCustom';
import moment from 'moment';
import TableCustom from '../components/TableCustom';
import { useDidUpdateEffect } from 'utils/HookUtils';

function Role() {
  const [notificationModal, setNotificationModal] = useState(false);
  const [role, setRole] = useState(null);
  const [searchRole, setSearchRole] = useState('');
  const { page, limit, query, roles, isDeleteRole, roleExtend } = useSelector(
    (state) => state.roleReducer
  );
  const { currentAccountExtend } = useSelector((state) => state.accountReducer);
  const roleExtendId = _.get(roleExtend, 'id', '');
  const results = _.get(roles, 'results', []);
  const total = _.get(roles, 'pagination.total', 0);
  const dispatch = useDispatch();
  // const nameTypeSearch = 'Tìm kiếm';

  const columns = [
    {
      dataField: 'name',
      text: 'Tên nhóm quyền',
    },
    {
      dataField: 'description',
      text: 'Mô tả',
    },
    {
      dataField: 'accountExtends.count',
      text: 'Số tài khoản sử dụng',
      headerStyle: {
        textAlign: 'center',
      },
      style: {
        textAlign: 'center',
      },
    },
    {
      dataField: 'createdAt',
      text: 'Ngày tạo',
      headerStyle: {
        textAlign: 'center',
      },
      style: {
        textAlign: 'center',
      },
      formatter: (cell) => {
        return !!cell ? moment(cell).format('DD/MM/YYYY') : '';
      },
    },

    {
      dataField: 'actions',
      text: 'Hành động',
      formatter: boxActions,
      headerStyle: {
        textAlign: 'center',
      },
      headerClasses: 'fixed-column',
      classes: 'fixed-column',
    },
  ];

  function boxActions(__, row) {
    return (
      <div className="d-flex justify-content-center">
        <button
          id={'view' + row.id}
          onClick={() => {
            dispatch(
              formActions.setValue({
                name: 'modalInfo',
                value: { ...row, isView: true },
              })
            );
            dispatch(
              formActions.setValue({
                name: 'isModalVisible',
                value: true,
              })
            );
          }}
          className="btn p-0 btn-no-shadow mr-0"
        >
          <img src={IconEye} className="mr-1" />
        </button>
        <UncontrolledTooltip delay={1} placement="top" target={'view' + row.id}>
          Xem chi tiết
        </UncontrolledTooltip>{' '}
        {checkRole(currentAccountExtend, {
          roles: ['update-role'],
        }) && (
          <>
            <button
              id={'edit' + row.id}
              onClick={() => {
                // if (row.accountExtends.count > 0) {
                //   return;
                // }
                dispatch(
                  formActions.setValue({
                    name: 'modalInfo',
                    value: row,
                  })
                );
                dispatch(
                  formActions.setValue({
                    name: 'isModalVisible',
                    value: true,
                  })
                );
              }}
              className="btn p-0 btn-no-shadow mr-0"
            >
              <img src={IconEdit} className="mr-1" />
            </button>
            <UncontrolledTooltip
              delay={1}
              placement="top"
              target={'edit' + row.id}
            >
              Cập nhật phân quyền
            </UncontrolledTooltip>
          </>
        )}{' '}
        {checkRole(currentAccountExtend, {
          roles: ['delete-role'],
        }) && (
          <>
            <button
              id={'delete' + row.id}
              onClick={() => {
                dispatch(
                  roleActions.getRoleExtend(row.id, queryString.stringify({}), {
                    success: () => {
                      setRole(row);
                      setNotificationModal(true);
                    },
                  })
                );
              }}
              className="btn p-0 btn-no-shadow mr-0"
            >
              <img src={IconBin} />
            </button>
            <UncontrolledTooltip
              delay={1}
              placement="top"
              target={'delete' + row.id}
            >
              Xóa phân quyền
            </UncontrolledTooltip>{' '}
          </>
        )}
      </div>
    );
  }

  const onSizePerPageChange = (e) => {
    dispatch(roleActions.setValue({ name: 'page', value: 1 }));
    dispatch(roleActions.setValue({ name: 'limit', value: e.target.value }));
  };

  const onPageChange = (value) => {
    dispatch(roleActions.setValue({ name: 'page', value: value }));
  };

  const pagination = paginationFactory({
    page: page,
    sizePerPage: limit,
    totalSize: total,
    showTotal: false,
    withFirstAndLast: false,
    alwaysShowAllBtns: true,
    onPageChange: onPageChange,
    sizePerPageRenderer: () => {
      return (
        <div className="dataTables_length" id="datatable-basic_length">
          <label>
            Hiển thị{' '}
            {
              <select
                name="datatable-basic_length"
                aria-controls="datatable-basic"
                className="form-control form-control-sm"
                onChange={onSizePerPageChange}
              >
                <option value="10">10</option>
                <option value="25">25</option>
                <option value="50">50</option>
                <option value="100">100</option>
              </select>
            }{' '}
            dòng.
          </label>
        </div>
      );
    },
  });

  const handleDelete = () => {
    if (checkSupperAdmin(role?.name))
      return notify(
        notificationAlertRef,
        'warning',
        'Thông báo',
        `Quyền Super Admin không được phép cập nhật!`
      );
    if (role?.accountExtends?.count > 0)
      return notify(
        notificationAlertRef,
        'warning',
        'Thông báo',
        `Đang có tài khoản áp dụng phân quyền này, không được xóa!`
      );
    if (role === null) return;
    dispatch(
      roleActions.deleteRole(role.id, {
        success: () => {
          setNotificationModal(false);
          notify(
            notificationAlertRef,
            'success',
            'Thông báo',
            `Xóa phân quyền ${role.name} thành công!`
          );
          // dispatch(roleActions.deleteRoleExtend(roleExtendId));
          // dispatch(roleActions.getRoles(queryString.stringify({})));
        },
        failed: () => {
          notify(
            notificationAlertRef,
            'danger',
            'Thông báo',
            `Xóa phân quyền ${role.name} thất bại!`
          );
        },
      })
    );
  };

  useEffect(() => {
    if (_.isEmpty(roles.results)) {
      dispatch(
        roleActions.getRoles(queryString.stringify({ page, pageSize: limit }))
      );
    }
  }, []);

  useDidUpdateEffect(() => {
    dispatch(
      roleActions.getRoles(queryString.stringify({ page, pageSize: limit }))
    );
  }, [page, limit]);

  return (
    <>
      <SimpleHeader />
      <CURole />
      <ModalWarningCustom
        notificationModal={notificationModal}
        setNotificationModal={setNotificationModal}
        name={`phân quyền ${role?.name ?? ''}`}
        func={handleDelete}
        isDelete={isDeleteRole}
      />
      <Container className="mt--9" fluid>
        <Row>
          <div className="col">
            <TableCustom
              tableName="Danh sách phân quyền"
              data={results}
              columns={columns}
              page={page}
              limit={limit}
              total={total}
              searchBy={'Tìm kiếm'}
              pagination={pagination}
              search={searchRole}
              onChange={(e) => {
                setSearchRole(e.target.value);
              }}
            />
          </div>
        </Row>
      </Container>
    </>
  );
}

export default Role;
