import React, { memo } from 'react';
import _ from 'lodash';
import { Button, Col, Row } from 'reactstrap';
import DatePicker from 'react-datepicker';

import 'assets/css/custom-pages/react-bs-table.css';
import LabelValue from 'views/pages/components/LabelValue';
import CONSTANT from 'constant';
import HelperText from 'views/pages/components/HelperText';
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment';

const BNInfo = ({}) => {
  const dispatch = useDispatch();
  const { order } = useSelector((state) => state.orderReducer);

  const genderValue = CONSTANT.ACCOUNT_GENDER.find(
    (item) => item.value === order?.specifyVote?.genderBN
  );

  return (
    <>
      <Row>
        <Col xs={12} md={12}>
          <h3>Thông tin người làm xét nghiệm</h3>
        </Col>
      </Row>
      <Row>
        <Col xs={12} md={12}>
          <LabelValue
            labelWidth={160}
            label="Mã bệnh nhân"
            value={order?.specifyVote?.codeBN ?? ''}
          />
        </Col>
      </Row>
      <Row>
        <Col xs={12} md={12}>
          <LabelValue
            labelWidth={160}
            label="Họ tên"
            value={order?.specifyVote?.nameBN ?? ''}
          />
        </Col>
      </Row>
      <Row>
        <Col xs={12} md={6}>
          <LabelValue
            labelWidth={160}
            label={'Ngày sinh'}
            value={order?.specifyVote?.dateOfBirthBN ?? ''}
          />
        </Col>
        <Col xs={12} md={6}>
          <LabelValue
            labelWidth={160}
            label="Giới tính"
            value={genderValue?.label ?? ''}
          />
        </Col>
      </Row>
      <Row className="justify-content-between">
        <Col xs={12} md={12}>
          <LabelValue
            labelWidth={160}
            label="Email"
            value={order?.specifyVote?.emailBN ?? ''}
          />
        </Col>
      </Row>
      <Row>
        <Col xs={12} md={12}>
          <LabelValue
            labelWidth={160}
            label="Số điện thoại"
            value={order?.specifyVote?.phoneBN ?? ''}
          />
        </Col>
      </Row>
      <Row>
        <Col xs={12}>
          <LabelValue
            labelWidth={160}
            label="Nghề nghiệp"
            value={order?.specifyVote?.jobBN ?? ''}
          />
        </Col>
      </Row>

      <Row className="justify-content-between">
        <Col xs={12} md={12}>
          <LabelValue
            labelWidth={160}
            label="Người liên hệ"
            value={order?.specifyVote?.nameContact ?? ''}
          />
        </Col>
      </Row>
      <Row>
        <Col xs={12} md={12}>
          <LabelValue
            labelWidth={160}
            label="Số điện thoại người liên hệ"
            value={order?.specifyVote?.phoneContact ?? ''}
          />
        </Col>
      </Row>
      <Row>
        <Col xs={12}>
          <LabelValue
            labelWidth={160}
            label="Địa chỉ liên hệ"
            value={order?.specifyVote?.addressBN ?? ''}
          />
        </Col>
      </Row>
    </>
  );
};

export default memo(BNInfo);
