import React, { useEffect, useState } from 'react';
import _ from 'lodash';
import { CardHeader, Col, Row } from 'reactstrap';
import { useDispatch, useSelector } from 'react-redux';
import queryString from 'query-string';

import 'assets/css/custom-pages/react-bs-table.css';
import InputCustom from 'views/pages/components/InputCustom';
import SelectCustom from 'views/pages/components/SelectCustom';
import CONSTANT from 'constant';
import { serviceActions } from 'redux/actions';
import moment from 'moment';
import { useFormikContext } from 'formik';
import { useDidUpdateEffect } from 'utils/HookUtils';
import DatePickerCustom from 'views/pages/components/DatePickerCustom';
import { isNumeric } from 'common';
import { useParams } from 'react-router';

const XNInfo = ({ isDisabled, specifyVoteValue }) => {
  const dispatch = useDispatch();
  const { id } = useParams();

  const {
    setFieldValue,
    values,
    errors,
    touched,
    handleBlur,
    handleChange,
    setValues,
  } = useFormikContext();

  const { order } = useSelector((state) => state.orderReducer);

  const [accountValue, setAccountValue] = useState(null);
  const [serviceValue, setServiceValue] = useState(null);

  const [serviceSearch, setServiceSearch] = useState('');

  const [serviceResults, setServiceResults] = useState([]);
  const [specimensValue, setSpecimensValue] = useState(null);

  const [specimensData, setSpecimensData] = useState([]);

  // get services
  useEffect(() => {
    const delayDebounceFn = setTimeout(() => {
      handleGetServices();
    }, 500);

    return () => clearTimeout(delayDebounceFn);
  }, [serviceSearch]);

  const handleGetServices = () => {
    const query = {};
    if (serviceSearch !== '') {
      query['filters[$or][1][code][$containsi]'] = serviceSearch;
      query['filters[$or][2][name][$containsi]'] = serviceSearch;
    }

    dispatch(
      serviceActions.getServices(
        queryString.stringify(query),
        {
          success: (data) => {
            const results = _.get(data, 'results', []);
            setServiceResults(results);
          },
          failed: () => {},
        },
        false
      )
    );
  };

  useEffect(() => {
    const delayDebounceFn = setTimeout(() => {
      handleGetAccountBS(values.organization);
    }, 500);

    return () => clearTimeout(delayDebounceFn);
  }, [values.organization]);

  useEffect(() => {
    if (!order?.specifyVote?.codeTest) return setServiceValue(null);
    setServiceValue({
      value: -1,
      label: order?.specifyVote?.codeTest,
    });
  }, [order?.specifyVote?.codeTest]);

  useEffect(() => {
    if (!order?.specifyVote?.specimens) {
      setSpecimensValue(null);
      setSpecimensData([]);
      return;
    }
    const specimensV = CONSTANT.SPECIMENS.filter((item) =>
      order?.specifyVote?.specimens.includes(item.value)
    );
    setSpecimensValue(specimensV);
    setSpecimensData(specimensV);
  }, [order?.specifyVote?.specimens]);

  useEffect(() => {
    if (!order?.specifyVote?.firstNameBS) return setAccountValue(null);
    setAccountValue({
      value: -1,
      label: `${order?.specifyVote?.lastNameBS ?? ''} ${
        order?.specifyVote?.firstNameBS ?? ''
      }`,
    });
  }, [order?.specifyVote?.firstNameBS]);

  const handleGetAccountBS = (id) => {
    // const query = {
    //   'filters[$and][1][accountType][$eq]': CONSTANT.ACCOUNT_TYPE[5].value,
    // };
    // if (!!id) {
    //   query['filters[$and][2][organization][id]'] = id;
    // }
    // if (accountSearch !== '') {
    //   query['filters[$and][3][code][$containsi]'] = accountSearch;
    // }
    // dispatch(
    //   accountActions.getAccountsExtend(
    //     queryString.stringify(query),
    //     {
    //       success: (data) => {
    //         const results = _.get(data, 'results', []);
    //         setAccountResults(results);
    //       },
    //       failed: () => {},
    //     },
    //     false
    //   )
    // );
    // if (id === null) return;
    // dispatch(
    //   accountActions.getAccountExtendsByOrganization(id, {
    //     success: (data) => {
    //       setAccountResults(data);
    //     },
    //   })
    // );
  };

  // const handleGetPromotionDetail = (promotionDetailId) => {
  //   if (!!promotionDetailId) {
  //     dispatch(
  //       promotionDetailActions.getRelation(promotionDetailId, 'promotion', {
  //         success: (data) => {
  //           setFieldValue('specifyVote.codePromotion', data.code);
  //           setFieldValue('specifyVote.form', data.form);
  //           setFieldValue('specifyVote.valuePromotion', data.valuePromotion);
  //           setFieldValue('specifyVote.promotionStatus', data?.status ?? false);
  //           setFieldValue(
  //             'specifyVote.promotionDeleted',
  //             data?.deleted ?? true
  //           );
  //         },
  //         failed: () => {},
  //       })
  //     );
  //   } else {
  //     setFieldValue('specifyVote.codePromotion', '');
  //     setFieldValue('specifyVote.form', 0);
  //     setFieldValue('specifyVote.valuePromotion', 0);
  //     setFieldValue('specifyVote.promotionStatus', false);
  //     setFieldValue('specifyVote.promotionDeleted', true);
  //   }
  // };

  useDidUpdateEffect(() => {
    setServiceValue(
      !!specifyVoteValue?.codeTest
        ? {
            label: specifyVoteValue.codeTest,
            value: -1,
          }
        : null
    );
    const specimens = specifyVoteValue?.specimens ?? [];
    const specimensV = CONSTANT.SPECIMENS.filter((item) =>
      specimens.includes(item.value)
    );
    setSpecimensValue(specimensV);
    setAccountValue(
      specifyVoteValue?.firstNameBS
        ? {
            value: -1,
            label: `${specifyVoteValue?.lastNameBS ?? ''} ${
              specifyVoteValue?.firstNameBS ?? ''
            }`,
          }
        : null
    );
  }, [specifyVoteValue]);

  return (
    <>
      <Row>
        <CardHeader className="pb-2 d-flex justify-content-between rounded border-bottom w-100">
          <h3>Thông tin xét nghiệm</h3>
        </CardHeader>
      </Row>
      <Row>
        <Col
          xs={12}
          md={12}
          // lg={10}
        >
          <SelectCustom
            name="specifyVote.codeTest"
            label="Mã xét nghiệm"
            placeholder="Lựa chọn"
            value={serviceValue}
            isRequired={true}
            isClearable={false}
            handleChange={(e) => {
              setServiceValue(e);
              const specimens = e?.specimens ?? [];
              const specimensV = CONSTANT.SPECIMENS.filter((item) =>
                specimens.includes(item.value)
              );
              setSpecimensValue(specimensV);
              setSpecimensData(specimensV);
              // setFieldValue('specifyVote.unitPrice', e?.unitPrice ?? 0);
              // setFieldValue('specifyVote.codeTest', e?.label ?? '');
              // setFieldValue('specifyVote.nameTest', e?.name ?? '');
              // setFieldValue('specifyVote.specimens', specimens);
              // setFieldValue(
              //   'specifyVote.promotionDetailStartDate',
              //   e?.startDate ?? null
              // );
              // setFieldValue(
              //   'specifyVote.promotionDetailEndDate',
              //   e?.endDate ?? null
              // );
              // setFieldValue(
              //   'specifyVote.codeCTKM',
              //   e?.promotionDetail?.code ?? ''
              // );
              // setFieldValue('specifyVote.content', e?.content ?? '');

              setValues({
                ...values,
                specifyVote: {
                  ...values.specifyVote,
                  unitPrice: e?.unitPrice ?? 0,
                  codeTest: e?.code ?? '',
                  nameTest: e?.name ?? '',
                  specimens: specimens,
                  codeCTKM: e?.promotionDetail?.code ?? '',
                  content: e?.content ?? '',
                  timeTest: e?.time ?? 0,
                },
              });
              // handleGetPromotionDetail(e?.promotionDetail?.id, e?.label);
            }}
            handleBlur={handleBlur}
            onInputChange={(value) => {
              setServiceSearch(value);
            }}
            error={errors?.specifyVote?.codeTest}
            touched={touched?.specifyVote?.codeTest}
            options={serviceResults.map((item) => ({
              value: item?.id,
              label: `(${item?.code ?? ''}) ${item?.name ?? ''}`,
              ...item,
            }))}
            isDisabled={isDisabled}
            row
            labelWidth={160}
          />
        </Col>
      </Row>
      <Row>
        <Col
          xs={12}
          md={12}
          // lg={10}
        >
          <InputCustom
            name="specifyVote.nameTest"
            label="Tên xét nghiệm"
            type="text"
            placeholder="Nhập tên xét nghiệm"
            value={values.specifyVote.nameTest}
            isRequired={true}
            handleChange={handleChange}
            handleBlur={handleBlur}
            error={errors?.specifyVote?.nameTest}
            touched={touched?.specifyVote?.nameTest}
            disabled={isDisabled}
            row
            labelWidth={160}
          />
        </Col>
      </Row>
      <Row>
        <Col
          xs={12}
          md={12}
          // lg={10}
        >
          <SelectCustom
            name="specifyVote.specimens"
            label="Mẫu xét nghiệm"
            placeholder="Lựa chọn"
            value={specimensValue}
            isRequired={true}
            isClearable={false}
            handleChange={(e, remove) => {
              if (_.isEmpty(remove?.removedValue)) {
                setSpecimensValue(e);
                setFieldValue(
                  'specifyVote.specimens',
                  e.map((item) => item.value)
                );
              } else {
                const tmp = specimensValue.filter(
                  (item) => item.value !== remove.removedValue.value
                );
                setSpecimensValue(tmp);

                const specimens = values.specifyVote.specimens.filter(
                  (item) => item !== remove.removedValue.value
                );
                setFieldValue('specifyVote.specimens', specimens);
              }
            }}
            handleBlur={handleBlur}
            // onInputChange={(value) => {}}
            error={errors?.specifyVote?.specimens}
            touched={touched?.specifyVote?.specimens}
            options={specimensData}
            isDisabled={isDisabled}
            disabled={id !== '-1'}
            isMulti
            row
            labelWidth={160}
          />
        </Col>
      </Row>
      <Row>
        <Col
          xs={12}
          md={12}
          // lg={10}
        >
          <InputCustom
            name="specifyVote.content"
            label="Nội dung xét nghiệm"
            type="textarea"
            placeholder="Nhập tên xét nghiệm"
            value={values.specifyVote.content}
            isRequired={true}
            handleChange={handleChange}
            handleBlur={handleBlur}
            error={errors?.specifyVote?.content}
            touched={touched?.specifyVote?.content}
            disabled={isDisabled}
            row
            labelWidth={160}
          />
        </Col>
      </Row>

      <Row>
        <Col
          xs={12}
          md={12}
          // lg={10}
        >
          <InputCustom
            name="specifyVote.whereTM"
            label="Nơi thu mẫu"
            type="text"
            placeholder="Nhập nơi thu mẫu"
            value={values.specifyVote.whereTM}
            isRequired={true}
            handleChange={handleChange}
            handleBlur={handleBlur}
            error={errors?.specifyVote?.whereTM}
            touched={touched?.specifyVote?.whereTM}
            disabled={isDisabled}
            row
            labelWidth={160}
          />
        </Col>
      </Row>
      <Row>
        <Col xs={12} md={12} lg={8}>
          <DatePickerCustom
            label="Ngày/giờ thu mẫu"
            lableTextAlign={'start'}
            isRequired={true}
            row
            labelWidth={160}
            maxDate={new Date()}
            dateFormat={'dd/MM/yyyy HH:mm'}
            placeholderText={'DD/MM/YYYY HH:mm'}
            selected={values.specifyVote.dateTM}
            handleChange={(date) => {
              setFieldValue('specifyVote.dateTM', date);
              setFieldValue('specifyVote.hourTM', moment(date).format('HH:mm'));
            }}
            handleBlur={handleBlur}
            disabled={isDisabled}
            showTimeSelect
            timeIntervals={1}
            error={errors?.specifyVote?.dateTM}
            touched={touched?.specifyVote?.dateTM}
          />
          {/* <div className="d-flex">
            <LabelCustom
              label={'Ngày thu mẫu'}
              style={{
                margin: '0.5em 8px 0px 0px',
              }}
              styleLabel={{
                minWidth: 160,
              }}
              isRequired={true}
            />
            <div className="date-picker-custom row-date-picker-custom d-flex flex-column">
              <DatePicker
              
                customInput={<CustomInputCalendar />}
                disabled={isDisabled}
                dateFormat={'dd/MM/yyyy'}
                selected={values.specifyVote.dateTM}
                maxDate={new Date()}
                onChange={(date) => {
                  setFieldValue('specifyVote.dateTM', date);
                }}
              />
              {errors?.specifyVote?.dateTM &&
                !!touched?.specifyVote?.dateTM && (
                  <div style={{}}>
                    <HelperText message={errors?.specifyVote?.dateTM} />
                  </div>
                )}
            </div>
          </div> */}
        </Col>
      </Row>
      <Row>
        <Col xs={12} md={12} lg={8}>
          <InputCustom
            label="Số lượng phôi"
            row
            labelWidth={160}
            className={'height-input'}
            placeholder={'Nhập số'}
            type={'number'}
            name={'specifyVote.numOfEmbryos'}
            value={values?.specifyVote?.numOfEmbryos}
            handleChange={(e) => {
              if (isNumeric(e.target.value)) {
                setFieldValue('specifyVote.numOfEmbryos', e.target.value);
              }
            }}
            handleBlur={handleBlur}
            disabled={isDisabled}
            error={errors?.specifyVote?.numOfEmbryos}
            touched={touched?.specifyVote?.numOfEmbryos}
          />
        </Col>
      </Row>
      {/* <Row>
        <Col xs={12} md={12} lg={8}>
          <div className="d-flex">
            <LabelCustom
              label={'Giờ thu mẫu'}
              style={{
                margin: '0.5em 8px 0px 0px',
              }}
              styleLabel={{
                minWidth: 160,
              }}
              isRequired={true}
            />
            <div style={{ marginLeft: 8 }} className="d-flex flex-column">
              <Input
                style={{ marginLeft: 8 }}
                className="height-input"
                id="hourTM"
                name="specifyVote.hourTM"
                placeholder="time placeholder"
                type="time"
                disabled={true}
                value={values.specifyVote.hourTM}
                onChange={handleChange}
              />
              {errors?.specifyVote?.hourTM &&
                !!touched?.specifyVote?.hourTM && (
                  <div
                    style={{
                      paddingLeft: '8px',
                    }}
                  >
                    <HelperText message={errors?.specifyVote?.hourTM} />
                  </div>
                )}
            </div>
          </div>
        </Col>
      </Row> */}
    </>
  );
};

export default XNInfo;
