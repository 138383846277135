import _ from 'lodash';
import { all, call, put, select, takeLatest } from 'redux-saga/effects';

import typeActions from 'redux/typeActions';
import ServiceURL from 'services/ServiceURL';
import { DELETE, GET, GET_FILE, POST, PUT } from 'services/ServiceBase';
import { getMedicalRecordById, getMedicalRecords } from './medicalRecordSaga';
import { handleGetRelation, handleGetSingleRelation } from './relationSaga';
import { convertMessageError, notificationAlertRef, notify } from 'common';
import { getSpecifyVoteById } from './specifyVoteSaga';


export function* exportPatient(data) {
  const url = `${ServiceURL.exportPatient}?${data.query}`;
  const callback = data.callback;
  try {
    const res = yield call(GET_FILE, url);
    if (!!res.data?.error?.message) {
      yield put({
        type: typeActions.EXPORT_PATIENT_FAILED,
        error: res.data?.error?.message,
      });
      !!callback?.failed && callback.failed(res.data.error.message);
    } else {
      yield put({
        type: typeActions.EXPORT_PATIENT_SUCCESS,
        data: res.data,
      });

      !!callback?.success && callback.success(res.data);
    }
  } catch (error) {
    yield put({
      type: typeActions.EXPORT_PATIENT_FAILED,
      error: error?.response?.data?.error?.message ?? '',
    });
    !!callback?.failed &&
      callback.failed(error?.response?.data?.error?.message ?? '');
  }
}
/**
 *
 * @param {*} data
 * get list patients:
 * - failed -> call actions failed
 * - success ->
 *      + if (search in select-options) => return;
 *      + if (search in list patients) => call actions sucess
 */
export function* getPatients(data) {
  const url = `${ServiceURL.patient}?${data.query}&sort=createdAt:DESC&filters[$and][0][deleted][$eq]=false`;
  const callback = data.callback;
  const isUpdateReducer = data.isUpdateReducer;
  try {
    const res = yield call(GET, url);
    if (!!res.data?.error?.message) {
      yield put({
        type: typeActions.GET_PATIENT_FAILED,
        error: res.data?.error?.message,
      });
      !!callback?.failed && callback.failed(res.data.error.message);
    } else {
      if (isUpdateReducer) {
        yield put({
          type: typeActions.GET_PATIENT_SUCCESS,
          data: res.data,
        });
      }
      !!callback?.success && callback.success(res.data);
    }
  } catch (error) {
    yield put({
      type: typeActions.GET_PATIENT_FAILED,
      error: error?.response?.data?.error?.message ?? '',
    });
    !!callback?.failed &&
      callback.failed(error?.response?.data?.error?.message ?? '');
  }
}
export function* getPatientById(data) {
  const url = ServiceURL.patient + '/' + data.param + '?' + data.query;
  const callback = data.callback;
  try {
    const res = yield call(GET, url);
    if (!!res.data?.error?.message) {
      yield put({
        type: typeActions.GET_PATIENT_BY_ID_FAILED,
        error: res.data.error.message,
      });
      !!callback?.failed && callback.failed(res.data.error.message);
    } else {
      //get morde info: medical record, specifyvotes
      const relationData = yield call(handleGetSingleRelation, {
        objName: 'patient',
        obj: res.data,
        relationTo: 'medicalRecord',
        callback: {
          success: (relationData) => { },
          failed: (err) => { },
        },
      });
      const medicalRecord = yield call(getMedicalRecordById, {
        param: relationData?.data?.data?.id ?? '',
        query: 'populate=*',
        callback: {
          success: (medicalRecord) => { },
          failed: (err) => {
            notify(
              notificationAlertRef,
              'success',
              'Thông báo!',
              'Lấy dữ liệu bệnh án không thành công!'
            );
          },
        },
      });
      const relationDataSpecifyvotes = yield call(handleGetRelation, {
        objName: 'patient',
        results: [res.data],
        relationTo: 'specifyVotes',
        callback: {
          success: (relationData) => { },
          failed: (err) => { },
        },
      });
      const specifyVotes = yield all(
        relationDataSpecifyvotes[0].data.results.map((relation) => {
          return call(getSpecifyVoteById, {
            param: relation?.id ?? '',
            query: 'populate=*',
            callback: {
              success: (medicalRecord) => { },
              failed: (err) => {
                notify(
                  notificationAlertRef,
                  'danger',
                  'Thông báo!',
                  'Lấy dữ liệu bệnh án không thành công!'
                );
              },
            },
          });
        })
      );
      yield put({
        type: typeActions.GET_PATIENT_BY_ID_SUCCESS,
        data: {
          ...res.data,
          medicalRecord,
          specifyVotes,
        },
      });
      !!callback?.success &&
        callback.success({
          ...res.data,
          medicalRecord,
          specifyVotes,
        });
    }
  } catch (error) {
    yield put({
      type: typeActions.GET_PATIENT_BY_ID_FAILED,
      error: error?.response?.data?.error?.message ?? '',
    });
    !!callback?.failed &&
      callback.failed(error?.response?.data?.error?.message ?? '');
  }
}
function* getMedicalRecordOfPatient(patient) {
  //gọi relation
  return {
    ...patient,
  };
}

export function* createPatient(data) {
  const url = ServiceURL.patient;
  const callback = data.callback;
  try {
    const res = yield call(POST, url, data.params);
    if (!!res.data?.error?.message) {
      yield put({
        type: typeActions.CREATE_PATIENT_FAILED,
        error: res.data.error.message,
      });

      !!callback?.failed && callback.failed(res.data.error.message);
    } else {
      yield put({
        type: typeActions.CREATE_PATIENT_SUCCESS,
        data: res.data,
      });
      !!callback?.success && callback.success(res?.data);
    }
  } catch (error) {
    const message = convertMessageError('c', error, ' bệnh nhân ');
    yield put({
      type: typeActions.CREATE_PATIENT_FAILED,
      error: message,
    });
    !!callback?.failed && callback.failed(message);
  }
}

export function* deletePatient(data) {
  const url = ServiceURL.patient + '/' + data.params;
  const callback = data.callback;
  try {
    const res = yield call(DELETE, url);
    if (!!res.data?.error?.message) {
      yield put({
        type: typeActions.DELETE_PATIENT_FAILED,
        error: res.data.error.message,
      });
      !!callback?.failed && callback.failed(res.data.error.message);
    } else {
      yield put({
        type: typeActions.DELETE_PATIENT_SUCCESS,
      });
      !!callback?.success && callback.success();
    }
  } catch (error) {
    yield put({
      type: typeActions.DELETE_PATIENT_FAILED,
      error: error?.response?.data?.error?.message ?? '',
    });
    !!callback?.failed &&
      callback.failed(error?.response?.data?.error?.message ?? '');
  }
}
export function* updatePatient(data) {
  const url = ServiceURL.patient + '/' + data.params;
  const callback = data.callback;
  try {
    const res = yield call(PUT, url, data.body);
    if (!!res.error?.message) {
      yield put({
        type: typeActions.UPDATE_PATIENT_FAILED,
        error: res.error.message,
      });
      !!callback?.failed && callback.failed(res.error.message);
    } else {
      yield put({
        type: typeActions.UPDATE_PATIENT_SUCCESS,
        data: res.data,
      });
      !!callback?.success && callback.success(res.data);
    }
  } catch (error) {
    const details = error?.response?.data?.error?.details?.errors ?? '';
    const m = details
      ? details.map((_) => {
        return `Trường ${_.path.join(',')} : ${_.message}`;
      })
      : error?.response?.data?.error?.message ?? '' ?? '';
    yield put({
      type: typeActions.UPDATE_PATIENT_FAILED,
      error: m,
    });
    !!callback?.failed && callback.failed(m);
  }
}
export default function* accountSaga() {
  yield takeLatest(typeActions.GET_PATIENT_REQUEST, getPatients);
  yield takeLatest(typeActions.GET_PATIENT_BY_ID_REQUEST, getPatientById);
  yield takeLatest(typeActions.CREATE_PATIENT_REQUEST, createPatient);
  yield takeLatest(typeActions.DELETE_PATIENT_REQUEST, deletePatient);
  yield takeLatest(typeActions.UPDATE_PATIENT_REQUEST, updatePatient);
  yield takeLatest(typeActions.EXPORT_PATIENT_REQUEST, exportPatient);
}
