import typeActions from 'redux/typeActions';
import CONSTANT from 'constant';
import { returnValue } from 'common';

const initialState = {
  // loading
  isConverting: false,
  data: null,
};

const exportFileReducer = (state = initialState, actions) => {
  switch (actions.type) {
    case typeActions.CONVERT_FILE_PDF_REQUEST:
      return {
        isConverting: true,
        data: null,
      };
    case typeActions.CONVERT_FILE_PDF_SUCCESS:
      return {
        isConverting: false,
        data: actions.data,
      };
    case typeActions.CONVERT_FILE_PDF_FAILED:
      return {
        isConverting: false,
        data: null,
      };

    default:
      return {
        ...state,
      };
  }
};
export default exportFileReducer;
