import typeActions from 'redux/typeActions';

const setValue = ({ name, value }) => {
  return {
    type: typeActions.SET_ACCOUNT_VALUE,
    name,
    value,
  };
};

const accountLogin = (body, callback) => {
  return {
    type: typeActions.ACCOUNT_LOGIN_REQUEST,
    body,
    callback,
  };
};
const importAccount = (body, callback) => {
  return {
    type: typeActions.ACCOUNT_IMPORT_REQUEST,
    body,
    callback,
  };
};

const accountLogOut = (callback) => {
  return {
    type: typeActions.ACCOUNT_LOGOUT_REQUEST,
    callback,
  };
};

const forgotPassword = (body, callback) => {
  return {
    type: typeActions.ACCOUNT_FORGOT_PASSWORD_REQUEST,
    body,
    callback,
  };
};

const resetPassword = (body, query, callback) => {
  return {
    type: typeActions.ACCOUNT_RESET_PASSWORD_REQUEST,
    body,
    query,
    callback,
  };
};

const getAccounts = (query, callback) => {
  return {
    type: typeActions.GET_ACCOUNTS_REQUEST,
    query,
    callback,
  };
};

const getCurrentAccount = (query, callback) => {
  return {
    type: typeActions.GET_CURRENT_ACCOUNT_REQUEST,
    query,
    callback,
  };
};

const getCurrentAccountExtend = (query, callback) => {
  return {
    type: typeActions.GET_CURRENT_ACCOUNT_EXTEND_REQUEST,
    query,
    callback,
  };
};

const getAccountById = (param, query, callback) => {
  return {
    type: typeActions.GET_ACCOUNT_BY_ID_REQUEST,
    query,
    param,
    callback,
  };
};

const getAccountGroupById = (param, query, callback) => {
  return {
    type: typeActions.GET_ACCOUNT_GROUP_BY_ID_REQUEST,
    query,
    param,
    callback,
  };
};

const createAccount = (body, callback) => {
  return {
    type: typeActions.CREATE_ACCOUNT_REQUEST,
    body,
    callback,
  };
};

const updateAccount = (param, body, callback) => {
  return {
    type: typeActions.UPDATE_ACCOUNT_REQUEST,
    param,
    body,
    callback,
  };
};

const deleteAccount = (param, callback) => {
  return {
    type: typeActions.DELETE_ACCOUNT_REQUEST,
    param,
    callback,
  };
};

const configPasswordAccount = (body, callback) => {
  return {
    type: typeActions.CONFIG_PASSWORD_ACCOUNT_REQUEST,
    body,
    callback,
  };
};

const accountChangePassword = (param, body, callback) => {
  return {
    type: typeActions.ACCOUNT_CHANGE_PASSWORD_REQUEST,
    param,
    body,
    callback,
  };
};

const getAccountsExtend = (query, callback, isUpdateReducer = true) => {
  return {
    type: typeActions.GET_ACCOUNTS_EXTEND_REQUEST,
    query,
    callback,
    isUpdateReducer,
  };
};
const getAccountExtend = (param, query, callback) => {
  return {
    type: typeActions.GET_ACCOUNT_EXTEND_REQUEST,
    param,
    query,
    callback,
  };
};
const getChildrentRelationByAccountExtend = (param, query, callback) => {
  return {
    type: typeActions.GET_RELATION_CHILDRNT_BY_ACCOUNT_EXTEND_REQUEST,
    param,
    query,
    callback,
  };
};

const createAccountExtend = (body, callback) => {
  return {
    type: typeActions.CREATE_ACCOUNT_EXTEND_REQUEST,
    body,
    callback,
  };
};

const updateAccountExtend = (param, body, callback) => {
  return {
    type: typeActions.UPDATE_ACCOUNT_EXTEND_REQUEST,
    param,
    body,
    callback,
  };
};

const deleteAccountExtend = (param, callback) => {
  return {
    type: typeActions.DELETE_ACCOUNT_EXTEND_REQUEST,
    param,
    callback,
  };
};

const updateCurrentAccount = (body, callback) => {
  return {
    type: typeActions.UPDATE_CURRENT_ACCOUNT_REQUEST,
    body,
    callback,
  };
};

const getAccountExtendsByOrganization = (param, callback) => {
  return {
    type: typeActions.GET_ACCOUNT_EXTEND_BY_ORGANIZATION_REQUEST,
    param,
    callback,
  };
};

const getAccountExtendsByPartner = (param, callback) => {
  return {
    type: typeActions.GET_ACCOUNT_EXTEND_BY_PARTNER_REQUEST,
    param,
    callback,
  };
};

const exportAccounts = (query, callback) => {
  return {
    type: typeActions.EXPORT_ACCOUNT_REQUEST,
    callback,
    query,
  };
};

export default {
  setValue,
  accountLogin,
  accountLogOut,
  forgotPassword,
  resetPassword,
  getAccounts,
  getCurrentAccount,
  getAccountById,
  getAccountGroupById,
  createAccount,
  updateAccount,
  deleteAccount,
  configPasswordAccount,
  accountChangePassword,
  //
  getCurrentAccountExtend,
  getAccountsExtend,
  getAccountExtend,
  createAccountExtend,
  updateAccountExtend,
  deleteAccountExtend,
  updateCurrentAccount,
  getAccountExtendsByOrganization,
  importAccount,
  getChildrentRelationByAccountExtend,
  getAccountExtendsByPartner,
  exportAccounts
};
