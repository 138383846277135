import axios from 'axios';
import queryString from 'query-string';
import refreshToken from 'redux/sagas/refreshToken';
import { BASE_URL } from './ServiceURL';
import _ from 'lodash';

const axiosClient = axios.create({
  baseURL: BASE_URL,
  timeout: 5 * 60 * 1000,
  paramsSerializer: {
    serialize: (params) => queryString.stringify(params, { sort: false }),
  },
});

axiosClient.interceptors.request.use(
  async (config) => {
    const token = JSON.parse(localStorage.getItem('token'));
    if (config.url.includes('api/import-account')) return config;
    if (
      !config.url.includes('api/upload') &&
      !config.url.includes('api/statistic') &&
      !config.url.includes('api/update-labcodes') &&
      !config.url.includes('api/send-to-zalo')
    )
      config.headers.authorization = `Bearer ${token}`;
    else config.headers.authorization = `${token}`;
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

axiosClient.interceptors.response.use(
  function (response) {
    if (response) return response;
  },
  async function (error) {
    // const { config } = error;

    // const refreshtoken = localStorage.getItem('refreshtoken');
    // let token = localStorage.getItem('token');

    // if (
    //   error?.response?.status === 401 &&
    //   !!refreshtoken &&
    //   !!token &&
    //   !config.url.includes('admin') &&
    //   (config.retry || 0) < 4
    // ) {
    //   config.retry = config.retry ? config.retry + 1 : 1;

    //   const data = refreshToken();
    //   token = typeof data === 'string' ? data : await data;

    //   // setting updated token
    //   if (token) {
    //     localStorage.setItem('token', token);

    //     return axiosClient(config);
    //   }
    // }
    return Promise.reject(error);
  }
);

const axiosClientFile = axios.create({
  baseURL: BASE_URL,
  timeout: 900000,
  paramsSerializer: {
    serialize: (params) => queryString.stringify(params, { sort: false }),
  },
});

axiosClientFile.interceptors.request.use(
  async (config) => {
    // config.headers.authorization = `Bearer ${localStorage.getItem('token')}`;
    config.responseType = 'blob';
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

axiosClientFile.interceptors.response.use(
  function (response) {
    if (response) return response;
  },
  async function (error) {
    return Promise.reject(error);
  }
);

const custom = async (url, body, method, host) => {
  const config = {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
    // responseType: 'blob',
  };
  return axiosClient({
    baseURL: host,
    url: url,
    data: method === 'get' ? undefined : body,
    method: method,
    ...config,
  }).then(
    (response) => {
      return response.data;
    },
    (error) => {
      return Promise.reject(error);
    }
  );
};

export { axiosClient, axiosClientFile, custom };
