//core-componens
import React, { useEffect, useState } from 'react';
import { Container } from 'reactstrap';
import SimpleHeader from 'components/Headers/SimpleHeader.js';
//custom components
import CilinicalInfomation from './components/CilinicalInfomation';
//module
import _ from 'lodash';
import queryString from 'query-string';
import {
  checkObjectChange,
  deleteFieldEmpty,
  getDayOfPregnancy,
  getPregnancy,
  isNumeric,
  relationOneToMany,
  relationOneToOne,
} from 'common';
import { notificationAlertRef, notify } from 'common';
//styles
import 'assets/css/custom-pages/specify-votes.css';
import 'assets/css/custom-pages/create-specify-votes.css';
import { useFormik } from 'formik';
import { useDispatch, useSelector } from 'react-redux';
import {
  medicalRecordActions,
  patientActions,
  specifyVotesActions,
} from 'redux/actions';
import TestGroupInfomation from './components/TestGroupInformation';
import GeneralInfomation from './components/GeneralInfomation';
import { createSchema } from './ultils/schema';
import { fnValidate } from './ultils/validate';
import { initInfoCreate, randomIDSpecifyVotes } from './ultils/ultils';
import { useHistory, useParams } from 'react-router';
import CONSTANT from 'constant';

function CUSpecifyVotes() {
  const param = useParams();
  const history = useHistory();
  const dispatch = useDispatch();
  const [isUpdate, setIsUpdate] = useState(false);
  const [isDisabled, setIsDisable] = useState(false);
  const { isSubmitForm } = useSelector((state) => state.specifyvoteReducer);
  //specify votes update
  const [currentSP, setCurrentSP] = useState();
  // const [isLoadingUpdate, setIsLoadingUpdate] = useState(false);
  useEffect(() => {
    if (
      history.location.pathname.includes('/specify-votes-update') &&
      param &&
      !isNumeric(param.id)
    ) {
      history.push('/admin/specify-votes-create?tab=specify-vote-all');
    }
    if (param && param.id) {
      //get current specify votes to updates
      setIsUpdate(true);
      dispatch(
        specifyVotesActions.getSpecifyVoteById(param.id, 'populate=*', {
          success: (data) => {
            setCurrentSP(data);
            if (data.specifyVoteStatus === CONSTANT.ORDER_STATUS[0].value) {
              setIsDisable(true); // Không cho sửa với các phiếu đã hủy
            }
          },
          failed: (err) => {
            setCurrentSP(null);
          },
        })
      );
    } else {
      setIsUpdate(false);
    }
  }, []);
  const [isCreatePatient, setIsCreatePatient] = useState(false);
  const [testValue, setTestValue] = useState(0);
  const initInfo = isUpdate
    ? {
        ...initInfoCreate,
        ...currentSP,
      }
    : {
        ...initInfoCreate,
        specifyVotesStatus: 0,
        note: '',
        codePCD: 'HTG',
        testGR: 0,
        weekPregnancy: '',
        dayOfPregnancy: '',
      };
  const formik = useFormik({
    initialValues: initInfo,
    validationSchema: createSchema(isCreatePatient, testValue, currentSP),
    validate: (e) => fnValidate(e, testValue),
    onSubmit: (values, { setFieldValue, resetForm }) => {
      handleSubmit(values, setFieldValue, resetForm);
    },
    enableReinitialize: true,
  });

  useEffect(() => {
    if (isSubmitForm && isSubmitForm.value) {
      formik.handleSubmit();
    }
  }, [isSubmitForm]);
  //Chức năng in phiếu xét nghiệm
  const handlePrintPCD = (id) => {
    dispatch(
      specifyVotesActions.printSpecifyVote(id ?? -1, {
        success: (data) => {
          var blobURL = URL.createObjectURL(data);
          window.open(blobURL);
        },
        failed: (mess) => {
          notify(
            notificationAlertRef,
            'danger',
            'Thông báo',
            `In phiếu xét nghiệm lỗi!`
          );
        },
      })
    );
  };
  //Tạo mói phiếu xét nghiệm
  function handleCreateSpecifyVotes(values, setFieldValue, resetForm) {
    const payload = {
      ...values,
      codeBN: _.get(values, 'codeBN', ''),
      phoneBN: _.get(values, 'codeBN', ''),
      specimens: JSON.stringify(values.specimens),
      medicationsUsing: JSON.stringify(values.medicationsUsing),
      templatePcd: relationOneToOne(formik.values.testGRTemplate),
    };
    deleteFieldEmpty(payload);
    dispatch(
      specifyVotesActions.createSpecifyVote(payload, {
        success: (data) => {
          notify(
            notificationAlertRef,
            'success',
            'Thông báo',
            `Thêm mới phiếu xét nghiệm thành công!.`
          );
          resetForm();
          handleResetAfterSubmitForm(data.id);
        },
        failed: (mess) => {
          notify(
            notificationAlertRef,
            'danger',
            'Thông báo',
            `Thêm mới phiếu xét nghiệm thất bại! Lỗi: ${mess}.`
          );
          handleResetAfterSubmitForm(null);
        },
      })
    );
  }
  function handleUpdateSpecifyVotes(values) {
    //Chuyển một số giá trị về giống lúc đầu khởi tạo (xem initInfo)
    const currentValues = {
      ...values,
    };
    //Nếu khác nhau thì cập nhật
    if (!checkObjectChange(currentValues, initInfo)) {
      dispatch(
        specifyVotesActions.updateSpecifyVote(
          currentSP.id,
          { ...values, codeBN: _.get(values, 'phoneBN', '') },
          {
            success: (data) => {
              notify(
                notificationAlertRef,
                'success',
                'Thông báo',
                `Cập nhật phiếu xét nghiệm ${values?.codePCD ?? ''} thành công.`
              );
              handleResetAfterSubmitForm(data.id);
            },
            failed: (mess) => {
              notify(
                notificationAlertRef,
                'danger',
                'Thông báo',
                `Cập nhật phiếu xét nghiệm ${
                  values?.codePCD ?? ''
                } thất bại!. Lỗi: ${mess}!`
              );
              handleResetAfterSubmitForm(null);
            },
          }
        )
      );
    } else if (isSubmitForm && isSubmitForm.name === 'submitAndPrint') {
      handlePrintPCD(currentSP?.id ?? -1);
      history.push('/admin/specify-vote-all');
    } else {
      history.push('/admin/specify-vote-all');
    }
  }
  function handleResetAfterSubmitForm(id) {
    //reset trạng thái sau khi tạo mới hoặc cập nhật thành công
    dispatch(
      specifyVotesActions.setValueSpecifyVote({
        name: 'isSubmitForm',
        value: {
          name: 'submitAndPrint',
          value: false,
        },
      })
    );
    if (isSubmitForm.name === 'submitAndExit' && !!isSubmitForm.value) {
      history.push('/admin/specify-vote-all');
      history.push('/admin/specify-vote-all');
    }
    if (isSubmitForm.name === 'submitAndPrint' && !!isSubmitForm.value) {
      handlePrintPCD(id);
    }
  }
  const handleSubmit = (values, setFieldValue, resetForm) => {
    const dayOfPregnancy = _.get(values, 'dayOfPregnancy', '0');
    const weekPregnancy = _.get(values, 'weekPregnancy', '0');
    const payload = {
      ...values,
      weekPregnancy: getPregnancy(weekPregnancy, dayOfPregnancy, 'week'),
      dayOfPregnancy: getPregnancy(weekPregnancy, dayOfPregnancy, 'day'),
      phoneBN: _.get(values, 'codeBN', ''),
      emailBN: !!values.emailBN ? values.emailBN : null,
    };
    // return;
    //Xóa các trường dữ liệu trống
    if (!payload?.emailBN) delete payload['emailBN'];
    if (!payload?.email) delete payload['email'];
    if (!isUpdate) {
      //TH: Tạo mới phiếu xét nghiệm
      const payloadMedical = {
        code: values.codeBN,
        height: values.height,
        weight: values.weight,
        anamnesisBA: values.anamnesisBA,
        familyHistory: values.familyHistory,
        lifeStyle: values.lifeStyle,
        pathological1: values.pathological1,
        pathological2: values.pathological2,
        anamnesis: values.anamnesis,
        time: values.time,
        result1: values.result1,
        result2: values.result2,
      };
      deleteFieldEmpty(payloadMedical);
      deleteFieldEmpty(payload);
      if (isCreatePatient) {
        //- TH: Tạo mới bệnh nhân và bệnh án
        //1. Kiểm tra trùng bệnh nhân
        //2. Tạo mới bệnh án
        //3. Tạo mới bệnh nhân
        //4. Tạo mới phiếu xét nghiệm
        dispatch(
          patientActions.getPatients(
            queryString.stringify({
              'filters[code][$eq]': values.codeBN,
            }),
            {
              success: (data) => {
                if (data && data.results.length > 0) {
                  notify(
                    notificationAlertRef,
                    'danger',
                    'Thông báo',
                    'Mã bệnh nhân đã tồn tại trong hệ thống!'
                  );
                  return;
                }
                dispatch(
                  medicalRecordActions.createMedicalRecord(payloadMedical, {
                    success: (medical) => {
                      const medicalID = medical.id;
                      if (!medicalID) return;
                      dispatch(
                        patientActions.createPatient(
                          {
                            code: values.codeBN,
                            name: values.nameBN,
                            phone: values.phoneBN,
                            dateOfBirth: values?.dateOfBirthBN ?? '',
                            phoneContact: values.phoneContact,
                            nameContact: values.nameContact,
                            gender: values.genderBN,
                            address: values.addressBN,
                            email: !!values?.emailBN ? values?.emailBN : null,
                            job: values.jobBN,
                            medicalRecords: relationOneToOne(medicalID, null),
                            doctorAppointeds: relationOneToOne(
                              values.idBS,
                              null
                            ),
                          },
                          {
                            success: (data) => {
                              const patientID = data?.id ?? '';
                              if (!patientID) return;
                              handleCreateSpecifyVotes(
                                {
                                  ...payload,
                                  patient: relationOneToOne(patientID, null),
                                  medicalRecord: relationOneToOne(
                                    medicalID,
                                    null
                                  ),
                                },
                                setFieldValue,
                                resetForm
                              );
                              setIsCreatePatient(false);
                            },
                            failed: (mess) => {
                              notify(
                                notificationAlertRef,
                                'danger',
                                'Thông báo',
                                `Tạo mới bệnh nhân thất bại!. Lỗi: ${mess}!`
                              );
                            },
                          }
                        )
                      );
                    },
                    failed: (errMessage) => {
                      notify(
                        notificationAlertRef,
                        'danger',
                        'Thông báo',
                        `Tạo mới bệnh án thất bại!. Lỗi: ${errMessage}!`
                      );
                    },
                  })
                );
              },
            },
            false
          )
        );
      } else {
        //- TH: Cập nhật bệnh nhân và tạo mới bệnh án
        //1. Cập nhật bệnh nhân:
        //2. Tạo mới bệnh án:
        //3. Tạo phiếu xét nghiệm
        // return;
        dispatch(
          medicalRecordActions.createMedicalRecord(payloadMedical, {
            success: (medical) => {
              const medicalID = medical.id;
              if (!medicalID) return;
              dispatch(
                patientActions.updatePatient(
                  values.idBN,
                  {
                    medicalRecords: relationOneToMany([medicalID], null),
                    doctorAppointeds: relationOneToOne(values.idBS, null),
                  },
                  {
                    success: (data) => {
                      const patientID = data?.id ?? '';
                      if (!patientID) return;
                      handleCreateSpecifyVotes(
                        {
                          ...payload,
                          patient: relationOneToOne(patientID, null),
                          medicalRecord: relationOneToOne(medicalID, null),
                        },
                        setFieldValue,
                        resetForm
                      );
                    },
                    failed: (err) => {
                      notify(
                        notificationAlertRef,
                        'danger',
                        'Thông báo',
                        `Cập nhật bệnh nhân thất bại!. Lỗi: ${err}!`
                      );
                    },
                  }
                )
              );
            },
            failed: (errMessage) => {
              notify(
                notificationAlertRef,
                'danger',
                'Thông báo',
                `Tạo mới bệnh án thất bại!. Lỗi: ${errMessage}!`
              );
            },
          })
        );
      }
    } else {
      //TH: Cập nhật phiếu xét nghiệm
      //1. Cập nhật bệnh nhân
      //2. Cập nhật bệnh án
      //3. Cập nhật phiếu xét nghiệm
      const prePatientInfo = {
        code: currentSP.codeBN,
        name: currentSP.nameBN,
        dateOfBirth: currentSP?.dateOfBirthBN ?? '',
        gender: currentSP.genderBN,
        email: !!currentSP.emailBN ? currentSP.emailBN : null,
        phone: currentSP.phoneBN,
        job: currentSP.jobBN,
        nameContact: currentSP.nameContact,
        phoneContact: currentSP.phoneContact,
        address: currentSP.addressBN,
      };
      const currentPatientInfo = {
        code: payload.codeBN,
        name: payload.nameBN,
        dateOfBirth: payload?.dateOfBirthBN ?? '',
        gender: payload.genderBN,
        email: payload.emailBN,
        phone: payload.phoneBN,
        job: payload.jobBN,
        nameContact: payload.nameContact,
        phoneContact: payload.phoneContact,
        address: payload.addressBN,
        doctorAppointeds: relationOneToOne(payload.idBS, null),
      };
      dispatch(
        patientActions.getPatients(
          queryString.stringify({
            'filters[code][$eq]': currentSP.codeBN,
          }),
          {
            success: (data) => {
              const patientData =
                data.results.length > 0 ? data.results[0] : null;
              if (
                !!patientData &&
                !checkObjectChange(prePatientInfo, currentPatientInfo)
              ) {
                dispatch(
                  patientActions.updatePatient(
                    patientData.id,
                    currentPatientInfo,
                    {
                      success: (data) => {
                        notify(
                          notificationAlertRef,
                          'success',
                          'Thông báo',
                          `Cập nhật thông tin bệnh nhân có mã ${
                            currentSP?.codeBN ?? ''
                          } thành công!`
                        );
                      },
                      failed: (mess) => {
                        notify(
                          notificationAlertRef,
                          'danger',
                          'Thông báo',
                          `Cập nhật thông tin bệnh nhân có mã ${
                            currentSP?.codeBN ?? ''
                          } thất bại! Lỗi : ${mess}`
                        );
                      },
                    }
                  )
                );
              }
              handleUpdateSpecifyVotes({
                ...payload,
              });
            },
            failed: (err) => {
              notify(
                notificationAlertRef,
                'danger',
                'Thông báo',
                `Lấy thông tin bệnh nhân thất bại!. Lỗi: ${err}!`
              );
            },
          },
          false
        )
      );
    }
  };
  return (
    <>
      <form
        onSubmit={() => {
          formik.handleSubmit();
        }}
      >
        <SimpleHeader />
        <Container className="mt--9 custom-specify-votes " fluid>
          <GeneralInfomation
            testValue={testValue}
            setTestValue={setTestValue}
            isCreatePatient={isCreatePatient}
            setIsCreatePatient={setIsCreatePatient}
            randomIDSpecifyVotes={() => {
              return randomIDSpecifyVotes(testValue);
            }}
            formik={formik}
            isUpdate={isUpdate}
            currentSP={currentSP}
            isDisabled={isDisabled}
          />
          <CilinicalInfomation
            isDisabled={isDisabled}
            testValue={testValue}
            setTestValue={setTestValue}
            currentSP={currentSP}
            formik={formik}
          />
          <TestGroupInfomation
            isDisabled={isDisabled}
            testValue={testValue}
            setTestValue={setTestValue}
            formik={formik}
          />
        </Container>
      </form>
    </>
  );
}
export default CUSpecifyVotes;
