import typeActions from 'redux/typeActions';
import CONSTANT from 'constant';
import { returnValue } from 'common';

const initialState = {
  // loading
  isGettingAuthors: false,
  isGettingAuthor: false,
  isCreatingAuthor: false,
  isUpdatingAuthor: false,
  isDeletingAuthor: false,
  // data
  authors: {},
  author: {},
  // error
  errors: {
    getAuthors: '',
    getAuthor: '',
    createAuthor: '',
    updateAuthor: '',
    deleteAuthor: '',
  },
  // query
  search: '',
  page: 1,
  limit: 10,
  startDate: null,
  endDate: null,
};

const authorReducer = (state = initialState, actions) => {
  switch (actions.type) {
    case typeActions.SET_AUTHOR_VALUE:
      const { name, value } = actions;
      if (typeof name !== 'string') return { ...state };
      const obj = returnValue(state, name, value);

      return {
        ...state,
        ...obj,
      };

    case typeActions.GET_AUTHORS_REQUEST:
      return {
        ...state,
        isGettingAuthors: true,
        errors: {
          ...state.errors,
          getAuthors: '',
        },
      };
    case typeActions.GET_AUTHORS_SUCCESS:
      return {
        ...state,
        authors: actions.data ?? {},
        isGettingAuthors: false,
        errors: {
          ...state.errors,
          getAuthors: '',
        },
      };
    case typeActions.GET_AUTHORS_FAILED:
      return {
        ...state,
        authors: {},
        isGettingAuthors: false,
        errors: {
          ...state.errors,
          getAuthors: actions.error,
        },
      };

    case typeActions.GET_AUTHOR_REQUEST:
      return {
        ...state,
        isGettingAuthor: true,
        errors: {
          ...state.errors,
          getAuthor: '',
        },
      };
    case typeActions.GET_AUTHOR_SUCCESS:
      return {
        ...state,
        author: actions.data || {},
        isGettingAuthor: false,
        errors: {
          ...state.errors,
          getAuthor: '',
        },
      };
    case typeActions.GET_AUTHOR_FAILED:
      return {
        ...state,
        author: {},
        isGettingAuthor: false,
        errors: {
          ...state.errors,
          getAuthor: actions.error,
        },
      };

    case typeActions.CREATE_AUTHOR_REQUEST:
      return {
        ...state,
        isCreatingAuthor: true,
        errors: {
          ...state.errors,
          createAuthor: '',
        },
      };
    case typeActions.CREATE_AUTHOR_SUCCESS:
      return {
        ...state,
        isCreatingAuthor: false,
        errors: {
          ...state.errors,
          createAuthor: '',
        },
      };
    case typeActions.CREATE_AUTHOR_FAILED:
      return {
        ...state,
        isCreatingAuthor: false,
        errors: {
          ...state.errors,
          createAuthor: actions.error,
        },
      };

    case typeActions.UPDATE_AUTHOR_REQUEST:
      return {
        ...state,
        isUpdatingAuthor: true,
        errors: {
          ...state.errors,
          updateAuthor: '',
        },
      };
    case typeActions.UPDATE_AUTHOR_SUCCESS:
      return {
        ...state,
        isUpdatingAuthor: false,
        errors: {
          ...state.errors,
          updateAuthor: '',
        },
      };
    case typeActions.UPDATE_AUTHOR_FAILED:
      return {
        ...state,
        isUpdatingAuthor: false,
        errors: {
          ...state.errors,
          updateAuthor: actions.error,
        },
      };

    case typeActions.DELETE_AUTHOR_REQUEST:
      return {
        ...state,
        isDeletingAuthor: true,
        errors: {
          ...state.errors,
          deleteAuthor: '',
        },
      };
    case typeActions.DELETE_AUTHOR_SUCCESS:
      return {
        ...state,
        isDeletingAuthor: false,
        errors: {
          ...state.errors,
          deleteAuthor: '',
        },
      };
    case typeActions.DELETE_AUTHOR_FAILED:
      return {
        ...state,
        isDeletingAuthor: false,
        errors: {
          ...state.errors,
          deleteAuthor: actions.error,
        },
      };

    default:
      return {
        ...state,
      };
  }
};
export default authorReducer;
