import React, { useEffect, useState } from 'react';
import _ from 'lodash';
import { CardHeader, Col, Row } from 'reactstrap';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router';
import { useFormikContext } from 'formik';

import 'assets/css/custom-pages/react-bs-table.css';
import InputCustom from 'views/pages/components/InputCustom';
import { isPCDBL } from 'common';
import SelectCustom from 'views/pages/components/SelectCustom';
import CONSTANT from 'constant';
import { useDidUpdateEffect } from 'utils/HookUtils';

const LSInfo = ({ isDisabled, specifyVoteValue }) => {
  const { id } = useParams();
  const { order } = useSelector((state) => state.orderReducer);
  const { setFieldValue, values, errors, touched, handleBlur, handleChange } =
    useFormikContext();
  const [medicationsUsingValue, setMedicationsUsingValue] = useState(null);

  useEffect(() => {
    if (!order?.specifyVote?.medicationsUsing) {
      setMedicationsUsingValue(null);
      return;
    }
    const medicationsUsingV = CONSTANT.MEDICATIONS_USING.filter((item) =>
      order?.specifyVote?.medicationsUsing.includes(item.value)
    );
    setMedicationsUsingValue(medicationsUsingV);
  }, [order?.specifyVote?.medicationsUsing]);

  useDidUpdateEffect(() => {
    if (!!specifyVoteValue?.medicationsUsing) {
      const medicationsUsingV = CONSTANT.MEDICATIONS_USING.filter((item) =>
        specifyVoteValue?.medicationsUsing.includes(item.value)
      );
      setFieldValue(
        'specifyVote.medicationsUsing',
        medicationsUsingV.map((item) => item.value)
      );
      setMedicationsUsingValue(medicationsUsingV);
    } else {
      setMedicationsUsingValue(null);
    }
  }, [specifyVoteValue]);

  return (
    <>
      <Row>
        <CardHeader className="pb-2 d-flex justify-content-between rounded border-bottom w-100">
          <h3>Thông tin lâm sàng</h3>
        </CardHeader>
      </Row>
      <Row>
        <Col xs={12} md={7}>
          <InputCustom
            name="specifyVote.height"
            id="specifyVote.height"
            label="Chiều cao (cm)"
            type="number"
            placeholder="Nhập chiều cao"
            value={values.specifyVote.height}
            isRequired={
              !isPCDBL(_.get(values, 'specifyVote.testGR', '').toString())
            }
            handleChange={(e) => {
              setFieldValue('specifyVote.height', e.target.value);
            }}
            handleBlur={handleBlur}
            error={errors?.specifyVote?.height}
            touched={touched?.specifyVote?.height}
            disabled={isDisabled}
            row
            labelWidth={160}
          />
        </Col>
      </Row>
      <Row>
        <Col xs={12} md={7}>
          <InputCustom
            name="specifyVote.weight"
            label="Cân nặng (kg)"
            type="number"
            placeholder="Nhập cân nặng"
            value={values.specifyVote.weight}
            isRequired={!isPCDBL(values?.specifyVote?.testGR)}
            handleChange={(e) => {
              setFieldValue('specifyVote.weight', e.target.value);
            }}
            handleBlur={handleBlur}
            error={errors?.specifyVote?.weight}
            touched={touched?.specifyVote?.weight}
            disabled={isDisabled}
            row
            labelWidth={160}
          />
        </Col>
      </Row>
      <Row>
        <Col xs={12}>
          <InputCustom
            name="specifyVote.anamnesisBA"
            label="Tiền sử bệnh nhân"
            type="text"
            placeholder="Nhập tiền sử bệnh nhân"
            value={values.specifyVote.anamnesisBA}
            isRequired={true}
            handleChange={handleChange}
            handleBlur={handleBlur}
            error={errors?.specifyVote?.anamnesisBA}
            touched={touched?.specifyVote?.anamnesisBA}
            disabled={isDisabled}
            row
            labelWidth={160}
          />
        </Col>
      </Row>
      <Row>
        <Col xs={12}>
          <InputCustom
            name="specifyVote.familyHistory"
            label="Tiền sử gia đình"
            type="text"
            placeholder="Nhập tiền sử gia đình"
            value={values.specifyVote.familyHistory}
            isRequired={false}
            handleChange={handleChange}
            handleBlur={handleBlur}
            error={errors?.specifyVote?.familyHistory}
            touched={touched?.specifyVote?.familyHistory}
            disabled={isDisabled}
            row
            labelWidth={160}
          />
        </Col>
      </Row>
      <Row>
        <Col xs={12}>
          <InputCustom
            name="specifyVote.lifeStyle"
            label="Tiếp xúc độc hại" // Lối sống
            type="text"
            placeholder="Nhập thông tin có tiếp xúc các yếu tố độc hại"
            value={values.specifyVote.lifeStyle}
            isRequired={false}
            handleChange={handleChange}
            handleBlur={handleBlur}
            error={errors?.specifyVote?.lifeStyle}
            touched={touched?.specifyVote?.lifeStyle}
            disabled={isDisabled}
            row
            labelWidth={160}
          />
        </Col>
      </Row>

      <Row>
        <Col xs={12}>
          <InputCustom
            name="specifyVote.pathological1"
            label="Bệnh lý cấp tính"
            type="text"
            placeholder="Nhập bệnh lý cấp tính"
            value={values.specifyVote.pathological1}
            isRequired={true}
            handleChange={handleChange}
            handleBlur={handleBlur}
            error={errors?.specifyVote?.pathological1}
            touched={touched?.specifyVote?.pathological1}
            disabled={isDisabled}
            row
            labelWidth={160}
          />
        </Col>
      </Row>

      <Row>
        <Col xs={12}>
          <InputCustom
            name="specifyVote.pathological2"
            label="Bệnh lý mãn tính"
            type="text"
            placeholder="Nhập bệnh lý mãn tính"
            value={values.specifyVote.pathological2}
            isRequired={false}
            handleChange={handleChange}
            handleBlur={handleBlur}
            error={errors?.specifyVote?.pathological2}
            touched={touched?.specifyVote?.pathological2}
            disabled={isDisabled}
            row
            labelWidth={160}
          />
        </Col>
      </Row>
      <Row>
        <Col
          xs={12}
          md={12}
          // lg={10}
        >
          <InputCustom
            name="specifyVote.anamnesis"
            label="Tiền sử bệnh"
            type="text"
            placeholder="Nhập tiền sử bệnh"
            value={values.specifyVote.anamnesis}
            isRequired={false}
            handleChange={handleChange}
            handleBlur={handleBlur}
            error={errors?.specifyVote?.anamnesis}
            touched={touched?.specifyVote?.anamnesis}
            disabled={isDisabled}
            row
            labelWidth={160}
          />
        </Col>
      </Row>
      <Row>
        <Col xs={12} md={12} lg={7}>
          <SelectCustom
            name="specifyVote.medicationsUsing"
            label="Thuốc đang dùng"
            placeholder="Lựa chọn"
            value={medicationsUsingValue}
            isRequired={true}
            isClearable={false}
            handleChange={(e, remove) => {
              if (_.isEmpty(remove?.removedValue)) {
                setMedicationsUsingValue(e);
                setFieldValue(
                  'specifyVote.medicationsUsing',
                  e.map((item) => item.value)
                );
              } else {
                const tmp = medicationsUsingValue.filter(
                  (item) => item.value !== remove.removedValue.value
                );
                setMedicationsUsingValue(tmp);

                const medicationsUsing =
                  values.specifyVote.medicationsUsing.filter(
                    (item) => item !== remove.removedValue.value
                  );
                setFieldValue('specifyVote.medicationsUsing', medicationsUsing);
              }
            }}
            handleBlur={handleBlur}
            // onInputChange={(value) => {}}
            error={errors?.specifyVote?.medicationsUsing}
            touched={touched?.specifyVote?.medicationsUsing}
            options={CONSTANT.MEDICATIONS_USING}
            isDisabled={isDisabled}
            disabled={id !== '-1'}
            isMulti
            row
            labelWidth={160}
          />
          {/* <InputCustom
            name="specifyVote.time"
            label="Thời gian bệnh(Ngày)"
            type="number"
            placeholder="Nhập số ngày bệnh"
            value={values.specifyVote.time}
            isRequired={false}
            handleChange={handleChange}
            handleBlur={handleBlur}
            error={errors?.specifyVote?.time}
            touched={touched?.specifyVote?.time}
            disabled={isDisabled}
            row
            labelWidth={160}
          /> */}
        </Col>
      </Row>
    </>
  );
};

export default LSInfo;
