import React, { memo, useEffect, useRef, useState } from 'react';
import _ from 'lodash';
import { Button, Col, Row } from 'reactstrap';
import DatePicker from 'react-datepicker';
import queryString from 'query-string';

import 'assets/css/custom-pages/react-bs-table.css';
import { isNumeric, notificationAlertRef, notify } from 'common';
import InputCustom from 'views/pages/components/InputCustom';
import SelectCustom from 'views/pages/components/SelectCustom';
import LabelCustom from 'views/pages/components/LabelCustom';
import CONSTANT from 'constant';
import HelperText from 'views/pages/components/HelperText';
import { useDispatch, useSelector } from 'react-redux';
import { patientActions } from 'redux/actions';
import CustomInputCalendar from 'views/pages/components/CustomInputCalendar';
import { useFormikContext } from 'formik';

const BNInfo = ({ isDisabled = false, specifyVoteValue }) => {
  const dispatch = useDispatch();

  const { order } = useSelector((state) => state.orderReducer);
  const {
    setFieldValue,
    values,
    errors,
    touched,
    handleBlur,
    handleChange,
    setValues,
  } = useFormikContext();

  const [patientSearch, setPatientSearch] = useState('');
  const [patientResults, setPatientResults] = useState([]);
  const [patientValue, setPatientValue] = useState(null);
  const [genderValue, setGenderValue] = useState(null);

  useEffect(() => {
    const genderV = CONSTANT.ACCOUNT_GENDER.find(
      (item) => item.value === order?.specifyVote?.genderBN
    );
    if (!!genderV) {
      setGenderValue(genderV);
    } else {
      setGenderValue(CONSTANT.ACCOUNT_GENDER[0]);
    }
  }, [order?.specifyVote?.genderBN]);

  useEffect(() => {
    const delayDebounceFn = setTimeout(() => {
      handleGetPatients();
    }, 500);

    return () => clearTimeout(delayDebounceFn);
  }, [patientSearch]);

  const handleGetPatients = () => {
    const query = {};
    if (patientSearch !== '') {
      query['filters[$and][0][code][$containsi]'] = patientSearch;
    }

    dispatch(
      patientActions.getPatients(
        queryString.stringify(query),
        {
          success: (data) => {
            const results = _.get(data, 'results', []);
            setPatientResults(results);
          },
          failed: () => {},
        },
        false
      )
    );
  };

  useEffect(() => {
    setPatientValue(
      !!specifyVoteValue?.patient
        ? {
            label: specifyVoteValue.codeBN,
            value: specifyVoteValue.nameBN,
            ...specifyVoteValue.patient,
          }
        : null
    );
  }, [specifyVoteValue]);

  function copyToClipboard() {
    navigator.clipboard.writeText(values.specifyVote.codeBN);
    notify(
      notificationAlertRef,
      'success',
      'Thông báo',
      `Đã sao chép ${values.specifyVote.codeBN}!`
    );
  }

  return (
    <>
      <Row>
        <Col xs={12} md={12}>
          <h3>Thông tin người làm xét nghiệm</h3>
        </Col>
      </Row>
      <Row>
        <Col xs={12} md={12} lg={10}>
          <SelectCustom
            name="specifyVote.codeBN"
            label="Số điện thoại"
            placeholder="Lựa chọn"
            value={patientValue}
            isRequired={true}
            isClearable={false}
            handleChange={(e) => {
              setPatientValue(e);
              const genderV = CONSTANT.ACCOUNT_GENDER.find(
                (item) => item.value === e?.gender
              );
              if (!!genderV) {
                setGenderValue(genderV);
              } else {
                setGenderValue(CONSTANT.ACCOUNT_GENDER[0]);
              }

              setValues({
                ...values,
                specifyVote: {
                  ...values.specifyVote,
                  dateOfBirthBN: e.dateOfBirth ?? '',
                  patient: e?.value ?? null,
                  codeBN: e?.label ?? '',
                  nameBN: e?.name ?? '',
                  genderBN: genderV?.value ?? '',
                  emailBN: e?.email ?? '',
                  phoneBN: e?.phone ?? '',
                  jobBN: e?.job ?? '',
                  nameContact: e?.nameContact ?? '',
                  phoneContact: e?.phoneContact ?? '',
                  addressBN: e?.address ?? '',
                },
              });
            }}
            handleBlur={handleBlur}
            // inputValue={patientValue?.label}
            onInputChange={(value, { action }) => {
              if (!isNumeric(value)) return;
              if (action === 'input-change') {
                setPatientValue({
                  label: value,
                  value: -1,
                });
                setFieldValue('specifyVote.codeBN', value);
                setFieldValue('specifyVote.phoneBN', value);
                setFieldValue('specifyVote.patient', null);
              }
              setPatientSearch(value);
            }}
            isDisabled={order?.isUpdated || !!order?.specifyVote?.patient?.name}
            error={errors?.specifyVote?.codeBN}
            touched={touched?.specifyVote?.codeBN}
            options={patientResults.map((item) => ({
              label: item?.code ?? '',
              value: item?.id,
              ...item,
            }))}
            row
            labelWidth={160}
          />
        </Col>
        <Col className="d-flex align-items-center">
          {patientValue && (
            <Button
              onClick={copyToClipboard}
              style={{ fontSize: 10 }}
              color="info"
              size="sm"
            >
              Sao chép
            </Button>
          )}
        </Col>
      </Row>
      <Row>
        <Col
          xs={12}
          md={12}

          // lg={10}
        >
          <InputCustom
            name="specifyVote.nameBN"
            label="Họ tên"
            type="text"
            placeholder="Nhập vào họ và tên"
            value={values.specifyVote.nameBN}
            isRequired={true}
            handleChange={handleChange}
            handleBlur={handleBlur}
            error={errors?.specifyVote?.nameBN}
            touched={touched?.specifyVote?.nameBN}
            row
            labelWidth={160}
            disabled={isDisabled}
          />
        </Col>
      </Row>
      <Row>
        <Col xs={12} md={7}>
          <InputCustom
            name="specifyVote.dateOfBirthBN"
            label="Ngày sinh"
            type="text"
            isRequired
            placeholder="DD/MM/YYYY"
            value={values?.specifyVote?.dateOfBirthBN ?? ''}
            handleChange={handleChange}
            handleBlur={handleBlur}
            error={errors?.specifyVote?.dateOfBirthBN}
            touched={touched?.specifyVote?.dateOfBirthBN}
            disabled={isDisabled}
            row
            labelWidth={160}
          />
        </Col>
        <Col xs={12} md={5}>
          <SelectCustom
            name="specifyVote.genderBN"
            label="Giới tính"
            placeholder="Lựa chọn"
            value={genderValue}
            isRequired={true}
            isClearable={false}
            handleChange={(e) => {
              setGenderValue(e);
              setFieldValue('specifyVote.genderBN', e?.value ?? -1);
            }}
            handleBlur={handleBlur}
            // onInputChange={(value) => {}}
            isDisabled={isDisabled}
            error={errors?.specifyVote?.genderBN}
            touched={touched?.specifyVote?.genderBN}
            options={CONSTANT.ACCOUNT_GENDER}
            row
            labelWidth={80}
          />
        </Col>
      </Row>
      <Row className="justify-content-between">
        <Col xs={12}>
          <InputCustom
            name="specifyVote.emailBN"
            label="Email"
            type="email"
            placeholder="Nhập email"
            value={values.specifyVote.emailBN}
            handleChange={handleChange}
            handleBlur={handleBlur}
            error={errors?.specifyVote?.emailBN}
            touched={touched?.specifyVote?.emailBN}
            disabled={isDisabled}
            row
            labelWidth={160}
          />
        </Col>
      </Row>
      <Row>
        <Col xs={12}>
          <InputCustom
            name="specifyVote.jobBN"
            label="Nghề nghiệp"
            type="text"
            placeholder="Nhập nghề nghiệp"
            value={values.specifyVote.jobBN}
            isRequired={false}
            handleChange={handleChange}
            handleBlur={handleBlur}
            error={errors?.specifyVote?.jobBN}
            touched={touched?.specifyVote?.jobBN}
            disabled={isDisabled}
            row
            labelWidth={160}
          />
        </Col>
      </Row>

      <Row className="justify-content-between">
        <Col
          xs={12}
          md={12}

          // lg={10}
        >
          <InputCustom
            name="specifyVote.nameContact"
            label="Người liên hệ"
            type="text"
            placeholder="Nhập người liên hệ"
            value={values.specifyVote.nameContact}
            isRequired={true}
            handleChange={handleChange}
            handleBlur={handleBlur}
            error={errors?.specifyVote?.nameContact}
            touched={touched?.specifyVote?.nameContact}
            disabled={isDisabled}
            row
            labelWidth={160}
          />
        </Col>
      </Row>
      <Row>
        <Col
          xs={12}
          md={12}

          // lg={10}
        >
          <InputCustom
            name="specifyVote.phoneContact"
            label="SĐT người liên hệ"
            type="text"
            placeholder="Nhập số điện thoại"
            value={values.specifyVote.phoneContact}
            isRequired={true}
            handleChange={handleChange}
            handleBlur={handleBlur}
            error={errors?.specifyVote?.phoneContact}
            touched={touched?.specifyVote?.phoneContact}
            disabled={isDisabled}
            row
            labelWidth={160}
          />
        </Col>
      </Row>
      <Row>
        <Col xs={12}>
          <InputCustom
            name="specifyVote.addressBN"
            label="Địa chỉ bệnh nhân"
            type="textarea"
            placeholder="Nhập địa chỉ liên hệ"
            value={values.specifyVote.addressBN}
            isRequired={true}
            handleChange={handleChange}
            handleBlur={handleBlur}
            error={errors?.specifyVote?.addressBN}
            touched={touched?.specifyVote?.addressBN}
            disabled={isDisabled}
            row
            labelWidth={160}
          />
        </Col>
      </Row>
    </>
  );
};

export default memo(BNInfo);
