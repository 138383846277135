//core-componens
import React, { useCallback, useEffect, useState } from 'react';
import {
  Button,
  Card,
  CardBody,
  CardHeader,
  Col,
  Container,
  Input,
  Row,
} from 'reactstrap';
import Select from 'react-select';
import DatePicker from 'react-datepicker';
//custom components
import LabelCustom from 'views/pages/components/LabelCustom';
import InputCustom from 'views/pages/components/InputCustom';
import SelectCustom from 'views/pages/components/SelectCustom';
//module
import CONSTANT from 'constant';
import classNames from 'classnames';
import * as yup from 'yup';
import _ from 'lodash';
import queryString from 'query-string';
import { getListSelectOption } from 'common';
import { notificationAlertRef, notify, queryFilter } from 'common';
//styles
import 'assets/css/custom-pages/specify-votes.css';
import 'assets/css/custom-pages/create-specify-votes.css';
import { Formik, useFormikContext } from 'formik';
import { useDispatch, useSelector } from 'react-redux';
import { useDelayInputEffect } from 'utils/HookUtils';
import {
  accountActions,
  organizationActions,
  serviceActions,
} from 'redux/actions';
import DatePickerCustom from 'views/pages/components/DatePickerCustom';

function GeneralInfomationRight({ formik, isUpdate, isDisabled }) {
  const { currentSpecifyVotes, isCreatingSpecifyvote } = useSelector(
    (state) => state.specifyvoteReducer
  );

  //get options select
  const [codeTestOptions, setCodeServiceOptions] = useState([]);
  const [specimensOption, setSpecimensOptions] = useState([]);
  const [nameServiceOptions, setNameServiceOptions] = useState([]);
  const [organizationOptions, setOrganizationOptions] = useState([]);
  const [accountExtendsOption, setAccountExtendsOptions] = useState([]);
  const [codeTestIV, setCodeServiceIV] = useState('');
  const [nameServiceIV, setNameServiceIV] = useState('');
  const [dateIV, setDateIV] = useState(new Date());
  //value {label: '',value: ''}
  const [serviceValue, setServiceValue] = useState('');
  const [accountValue, setAccountValue] = useState(null);
  const [nameTCValue, setNameTCValue] = useState(null);

  const [serviceArr, setServiceArr] = useState([]);
  const [specimensValue, setSpecimensValue] = useState(null);
  const [tenBS, setTenBS] = useState('');
  // const [organizationArr, setOrganizationArr] = useState([]);
  //redux
  const { query } = useSelector((state) => state.serviceReducer);
  const { currentAccountExtend } = useSelector((state) => state.accountReducer);
  const accountInfo = currentAccountExtend;
  const dispatch = useDispatch();
  useEffect(() => {
    const acc = currentAccountExtend ?? null;
    if (!!acc && acc.accountType === 5) {
      // formik.setFieldValue('whereTM', acc?.organization?.address ?? '');
      formik.setFieldValue('dateTM', new Date().toISOString());
      // formik.setFieldValue('nameTC', acc?.organization?.name ?? '');
      // formik.setFieldValue('organization', acc?.organization?.id ?? '');
      formik.setFieldValue('lastNameBS', acc?.adminUser?.lastname);
      formik.setFieldValue('firstNameBS', acc?.adminUser?.firstname);
      formik.setFieldValue('idBS', acc?.id ?? '');
    }
  }, [currentAccountExtend]);
  //get data options
  const serviceAC = serviceActions.getServices(
    queryString.stringify(
      queryFilter({
        'filters[$or][0][code][$containsi]': codeTestIV,
        'filters[$or][1][name][$containsi]': nameServiceIV,
        ...query,
        page: 1,
        pageSize: 10,
      })
    ),
    {
      success: (data) => {
        setServiceArr(data?.results);
        setCodeServiceOptions(
          getListSelectOption(data?.results, ['code'], ['code'])
        );
        setNameServiceOptions(
          getListSelectOption(data?.results, ['name'], ['code'])
        );
      },
      failed: (error) => {
        notify(
          notificationAlertRef,
          'failed',
          'Lỗi tìm dữ liệu xét nghiệm!',
          `Lỗi: ${error}`
        );
      },
    },
    false
  );

  const dsBsChiDinh = () => {
    const query = {
      'filters[$and][1][accountType][$eq]': 5,
      'filters[$and][2][isActive][$eq]': true,
    };
    if (tenBS !== '') {
      query['filters[$and][3][name][$containsi]'] = tenBS;
    }

    dispatch(
      accountActions.getAccountsExtend(
        queryString.stringify(query),
        {
          success: (data) => {
            const results = _.get(data, 'results', []);

            setAccountExtendsOptions(
              results.map((item) => ({
                ...item,
                label: `${item?.fullname ?? ''} `,
                value: item.id,
              }))
            );
          },
          failed: () => {},
        },
        false
      )
    );
  };

  const getRelationOrganizations = (id) => {
    dispatch(
      organizationActions.getOrganizationsByAccountExtend(id, {
        success: (data) => {
          setOrganizationOptions(
            data.map((item) => ({
              label: item.name,
              value: item.id,
              ...item,
            }))
          );
        },
        failed: () => {},
      })
    );
  };
  //Gọi API lấy các lựa chọn cho các ô select
  useEffect(() => {
    if (!isUpdate) {
      dispatch(serviceAC);
      // dispatch(organizationAC);
      dsBsChiDinh();
    }
  }, []);

  //Đặt giá trị cho ô chọn bác sĩ khi cập nhật
  useEffect(() => {
    if (isUpdate && _.isEmpty(accountValue)) {
      setAccountValue({
        value: currentSpecifyVotes.idBS,
        label: `${currentSpecifyVotes?.lastNameBS ?? ''} ${
          currentSpecifyVotes?.firstNameBS ?? ''
        } `,
      });
      setNameTCValue({
        value: -1,
        label: currentSpecifyVotes?.nameTC ?? '',
      });
      const optionsSpecimens = CONSTANT.SPECIMENS.filter((specimen) => {
        return (currentSpecifyVotes?.specimens ?? []).includes(specimen.value);
      });
      setSpecimensOptions(optionsSpecimens);
      setSpecimensValue(optionsSpecimens);
    }
  }, [currentSpecifyVotes]);
  //Tìm kiếm sau khi hoàn thành nhập ô input sau 700ms
  useDelayInputEffect(serviceAC, [codeTestIV, nameServiceIV], 700);
  // useDelayInputEffect(organizationAC, organizationIV, 700);
  //Cập nhật thông tin dịch vụ khi chọn trong ô select
  function setInfoService(e) {
    const service = _.find(serviceArr, { code: e?.value ?? '' });
    if (!!service) {
      formik.setFieldValue('codeTest', service?.code ?? '');
      formik.setFieldValue('nameTest', service?.name ?? '');
      formik.setFieldValue('content', service?.content ?? '');
      formik.setFieldValue('codeCTKM', service?.promotionDetail?.code ?? '');
      formik.setFieldValue('unitPrice', service?.unitPrice ?? '');
      formik.setFieldValue('timeTest', service?.time ?? 0);
    }
  }
  // //Lấy danh sách các id của mẫu xét nghiệm
  // function getSpecimensValue() {
  //   const specimentId = formik?.values?.specimens ?? [];

  //   const result = CONSTANT.SPECIMENS.filter((specimens) => {
  //     return _.isArray(specimentId) && specimentId.includes(specimens.value);
  //   });
  //   return result ?? null;
  // }
  //Lấy mẫu xét nghiệm trong danh sach
  function getCodeServiceValue(optionArr) {
    const result = _.find(optionArr, {
      value: formik.values.codeTest,
    });
    return result ?? null;
  }
  //Danh
  useEffect(() => {
    if (!!formik.values.codeTest) {
      setCodeServiceIV(formik.values.codeTest);
    }
  }, [formik.values.codeTest]);
  useEffect(() => {
    if (_.get(formik, 'values.idBS', '') !== '') {
      getRelationOrganizations(formik.values.idBS);
    }
  }, [formik.values?.idBS]);
  //service: set list specimensOption
  function setListOptionSpecimens(e) {
    if (!e) {
      formik.setFieldValue('specimens', []); //clear specimens list
      setSpecimensOptions([]);
      setSpecimensValue(null);
      return;
    }
    const currentServiceArr =
      _.find(serviceArr, {
        code: e.value,
      })?.specimens ?? [];
    const optionsSpecimens = CONSTANT.SPECIMENS.filter((specimen) => {
      return currentServiceArr.includes(specimen.value);
    });

    formik.setFieldValue(
      'specimens',
      optionsSpecimens.map((item) => item.value)
    ); //clear specimens list
    setSpecimensOptions(optionsSpecimens);
    setSpecimensValue(optionsSpecimens);
  }
  function setOtherInfoService(e) {
    if (!e) {
      formik.setFieldValue('content', '');
      setCodeServiceIV('');
      setNameServiceIV('');
      return;
    }
    const currentService = _.find(serviceArr, { code: e.value });
    formik.setFieldValue('content', currentService?.content ?? '');
    if (!!currentService?.promotionDetail) {
      formik.setFieldValue(
        'codeCTKM',
        currentService?.promotionDetail?.code ?? ''
      );
      formik.setFieldValue('unitPrice', currentService?.unitPrice ?? '');
    }
  }

  useEffect(() => {
    if (!isUpdate) {
      setSpecimensValue(null);
      setAccountValue(
        accountInfo?.accountType === 5
          ? {
              label: `${accountInfo?.fullname ?? ''}`,
              value: accountInfo?.code ?? '',
            }
          : null
      );
    }
  }, [isUpdate, isCreatingSpecifyvote]);

  return (
    <Col className="pl-0 col-xl-6  ">
      <Container>
        <Row>
          <Col xs={12}>
            <Row>
              <Col xs={12}>
                <LabelCustom
                  label="Thông tin xét nghiệm"
                  lableTextAlign={'left'}
                  horizontal={true}
                  style={{
                    fontSize: '14px',
                    color: 'rgba(140, 151, 174, 1)',
                    fontWeight: 400,
                    textTransform: 'uppercase',
                  }}
                />
              </Col>
            </Row>
            <Row>
              <Col xl={12}>
                <div>
                  <SelectCustom
                    name="codeTest"
                    label="Mã xét nghiệm"
                    isDisabled={isDisabled}
                    isRequired
                    row
                    labelWidth={150}
                    lableTextAlign={'left'}
                    placeholder="Nhập mã"
                    isClearable={true}
                    handleBlur={formik.handleBlur}
                    value={getCodeServiceValue(codeTestOptions)}
                    handleChange={(e) => {
                      setInfoService(e);
                      formik.setFieldValue('codeTest', e?.value ?? '');
                      setServiceValue(e);
                      setListOptionSpecimens(e);

                      setOtherInfoService(e);
                    }}
                    onInputChange={(e) => {
                      setCodeServiceIV(e);
                    }}
                    options={codeTestOptions}
                    error={formik.errors.codeTest}
                    touched={formik.touched.codeTest}
                  />
                </div>
              </Col>
            </Row>
            <Row>
              <Col xs={12}>
                <div className="pl-0">
                  <SelectCustom
                    label="Tên xét nghiệm"
                    labelWidth={150}
                    lableTextAlign={'left'}
                    placeholder="Nhập để tìm kiếm"
                    isDisabled={isDisabled}
                    handleBlur={formik.handleBlur}
                    row={true}
                    isClearable={true}
                    isRequired={true}
                    handleChange={(e) => {
                      setInfoService(e);
                      formik.setFieldValue('nameTest', e?.label ?? '');
                      setServiceValue(e);
                      setListOptionSpecimens(e);
                      setOtherInfoService(e);
                    }}
                    onInputChange={(e) => {
                      setNameServiceIV(e);
                    }}
                    error={formik.errors.codeTest}
                    touched={formik.touched.codeTest}
                    options={nameServiceOptions}
                    value={getCodeServiceValue(nameServiceOptions)}
                  />
                </div>
              </Col>
            </Row>
            <Row>
              <Col xs={12}>
                <InputCustom
                  row
                  label="Nội dung xét nghiệm"
                  isDisabled={isDisabled}
                  disabled={isDisabled}
                  labelWidth={150}
                  isRequired
                  className={'height-input'}
                  id={'content'}
                  placeholder={'Nội dung xét nghiệm'}
                  type={'text'}
                  name={'content'}
                  handleChange={(e) => {
                    formik.setFieldValue('content', e.target.value);
                  }}
                  handleBlur={formik.handleBlur}
                  value={formik.values.content}
                  error={formik.errors.content}
                  touched={formik.touched.content}
                />
              </Col>
            </Row>
            <Row>
              <Col xs={12}>
                <SelectCustom
                  label="Mẫu xét nghiệm"
                  row
                  isDisabled={isDisabled}
                  isRequired
                  labelWidth={150}
                  lableTextAlign={'left'}
                  name="specimens"
                  id="specimens"
                  placeholder="Lựa chọn mẫu xét nghiệm"
                  isMulti={true}
                  handleChange={(e, remove) => {
                    // formik.setFieldValue(
                    //   'specimens',
                    //   JSON.stringify(arr.map((item) => item.value))
                    // );
                    if (_.isEmpty(remove?.removedValue)) {
                      setSpecimensValue(e);
                      formik.setFieldValue(
                        'specimens',
                        e.map((item) => item.value)
                      );
                    } else {
                      const tmp = specimensValue.filter(
                        (item) => item.value !== remove.removedValue.value
                      );
                      setSpecimensValue(tmp);

                      const specimens = formik.values.specimens.filter(
                        (item) => item !== remove.removedValue.value
                      );
                      formik.setFieldValue('specimens', specimens);
                    }
                  }}
                  value={specimensValue}
                  className="react-select-container"
                  classNamePrefix="react-select"
                  options={specimensOption}
                  handleBlur={formik.handleBlur}
                  error={formik.errors.specimens}
                  touched={formik.touched.specimens}
                />
              </Col>
            </Row>
            <Row>
              <Col xs={12}>
                <InputCustom
                  label="Số lượng phôi"
                  row
                  labelWidth={150}
                  lableTextAlign={'left'}
                  disabled={isDisabled}
                  className={classNames('height-input')}
                  id={'numOfEmbryos'}
                  placeholder={'Nhập số'}
                  type={'number'}
                  name={'numOfEmbryos'}
                  value={formik.values.numOfEmbryos}
                  handleChange={(e) => {
                    formik.setFieldValue('numOfEmbryos', e.target.value);
                  }}
                  handleBlur={formik.handleBlur}
                  error={formik.errors.numOfEmbryos}
                  touched={formik.touched.numOfEmbryos}
                />
              </Col>
            </Row>
            <Row>
              <Col xs={12}>
                <SelectCustom
                  label="Bác sĩ chỉ định"
                  isRequired
                  row
                  labelWidth={150}
                  lableTextAlign={'left'}
                  className={classNames('height-input')}
                  id={'nameBS'}
                  handleBlur={formik.handleBlur}
                  placeholder={'Chọn bác sĩ chỉ định'}
                  type={'text'}
                  name={'nameBS'}
                  isDisabled={isDisabled}
                  value={
                    accountInfo?.accountType === 5
                      ? {
                          label: `${accountInfo?.fullname ?? ''}`,
                          value: accountInfo?.code ?? '',
                        }
                      : accountValue
                  }
                  handleChange={(e) => {
                    setAccountValue(e);
                    setNameTCValue(null);
                    if (!!e) {
                      formik.setFieldValue('lastNameBS', e?.lastname ?? '');
                      formik.setFieldValue('firstNameBS', e?.firstname ?? '');
                      formik.setFieldValue('nameBS', e?.fullname ?? '');
                      formik.setFieldValue('idBS', e?.id ?? '');
                    }
                  }}
                  options={accountExtendsOption}
                  error={formik.errors.nameBS}
                  touched={formik.touched.nameBS}
                />
              </Col>
            </Row>
            <Row>
              <Col xs={12}>
                <SelectCustom
                  label="Phòng khám"
                  row
                  labelWidth={150}
                  lableTextAlign={'left'}
                  className={classNames('height-input')}
                  id={'nameTC'}
                  placeholder={'Chọn phòng khám'}
                  type={'text'}
                  name={'nameTC'}
                  handleChange={(e) => {
                    if (!!e) {
                      // const organ = _.find(organizationArr, {
                      //   code: e.value,
                      // });
                      formik.setFieldValue('whereTM', e?.address ?? '');
                      formik.setFieldValue('dateTM', new Date().toISOString());
                      formik.setFieldValue('nameTC', e?.label ?? '');
                      formik.setFieldValue('organization', e?.value ?? '');
                    }
                    setNameTCValue(e);
                  }}
                  value={
                    // accountInfo?.accountType === 5
                    //   ? {
                    //       label: `${accountInfo?.organization?.name ?? ''}`,
                    //       value: accountInfo?.organization?.code ?? '',
                    //     }
                    //   :
                    nameTCValue
                  }
                  onInputChange={(e) => {
                    // setOrganizationIV(e);
                  }}
                  isDisabled={!formik.values.idBS || isDisabled}
                  isClearable={true}
                  options={organizationOptions}
                  error={formik.errors.nameTC}
                  touched={formik.touched.nameTC}
                />
              </Col>
            </Row>

            <Row>
              <Col xs={12}>
                <InputCustom
                  label={'Nơi thu mẫu'}
                  row
                  labelWidth={150}
                  lableTextAlign={'left'}
                  isRequired
                  className={classNames('height-input')}
                  id={'whereTM'}
                  placeholder={'Nơi thu mẫu'}
                  type={'text'}
                  handleChange={(e) => {
                    formik.setFieldValue('whereTM', e.target.value);
                  }}
                  value={
                    // accountInfo?.accountType === 5
                    //   ? `${accountInfo.organization.address}`
                    //   :
                    formik.values.whereTM ?? ''
                  }
                  name={'whereTM'}
                  disabled={isDisabled}
                  error={formik.errors.whereTM}
                  touched={formik.touched.whereTM}
                />
              </Col>
            </Row>
            <Row>
              <Col xs={12}>
                <DatePickerCustom
                  label={'Ngày thu mẫu'}
                  isRequired={true}
                  labelWidth={150}
                  lableTextAlign={'left'}
                  row
                  dateFormat={'HH:mm dd/MM/yyyy'}
                  placeholderText={'HH:mm dd/MM/yyyy'}
                  maxDate={new Date()}
                  selected={
                    !!formik.values.dateTM
                      ? new Date(formik.values.dateTM)
                      : null
                  }
                  disabled={isDisabled}
                  onChange={(date) => {
                    setDateIV(date);
                    formik.setFieldValue(
                      'dateTM',
                      !!date ? new Date(date).toISOString() : null
                    );
                  }}
                  handleChange={(str) => {
                    formik.setFieldValue(
                      'dateTM',
                      !!str ? new Date(str).toISOString() : null
                    );
                  }}
                  showTimeSelect
                  timeIntervals={1}
                  error={formik.errors.dateTM}
                  touched={formik.touched.dateTM}
                />
              </Col>
              {/* <Col lg={5}>
                <DatePickerCustom
                  label="Giờ thu mẫu"
                  lableTextAlign={'left'}
                  isRequired={true}
                  row
                  isClock={true}
                  maxDate={new Date()}
                  dateFormat={'HH:mm'}
                  placeholderText={'HH:mm'}
                  selected={
                    formik.values.dateTM ? new Date(formik.values.dateTM) : null
                  }
                  handleChange={(date) => {
                    formik.setFieldValue(
                      'dateTM',
                      new Date(date).toISOString()
                    );
                    formik.setFieldValue(
                      'hourTM',
                      moment(new Date(date)).format('HH:mm')
                    );
                  }}
                  handleBlur={formik.handleBlur}
                  showTimeSelect
                  timeIntervals={1}
                  error={formik.errors.dateTM}
                  touched={formik.touched.dateTM}
                />
              </Col> */}
            </Row>
          </Col>
        </Row>
      </Container>
    </Col>
  );
}

export default GeneralInfomationRight;
