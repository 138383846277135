import React, { useEffect, useState } from 'react';
import { Card, CardBody, CardHeader, Col, Row } from 'reactstrap';
import _ from 'lodash';

import InputCustom from 'views/pages/components/InputCustom';
import SelectCustom from 'views/pages/components/SelectCustom';
import { isPCDBL } from 'common';
import CONSTANT from 'constant';

function CilinicalInfomation({ testValue, currentSP, formik, isDisabled }) {
  const { values, setFieldValue, handleBlur, errors, touched } = formik;
  const [medicationsUsingValue, setMedicationsUsingValue] = useState(null);

  useEffect(() => {
    if (!currentSP?.medicationsUsing) {
      setMedicationsUsingValue(null);
      return;
    }
    const medicationsUsingV = CONSTANT.MEDICATIONS_USING.filter((item) =>
      currentSP?.medicationsUsing.includes(item.value)
    );
    setMedicationsUsingValue(medicationsUsingV);
  }, [currentSP?.medicationsUsing]);

  return (
    <Card>
      <CardHeader>
        <h3 className="mb-0">Thông tin lâm sàng</h3>
      </CardHeader>
      <CardBody>
        <Row>
          <Col xs={3}>
            <InputCustom
              isRequired={!isPCDBL(testValue)} //Khác nhóm bệnh lý
              label="Chiều cao (cm)"
              type="number"
              labelWidth={150}
              disabled={isDisabled}
              lableTextAlign={'left'}
              placeholder="Nhập số"
              row={true}
              value={values.height}
              handleChange={(e) => {
                setFieldValue('height', e?.target?.value ?? '');
              }}
              id={'height'}
              name={'height'}
              handleBlur={handleBlur}
              error={!isPCDBL(testValue) && errors.height}
              touched={!isPCDBL(testValue) && touched.height}
            />
          </Col>
          <Col xs={3}>
            <InputCustom
              label="Cân nặng (kg)"
              labelWidth={100}
              lableTextAlign={'left'}
              isRequired={!isPCDBL(testValue)}
              disabled={isDisabled}
              placeholder="Nhập số"
              type="number"
              row={true}
              value={values.weight}
              handleChange={(e) => {
                setFieldValue('weight', e.target.value);
              }}
              id={'weight'}
              name={'weight'}
              handleBlur={handleBlur}
              error={!isPCDBL(testValue) && errors.weight}
              touched={!isPCDBL(testValue) && touched.weight}
            />
          </Col>
          <Col xs={6}>
            <InputCustom
              label="Tiền sử bệnh nhân"
              isRequired={true}
              placeholder="Nhập vào tiền sử bệnh nhân"
              labelWidth={150}
              disabled={isDisabled}
              lableTextAlign={'left'}
              row={true}
              value={values.anamnesisBA}
              handleChange={(e) => {
                setFieldValue('anamnesisBA', e.target.value);
              }}
              id={'anamnesisBA'}
              name={'anamnesisBA'}
              handleBlur={handleBlur}
              error={errors.anamnesisBA}
              touched={touched.anamnesisBA}
            />
          </Col>
        </Row>
        <Row>
          <Col xs={6}>
            <InputCustom
              label="Tiền sử gia đình"
              disabled={isDisabled}
              labelWidth={150}
              lableTextAlign={'left'}
              placeholder="Nhập vào tiền sử gia đình"
              row={true}
              value={values.familyHistory}
              handleChange={(e) => {
                setFieldValue('familyHistory', e.target.value);
              }}
              id={'familyHistory'}
              name={'familyHistory'}
              handleBlur={handleBlur}
              error={errors.familyHistory}
              touched={touched.familyHistory}
            />
          </Col>
          <Col xs={6}>
            <InputCustom
              label="Tiền sử bệnh"
              labelWidth={150}
              lableTextAlign={'left'}
              disabled={isDisabled}
              placeholder="Nhập tiền sử"
              row={true}
              value={values.anamnesis}
              handleChange={(e) => {
                setFieldValue('anamnesis', e.target.value);
              }}
              id={'anamnesis'}
              name={'anamnesis'}
              handleBlur={handleBlur}
              error={errors.anamnesis}
              touched={touched.anamnesis}
            />
          </Col>
        </Row>
        <Row>
          <Col xs={6}>
            <InputCustom
              label="Bệnh lý cấp tính"
              labelWidth={150}
              lableTextAlign={'left'}
              disabled={isDisabled}
              placeholder="Nhập kết quả"
              row={true}
              value={values.pathological1}
              handleChange={(e) => {
                setFieldValue('pathological1', e.target.value);
              }}
              isRequired
              id={'pathological1'}
              name={'pathological1'}
              handleBlur={handleBlur}
              error={errors.pathological1}
              touched={touched.pathological1}
            />
          </Col>
          <Col xs={6}>
            <InputCustom
              label="Bệnh lý mãn tính"
              placeholder="Nhập kết quả"
              labelWidth={150}
              disabled={isDisabled}
              row={true}
              value={values.pathological2}
              handleChange={(e) => {
                setFieldValue('pathological2', e.target.value);
              }}
              id={'pathological2'}
              name={'pathological2'}
              handleBlur={handleBlur}
              error={errors.pathological2}
              touched={touched.pathological2}
            />
          </Col>
        </Row>
        <Row>
          <Col xs={6}>
            {/* <InputCustom
              name="time"
              label="Thời gian bệnh(Ngày)"
              type="number"
              placeholder="Nhập số ngày bệnh"
              value={formik?.values?.time >= 0 ? formik?.values?.time : ""}
              disabled={isDisabled}
              isRequired={false}
              handleChange={(e) => {
                setFieldValue(
                  "time",
                  !!e?.target?.value ? e?.target?.value : 0
                );
              }}
              handleBlur={handleBlur}
              error={formik?.errors?.time}
              touched={formik?.touched?.time}
              row
              labelWidth={150}
            /> */}
            <SelectCustom
              label="Thuốc đang dùng"
              placeholder="Lựa chọn"
              handleBlur={handleBlur}
              value={medicationsUsingValue}
              isRequired={true}
              isClearable={false}
              handleChange={(e, remove) => {
                if (_.isEmpty(remove?.removedValue)) {
                  setMedicationsUsingValue(e);
                  setFieldValue(
                    'medicationsUsing',
                    e.map((item) => item.value)
                  );
                } else {
                  const tmp = medicationsUsingValue.filter(
                    (item) => item.value !== remove.removedValue.value
                  );
                  setMedicationsUsingValue(tmp);

                  const medicationsUsing = values.medicationsUsing.filter(
                    (item) => item !== remove.removedValue.value
                  );
                  setFieldValue('medicationsUsing', medicationsUsing);
                }
              }}
              name="medicationsUsing"
              error={errors?.medicationsUsing}
              touched={touched?.medicationsUsing}
              options={CONSTANT.MEDICATIONS_USING}
              isDisabled={isDisabled}
              isMulti
              row
              labelWidth={150}
            />
          </Col>
          <Col xs={6}>
            <InputCustom
              label="Tiếp xúc độc hại"
              lableTextAlign={'left'}
              labelWidth={150}
              disabled={isDisabled}
              placeholder="Nhập thông tin có tiếp xúc các yếu tố độc hại"
              row={true}
              value={values.lifeStyle}
              handleChange={(e) => {
                setFieldValue('lifeStyle', e.target.value);
              }}
              id={'lifeStyle'}
              name={'lifeStyle'}
              handleBlur={handleBlur}
              error={errors.lifeStyle}
              touched={touched.lifeStyle}
            />
          </Col>
        </Row>

        <Row className="mb-2">
          <Col xs={12}>
            <h3
              style={{
                fontSize: '14px',
                color: 'rgba(140, 151, 174, 1)',
                fontWeight: 400,
                textTransform: 'uppercase',
                marginBottom: 0,
              }}
            >
              Kết quả xét nghiệm di truyền
            </h3>
          </Col>
        </Row>
        <Row>
          <Col xs={6}>
            <InputCustom
              label="Bản thân"
              lableTextAlign={'left'}
              labelWidth={150}
              disabled={isDisabled}
              placeholder="Nhập kết quả"
              row={true}
              value={values.result1}
              handleChange={(e) => {
                setFieldValue('result1', e.target.value);
              }}
              id={'result1'}
              name={'result1'}
              handleBlur={handleBlur}
              error={errors.result1}
              touched={touched.result1}
            />
          </Col>
          <Col xs={6}>
            <InputCustom
              label="Người thân"
              labelWidth={150}
              lableTextAlign={'left'}
              disabled={isDisabled}
              placeholder="Nhập kết quả"
              row={true}
              value={values.result2}
              handleChange={(e) => {
                setFieldValue('result2', e.target.value);
              }}
              id={'result2'}
              name={'result2'}
              handleBlur={handleBlur}
              error={errors.result2}
              touched={touched.result2}
            />
          </Col>
        </Row>
      </CardBody>
    </Card>
  );
}
export default CilinicalInfomation;
