import typeActions from 'redux/typeActions';
import CONSTANT from 'constant';
import { returnValue } from 'common';

const initialState = {
  // loading
  isGettingBills: false,
  isGettingBill: false,
  isCreatingBill: false,
  isUpdatingBill: false,
  isDeletingBill: false,
  // data
  bills: {},
  bill: {},
  // error
  errors: {
    getBills: '',
    getBill: '',
    createBill: '',
    updateBill: '',
    deleteBill: '',
  },
  // query
  search: '',
  page: 1,
  limit: 10,
  startDate: null,
  endDate: null,
};

const billReducer = (state = initialState, actions) => {
  switch (actions.type) {
    case typeActions.SET_BILL_VALUE:
      const { name, value } = actions;
      if (typeof name !== 'string') return { ...state };
      const obj = returnValue(state, name, value);

      return {
        ...state,
        ...obj,
      };

    case typeActions.GET_BILLS_REQUEST:
      return {
        ...state,
        isGettingBills: true,
        errors: {
          ...state.errors,
          getBills: '',
        },
      };
    case typeActions.GET_BILLS_SUCCESS:
      return {
        ...state,
        bills: actions.data ?? {},
        isGettingBills: false,
        errors: {
          ...state.errors,
          getBills: '',
        },
      };
    case typeActions.GET_BILLS_FAILED:
      return {
        ...state,
        bills: {},
        isGettingBills: false,
        errors: {
          ...state.errors,
          getBills: actions.error,
        },
      };

    case typeActions.GET_BILL_REQUEST:
      return {
        ...state,
        isGettingBill: true,
        errors: {
          ...state.errors,
          getBill: '',
        },
      };
    case typeActions.GET_BILL_SUCCESS:
      return {
        ...state,
        bill: actions.data || {},
        isGettingBill: false,
        errors: {
          ...state.errors,
          getBill: '',
        },
      };
    case typeActions.GET_BILL_FAILED:
      return {
        ...state,
        bill: {},
        isGettingBill: false,
        errors: {
          ...state.errors,
          getBill: actions.error,
        },
      };

    case typeActions.CREATE_BILL_REQUEST:
      return {
        ...state,
        isCreatingBill: true,
        errors: {
          ...state.errors,
          createBill: '',
        },
      };
    case typeActions.CREATE_BILL_SUCCESS:
      return {
        ...state,
        isCreatingBill: false,
        errors: {
          ...state.errors,
          createBill: '',
        },
      };
    case typeActions.CREATE_BILL_FAILED:
      return {
        ...state,
        isCreatingBill: false,
        errors: {
          ...state.errors,
          createBill: actions.error,
        },
      };

    case typeActions.UPDATE_BILL_REQUEST:
      return {
        ...state,
        isUpdatingBill: true,
        errors: {
          ...state.errors,
          updateBill: '',
        },
      };
    case typeActions.UPDATE_BILL_SUCCESS:
      return {
        ...state,
        isUpdatingBill: false,
        errors: {
          ...state.errors,
          updateBill: '',
        },
      };
    case typeActions.UPDATE_BILL_FAILED:
      return {
        ...state,
        isUpdatingBill: false,
        errors: {
          ...state.errors,
          updateBill: actions.error,
        },
      };

    case typeActions.DELETE_BILL_REQUEST:
      return {
        ...state,
        isDeletingBill: true,
        errors: {
          ...state.errors,
          deleteBill: '',
        },
      };
    case typeActions.DELETE_BILL_SUCCESS:
      return {
        ...state,
        isDeletingBill: false,
        errors: {
          ...state.errors,
          deleteBill: '',
        },
      };
    case typeActions.DELETE_BILL_FAILED:
      return {
        ...state,
        isDeletingBill: false,
        errors: {
          ...state.errors,
          deleteBill: actions.error,
        },
      };

    default:
      return {
        ...state,
      };
  }
};
export default billReducer;
