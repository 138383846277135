import _ from 'lodash';
import { all, call, put, takeEvery, takeLatest } from 'redux-saga/effects';

import typeActions from 'redux/typeActions';
import ServiceURL from 'services/ServiceURL';
import { DELETE, GET, GET_FILE, POST, POST_FILE, PUT } from 'services/ServiceBase';
import CONSTANT from '../../constant';
import { convertMessageError } from 'common';

export function* accountLogIn(data) {
  const url = ServiceURL.logIn;
  const callback = data.callback;
  try {
    const res = yield call(POST, url, data.body);

    if (!!res.data?.error?.message) {
      yield put({
        type: typeActions.ACCOUNT_LOGIN_FAILED,
        error: res.data?.error?.message,
      });
      !!callback?.failed && callback.failed(res.data?.error?.message);
    } else {
      yield put({
        type: typeActions.ACCOUNT_LOGIN_SUCCESS,
        data: res.data?.data,
      });
      !!callback?.success && callback.success(res.data?.data);
    }
  } catch (error) {
    yield put({
      type: typeActions.ACCOUNT_LOGIN_FAILED,
      error:
        error.response?.data?.error?.message === 'User not active' &&
          error.response?.data?.error?.status === 400
          ? 'Tài khoản đã bị khoá! Vui lòng liên hệ với admin và thủ lại sau!'
          : error.response?.data?.error?.message === 'Invalid credentials' &&
            error.response?.data?.error?.status === 400
            ? 'Tài khoản hoặc mật khẩu chưa đúng'
            : '',
    });
    !!callback?.failed &&
      callback.failed(
        error.response?.data?.error?.message === 'User not active' &&
          error.response?.data?.error?.status === 400
          ? 'Tài khoản đã bị khoá! Vui lòng liên hệ với admin và thủ lại sau!'
          : error.response?.data?.error?.message === 'Invalid credentials' &&
            error.response?.data?.error?.status === 400
            ? 'Tài khoản hoặc mật khẩu chưa đúng'
            : ''
      );
  }
}

export function* accountLogOut() {
  const url = ServiceURL.logOut;
  localStorage.clear();
  try {
    const res = yield call(POST, url);

    if (!!res.data?.error?.message) {
      yield put({
        type: typeActions.ACCOUNT_LOGOUT_FAILED,
        error: res.data.error.message,
      });
    } else {
      yield put({
        type: typeActions.ACCOUNT_LOGOUT_SUCCESS,
      });
    }
  } catch (error) {
    yield put({
      type: typeActions.ACCOUNT_LOGOUT_FAILED,
      error: error?.response?.data?.error?.message ?? '',
    });
  }
}

export function* forgotPassword(data) {
  const url = ServiceURL.forgotPassword;
  const callback = data.callback;
  try {
    const res = yield call(POST, url, data.body);
    if (!!res.data?.error?.message) {
      yield put({
        type: typeActions.ACCOUNT_FORGOT_PASSWORD_FAILED,
        error: res.data.error.message,
      });
      !!callback?.failed && callback.failed(res.data.error.message);
    } else {
      yield put({
        type: typeActions.ACCOUNT_FORGOT_PASSWORD_SUCCESS,
      });
      !!callback?.success && callback.success();
    }
  } catch (error) {
    yield put({
      type: typeActions.ACCOUNT_FORGOT_PASSWORD_FAILED,
      error: error?.response?.data?.error?.message ?? '',
    });
    !!callback?.failed &&
      callback.failed(error?.response?.data?.error?.message ?? '');
  }
}

export function* resetPassword(data) {
  const url = ServiceURL.resetPassword + '?' + data.query;
  const callback = data.callback;
  try {
    const res = yield call(POST, url, data.body);
    if (!!res.data?.error?.message) {
      yield put({
        type: typeActions.ACCOUNT_RESET_PASSWORD_FAILED,
        error: res.data.error.message,
      });
      !!callback?.failed && callback.failed(res.data.error.message);
    } else {
      yield put({
        type: typeActions.ACCOUNT_RESET_PASSWORD_SUCCESS,
      });
      !!callback?.success && callback.success();
    }
  } catch (error) {
    yield put({
      type: typeActions.ACCOUNT_RESET_PASSWORD_FAILED,
      error: error?.response?.data?.error?.message ?? '',
    });
    !!callback?.failed &&
      callback.failed(error?.response?.data?.error?.message ?? '');
  }
}

export function* getAccounts(data) {
  const url = `${ServiceURL.accounts}?${data.query}&sort[0]=createdAt:DESC`;
  const callback = data.callback;
  try {
    const res = yield call(GET, url);
    if (!!res.data?.error?.message) {
      yield put({
        type: typeActions.GET_ACCOUNTS_FAILED,
        error: res.data?.error?.message,
      });
      !!callback?.failed && callback.failed(res.data.error.message);
    } else {
      // const arrApiAccExtend =
      //   res.data?.data?.results.map((item) => {
      //     const urlAccExtend = `${ServiceURL.accountExtend}?filters[$and][0][email][$eq]=${item.email}`;
      //     return call(GET, urlAccExtend);
      //   }) ?? [];
      // const resAccExtend = yield all(arrApiAccExtend);
      // const pagination = _.get(res, 'data.data.pagination', {});
      // const results = _.get(res, 'data.data.results', []);
      // const data = {
      //   pagination: {
      //     page: pagination?.page ?? 1,
      //     pageCount: pagination?.pageCount ?? 1,
      //     pageSize: pagination?.pageSize ?? 0,
      //     total: pagination?.total ?? 0,
      //   },
      //   results: results.map((item) => {
      //     let accExtend = {};
      //     resAccExtend.every((v) => {
      //       const acc = _.get(v, 'data.results[0]', {
      //         email: '',
      //       });
      //       if (item.email === acc.email) {
      //         accExtend = {
      //           ...acc,
      //           avatar: acc?.avatar?.url ?? '',
      //           avatarId: acc?.avatar?.id ?? '',
      //           accId: acc?.id ?? '',
      //         };
      //         delete accExtend.id;
      //         return false;
      //       }
      //       return true;
      //     });

      //     return {
      //       ...item,
      //       ...accExtend,
      //     };
      //   }),
      // };
      yield put({
        type: typeActions.GET_ACCOUNTS_SUCCESS,
        data: res.data,
      });
      !!callback?.success && callback.success(res.data);
    }
  } catch (error) {
    yield put({
      type: typeActions.GET_ACCOUNTS_FAILED,
      error: error?.response?.data?.error?.message ?? '',
    });
    !!callback?.failed &&
      callback.failed(error?.response?.data?.error?.message ?? '');
  }
}

export function* getCurrentAccount(data) {
  const url = `${ServiceURL.accounts}/${ServiceURL.me}`;
  const callback = data.callback;
  try {
    const res = yield call(GET, url);
    if (!!res.data?.error?.message) {
      yield put({
        type: typeActions.GET_CURRENT_ACCOUNT_FAILED,
        error: res.data.error.message,
      });
      !!callback?.failed &&
        callback.failed(res.data.error.message, res.data.error.status);
    } else {
      yield put({
        type: typeActions.GET_CURRENT_ACCOUNT_SUCCESS,
        data: res.data?.data,
      });
      !!callback?.success && callback.success(res.data?.data);
    }
  } catch (error) {
    yield put({
      type: typeActions.GET_CURRENT_ACCOUNT_FAILED,
      error: error?.response?.data?.error?.message ?? '',
    });
    !!callback?.failed &&
      callback.failed(
        error?.response?.data?.error?.message ?? '',
        error?.response?.data?.error?.status
      );
  }
}
export function* getGroupAccountById(data) {
  const callback = data.callback;
  try {
    const requestAccount = data.param.map((obj) => {
      const url = ServiceURL.accountExtend + '/' + obj.id + '?' + data.query;
      return call(GET, url);
    });
    const res = yield all(requestAccount);
    const results = res.map((r) => r.data);
    !!callback.success && callback.success(results);
  } catch (error) {
    !!callback.failed && callback.failed(error.message);
  }
}
export function* getAccountById(data) {
  const url = ServiceURL.accountExtend + '/' + data.param + '?' + data.query;
  const callback = data.callback;
  try {
    const res = yield call(GET, url);
    if (!!res.data?.error?.message) {
      yield put({
        type: typeActions.GET_ACCOUNT_BY_ID_FAILED,
        error: res.data.error.message,
      });
      !!callback?.failed && callback.failed(res.data.error.message);
    } else {
      yield put({
        type: typeActions.GET_ACCOUNT_BY_ID_SUCCESS,
        data: res.data,
      });
      !!callback?.success && callback.success(res.data);
    }
  } catch (error) {
    yield put({
      type: typeActions.GET_ACCOUNT_BY_ID_FAILED,
      error: error?.response?.data?.error?.message ?? '',
    });
    !!callback?.failed &&
      callback.failed(error?.response?.data?.error?.message ?? '');
  }
}

export function* createAccount(data) {
  const url = ServiceURL.accounts;
  const callback = data.callback;
  try {
    const res = yield call(POST, url, data.body);
    if (!!res.data?.error?.message) {
      yield put({
        type: typeActions.CREATE_ACCOUNT_FAILED,
        error: res.data.error.message,
      });
      !!callback?.failed && callback.failed(res.data.error.message);
    } else {
      yield put({
        type: typeActions.CREATE_ACCOUNT_SUCCESS,
        data: res.data?.data,
      });
      !!callback?.success && callback.success(res.data?.data);
    }
  } catch (error) {
    const message =
      error.response.data.error.status === 400 &&
        error.response.data.error.message === 'Email already taken'
        ? 'Tên tài khoản đã tồn tại'
        : error?.response?.data?.error?.message ?? '';
    yield put({
      type: typeActions.CREATE_ACCOUNT_FAILED,
      error: message,
    });

    !!callback?.failed && callback.failed(message);
  }
}

export function* updateAccount(data) {
  const url = ServiceURL.accounts + '/' + data.param;
  const callback = data.callback;
  try {
    const res = yield call(PUT, url, data.body);
    if (!!res.data?.error?.message) {
      yield put({
        type: typeActions.UPDATE_ACCOUNT_FAILED,
        error: res.data.error.message,
      });
      !!callback?.failed && callback.failed(res.data.error.message);
    } else {
      yield put({
        type: typeActions.UPDATE_ACCOUNT_SUCCESS,
        data: res.data?.data,
      });

      !!callback?.success && callback.success(res.data?.data);
    }
  } catch (error) {
    yield put({
      type: typeActions.UPDATE_ACCOUNT_FAILED,
      error: error?.response?.data?.error?.message ?? '',
    });
    !!callback?.failed &&
      callback.failed(error?.response?.data?.error?.message ?? '');
  }
}

export function* deleteAccount(data) {
  const url = ServiceURL.accounts + '/' + data.param;
  const callback = data.callback;
  try {
    const res = yield call(DELETE, url);
    if (!!res.data?.error?.message) {
      yield put({
        type: typeActions.DELETE_ACCOUNT_FAILED,
        error: res.data.error.message,
      });
      !!callback?.failed && callback.failed(res.data.error.message);
    } else {
      yield put({
        type: typeActions.DELETE_ACCOUNT_SUCCESS,
      });
      !!callback?.success && callback.success();
    }
  } catch (error) {
    yield put({
      type: typeActions.DELETE_ACCOUNT_FAILED,
      error: error?.response?.data?.error?.message ?? '',
    });
    !!callback?.failed &&
      callback.failed(error?.response?.data?.error?.message ?? '');
  }
}

export function* configPasswordAccount(data) {
  const url = ServiceURL.accounts + '/' + ServiceURL.configPasswordAccount;
  const callback = data.callback;
  try {
    const res = yield call(POST, url, data.body);
    if (!!res.data?.error?.message) {
      yield put({
        type: typeActions.CONFIG_PASSWORD_ACCOUNT_FAILED,
        error: res.data.error.message,
      });
      !!callback?.failed && callback.failed(res.data.error.message);
    } else {
      yield put({
        type: typeActions.CONFIG_PASSWORD_ACCOUNT_SUCCESS,
      });
      !!callback?.success && callback.success();
    }
  } catch (error) {
    yield put({
      type: typeActions.CONFIG_PASSWORD_ACCOUNT_FAILED,
      error: error?.response?.data?.error?.message ?? '',
    });
    !!callback?.failed &&
      callback.failed(error?.response?.data?.error?.message ?? '');
  }
}

export function* accountChangePassword(data) {
  const url =
    ServiceURL.accounts + '/' + ServiceURL.changePassword + '/' + data.param;
  const callback = data.callback;
  try {
    const res = yield call(POST, url, data.body);
    if (!!res.data?.error?.message) {
      yield put({
        type: typeActions.ACCOUNT_CHANGE_PASSWORD_FAILED,
        error: res.data.error.message,
      });
      !!callback?.failed && callback.failed(res.data.error.message);
    } else {
      yield put({
        type: typeActions.ACCOUNT_CHANGE_PASSWORD_SUCCESS,
      });
      !!callback?.success && callback.success();
    }
  } catch (error) {
    yield put({
      type: typeActions.ACCOUNT_CHANGE_PASSWORD_FAILED,
      error: error?.response?.data?.error?.message ?? '',
    });
    !!callback?.failed &&
      callback.failed(error?.response?.data?.error?.message ?? '');
  }
}

export function* getAccountsExtend(data) {
  const url = `${ServiceURL.accountExtend}?filters[$and][0][deleted][$eq]=false&sort=createdAt:DESC&${data.query}`;
  const callback = data.callback;
  try {
    const res = yield call(GET, url);
    if (!!res.data?.error?.message) {
      yield put({
        type: typeActions.GET_ACCOUNTS_EXTEND_FAILED,
        error: res.data?.error?.message,
      });
      !!callback?.failed && callback.failed(res.data.error.message);
    } else {
      if (data.isUpdateReducer) {
        yield put({
          type: typeActions.GET_ACCOUNTS_EXTEND_SUCCESS,
          data: res.data,
        });
      }
      !!callback?.success && callback.success(res.data);
    }
  } catch (error) {
    yield put({
      type: typeActions.GET_ACCOUNTS_EXTEND_FAILED,
      error: error?.response?.data?.error?.message ?? '',
    });
    !!callback?.failed &&
      callback.failed(error?.response?.data?.error?.message ?? '');
  }
}

export function* getAccountExtend(data) {
  const url = `${ServiceURL.accountExtend}/${data.param}`;
  const callback = data.callback;
  try {
    const res = yield call(GET, url);
    if (!!res.data?.error?.message) {
      yield put({
        type: typeActions.GET_ACCOUNT_EXTEND_FAILED,
        error: res.data.error.message,
      });
      !!callback?.failed && callback.failed(res.data.error.message);
    } else {
      yield put({
        type: typeActions.GET_ACCOUNT_EXTEND_SUCCESS,
        data: res.data,
      });
      !!callback?.success && callback.success(res.data);
    }
  } catch (error) {
    yield put({
      type: typeActions.GET_ACCOUNT_EXTEND_FAILED,
      error: error?.response?.data?.error?.message ?? '',
    });
    !!callback?.failed &&
      callback.failed(error?.response?.data?.error?.message ?? '');
  }
}

export function* getCurrentAccountExtend(data) {
  const url = `${ServiceURL.accountExtend}?${data.query}`;
  const callback = data.callback;
  try {
    const res = yield call(GET, url);
    if (!!res.data?.error?.message) {
      yield put({
        type: typeActions.GET_CURRENT_ACCOUNT_EXTEND_FAILED,
        error: res.data.error.message,
      });
      !!callback?.failed && callback.failed(res.data.error.message);
    } else {
      yield put({
        type: typeActions.GET_CURRENT_ACCOUNT_EXTEND_SUCCESS,
        data: res.data?.results[0] ?? {},
      });

      !!callback?.success && callback.success(res.data?.results[0] ?? {});
    }
  } catch (error) {
    yield put({
      type: typeActions.GET_CURRENT_ACCOUNT_EXTEND_FAILED,
      error: error?.response?.data?.error?.message ?? '',
    });
    !!callback?.failed &&
      callback.failed(error?.response?.data?.error?.message ?? '');
  }
}

export function* createAccountExtend(data) {
  const url = `${ServiceURL.accountExtend}`;
  const callback = data.callback;
  try {
    const res = yield call(POST, url, data.body);
    if (!!res.data?.error?.message) {
      yield put({
        type: typeActions.CREATE_ACCOUNT_EXTEND_FAILED,
        error: res.data.error.message,
      });
      !!callback?.failed && callback.failed(res.data.error.message);
    } else {
      yield put({
        type: typeActions.CREATE_ACCOUNT_EXTEND_SUCCESS,
        data: res.data,
      });
      !!callback?.success && callback.success(res.data);
    }
  } catch (error) {
    const message = convertMessageError('c', error, ' tài khoản ');
    yield put({
      type: typeActions.CREATE_ACCOUNT_EXTEND_FAILED,
      error: message,
    });
    !!callback?.failed && callback.failed(message);
  }
}

export function* updateAccountExtend(data) {
  const url = `${ServiceURL.accountExtend}/${data.param}`;
  const callback = data.callback;
  try {
    const res = yield call(PUT, url, data.body);
    if (!!res.data?.error?.message) {
      yield put({
        type: typeActions.UPDATE_ACCOUNT_EXTEND_FAILED,
        error: res.data.error.message,
      });
      !!callback?.failed && callback.failed(res.data.error.message);
    } else {
      yield put({
        type: typeActions.UPDATE_ACCOUNT_EXTEND_SUCCESS,
      });
      !!callback?.success && callback.success();
    }
  } catch (error) {
    yield put({
      type: typeActions.UPDATE_ACCOUNT_EXTEND_FAILED,
      error: error?.response?.data?.error?.message ?? '',
    });
    !!callback?.failed &&
      callback.failed(error?.response?.data?.error?.message ?? '');
  }
}

export function* deleteAccountExtend(data) {
  const url = `${ServiceURL.accountExtend}/${data.param}`;
  const callback = data.callback;
  try {
    const res = yield call(DELETE, url);
    if (!!res.data?.error?.message) {
      yield put({
        type: typeActions.DELETE_ACCOUNT_EXTEND_FAILED,
        error: res.data.error.message,
      });
      !!callback?.failed && callback.failed(res.data.error.message);
    } else {
      yield put({
        type: typeActions.DELETE_ACCOUNT_EXTEND_SUCCESS,
      });
      !!callback?.success && callback.success();
    }
  } catch (error) {
    yield put({
      type: typeActions.DELETE_ACCOUNT_EXTEND_FAILED,
      error: error?.response?.data?.error?.message ?? '',
    });
    !!callback?.failed &&
      callback.failed(error?.response?.data?.error?.message ?? '');
  }
}

export function* updateCurrentAccount(data) {
  const url = `${ServiceURL.accounts}/${ServiceURL.me}`;
  const callback = data.callback;
  try {
    const res = yield call(PUT, url, data.body);

    if (!!res.data?.error?.message) {
      yield put({
        type: typeActions.UPDATE_CURRENT_ACCOUNT_FAILED,
        error: res.data.error.message,
      });
      !!callback?.failed &&
        callback.failed(res.data.error.message, res.data.error.status);
    } else {
      yield put({
        type: typeActions.UPDATE_CURRENT_ACCOUNT_SUCCESS,
        data: res.data?.data,
      });
      !!callback?.success && callback.success(res.data?.data);
    }
  } catch (error) {
    const status = _.get(error, 'response.data.error.status', -1);
    const messageDetail = _.get(
      error,
      'response.data.error.details.currentPassword[0]',
      ''
    );

    yield put({
      type: typeActions.UPDATE_CURRENT_ACCOUNT_FAILED,
      error:
        status === 400 && messageDetail === 'Invalid credentials'
          ? 'Mật khẩu hiện tại chưa đúng'
          : error?.response?.data?.error?.message ?? '',
    });
    !!callback?.failed &&
      callback.failed(
        status === 400 && messageDetail === 'Invalid credentials'
          ? 'Mật khẩu hiện tại chưa đúng'
          : error?.response?.data?.error?.message ?? ''
      );
  }
}

export function* getAccountExtendsByOrganization({ param, callback }) {
  try {
    const urlRelation = `${ServiceURL.relation}/api::organization.organization/${param}/accountExtends?pageSize=100`;
    const res = yield call(GET, urlRelation);
    const results = _.get(res, 'data.results', []);
    const apiAccountExtends =
      results.map((item) => {
        const url = `${ServiceURL.accountExtend}/${item.id}`;
        return call(GET, url);
      }) ?? [];
    let resAccountExtends = [];
    try {
      resAccountExtends = yield all(apiAccountExtends);
    } catch (error) {
      //
    }
    !!callback?.success &&
      callback.success(
        resAccountExtends
          .map((item) => item.data)
          .filter((item) => {
            return (
              !item?.deleted &&
              item?.isActive &&
              item.accountType === CONSTANT.ACCOUNT_TYPE[5].value
            );
          })
      );
  } catch (error) {
    // !!callback?.failed && callback.failed(error);
  }
}

export function* getAccountExtendsByPartner({ param, callback }) {
  try {
    const urlRelation = `${ServiceURL.relation}/api::partner.partner/${param}/doctorPrescribes?pageSize=100`;
    const res = yield call(GET, urlRelation);
    const results = _.get(res, 'data.results', []);
    const apiAccountExtends =
      results.map((item) => {
        const url = `${ServiceURL.accountExtend}/${item.id}`;
        return call(GET, url);
      }) ?? [];
    let resAccountExtends = [];
    try {
      resAccountExtends = yield all(apiAccountExtends);
    } catch (error) {
      //
    }
    !!callback?.success &&
      callback.success(
        resAccountExtends
          .map((item) => item.data)
          .filter((item) => {
            return (
              !item?.deleted &&
              item?.isActive &&
              item.accountType === CONSTANT.ACCOUNT_TYPE[4].value
            );
          })
      );
  } catch (error) {
    // !!callback?.failed && callback.failed(error);
  }
}

export function* importAccount(data) {
  const url = ServiceURL.importAccount;
  const callback = data.callback;
  try {
    const res = yield call(POST_FILE, url, data.body);
    if (!!res.data?.error?.message) {
      !!callback?.failed && callback.failed(res.data.error.message);
    } else {
      !!callback?.success && callback.success();
    }
  } catch (error) {
    !!callback?.failed &&
      callback.failed(_.get(error, 'response.data.error.message', ''));
  }
}
export function* getRelationChildrentByAccountExtend(data) {
  const url = `${ServiceURL.relation}/api::account-extend.account-extend/${data.param}/chidrent?pageSize=100&page=1`;
  const callback = data.callback;
  try {
    const res = yield call(GET, url);
    if (!!res.data?.error?.message) {
      yield put({
        type: typeActions.GET_RELATION_CHILDRNT_BY_ACCOUNT_EXTEND_FAILED,
        error: res.data.error.message,
      });
      !!callback?.failed && callback.failed(res.data.error.message);
    } else {
      yield put({
        type: typeActions.GET_RELATION_CHILDRNT_BY_ACCOUNT_EXTEND_SUCCESS,
        data: res.data?.results ?? [],
      });

      !!callback?.success && callback.success(res.data?.results ?? []);
    }
  } catch (error) {
    yield put({
      type: typeActions.GET_RELATION_CHILDRNT_BY_ACCOUNT_EXTEND_FAILED,
      error: error?.response?.data?.error?.message ?? '',
    });
    !!callback?.failed &&
      callback.failed(error?.response?.data?.error?.message ?? '');
  }
}

export function* exportAccount(data) {
  const url = `${ServiceURL.exportAccount}?${data.query}`;
  const callback = data.callback;
  try {
    const res = yield call(GET_FILE, url);
    if (!!res.data?.error?.message) {
      yield put({
        type: typeActions.EXPORT_ACCOUNT_FAILED,
        error: res.data?.error?.message,
      });
      !!callback?.failed && callback.failed(res.data.error.message);
    } else {
      yield put({
        type: typeActions.EXPORT_ACCOUNT_SUCCESS,
        data: res.data,
      });

      !!callback?.success && callback.success(res.data);
    }
  } catch (error) {
    yield put({
      type: typeActions.EXPORT_ACCOUNT_FAILED,
      error: error?.response?.data?.error?.message ?? '',
    });
    !!callback?.failed &&
      callback.failed(error?.response?.data?.error?.message ?? '');
  }
}
export default function* accountSaga() {
  yield takeLatest(typeActions.ACCOUNT_LOGIN_REQUEST, accountLogIn);
  yield takeLatest(typeActions.ACCOUNT_LOGOUT_REQUEST, accountLogOut);
  yield takeLatest(typeActions.ACCOUNT_FORGOT_PASSWORD_REQUEST, forgotPassword);
  yield takeLatest(typeActions.ACCOUNT_RESET_PASSWORD_REQUEST, resetPassword);
  yield takeLatest(typeActions.GET_ACCOUNTS_REQUEST, getAccounts);
  yield takeLatest(typeActions.GET_CURRENT_ACCOUNT_REQUEST, getCurrentAccount);
  yield takeLatest(
    typeActions.CONFIG_PASSWORD_ACCOUNT_REQUEST,
    configPasswordAccount
  );
  yield takeLatest(typeActions.DELETE_ACCOUNT_REQUEST, deleteAccount);
  yield takeLatest(typeActions.GET_ACCOUNT_BY_ID_REQUEST, getAccountById);
  yield takeLatest(
    typeActions.GET_ACCOUNT_GROUP_BY_ID_REQUEST,
    getGroupAccountById
  );
  yield takeLatest(typeActions.CREATE_ACCOUNT_REQUEST, createAccount);
  yield takeLatest(typeActions.UPDATE_ACCOUNT_REQUEST, updateAccount);
  yield takeLatest(
    typeActions.ACCOUNT_CHANGE_PASSWORD_REQUEST,
    accountChangePassword
  );
  //
  yield takeEvery(typeActions.GET_ACCOUNTS_EXTEND_REQUEST, getAccountsExtend);
  yield takeLatest(typeActions.GET_ACCOUNT_EXTEND_REQUEST, getAccountExtend);
  yield takeLatest(
    typeActions.CREATE_ACCOUNT_EXTEND_REQUEST,
    createAccountExtend
  );
  yield takeLatest(
    typeActions.UPDATE_ACCOUNT_EXTEND_REQUEST,
    updateAccountExtend
  );
  yield takeLatest(
    typeActions.DELETE_ACCOUNT_EXTEND_REQUEST,
    deleteAccountExtend
  );
  yield takeLatest(
    typeActions.UPDATE_CURRENT_ACCOUNT_REQUEST,
    updateCurrentAccount
  );
  yield takeLatest(
    typeActions.GET_CURRENT_ACCOUNT_EXTEND_REQUEST,
    getCurrentAccountExtend
  );
  yield takeLatest(
    typeActions.GET_ACCOUNT_EXTEND_BY_ORGANIZATION_REQUEST,
    getAccountExtendsByOrganization
  );
  yield takeLatest(typeActions.ACCOUNT_IMPORT_REQUEST, importAccount);
  yield takeLatest(
    typeActions.GET_RELATION_CHILDRNT_BY_ACCOUNT_EXTEND_REQUEST,
    getRelationChildrentByAccountExtend
  );
  yield takeLatest(
    typeActions.GET_ACCOUNT_EXTEND_BY_PARTNER_REQUEST,
    getAccountExtendsByPartner
  );
  yield takeLatest(
    typeActions.EXPORT_ACCOUNT_REQUEST,
    exportAccount
  );
}
