//core-componens
import React, { useEffect, useState } from 'react';
import { Button, Card, CardBody, CardHeader, Col, Container } from 'reactstrap';
import SimpleHeader from 'components/Headers/SimpleHeader.js';
//custom components
import CilinicalInfomation from './components/ReadCilinicalInfomation';
//module
import _ from 'lodash';
import { notificationAlertRef, notify } from 'common';
//styles
import 'assets/css/custom-pages/specify-votes.css';
import 'assets/css/custom-pages/create-specify-votes.css';
import { useDispatch, useSelector } from 'react-redux';
import { specifyVotesActions } from 'redux/actions';
import TestGroupInfomation from './components/ReadTestGroupInformation';
import GeneralInfomation from './components/ReadGeneralInfomation';
import { useHistory, useLocation, useParams } from 'react-router';
function SpecifyVotesView() {
  const history = useHistory();
  const param = useParams();
  const [specifyVotes, setSpecifyVotes] = useState(null);
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(
      specifyVotesActions.getSpecifyVoteById(param.id, 'populate=*', {
        success: (specifyvote) => {
          setSpecifyVotes(specifyvote);
        },
        failed: (message) => {
          notify(
            notificationAlertRef,
            'danger',
            'Thông báo',
            `Lỗi: ${message}`
          );
          history.push('/admin/specify-vote-all');
        },
      })
    );
  }, []);

  return (
    <>
      <SimpleHeader />
      <Container className="mt--9 custom-specify-votes" fluid>
        <GeneralInfomation specifyVotes={specifyVotes} />
        <CilinicalInfomation specifyVotes={specifyVotes} />
        <TestGroupInfomation specifyVotes={specifyVotes} />
      </Container>
    </>
  );
}

export default SpecifyVotesView;
