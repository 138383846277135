import typeActions from 'redux/typeActions';
const setValueMedicalRecord = ({ name, value }) => {
  return {
    type: typeActions.SET_MEDICALRECORD_VALUE,
    name,
    value,
  };
};
const getMedicalRecords = (query, callback, isUpdateReducer = true) => {
  return {
    type: typeActions.GET_MEDICALRECORD_REQUEST,
    query,
    callback,
    isUpdateReducer,
  };
};
const getMedicalRecordById = (param,query, callback) => {
  return {
    type: typeActions.GET_MEDICALRECORD_BY_ID_REQUEST,
    param,
    query,
    callback,
  };
};
const createMedicalRecord = (params, callback) => {
  return {
    type: typeActions.CREATE_MEDICALRECORD_REQUEST,
    params,
    callback,
  };
};
const updateMedicalRecord = (params, body, callback) => {
  return {
    type: typeActions.UPDATE_MEDICALRECORD_REQUEST,
    params,
    body,
    callback,
  };
};
const deleteMedicalRecord = (params, callback) => {
  return {
    type: typeActions.DELETE_MEDICALRECORD_REQUEST,
    params,
    callback,
  };
};
export default {
  setValueMedicalRecord,
  getMedicalRecords,
  deleteMedicalRecord,
  updateMedicalRecord,
  createMedicalRecord,
  getMedicalRecordById
};
