import _ from 'lodash';

export const fnValidate = (e, testValue) => {
  const err = {};
  const specimens = e.specimens;
  if (!_.isArray(specimens)) {
    err[`specimens`] = 'Mẫu xét nghiệm phải là một danh sách!';
  } else if (specimens.length == 0) {
    err[`specimens`] = 'Mẫu xét nghiệm không được để trống!';
  }
  return err;
};
