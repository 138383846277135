import React from 'react';
import _ from 'lodash';
import { Input } from 'reactstrap';

const RowTable = ({ item, idxR, handleResultChange, disable }) => {
  return (
    <tr
      style={{
        background:
          item?.value > item?.abnormal && item?.abnormal !== ''
            ? 'rgba(192, 6, 6, 0.1)'
            : 'transparent',
      }}
    >
      <td className="p-2">{item?.content ?? ''}</td>
      <td
        style={{ color: '#037FBE', fontWeight: '900' }}
        className="p-2 text-center"
      >
        {item?.value}
      </td>
      {disable ? (
        <td className="p-2">{item?.result}</td>
      ) : (
        <td className="p-2">
          {item?.normal !== '' && item?.abnormal !== '' && item?.suspect && (
            <Input
              type="text"
              className="height-input"
              value={item?.result}
              onChange={(e) => {
                handleResultChange(e.target.value, idxR);
              }}
              placeholder="Nhập"
            />
          )}
        </td>
      )}
    </tr>
  );
};

export default RowTable;
