// core componet
import React, { useEffect, useState } from 'react';
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory from 'react-bootstrap-table2-paginator';
import filterFactory from 'react-bootstrap-table2-filter';
import ToolkitProvider from 'react-bootstrap-table2-toolkit';
import _ from 'lodash';
import {
  Badge,
  Card,
  CardHeader,
  Col,
  Container,
  Input,
  Row,
  Spinner,
  UncontrolledTooltip,
} from 'reactstrap';
import FileDownload from 'js-file-download';

import {
  checkRole,
  getQueryByChildrentRelationAccountExtendSP,
  handleDownloadLink,
  notificationAlertRef,
  notify,
} from 'common';
// custom components
import SimpleHeader from 'components/Headers/SimpleHeader.js';
import ModalWarningCustom from 'views/pages/components/ModalWarningCustom';
import Loading from 'views/pages/components/Loading';
// css
import 'assets/css/custom-pages/react-bs-table.css';
import 'assets/css/custom-components/input-title-table.css';
// actions,hook - redux
import { useDispatch, useSelector } from 'react-redux';
import {
  specifyVotesActions,
  orderActions,
  organizationActions,
  additionalSampleActions,
  filterActions,
} from 'redux/actions';
// custom hook
import { useDelayInputEffect, useDidUpdateEffect } from 'utils/HookUtils';
// icon
import IconEdit from 'assets/svgs/edit.svg';
import IconBin from 'assets/svgs/bin.svg';
import IconBinDisable from 'assets/svgs/bin_disable.svg';
import IconEye from 'assets/svgs/eye.svg';
import IconPrint from 'assets/svgs/print.svg';
import IconDownloadEnable from 'assets/svgs/download_enable.svg';
import IconDownloadDisable from 'assets/svgs/download_disable.svg';
//other
import CONSTANT from 'constant';
import queryString from 'query-string';
import SpecifyVotesRead from './components/pages/CUSpecifyVotes';
import FilterSpecifyVote from './components/FilterSpecifyVote';
import { useHistory } from 'react-router';
import SelectCustom from '../components/SelectCustom';

const moment = require('moment');
function SpecifyVotes() {
  const history = useHistory();
  const {
    currentAccountExtend,
    isAdmin,
    childrentByCurrentAccountExtend,
    isHasChildrent,
    isDoctorKH,
  } = useSelector((state) => state.accountReducer);

  const { isGettingOrder, isPrintPkq } = useSelector(
    (state) => state.orderReducer
  );

  const additionalSampleReducer = useSelector(
    (state) => state.additionalSampleReducer
  );

  const [notificationModal, setNotificationModal] = useState(false);
  const [specifyvote, setSpecifyVote] = useState(null);
  const [searchTerm, setSearchTerm] = useState('');
  const [searchOption, setSearchOption] = useState('');
  const [isUpdateReducer, setIsUpdateReducer] = useState(true);
  const [organizationBSVal, setOrganizationBSVal] = useState([]);
  const [organizationBSSearch, setOrganizationBSSearch] = useState([]);
  const [organizationBSOptions, setOrganizationBSOptions] = useState([]);
  const [displayType, setDisplayType] = useState('list');
  const {
    page,
    pagination,
    search,
    limit,
    query,
    specifyvotes,
    isDeleteSpecifyVote,
    isPrintSpecifyVote,
  } = useSelector((state) => state.specifyvoteReducer);

  const dispatch = useDispatch();
  const nameTypeSearch = 'Tìm kiếm';
  const headerStyle = {
    textAlign: 'center',
  };
  function handleGetColorFromSpecifyVoteStatus() {
    let colors = [
      { value: -1, label: 'danger' },
      { value: 0, label: 'info' },
      { value: 1, label: 'danger' },
      { value: 2, label: 'success' },
      { value: 4, label: 'danger' },
      { value: 5, label: 'danger' },
      { value: 6, label: 'warning' },
      { value: 7, label: 'warning' },
    ];
    if (isDoctorKH) {
      colors = colors.map((color) => {
        if (color.value === 1) {
          // Nếu là bác sĩ khách hàng thì gộp trạng thái
          // Khởi tạo và đang xét nghiệm thành đã tiếp nhận mẫu
          return {
            ...color,
            label: 'info',
          };
        }
        return color;
      });
    }
    return colors;
  }
  function getLabelFromSpecifyVoteStatus(status) {
    if (isDoctorKH && [0, 1].includes(status)) {
      return 'Đã thu mẫu';
    }
    if (isDoctorKH && [2].includes(status)) {
      return 'Mẫu lỗi';
    }
    return (
      CONSTANT.SPECIFY_VOTE_STATUS.find((_) => _.value === status)?.label ?? ''
    );
  }
  const columns = [
    {
      dataField: 'codePCD',
      text: 'MÃ PHIẾU',
    },
    {
      dataField: 'nameBN',
      text: 'HỌ TÊN BỆNH NHÂN',
    },
    {
      dataField: 'dateOfBirthBN',
      text: 'NGÀY SINH',
      formatter: (cell, row) => {
        return row?.dateOfBirthBN ?? '';
      },
    },
    {
      dataField: 'phoneBN',
      text: 'SỐ ĐIỆN THOẠI',
    },
    {
      dataField: 'specifyvoteType',
      text: 'BÁC SĨ CHỈ ĐỊNH',
      formatter: (cell, row) => {
        return `${row?.lastNameBS ?? ''} ${row?.firstNameBS ?? ''}`;
      },
    },
    {
      dataField: 'nameTest',
      text: 'DỊCH VỤ XÉT NGHIỆM',
    },
    {
      dataField: 'createdAt',
      text: 'NGÀY CHỈ ĐỊNH',
      formatter: (cell, row) => {
        return (
          <div style={{ textAlign: 'left' }}>
            {moment(new Date(row.createdAt)).format('DD/MM/YYYY')}
          </div>
        );
      },
    },
    isDoctorKH
      ? {
          dataField: 'order.resultDate',
          text: 'NGÀY TRẢ KẾT QUẢ',
          formatter: (cell) => {
            return (
              <div style={{ textAlign: 'left' }}>
                {!!cell ? moment(cell).format('DD/MM/YYYY') : ''}
              </div>
            );
          },
        }
      : null,
    {
      dataField: 'specifyVoteStatus',
      text: 'TRẠNG THÁI KẾT QUẢ',
      headerStyle,
      formatter: (cell, row) => {
        return (
          <div className="d-flex justify-content-center custom-color-info ">
            <Badge
              color={
                handleGetColorFromSpecifyVoteStatus().find(
                  (_) => _.value === row.specifyVoteStatus
                )?.label ?? 'badge-default'
              }
            >
              {getLabelFromSpecifyVoteStatus(row.specifyVoteStatus)}
            </Badge>
          </div>
        );
      },
    },
    {
      dataField: 'actions',
      text: 'HÀNH ĐỘNG',
      formatter: boxActions,
      headerStyle: {
        textAlign: 'center',
      },
      headerClasses: 'fixed-column',
      classes: 'fixed-column',
    },
  ].filter((column) => column !== null);

  const handlePrintPKQ = (id, code, type = 'order') => {
    if (type === 'order') {
      if (id === -1) return;
      dispatch(
        orderActions.printPkq(id, {
          success: (data) => {
            FileDownload(
              data,
              `Phieu_ket_qua_${code}_${new Date().getTime()}.pdf`
            );
            notify(
              notificationAlertRef,
              'success',
              'Thông báo',
              `Tải xuống thành công!`
            );
          },
          failed: (mess) => {
            notify(
              notificationAlertRef,
              'danger',
              'Thông báo',
              `Tải xuống lỗi!`
            );
          },
        })
      );
    } else {
      dispatch(
        additionalSampleActions.printPkqAdditionalSample(id, {
          success: (data) => {
            var blobURL = URL.createObjectURL(data);
            window.open(blobURL);
          },
          failed: (mess) => {
            notify(
              notificationAlertRef,
              'danger',
              'Thông báo',
              `In phiếu kết quả lỗi!`
            );
          },
        })
      );
    }
  };

  const handleGetOrderBySpecifyVote = (specifyVoteId) => {
    const query = {
      'filters[$and][1][specifyVote][id][$eq]': specifyVoteId,
    };
    dispatch(
      orderActions.getOrderBy(
        queryString.stringify(query),
        {
          success: (data) => {
            handleGetAdditionalSampleByOrder(data.id);
            if (data?.orderType === 'appendix') {
              (data?.fileAppendixs ?? []).forEach((item) => {
                handleDownloadLink(item);
              });
            } else {
              handlePrintPKQ(data?.id ?? -1, 'order');
              handleDownloadLink(data.fileResult);
            }
          },
          failed: () => {},
        },
        false
      )
    );
  };

  const handleGetAdditionalSampleByOrder = (orderId) => {
    const query = {
      'filters[$and][1][order][id][$eq]': orderId,
    };
    dispatch(
      additionalSampleActions.getAdditionalSamples(
        queryString.stringify(query),
        {
          success: (data) => {
            data.results.forEach((result) => {
              if (result?.orderType === 'appendix') {
                (result?.fileAppendixs ?? []).forEach((item) => {
                  handleDownloadLink(item);
                });
              } else {
                handlePrintPKQ(result?.id ?? -1, 'additional-sample');
                handleDownloadLink(result?.fileResult);
              }
            });
          },
          failed: () => {},
        },
        false
      )
    );
  };

  function boxActions(__, row) {
    return (
      <div className="d-flex justify-content-center">
        <button
          id={'download' + row.id}
          onClick={() => {
            if (row.specifyVoteStatus === CONSTANT.ORDER_STATUS[4].value) {
              handleGetOrderBySpecifyVote(row.id);
            }
          }}
          className="btn p-0 btn-no-shadow mr-0"
        >
          <img
            src={
              row.specifyVoteStatus === CONSTANT.ORDER_STATUS[4].value &&
              !!row?.order?.id
                ? IconDownloadEnable
                : IconDownloadDisable
            }
            className="mr-1"
          />
        </button>
        <UncontrolledTooltip
          delay={1}
          placement="top"
          target={'download' + row.id}
        >
          Tải xuống phiếu kết quả
        </UncontrolledTooltip>
        <button
          id={'print' + row.id}
          onClick={() => {
            handlePrintPCD(row.id);
          }}
          className="btn p-0 btn-no-shadow mr-0"
        >
          <img src={IconPrint} className="mr-1" />
        </button>
        <UncontrolledTooltip
          delay={1}
          placement="top"
          target={'print' + row.id}
        >
          In phiếu xét nghiệm
        </UncontrolledTooltip>
        <button
          id={'view' + row.id}
          onClick={() => {
            history.push(
              `/admin/specify-vote-all/${row.id}?tab=specify-vote-all`
            );
          }}
          className="btn p-0 btn-no-shadow mr-0"
        >
          <img src={IconEye} className="mr-1" />
        </button>
        <UncontrolledTooltip delay={1} placement="top" target={'view' + row.id}>
          Xem chi tiết
        </UncontrolledTooltip>
        {checkRole(currentAccountExtend, {
          roles: ['update-specify-vote-all'],
        }) && (
          <>
            <button
              id={'edit' + row.id}
              onClick={() => {
                dispatch(
                  specifyVotesActions.getSpecifyVoteById(row.id, 'populate=*', {
                    success: (data) => {
                      history.push(
                        `/admin/specify-votes-update/${row.id}?tab=specify-vote-all`
                      );
                    },
                    failed: (err) => {},
                  })
                );
              }}
              className="btn p-0 btn-no-shadow mr-0"
            >
              <img src={IconEdit} className="mr-1" />
            </button>
            <UncontrolledTooltip
              delay={1}
              placement="top"
              target={'edit' + row.id}
            >
              Cập nhật phiếu xét nghiệm
            </UncontrolledTooltip>
          </>
        )}
        {checkRole(currentAccountExtend, {
          roles: ['delete-specify-vote-all'],
        }) && (
          <>
            <button
              id={'lock' + row.id}
              onClick={() => {
                if (
                  row.specifyVoteStatus !== CONSTANT.ORDER_STATUS[2].value &&
                  !row?.order
                ) {
                  setSpecifyVote(row);
                  setNotificationModal(true);
                }
              }}
              className="btn p-0 btn-no-shadow mr-0"
            >
              <img
                src={
                  row.specifyVoteStatus !== CONSTANT.ORDER_STATUS[2].value &&
                  !row?.order
                    ? IconBin
                    : IconBinDisable
                }
              />
            </button>
            <UncontrolledTooltip
              delay={1}
              placement="top"
              target={'lock' + row.id}
            >
              Xóa phiếu xét nghiệm
            </UncontrolledTooltip>
          </>
        )}
      </div>
    );
  }

  const onSizePerPageChange = (e) => {
    setIsUpdateReducer(true);
    dispatch(
      specifyVotesActions.setValueSpecifyVote({ name: 'page', value: 1 })
    );
    dispatch(
      specifyVotesActions.setValueSpecifyVote({
        name: 'limit',
        value: e.target.value,
      })
    );
  };

  const onPageChange = (value) => {
    setIsUpdateReducer(true);
    dispatch(
      specifyVotesActions.setValueSpecifyVote({ name: 'page', value: value })
    );
  };
  const paginations = paginationFactory({
    page: page,
    sizePerPage: limit,
    totalSize: pagination?.total,
    showTotal: false,
    withFirstAndLast: false,
    alwaysShowAllBtns: true,
    onPageChange: onPageChange,
    sizePerPageRenderer: () => {
      return (
        <div className="dataTables_length" id="datatable-basic_length">
          <label>
            Hiển thị{' '}
            {
              <select
                name="datatable-basic_length"
                aria-controls="datatable-basic"
                className="form-control form-control-sm"
                onChange={onSizePerPageChange}
              >
                <option value="10">10</option>
                <option value="25">25</option>
                <option value="50">50</option>
                <option value="100">100</option>
              </select>
            }{' '}
            dòng.
          </label>
        </div>
      );
    },
  });

  const handleDelete = () => {
    if (specifyvote === null) return;
    //Chưa nối đơn hàng mới được phép xóa
    if (
      specifyvote.specifyVoteStatus !== CONSTANT.ORDER_STATUS[2].value &&
      !specifyvote?.order
    )
      dispatch(
        specifyVotesActions.deleteSpecifyVote(specifyvote.id, {
          success: () => {
            setNotificationModal(false);
            notify(
              notificationAlertRef,
              'success',
              'Thông báo',
              `Xóa phiếu xét nghiệm ${specifyvote?.codePCD} thành công!`
            );
            callListSpecifyVote();
          },
          failed: () => {
            notify(
              notificationAlertRef,
              'danger',
              'Thông báo',
              `Xóa phiếu xét nghiệm ${specifyvote.codePCD} thất bại!`
            );
          },
        })
      );
  };
  /**
   *  - Get the list of specifyvotes on the first render
   *  - Call api when pagination or search values change
   */
  function callListSpecifyVote() {
    const otherQuery = {};
    if (!isAdmin && isHasChildrent) {
      getQueryByChildrentRelationAccountExtendSP(
        otherQuery,
        childrentByCurrentAccountExtend
      );
    }
    const q = {
      ...query,
      page: page,
      pageSize: limit,
      _q: search,
      'filters[$or][7][idBS][$eq]': [4, 5].includes(
        currentAccountExtend?.accountType
      )
        ? currentAccountExtend?.id ?? ''
        : '',
      'filters[$and][8][nameTC][$containsi]': _.get(
        organizationBSVal,
        'value',
        ''
      ),
      ...otherQuery,
    };
    dispatch(
      filterActions.setValueFilter({
        name: 'queryPage',
        value: q,
      })
    );
    dispatch(
      specifyVotesActions.getSpecifyVotes(
        queryString.stringify(q, {
          skipEmptyString: true,
          skipNull: true,
        }),
        {
          success: (data) => {
            // if (!isUpdateReducer) {
            // }
          },
          failed: () => {},
        },
        isUpdateReducer
      )
    );
  }
  useEffect(() => {
    // if (_.isEmpty(specifyvotes)) {
    //   callListSpecifyVote();
    // }
    handleGetOrganizationByAccountBS();
  }, []);
  useEffect(() => {
    callListSpecifyVote();
  }, [organizationBSVal, isHasChildrent]);
  useEffect(() => {
    if (_.get(childrentByCurrentAccountExtend, 'length', 0) > 0)
      callListSpecifyVote();
  }, [childrentByCurrentAccountExtend]);
  useDidUpdateEffect(() => {
    const otherQuery = {};
    if (!isAdmin && isHasChildrent) {
      getQueryByChildrentRelationAccountExtendSP(
        otherQuery,
        childrentByCurrentAccountExtend
      );
    }

    const q = {
      ...query,
      page: page,
      pageSize: limit,
      _q: search,
      'filters[$or][7][idBS][$eq]': [4, 5].includes(
        currentAccountExtend?.accountType
      )
        ? currentAccountExtend?.id ?? ''
        : '',
      'filters[$and][8][nameTC][$containsi]': _.get(
        organizationBSVal,
        'value',
        ''
      ),
      ...otherQuery,
    };

    dispatch(
      filterActions.setValueFilter({
        name: 'queryPage',
        value: q,
      })
    );
    dispatch(
      specifyVotesActions.getSpecifyVotes(
        queryString.stringify(q, {
          skipEmptyString: true,
          skipNull: true,
        }),
        {
          success: (dataSpecifyVote) => {},
          failed: () => {},
        },
        isUpdateReducer
      )
    );
  }, [page, limit, search, query, isHasChildrent]);
  /**
   * Handle the search when the user finishes typing after 500 milliseconds
   */
  useDelayInputEffect(
    specifyVotesActions.setValueSpecifyVote({
      name: 'search',
      value: searchTerm,
    }),
    searchTerm,
    700
  );
  useDelayInputEffect(
    specifyVotesActions.setValueSpecifyVote({
      name: 'search',
      value: searchOption,
    }),
    searchOption,
    700
  );

  const handlePrintPCD = (id) => {
    dispatch(
      specifyVotesActions.printSpecifyVote(id ?? -1, {
        success: (data) => {
          var blobURL = URL.createObjectURL(data);
          window.open(blobURL);
        },
        failed: (mess) => {
          notify(
            notificationAlertRef,
            'danger',
            'Thông báo',
            `In phiếu xét nghiệm lỗi!`
          );
        },
      })
    );
  };

  //Xử lý lọc phòng khám theo bác sĩ đang đăng nhập
  useEffect(() => {
    const debounce = setTimeout(() => {
      handleGetOrganizationByAccountBS();
    }, 700);
    return () => clearTimeout(debounce);
  }, [organizationBSSearch]);
  const handleGetOrganizationByAccountBS = () => {
    dispatch(
      organizationActions.getOrganizationsByAccountBS(
        _.get(currentAccountExtend, 'id', ''),
        queryString.stringify(
          {
            'filters[$and][3][name][$containsi]': organizationBSSearch,
          },
          {
            skipEmptyString: true,
            skipNull: true,
          }
        ),
        {
          success: (data) => {
            if (_.isArray(data)) {
              setOrganizationBSOptions(
                data.map((item) => {
                  return {
                    label: _.get(item, 'name', ''),
                    value: _.get(item, 'name', ''),
                  };
                })
              );
            }
          },
          failed: (error) => {},
        }
      )
    );
  };
  useEffect(() => {}, [organizationBSVal]);
  if (displayType === 'read')
    return (
      <SpecifyVotesRead
        setDisplayType={(str) => {
          SpecifyVotesRead(str);
        }}
      />
    );
  return (
    <>
      <SimpleHeader />
      <FilterSpecifyVote />
      <ModalWarningCustom
        notificationModal={notificationModal}
        setNotificationModal={setNotificationModal}
        name={`Phiếu xét nghiệm ${specifyvote?.codePCD ?? ''}`}
        func={handleDelete}
        isDelete={isDeleteSpecifyVote}
      />

      <Container className="mt--9" fluid>
        <Row>
          <Col xs={12}>
            <Card className="position-relative">
              <CardHeader className="pb-2">
                <h3>Danh sách</h3>
              </CardHeader>
              <ToolkitProvider
                data={_.isEmpty(specifyvotes) ? [] : specifyvotes}
                keyField="id"
                columns={columns}
                search={{
                  searchFormatted: true,
                }}
              >
                {(props) => (
                  <div className="pb-4 table-responsive">
                    <div className="d-flex ml-4 my-3 justify-content-between">
                      <div className="d-flex align-items-center">
                        <h5 style={{ fontWeight: 400, marginBottom: 0 }}>
                          Hiển thị từ {(page - 1) * limit + 1} đến{' '}
                          {specifyvotes?.length > page * limit
                            ? specifyvotes.length
                            : page * limit > pagination.total
                            ? pagination.total
                            : page * limit}{' '}
                          trong số {pagination?.total} bản ghi <br />
                        </h5>
                      </div>
                      <div
                        id="datatable-basic_filter"
                        className="dataTables_filter px-4 pb-1 d-flex align-items-center"
                      >
                        <h4 className="m-0 p-0">{nameTypeSearch}</h4>
                        <Input
                          type="text"
                          className="height-input"
                          placeholder="Nhập từ cần tìm"
                          onChange={(e) => {
                            if (searchTerm !== e) {
                              setIsUpdateReducer(true);
                              setSearchTerm(e.target.value);
                            }
                          }}
                        />
                        &nbsp;
                        {_.get(currentAccountExtend, 'accountType', -1) ===
                          CONSTANT.ACCOUNT_TYPE[5].value && (
                          <div
                            style={{
                              width: 200,
                            }}
                          >
                            <SelectCustom
                              options={[
                                ...organizationBSOptions,
                                {
                                  label: 'Tất cả',
                                  value: null,
                                },
                              ]}
                              placeholder="Chọn phòng khám"
                              value={organizationBSVal}
                              onInputChange={(e) => {
                                setOrganizationBSSearch(e);
                              }}
                              handleChange={(e) => {
                                setOrganizationBSVal(e);
                              }}
                              stylesSelect={{
                                menuPortal: (baseStyles, state) => ({
                                  ...baseStyles,
                                  zIndex: 9999,
                                }),
                              }}
                              menuPortalTarget={document.body}
                            />
                          </div>
                        )}
                      </div>
                    </div>

                    <BootstrapTable
                      {...props.baseProps}
                      noDataIndication={() => {
                        return (
                          <span className="font-weight-bold text-danger">
                            Không có dữ liệu!
                          </span>
                        );
                      }}
                      onTableChange={() => {
                        return <Loading />;
                      }}
                      remote
                      hover
                      bootstrap4={true}
                      pagination={paginations}
                      bordered={false}
                      filter={filterFactory()}
                      rowStyle={{
                        cursor: 'pointer',
                      }}
                      keyField="id"
                    />
                  </div>
                )}
              </ToolkitProvider>
              {(isPrintSpecifyVote ||
                isPrintPkq ||
                isGettingOrder ||
                additionalSampleReducer.isPrintPkq) && (
                <div
                  className="position-absolute d-flex w-100 justify-content-center rounded"
                  style={{
                    top: 0,
                    height: '100%',
                    background: 'rgba(0,0,0,.1)',
                    zIndex: 999,
                  }}
                >
                  <Spinner className="mt-8" color="info">
                    Loading...
                  </Spinner>
                </div>
              )}
            </Card>
          </Col>
        </Row>
      </Container>
    </>
  );
}

export default SpecifyVotes;
