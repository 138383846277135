import React from 'react';
import PropTypes from 'prop-types';
import {
  Breadcrumb,
  BreadcrumbItem,
  Button,
  Container,
  Row,
  Col,
} from 'reactstrap';

import 'assets/css/custom-pages/timelineheader.css';
function TimelineHeader({ name, parentName }) {
  return (
    <>
      <div className="header header-dark pt-5 content__title content__title--calendar ">
        <Container fluid>
          {/* <div className="header-body">
            <Row className="align-items-center pb-4">
              <Col lg="6" xs="7">
                <h2 className="fullcalendar-title h2 text-white d-inline-block mb-0 align-top text-nowrap">
                  {name}
                  Quản lý tài khoản người dùng
                </h2>{" "}
                <Breadcrumb
                  className="d-none d-md-inline-block ml-lg-4"
                  listClassName="breadcrumb-links breadcrumb-dark"
                >
                  <BreadcrumbItem>
                    <a href="#pablo" onClick={(e) => e.preventDefault()}>
                      <i className="fas fa-home" />
                    </a>
                  </BreadcrumbItem>
                  <BreadcrumbItem>
                    <a href="#pablo" onClick={(e) => e.preventDefault()}>
                      {parentName}
                    </a>
                  </BreadcrumbItem>
                  <BreadcrumbItem aria-current="page" className="active">
                    {name}
                  </BreadcrumbItem>
                </Breadcrumb>
              </Col>
            </Row>
          </div> */}
        </Container>
      </div>
    </>
  );
}

TimelineHeader.propTypes = {
  name: PropTypes.string,
  parentName: PropTypes.string,
};

export default TimelineHeader;
