import _ from 'lodash';
import { all, call, put, takeLatest } from 'redux-saga/effects';

import typeActions from 'redux/typeActions';
import ServiceURL from 'services/ServiceURL';
import { DELETE, GET, GET_FILE, POST, PUT } from 'services/ServiceBase';

/**
 *
 * @param {*} data
 * get list templatepcds:
 * - failed -> call actions failed
 * - success ->
 *      + if (search in select-options) => return;
 *      + if (search in list templatepcds) => call actions sucess
 */
export function* getTemplatePCDs(data) {
  const url = `${ServiceURL.templatepcd}?${data.query}&sort[0]=createdAt:DESC`;
  const callback = data.callback;

  try {
    const res = yield call(GET, url);
    if (!!res.data?.error?.message) {
      yield put({
        type: typeActions.GET_TEMPLATE_PCDS_FAILED,
        error: res.data?.error?.message,
      });
      !!callback?.failed && callback.failed(res.data.error.message);
    } else {
      if (data.isUpdateReducer) {
        yield put({
          type: typeActions.GET_TEMPLATE_PCDS_SUCCESS,
          data: res.data,
        });
      }
      !!callback?.success && callback.success(res.data);
    }
  } catch (error) {
    yield put({
      type: typeActions.GET_TEMPLATE_PCDS_FAILED,
      error: error?.response?.data?.error?.message ?? '',
    });
    !!callback?.failed &&
      callback.failed(error?.response?.data?.error?.message ?? '');
  }
}

export function* updatedTemplatePCD(data) {
  const url = `${ServiceURL.templatepcd}/${data.param}`;
  const callback = data.callback;
  try {
    const res = yield call(PUT, url, data.body);
    if (!!res.data?.error?.message) {
      yield put({
        type: typeActions.UPDATE_TEMPLATE_PCD_FAILED,
        error: res.data.error.message,
      });
      !!callback?.failed && callback.failed(res.data.error.message);
    } else {
      yield put({
        type: typeActions.UPDATE_TEMPLATE_PCD_SUCCESS,
        data: res.data,
      });

      !!callback?.success && callback.success(res.data);
    }
  } catch (error) {
    yield put({
      type: typeActions.UPDATE_TEMPLATE_PCD_FAILED,
      error: error?.response?.data?.error?.message ?? '',
    });
    !!callback?.failed &&
      callback.failed(error?.response?.data?.error?.message ?? '');
  }
}
export default function* templatepcdsSaga() {
  yield takeLatest(typeActions.GET_TEMPLATE_PCDS_REQUEST, getTemplatePCDs);
  yield takeLatest(typeActions.UPDATE_TEMPLATE_PCD_REQUEST, updatedTemplatePCD);
}
