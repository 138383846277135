import React from 'react';
import { Card, CardBody, CardHeader, Col, Row } from 'reactstrap';

import CONSTANT from 'constant';
import LabelValue from 'views/pages/components/LabelValue';

function ReadCilinicalInfomation({ specifyVotes }) {
  const medicationsUsingValue = CONSTANT.MEDICATIONS_USING.filter((item) =>
    (specifyVotes?.medicationsUsing ?? []).includes(item.value)
  );
  return (
    <Card className="custom-row">
      <CardHeader>
        <h3 className="mb-0">Thông tin lâm sàng</h3>
      </CardHeader>
      <CardBody>
        <Row>
          <Col xs={3}>
            <Row className="mb-0">
              <Col xs={6}>
                <LabelValue
                  lableTextAlign={'right'}
                  label={'Chiều cao (cm)'}
                  value={specifyVotes?.height ?? ''}
                  row={true}
                />
              </Col>
              <Col xs={6}>
                <LabelValue
                  lableTextAlign={'right'}
                  label={'Cân nặng (kg)'}
                  value={specifyVotes?.weight ?? ''}
                  row={true}
                />
              </Col>
            </Row>
          </Col>
          <Col xs={3}>
            <LabelValue
              lableTextAlign={'right'}
              label={'Tiền sử bệnh nhân'}
              value={specifyVotes?.anamnesisBA ?? ''}
              row={true}
              labelWidth={140}
            />
          </Col>
          <Col xs={3}>
            <LabelValue
              lableTextAlign={'right'}
              label={'Tiền sử gia đình'}
              value={specifyVotes?.familyHistory ?? ''}
              labelWidth={140}
              row={true}
            />
          </Col>
        </Row>
        <Row>
          <Col xs={3}>
            <LabelValue
              lableTextAlign={'right'}
              label={'Tiếp xúc độc hại'}
              value={specifyVotes?.lifeStyle ?? ''}
              row={true}
              labelWidth={70}
            />
          </Col>
          <Col xs={3}>
            <LabelValue
              lableTextAlign={'right'}
              label={'Bệnh lý cấp tính'}
              value={specifyVotes?.pathological1 ?? ''}
              row={true}
              labelWidth={120}
            />
          </Col>
          <Col xs={3}>
            <LabelValue
              lableTextAlign={'right'}
              label={'Bệnh lý mãn tính'}
              value={specifyVotes?.pathological2 ?? ''}
              labelWidth={120}
              row={true}
            />
          </Col>
        </Row>
        <Row>
          <Col xs={3}>
            <LabelValue
              lableTextAlign={'right'}
              label={'Tiền sử bệnh'}
              value={specifyVotes?.anamnesis ?? ''}
              labelWidth={100}
              row={true}
            />
          </Col>
          <Col xs={4}>
            <LabelValue
              labelWidth={160}
              label="Thuốc đang dùng"
              value={medicationsUsingValue.map((item, index) => {
                return index === medicationsUsingValue.length - 1
                  ? item?.label ?? ''
                  : `${item?.label ?? ''} - `;
              })}
            />
            {/* <LabelValue
              lableTextAlign={"right"}
              label={"Thời gian bệnh"}
              value={specifyVotes?.time ?? ""}
              labelWidth={80}
              row={true}
            /> */}
          </Col>
        </Row>
        <Row className="mb-3">
          <Col xs={12}>
            <h3
              style={{
                fontSize: '17px',
                fontSize: '14px',
                fontWeight: 400,
                lineHeight: '19px',
                textTransform: 'uppercase',
              }}
            >
              Kết quả xét nghiệm di truyền
            </h3>
          </Col>
        </Row>
        <Row>
          <Col xs={6}>
            <LabelValue
              lableTextAlign={'right'}
              label={'Bản thân'}
              value={specifyVotes?.result1 ?? ''}
              row={true}
              labelWidth={100}
            />
          </Col>
          <Col xs={6}>
            <LabelValue
              lableTextAlign={'right'}
              label={'Người thân'}
              value={specifyVotes?.result2 ?? ''}
              labelWidth={80}
              row={true}
            />
          </Col>
        </Row>
      </CardBody>
    </Card>
  );
}
export default ReadCilinicalInfomation;
