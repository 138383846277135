import React, { memo, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Button, Col, Form, Modal, Row } from 'reactstrap';
import * as yup from 'yup';
import { Formik } from 'formik';
import _ from 'lodash';
import queryString from 'query-string';

import { checkObjectChange, notificationAlertRef, notify } from 'common';
import { patientActions, formActions } from 'redux/actions';
import LoadingButtonCustom from 'views/pages/components/LoadingButtonCustom';
import CONSTANT from 'constant';
import InputCustom from 'views/pages/components/InputCustom';
import SelectCustom from 'views/pages/components/SelectCustom';
import DatePickerCustom from 'views/pages/components/DatePickerCustom';

const CUPatient = ({ handleGetPatientsIndex }) => {
  const dispatch = useDispatch();

  // reducers
  const { isModalVisible, modalInfo } = useSelector(
    (state) => state.formReducer
  );
  const { isCreatingService, isUpdatingService } = useSelector(
    (state) => state.serviceReducer
  );
  const [patients, setPatients] = useState([]);

  const isUpdate = !_.isEmpty(modalInfo) && !modalInfo?.isAddAction;

  // Khởi tạo dữ liêu
  const initInfo = {
    code: modalInfo?.code ?? '',
    name: modalInfo?.name ?? '',
    dateOfBirth: modalInfo?.dateOfBirth ?? '',
    phone: modalInfo?.phoneContact ?? '',
    phoneContact: modalInfo?.phoneContact ?? '',
    nameContact: modalInfo?.nameContact ?? '',
    gender: modalInfo?.gender ?? '',
    address: modalInfo?.address ?? '',
    email: modalInfo?.email ?? '',
    phone: modalInfo?.phone ?? '',
    job: modalInfo?.job ?? '',
    height: modalInfo?.medicalRecord?.height ?? '',
    weight: modalInfo?.medicalRecord?.weight ?? '',
  };
  const [patientValue, setPatientValue] = useState(null);

  const [patientSearch, setPatientSearch] = useState('');

  // validate dữ liêu
  const infoSchema = yup.object().shape({
    code: yup
      .string()
      .required('Số điện thoại bệnh nhân không được để trống!')
      .length(10, 'Số điện thoại bệnh nhân phải là số điện thoại gồm 10 số!')
      .matches(
        '0[1-9][0-9]{8}',
        'Số điện thoại bệnh nhân phải là số điện thoại!'
      ),
    name: yup
      .string()
      .test(
        'empty-check',
        'Họ tên bệnh nhân không được để trống!',
        (name) => name && !!name.trim()
      )
      .required('Họ tên bệnh nhân không được để trống!'),
    dateOfBirth: yup.string().required('Ngày sinh không được để trống!'),
    gender: yup
      .string()
      .required('Giới tính không được để trống!')
      .nullable(true),
    email: yup
      .string()
      .required('Email không được để trống!')
      .email('Email không đúng định dạng!')
      .nullable(true),
    // phone: yup
    //   .string()
    //   .required("Số điện thoại bệnh nhân không được để trống!")
    //   .length(10, "Số điện thoại bao gồm 10 số!")
    //   .matches("0[1-9][0-9]{8}", "Vui lòng nhập đúng định dạng số điện thoại!"),
    nameContact: yup
      .string()
      .required('Họ tên người liên hệ không được để trống!'),
    phoneContact: yup
      .string()
      .required('Số điện thoại người liên hệ không được để trống!')
      .length(10, 'Số điện thoại bao gồm 10 số!')
      .matches('0[1-9][0-9]{8}', 'Vui lòng nhập đúng định dạng số điện thoại!'),
    // height: yup
    //   .string()
    //   .required("Chiều cao bệnh nhân không được để trống!")
    //   .test("number-check", "Chiều cao phải là số nguyên dương!", (height) => {
    //     return isNumeric(height) && parseInt(height) > 0;
    //   }),
    // weight: yup
    //   .string()
    //   .required("Cân nặng bệnh nhân không được để trống!")
    //   .test("number-check", "Cân nặng phải là số nguyên dương!", (height) => {
    //     return isNumeric(height) && parseInt(height) > 0;
    //   }),
    // descriptions: yup.string().required('Mô tả bệnh nhân không được để trống!'),
  });

  // Xử lý submit
  const onSubmit = (values) => {
    if (isUpdate) {
      Object.keys(values).forEach((key) => {
        if (!values[key]) {
          switch (key) {
            case 'gender':
              values[`${key}`] = -1;
              break;
            default:
              values[`${key}`] = '';
          }
        }
      });

      if (!checkObjectChange(initInfo, values))
        dispatch(
          patientActions.updatePatient(modalInfo?.id ?? '', values, {
            success: () => {
              notify(
                notificationAlertRef,
                'success',
                'Thông báo!',
                `Cập nhật thông tin bệnh nhân thành công!`
              );
              dispatch(
                formActions.setValue({
                  name: 'isModalVisible',
                  value: false,
                })
              );
              dispatch(
                patientActions.getPatients(
                  queryString.stringify({
                    populate: '*',
                  }),
                  {
                    failed: (err) => {
                      notify(
                        notificationAlertRef,
                        'danger',
                        'Thông báo!',
                        `Cập nhật danh sách bệnh nhân thất bại! Lỗi: ${err} `
                      );
                    },
                  },
                  true
                )
              );
            },
            failed: (err) => {
              notify(
                notificationAlertRef,
                'danger',
                'Thông báo!',
                `Cập nhật thông tin bệnh nhân thất bại! Lỗi: ${err} `
              );
            },
          })
        );
      else
        dispatch(
          formActions.setValue({
            name: 'isModalVisible',
            value: false,
          })
        );
    }
  };
  // Xử lý đóng form
  const handleClose = () => {
    dispatch(
      formActions.setValue({
        name: 'isModalVisible',
        value: false,
      })
    );
    dispatch(
      formActions.setValue({
        name: 'modalInfo',
        value: {},
      })
    );
  };

  // Lấy danh sách service patients
  const handleGetPatients = () => {
    const query = {};
    if (patientSearch !== '') {
      query['filters[$and][0][code][$containsi]'] = patientSearch;
    }
    dispatch(
      patientActions.getPatients(
        queryString.stringify(query),
        {
          success: (data) => {
            const results = _.get(data, 'results', []);
            setPatients(results);
          },
        },
        false
      )
    );
  };

  useEffect(() => {
    // Sau 500ms thì bắn api
    const delayDebounceFn = setTimeout(() => {
      handleGetPatients();
    }, 500);
    return () => clearTimeout(delayDebounceFn);
  }, [patientSearch, modalInfo]);

  useEffect(() => {
    if (isUpdate || modalInfo?.isAddAction) {
      setPatientValue(
        !!modalInfo?.patient
          ? {
              label: `${modalInfo.patient?.name ?? ''}(${
                modalInfo.patient?.code ?? ''
              })`,
              value: modalInfo.patient?.id ?? -1,
            }
          : null
      );
    } else {
      setPatientValue(null);
    }
  }, [isUpdate, modalInfo]);

  return (
    <Modal
      size={'md'}
      isOpen={isModalVisible}
      className="modal-dialog-centered modal-secondary"
      toggle={() => {
        handleClose();
      }}
    >
      <div className="modal-header border-bottom bg-white my-0 mt-1 py-2">
        <h2 className="mb-0">{isUpdate ? 'Cập nhật' : 'Thêm mới'} bệnh nhân</h2>
        <button
          aria-label="Close"
          className="close"
          data-dismiss="modal"
          type="button"
          onClick={() => {
            handleClose();
          }}
        >
          <span style={{ color: '#262626' }} aria-hidden={true}>
            ×
          </span>
        </button>
      </div>
      <div className="modal-body bg-white rounded">
        <Formik
          initialValues={initInfo}
          enableReinitialize
          validateOnChange={false}
          validateOnBlur={false}
          onSubmit={onSubmit}
          validationSchema={infoSchema}
        >
          {({
            values,
            handleSubmit,
            errors,
            touched,
            handleBlur,
            handleChange,
            setFieldValue,
          }) => {
            return (
              <Form onSubmit={handleSubmit} className="">
                <Row>
                  <Col xs={12}>
                    <InputCustom
                      name="code"
                      label="Mã bệnh nhân"
                      type="text"
                      placeholder="Nhập mã bệnh nhân"
                      value={values.code}
                      disabled={true}
                      isRequired={true}
                      handleChange={handleChange}
                      handleBlur={handleBlur}
                      error={errors?.code}
                      touched={touched?.code}
                    />
                  </Col>
                </Row>
                <Row>
                  <Col xs={12}>
                    <InputCustom
                      name="name"
                      label="Họ tên"
                      type="text"
                      placeholder="Nhập tên bệnh nhân"
                      value={values.name}
                      isRequired={true}
                      handleChange={handleChange}
                      handleBlur={handleBlur}
                      error={errors?.name}
                      touched={touched?.name}
                    />
                  </Col>
                </Row>
                <Row>
                  <Col xs={6}>
                    <InputCustom
                      name="dateOfBirth"
                      label="Ngày sinh"
                      type="text"
                      isRequired
                      placeholder="DD/MM/YYYY"
                      value={values.dateOfBirth}
                      handleChange={handleChange}
                      handleBlur={handleBlur}
                      error={errors.dateOfBirth}
                      touched={touched.dateOfBirth}
                    />
                  </Col>
                  <Col xs={6}>
                    <SelectCustom
                      name="gender"
                      label="Giới tính"
                      isRequired={true}
                      placeholder="Lựa chọn"
                      value={_.find(CONSTANT.ACCOUNT_GENDER, {
                        value: values.gender,
                      })}
                      isClearable={true}
                      handleChange={(e) => {
                        setFieldValue('gender', e?.value ?? null);
                      }}
                      handleBlur={handleBlur}
                      error={errors.gender}
                      touched={touched.gender}
                      options={CONSTANT.ACCOUNT_GENDER}
                    />
                  </Col>
                </Row>
                <Row>
                  <Col xs={12}>
                    <InputCustom
                      id="email"
                      name="email"
                      label="Email"
                      isRequired={true}
                      placeholder="Nhập email bệnh nhân"
                      value={values.email}
                      handleChange={(e) => {
                        setFieldValue('email', e?.target?.value ?? null);
                      }}
                      handleBlur={handleBlur}
                      error={errors.email}
                      touched={touched.email}
                    />
                  </Col>
                  {/* <Col xs={6}>
                    <InputCustom
                      id="phone"
                      name="phone"
                      label="Số điện thoại"
                      placeholder="Nhập số điện thoại bệnh nhân"
                      value={values.phone}
                      handleChange={(e) => {
                        setFieldValue("phone", e?.target?.value ?? null);
                      }}
                      handleBlur={handleBlur}
                      error={errors.phone}
                      touched={touched.phone}
                    />
                  </Col> */}
                </Row>
                <Row>
                  <Col xs={12}>
                    <InputCustom
                      id="job"
                      name="job"
                      label="Nhập nghề nghiệp"
                      type="text"
                      placeholder="Nhập nghề nghiệp"
                      value={values.job}
                      handleChange={(e) => {
                        setFieldValue('job', e?.target?.value ?? null);
                      }}
                      handleBlur={handleBlur}
                      error={errors?.job}
                      touched={touched?.job}
                    />
                  </Col>
                </Row>
                <Row>
                  <Col xs={12}>
                    <InputCustom
                      id="nameContact"
                      name="nameContact"
                      label="Người liên hệ"
                      type="text"
                      placeholder="Nhập họ tên người liên hệ"
                      value={values.nameContact}
                      isRequired={true}
                      handleChange={(e) => {
                        setFieldValue('nameContact', e?.target?.value ?? null);
                      }}
                      handleBlur={handleBlur}
                      error={errors?.nameContact}
                      touched={touched?.nameContact}
                    />
                  </Col>
                </Row>
                <Row>
                  <Col xs={12}>
                    <InputCustom
                      id="phoneContact"
                      name="name"
                      label="Số điện thoại"
                      type="text"
                      placeholder="Nhập số điện thoại người liên hệ"
                      value={values.phoneContact}
                      isRequired={true}
                      handleChange={(e) => {
                        setFieldValue('phoneContact', e?.target?.value ?? null);
                      }}
                      handleBlur={handleBlur}
                      error={errors?.phoneContact}
                      touched={touched?.phoneContact}
                    />
                  </Col>
                </Row>
                <Row>
                  <Col xs={12}>
                    <InputCustom
                      id="address"
                      name="address"
                      label="Địa chỉ bệnh nhân"
                      type="textarea"
                      placeholder="Nhập địa chỉ"
                      value={values.address}
                      handleChange={(e) => {
                        setFieldValue('address', e?.target?.value ?? null);
                      }}
                      handleBlur={handleBlur}
                      error={errors?.address}
                      touched={touched?.address}
                    />
                  </Col>
                </Row>
                {/* <Row>
                  <Col xs={6}>
                    <InputCustom
                      id="height"
                      name="height"
                      label="Chiều cao (cm)"
                      type="number"
                      placeholder="Nhập chiều cao"
                      value={values.height}
                      isRequired={true}
                      handleChange={(e) => {
                        setFieldValue("height", e?.target?.value ?? null);
                      }}
                      handleBlur={handleBlur}
                      error={errors?.height}
                      touched={touched?.height}
                    />
                  </Col>
                  <Col xs={6}>
                    <InputCustom
                      id="weight"
                      name="weight"
                      label="Cân nặng (kg)"
                      type="number"
                      placeholder="Nhập cân nặng"
                      value={values.weight}
                      isRequired={true}
                      handleChange={(e) => {
                        setFieldValue("weight", e?.target?.value ?? null);
                      }}
                      handleBlur={handleBlur}
                      error={errors?.weight}
                      touched={touched?.weight}
                    />
                  </Col>
                </Row> */}
                <div className="mb-0 pb-0 mt-4 d-flex justify-content-end">
                  <Button
                    className="text-nowrap mr-1"
                    onClick={() => {
                      handleClose();
                    }}
                  >
                    Hủy
                  </Button>
                  <LoadingButtonCustom
                    onClick={handleSubmit}
                    color="info"
                    className="text-nowrap "
                    type="submit"
                    loading={isCreatingService || isUpdatingService}
                  >
                    {isUpdate ? 'Lưu lại' : 'Thêm mới'}
                  </LoadingButtonCustom>
                </div>
              </Form>
            );
          }}
        </Formik>
      </div>
    </Modal>
  );
};

export default memo(CUPatient);
