import _ from 'lodash';
import { takeLatest, call, put, all } from 'redux-saga/effects';

import typeActions from 'redux/typeActions';
import ServiceURL from 'services/ServiceURL';
import { DELETE, GET, POST, PUT } from 'services/ServiceBase';

export function* getStatistic(data) {
  const url = `${ServiceURL.statistic}?${data.query}`;
  const callback = data.callback;
  try {
    const res = yield call(GET, url);
    if (!!res.data?.error?.message) {
      yield put({
        type: typeActions.GET_STATISTIC_FAILED,
        error: res.data.error.message,
      });
      !!callback?.failed && callback.failed(res.data.error.message);
    } else {
      yield put({
        type: typeActions.GET_STATISTIC_SUCCESS,
        data: res.data,
      });
      !!callback?.success && callback.success(res.data);
    }
  } catch (error) {
    yield put({
      type: typeActions.GET_STATISTIC_FAILED,
      error: error?.response?.data?.error?.message ?? '',
    });
    !!callback?.failed &&
      callback.failed(error?.response?.data?.error?.message ?? '');
  }
}

export default function* statisticSaga() {
  yield takeLatest(typeActions.GET_STATISTIC_REQUEST, getStatistic);
}
