import React, { memo, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Button, Col, Form, Modal, Row } from 'reactstrap';
import _ from 'lodash';

import { BASE_URL_IMAGE } from 'services/ServiceURL';
import CONSTANT from 'constant';
import moment from 'moment';
import LabelValue from 'views/pages/components/LabelValue';
import { areaActions, organizationActions } from 'redux/actions';

const DAccount = ({ isVisibleD, setIsVisibleD, account, setAccount }) => {
  const dispatch = useDispatch();
  const [areasValue, setAreasValue] = useState(null);
  const [organizationsValue, setOrganizationsValue] = useState(null);
  const handleClose = () => {
    setAccount(null);
    setIsVisibleD(false);
  };

  const renderLabelValue = (
    label = '',
    value = '',
    labelWidth = 120,
    textAlign = 'end'
  ) => {
    return (
      <LabelValue
        label={label}
        value={value}
        labelWidth={labelWidth}
        textAlign={textAlign}
      />
    );
  };

  const avatar = !!account?.avatar?.url
    ? `${BASE_URL_IMAGE}${account.avatar.url}`
    : require('assets/img/icons/cards/avatar.jpg').default;
  const accountTypeV = CONSTANT.ACCOUNT_TYPE.find(
    (item) => item.value === account?.accountType
  );
  const genderV = CONSTANT.ACCOUNT_GENDER.find(
    (item) => item.value === account?.gender
  );
  const isActiveV = CONSTANT.ACCOUNT_STATUS.find(
    (item) => item.value === account?.isActive
  );

  const channelV = CONSTANT.CHANNEL.find(
    (item) => item.value === account?.channel
  );
  const specialistGrV = CONSTANT.ACCOUNT_SPECIAL_LIST_GR.find(
    (item) => item.value === account?.specialistGr
  );
  const isMarriedV = CONSTANT.MARRIED.find(
    (item) => item.value === account?.isMarried
  );

  const getRelationOrganizations = () => {
    dispatch(
      organizationActions.getOrganizationsByAccountExtend(account.id, {
        success: (data) => {
          setOrganizationsValue(
            data.map((item) => ({
              label: item.name,
              value: item.id,
              ...item,
            }))
          );
        },
        failed: () => {},
      })
    );
  };

  const getAreasByAccountExtend = () => {
    dispatch(
      areaActions.getAreasByAccountExtend(account.id, {
        success: (data) => {
          setAreasValue(
            data.map((item) => ({
              label: item.name,
              value: item.id,
              ...item,
            }))
          );
        },
        failed: () => {},
      })
    );
  };

  useEffect(() => {
    if (!account?.id) return;
    getAreasByAccountExtend();
    getRelationOrganizations();
  }, [account?.id]);

  return (
    <Modal
      style={{ minWidth: 1333 }}
      isOpen={isVisibleD}
      className="modal-dialog-centered modal-secondary"
      toggle={() => {
        handleClose();
      }}
    >
      <div className="modal-body bg-white rounded pT-0">
        <div className="bg-white d-flex py-2 border-bottom">
          <span style={{ fontSize: 20, fontWeight: 600 }}>
            Xem thông tin tài khoản
          </span>
        </div>

        <div>
          <div className="">
            <Row>
              <Col className="border-right" xs={8}>
                <div>
                  <div className="d-flex align-items-center my-2">
                    <span
                      className=""
                      style={{
                        fontSize: 15,
                        fontWeight: 600,
                      }}
                    >
                      Thông tin cơ bản tài khoản
                    </span>
                    <div className="border-bottom flex-grow-1 ml-2" />
                  </div>
                  <Row>
                    <Col xs={5}>
                      {renderLabelValue('Mã tài khoản', account?.code ?? '')}
                    </Col>
                    <Col xs={5}>
                      {renderLabelValue(
                        'Tên đăng nhập',
                        account?.email ?? '',
                        undefined,
                        'left'
                      )}
                    </Col>
                  </Row>
                  <Row>
                    <Col xs={5}>
                      {renderLabelValue('Phân loại', accountTypeV?.label ?? '')}
                    </Col>
                    <Col xs={5}>
                      {renderLabelValue(
                        'Khu vực',
                        (areasValue ?? []).map((item, index, self) =>
                          index === self.length - 1
                            ? `${item.name}.`
                            : `${item.name}, `
                        ),
                        undefined,
                        'left'
                      )}
                    </Col>
                  </Row>
                  <Row>
                    <Col xs={10}>
                      {renderLabelValue(
                        'Tổ chức',
                        (organizationsValue ?? []).map((item, index, self) =>
                          index === self.length - 1
                            ? `${item.name}.`
                            : `${item.name}, `
                        )
                      )}
                    </Col>
                  </Row>
                  <Row>
                    <Col xs={5}>
                      {renderLabelValue('Trạng thái', isActiveV?.label ?? '')}
                    </Col>
                    <Col xs={5}>
                      {renderLabelValue(
                        'Nhóm chuyên khoa phụ trách',
                        specialistGrV?.label ?? '',
                        undefined,
                        'left'
                      )}
                    </Col>
                  </Row>

                  <Row>
                    <Col xs={5}>
                      {renderLabelValue('Chức vụ', account?.position ?? '')}
                    </Col>
                    <Col xs={5}>
                      {renderLabelValue(
                        'Nhóm quyền',
                        account?.decentralization?.name ?? '',
                        undefined,
                        'left'
                      )}
                    </Col>
                  </Row>

                  <Row>
                    <Col xs={6}>
                      {renderLabelValue(
                        'Cấp quản lý',
                        `${account?.parent?.code ?? ''}(${
                          account?.parent?.email ?? ''
                        })`
                      )}
                    </Col>
                    <Col xs={6}>
                      {renderLabelValue(
                        'Nv phụ trách',
                        `${account?.staffInCharge?.fullname ?? ''}`
                      )}
                    </Col>
                  </Row>
                </div>
                <div>
                  <div className="d-flex align-items-center my-2">
                    <span
                      className=""
                      style={{
                        fontSize: 15,
                        fontWeight: 600,
                      }}
                    >
                      Thông tin cá nhân
                    </span>
                    <div className="border-bottom flex-grow-1 ml-2" />
                  </div>
                </div>
                <Row>
                  <Col xs={4}>
                    {renderLabelValue(
                      'Họ và tên đệm',
                      account?.adminUser?.lastname ?? ''
                    )}
                  </Col>
                  <Col xs={4}>
                    {renderLabelValue(
                      'Tên',
                      account?.adminUser?.firstname ?? '',
                      80,
                      'left'
                    )}
                  </Col>
                </Row>

                <Row>
                  <Col xs={4} className="d-flex">
                    {renderLabelValue(
                      'Ngày sinh',
                      !!account?.dateOfBirth
                        ? moment(account.dateOfBirth).format('DD/MM/YYYY')
                        : ''
                    )}
                  </Col>
                  <Col xs={4}>
                    {renderLabelValue(
                      'Giới tính',
                      genderV?.label ?? '',
                      80,
                      'left'
                    )}
                  </Col>
                  <Col xs={4}>
                    {renderLabelValue(
                      'Hôn nhân',
                      isMarriedV?.label ?? '',
                      80,
                      'left'
                    )}
                  </Col>
                </Row>
                <Row>
                  <Col xs={4}>
                    {renderLabelValue('Số CCCD', account?.citizenId ?? '')}
                  </Col>

                  <Col xs={4} className="d-flex">
                    {renderLabelValue(
                      'Ngày cấp',
                      !!account?.licenseDate
                        ? moment(account.licenseDate).format('DD/MM/YYYY')
                        : '',
                      80,
                      'left'
                    )}
                  </Col>
                  <Col xs={4}>
                    {renderLabelValue(
                      'Nơi cấp',
                      account?.licenseWhere ?? '',
                      80,
                      'left'
                    )}
                  </Col>
                </Row>
                <Row>
                  <Col xs={6}>
                    {renderLabelValue('Số điện thoại', account?.phone ?? '')}
                  </Col>
                </Row>

                <Row>
                  <Col xs={12}>
                    {renderLabelValue('Đia chỉ', account?.address ?? '')}
                  </Col>
                </Row>
              </Col>
              <Col xs={4}>
                <div className="d-flex align-items-center my-2">
                  <span
                    className=""
                    style={{
                      fontSize: 15,
                      fontWeight: 600,
                    }}
                  >
                    Thông tin bổ sung
                  </span>
                  <div className="border-bottom flex-grow-1 ml-2" />
                </div>
                <div className="d-flex justify-content-center align-items-center rounded-circle flex-column my-3">
                  <img
                    alt="..."
                    className="img-fluid rounded-circle shadow"
                    src={avatar}
                    style={{ width: '100px', height: '100px' }}
                  />
                </div>
                <div>
                  <Row>
                    <Col xs={12}>
                      {renderLabelValue(
                        'Chuyên khoa',
                        account?.specialist ?? ''
                      )}
                    </Col>
                  </Row>
                  <Row>
                    <Col xs={12}>
                      {renderLabelValue('Bằng cấp', account?.degree ?? '')}
                    </Col>
                  </Row>
                  <Row>
                    <Col xs={12}>
                      {renderLabelValue(
                        'Nơi làm việc',
                        account?.workAddress ?? ''
                      )}
                    </Col>
                  </Row>
                  <Row>
                    <Col xs={12}>
                      {renderLabelValue('Kênh', channelV?.label ?? '')}
                    </Col>
                  </Row>
                  <Row>
                    <Col xs={12}>
                      {renderLabelValue(
                        'Địa chỉ làm việc',
                        account?.workWhere ?? ''
                      )}
                    </Col>
                  </Row>
                </div>
              </Col>
            </Row>
          </div>
        </div>
        <div className="mb-0 pb-0 mt-4 d-flex justify-content-end">
          <Button
            className="text-nowrap"
            onClick={() => {
              handleClose();
            }}
          >
            Đóng
          </Button>
        </div>
      </div>
    </Modal>
  );
};

export default memo(DAccount);
