import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import {
  Card,
  CardBody,
  CardTitle,
  Container,
  Row,
  Col,
  Input,
} from 'reactstrap';
import moment from 'moment';
import queryString from 'query-string';
import LabelCustom from 'views/pages/components/LabelCustom';
import IconXnError from 'assets/svgs/dashboard_xn_error.svg';
import IconXn from 'assets/svgs/dashboard_xn.svg';
import IconReceived from 'assets/svgs/dashboard_received.svg';
import IconResult from 'assets/svgs/dashboard_result.svg';
import { useDispatch, useSelector } from 'react-redux';
import { statisticActions } from 'redux/actions';
import { currencyFormat } from 'common';
import SelectTime from './SelectTime';
import { useHistory } from 'react-router';
function SatisticAdmin({}) {
  const history = useHistory();
  const dispatch = useDispatch();
  const { isDoctorHT } = useSelector((state) => state.accountReducer);
  const { areasByAccountExtend } = useSelector((state) => state.areaReducer);
  const { statistic } = useSelector((state) => state.statisticReducer);
  const [time, setTime] = useState(0);

  useEffect(() => {
    handleGetSatistic();
  }, [time]);

  const handleGetSatistic = () => {
    const query = { type: 'admin-htg' };
    switch (time) {
      case 0:
        query['filters[$and][0][createdAt][$gte]'] = moment()
          .startOf('date')
          .toISOString();
        query['filters[$and][1][createdAt][$lte]'] = moment()
          .endOf('date')
          .toISOString();
        break;
      case 1:
        query['filters[$and][0][createdAt][$gte]'] = moment()
          .startOf('week')
          .toISOString();
        query['filters[$and][1][createdAt][$lte]'] = moment()
          .endOf('week')
          .toISOString();
        break;
      case 2:
        query['filters[$and][0][createdAt][$gte]'] = moment()
          .startOf('month')
          .toISOString();
        query['filters[$and][1][createdAt][$lte]'] = moment()
          .endOf('month')
          .toISOString();
        break;
    }

    if (isDoctorHT) {
      areasByAccountExtend.forEach((item, index) => {
        query[`filters[$and][2][$or][${index}][areaName][$eq]`] = item.name;
      });
    }

    dispatch(statisticActions.getStatistic(queryString.stringify(query)));
  };

  return (
    <>
      <div className="header mt--9 pb-6">
        <Container fluid>
          <div className="header-body">
            <Row className="align-items-center py-4">
              <Col lg="6" xs="7"></Col>
              <Col className="d-flex justify-content-end" lg="6" xs="3">
                <LabelCustom
                  label={'Thời gian:'}
                  id="time"
                  style={{
                    textAlign: 'end',
                    margin: '0.5em 8px 0px 0px',
                    color: 'white',
                  }}
                  styleLabel={{
                    minWidth: 60,
                  }}
                />
                <SelectTime time={time} setTime={setTime} />
              </Col>
            </Row>

            <Row>
              <Col sm={12} md={6}>
                <Card className="card-stats">
                  <CardBody style={{ height: 308 }}>
                    <Row>
                      <div className="col d-flex flex-column justify-content-center align-items-center">
                        <CardTitle
                          tag="h1"
                          className="text-uppercase text-muted mb-0"
                        >
                          Tổng số
                        </CardTitle>
                        <span className="h1 font-weight-bold mb-0">
                          {currencyFormat(statistic?.orderTotal ?? 0)}
                        </span>
                      </div>

                      <div className="col d-flex align-items-center">
                        <div
                          className="border-left"
                          style={{ height: '80%' }}
                        />
                        <CardBody className="">
                          <div className="border-bottom py-2">
                            <Row>
                              <div className="col">
                                <CardTitle
                                  tag="h6"
                                  className="text-uppercase text-muted mb-0"
                                >
                                  Đơn hàng chưa thanh toán
                                  <br />
                                  <i>(Nợ tiền mặt)</i>
                                </CardTitle>
                                <div className="d-flex justify-content-between align-items-end mt-2">
                                  <span className="h2 font-weight-bold mb-0">
                                    {currencyFormat(
                                      statistic?.organizationCashDebt ?? 0
                                    )}
                                  </span>
                                  <span
                                    onClick={() => {
                                      history.push('/admin/order-unpaid');
                                    }}
                                    style={{
                                      lineHeight: 1.5,
                                      color: '#0a66b2',
                                      cursor: 'pointer',
                                    }}
                                    className="text-nowrap text-underline"
                                  >
                                    Chi tiết {'>>'}
                                  </span>
                                </div>
                              </div>
                            </Row>
                          </div>
                          <div className="py-2">
                            <Row>
                              <div className="col">
                                <CardTitle
                                  tag="h6"
                                  className="text-uppercase text-muted mb-0"
                                >
                                  Đơn hàng chưa thanh toán
                                  <br />
                                  <i>(Tiền mặt)</i>
                                </CardTitle>
                                <div className="d-flex justify-content-between align-items-end mt-2">
                                  <span className="h2 font-weight-bold mb-0">
                                    {currencyFormat(
                                      statistic?.organizationCash ?? 0
                                    )}
                                  </span>
                                  <span
                                    onClick={() => {
                                      history.push('/admin/order-unpaid');
                                    }}
                                    style={{
                                      lineHeight: 1.5,
                                      color: '#0a66b2',
                                      cursor: 'pointer',
                                    }}
                                    className="text-nowrap text-underline"
                                  >
                                    Chi tiết {'>>'}
                                  </span>
                                </div>
                              </div>
                            </Row>
                          </div>
                          <div className="py-2">
                            <Row>
                              <div className="col">
                                <CardTitle
                                  tag="h6"
                                  className="text-uppercase text-muted mb-0"
                                >
                                  Đơn hàng chưa thanh toán
                                  <br />
                                  <i>(Hợp đồng)</i>
                                </CardTitle>
                                <div className="d-flex justify-content-between align-items-end mt-2">
                                  <span className="h2 font-weight-bold mb-0">
                                    {currencyFormat(
                                      statistic?.organizationContract ?? 0
                                    )}
                                  </span>
                                  <span
                                    onClick={() => {
                                      history.push('/admin/order-unpaid');
                                    }}
                                    style={{
                                      lineHeight: 1.5,
                                      color: '#0a66b2',
                                      cursor: 'pointer',
                                    }}
                                    className="text-nowrap text-underline"
                                  >
                                    Chi tiết {'>>'}
                                  </span>
                                </div>
                              </div>
                            </Row>
                          </div>
                        </CardBody>
                      </div>
                    </Row>
                  </CardBody>
                </Card>
              </Col>
              <Col sm={12} md={3}>
                <Card className="card-stats">
                  <CardBody>
                    <Row>
                      <div className="col">
                        <div className="pt-2">
                          <Row>
                            <div className="col">
                              <CardTitle
                                tag="h6"
                                className="text-uppercase text-muted mb-4"
                              >
                                Đơn hàng xn lỗi
                              </CardTitle>

                              <span className="h2 font-weight-bold mb-0">
                                {currencyFormat(statistic?.errorXNOrder ?? 0)}
                              </span>
                            </div>
                          </Row>
                        </div>
                      </div>
                      <Col className="col-auto d-flex flex-column justify-content-between align-items-center">
                        <div className="icon icon-shape bg-gradient-red text-white rounded-circle shadow">
                          <img style={{ zoom: 0.8 }} src={IconXnError} />
                        </div>
                        <span
                          onClick={() => {
                            history.push('/admin/synthesis-sample-transfer');
                          }}
                          style={{
                            lineHeight: 1.5,
                            color: '#0a66b2',
                            cursor: 'pointer',
                          }}
                          className="text-nowrap text-underline"
                        >
                          Chi tiết {'>>'}
                        </span>
                      </Col>
                    </Row>
                  </CardBody>
                </Card>
                <Card className="card-stats">
                  <CardBody>
                    <Row>
                      <div className="col">
                        <div className="pt-2">
                          <Row>
                            <div className="col">
                              <CardTitle
                                tag="h6"
                                className="text-uppercase text-muted mb-4"
                              >
                                Tổng hợp/tiếp nhận
                              </CardTitle>

                              <span className="h2 font-weight-bold mb-0">
                                {currencyFormat(statistic?.receivedOrder ?? 0)}
                              </span>
                            </div>
                          </Row>
                        </div>
                      </div>
                      <Col className="col-auto d-flex flex-column justify-content-between align-items-center">
                        <div className="icon icon-shape bg-gradient-info text-white rounded-circle shadow">
                          <img style={{ zoom: 0.8 }} src={IconReceived} />
                        </div>
                        <span
                          onClick={() => {
                            history.push('/admin/receive-result');
                          }}
                          style={{
                            lineHeight: 1.5,
                            color: '#0a66b2',
                            cursor: 'pointer',
                          }}
                          className="text-nowrap text-underline"
                        >
                          Chi tiết {'>>'}
                        </span>
                      </Col>
                    </Row>
                  </CardBody>
                </Card>
              </Col>
              <Col sm={12} md={3}>
                <Card className="card-stats">
                  <CardBody>
                    <Row>
                      <div className="col">
                        <div className="pt-2">
                          <Row>
                            <div className="col">
                              <CardTitle
                                tag="h6"
                                className="text-uppercase text-muted mb-4"
                              >
                                Đang xét nghiệm
                              </CardTitle>

                              <span className="h2 font-weight-bold mb-0">
                                {currencyFormat(statistic?.xnOrder ?? 0)}
                              </span>
                            </div>
                          </Row>
                        </div>
                      </div>
                      <Col className="col-auto d-flex flex-column justify-content-between align-items-center">
                        <div className="icon icon-shape bg-gradient-success text-white rounded-circle shadow">
                          <img style={{ zoom: 0.8 }} src={IconXn} />
                        </div>
                        <span
                          onClick={() => {
                            history.push('/admin/synthesis-sample-transfer');
                          }}
                          style={{
                            lineHeight: 1.5,
                            color: '#0a66b2',
                            cursor: 'pointer',
                          }}
                          className="text-nowrap text-underline"
                        >
                          Chi tiết {'>>'}
                        </span>
                      </Col>
                    </Row>
                  </CardBody>
                </Card>
                <Card className="card-stats">
                  <CardBody>
                    <Row>
                      <div className="col">
                        <div className="pt-2">
                          <Row>
                            <div className="col">
                              <CardTitle
                                tag="h6"
                                className="text-uppercase text-muted mb-4"
                              >
                                Đã trả kết quả
                              </CardTitle>

                              <span className="h2 font-weight-bold mb-0">
                                {currencyFormat(statistic?.payResultOrder ?? 0)}
                              </span>
                            </div>
                          </Row>
                        </div>
                      </div>
                      <Col className="col-auto d-flex flex-column justify-content-between align-items-center">
                        <div className="icon icon-shape bg-gradient-success text-white rounded-circle shadow">
                          <img style={{ zoom: 0.8 }} src={IconResult} />
                        </div>
                        <span
                          onClick={() => {
                            history.push('/admin/pay-result');
                          }}
                          style={{
                            lineHeight: 1.5,
                            color: '#0a66b2',
                            cursor: 'pointer',
                          }}
                          className="text-nowrap text-underline mt-3"
                        >
                          Chi tiết {'>>'}
                        </span>
                      </Col>
                    </Row>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </div>
        </Container>
      </div>
    </>
  );
}

SatisticAdmin.propTypes = {
  name: PropTypes.string,
  parentName: PropTypes.string,
};

export default SatisticAdmin;
