import typeActions from 'redux/typeActions';

const setValue = ({ name, value }) => {
  return {
    type: typeActions.SET_PAYMENT_HISTORY_VALUE,
    name,
    value,
  };
};

const getPaymentHistorys = (query, callback, isUpdateReducer = true) => {
  return {
    type: typeActions.GET_PAYMENT_HISTORYS_REQUEST,
    query,
    callback,
    isUpdateReducer,
  };
};

const getPaymentHistory = (param, query, callback) => {
  return {
    type: typeActions.GET_PAYMENT_HISTORY_REQUEST,
    param,
    query,
    callback,
  };
};

const createPaymentHistory = (body, callback) => {
  return {
    type: typeActions.CREATE_PAYMENT_HISTORY_REQUEST,
    body,
    callback,
  };
};
const updatePaymentHistory = (param, body, callback) => {
  return {
    type: typeActions.UPDATE_PAYMENT_HISTORY_REQUEST,
    param,
    body,
    callback,
  };
};
const deletePaymentHistory = (param, callback) => {
  return {
    type: typeActions.DELETE_PAYMENT_HISTORY_REQUEST,
    param,
    callback,
  };
};

export default {
  setValue,
  getPaymentHistorys,
  getPaymentHistory,
  createPaymentHistory,
  updatePaymentHistory,
  deletePaymentHistory,
};
