import _ from 'lodash';
import { takeLatest, call, put, all } from 'redux-saga/effects';

import typeActions from 'redux/typeActions';
import ServiceURL, { CONVERT_BASE_URL } from 'services/ServiceURL';
import { custom } from 'services/axiosClient';

export function* convertFilePdf(data) {
  const callback = data.callback;
  try {
    const formData = new FormData();
    data.files.forEach((file) => {
      formData.append(`file`, file);
    });
    const res = yield custom('upload', formData, 'post', CONVERT_BASE_URL);
    if (!!res.data?.error?.message) {
      yield put({
        type: typeActions.CONVERT_FILE_PDF_FAILED,
        error: res.data?.error?.message,
      });
      !!callback?.failed && callback.failed(res.data.error.message);
    } else {
      yield put({
        type: typeActions.CONVERT_FILE_PDF_SUCCESS,
        data: res,
      });

      !!callback?.success && callback.success(res);
    }
  } catch (error) {
    yield put({
      type: typeActions.CONVERT_FILE_PDF_FAILED,
      error: error?.response?.data?.error?.message ?? '',
    });
    !!callback?.failed &&
      callback.failed(error?.response?.data?.error?.message ?? '');
  }
}

export default function* billSaga() {
  yield takeLatest(typeActions.CONVERT_FILE_PDF_REQUEST, convertFilePdf);
}
