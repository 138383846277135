import typeActions from 'redux/typeActions';
import _ from 'lodash';

const initialState = {
  isUploading: false,
  isDeleting: false,
};

const uploadReducer = (state = initialState, actions) => {
  switch (actions.type) {
    case typeActions.UPLOAD_AVATAR_REQUEST:
      return {
        ...state,
        isUploading: true,
      };
    case typeActions.UPLOAD_AVATAR_SUCCESS:
      return {
        ...state,
        isUploading: false,
      };
    case typeActions.UPLOAD_AVATAR_FAILED:
      return {
        ...state,
        isUploading: false,
      };

    case typeActions.DELETE_AVATAR_REQUEST:
      return {
        ...state,
        isUploading: true,
      };
    case typeActions.DELETE_AVATAR_SUCCESS:
      return {
        ...state,
        isDeleting: false,
      };
    case typeActions.DELETE_AVATAR_FAILED:
      return {
        ...state,
        isDeleting: false,
      };

    default:
      return {
        ...state,
      };
  }
};
export default uploadReducer;
