import { returnValue } from 'common';
import typeActions from 'redux/typeActions';
const initialState = {
  organizations: {},
  pagination: {},
  // form show/delete
  isModalVisible: false,
  modalInfo: {},
  page: 1,
  limit: 10,
  query: {
    populate: '*',
  },
};
const organizationReducer = (state = initialState, actions) => {
  switch (actions.type) {
    case typeActions.SET_ORGANIZATION_VALUE:
      const nameOrganization = actions.name;
      const valueOrganization = actions.value;
      if (typeof nameOrganization !== 'string') return { ...state };
      const objOrganization = returnValue(
        state,
        nameOrganization,
        valueOrganization
      );
      return {
        ...state,
        ...objOrganization,
      };
    case typeActions.GET_ORGANIZATION_REQUEST:
      return {
        ...state,
        ...actions?.data,
      };
    case typeActions.GET_ORGANIZATION_SUCCESS:
      return {
        ...state,
        organizations: actions.data.results,
        pagination: actions.data.pagination,
        page: actions.data.pagination.page,
        limit: actions.data.pagination.pageSize,
      };
    case typeActions.GET_ORGANIZATION_FAILED:
      return {
        ...state,
      };
    // create

    case typeActions.CREATE_ORGANIZATION_REQUEST:
      return {
        ...state,
        errors: {
          ...state.errors,
        },
      };
    case typeActions.CREATE_ORGANIZATION_SUCCESS:
      return {
        ...state,
        errors: {
          ...state.errors,
          createAccount: '',
        },
      };
    case typeActions.CREATE_ORGANIZATION_FAILED:
      return {
        ...state,
        errors: {
          ...state.errors,
          createAccount: actions.error,
        },
      };

    //delete
    case typeActions.DELETE_ORGANIZATION_REQUEST:
      return {
        ...state,
      };
    case typeActions.DELETE_ORGANIZATION_SUCCESS:
      return {
        ...state,
      };
    case typeActions.DELETE_ORGANIZATION_FAILED:
      return {
        ...state,
      };
    default:
      return {
        ...state,
      };
  }
};

export default organizationReducer;
