import React, { memo, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Button, Col, Form, Modal, Row } from 'reactstrap';
import * as yup from 'yup';
import { Formik } from 'formik';
import _ from 'lodash';
import queryString from 'query-string';
import DatePicker from 'react-datepicker';

import {
  notificationAlertRef,
  notify,
  relationOneToOne,
  relationOneToMany,
} from 'common';
import {
  promotionDetailActions,
  formActions,
  serviceActions,
  organizationActions,
  promotionActions,
} from 'redux/actions';
import LoadingButtonCustom from 'views/pages/components/LoadingButtonCustom';
import InputCustom from 'views/pages/components/InputCustom';
import SelectCustom from 'views/pages/components/SelectCustom';
import LabelCustom from 'views/pages/components/LabelCustom';
import HelperText from 'views/pages/components/HelperText';
import moment from 'moment';
import CustomInputCalendar from 'views/pages/components/CustomInputCalendar';

const CUPromotionDetail = ({ handleGetPromotionDetails }) => {
  const dispatch = useDispatch();

  // reducers
  const { isModalVisible, modalInfo } = useSelector(
    (state) => state.formReducer
  );
  const { isCreatingPromotionDetail, isUpdatingPromotionDetail } = useSelector(
    (state) => state.promotionDetailReducer
  );

  const isUpdate = !_.isEmpty(modalInfo);
  // Khởi tạo dữ liêu
  const initInfo = {
    code: modalInfo?.code ?? '',
    amount: modalInfo?.amount ?? 1,
    amountUsed: modalInfo?.amoundUsed ?? 0,
    status: modalInfo?.status ?? true,
    startDate: !!modalInfo?.startDate ? new Date(modalInfo.startDate) : null,
    endDate: !!modalInfo?.endDate ? new Date(modalInfo.endDate) : null,
    services: !!modalInfo?.services
      ? modalInfo.services.map((item) => item.id)
      : null,
    organization: modalInfo?.organization?.id ?? null,
    promotion: modalInfo?.promotion?.id ?? null,
  };

  const [resultsService, setResultsService] = useState([]);
  const [resultsOrganization, setResultsOrganization] = useState([]);
  const [resultsPromotion, setResultsPromotion] = useState([]);
  const [servicesValue, setServicesValue] = useState(null);
  const [organizationValue, setOrganizationValue] = useState(null);
  const [promotionValue, setPromotionValue] = useState(null);

  const [serviceSearch, setServiceSearch] = useState('');
  const [organizationSearch, setOrganizationSearch] = useState('');
  const [promotionSearch, setPromotionSearch] = useState('');

  // validate dữ liêu
  const infoSchema = yup.object().shape({
    code: yup.string().required('Mã chi tiết khuyến mại không được để trống!'),
    amount: yup
      .number()
      .min(1, 'Số lượng lớn hơn hoặc bằng 1!')
      .required('Số lượng là số và không được để trống!'),
    startDate: yup
      .date()
      .required('Ngày bắt đầu không được để trống!')
      .nullable(),
    endDate: yup
      .date()
      .required('Ngày kết thúc không được để trống!')
      .nullable(),
    // services: yup.array().min(1, 'Dịch vụ áp dụng không được để trống!'),
    // organization: yup.number().required('Mã tổ chức không được để trống!'),
    promotion: yup
      .number()
      .required('Chương trình khuyến mại không được để trống!')
      .nullable(),
  });

  // Xử lý submit
  const onSubmit = (values) => {
    const body = {
      code: values.code,
      amount: values.amount,
      amountUsed: values.amoundUsed,
      status: values.status,
      startDate: values.startDate,
      endDate: values.endDate,
      services: relationOneToMany(
        values.services,
        !!modalInfo?.services
          ? modalInfo.services
              .map((item) => item.id)
              .filter((item) => !values.services.includes(item))
          : null
      ),
      organization: relationOneToOne(values.organization, null),
      promotion: relationOneToOne(values.promotion, null),
    };

    for (const key in body) {
      if (body[key] === '') {
        delete body[key];
      }
    }

    if (isUpdate) {
      // update
      dispatch(
        promotionDetailActions.updatePromotionDetail(modalInfo.id, body, {
          success: () => {
            handleGetPromotionDetails();
            notify(
              notificationAlertRef,
              'success',
              'Thông báo',
              `Cập nhật chi tiết khuyến mại thành công.`
            );
            handleClose();
          },
          failed: (mess) => {
            notify(
              notificationAlertRef,
              'danger',
              'Thông báo',
              `Cập nhật chi tiết khuyến mại thất bại!. Lỗi: ${mess}!`
            );
          },
        })
      );
    } else {
      // create
      dispatch(
        promotionDetailActions.createPromotionDetail(body, {
          success: () => {
            handleGetPromotionDetails();
            notify(
              notificationAlertRef,
              'success',
              'Thông báo',
              `Thêm mới chi tiết khuyến mại thành công.`
            );
            handleClose();
          },
          failed: (mess) => {
            notify(
              notificationAlertRef,
              'danger',
              'Thông báo',
              `Thêm mới chi tiết khuyến mại thất bại!. Lỗi: ${mess}!`
            );
          },
        })
      );
    }
  };

  // Xử lý đóng form
  const handleClose = () => {
    setOrganizationValue(null);
    setServicesValue(null);
    setPromotionValue(null);
    dispatch(
      formActions.setValue({
        name: 'isModalVisible',
        value: false,
      })
    );
    dispatch(
      formActions.setValue({
        name: 'modalInfo',
        value: {},
      })
    );
  };
  // get services
  const handleGetServices = () => {
    if (serviceSearch !== '') {
      dispatch(
        serviceActions.getServices(
          queryString.stringify({
            'filters[$and][1][code][$containsi]': serviceSearch,
          }),
          {
            success: (data) => {
              const results = _.get(data, 'results', []);
              setResultsService(results);
            },
            failed: () => {},
          },
          false
        )
      );
    } else {
      dispatch(
        serviceActions.getServices(
          queryString.stringify({}),
          {
            success: (data) => {
              const results = _.get(data, 'results', []);
              setResultsService(results);
            },
            failed: () => {},
          },
          false
        )
      );
    }
  };

  useEffect(() => {
    // Sau 500ms thì bắn api
    const delayDebounceFn = setTimeout(() => {
      handleGetServices();
    }, 500);
    return () => clearTimeout(delayDebounceFn);
  }, [serviceSearch]);

  // get organizations
  const handleGetOrganizations = () => {
    const query = {
      'filters[$and][1][organizationType][$eq]': 1,
      'filters[$and][2][status][$eq]': 0,
    };
    if (organizationSearch !== '') {
      query['filters[$and][3][code][$containsi]'] = organizationSearch;
    }

    dispatch(
      organizationActions.getOrganizations(
        queryString.stringify(query),
        {
          success: (data) => {
            // const results = _.get(data, 'results', []);
            setResultsOrganization(data ?? []);
          },
          failed: () => {},
        },
        false
      )
    );
  };
  useEffect(() => {
    // Sau 500ms thì bắn api
    const delayDebounceFn = setTimeout(() => {
      handleGetOrganizations();
    }, 500);
    return () => clearTimeout(delayDebounceFn);
  }, [organizationSearch]);

  // get promotions
  const handleGetPromotions = () => {
    const query = {
      'filters[$and][1][status][$eq]': true,
    };
    if (promotionSearch !== '') {
      query['filters[$and][2][name][$containsi]'] = promotionSearch;
    }
    dispatch(
      promotionActions.getPromotions(
        queryString.stringify(query),
        {
          success: (data) => {
            const results = _.get(data, 'results', []);
            setResultsPromotion(results);
          },
          failed: () => {},
        },
        false
      )
    );
  };
  useEffect(() => {
    // Sau 500ms thì bắn api
    const delayDebounceFn = setTimeout(() => {
      handleGetPromotions();
    }, 500);
    return () => clearTimeout(delayDebounceFn);
  }, [promotionSearch]);

  useEffect(() => {
    if (isUpdate) {
      setPromotionValue(
        !!modalInfo?.promotion
          ? {
              value: modalInfo?.promotion?.id ?? -1,
              label: modalInfo?.promotion?.name ?? '',
              startDate: !!modalInfo?.promotion?.startDate
                ? new Date(modalInfo.promotion.startDate)
                : null,
              endDate: !!modalInfo?.promotion?.endDate
                ? new Date(modalInfo.promotion.endDate)
                : null,
            }
          : null
      );
      setOrganizationValue(
        !!modalInfo?.organization
          ? {
              value: modalInfo?.organization?.id ?? -1,
              label: `${modalInfo?.organization?.name ?? ''} (${
                modalInfo?.organization?.code ?? ''
              })`,
            }
          : null
      );
      setServicesValue(
        modalInfo?.services.map((item) => ({
          label: `${item.name ?? ''}(${item?.code ?? ''})`,
          value: item.id,
        })) ?? null
      );
    } else {
      setPromotionValue(null);
      setOrganizationValue(null);
      setServicesValue(null);
    }
  }, [isUpdate]);

  return (
    <Modal
      size={'md'}
      isOpen={isModalVisible}
      className="modal-dialog-centered modal-secondary"
      toggle={() => {
        handleClose();
      }}
    >
      <div className="modal-header border-bottom bg-white pb-2">
        <h2>{isUpdate ? 'Cập nhật' : 'Thêm mới'} chi tiết khuyến mại</h2>
        <button
          aria-label="Close"
          className="close"
          data-dismiss="modal"
          type="button"
          onClick={() => {
            handleClose();
          }}
        >
          <span style={{ color: '#262626' }} aria-hidden={true}>
            ×
          </span>
        </button>
      </div>
      <div className="modal-body bg-white rounded">
        <Formik
          initialValues={initInfo}
          enableReinitialize
          onSubmit={onSubmit}
          validationSchema={infoSchema}
        >
          {({
            values,
            handleSubmit,
            errors,
            touched,
            handleBlur,
            handleChange,
            setFieldValue,
          }) => {
            return (
              <Form onSubmit={handleSubmit} className="">
                <Row>
                  <Col xs={12}>
                    <InputCustom
                      name="code"
                      label="Mã khuyến mại"
                      type="text"
                      placeholder="Nhập mã khuyến mại"
                      value={values.code}
                      isRequired={true}
                      handleChange={handleChange}
                      handleBlur={handleBlur}
                      error={errors?.code}
                      touched={touched?.code}
                      disabled={isUpdate}
                    />
                  </Col>
                </Row>
                <Row>
                  <Col xs={12}>
                    <SelectCustom
                      name="services"
                      label="Dịch vụ áp dụng"
                      placeholder="Chọn dịch vụ"
                      value={servicesValue}
                      isRequired={false}
                      isClearable={false}
                      handleChange={(e, remove) => {
                        if (_.isEmpty(remove?.removedValue)) {
                          setServicesValue(e);
                          setFieldValue(
                            'services',
                            e.map((item) => item.value)
                          );
                        } else {
                          const tmp = servicesValue.filter(
                            (item) => item.value !== remove.removedValue.value
                          );
                          setServicesValue(tmp);
                          const speciments = values.services.filter(
                            (item) => item !== remove.removedValue.value
                          );

                          setFieldValue('services', speciments);
                        }
                      }}
                      handleBlur={handleBlur}
                      onInputChange={(value) => {
                        setServiceSearch(value);
                      }}
                      error={errors.services}
                      touched={touched.services}
                      options={resultsService.map((item) => ({
                        label: `${item?.name ?? ''}(${item?.code ?? ''})`,
                        value: item?.id ?? -1,
                      }))}
                      isMulti
                    />
                  </Col>
                </Row>

                <Row>
                  <Col xs={6}>
                    <SelectCustom
                      name="organization"
                      label="Mã tổ chức"
                      placeholder="Lựa chọn"
                      value={organizationValue}
                      isRequired={false}
                      isClearable={true}
                      handleChange={(e) => {
                        setOrganizationValue(e);
                        setFieldValue('organization', e?.value ?? null);
                      }}
                      handleBlur={handleBlur}
                      onInputChange={(value) => {
                        setOrganizationSearch(value);
                      }}
                      error={errors.organization}
                      touched={touched.organization}
                      options={resultsOrganization.map((item) => ({
                        label:
                          `${item?.name ?? ''} (${item?.code ?? ''})` ?? '',
                        value: item?.id ?? -1,
                      }))}
                    />
                  </Col>
                  <Col xs={6}>
                    <InputCustom
                      name="amount"
                      label="Số lượng"
                      type="number"
                      placeholder="Nhập vào tên chi tiết khuyến mại"
                      value={values.amount}
                      isRequired={true}
                      handleChange={handleChange}
                      handleBlur={handleBlur}
                      error={errors?.amount}
                      touched={touched?.amount}
                    />
                  </Col>
                </Row>
                <Row>
                  <Col xs={12}>
                    <SelectCustom
                      name="promotion"
                      label="Chọn chương trình khuyến mại"
                      placeholder="Lựa chọn"
                      value={promotionValue}
                      isRequired={true}
                      isClearable={true}
                      handleChange={(e) => {
                        setPromotionValue(e);
                        setFieldValue('promotion', e?.value ?? null);
                        setFieldValue('startDate', null);
                        setFieldValue('endDate', null);
                      }}
                      handleBlur={handleBlur}
                      onInputChange={(value) => {
                        setPromotionSearch(value);
                      }}
                      error={errors.promotion}
                      touched={touched.promotion}
                      options={resultsPromotion.map((item) => ({
                        label: item?.name ?? '',
                        value: item?.id ?? -1,
                        startDate: !!item?.startDate
                          ? new Date(item.startDate)
                          : null,
                        endDate: !!item?.endDate
                          ? new Date(item.endDate)
                          : null,
                      }))}
                    />
                  </Col>
                </Row>
                <Row>
                  <Col xs={12}>
                    <LabelCustom
                      isRequired={true}
                      label={'Thời gian'}
                      id={'start'}
                    />
                    <div className="d-flex justify-content-between">
                      <div className="d-flex align-items-center">
                        <span
                          style={{ minWidth: 60 }}
                          className="mr-1 text-right"
                        >
                          Bắt đầu:
                        </span>
                        <div className="date-picker-custom row-date-picker-custom d-flex flex-column">
                          <DatePicker
                            customInput={<CustomInputCalendar />}
                            wrapperClassName="icon-calendar"
                            dateFormat={'dd/MM/yyyy'}
                            selected={values.startDate}
                            minDate={promotionValue?.startDate}
                            maxDate={promotionValue?.endDate}
                            disabled={!promotionValue}
                            onChange={(date) => {
                              if (
                                moment(values.endDate).unix() <
                                moment(date).unix()
                              )
                                return;
                              const startOfDate = moment(date)
                                .startOf('date')
                                .toDate();
                              setFieldValue('startDate', startOfDate);
                            }}
                          />
                          {errors.startDate && !!touched.startDate && (
                            <HelperText message={errors.startDate} />
                          )}
                        </div>
                      </div>
                      <div className="d-flex align-items-center">
                        <span
                          style={{ minWidth: 60 }}
                          className="mr-1 text-right"
                        >
                          Kết thúc:
                        </span>
                        <div className="date-picker-custom row-date-picker-custom d-flex flex-column">
                          <DatePicker
                            customInput={<CustomInputCalendar />}
                            wrapperClassName="icon-calendar"
                            dateFormat={'dd/MM/yyyy'}
                            selected={values.endDate}
                            minDate={promotionValue?.startDate}
                            maxDate={promotionValue?.endDate}
                            disabled={!promotionValue}
                            onChange={(date) => {
                              if (
                                moment(values.startDate)
                                  .startOf('date')
                                  .unix() > moment(date).startOf('date').unix()
                              )
                                return;
                              const endOfDate = moment(date)
                                .endOf('date')
                                .toDate();
                              setFieldValue('endDate', endOfDate);
                            }}
                          />
                          {errors.endDate && !!touched.endDate && (
                            <HelperText message={errors.endDate} />
                          )}
                        </div>
                      </div>
                    </div>
                  </Col>
                </Row>

                <div className="mb-0 pb-0 mt-4 d-flex justify-content-end">
                  <Button
                    className="text-nowrap mr-1"
                    onClick={() => {
                      handleClose();
                    }}
                  >
                    Hủy
                  </Button>
                  <LoadingButtonCustom
                    onClick={handleSubmit}
                    color="info"
                    className="text-nowrap "
                    type="submit"
                    loading={
                      isCreatingPromotionDetail || isUpdatingPromotionDetail
                    }
                  >
                    {isUpdate ? 'Cập nhật' : 'Thêm mới'}
                  </LoadingButtonCustom>
                </div>
              </Form>
            );
          }}
        </Formik>
      </div>
    </Modal>
  );
};

export default memo(CUPromotionDetail);
