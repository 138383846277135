import React, { memo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Form, Modal } from 'reactstrap';
import { Formik } from 'formik';
import _ from 'lodash';

import { isAccountantStaff, notificationAlertRef, notify } from 'common';
import { formActions, orderActions, promotionActions } from 'redux/actions';
import queryString from 'query-string';
import LoadingButtonCustom from 'views/pages/components/LoadingButtonCustom';
import CONSTANT from 'constant';
import FileDownload from 'js-file-download';
import moment from 'moment';
import { useLocation } from 'react-router';

const ExportOrder = ({ q = () => { } }) => {
  const location = useLocation();
  const dispatch = useDispatch();
  const { isModalVisible, formType } = useSelector(
    (state) => state.formReducer
  );
  const { isCreateAccount, isUpdateAccount } = useSelector(
    (state) => state.accountReducer
  );
  const {
    currentAccountExtend,
    isAdmin,
    isSampleCollectorStaff,
    isBusinessStaff,
  } = useSelector((state) => state.accountReducer);
  const { queryUnpaid, querySynthetic } = useSelector(
    (state) => state.orderReducer
  );
  console.log(querySynthetic);
  //Xuất danh sách đơn hàng tổng hợp
  const handleExportOrder = (otherQuery) => {
    const query = _.cloneDeep(querySynthetic);
    for (const key in query) {
      if (query[key] === null || query[key] === '') {
        delete query[key];
      }
    }

    if (!isAdmin && isSampleCollectorStaff) {
      query['filters[$and][11][codeStaff2][id][$eq]'] =
        currentAccountExtend?.id;
    } else if (!isAdmin && isBusinessStaff) {
      query['filters[$and][11][codeStaff1][id][$eq]'] =
        currentAccountExtend?.id;
    }

    dispatch(
      orderActions.exportOrder(
        queryString.stringify(
          {
            ...query,
            'sort[0]': 'createdAt:DESC',
            type: 'order-all',
            ...otherQuery,
          },
          { arrayFormat: 'bracket' }
        ),
        {
          success: (data) => {
            handleDownloadFile(
              data,
              `Danh_sach_don_hang_${moment().unix()}.xlsx`
            );
          },
          failed: handleExportFailed,
        }
      )
    );
  };
  // Xuất danh sách đơn hàng chờ thanh toán
  const handleExportOrderUnpaid = (otherQuery) => {
    const query = _.cloneDeep(queryUnpaid);
    for (const key in query) {
      if (query[key] === null || query[key] === '') {
        delete query[key];
      }
    }
    dispatch(
      orderActions.exportOrder(
        queryString.stringify(
          {
            type: 'order-unpaid',

            'sort[0]': 'createdAt:DESC',
            ...query,
            ...otherQuery,
          },
          { arrayFormat: 'bracket', skipEmptyString: true, skipNull: true }
        ),
        {
          success: (data) => {
            handleDownloadFile(
              data,
              `Danh_sach_don_hang_cho_thanh_toan_${moment().unix()}.xlsx`
            );
          },
          failed: handleExportFailed,
        }
      )
    );
  };
  // data
  const handleExportFailed = (mess) => {
    notify(
      notificationAlertRef,
      'danger',
      'Thông báo',
      `Xuất danh sách thất bại. Lỗi: ${mess}`
    );
  };
  const handleDownloadFile = (data, filename) => {
    FileDownload(data, filename);
    notify(
      notificationAlertRef,
      'success',
      'Thông báo',
      `Xuất danh sách thành công!`
    );
  };
  const onSubmit = (values) => {
    const headers = [];
    const keys = [];
    Object.keys(values)
      .filter((key) => {
        const item = values[`${key}`];
        return item.isActive;
      })
      .map((key) => {
        headers.push(values[`${key}`].header);
        keys.push(values[`${key}`].key);
        return values[`${key}`];
      });

    const query = {
      fieldNames: headers,
      fieldVariables: keys,
      ...q(),
    };
    if (location.pathname.includes('/admin/order-unpaid')) {
      handleExportOrderUnpaid(query);
    } else if (location.pathname.includes('/admin/order')) {
      dispatch(
        formActions.setValue({
          name: 'isModalVisible',
          value: true,
        })
      );
      handleExportOrder(query);
    }
  };

  const handleClose = () => {
    dispatch(
      formActions.setValue({
        name: 'isModalVisible',
        value: false,
      })
    );
    dispatch(
      formActions.setValue({
        name: 'modalInfo',
        value: {},
      })
    );
  };
  const keys = {};
  CONSTANT.ORDER_EXPORT.map((item) => {
    keys[`${item.key}`] = {
      ...item,
      isActive: false,
    };
  });
  CONSTANT.SPECIFYVOTE_EXPORT.map((item) => {
    keys[`${item.key}`] = {
      ...item,
      isActive: false,
    };
  });
  return (
    <Modal
      style={{
        minWidth: 800,
      }}
      isOpen={isModalVisible && formType === 0}
      className="modal-dialog-centered modal-secondary"
      toggle={() => {
        handleClose();
      }}
    >
      <div className="modal-body bg-white rounded py-0">
        <Formik
          initialValues={keys}
          enableReinitialize
          onSubmit={(values, { resetForm }) => {
            onSubmit(values, resetForm);
          }}
        >
          {({ values, handleSubmit, errors, touched, setFieldValue }) => {
            return (
              <>
                <div
                  className="bg-white d-flex py-2 border-bottom"
                  style={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                    width: '100%',
                  }}
                >
                  <span style={{ fontSize: 20, fontWeight: 600 }}>
                    Chọn trường dữ liệu để xuất
                  </span>
                  <label
                    style={{
                      fontWeight: 'bold',
                      whiteSpace: 'nowrap',
                      display: 'flex',
                      alignItems: 'center',
                      cursor: 'pointer',
                      fontSize: 24,
                    }}
                  >
                    <input
                      type="checkbox"
                      onChange={(e) => {
                        // setFieldValue(_.get(item, "key", ""), e.target.checked);
                        const keys = Object.keys(values);
                        keys.forEach((key) => {
                          setFieldValue(`${key}.isActive`, e.target.checked);
                        });
                      }}
                    />
                    &nbsp;
                    <span
                      style={{
                        textTransform: 'capitalize',
                      }}
                    >
                      Tất cả
                    </span>
                  </label>
                </div>
                <Form className="pb-3 px-0 form-add-account bg-white">
                  <div
                    style={{
                      display: 'flex',
                      flexWrap: 'wrap',
                      justifyContent: 'space-around',
                    }}
                  >
                    {CONSTANT.ORDER_EXPORT.map((item) => {
                      const key = _.get(item, 'key', '');
                      return (
                        <label
                          style={{
                            fontWeight: 'bold',
                            whiteSpace: 'nowrap',
                            flexShrink: 1,
                            flexBasis: 200,
                            display: 'flex',
                            alignItems: 'center',
                            marginBottom: 8,
                            cursor: 'pointer',
                            fontSize: 24,
                          }}
                        >
                          <input
                            type="checkbox"
                            checked={_.get(values, `${key}.isActive`, false)}
                            onChange={(e) => {
                              setFieldValue(key, {
                                ...item,
                                isActive: e.target.checked,
                              });
                            }}
                          />
                          &nbsp;
                          <span
                            style={{
                              textTransform: 'capitalize',
                            }}
                          >
                            {_.get(item, 'header', '').toLowerCase()}
                          </span>
                        </label>
                      );
                    })}
                  </div>
                  <span style={{ fontSize: 16, fontWeight: 600 }}>
                    Thông tin phiếu xét nghiệm
                  </span>
                  <div
                    style={{
                      display: 'flex',
                      flexWrap: 'wrap',
                      justifyContent: 'space-around',
                    }}
                  >
                    {CONSTANT.SPECIFYVOTE_EXPORT.map((item) => {
                      const key = _.get(item, 'key', '');
                      return (
                        <label
                          style={{
                            fontWeight: 'bold',
                            whiteSpace: 'nowrap',
                            flexShrink: 1,
                            flexBasis: 200,
                            display: 'flex',
                            alignItems: 'center',
                            marginBottom: 8,
                            cursor: 'pointer',
                            fontSize: 24,
                          }}
                        >
                          <input
                            type="checkbox"
                            checked={_.get(values, `${key}.isActive`, false)}
                            onChange={(e) => {
                              setFieldValue(key, {
                                ...item,
                                isActive: e.target.checked,
                              });
                            }}
                          />
                          &nbsp;
                          <span
                            style={{
                              textTransform: 'capitalize',
                            }}
                          >
                            {_.get(item, 'header', '').toLowerCase()}
                          </span>
                        </label>
                      );
                    })}
                  </div>
                  <div className="mb-0 pb-0 mt-4 d-flex justify-content-end">
                    <LoadingButtonCustom
                      onClick={() => {
                        handleSubmit();
                      }}
                      color="info"
                      className="text-nowrap "
                      // type="submit"
                      loading={isCreateAccount || isUpdateAccount}
                    >
                      Xác nhận
                    </LoadingButtonCustom>
                  </div>
                </Form>
              </>
            );
          }}
        </Formik>
      </div>
    </Modal>
  );
};

export default memo(ExportOrder);
