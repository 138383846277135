import React, { memo, useEffect, useState } from 'react';
import _ from 'lodash';
import { Card, CardBody, CardHeader, Col, Row } from 'reactstrap';
import { useDispatch, useSelector } from 'react-redux';

import 'assets/css/custom-pages/react-bs-table.css';
import CONSTANT from 'constant';
import LabelValue from 'views/pages/components/LabelValue';
import { currencyFormat } from 'common';

const OrderInfo = () => {
  const { order } = useSelector((state) => state.orderReducer);
  const statusValue = CONSTANT.ORDER_STATUS.find(
    (item) => item.value === order.status
  );

  return (
    <Card>
      <CardHeader className="pb-2 d-flex justify-content-between rounded border-bottom">
        <h3>Thông tin cơ bản đơn hàng</h3>
      </CardHeader>
      <CardBody>
        <Row>
          <Col xs={12} md={6}>
            <div className="w-50">
              <LabelValue
                labelWidth={160}
                label="Mã đơn hàng"
                value={order?.code ?? ''}
              />
            </div>
          </Col>

          <Col xs={12} md={6}>
            <LabelValue
              labelWidth={160}
              label="Mã Barcode"
              value={order?.barcode1 ?? ''}
            />
          </Col>
        </Row>
        <Row>
          <Col xs={12} md={6}>
            <LabelValue
              labelWidth={160}
              label="Tên đơn hàng"
              value={order?.name ?? ''}
            />
          </Col>
          <Col xs={12} md={3}>
            <LabelValue
              labelWidth={160}
              label="Mã Barcode mẫu 1"
              value={order?.barcode2 ?? ''}
            />
          </Col>
          <Col xs={12} md={3}>
            <LabelValue
              labelWidth={160}
              label="Mã Barcode mẫu 2"
              value={order?.barcode3 ?? ''}
            />
          </Col>
        </Row>
        <Row>
          <Col xs={12} md={6}>
            <LabelValue
              labelWidth={160}
              label="Phòng khám/Bệnh viện"
              value={order?.organization?.name ?? ''}
            />
          </Col>
          <Col xs={12} md={3}>
            <LabelValue
              labelWidth={160}
              label="Mã Barcode mẫu 3"
              value={order?.barcode4 ?? ''}
            />
          </Col>
          <Col xs={12} md={3}>
            <LabelValue
              labelWidth={160}
              label="Mã Barcode mẫu 4"
              value={order?.barcode5 ?? ''}
            />
          </Col>
        </Row>
        <Row>
          <Col xs={12} md={6}>
            <LabelValue
              labelWidth={160}
              label="Số tiền đã thu"
              value={`${currencyFormat(Number(order?.amountMoney ?? 0))} VNĐ`}
            />
          </Col>
          <Col xs={12} md={6}>
            <LabelValue
              labelWidth={160}
              label="Mã khuyến mại"
              value={order?.promotionDetail?.code}
            />
          </Col>
        </Row>
        <Row>
          <Col xs={12} md={6}>
            <LabelValue
              labelWidth={160}
              label="Nhân viên phụ trách"
              value={`${order?.codeStaff1?.fullname ?? ''}`}
            />
          </Col>
        </Row>
        <Row>
          <Col xs={12} md={6}>
            <LabelValue
              labelWidth={160}
              label="Người thu tiền"
              value={`${order?.codeStaff3?.fullname ?? ''}`}
            />
          </Col>
          <Col xs={12} md={6}>
            <LabelValue
              labelWidth={160}
              label="Ghi chú"
              value={order?.note ?? ''}
            />
          </Col>
        </Row>
        <Row>
          <Col xs={12} md={6}>
            <LabelValue
              labelWidth={160}
              label="Nhân viên thu mẫu"
              value={`${order?.codeStaff2?.fullname ?? ''}`}
            />
          </Col>
          <Col xs={12} md={6}>
            <LabelValue
              labelWidth={160}
              label="Trạng thái"
              value={statusValue?.label ?? ''}
            />
          </Col>
        </Row>
      </CardBody>
    </Card>
  );
};

export default memo(OrderInfo);
