//core-componens
import React, { useCallback, useEffect, useState } from 'react';
import {
  Button,
  Card,
  CardBody,
  CardHeader,
  Col,
  Container,
  Input,
  Row,
} from 'reactstrap';
import Select from 'react-select';
import DatePicker from 'react-datepicker';
//custom components
import LabelCustom from 'views/pages/components/LabelCustom';
import InputCustom from 'views/pages/components/InputCustom';
import SelectCustom from 'views/pages/components/SelectCustom';
//module
import CONSTANT from 'constant';
import classNames from 'classnames';
import * as yup from 'yup';
import _, { values } from 'lodash';
import queryString from 'query-string';
import { getListSelectOption } from 'common';
import { notificationAlertRef, notify } from 'common';
//styles
import 'assets/css/custom-pages/specify-votes.css';
import 'assets/css/custom-pages/create-specify-votes.css';
import { Formik, useFormikContext } from 'formik';
import { useDispatch, useSelector } from 'react-redux';
import { useDelayInputEffect } from 'utils/HookUtils';
import {
  accountActions,
  organizationActions,
  patientActions,
  relationActions,
  serviceActions,
} from 'redux/actions';
import HelperText from 'views/pages/components/HelperText';
import LabelValue from 'views/pages/components/LabelValue';
const rightAlignText = {
  textAlign: 'right',
  paddingRight: 0,
};
const moment = require('moment');
function GeneralInfomationLeft({
  isCreatePatient,
  setIsCreatePatient,
  patient,
}) {
  return (
    <Col
      xs={12}
      className="custom-row pr-0 pl-4"
      style={{
        borderRight: '1px solid rgba(0, 0, 0, 0.1)',
      }}
    >
      {/* <Row>
              <Col xs={12} className="pr-0">
                <LabelCustom
                  label="Thông tin cơ bản"
                  style={{ fontSize: '17px' }}
                />
              </Col>
            </Row> */}
      <Row>
        <Col
          xs={3}
          className="customSelect disable-react-select__dropdown-indicator"
        >
          <div className="pl-0">
            <LabelValue
              lableTextAlign={'right'}
              label={'Mã bệnh nhân'}
              labelWidth={110}
              value={patient?.code ?? 'Không có'}
              row={true}
            />
          </div>
        </Col>
        <Col xs={3}>
          <LabelValue
            lableTextAlign={'right'}
            label={'Họ tên'}
            labelWidth={110}
            value={patient?.name ?? 'Không có'}
            row={true}
          />
        </Col>
        <Col xs={3} style={{ marginRight: '-8px' }}>
          <div
            className="d-flex align-items-center "
            style={{ position: 'relative' }}
          >
            <LabelValue
              lableTextAlign={'right'}
              label={'Ngày sinh'}
              labelWidth={110}
              value={patient?.dateOfBirth ?? 'Không có'}
              row={true}
            />
          </div>
        </Col>
        <Col xs={3} className="customSelect pr-2">
          <div className="">
            <LabelValue
              lableTextAlign={'right'}
              label={'Giới tính'}
              labelWidth={128}
              value={
                _.find(CONSTANT.ACCOUNT_GENDER, {
                  value: patient?.gender,
                })?.label ?? ''
              }
              row={true}
            />
          </div>
        </Col>
      </Row>
      <Row>
        <Col xs={3}>
          <LabelValue
            lableTextAlign={'right'}
            label={'Email'}
            labelWidth={110}
            value={patient?.email ?? 'Không có'}
            row={true}
          />
        </Col>
        <Col xs={3}>
          <LabelValue
            lableTextAlign={'right'}
            label={'Số điện thoại'}
            labelWidth={120}
            value={patient?.phone ?? 'Không có'}
            row={true}
          />
        </Col>
        <Col xs={3}>
          <LabelValue
            lableTextAlign={'right'}
            label={'Nghề nghiệp'}
            labelWidth={110}
            value={patient?.job ?? 'Không có'}
            row={true}
          />
        </Col>
      </Row>
      <Row>
        <Col xs={3}>
          <LabelValue
            lableTextAlign={'right'}
            label={'Người liên hệ'}
            labelWidth={110}
            value={patient?.nameContact ?? 'Không có'}
            row={true}
          />
        </Col>
        <Col xs={3}>
          <LabelValue
            lableTextAlign={'right'}
            label={'Số điện thoại'}
            labelWidth={120}
            value={patient?.phoneContact ?? 'Không có'}
            row={true}
          />
        </Col>
      </Row>
      <Row>
        <Col xs={12}>
          <LabelValue
            lableTextAlign={'right'}
            label={'Địa chỉ bệnh nhân'}
            labelWidth={110}
            value={patient?.address ?? 'Không có'}
            row={true}
          />
        </Col>
      </Row>
    </Col>
  );
}

export default GeneralInfomationLeft;
