import React, { memo, useEffect, useState } from 'react';
import paginationFactory from 'react-bootstrap-table2-paginator';
import _ from 'lodash';
import { Container, Row, UncontrolledTooltip } from 'reactstrap';
import queryString from 'query-string';

import { checkRole, notificationAlertRef, notify } from 'common';
import { areaActions, filterActions, formActions } from 'redux/actions';
import SimpleHeader from 'components/Headers/SimpleHeader.js';
import 'assets/css/custom-pages/react-bs-table.css';
import { useDispatch, useSelector } from 'react-redux';
import CUArea from './components/CUArea';
import ModalWarningCustom from 'views/pages/components/ModalWarningCustom';

import IconEdit from 'assets/svgs/edit.svg';
import IconBin from 'assets/svgs/bin.svg';
import IconAdd from 'assets/svgs/add.svg';
import IconEye from 'assets/svgs/eye.svg';
import TableCustom from '../components/TableCustom';
import { Link, useHistory } from 'react-router-dom';

function Area({ location }) {
  const dispatch = useDispatch();
  const history = useHistory();
  const { page, limit, areas, isDeletingArea } = useSelector(
    (state) => state.areaReducer
  );
  const { currentAccountExtend } = useSelector((state) => state.accountReducer);

  const results = _.get(areas, 'results', []);
  const total = _.get(areas, 'pagination.total', 0);

  const [areaSearch, setAreaSearch] = useState('');
  const [notificationModal, setNotificationModal] = useState(false);
  const [area, setArea] = useState(null);

  const columns = [
    {
      dataField: 'code',
      text: 'MÃ KHU VỰC',
    },
    {
      dataField: 'name',
      text: 'TÊN KHU VỰC',
    },
    {
      dataField: 'children',
      text: 'CẤP CON',
      formatter: (cell, row) => {
        return cell.map((item, index) => (
          <span
            key={index}
            style={{ color: '#4690CD' }}
            onClick={() => {
              history.push(
                `/admin/area/${item?.id ?? ''}?code=${item.code}&parent-name=${row?.name ?? ''
                }`
              );
            }}
          >
            {item.code}
            {index === cell.length - 1 ? '. ' : ', '}
          </span>
        ));
      },
    },
    {
      dataField: 'descriptions',
      text: 'MÔ TẢ',
    },
    {
      dataField: 'actions',
      text: 'HÀNH ĐỘNG',
      formatter: boxActions,
      headerStyle: {
        textAlign: 'center',
      },
      headerClasses: 'fixed-column',
      classes: 'fixed-column',
    },
  ];

  function boxActions(__, row) {
    return (
      <div className="d-flex justify-content-center">
        {checkRole(currentAccountExtend, {
          roles: ['create-area'],
        }) && (
            <>
              <button
                id={'add' + row.id}
                onClick={() => {
                  dispatch(
                    formActions.setValue({
                      name: 'modalInfo',
                      value: {
                        area: {
                          id: row?.id ?? '',
                          code: row?.code ?? '',
                          name: row?.name ?? '',
                        },
                        isAddAction: true,
                      },
                    })
                  );
                  dispatch(
                    formActions.setValue({
                      name: 'isModalVisible',
                      value: true,
                    })
                  );
                }}
                className="btn p-0 btn-no-shadow mr-0"
              >
                <img src={IconAdd} className="mr-1" />
              </button>
              <UncontrolledTooltip
                delay={1}
                placement="top"
                target={'add' + row.id}
              >
                Thêm khu vực con
              </UncontrolledTooltip>
            </>
          )}
        <button
          id={'view' + row.id}
          onClick={() => {
            history.push(
              `/admin/area/${row?.id ?? ''}?code=${row.code}&parent-name=${''}`
            );
          }}
          className="btn p-0 btn-no-shadow mr-0"
        >
          <img src={IconEye} className="mr-1" />
        </button>
        <UncontrolledTooltip delay={1} placement="top" target={'view' + row.id}>
          Xem chi tiết khu vực
        </UncontrolledTooltip>
        {checkRole(currentAccountExtend, {
          roles: ['update-area'],
        }) && (
            <>
              <button
                id={'edit' + row.id}
                onClick={() => {
                  dispatch(
                    formActions.setValue({
                      name: 'modalInfo',
                      value: row,
                    })
                  );
                  dispatch(
                    formActions.setValue({
                      name: 'isModalVisible',
                      value: true,
                    })
                  );
                }}
                className="btn p-0 btn-no-shadow mr-0"
              >
                <img src={IconEdit} className="mr-1" />
              </button>
              <UncontrolledTooltip
                delay={1}
                placement="top"
                target={'edit' + row.id}
              >
                Cập nhật khu vực
              </UncontrolledTooltip>
            </>
          )}
        {checkRole(currentAccountExtend, {
          roles: ['delete-area'],
        }) && (
            <>
              <button
                id={'lock' + row.id}
                onClick={() => {
                  setArea(row);
                  setNotificationModal(true);
                }}
                className="btn p-0 btn-no-shadow mr-0"
              >
                <img src={IconBin} />
              </button>
              <UncontrolledTooltip
                delay={1}
                placement="top"
                target={'lock' + row.id}
              >
                Xóa khu vực
              </UncontrolledTooltip>
            </>
          )}
      </div>
    );
  }

  const onSizePerPageChange = (e) => {
    dispatch(areaActions.setValueArea({ name: 'page', value: 1 }));
    dispatch(
      areaActions.setValueArea({ name: 'limit', value: e.target.value })
    );
  };

  const onPageChange = (value) => {
    dispatch(areaActions.setValueArea({ name: 'page', value: value }));
  };

  const pagination = paginationFactory({
    page: page,
    sizePerPage: limit,
    totalSize: total,
    showTotal: false,
    withFirstAndLast: false,
    alwaysShowAllBtns: true,
    onPageChange: onPageChange,
    sizePerPageRenderer: () => {
      return (
        <div className="dataTables_length" id="datatable-basic_length">
          <label>
            Hiển thị{' '}
            {
              <select
                name="datatable-basic_length"
                aria-controls="datatable-basic"
                className="form-control form-control-sm"
                onChange={onSizePerPageChange}
              >
                <option value="10">10</option>
                <option value="25">25</option>
                <option value="50">50</option>
                <option value="100">100</option>
              </select>
            }{' '}
            dòng.
          </label>
        </div>
      );
    },
  });
  useEffect(() => {
    // Sau 500ms thì bắn api
    const delayDebounceFn = setTimeout(() => {
      handleGetAreas();
    }, 500);
    return () => clearTimeout(delayDebounceFn);
  }, [areaSearch, limit, page]);

  const handleGetAreas = () => {
    const query = {
      pageSize: limit,
      page,
      'filters[$and][1][isOrigin][$eq]': true,
    };
    if (areaSearch !== '') {
      query['_q'] = areaSearch;
    }
    dispatch(filterActions.setValueFilter({
      name: 'queryPage',
      value: query
    }))
    dispatch(areaActions.getAreas(queryString.stringify(query)));
  };

  const handleDelete = () => {
    if (area === null) return;
    if ((area?.children ?? []).length > 0) {
      return notify(
        notificationAlertRef,
        'warning',
        'Thông báo',
        `Khu vực có các khu vực trực thuộc. Không được xóa!`
      );
    }
    if ((area?.accountsExtend?.count ?? 0) > 0) {
      return notify(
        notificationAlertRef,
        'warning',
        'Thông báo',
        `Khu vực có tài khoản trực thuộc. Không được xóa!`
      );
    }
    if ((area?.organizations?.count ?? 0) > 0) {
      return notify(
        notificationAlertRef,
        'warning',
        'Thông báo',
        `Khu vực có tổ chức trực thuộc. Không được xóa!`
      );
    }
    dispatch(
      areaActions.updateArea(
        area.id,
        { deleted: true },
        {
          success: () => {
            setNotificationModal(false);
            notify(
              notificationAlertRef,
              'success',
              'Thông báo',
              `Xóa khu vực ${area?.name} thành công!`
            );
            handleGetAreas();
          },
          failed: (mess) => {
            notify(
              notificationAlertRef,
              'danger',
              'Thông báo',
              `Xóa khu vực ${area?.name} thất bại!. Lỗi: ${mess}`
            );
          },
        }
      )
    );
  };

  return (
    <>
      <SimpleHeader />
      <CUArea handleGetAreasIndex={handleGetAreas} />
      <ModalWarningCustom
        notificationModal={notificationModal}
        setNotificationModal={setNotificationModal}
        name={`khu vực ${area?.name ?? ''}`}
        func={handleDelete}
        isDelete={isDeletingArea}
      />
      <Container className="mt--9" fluid>
        <Row>
          <div className="col">
            <TableCustom
              tableName="Danh sách khu vực"
              data={results}
              columns={columns}
              page={page}
              limit={limit}
              total={total}
              searchBy={'Tìm kiếm'}
              pagination={pagination}
              search={areaSearch}
              onChange={(e) => {
                setAreaSearch(e.target.value);
              }}
            />
          </div>
        </Row>
      </Container>
    </>
  );
}

export default memo(Area);
