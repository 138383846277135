import React, { memo, useEffect, useMemo, useState } from 'react';
import paginationFactory from 'react-bootstrap-table2-paginator';
import _ from 'lodash';
import {
  Badge,
  Container,
  FormGroup,
  Row,
  UncontrolledTooltip,
} from 'reactstrap';

import { checkRole, notificationAlertRef, notify } from 'common';
import {
  accountActions,
  areaActions,
  filterActions,
  formActions,
  roleActions,
} from 'redux/actions';
import SimpleHeader from 'components/Headers/SimpleHeader.js';
import 'assets/css/custom-pages/react-bs-table.css';
import { useDispatch, useSelector } from 'react-redux';
import CONSTANT from 'constant';
import IconEdit from 'assets/svgs/edit.svg';
import IconBin from 'assets/svgs/bin.svg';
import IconKey from 'assets/svgs/key.svg';
import IconEye from 'assets/svgs/eye.svg';
import queryString from 'query-string';
import CUAccount from './components/CUAccount';
import ModalWarningCustom from 'views/pages/components/ModalWarningCustom';
import { useDidUpdateEffect } from 'utils/HookUtils';
import TableCustom from 'views/pages/components/TableCustom';
import DAccount from './components/DAccount';
import ImportAccount from './components/ImportAccount';
import SelectCustom from '../components/SelectCustom';
import classNames from 'classnames';

function Account() {
  const [notificationModal, setNotificationModal] = useState(false);
  const [account, setAccount] = useState(null);
  const [searchAccount, setSearchAccount] = useState('');
  const [roleValue, setRoleValue] = useState('');
  const [areaValue, setAreaValue] = useState('');
  const [isVisibleD, setIsVisibleD] = useState(false);
  const [roleSearch, setRoleSearch] = useState('');
  const [areaSearch, setAreaSearch] = useState('');
  const [roleOptions, setRoleOptions] = useState([]);
  const [areaOptions, setAreaOptions] = useState([]);
  const { page, limit, accountsExtend, isDeleteAccount } = useSelector(
    (state) => state.accountReducer
  );
  const { currentAccountExtend } = useSelector((state) => state.accountReducer);
  const results = _.get(accountsExtend, 'results', []);
  const total = _.get(accountsExtend, 'pagination.total', 0);
  const dispatch = useDispatch();

  const columns = [
    {
      dataField: 'email',
      text: 'TÊN ĐĂNG NHẬP',
      style: {
        maxWidth: 200,
      },
      headerStyle: {
        maxWidth: 200,
      },
    },
    {
      dataField: 'adminUser.lastname',
      text: 'HỌ',
      style: {
        maxWidth: 200,
      },
      headerStyle: {
        maxWidth: 200,
      },
    },
    {
      dataField: 'adminUser.firstname',
      text: 'TÊN',
      style: {
        maxWidth: 200,
      },
      headerStyle: {
        maxWidth: 200,
      },
    },
    {
      dataField: 'decentralization.name',
      text: 'NHÓM QUYỀN',
    },
    {
      dataField: 'accountType',
      text: 'PHÂN LOẠI',
      formatter: (cell) => {
        const typeValue = CONSTANT.ACCOUNT_TYPE.find(
          (item) => item.value === cell
        );
        return typeValue?.label ?? 'Khác';
      },
    },
    {
      dataField: 'phone',
      text: 'SỐ ĐIỆN THOẠI',
    },
    {
      dataField: 'address',
      text: 'ĐỊA CHỈ',
      style: {
        maxWidth: 200,
      },
      headerStyle: {
        maxWidth: 200,
      },
    },
    {
      dataField: 'isActive',
      text: 'TRẠNG THÁI',
      formatter: (cell) => {
        if (cell) {
          return <Badge color="success">Hoạt động</Badge>;
        }
        return <Badge color="danger">Khóa</Badge>;
      },
      headerStyle: {
        textAlign: 'center',
      },
      style: {
        textAlign: 'center',
      },
    },
    {
      dataField: 'actions',
      text: 'HÀNH ĐỘNG',
      formatter: boxActions,
      headerStyle: {
        textAlign: 'center',
      },
      headerClasses: 'fixed-column',
      classes: 'fixed-column',
    },
  ];

  function boxActions(__, row) {
    return (
      <div className="d-flex justify-content-center">
        <button
          id={'view' + row.id}
          onClick={() => {
            setAccount(row);
            setIsVisibleD(true);
          }}
          className="btn p-0 btn-no-shadow mr-0"
        >
          <img src={IconEye} className="mr-1" />
        </button>
        <UncontrolledTooltip delay={1} placement="top" target={'view' + row.id}>
          Thông tin tài khoản
        </UncontrolledTooltip>

        {checkRole(currentAccountExtend, {
          roles: ['update-account'],
        }) && (
          <>
            <button
              id={'edit' + row.id}
              onClick={() => {
                dispatch(
                  formActions.setValue({
                    name: 'formType',
                    value: 1,
                  })
                );
                dispatch(
                  formActions.setValue({
                    name: 'modalInfo',
                    value: row,
                  })
                );
                dispatch(
                  formActions.setValue({
                    name: 'isModalVisible',
                    value: true,
                  })
                );
              }}
              className="btn p-0 btn-no-shadow mr-0"
            >
              <img src={IconEdit} className="mr-1" />
            </button>
            <UncontrolledTooltip
              delay={1}
              placement="top"
              target={'edit' + row.id}
            >
              Cập nhật tài khoản
            </UncontrolledTooltip>
          </>
        )}
        {checkRole(currentAccountExtend, {
          roles: ['delete-account'],
        }) && (
          <>
            <button
              id={'key' + row.id}
              onClick={() => {
                dispatch(
                  formActions.setValue({
                    name: 'formType',
                    value: 2,
                  })
                );
                dispatch(
                  formActions.setValue({
                    name: 'modalInfo',
                    value: { ...row, isConfigPassword: true },
                  })
                );
                dispatch(
                  formActions.setValue({
                    name: 'isModalVisible',
                    value: true,
                  })
                );
              }}
              className="btn p-0 btn-no-shadow mr-0"
            >
              <img src={IconKey} />
            </button>
            <UncontrolledTooltip
              delay={1}
              placement="top"
              target={'key' + row.id}
            >
              Thiết lập mật khẩu
            </UncontrolledTooltip>
          </>
        )}

        <button
          id={'lock' + row.id}
          onClick={() => {
            setAccount(row);
            setNotificationModal(true);
          }}
          className="btn p-0 btn-no-shadow mr-0"
        >
          <img src={IconBin} />
        </button>
        <UncontrolledTooltip delay={1} placement="top" target={'lock' + row.id}>
          Xóa tài khoản
        </UncontrolledTooltip>
      </div>
    );
  }

  const onSizePerPageChange = (e) => {
    dispatch(accountActions.setValue({ name: 'page', value: 1 }));
    dispatch(accountActions.setValue({ name: 'limit', value: e.target.value }));
  };

  const onPageChange = (value) => {
    dispatch(accountActions.setValue({ name: 'page', value: value }));
  };

  const pagination = paginationFactory({
    page: page,
    sizePerPage: limit,
    totalSize: total,
    showTotal: false,
    withFirstAndLast: false,
    alwaysShowAllBtns: true,
    onPageChange: onPageChange,
    sizePerPageRenderer: () => {
      return (
        <div className="dataTables_length" id="datatable-basic_length">
          <label>
            Hiển thị{' '}
            {
              <select
                name="datatable-basic_length"
                aria-controls="datatable-basic"
                className="form-control form-control-sm"
                onChange={onSizePerPageChange}
              >
                <option value="10">10</option>
                <option value="25">25</option>
                <option value="50">50</option>
                <option value="100">100</option>
              </select>
            }{' '}
            dòng.
          </label>
        </div>
      );
    },
  });

  const handleDelete = () => {
    if (account === null) return;

    const adminId = _.get(account, 'adminUser.id', -1);
    const id = _.get(account, 'id', -1);
    if ((account?.chidrent?.count ?? 0) > 0)
      return notify(
        notificationAlertRef,
        'warning',
        'Thông báo',
        `Tài khoản có cấp dưới, không được xóa!!`
      );
    if ((account?.order1s?.count ?? 0) > 0)
      return notify(
        notificationAlertRef,
        'warning',
        'Thông báo',
        `Tài khoản có đơn hàng phụ trách. Không được xoá!`
      );
    dispatch(
      accountActions.updateAccount(
        adminId,
        {
          isActive: false,
        },
        {
          success: () => {
            setNotificationModal(false);
            notify(
              notificationAlertRef,
              'success',
              'Thông báo',
              `Xóa tài khoản ${account.email} thành công!`
            );
            dispatch(
              accountActions.updateAccountExtend(
                id,
                {
                  deleted: true,
                  isActive: false,
                  organizationResponsibles: null,
                },
                {
                  success: () => {
                    handleGetAccountsExtend();
                  },
                }
              )
            );
          },
          failed: () => {
            notify(
              notificationAlertRef,
              'danger',
              'Thông báo',
              `Xóa tài khoản ${account.email} thất bại!`
            );
          },
        }
      )
    );
  };

  useEffect(() => {
    if (_.isEmpty(accountsExtend.results)) {
      handleGetAccountsExtend();
    }
  }, []);

  const handleGetAccountsExtend = () => {
    const query = { page, pageSize: limit, _q: searchAccount };
    if (!searchAccount) {
      delete query['_q'];
    }
    if (roleValue) {
      query['filters[$and][0][decentralization][id][$eq]'] = _.get(
        roleValue,
        'value'
      );
    }
    if (areaValue) {
      query['filters[$and][1][areas][id][$eq]'] = _.get(areaValue, 'value');
    }
    dispatch(
      filterActions.setValueFilter({
        name: 'queryPage',
        value: query,
      })
    );
    dispatch(
      accountActions.getAccountsExtend(
        queryString.stringify(query, {
          skipEmptyString: true,
          skipNull: true,
        })
      )
    );
  };

  useDidUpdateEffect(() => {
    const delayDebounceFn = setTimeout(() => {
      handleGetAccountsExtend();
    }, 500);

    return () => clearTimeout(delayDebounceFn);
  }, [searchAccount, roleValue, areaValue]);

  useDidUpdateEffect(() => {
    handleGetAccountsExtend();
  }, [page, limit]);

  const headerLeftComponent = () => {
    return (
      <div style={{ zIndex: 1000 }}>
        <FormGroup
          className="mb-1 flex"
          style={{
            display: 'flex',
          }}
        >
          <SelectCustom
            name="status"
            label="Chọn khu vực"
            placeholder="Nhập để tìm kiếm"
            value={areaValue}
            isRequired={false}
            isClearable={true}
            handleChange={(e) => {
              setAreaValue(e);
            }}
            options={areaOptions}
            onInputChange={(val) => {
              setAreaSearch(val);
            }}
            stylesWrapper={{
              marginRight: 16,
            }}
          />
          <SelectCustom
            name="status"
            label="Chọn nhóm quyền"
            placeholder="Nhập để tìm kiếm"
            value={roleValue}
            isRequired={false}
            isClearable={true}
            handleChange={(e) => {
              setRoleValue(e);
            }}
            options={roleOptions}
            onInputChange={(val) => {
              setRoleSearch(val);
            }}
          />
        </FormGroup>
      </div>
    );
  };

  useEffect(() => {
    const debounce = setTimeout(() => {
      const query = {};
      query['filters[$and][0][name][$containsi]'] = roleSearch;
      dispatch(
        roleActions.getRoles(queryString.stringify(query), {
          success: (data) => {
            const results = _.get(data, 'results', []);
            if (_.isArray(results))
              setRoleOptions(
                results.map((res) => {
                  return {
                    label: _.get(res, 'name', ''),
                    value: _.get(res, 'id', ''),
                  };
                })
              );
          },
          failed: (err) => {
            console.error(err);
          },
        })
      );
    }, 700);

    return () => clearTimeout(debounce);
  }, [roleSearch]);
  useEffect(() => {
    const debounce = setTimeout(() => {
      const query = {};
      query['filters[$and][1][name][$containsi]'] = areaSearch;
      dispatch(
        areaActions.getAreas(
          queryString.stringify(query),
          {
            success: (data) => {
              const results = _.get(data, 'results', []);
              if (_.isArray(results))
                setAreaOptions(
                  results.map((res) => {
                    return {
                      label: _.get(res, 'name', ''),
                      value: _.get(res, 'id', ''),
                    };
                  })
                );
            },
            failed: (err) => {
              console.error(err);
            },
          },
          false
        )
      );
    }, 700);

    return () => clearTimeout(debounce);
  }, [areaSearch]);
  return (
    <>
      <SimpleHeader />
      <CUAccount handleGetAccountsExtendIndex={handleGetAccountsExtend} />
      <ImportAccount handleGetAccountsExtendIndex={handleGetAccountsExtend} />
      <DAccount
        isVisibleD={isVisibleD}
        setIsVisibleD={setIsVisibleD}
        account={account}
        setAccount={setAccount}
      />
      <ModalWarningCustom
        notificationModal={notificationModal}
        setNotificationModal={setNotificationModal}
        name={`tài khoản ${account?.email ?? ''}`}
        func={handleDelete}
        isDelete={isDeleteAccount}
      />
      <Container className="mt--9" fluid>
        <Row>
          <div className="col">
            <TableCustom
              tableName="Danh sách tài khoản"
              data={results}
              columns={columns}
              page={page}
              limit={limit}
              total={total}
              searchBy={'Tìm kiếm'}
              pagination={pagination}
              search={searchAccount}
              headerLeftComponent={headerLeftComponent}
              onChange={(e) => {
                setSearchAccount(e.target.value);
              }}
            />
          </div>
        </Row>
      </Container>
    </>
  );
}

export default memo(Account);
