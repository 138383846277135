import React, { memo, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Button, Col, Form, Modal, Row } from 'reactstrap';
import * as yup from 'yup';
import { Formik } from 'formik';
import _ from 'lodash';
import DatePicker from 'react-datepicker';

import {
  isNumeric,
  notificationAlertRef,
  notify,
  relationOneToMany,
  relationOneToOne,
} from 'common';
import {
  formActions,
  accountActions,
  roleActions,
  uploadActions,
  organizationActions,
  areaActions,
} from 'redux/actions';
import HelperText from 'views/pages/components/HelperText';
import queryString from 'query-string';
import LoadingButtonCustom from 'views/pages/components/LoadingButtonCustom';
import CONSTANT from 'constant';
import { BASE_URL_IMAGE } from 'services/ServiceURL';
import InputCustom from 'views/pages/components/InputCustom';
import SelectCustom from 'views/pages/components/SelectCustom';
import LabelCustom from 'views/pages/components/LabelCustom';
import CustomInputCalendar from 'views/pages/components/CustomInputCalendar';

const CUAccount = ({ handleGetAccountsExtendIndex }) => {
  const dispatch = useDispatch();

  const { isModalVisible, formType, modalInfo } = useSelector(
    (state) => state.formReducer
  );
  const { isCreateAccount, isUpdateAccount } = useSelector(
    (state) => state.accountReducer
  );

  const isUpdate = !_.isEmpty(modalInfo);

  // data
  const [areas, setAreas] = useState(null);
  const [organizations, setOrganizations] = useState([]);
  const [organizationsOld, setOrganizationsOld] = useState([]);
  const [areasOld, setAreasOld] = useState([]);
  const [organizationsTouched, setOrganizationsTouched] = useState(false);
  const [accountsExtend, setAccountsExtend] = useState([]);
  const [staffInChargeResults, setStaffInChargeResults] = useState([]);
  const resultsArea = _.get(areas, 'results', []);
  const resultsAccountsExtend = _.get(accountsExtend, 'results', []);
  // Value select
  const [roleValue, setRoleValue] = useState(null);
  const [genderValue, setGenderValue] = useState(CONSTANT.ACCOUNT_GENDER[0]);
  const [isActiveValue, setIsActiveValue] = useState(
    CONSTANT.ACCOUNT_STATUS[0]
  );
  const [areasValue, setAreasValue] = useState(null);
  const [organizationsValue, setOrganizationsValue] = useState(null);
  const [isMarriedValue, setIsMarriedValue] = useState(CONSTANT.MARRIED[0]);
  const [parentValue, setParentValue] = useState(null);
  const [accountTypeValue, setAccountTypeValue] = useState(null);
  const [specialistGrValue, setSpecialistGrValue] = useState(null);
  const [channelValue, setChannelValue] = useState(null);
  const [staffInChargeValue, setStaffInChargeValue] = useState(null);

  // Tìm kiếm select
  const [areaSearch, setAreaSearch] = useState('');
  const [roleSearch, setRoleSearch] = useState('');
  const [accountExtendSearch, setAccountExtendSearch] = useState('');
  const [organizationSearch, setOrganizationSearch] = useState('');
  const [staffInChargeSearch, setStaffInChargeSearch] = useState('');

  // Dữ liệu của modal
  const {
    email,
    isActive,
    isMarried,
    accountType,
    gender,
    avatar,
    decentralization,
    parent,
    channel,
    specialistGr,
    isConfigPassword,
    staffInCharge,
  } = modalInfo;

  const avatarId = avatar?.id ?? '';
  const { roles } = useSelector((state) => state.roleReducer);
  const roleResults = _.get(roles, 'results', []);
  const initInfo = isConfigPassword
    ? {
        password: '',
        rePassword: '',
        adminId: _.get(modalInfo, 'adminUser.id', -1),
      }
    : {
        firstname: _.get(modalInfo, 'adminUser.firstname', ''),
        lastname: _.get(modalInfo, 'adminUser.lastname', ''),
        username: isUpdate ? email : '',
        email: _.get(modalInfo, 'email', ''),
        decentralization: _.get(modalInfo, 'decentralization.id', null),
        useSSORegistration: false,
        // account extend
        avatar: _.get(modalInfo, 'avatar.url', ''),
        address: _.get(modalInfo, 'address', ''),
        phone: _.get(modalInfo, 'phone', ''),
        code: _.get(modalInfo, 'code', ''),
        dateOfBirth: !_.isEmpty(modalInfo?.dateOfBirth)
          ? new Date(_.get(modalInfo, 'dateOfBirth', null))
          : null,
        citizenId: _.get(modalInfo, 'citizenId', ''),
        licenseDate: !_.isEmpty(modalInfo?.licenseDate)
          ? new Date(_.get(modalInfo, 'licenseDate', null))
          : null,
        isMarried: _.get(modalInfo, 'isMarried', false),
        position: _.get(modalInfo, 'position', null),
        specialistGr: _.get(modalInfo, 'specialistGr', null),
        accountType: _.get(modalInfo, 'accountType', null),
        licenseWhere: _.get(modalInfo, 'licenseWhere', ''),
        parent: _.get(modalInfo, 'parent.id', null),
        channel: _.get(modalInfo, 'channel', null),
        gender: _.get(modalInfo, 'gender', 0),
        workAddress: _.get(modalInfo, 'workAddress', ''),
        workWhere: _.get(modalInfo, 'workWhere', ''),
        specialist: _.get(modalInfo, 'specialist', ''),
        degree: _.get(modalInfo, 'degree', ''),
        isActive: _.get(modalInfo, 'isActive', true),
        id: _.get(modalInfo, 'id', ''),
        adminId: _.get(modalInfo, 'adminUser.id', -1),
        staffInCharge: _.get(modalInfo, 'staffInCharge.id', null),
      };
  const infoSchema = isConfigPassword
    ? yup.object().shape({
        password: yup
          .string()
          .required('Vui lòng nhập mật khẩu mới!')
          .test(
            'password',
            'Mật khẩu phải dài ít nhất 8 kí tự bao gồm chữ thường, chữ hoa và số!',
            (password) => {
              const reg = /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{8,}$/g;
              // /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/g;
              return reg.test(password);
            }
          ),
        rePassword: yup
          .string()
          .oneOf([yup.ref('password')], 'Mật khẩu không trùng khớp!')
          .required('Vui lòng xác nhận mật khẩu!'),
      })
    : yup.object().shape({
        code: yup.string().required('Mã tài khoản không được để trống!'),
        email: yup
          .string()
          .email('Email không đúng định dạng!')
          .required('Email không được để trống!'),
        decentralization: yup
          .number()
          .required('Quyền không được để trống!')
          .nullable(),
        firstname: yup.string().required('Tên không được để trống!'),
        phone: yup
          .string()
          .required('Số điện thoại không được để trống!')
          .matches(
            '0[1-9][0-9]+',
            'Vui lòng nhập đúng định dạng số điện thoại!'
          ),
        gender: yup.string().required('Giới tính không được để trống!'),
      });
  const onSubmit = (values) => {
    if (isUpdate) {
      // update avatar
      if (typeof values.avatar === 'object') {
        if (avatarId !== '')
          return dispatch(
            uploadActions.deleteFile(avatarId, {
              success: () => {
                handleUploadAvatar(values);
              },
              failed: (mess) => {
                handleUploadAvatar(values);
              },
            })
          );
        return handleUploadAvatar(values);
      } else {
        return handleCUAccount(values, avatarId);
      }
    } else {
      if (!values.avatar) {
        return handleCUAccount(values, '');
      }
      return handleUploadAvatar(values);
    }
  };

  const handleUploadAvatar = (values) => {
    const formData = new FormData();
    formData.append('files', values.avatar.file, values.avatar.file.name);
    dispatch(
      uploadActions.uploadFiles(formData, {
        success: (data) => {
          handleCUAccount(values, data[0].id ?? '');
        },
        failed: (mess) => {
          notify(
            notificationAlertRef,
            'danger',
            'Thông báo',
            `Tải ảnh lên thất bại!. Lỗi: ${mess}!`
          );
        },
      })
    );
  };

  const handleCUAccount = (values, avatarId) => {
    const {
      email,
      firstname,
      lastname,
      username,
      useSSORegistration,
      isActive,
      password,
    } = values;
    const body = _.cloneDeep({
      firstname: (firstname ?? '').trim(),
      isActive,
      username,
      lastname: (lastname ?? '').trim(),
      roles: [1],
    });

    if (isUpdate) {
      if (isConfigPassword) {
        return dispatch(
          accountActions.updateAccount(
            values.adminId ?? '',
            {
              password,
            },
            {
              success: () => {
                notify(
                  notificationAlertRef,
                  'success',
                  'Thông báo',
                  `Thiết lập mật khẩu thành công`
                );
                handleClose();
              },
              failed: (mess) => {
                notify(
                  notificationAlertRef,
                  'danger',
                  'Thông báo',
                  `Cập nhật tài khoản  thất bại!. Lỗi: ${mess}!`
                );
              },
            }
          )
        );
      }

      if (organizationsValue.length === 0) {
        return;
      }

      return dispatch(
        accountActions.updateAccount(values.adminId ?? '', body, {
          success: (data) => {
            handleCUAccountExtend(data.id, avatarId, values);
          },
          failed: (mess) => {
            notify(
              notificationAlertRef,
              'danger',
              'Thông báo',
              `Cập nhật tài khoản  thất bại!. Lỗi: ${mess}!`
            );
          },
        })
      );
    }
    if (organizationsValue.length === 0) {
      return;
    }
    delete body.isActive;
    delete body.username;
    if (_.isEmpty(lastname)) {
      body.lastname = '';
    }
    dispatch(
      accountActions.createAccount(
        { ...body, email, useSSORegistration },
        {
          success: (data) => {
            if (isActive) {
              // nếu trạng thái là hoạt động thì cần cập nhật isActive là true vì mặc định nó là false
              dispatch(
                accountActions.updateAccount(
                  data.id,
                  { isActive: isActive },
                  {
                    success: () => {
                      handleCUAccountExtend(data.id, avatarId, values);
                    },
                  }
                )
              );
            } else {
              handleCUAccountExtend(data.id, avatarId, values);
            }
          },
          failed: (mess) => {
            notify(
              notificationAlertRef,
              'danger',
              'Thông báo',
              `Thêm mới tài khoản thất bại!. Lỗi: ${mess}!`
            );
          },
        }
      )
    );
  };
  const handleCUAccountExtend = (adminId, avatarId, values) => {
    const {
      firstname,
      lastname,
      channel,
      gender,
      accountType,
      position,
      specialistGr,
      isMarried,
      parent,
      email,
      address,
      phone,
      code,
      dateOfBirth,
      citizenId,
      licenseDate,
      licenseWhere,
      workAddress,
      workWhere,
      specialist,
      degree,
      isActive,
      id,
      decentralization,
      staffInCharge,
    } = values;
    console.log('organizationsValue: ', organizationsValue);
    console.log('organizationsOld: ', organizationsOld);
    const body = {
      firstname: (firstname ?? '').trim(),
      lastname: (lastname ?? '').trim(),
      fullname: `${lastname ?? ''} ${firstname ?? ''}`.trim(),
      channel,
      gender,
      accountType,
      position,
      specialistGr,
      isMarried,
      email,
      address,
      phone,
      code,
      dateOfBirth,
      citizenId,
      licenseDate,
      licenseWhere,
      workAddress,
      workWhere,
      specialist,
      degree,
      isActive,
      avatar: avatarId,
      parent: relationOneToOne(parent, null),
      organizations: relationOneToMany(
        (organizationsValue ?? [])
          .map((item) => item.value)
          .filter((item) => !(organizationsOld ?? []).includes(item)),
        (organizationsOld ?? []).filter(
          (item) =>
            !(organizationsValue ?? []).map((item) => item.value).includes(item)
        )
      ),
      areas: relationOneToMany(
        (areasValue ?? [])
          .map((item) => item.value)
          .filter((item) => !(areasOld ?? []).includes(item)),
        (areasOld ?? []).filter(
          (item) => !(areasValue ?? []).map((item) => item.value).includes(item)
        )
      ),
      adminUser: relationOneToOne(adminId, null),
      decentralization: relationOneToOne(decentralization, null),
      staffInCharge: relationOneToOne(staffInCharge, null),
    };

    if (body.avatar === '') {
      body.avatar = null;
    }

    if (isUpdate) {
      dispatch(
        accountActions.updateAccountExtend(id, body, {
          success: () => {
            notify(
              notificationAlertRef,
              'success',
              'Thông báo',
              `Cập nhật tài khoản thành công.`
            );
            handleClose();
            handleGetAccountsExtendIndex();
          },
          failed: (mess) => {
            notify(
              notificationAlertRef,
              'danger',
              'Thông báo',
              `Cập nhật tài khoản mở rộng thất bại!. Lỗi: ${mess}!`
            );
          },
        })
      );
    } else {
      dispatch(
        accountActions.createAccountExtend(
          {
            ...body,
          },
          {
            success: (data) => {
              notify(
                notificationAlertRef,
                'success',
                'Thông báo',
                `Thêm mới tài khoản thành công.`
              );
              handleSendEmail(data.email);
              handleClose();
              handleGetAccountsExtendIndex();
            },
            failed: (mess) => {
              handleDeleteAccAdmin(adminId);
              notify(
                notificationAlertRef,
                'danger',
                'Thông báo',
                `Thêm mới tài khoản mở rộng thất bại!. Lỗi: ${mess}!`
              );
            },
          }
        )
      );
    }
  };

  const handleSendEmail = (email) => {
    dispatch(
      accountActions.forgotPassword(
        { email: email },
        {
          success: () => {},
          failed: (mess) => {},
        }
      )
    );
  };

  const handleDeleteAccAdmin = (id) => {
    dispatch(accountActions.deleteAccount(id));
  };

  useEffect(() => {
    const query = {};
    if (roleSearch !== '') {
      query['filters[$and][1][name][$containsi]'] = roleSearch;
    }
    dispatch(roleActions.getRoles(queryString.stringify(query)));
  }, [roleSearch]);

  useEffect(() => {
    if (isUpdate) {
      getRelationOrganizations();
      getAreasByAccountExtend();
      const accountTypeV = CONSTANT.ACCOUNT_TYPE.find(
        (item) => item.value === accountType
      );
      const ganderV = CONSTANT.ACCOUNT_GENDER.find(
        (item) => item.value === gender
      );
      const isActiveV = CONSTANT.ACCOUNT_STATUS.find(
        (item) => item.value === isActive
      );

      const channelV = CONSTANT.CHANNEL.find((item) => item.value === channel);
      const specialistGrV = CONSTANT.ACCOUNT_SPECIAL_LIST_GR.find(
        (item) => item.value === specialistGr
      );
      const isMarriedV = CONSTANT.MARRIED.find(
        (item) => item.value === isMarried
      );
      setRoleValue({
        label: modalInfo?.roleName ?? '',
        value: modalInfo?.roleId ?? -1,
      });
      setIsMarriedValue(isMarriedV);
      // setAreasValue(
      //   !!area
      //     ? {
      //         label: area?.name ?? '',
      //         value: area?.id ?? -1,
      //       }
      //     : null
      // );
      setRoleValue(
        !!decentralization
          ? {
              label: decentralization?.name ?? '',
              value: decentralization?.id ?? -1,
            }
          : null
      );
      setParentValue(
        !!parent
          ? {
              label: parent?.code ?? '',
              value: parent?.id ?? -1,
            }
          : null
      );
      setAccountTypeValue(accountTypeV);
      setGenderValue(ganderV);
      setIsActiveValue(isActiveV);
      setChannelValue(channelV);
      setSpecialistGrValue(specialistGrV);
      setStaffInChargeValue(
        staffInCharge
          ? {
              label: `${staffInCharge?.fullname ?? ''}(${
                staffInCharge?.code ?? ''
              })`,
              value: staffInCharge.id,
            }
          : null
      );
    } else {
      setRoleValue(null);
      setAreasValue(null);
      setParentValue(null);
      setOrganizationsValue(null);
      setOrganizationsOld(null);
      setAreasOld(null);
      setGenderValue(CONSTANT.ACCOUNT_GENDER[0]);
      setIsActiveValue(CONSTANT.ACCOUNT_STATUS[0]);
      setIsMarriedValue(CONSTANT.MARRIED[0]);
      setAccountTypeValue(null);
      setSpecialistGrValue(null);
      setChannelValue(null);
      setStaffInChargeValue(null);
    }
  }, [isUpdate, modalInfo]);

  // get Tổ chức
  useEffect(() => {
    const delayDebounceFn = setTimeout(() => {
      handleGetOrganizations();
    }, 500);
    return () => clearTimeout(delayDebounceFn);
  }, [organizationSearch]);
  const handleGetOrganizations = () => {
    const query = {
      'filters[$and][1][status][$eq]': 0,
      'filters[$and][2][id][$ne]': CONSTANT.HTGEN_ID,
    };
    if (organizationSearch !== '') {
      query['filters[$and][3][name][$containsi]'] = organizationSearch;
    }
    dispatch(
      organizationActions.getOrganizations(
        queryString.stringify(query),
        {
          success: handleOrganizations,
        },
        false
      )
    );
  };

  const getRelationOrganizations = () => {
    dispatch(
      organizationActions.getOrganizationsByAccountExtend(modalInfo.id, {
        success: (data) => {
          setOrganizationsValue(
            data.map((item) => ({
              label: item.name,
              value: item.id,
              ...item,
            }))
          );
          const ids = data.map((item) => item.id);
          setOrganizationsOld(ids);
        },
        failed: () => {},
      })
    );
  };

  const getAreasByAccountExtend = () => {
    dispatch(
      areaActions.getAreasByAccountExtend(modalInfo.id, {
        success: (data) => {
          setAreasValue(
            data.map((item) => ({
              label: item.name,
              value: item.id,
              ...item,
            }))
          );
          const ids = data.map((item) => item.id);
          setAreasOld(ids);
        },
        failed: () => {},
      })
    );
  };

  // get Khu vực
  useEffect(() => {
    if (isModalVisible) {
      const delayDebounceFn = setTimeout(() => {
        handleGetAreas();
      }, 500);
      return () => clearTimeout(delayDebounceFn);
    }
  }, [areaSearch, isModalVisible]);

  const handleGetAreas = () => {
    const query = {};
    if (areaSearch !== '') {
      query['filters[$and][0][name][$containsi]'] = areaSearch;
    }
    dispatch(
      areaActions.getAreas(
        queryString.stringify(query),
        {
          success: handleAreas,
        },
        false
      )
    );
  };

  // Tài khoản cha
  useEffect(() => {
    const delayDebounceFn = setTimeout(() => {
      handleGetAccountsExtend();
    }, 500);
    return () => clearTimeout(delayDebounceFn);
  }, [accountExtendSearch]);

  const handleGetAccountsExtend = () => {
    if (accountExtendSearch !== '') {
      dispatch(
        accountActions.getAccountsExtend(
          queryString.stringify({
            'filters[$and][0][code][$containsi]': accountExtendSearch,
          }),
          {
            success: handleAccountsExtend,
          },
          false
        )
      );
    } else {
      dispatch(
        accountActions.getAccountsExtend(
          queryString.stringify({}),
          {
            success: handleAccountsExtend,
          },
          false
        )
      );
    }
  };

  // handle lấy tài khoản phụ trách
  useEffect(() => {
    const delayDebounceFn = setTimeout(() => {
      handleGetStaffInCharge();
    }, 500);
    return () => clearTimeout(delayDebounceFn);
  }, [staffInChargeSearch, accountTypeValue]);

  const handleGetStaffInCharge = () => {
    if (
      accountTypeValue &&
      accountTypeValue.value !== CONSTANT.ACCOUNT_TYPE[5].value
    )
      return;
    const query = {
      'filters[$and][1][accountType][$eq]': 1,
      'filters[$and][2][isActive][$eq]': true,
    };
    if (staffInChargeSearch !== '') {
      query['filters[$and][3][code][$containsi]'] = staffInChargeSearch;
    }

    dispatch(
      accountActions.getAccountsExtend(
        queryString.stringify(query),
        {
          success: (data) => {
            const results = _.get(data, 'results', []);
            setStaffInChargeResults(results);
          },
          failed: () => {},
        },
        false
      )
    );
  };

  // set value arr data
  const handleAreas = (data) => {
    setAreas(data ?? {});
  };

  const handleOrganizations = (data) => {
    setOrganizations(data ?? []);
  };

  const handleAccountsExtend = (data) => {
    setAccountsExtend(data ?? []);
  };

  const handleClose = () => {
    // setInitInfo({});
    dispatch(
      formActions.setValue({
        name: 'isModalVisible',
        value: false,
      })
    );
    if (!isConfigPassword)
      dispatch(
        formActions.setValue({
          name: 'modalInfo',
          value: {},
        })
      );
    setOrganizationsTouched(false);
    setAreaSearch('');
    setOrganizationSearch('');
  };

  return (
    <Modal
      style={{ minWidth: isConfigPassword ? 500 : 1333 }}
      isOpen={isModalVisible && formType !== 0}
      className="modal-dialog-centered modal-secondary"
      toggle={() => {
        handleClose();
      }}
    >
      <div className="modal-body bg-white rounded py-0">
        <div className="bg-white d-flex py-2 border-bottom">
          <span style={{ fontSize: 20, fontWeight: 600 }}>
            {isConfigPassword
              ? 'Thiết lập mật khẩu'
              : isUpdate
              ? 'Cập nhật tài khoản'
              : 'Thêm mới tài khoản'}
          </span>
        </div>
        <Formik
          initialValues={initInfo}
          enableReinitialize
          onSubmit={onSubmit}
          validationSchema={infoSchema}
        >
          {({
            values,
            handleSubmit,
            errors,
            touched,
            handleBlur,
            handleChange,
            setFieldValue,
          }) => {
            let avatar = null;
            if (isUpdate) {
              avatar = !values.avatar?.fileUrl
                ? `${BASE_URL_IMAGE}${values.avatar}`
                : values.avatar.fileUrl;
              if (values.avatar === '')
                avatar = require('assets/img/icons/cards/avatar.jpg').default;
            } else {
              avatar = !values.avatar?.fileUrl
                ? require('assets/img/icons/cards/avatar.jpg').default
                : values.avatar.fileUrl;
            }
            return (
              <Form
                onSubmit={() => {
                  setOrganizationsTouched(true);
                  handleSubmit();
                }}
                className="pb-3 pt-2 px-4 form-add-account bg-white"
              >
                {isConfigPassword ? (
                  <Row>
                    <Col md={12} lg={12}>
                      <InputCustom
                        name="password"
                        label="Mật khẩu"
                        type="password"
                        placeholder="Nhập mật khẩu mới"
                        value={values.password}
                        isRequired={true}
                        handleChange={handleChange}
                        handleBlur={handleBlur}
                        error={errors?.password}
                        touched={touched?.password}
                      />
                    </Col>
                    <Col md={12} lg={12}>
                      <InputCustom
                        name="rePassword"
                        label="Xác nhận mật khẩu"
                        type="password"
                        placeholder="Nhập lại mật khẩu mới"
                        value={values.rePassword}
                        isRequired={true}
                        handleChange={handleChange}
                        handleBlur={handleBlur}
                        error={errors?.rePassword}
                        touched={touched?.rePassword}
                      />
                    </Col>
                  </Row>
                ) : (
                  <div>
                    <div className="">
                      <Row>
                        <Col className="border-right" xs={8}>
                          <div>
                            <div className="d-flex align-items-center my-2">
                              <span
                                className=""
                                style={{
                                  fontSize: 15,
                                  fontWeight: 600,
                                }}
                              >
                                Thông tin cơ bản tài khoản
                              </span>
                              <div className="border-bottom flex-grow-1 ml-2" />
                            </div>
                            <Row>
                              <Col xs={6}>
                                <InputCustom
                                  name="code"
                                  label="Mã tài khoản"
                                  type="text"
                                  placeholder="Nhập mã tài khoản"
                                  value={values.code}
                                  isRequired={true}
                                  handleChange={handleChange}
                                  handleBlur={handleBlur}
                                  error={errors?.code}
                                  touched={touched?.code}
                                  disabled={isUpdate}
                                  row
                                  labelWidth={120}
                                />
                              </Col>
                              <Col xs={6}>
                                <InputCustom
                                  name="email"
                                  label="Tên đăng nhập"
                                  type="email"
                                  placeholder="Nhập email"
                                  value={values.email}
                                  isRequired={true}
                                  handleChange={handleChange}
                                  handleBlur={handleBlur}
                                  error={errors?.email}
                                  touched={touched?.email}
                                  disabled={isUpdate}
                                  row
                                  labelWidth={120}
                                />
                              </Col>
                            </Row>
                            <Row>
                              <Col xs={6}>
                                <SelectCustom
                                  disabled={isUpdate}
                                  name="accountType"
                                  label="Phân loại"
                                  placeholder="Lựa chọn"
                                  value={accountTypeValue}
                                  isRequired={true}
                                  isClearable={false}
                                  handleChange={(e) => {
                                    setAccountTypeValue(e);
                                    setFieldValue(
                                      'accountType',
                                      e?.value ?? null
                                    );
                                    setFieldValue('staffInCharge', null);
                                    setStaffInChargeValue(null);
                                    if (
                                      !!e &&
                                      e.value !== 5 &&
                                      e.value !== -1
                                    ) {
                                      setOrganizationsValue([
                                        {
                                          label: 'HTGEN',
                                          value: CONSTANT.HTGEN_ID,
                                        },
                                      ]);
                                    } else {
                                      setOrganizationsValue(null);
                                    }
                                  }}
                                  handleBlur={handleBlur}
                                  onInputChange={() => {}}
                                  error={errors.accountType}
                                  touched={touched.accountType}
                                  options={CONSTANT.ACCOUNT_TYPE}
                                  row
                                  labelWidth={120}
                                />
                              </Col>
                              <Col xs={6}>
                                <SelectCustom
                                  name="areas"
                                  label="Khu vực"
                                  placeholder="Chọn khu vực"
                                  value={areasValue}
                                  isRequired={false}
                                  isClearable={true}
                                  handleChange={(e, remove) => {
                                    if (_.isEmpty(remove?.removedValue)) {
                                      setAreasValue(e);
                                    } else {
                                      const tmp = areasValue.filter(
                                        (item) =>
                                          item.value !==
                                          remove.removedValue.value
                                      );
                                      setAreasValue(tmp);
                                    }
                                  }}
                                  handleBlur={handleBlur}
                                  onInputChange={(value) => {
                                    setAreaSearch(value);
                                  }}
                                  isMulti
                                  error={errors.areas}
                                  touched={touched.areas}
                                  options={resultsArea.map((item) => ({
                                    label: item?.name ?? '',
                                    value: item?.id,
                                  }))}
                                  row
                                  labelWidth={120}
                                />
                              </Col>
                            </Row>
                            <Row>
                              <Col xs={6}>
                                <SelectCustom
                                  name="organizations"
                                  label="Tổ chức"
                                  className="react-select-custom"
                                  placeholder="Lựa chọn tổ chức"
                                  value={organizationsValue}
                                  isRequired={true}
                                  isClearable={false}
                                  isMulti
                                  isDisabled={
                                    values.accountType !== -1 &&
                                    values.accountType !== 5
                                  }
                                  handleChange={(e, remove) => {
                                    if (_.isEmpty(remove?.removedValue)) {
                                      setOrganizationsValue(e);
                                    } else {
                                      const tmp = organizationsValue.filter(
                                        (item) =>
                                          item.value !==
                                          remove.removedValue.value
                                      );
                                      setOrganizationsValue(tmp);
                                    }
                                  }}
                                  handleBlur={() => {
                                    setOrganizationsTouched(true);
                                  }}
                                  onInputChange={(value) => {
                                    setOrganizationSearch(value);
                                  }}
                                  error={
                                    (organizationsValue ?? []).length === 0
                                      ? 'Tổ chức không được để trống'
                                      : undefined
                                  }
                                  touched={organizationsTouched}
                                  options={organizations.map((item) => ({
                                    label: item?.name ?? '',
                                    value: item?.id,
                                  }))}
                                  row
                                  labelWidth={120}
                                />
                              </Col>
                              <Col xs={6}>
                                <SelectCustom
                                  mutiLineLabel={true}
                                  name="specialistGr"
                                  label="Nhóm chuyên khoa phụ trách"
                                  placeholder="Lựa chọn"
                                  value={specialistGrValue}
                                  isRequired={false}
                                  isClearable={true}
                                  handleChange={(e) => {
                                    setSpecialistGrValue(e);
                                    setFieldValue(
                                      'specialistGr',
                                      e?.value ?? null
                                    );
                                  }}
                                  handleBlur={handleBlur}
                                  error={errors.specialistGr}
                                  touched={touched.specialistGr}
                                  options={CONSTANT.ACCOUNT_SPECIAL_LIST_GR}
                                  row
                                  labelWidth={120}
                                />
                              </Col>
                            </Row>
                            <Row>
                              <Col xs={6}>
                                <InputCustom
                                  name="position"
                                  label="Chức vụ"
                                  placeholder="Nhập chức vụ"
                                  value={values.position}
                                  isRequired={false}
                                  handleChange={handleChange}
                                  handleBlur={handleBlur}
                                  error={errors?.position}
                                  touched={touched?.position}
                                  row
                                  labelWidth={120}
                                />
                                {/* <SelectCustom
                                  name="position"
                                  label="Chức vụ"
                                  placeholder="Lựa chọn"
                                  value={positionValue}
                                  isRequired={false}
                                  isClearable={true}
                                  handleChange={(e) => {
                                    setPositionValue(e);
                                    setFieldValue('position', e?.value ?? null);
                                  }}
                                  handleBlur={handleBlur}
                                  onInputChange={() => {}}
                                  error={errors.position}
                                  touched={touched.position}
                                  options={CONSTANT.ACCOUNT_POSITIONS}
                                  row
                                  labelWidth={120}
                                /> */}
                              </Col>
                              <Col xs={6}>
                                <SelectCustom
                                  name="decentralization"
                                  label="Nhóm quyền"
                                  placeholder="Lựa chọn"
                                  value={roleValue}
                                  isRequired={true}
                                  isClearable={false}
                                  isDisabled={isUpdate}
                                  handleChange={(e) => {
                                    setRoleValue(e);
                                    setFieldValue(
                                      'decentralization',
                                      !!e ? e.value : null
                                    );
                                  }}
                                  handleBlur={handleBlur}
                                  onInputChange={(value) => {}}
                                  error={errors.decentralization}
                                  touched={touched.decentralization}
                                  options={roleResults.map((item) => ({
                                    label: item.name,
                                    value: item.id,
                                  }))}
                                  row
                                  labelWidth={120}
                                />
                              </Col>
                            </Row>

                            <Row>
                              <Col xs={6}>
                                <SelectCustom
                                  name="parent"
                                  label="Cấp quản lý"
                                  placeholder="Lựa chọn"
                                  value={parentValue}
                                  isRequired={false}
                                  isClearable={true}
                                  handleChange={(e) => {
                                    setParentValue(e);
                                    setFieldValue('parent', e?.value ?? null);
                                  }}
                                  handleBlur={handleBlur}
                                  onInputChange={(value) => {
                                    setAccountExtendSearch(value);
                                  }}
                                  error={errors.parent}
                                  touched={touched.parent}
                                  options={resultsAccountsExtend.map(
                                    (item) => ({
                                      label: item?.code ?? '',
                                      value: item?.id,
                                    })
                                  )}
                                  row
                                  labelWidth={120}
                                />
                              </Col>
                              <Col xs={6}>
                                <SelectCustom
                                  name="status"
                                  label="Trạng thái"
                                  placeholder="Lựa chọn"
                                  value={isActiveValue}
                                  isRequired={true}
                                  isClearable={false}
                                  handleChange={(e) => {
                                    setIsActiveValue(e);
                                    setFieldValue(
                                      'isActive',
                                      e?.value ?? false
                                    );
                                  }}
                                  handleBlur={handleBlur}
                                  onInputChange={() => {}}
                                  error={errors.status}
                                  touched={touched.status}
                                  options={CONSTANT.ACCOUNT_STATUS}
                                  row
                                  labelWidth={120}
                                />
                              </Col>
                            </Row>
                            {values.accountType ===
                              CONSTANT.ACCOUNT_TYPE[5].value && (
                              <Row>
                                <Col xs={6}>
                                  <SelectCustom
                                    disabled={isUpdate}
                                    name="staffInCharge"
                                    label="Nv phụ trách"
                                    placeholder="Lựa chọn"
                                    value={staffInChargeValue}
                                    isClearable={false}
                                    handleChange={(e) => {
                                      setStaffInChargeValue(e);
                                      setFieldValue(
                                        'staffInCharge',
                                        e?.value ?? null
                                      );
                                    }}
                                    handleBlur={handleBlur}
                                    onInputChange={(value) => {
                                      setStaffInChargeSearch(value);
                                    }}
                                    error={errors.staffInChargeValue}
                                    touched={touched.staffInChargeValue}
                                    options={staffInChargeResults.map(
                                      (item) => ({
                                        value: item.id,
                                        label: `${item?.fullname ?? ''}(${
                                          item.code
                                        })`,
                                      })
                                    )}
                                    row
                                    labelWidth={120}
                                  />
                                </Col>
                              </Row>
                            )}
                          </div>
                          <div>
                            <div className="d-flex align-items-center my-2">
                              <span
                                className=""
                                style={{
                                  fontSize: 15,
                                  fontWeight: 600,
                                }}
                              >
                                Thông tin cá nhân
                              </span>
                              <div className="border-bottom flex-grow-1 ml-2" />
                            </div>
                          </div>
                          <Row>
                            <Col xs={6}>
                              <InputCustom
                                name="lastname"
                                label="Họ và tên đệm "
                                type="text"
                                placeholder="Nhập vào họ"
                                value={values.lastname}
                                isRequired={false}
                                handleChange={handleChange}
                                handleBlur={handleBlur}
                                error={errors?.lastname}
                                touched={touched?.lastname}
                                row
                                labelWidth={120}
                              />
                            </Col>
                            <Col xs={6}>
                              <InputCustom
                                name="firstname"
                                label="Tên"
                                type="text"
                                placeholder="Nhập vào tên"
                                value={values.firstname}
                                isRequired={true}
                                handleChange={handleChange}
                                handleBlur={handleBlur}
                                error={errors?.firstname}
                                touched={touched?.firstname}
                                row
                                labelWidth={120}
                              />
                            </Col>
                          </Row>
                          <Row>
                            <Col xs={6} className="d-flex">
                              <LabelCustom
                                isRequired={false}
                                label={'Ngày sinh'}
                                id={'dateOfBirth'}
                                style={{
                                  whiteSpace: 'nowrap',
                                  margin: '0.5em 8px 0px 0px',
                                }}
                                styleLabel={{
                                  minWidth: 120,
                                }}
                              />
                              <div className="date-picker-custom row-date-picker-custom d-flex flex-column">
                                <DatePicker
                                  wrapperClassName="icon-calendar"
                                  customInput={<CustomInputCalendar />}
                                  dateFormat={'dd/MM/yyyy'}
                                  selected={values.dateOfBirth}
                                  maxDate={new Date()}
                                  onChange={(date) => {
                                    setFieldValue('dateOfBirth', date);
                                  }}
                                />
                                {errors.dateOfBirth &&
                                  !!touched.dateOfBirth && (
                                    <HelperText message={errors.dateOfBirth} />
                                  )}
                              </div>
                            </Col>
                            <Col xs={6}>
                              <SelectCustom
                                name="gender"
                                label="Giới tính"
                                placeholder="Lựa chọn"
                                value={genderValue}
                                isRequired={true}
                                isClearable={false}
                                handleChange={(e) => {
                                  setGenderValue(e);
                                  setFieldValue('gender', e?.value ?? -1);
                                }}
                                handleBlur={handleBlur}
                                onInputChange={(value) => {}}
                                error={errors.gender}
                                touched={touched.gender}
                                options={CONSTANT.ACCOUNT_GENDER}
                                row
                                labelWidth={120}
                              />
                            </Col>
                          </Row>
                          <Row>
                            <Col xs={6}>
                              <InputCustom
                                name="phone"
                                label="Số điện thoại"
                                type="text"
                                placeholder="Nhập số điện thoại"
                                value={values.phone}
                                isRequired={true}
                                handleChange={(e) => {
                                  if (isNumeric(e.target.value)) {
                                    setFieldValue('phone', e.target.value);
                                  }
                                }}
                                handleBlur={handleBlur}
                                error={errors?.phone}
                                touched={touched?.phone}
                                row
                                labelWidth={120}
                              />
                            </Col>
                            <Col xs={6}>
                              <SelectCustom
                                name="type"
                                label="Hôn nhân"
                                placeholder="Lựa chọn"
                                value={isMarriedValue}
                                isRequired={false}
                                isClearable={false}
                                handleChange={(e) => {
                                  setIsMarriedValue(e);
                                  setFieldValue('isMarried', e?.value ?? false);
                                }}
                                handleBlur={handleBlur}
                                onInputChange={(value) => {}}
                                error={errors.type}
                                touched={touched.type}
                                options={CONSTANT.MARRIED}
                                row
                                labelWidth={120}
                              />
                            </Col>
                          </Row>
                          <Row>
                            <Col xs={6}>
                              <InputCustom
                                name="citizenId"
                                label="Số CCCD"
                                type="text"
                                placeholder="Nhập số CCCD"
                                value={values.citizenId}
                                isRequired={false}
                                handleChange={(e) => {
                                  if (isNumeric(e.target.value)) {
                                    setFieldValue('citizenId', e.target.value);
                                  }
                                }}
                                handleBlur={handleBlur}
                                error={errors?.citizenId}
                                touched={touched?.citizenId}
                                row
                                labelWidth={120}
                              />
                            </Col>

                            <Col xs={6} className="d-flex">
                              <LabelCustom
                                isRequired={false}
                                label={'Ngày cấp '}
                                id={'licenseDate'}
                                style={{
                                  whiteSpace: 'nowrap',
                                  margin: '0.5em 8px 0px 0px',
                                }}
                                styleLabel={{
                                  minWidth: 120,
                                }}
                              />
                              <div className="date-picker-custom row-date-picker-custom d-flex flex-column">
                                <DatePicker
                                  customInput={<CustomInputCalendar />}
                                  wrapperClassName="icon-calendar"
                                  dateFormat={'dd/MM/yyyy'}
                                  selected={values.licenseDate}
                                  maxDate={new Date()}
                                  onChange={(date) => {
                                    setFieldValue('licenseDate', date);
                                  }}
                                />
                                {errors.licenseDate &&
                                  !!touched.licenseDate && (
                                    <HelperText message={errors.licenseDate} />
                                  )}
                              </div>
                            </Col>
                          </Row>
                          <Row>
                            <Col xs={12}>
                              <InputCustom
                                name="licenseWhere"
                                label="Nơi cấp"
                                type="textarea"
                                placeholder="Nhập nơi cấp CCCD"
                                value={values.licenseWhere}
                                isRequired={false}
                                handleChange={handleChange}
                                handleBlur={handleBlur}
                                error={errors?.licenseWhere}
                                touched={touched?.licenseWhere}
                                row
                                labelWidth={120}
                              />
                            </Col>
                          </Row>

                          <Row>
                            <Col xs={12}>
                              <InputCustom
                                name="address"
                                label="Đia chỉ"
                                type="textarea"
                                rows={4}
                                placeholder="Nhập địa chỉ"
                                value={values.address}
                                isRequired={false}
                                handleChange={handleChange}
                                handleBlur={handleBlur}
                                error={errors?.address}
                                touched={touched?.address}
                                row
                                labelWidth={120}
                              />
                            </Col>
                          </Row>
                        </Col>
                        <Col xs={4}>
                          <div className="d-flex align-items-center my-2">
                            <span
                              className=""
                              style={{
                                fontSize: 15,
                                fontWeight: 600,
                              }}
                            >
                              Thông tin bổ sung
                            </span>
                            <div className="border-bottom flex-grow-1 ml-2" />
                          </div>
                          <div className="d-flex justify-content-center align-items-center rounded-circle flex-column my-3">
                            <img
                              alt="..."
                              className="img-fluid rounded-circle shadow"
                              src={avatar}
                              style={{ width: '100px', height: '100px' }}
                            />
                            <input
                              className="mt-3"
                              style={{ fontSize: 12 }}
                              type="file"
                              accept="image/*"
                              onChange={(e) => {
                                try {
                                  if (_.isEmpty(e)) return;
                                  setFieldValue('avatar', {
                                    fileUrl: URL.createObjectURL(
                                      e.target.files[0]
                                    ),
                                    file: e.target.files[0],
                                  });
                                } catch (error) {}
                              }}
                            />
                          </div>
                          <div>
                            <Row>
                              <Col xs={12}>
                                <InputCustom
                                  name="specialist"
                                  label="Chuyên khoa"
                                  type="text"
                                  placeholder="Nhập chuyên khoa"
                                  value={values.specialist}
                                  isRequired={false}
                                  handleChange={handleChange}
                                  handleBlur={handleBlur}
                                  error={errors?.specialist}
                                  touched={touched?.specialist}
                                  row
                                  labelWidth={120}
                                />
                              </Col>
                            </Row>
                            <Row>
                              <Col xs={12}>
                                <InputCustom
                                  name="degree"
                                  label="Bằng cấp"
                                  type="text"
                                  placeholder="Nhập bằng cấp"
                                  value={values.degree}
                                  isRequired={false}
                                  handleChange={handleChange}
                                  handleBlur={handleBlur}
                                  error={errors?.degree}
                                  touched={touched?.degree}
                                  row
                                  labelWidth={120}
                                />
                              </Col>
                            </Row>
                            <Row>
                              <Col xs={12}>
                                <InputCustom
                                  name="workAddress"
                                  label="Nơi làm việc"
                                  type="text"
                                  placeholder="Nhập nơi làm việc"
                                  value={values.workAddress}
                                  isRequired={false}
                                  handleChange={handleChange}
                                  handleBlur={handleBlur}
                                  error={errors?.workAddress}
                                  touched={touched?.workAddress}
                                  row
                                  labelWidth={120}
                                />
                              </Col>
                            </Row>
                            <Row>
                              <Col xs={12}>
                                <SelectCustom
                                  name="channel"
                                  label="Kênh"
                                  placeholder="Chọn kênh"
                                  value={channelValue}
                                  isRequired={false}
                                  isClearable={true}
                                  handleChange={(e) => {
                                    setChannelValue(e);
                                    setFieldValue('channel', e?.value ?? null);
                                  }}
                                  handleBlur={handleBlur}
                                  onInputChange={(__) => {}}
                                  error={errors.channel}
                                  touched={touched.channel}
                                  options={CONSTANT.CHANNEL}
                                  row
                                  labelWidth={120}
                                />
                              </Col>
                            </Row>
                            <Row>
                              <Col xs={12}>
                                <InputCustom
                                  name="workWhere"
                                  label="Địa chỉ làm việc"
                                  type="textarea"
                                  rows={4}
                                  placeholder="Nhập địa chỉ"
                                  value={values.workWhere}
                                  isRequired={false}
                                  handleChange={handleChange}
                                  handleBlur={handleBlur}
                                  error={errors?.workWhere}
                                  touched={touched?.workWhere}
                                  row
                                  labelWidth={120}
                                />
                              </Col>
                            </Row>
                          </div>
                        </Col>
                      </Row>
                    </div>
                  </div>
                )}
                <div className="mb-0 pb-0 mt-4 d-flex justify-content-end">
                  <Button
                    className="text-nowrap mr-1"
                    onClick={() => {
                      handleClose();
                    }}
                  >
                    Hủy
                  </Button>
                  <LoadingButtonCustom
                    onClick={() => {
                      setOrganizationsTouched(true);
                      handleSubmit();
                    }}
                    color="info"
                    className="text-nowrap "
                    // type="submit"
                    loading={isCreateAccount || isUpdateAccount}
                  >
                    {isUpdate ? 'Cập nhật' : 'Thêm mới'}
                  </LoadingButtonCustom>
                </div>
              </Form>
            );
          }}
        </Formik>
      </div>
    </Modal>
  );
};

export default memo(CUAccount);
