//core-componens
import React, { memo, useCallback, useEffect, useState } from 'react';
import { Card, CardBody, CardHeader, Col, Row } from 'reactstrap';
//custom components
import LabelCustom from 'views/pages/components/LabelCustom';
import InputCustom from 'views/pages/components/InputCustom';
//module
import _ from 'lodash';
import queryString from 'query-string';
import { getListSelectOption } from 'common';
import { notificationAlertRef, notify } from 'common';
//styles
import 'assets/css/custom-pages/specify-votes.css';
import 'assets/css/custom-pages/create-specify-votes.css';
import { useDispatch, useSelector } from 'react-redux';
import {
  useDelayInputEffect,
  useDelayInputEffectFuntion,
} from 'utils/HookUtils';
import { patientActions, templatePCDActions } from 'redux/actions';
import GeneralInfomationRight from './GeneralInfomationRight';
import GeneralInfomationLeft from './GeneralInfomationLeft';
import { useFormikContext } from 'formik';
import CONSTANT from 'constant';
import SelectCustom from 'views/pages/components/SelectCustom';
import classNames from 'classnames';

function GeneralInfomation({
  testValue,
  setTestValue,
  isCreatePatient,
  setIsCreatePatient,
  randomIDSpecifyVotes,
  formik,
  isUpdate,
  currentSP,
  isDisabled,
}) {
  return (
    <Card>
      <CardHeader className="py-3">
        <h3 className="mb-0">Thông tin chung</h3>
      </CardHeader>
      <CardBody className={classNames('px-4 py-3 ', { 'pb-5': isUpdate })}>
        <GeneralInfomationHeader
          testValue={testValue}
          setTestValue={setTestValue}
          randomIDSpecifyVotes={randomIDSpecifyVotes}
          isUpdate={isUpdate}
          formik={formik}
          isDisabled={isDisabled}
        />
        <Row>
          <GeneralInfomationLeft
            isCreatePatient={isCreatePatient}
            setIsCreatePatient={setIsCreatePatient}
            formik={formik}
            isUpdate={isUpdate}
            currentSP={currentSP}
            isDisabled={isDisabled}
          />
          <GeneralInfomationRight
            isDisabled={isDisabled}
            formik={formik}
            isUpdate={isUpdate}
          />
        </Row>
        <Row>
          {isUpdate && (
            <StatusUpdateForm
              formik={formik}
              currentSP={currentSP}
              isDisabled={isDisabled}
            />
          )}
        </Row>
      </CardBody>
    </Card>
  );
}
function StatusUpdateForm({ formik, currentSP }) {
  return (
    <Col xs={12}>
      <Row style={{ marginTop: 12 }}>
        <Col
          xs={12}
          style={{
            display:
              formik.values.specifyVoteStatus === CONSTANT.ORDER_STATUS[0].value
                ? undefined
                : 'none',
          }}
        >
          <InputCustom
            row
            label="Ghi chú"
            labelWidth={150}
            lableTextAlign={'left'}
            className={'height-input'}
            id={'note'}
            placeholder={'Nhập lý do'}
            type={'textarea'}
            name={'note'}
            disabled={
              parseInt(currentSP?.specifyVoteStatus ?? '') ===
              CONSTANT.ORDER_STATUS[0].value
            }
            style={{
              minHeight: '100px',
            }}
            value={formik.values.note}
            handleChange={(e) => {
              formik.setFieldValue('note', e.target.value);
            }}
            handleBlur={formik.handleBlur}
            touched={formik.touched.note}
            error={formik.errors.note}
          />
        </Col>
      </Row>
    </Col>
  );
}
function GeneralInfomationHeader({
  testValue,
  setTestValue,
  randomIDSpecifyVotes,
  formik,
  isUpdate,
  isDisabled,
}) {
  const dispatch = useDispatch();
  const [templatePCDOptions, setTemplatePCDOptions] = useState([]);
  const [isSearch, setIsSearch] = useState(false);
  const [templatePCDSearch, setTemplatePCDSearch] = useState('');

  useEffect(() => {
    if (isUpdate && formik.values.testGR) {
      setTestValue(formik.values.testGR);
    }
  }, [formik]);
  //Lấy mẫu phiếu xét nghiệm
  /**
   * Lấy danh sách, tìm kiếm theo từ khóa cho mẫu phiếu xét nghiệm.
   * @param {*} type: Nếu là true sẽ chọn phần tử đầu tiên cho ô slecet mẫu phiếu xét nghiệm
   */
  function getListTemplatePCD(isSetValueSelect) {
    const templatePCDAC = templatePCDActions.getTemplatePCDs(
      queryString.stringify(
        {
          'filters[$and][0][testGR][$eq]': formik?.values?.testGR ?? 0,
          'filters[$or][0][code][$containsi]': templatePCDSearch,
          'filters[$or][1][name][$containsi]': templatePCDSearch,
        },
        {
          skipEmptyString: true,
          skipNull: true,
        }
      ),
      {
        success: (data) => {
          if (data.results && Array.isArray(data.results)) {
            if (data.results.length > 0 && isSetValueSelect) {
              formik.setFieldValue(
                'testGRTemplate',
                data?.results[0]?.id ?? -1
              );
              setIsSearch(true);
            } else setIsSearch(false);
            setTemplatePCDOptions(
              getListSelectOption(data.results, ['code+name'], ['id'], '()')
            );
          }
        },
        failed: (mess) => {
          notify(
            notificationAlertRef,
            'danger',
            'Thông báo',
            `Lấy danh sách mẫu phiếu xét nghiệm thất bại! Lỗi: ${mess}`
          );
        },
      },
      false
    );
    dispatch(templatePCDAC);
  }
  useDelayInputEffectFuntion(
    () => {
      if (!templatePCDSearch) {
        getListTemplatePCD(true);
      } else getListTemplatePCD(false);
    },
    templatePCDSearch,
    700
  );
  useEffect(() => {
    getListTemplatePCD(true);
  }, [formik.values.testGR]);
  if (isUpdate) {
    return (
      <Row>
        <Col
          xs={2}
          style={{ maxWidth: 170, display: 'flex', alignItems: 'center' }}
        >
          <LabelCustom
            label={'Nhóm xét nghiệm'}
            isRequired
            style={{
              textAlign: 'left',
              marginBottom: 0,
              // minWidth: 160,
              fontSize: 15,
            }}
          />
        </Col>
        <Col>
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
            }}
          >
            {parseInt(formik?.values?.testGR) === 0
              ? 'Nhóm sản'
              : parseInt(formik?.values?.testGR) === 1
              ? 'Nhóm phôi'
              : parseInt(formik?.values?.testGR) === 2
              ? 'Nhóm bệnh lý'
              : ''}
          </div>
          {/* <div style={{ maxWidth: 190 }}>
                <InputCustom
                  isRequired={true}
                  lableTextAlign={"right"}
                  label={"Mã phiếu"}
                  labelWidth={80}
                  disabled={true}
                  isDisplayLabel={false}
                  value={isUpdate ? formik.values.codePCD : ID}
                  row={true}
                />
              </div> */}
        </Col>
      </Row>
    );
  }
  return (
    <>
      <Row>
        <Col xs={2} style={{ maxWidth: '170px' }}>
          <LabelCustom label={'Nhóm xét nghiệm'} isRequired />
        </Col>
        <Col xs={5} className={'pl-4'}>
          <div className="d-flex ">
            <InputCustom
              name="testRadio"
              id={'testRadio1'}
              type="radio"
              label="Nhóm sản"
              value={0}
              row={true}
              disabled={true}
              checked={testValue == 0}
              onClick={(index) => {
                if (!isUpdate) {
                  setTestValue(index);
                  formik.setFieldValue('testGR', index);
                }
              }}
            />
            <InputCustom
              name="testRadio"
              id={'testRadio2'}
              type="radio"
              label="Nhóm phôi"
              row={true}
              value={1}
              checked={testValue == 1}
              disabled={true}
              onClick={(index) => {
                if (!isUpdate) {
                  setTestValue(index);
                  formik.setFieldValue('testGR', index);
                }
              }}
            />
            <InputCustom
              name="testRadio"
              id={'testRadio3'}
              type="radio"
              label="Nhóm bệnh lý"
              row={true}
              disabled={true}
              value={2}
              checked={testValue == 2}
              onClick={(index) => {
                if (!isUpdate) {
                  setTestValue(index);
                  formik.setFieldValue('testGR', index);
                }
              }}
            />
          </div>
        </Col>
      </Row>
      <Row className="mb-4">
        <Col xs={6}>
          <SelectCustom
            id={'testGRTemplate'}
            name={'testGRTemplate'}
            label="Mẫu phiếu xét nghiệm"
            isRequired
            lableTextAlign="left"
            labelWidth={150}
            isDisabled={isDisabled}
            row={true}
            placeholder="Lựa chọn"
            isMulti={false}
            options={templatePCDOptions}
            value={_.find(templatePCDOptions, {
              value: formik.values.testGRTemplate,
            })}
            handleChange={(e) => {
              formik.setFieldValue('testGRTemplate', e.value);
            }}
            onInputChange={(str) => {
              setTemplatePCDSearch(str);
            }}
            handleBlur={formik.handleBlur}
            error={formik.errors.testGRTemplate}
            touched={formik.touched.testGRTemplate}
          />
        </Col>
      </Row>
    </>
  );
}

export default memo(GeneralInfomation);
