import React, { memo, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Button, Col, Form, Modal, Row } from 'reactstrap';
import * as yup from 'yup';
import { Formik } from 'formik';
import _ from 'lodash';
import queryString from 'query-string';

import { notificationAlertRef, notify, relationOneToOne } from 'common';
import {
  accountActions,
  specifyVotesActions,
  templatePCDActions,
} from 'redux/actions';
import LoadingButtonCustom from 'views/pages/components/LoadingButtonCustom';
import CONSTANT from 'constant';
import SelectCustom from 'views/pages/components/SelectCustom';
import { useDidUpdateEffect } from 'utils/HookUtils';

const CFPcd = ({ callback, isFPcd, setIsFPcd, aV }) => {
  const dispatch = useDispatch();

  // reducers
  const {
    isBusinessStaff,
    currentAccountExtend,
    childrentByCurrentAccountExtend,
  } = useSelector((state) => state.accountReducer);
  const { templatePcds } = useSelector((state) => state.templatePCDReducer);

  // Khởi tạo dữ liêu
  const initInfo = {
    testGR: null,
    templatePcd: null,
    firstNameBS: aV?.firstname ?? '',
    lastNameBS: aV?.lastname ?? '',
    idBS: aV?.idBS ?? -1,
  };

  const [templateSearch, setTemplateSearch] = useState('');
  const [accountSearch, setAccountSearch] = useState('');

  const [accountValue, setAccountValue] = useState(null);
  const [testGRValue, setTestGRValue] = useState(null);
  const [templatePcdValue, setTemplatePcd] = useState(null);

  const [accountResults, setAccountResults] = useState([]);

  // validate dữ liêu
  const infoSchema = yup.object().shape({
    firstNameBS: yup
      .string()
      .required('Bác sĩ chỉ định không được để trống!')
      .nullable(),
    testGR: yup
      .number()
      .min(0, 'Nhóm xét nghiệm không được để trống')
      .required('Nhóm xét nghiệm không được để trống!')
      .nullable(),
    templatePcd: yup
      .number()
      .min(0, 'Mẫu in phiếu xét nghiệm không được để trống')
      .required('Mẫu in phiếu xét nghiệm không được để trống!')
      .nullable(),
  });

  // Xử lý submit
  const onSubmit = (values, { resetForm }) => {
    const { testGR, templatePcd, firstNameBS, lastNameBS, idBS } = values;
    const body = {
      codePCD: 'HTG',
      testGR,
      firstNameBS,
      lastNameBS,
      idBS,
      templatePcd: relationOneToOne(templatePcd, null),
    };
    dispatch(
      specifyVotesActions.createSpecifyVote(body, {
        success: (data) => {
          callback(data);
          notify(
            notificationAlertRef,
            'success',
            'Thông báo',
            `Thêm mới phiếu xét nghiệm thành công.`
          );
          resetForm();
          handleClose();
        },
        failed: (mess) => {
          notify(
            notificationAlertRef,
            'danger',
            'Thông báo',
            `Thêm mới phiếu xét nghiệm thất bại!. Lỗi: ${mess}!`
          );
        },
      })
    );
  };

  // Xử lý đóng form
  const handleClose = () => {
    setTestGRValue(null);
    setTemplatePcd(null);
    setAccountValue(null);
    setIsFPcd(false);
  };

  useEffect(() => {
    const delayDebounceFn = setTimeout(() => {
      handleGetAccountBS();
    }, 500);

    return () => clearTimeout(delayDebounceFn);
  }, [accountSearch, childrentByCurrentAccountExtend]);
  useDidUpdateEffect(() => {
    setAccountValue(aV);
  }, [aV]);

  const handleGetAccountBS = () => {
    const query = {
      'filters[$and][1][accountType][$eq]': CONSTANT.ACCOUNT_TYPE[5].value,
      'filters[$and][2][isActive][$eq]': true,
    };

    if (accountSearch !== '') {
      query[`filters[$and][3][fullname][$containsi]`] = accountSearch;
    }

    if (isBusinessStaff) {
      query['filters[$and][4][$or][4][staffInCharge][id][$eq]'] =
        currentAccountExtend.id;

      if (!_.isEmpty(childrentByCurrentAccountExtend)) {
        childrentByCurrentAccountExtend.forEach((c, i) => {
          query[`filters[$and][4][$or][${5 + i}][staffInCharge][id][$eq]`] =
            c.id;
        });
      }
    }

    dispatch(
      accountActions.getAccountsExtend(
        queryString.stringify(query),
        {
          success: (data) => {
            const results = _.get(data, 'results', []);
            setAccountResults(results);
          },
          failed: () => {},
        },
        false
      )
    );
  };

  const handleGetTemplatePcds = () => {
    const query = {};
    if (templateSearch !== '') {
      query['filters[$and][1][name][$containsi]'] = templateSearch;
    }
    if (!!testGRValue) {
      query['filters[$and][0][testGR][$eq]'] = testGRValue?.value;
    }
    dispatch(
      templatePCDActions.getTemplatePCDs(queryString.stringify(query), {
        success: () => {},
        failed: () => {},
      })
    );
  };
  useEffect(() => {
    const delayDebounceFn = setTimeout(() => {
      handleGetTemplatePcds();
    }, 500);

    return () => clearTimeout(delayDebounceFn);
  }, [templateSearch, testGRValue]);
  const templates = templatePcds?.results ?? [];
  return (
    <Modal
      size={'md'}
      isOpen={isFPcd}
      className="modal-dialog-centered modal-secondary"
      toggle={() => {
        handleClose();
      }}
    >
      <div className="modal-header border-bottom bg-white pb-2">
        <h2>Tạo nhanh phiếu xét nghiệm</h2>
        <button
          aria-label="Close"
          className="close"
          data-dismiss="modal"
          type="button"
          onClick={() => {
            handleClose();
          }}
        >
          <span style={{ color: '#262626' }} aria-hidden={true}>
            ×
          </span>
        </button>
      </div>
      <div className="modal-body bg-white rounded">
        <Formik
          initialValues={initInfo}
          enableReinitialize
          onSubmit={onSubmit}
          validationSchema={infoSchema}
        >
          {({ errors, touched, handleSubmit, handleBlur, setFieldValue }) => {
            return (
              <Form onSubmit={handleSubmit} className="">
                <Row>
                  <Col xs={6}>
                    <SelectCustom
                      name="firstNameBS"
                      label="Bác sĩ chỉ định"
                      placeholder="Lựa chọn"
                      value={accountValue}
                      isRequired={true}
                      isClearable={false}
                      isDisabled={!!aV}
                      handleChange={(e) => {
                        setAccountValue(e);
                        setFieldValue('firstNameBS', e?.firstname ?? '');
                        setFieldValue('lastNameBS', e?.lastname ?? '');
                        setFieldValue('idBS', e?.value ?? -1);
                      }}
                      handleBlur={handleBlur}
                      onInputChange={(value) => {
                        setAccountSearch(value);
                      }}
                      error={errors?.firstNameBS}
                      touched={touched?.firstNameBS}
                      options={accountResults.map((item) => ({
                        value: item.id,
                        label: `${item?.fullname ?? ''}`,
                        ...item,
                      }))}
                    />
                  </Col>
                </Row>
                <Row>
                  <Col xs={6}>
                    <SelectCustom
                      name="testGR"
                      label="Nhóm xét nghiệm"
                      placeholder="Lựa chọn"
                      value={testGRValue}
                      isRequired={true}
                      isClearable={true}
                      handleChange={(e) => {
                        setTestGRValue(e);
                        setFieldValue('testGR', e?.value ?? null);
                        setTemplatePcd(null);
                      }}
                      handleBlur={handleBlur}
                      error={errors?.testGR}
                      touched={touched?.testGR}
                      options={CONSTANT.SPECIFY_VOTE_NXN}
                    />
                  </Col>
                  <Col xs={6}>
                    <SelectCustom
                      name="templatePcd"
                      label="Mẫu in phiếu xét nghiệm"
                      placeholder="Lựa chọn"
                      value={templatePcdValue}
                      isRequired={true}
                      isClearable={true}
                      handleChange={(e) => {
                        setTemplatePcd(e);
                        setFieldValue('templatePcd', e?.value ?? null);
                      }}
                      handleBlur={handleBlur}
                      onInputChange={(value) => {
                        setTemplateSearch(value);
                      }}
                      error={errors?.templatePcd}
                      touched={touched?.templatePcd}
                      isDisabled={!testGRValue}
                      options={
                        templates.map((item) => ({
                          label: `(${item?.code ?? ''}) ${item?.name ?? ''}`,
                          value: item.id,
                        })) ?? []
                      }
                    />
                  </Col>
                </Row>

                <div className="mb-0 pb-0 mt-4 d-flex justify-content-end">
                  <Button
                    className="text-nowrap mr-1"
                    onClick={() => {
                      handleClose();
                    }}
                  >
                    Hủy
                  </Button>
                  <LoadingButtonCustom
                    color="info"
                    className="text-nowrap "
                    type="submit"
                    loading={false}
                  >
                    Thêm mới
                  </LoadingButtonCustom>
                </div>
              </Form>
            );
          }}
        </Formik>
      </div>
    </Modal>
  );
};

export default memo(CFPcd);
