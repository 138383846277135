import typeActions from 'redux/typeActions';
import CONSTANT from 'constant';
import { returnValue } from 'common';

const initialState = {
    
};

const relationReducer = (state = initialState, actions) => {
  switch (actions.type) {
    case typeActions.GET_RELATION_REQUEST:
      return {
        ...state,
        ...actions.data,
      };
    
    default:
      return {
        ...state,
      };
  }
};
export default relationReducer;
