import typeActions from 'redux/typeActions';
const setValueSpecifyVote = ({ name, value }) => {
  return {
    type: typeActions.SET_SPECIFYVOTE_VALUE,
    name,
    value,
  };
};
const getSpecifyVotes = (query, callback, isUpdateReducer) => {
  return {
    type: typeActions.GET_SPECIFYVOTE_REQUEST,
    query,
    callback,
    isUpdateReducer,
  };
};
const getSpecifyVoteById = (param, query, callback) => {
  return {
    type: typeActions.GET_SPECIFYVOTE_BY_ID_REQUEST,
    param,
    query,
    callback,
  };
};
const createSpecifyVote = (params, callback) => {
  return {
    type: typeActions.CREATE_SPECIFYVOTE_REQUEST,
    params,
    callback,
  };
};
const updateSpecifyVote = (params, body, callback) => {
  return {
    type: typeActions.UPDATE_SPECIFYVOTE_REQUEST,
    params,
    body,
    callback,
  };
};
const deleteSpecifyVote = (params, callback) => {
  return {
    type: typeActions.DELETE_SPECIFYVOTE_REQUEST,
    params,
    callback,
  };
};

const printSpecifyVote = (param, callback) => {
  return {
    type: typeActions.PRINT_SPECIFYVOTE_REQUEST,
    param,
    callback,
  };
};

const exportSpecifyVote = (query, callback) => {
  return {
    type: typeActions.EXPORT_SPECIFYVOTE_REQUEST,
    callback,
    query,
  };
};

export default {
  setValueSpecifyVote,
  getSpecifyVotes,
  deleteSpecifyVote,
  updateSpecifyVote,
  createSpecifyVote,
  getSpecifyVoteById,
  printSpecifyVote,
  exportSpecifyVote
};
