import typeActions from 'redux/typeActions';
import CONSTANT from 'constant';
import { returnValue } from 'common';

const initialState = {
  // loading
  isGettingContentXns: false,
  isGettingContentXn: false,
  isCreatingContentXn: false,
  isUpdatingContentXn: false,
  isDeletingContentXn: false,
  // data
  contentXns: {},
  contentXn: {},
  // error
  errors: {
    getContentXns: '',
    getContentXn: '',
    createContentXn: '',
    updateContentXn: '',
    deleteContentXn: '',
  },
  // query
  search: '',
  page: 1,
  limit: 10,
  startDate: null,
  endDate: null,
};

const contentXnReducer = (state = initialState, actions) => {
  switch (actions.type) {
    case typeActions.SET_CONTENT_XN_VALUE:
      const { name, value } = actions;
      if (typeof name !== 'string') return { ...state };
      const obj = returnValue(state, name, value);

      return {
        ...state,
        ...obj,
      };

    case typeActions.GET_CONTENT_XNS_REQUEST:
      return {
        ...state,
        isGettingContentXns: true,
        errors: {
          ...state.errors,
          getContentXns: '',
        },
      };
    case typeActions.GET_CONTENT_XNS_SUCCESS:
      return {
        ...state,
        contentXns: actions.data ?? {},
        isGettingContentXns: false,
        errors: {
          ...state.errors,
          getContentXns: '',
        },
      };
    case typeActions.GET_CONTENT_XNS_FAILED:
      return {
        ...state,
        contentXns: {},
        isGettingContentXns: false,
        errors: {
          ...state.errors,
          getContentXns: actions.error,
        },
      };

    case typeActions.GET_CONTENT_XN_REQUEST:
      return {
        ...state,
        isGettingContentXn: true,
        errors: {
          ...state.errors,
          getContentXn: '',
        },
      };
    case typeActions.GET_CONTENT_XN_SUCCESS:
      return {
        ...state,
        contentXn: actions.data || {},
        isGettingContentXn: false,
        errors: {
          ...state.errors,
          getContentXn: '',
        },
      };
    case typeActions.GET_CONTENT_XN_FAILED:
      return {
        ...state,
        contentXn: {},
        isGettingContentXn: false,
        errors: {
          ...state.errors,
          getContentXn: actions.error,
        },
      };

    case typeActions.CREATE_CONTENT_XN_REQUEST:
      return {
        ...state,
        isCreatingContentXn: true,
        errors: {
          ...state.errors,
          createContentXn: '',
        },
      };
    case typeActions.CREATE_CONTENT_XN_SUCCESS:
      return {
        ...state,
        isCreatingContentXn: false,
        errors: {
          ...state.errors,
          createContentXn: '',
        },
      };
    case typeActions.CREATE_CONTENT_XN_FAILED:
      return {
        ...state,
        isCreatingContentXn: false,
        errors: {
          ...state.errors,
          createContentXn: actions.error,
        },
      };

    case typeActions.UPDATE_CONTENT_XN_REQUEST:
      return {
        ...state,
        isUpdatingContentXn: true,
        errors: {
          ...state.errors,
          updateContentXn: '',
        },
      };
    case typeActions.UPDATE_CONTENT_XN_SUCCESS:
      return {
        ...state,
        isUpdatingContentXn: false,
        errors: {
          ...state.errors,
          updateContentXn: '',
        },
      };
    case typeActions.UPDATE_CONTENT_XN_FAILED:
      return {
        ...state,
        isUpdatingContentXn: false,
        errors: {
          ...state.errors,
          updateContentXn: actions.error,
        },
      };

    case typeActions.DELETE_CONTENT_XN_REQUEST:
      return {
        ...state,
        isDeletingContentXn: true,
        errors: {
          ...state.errors,
          deleteContentXn: '',
        },
      };
    case typeActions.DELETE_CONTENT_XN_SUCCESS:
      return {
        ...state,
        isDeletingContentXn: false,
        errors: {
          ...state.errors,
          deleteContentXn: '',
        },
      };
    case typeActions.DELETE_CONTENT_XN_FAILED:
      return {
        ...state,
        isDeletingContentXn: false,
        errors: {
          ...state.errors,
          deleteContentXn: actions.error,
        },
      };

    default:
      return {
        ...state,
      };
  }
};
export default contentXnReducer;
