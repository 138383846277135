import _ from 'lodash';
import { all, call, takeLatest } from 'redux-saga/effects';
import typeActions from 'redux/typeActions';
import ServiceURL from 'services/ServiceURL';
import { GET } from 'services/ServiceBase';
export function* handleGetRelation({ objName, results, relationTo, callback }) {
  try {
    const apiRelations =
      results.map((item) => {
        const urlRelation = `${ServiceURL.relation}/api::${objName}.${objName}/${item.id}/${relationTo}?pageSize=100`;
        return call(GET, urlRelation);
      }) ?? [];
    const res = yield all(apiRelations);
    !!callback?.success && callback.success(res);
    return res;
  } catch (error) {
    !!callback?.failed && callback.failed(error);
    return [];
  }
}
export function* handleGetSingleRelation({
  objName,
  obj,
  relationTo,
  callback,
}) {
  try {
    const urlRelation = `${ServiceURL.relation}/api::${objName}.${objName}/${obj.id}/${relationTo}?pageSize=100`;
    const res = yield call(GET, urlRelation);
    !!callback?.success && callback.success(res.data.data);
    return res;
  } catch (error) {
    !!callback?.failed && callback.failed(error);
    return [];
  }
}

export default function* organizarionSaga() {
  yield takeLatest(typeActions.GET_RELATION_REQUEST, handleGetRelation);
  yield takeLatest(
    typeActions.GET_SINGLE_RELATION_REQUEST,
    handleGetSingleRelation
  );
}
