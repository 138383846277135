/* eslint-disable import/no-anonymous-default-export */
import typeActions from 'redux/typeActions';

const setValue = ({ name, value }) => {
  return {
    type: typeActions.SET_ADDITIONAL_SAMPLE_VALUE,
    name,
    value,
  };
};

const getAdditionalSamples = (query, callback, isUpdateReducer = true) => {
  return {
    type: typeActions.GET_ADDITIONAL_SAMPLES_REQUEST,
    query,
    callback,
    isUpdateReducer,
  };
};

const getAdditionalSample = (param, query, callback) => {
  return {
    type: typeActions.GET_ADDITIONAL_SAMPLE_REQUEST,
    param,
    query,
    callback,
  };
};

const createAdditionalSample = (body, callback) => {
  return {
    type: typeActions.CREATE_ADDITIONAL_SAMPLE_REQUEST,
    body,
    callback,
  };
};
const updateAdditionalSample = (param, body, callback) => {
  return {
    type: typeActions.UPDATE_ADDITIONAL_SAMPLE_REQUEST,
    param,
    body,
    callback,
  };
};
const deleteAdditionalSample = (param, callback) => {
  return {
    type: typeActions.DELETE_ADDITIONAL_SAMPLE_REQUEST,
    param,
    callback,
  };
};

const getAdditionalSampleBy = (query, callback) => {
  return {
    type: typeActions.GET_ADDITIONAL_SAMPLE_BY_REQUEST,
    query,
    callback,
  };
};

const exportAdditionalSample = (query, callback) => {
  return {
    type: typeActions.EXPORT_ADDITIONAL_SAMPLE_REQUEST,
    callback,
    query,
  };
};
const exportAdditionalSampleAll = (query, callback) => {
  return {
    type: typeActions.EXPORT_ADDITIONAL_SAMPLE_ALL_REQUEST,
    callback,
    query,
  };
};

const printPkqAdditionalSample = (param, callback) => {
  return {
    type: typeActions.PRINT_PKQ_ADDITIONAL_SAMPLE_REQUEST,
    callback,
    param,
  };
};

const uploadFiles = (order, callback) => {
  return {
    type: typeActions.UPLOAD_FILES_ADDITIONAL_SAMPLE_REQUEST,
    callback,
    order,
  };
};

const updateLabCodes = (file, callback) => {
  return {
    type: typeActions.UPDATE_ADDITIONAL_SAMPLE_LABCODES_REQUEST,
    callback,
    file,
  };
};


export default {
  setValue,
  getAdditionalSamples,
  getAdditionalSample,
  createAdditionalSample,
  updateAdditionalSample,
  deleteAdditionalSample,
  getAdditionalSampleBy,
  exportAdditionalSample,
  exportAdditionalSampleAll,
  printPkqAdditionalSample,
  uploadFiles,
  updateLabCodes,
};
