import _ from 'lodash';
import { takeLatest, call, put, all } from 'redux-saga/effects';

import typeActions from 'redux/typeActions';
import ServiceURL from 'services/ServiceURL';
import { DELETE, GET, POST, PUT } from 'services/ServiceBase';
import { convertMessageError } from 'common';

export function* getdServiceDetails(data) {
  const url = `${ServiceURL.serviceDetail}?sort=createdAt:DESC&${data.query}`;
  const callback = data.callback;
  try {
    const res = yield call(GET, url);
    if (!!res.data?.error?.message) {
      yield put({
        type: typeActions.GET_SERVICE_DETAILS_FAILED,
        error: res.data?.error?.message,
      });
      !!callback?.failed && callback.failed(res.data.error.message);
    } else {
      if (data.isUpdateReducer) {
        yield put({
          type: typeActions.GET_SERVICE_DETAILS_SUCCESS,
          data: res.data,
        });
      }
      !!callback?.success && callback.success(res.data);
    }
  } catch (error) {
    yield put({
      type: typeActions.GET_SERVICE_DETAILS_FAILED,
      error: error?.response?.data?.error?.message ?? '',
    });
    !!callback?.failed &&
      callback.failed(error?.response?.data?.error?.message ?? '');
  }
}

export function* getdServiceDetail(data) {
  const url = `${ServiceURL.serviceDetail}?${data.query}`;
  const callback = data.callback;
  try {
    const res = yield call(GET, url);
    if (!!res.data?.error?.message) {
      yield put({
        type: typeActions.GET_SERVICE_DETAIL_FAILED,
        error: res.data.error.message,
      });
      !!callback?.failed && callback.failed(res.data.error.message);
    } else {
      const id = _.get(res.data, 'results[0].id', -1);

      const urlRelation = `${ServiceURL.contentXn}?filters[$and][0][serviceDetails][id]=${id}&pageSize=99999`;

      let relations = [];
      try {
        relations = yield call(GET, urlRelation);
      } catch (error) {}
      const resData = {
        ..._.get(res.data, 'results[0]', {}),
        contentXns: relations.data?.results ?? [],
      };
      yield put({
        type: typeActions.GET_SERVICE_DETAIL_SUCCESS,
        data: resData,
      });
      !!callback?.success && callback.success(resData);
    }
  } catch (error) {
    yield put({
      type: typeActions.GET_SERVICE_DETAIL_FAILED,
      error: error?.response?.data?.error?.message ?? '',
    });
    !!callback?.failed &&
      callback.failed(error?.response?.data?.error?.message ?? '');
  }
}

export function* createdServiceDetail(data) {
  const url = `${ServiceURL.serviceDetail}`;
  const callback = data.callback;
  try {
    const res = yield call(POST, url, data.body);
    if (!!res.data?.error?.message) {
      yield put({
        type: typeActions.CREATE_SERVICE_DETAIL_FAILED,
        error: res.data.error.message,
      });
      !!callback?.failed && callback.failed(res.data.error.message);
    } else {
      yield put({
        type: typeActions.CREATE_SERVICE_DETAIL_SUCCESS,
        data: res.data?.data,
      });
      !!callback?.success && callback.success(res.data?.data);
    }
  } catch (error) {
    const message = convertMessageError('c', error, ' chi tiết dịch vụ ');
    yield put({
      type: typeActions.CREATE_SERVICE_DETAIL_FAILED,
      error: message,
    });
    !!callback?.failed && callback.failed(message);
  }
}

export function* updatedServiceDetail(data) {
  const url = `${ServiceURL.serviceDetail}/${data.param}`;
  const callback = data.callback;
  try {
    const res = yield call(PUT, url, data.body);
    if (!!res.data?.error?.message) {
      yield put({
        type: typeActions.UPDATE_SERVICE_DETAIL_FAILED,
        error: res.data.error.message,
      });
      !!callback?.failed && callback.failed(res.data.error.message);
    } else {
      yield put({
        type: typeActions.UPDATE_SERVICE_DETAIL_SUCCESS,
        data: res.data,
      });

      !!callback?.success && callback.success(res.data);
    }
  } catch (error) {
    yield put({
      type: typeActions.UPDATE_SERVICE_DETAIL_FAILED,
      error: error?.response?.data?.error?.message ?? '',
    });
    !!callback?.failed &&
      callback.failed(error?.response?.data?.error?.message ?? '');
  }
}

export function* deletedServiceDetail(data) {
  const url = `${ServiceURL.serviceDetail}/${data.param}`;
  const callback = data.callback;
  try {
    const res = yield call(DELETE, url);
    if (!!res.data?.error?.message) {
      yield put({
        type: typeActions.DELETE_SERVICE_DETAIL_FAILED,
        error: res.data.error.message,
      });
      !!callback?.failed && callback.failed(res.data.error.message);
    } else {
      yield put({
        type: typeActions.DELETE_SERVICE_DETAIL_SUCCESS,
        data: res.data,
      });
      !!callback?.success && callback.success(res.data);
    }
  } catch (error) {
    yield put({
      type: typeActions.DELETE_SERVICE_DETAIL_FAILED,
      error: error?.response?.data?.error?.message ?? '',
    });
    !!callback?.failed &&
      callback.failed(error?.response?.data?.error?.message ?? '');
  }
}

export default function* serviceDetailSaga() {
  yield takeLatest(typeActions.GET_SERVICE_DETAILS_REQUEST, getdServiceDetails);
  yield takeLatest(typeActions.GET_SERVICE_DETAIL_REQUEST, getdServiceDetail);
  yield takeLatest(
    typeActions.CREATE_SERVICE_DETAIL_REQUEST,
    createdServiceDetail
  );
  yield takeLatest(
    typeActions.UPDATE_SERVICE_DETAIL_REQUEST,
    updatedServiceDetail
  );
  yield takeLatest(
    typeActions.DELETE_SERVICE_DETAIL_REQUEST,
    deletedServiceDetail
  );
}
