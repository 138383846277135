const HTGEN_ID = 1;

const DATA = {
  results: [],
  pagination: {
    page: 1,
    pageSize: 10,
    pageCount: 1,
    total: 0,
  },
};

const DEFAULT_EMPTY_STRING = 'Trống';
const ROLE_SUPPER_ADMIN = 'Admin_HTG';

const ACCOUNT_STATUS = [
  {
    value: true,
    label: 'Hoạt động',
  },
  {
    value: false,
    label: 'Khóa',
  },
];

const ACCOUNT_TYPE = [
  // { value: -1, label: 'Khác' },
  { value: 0, label: 'Thu mẫu' },
  { value: 1, label: 'Kinh doanh' },
  { value: 2, label: 'Quản lý' },
  { value: 3, label: 'Kế toán' },
  { value: 4, label: 'Bác sĩ' },
  { value: 5, label: 'Khách hàng' },
];

const ACCOUNT_GENDER = [
  { value: -1, label: 'Khác' },
  { value: 0, label: 'Nam' },
  { value: 1, label: 'Nữ' },
];

const ACCOUNT_LEVEL = [
  { value: 0, label: 'Cấp 0' },
  { value: 1, label: 'Cấp 1' },
  { value: 2, label: 'Cấp 2' },
  { value: 3, label: 'Cấp 3' },
  { value: 4, label: 'Cấp 4' },
  { value: 5, label: 'Cấp 5' },
  { value: 6, label: 'Cấp 6' },
  { value: 7, label: 'Cấp 7' },
  { value: 8, label: 'Cấp 8' },
  { value: 9, label: 'Cấp 9' },
  { value: 10, label: 'Cấp 10' },
];

const ACCOUNT_POSITIONS = [
  // { value: -1, label: 'Khác' },
  { value: 0, label: 'Giám đốc kinh doanh' },
  { value: 1, label: 'Kỹ thuật viên xét nghiệm' },
  { value: 2, label: 'Nhân viên kinh doanh' },
];

const ACCOUNT_SPECIAL_LIST_GR = [
  // { value: -1, label: 'Khác' },
  { value: 0, label: 'Sản' },
  { value: 1, label: 'Phôi' },
  { value: 2, label: 'Bệnh lý' },
];

const AREA_LEVEL = [
  { value: -1, label: 'Khác' },
  { value: 0, label: 'Nam' },
  { value: 1, label: 'Nữ' },
];

const PLUGIN = {
  import: 'plugin::content-manager.explorer.import',
  export: 'plugin::content-manager.explorer.export',
};

const TITLE_ROLE = [
  {
    value: 'create',
    label: 'Tạo',
  },
  {
    value: 'read',
    label: 'Đọc',
  },
  {
    value: 'update',
    label: 'Cập nhật',
  },
  {
    value: 'delete',
    label: 'Xóa',
  },
  {
    value: 'import',
    label: 'Nhập',
  },
  {
    value: 'export',
    label: 'Xuất',
  },
];

const ORGANIZATION_TYPE = [`Quản lý`, `Bệnh viện / Phòng khám`];

const ACTIVE_TYPE = [
  {
    value: 1,
    label: 'Hoạt động',
  },
  {
    value: 0,
    label: 'Không hoạt động',
  },
  // {
  //   value: -1,
  //   label: 'Khóa',
  // },
];
const ORGANIZATION_STATUS_TYPE = [
  {
    value: 0,
    label: 'Hoạt động',
  },
  {
    value: 1,
    label: 'Không hoạt động',
  },
];
// const PROVINCE = [
//   `TP. Hà Nội`,
//   `TP. Hồ Chí Minh`,
//   `Ninh Bình`,
//   `Vĩnh Phúc`,
//   `Hưng Yên`,
// ];
const SPECIMENS = [
  // { value: -1, label: 'Khác' },
  { value: 0, label: 'Máu ngoại vi' },
  { value: 1, label: 'Mẫu sinh thiết phôi' },
  { value: 2, label: 'Máu' },
  { value: 3, label: 'Niêm mạc' },
  { value: 4, label: 'Dịch ối' },
  { value: 5, label: 'Phết tăm bông' },
];

const MEDICATIONS_USING = [
  { value: 'no', label: 'Không' },
  { value: 'tonic', label: 'Thuốc bổ' },
  { value: 'endocrine_drugs', label: 'Thuốc nội tiết' },
  { value: 'other_medications', label: 'Các loại thuốc khác' },
];

const PROMOTION_FORMS = [
  { value: 0, label: 'Theo % tổng tiền' },
  { value: 1, label: 'Giảm trực tiếp tiền' },
];

const PROMOTION_STATUS = [
  { value: true, label: 'Họat động' },
  { value: false, label: 'Đóng' },
];
const ORGANIZATION_METHOD_TYPE = [
  { value: 0, label: 'Nợ tiền mặt' },
  { value: 1, label: 'Tiền mặt' },
  { value: 2, label: 'Hợp đồng' },
];

const MARRIED = [
  { value: false, label: 'Chưa kết hôn' },
  { value: true, label: 'Đã kết hôn' },
];

const CHANNEL = [
  // { value: -1, label: 'Khác' },
  { value: 0, label: 'Bệnh viện' },
  { value: 1, label: 'Phòng mạch tư' },
  { value: 2, label: 'Phòng khám tư' },
  { value: 3, label: 'Tư vấn trực tiếp' },
];

const SPECIFY_VOTE_RESULT_TYPE = [
  // { value: -1, label: 'Đóng' },
  { value: 0, label: 'Khởi tạo' },
  // { value: 1, label: 'Đã cập nhật' },
  { value: 4, label: 'Đang xét nghiệm' },
  // { value: 5, label: 'Đang chờ bổ sung mẫu' },
  { value: 6, label: ' Đã có kết quả' },
  // { value: 7, label: 'Đã trả kết quả' },
];
const SPECIFY_VOTE_RESULT_COLOR = [
  { value: -1, label: 'danger' },
  { value: 0, label: 'info' },
  { value: 1, label: 'danger' },
  { value: 2, label: 'success' },
  { value: 4, label: 'danger' },
  { value: 5, label: 'danger' },
  { value: 6, label: 'warning' },
  { value: 7, label: 'warning' },
];

const BARCODE_STATUS = [
  {
    label: 'Chưa sử dụng',
    value: false,
  },
  {
    label: 'Đã sử dụng',
    value: true,
  },
];

const ORDER_STATUS = [
  { value: -1, label: 'Hủy', color: 'dark' },
  { value: 0, label: 'Khởi tạo', color: 'light' },
  { value: 1, label: 'Yêu cầu bổ sung', color: 'secondary' },
  { value: 2, label: 'Đã cập nhật', color: 'primary' },
  { value: 3, label: 'Chờ chuyển xét nghiệm', color: 'info' },
  { value: 4, label: 'Đã chuyển xét nghiệm', color: 'info' },
  { value: 5, label: 'Đang xét nghiệm', color: 'danger' },
  { value: 6, label: 'Đang xét nghiệm (mẫu lỗi)', color: 'danger' },
  { value: 7, label: 'Đang xét nghiệm (chạy lại)', color: 'danger' },
  { value: 8, label: 'Chờ trả kết quả', color: 'info' },
  { value: 9, label: 'Chờ hội chẩn', color: 'info' },
  { value: 10, label: 'Đã trả kết quả', color: 'success' },
  { value: 11, label: 'Chờ duyệt kết quả', color: 'info' },
  { value: 12, label: 'Duyệt kết quả lỗi', color: 'danger' },
];

const SPECIFY_VOTE_STATUS = [
  { value: -1, label: 'Hủy' },
  { value: 0, label: 'Khởi tạo' },
  { value: 1, label: 'Đang xét nghiệm' },
  { value: 2, label: 'Đang xét nghiệm(mẫu lỗi)' },
  { value: 3, label: 'Đã có kết quả' },
];

const PAYMENT_STATUS = [
  { value: false, label: 'Chưa thanh toán' },
  { value: true, label: 'Đã thanh toán' },
];

const PAYMENT_METHOD = [
  { value: 0, label: 'Tiền mặt' },
  { value: 1, label: 'Chuyển khoản' },
];

const TEST_TYPE = [
  {
    value: 3,
    label: 'NIPT 3',
  },
  {
    value: 5,
    label: 'NIPT 5',
  },
  {
    value: 24,
    label: 'NIPT 24',
  },
];

const SPECIFY_VOTES_TYPE_PREGNANCY = [
  {
    value: 0,
    label: 'Thai đơn',
  },
  {
    value: 1,
    label: 'Song thai',
  },
];
const SPECIFY_VOTES_NO_PHOI = [
  {
    value: 0,
    label: 1,
  },
  {
    value: 1,
    label: 2,
  },
  {
    value: 2,
    label: 3,
  },
  {
    value: 3,
    label: 4,
  },
  {
    value: 4,
    label: 5,
  },
];

const TYPE_PREGNANCY = [
  {
    label: 'Thai đơn',
    value: 0,
  },
  {
    label: 'Song thai',
    value: 1,
  },
];
const TYPE_PATIENT = [
  {
    label: 'Khác',
    value: -1,
  },
  {
    label: 'Sản',
    value: 0,
  },
  {
    label: 'Ung thư',
    value: 1,
  },
];

const HISTORY_PAYMENT_METHOD = [
  {
    label: 'Tiền mặt',
    value: 0,
  },
  {
    label: 'Chuyển khoản',
    value: 1,
  },
];

const SUBMITTER = [
  {
    label: 'Khách hàng',
    value: 0,
  },
  {
    label: 'Nhân viên sale',
    value: 1,
  },
  {
    label: 'Nhân viên thu mẫu',
    value: 2,
  },
];

const SPECIFY_VOTE_NXN = [
  {
    label: 'Sản',
    value: 0,
  },
  {
    label: 'Phôi',
    value: 1,
  },
  {
    label: 'Bệnh lý',
    value: 2,
  },
];
const PERMISSIONS_BASIS = [
  'read-area',
  'read-organization',
  'read-service',
  'read-promotion',
  'read-partner',
  'read-barcode',
  'read-template',
];

const PERMISSIONS_SYSTEM = ['read-account', 'read-role'];

const PERMISSION_ADMIN = [
  'create-specify-vote-all',
  'create-patient',
  'read-specify-vote-all',
  'read-patient',
  'update-specify-vote-all',
  'update-patient',
  'delete-specify-vote-all',
  'delete-patient',
  'create-order-all',
  'create-order-init',
  'create-order-unpaid',
  'read-order-all',
  'read-order-init',
  'read-order-unpaid',
  'update-order-all',
  'update-order-init',
  'update-order-unpaid',
  'delete-order-all',
  'delete-order-init',
  'delete-order-unpaid',
  'read-synthesis-sample-transfer',
  'update-synthesis-sample-transfer',
  'read-receive-result',
  'update-receive-result',
  'create-area',
  'create-organization',
  'create-service',
  'create-promotion',
  'create-partner',
  'create-barcode',
  'create-template',
  'read-area',
  'read-organization',
  'read-service',
  'read-promotion',
  'read-partner',
  'read-barcode',
  'read-template',
  'update-area',
  'update-organization',
  'update-service',
  'update-promotion',
  'update-partner',
  'update-barcode',
  'update-template',
  'delete-area',
  'delete-organization',
  'delete-service',
  'delete-promotion',
  'delete-partner',
  'delete-barcode',
  'delete-template',
  'create-account',
  'create-role',
  'read-account',
  'read-role',
  'update-account',
  'update-role',
  'delete-account',
  'delete-role',
];

const ORDER_TYPE = [
  {
    label: 'Đơn hàng NIPT',
    value: 0,
  },
  {
    label: 'Đơn hàng khác',
    value: 1,
  },
];

const ORDER_EXPORT = [
  { header: 'stt', key: 'stt' },
  { header: 'Mã đơn hàng', key: 'code' },
  { header: 'Tên đơn hàng', key: 'name' },
  {
    header: 'Pk/bv',
    key: 'organizationName',
  },
  { header: 'Bác sĩ chỉ định', key: 'bsName' },
  {
    header: 'Ngày chỉ định',
    key: 'specifyVoteDate',
  },
  { header: 'sale', key: 'codeStaff1Name' },
  { header: 'Thu mẫu', key: 'codeStaff2Name' },
  { header: 'Dịch vụ', key: 'specifyVoteNameTest' },
  {
    header: 'Nội dung xét nghiệm',
    key: 'specifyVoteContent',
  },
  { header: 'Ghi chú', key: 'note' },
  {
    header: 'Tổng tiền',
    key: 'billTotalMoney',
  },
  {
    header: 'Trạng thái thanh toán',
    key: 'paymentStatus',
  },
  {
    header: 'Thông tin thanh toán',
    key: 'paymentInfos',
  },
  {
    header: 'Ngày thanh toán',
    key: 'paymentDateLastest',
  },
];

const SPECIFYVOTE_EXPORT = [
  { header: 'Tên bệnh nhân', key: 'nameBN' },
  { header: 'Mã phiếu xét nghiệm', key: 'codePCD' },
  { header: 'Mã bệnh nhân', key: 'codeBN' },
  { header: 'Email bệnh nhân', key: 'emailBN' },
  { header: 'Giới tính bệnh nhân', key: 'genderBN' },
  { header: 'Ngày sinh BN', key: 'dateOfBirthBN' },
  { header: 'Địa chỉ bệnh nhân', key: 'addressBN' },
  { header: 'SĐT bệnh nhân', key: 'phoneBN' },
  { header: 'Nghề nghiệp bệnh nhân', key: 'jobBN' },
  { header: 'Người liên hệ', key: 'nameContact' },
  { header: 'Số điện thoại liên hệ', key: 'phoneContact' },
  { header: 'Mã xét nghiệm', key: 'codeTest' },
  { header: 'SĐT bác sĩ', key: 'bsPhone' },
  { header: 'Nơi thu mẫu', key: 'whereTM' },
  { header: 'Tiền sử bệnh', key: 'anamnesis' },
  { header: 'Tiếp xúc độc hại', key: 'lifeStyle' },
  { header: 'Bản thân', key: 'result1' },
  { header: 'Người thân', key: 'result2' },
  { header: 'KQ combined test', key: 'result3' },
  { header: 'KQ siêu âm', key: 'result4' },
  { header: 'Bệnh lý cấp tính', key: 'pathological1' },
  { header: 'Bệnh lý mãn tính', key: 'pathological2' },
  { header: 'Tiền sử gia đình', key: 'familyHistory' },
  { header: 'Tiền sử bệnh nhân', key: 'anamnesisBA' },
  { header: 'Đối chứng âm', key: 'negativeControl' },
  { header: 'Triệu chứng', key: 'symptom' },
  { header: 'Chẩn đoán', key: 'diagnose' },
  { header: 'Chẩn đoán hình ảnh', key: 'imageAnalysation' },
  { header: 'Xét nghiệm liên quan', key: 'test' },
  { header: 'Cách thức điều trị', key: 'treatment' },
  { header: 'Dấu hiệu kháng thuốc', key: 'drugResistance' },
  { header: 'Tái phát bệnh', key: 'diseaseRecurrence' },
  { header: 'Dung dịch chứa tế bào', key: 'cellSolution' },
  { header: 'Người sinh thiết', key: 'biopsyPerson' },
  { header: 'Mẫu xét nghiệm', key: 'specimens' },
  { header: 'Chiều cao BN', key: 'height' },
  { header: 'Chiều dài đầu mông', key: 'height1' },
  { header: 'Trạng thái PCĐ', key: 'specifyVoteStatus' },
  { header: 'Nhóm xét nghiệm', key: 'testGR' },
  { header: 'Cân nặng BN', key: 'weight' },
  { header: 'Tuần thai', key: 'weekPregnancy' },
  { header: 'Ngày thu mẫu', key: 'dateTM' },
  { header: 'Ngày sinh thiết', key: 'biopsyDay' },
  { header: 'Nhân tế bào', key: 'cellNucleus' },
  { header: 'T.Gian điều trị', key: 'timeTreatment' },
  { header: 'Thuốc đang dùng', key: 'medicationsUsing' },
  { header: 'Trạng thái phôi', key: 'embryoState' },
  { header: 'Đánh giá hình thái phôi', key: 'embryoEvaluation' },
  { header: 'Số lượng thai', key: 'typePregnancy' },
  // { header: 'Cân nặng thai', key: 'weight1' },
  { header: 'Số phôi tạo ra', key: 'numOfEmbryos1' },
  { header: 'Số thứ tự phôi', key: 'noPhoi' },
  { header: 'Ngày thai', key: 'dayOfPregnancy' },
  { header: 'Ngày siêu âm', key: 'ultrasoundDays' },
  { header: 'Số lượng phôi', key: 'numOfEmbryos' },
  { header: 'Độ mờ da gáy', key: 'nappySkin' },
];

const CONCLUSION_FORMS = [
  { value: 0, label: 'Mẫu kết luận 1' },
  { value: 1, label: 'Mẫu kết luận 2' },
  { value: 2, label: 'Mẫu kết luận 3' },
];

const DEPARTMENTS = [
  {
    label: 'Văn phòng A(Hà Nội)',
    value: 'Hà Nội',
    areaName: 'Hà Nội',
  },
  {
    label: 'Văn phòng B(Đà Nẵng)',
    value: 'Đà Nẵng',
    areaName: 'Đà Nẵng',
  },
  {
    label: 'Văn phòng C(Hồ Chí Minh)',
    value: 'Hồ Chí Minh',
    areaName: 'Hồ Chí Minh',
  },
];

export default {
  DATA,
  ACTIVE_TYPE,
  ROLE_SUPPER_ADMIN,
  // account
  ACCOUNT_STATUS,
  ACCOUNT_TYPE,
  ACCOUNT_GENDER,
  ACCOUNT_LEVEL,
  ACCOUNT_POSITIONS,
  ACCOUNT_SPECIAL_LIST_GR,
  ORGANIZATION_METHOD_TYPE,
  PLUGIN,
  TITLE_ROLE,
  AREA_LEVEL,
  ORGANIZATION_TYPE,
  ORGANIZATION_STATUS_TYPE,
  SPECIMENS,
  PROMOTION_FORMS,
  PROMOTION_STATUS,
  SPECIFY_VOTE_RESULT_TYPE,
  SPECIFY_VOTE_RESULT_COLOR,
  MARRIED,
  CHANNEL,
  BARCODE_STATUS,
  ORDER_STATUS,
  PAYMENT_STATUS,
  TEST_TYPE,
  SPECIFY_VOTES_TYPE_PREGNANCY,
  SPECIFY_VOTES_NO_PHOI,
  TYPE_PREGNANCY,
  PAYMENT_METHOD,
  TYPE_PATIENT,
  HTGEN_ID,
  HISTORY_PAYMENT_METHOD,
  SUBMITTER,
  SPECIFY_VOTE_STATUS,
  SPECIFY_VOTE_NXN,

  // permissions
  PERMISSIONS_BASIS,
  PERMISSIONS_SYSTEM,
  PERMISSION_ADMIN,

  ORDER_TYPE,
  ORDER_EXPORT,
  DEFAULT_EMPTY_STRING,
  CONCLUSION_FORMS,
  DEPARTMENTS,
  MEDICATIONS_USING,
  SPECIFYVOTE_EXPORT,
};
