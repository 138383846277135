import React, { memo, useEffect, useState } from 'react';
import _ from 'lodash';
import {
  Button,
  Card,
  CardBody,
  CardHeader,
  Col,
  Container,
  Input,
  Row,
  Table,
} from 'reactstrap';
import queryString from 'query-string';
import { useHistory } from 'react-router';

import SimpleHeader from 'components/Headers/SimpleHeader.js';
import 'assets/css/custom-pages/react-bs-table.css';
import { useDispatch, useSelector } from 'react-redux';
import LabelCustom from '../../components/LabelCustom';
import { notificationAlertRef, notify } from 'common';
import LoadingButtonCustom from '../../components/LoadingButtonCustom';
import InputCustom from '../../components/InputCustom';
import LabelValue from '../../components/LabelValue';
import SelectCustom from '../../components/SelectCustom';
import SwitchCustom from '../../components/SwitchCustom';
import RowTable from './RowTable';
import {
  additionalSampleActions,
  orderActions,
  serviceActions,
  serviceDetailActions,
} from 'redux/actions';
import CONSTANT from 'constant';
import moment from 'moment';

const resultsDetail = [
  {
    content: 'Tam bội NST 21 (Trisomy 21)',
    value: '',
    normal: 2,
    suspect: '2 - 3',
    abnormal: 3,
    result: '',
  },
  {
    content: 'Tam bội NST 18 (Trisomy 18)',
    value: '',
    normal: 2,
    suspect: '2 - 3',
    abnormal: 3,
    result: '',
  },
  {
    content: 'Tam bội NST 13 (Trisomy 13)',
    value: '',
    normal: 2,
    suspect: '2 - 3',
    abnormal: 3,
    result: '',
  },
  {
    content: 'Monosomy X',
    value: '',
    normal: 2,
    suspect: '2 - 3',
    abnormal: 3,
    result: '',
  },
  // {
  //   content: 'cfDNA thai (%)',
  //   value: '',
  //   normal: '',
  //   suspect: '',
  //   abnormal: '',
  //   result: '',
  // },
];

function ResultsXN({
  resultXN,
  listType,
  handleDeleteOrder,
  handleUpdateOrder,
  idxO,
}) {
  // const history = useHistory();
  const dispatch = useDispatch();

  // const [orders, setOrders] = useState([]);
  const [orderValue, setOrderValue] = useState(null);
  // const [orderSearch, setOrderSearch] = useState('');

  // useEffect(() => {
  //   // Sau 500ms thì bắn api
  //   const delayDebounceFn = setTimeout(() => {
  //     handleGetOrders();
  //   }, 500);
  //   return () => clearTimeout(delayDebounceFn);
  // }, [orderSearch]);

  useEffect(() => {
    // if (!_.isEmpty(orders)) return;
    const delayDebounceFn = setTimeout(() => {
      if (listType === 'order') {
        handleGetOrders();
      } else {
        handleGetAdditionalSamples();
      }
    }, 500);
    return () => clearTimeout(delayDebounceFn);
  }, [resultXN?.orderCode]);

  const handleGetOrders = () => {
    const query = {
      'filters[$and][1][status][$eq]': CONSTANT.ORDER_STATUS[6].value,
      'filters[$and][2][code][$eq]': resultXN?.orderCode ?? '',
    };

    dispatch(
      orderActions.getOrderBy(
        queryString.stringify(query),
        {
          success: (data) => {
            if (!_.isEmpty(data)) {
              setOrderValue(data);
              handleUpdateOrder(
                '',
                {
                  ...resultXN,
                  order: data?.id ?? -1,
                  labCode: data?.labCode ?? '',
                },
                idxO
              );
            } else {
              notify(
                notificationAlertRef,
                'danger',
                'Thông báo',
                `Đơn hàng ${resultXN?.orderCode ?? ''
                } không phải đơn đang xét nghiệm!`
              );
            }
          },
          failed: () => { },
        },
        false
      )
    );
  };

  const handleGetAdditionalSamples = () => {
    const query = {
      'filters[$and][1][status][$eq]': CONSTANT.ORDER_STATUS[6].value,
      'filters[$and][2][order][code][$eq]': resultXN?.orderCode ?? '',
    };

    dispatch(
      additionalSampleActions.getAdditionalSampleBy(
        queryString.stringify(query),
        {
          success: (data) => {
            if (!_.isEmpty(data)) {
              setOrderValue({ ...data.order, ...data });
              handleUpdateOrder(
                '',
                {
                  ...resultXN,
                  order: data?.order?.id ?? -1,
                  labCode: data?.labCode ?? '',
                },
                idxO
              );
            } else {
              notify(
                notificationAlertRef,
                'danger',
                'Thông báo',
                `Mẫu bổ sung đơn hàng ${resultXN?.orderCode ?? ''
                } không phải mẫu đang xét nghiệm!`
              );
            }
          },
          failed: () => { },
        },
        false
      )
    );
  };

  const handleUpdateResultDetail = (name, value, idxR) => {
    handleUpdateOrder('resultsDetail', value, idxO, idxR, name);
  };

  return (
    <div>
      <Container className="" fluid>
        <Row>
          <div className="col">
            <Card>
              <CardBody>
                <Row>
                  <Col xs={12} md={6} lg={4}>
                    <LabelValue
                      labelWidth={110}
                      label={'Mã đơn hàng'}
                      value={orderValue?.code ?? ''}
                    />
                    {/* <SelectCustom
                      name="name"
                      label="Mã đơn hàng"
                      placeholder="Lựa chọn"
                      value={orderValue}
                      isRequired={true}
                      isClearable={true}
                      handleChange={(e) => {
                        setOrderValue(e);
                        handleGetServiceDetail(e);
                      }}
                      handleBlur={() => {}}
                      onInputChange={(value) => {
                        setOrderSearch(value);
                      }}
                      error={false}
                      touched={false}
                      options={orders.map((item) => ({
                        label: item?.code ?? '',
                        value: item.id,
                        ...item,
                      }))}
                      row
                      labelWidth={110}
                    /> */}
                  </Col>
                  <Col
                    className="d-flex justify-content-end"
                    xs={12}
                    md={6}
                    lg={8}
                  >
                    <Button
                      onClick={() => handleDeleteOrder(idxO)}
                      color="danger"
                      className="text-nowrap mr-1"
                    >
                      Xóa
                    </Button>
                  </Col>
                </Row>
                <Row>
                  <Col xs={12} md={6} lg={3}>
                    <LabelValue
                      labelWidth={110}
                      label={'Tên dịch vụ xét nghiệm'}
                      value={orderValue?.specifyVote?.nameTest ?? ''}
                    // value={''}
                    />
                  </Col>
                  <Col xs={12} md={6} lg={3}>
                    <LabelValue
                      labelWidth={110}
                      label={'Họ tên BN'}
                      value={orderValue?.specifyVote?.nameBN ?? ''}
                    // value={resultXN?.Name ?? ''}
                    />
                  </Col>
                  <Col xs={12} md={6} lg={3}>
                    <LabelValue
                      labelWidth={110}
                      label={'Ngày sinh'}
                      value={orderValue?.specifyVote?.dateOfBirthBN ?? ''}
                    // value={resultXN?.NgaySinh ?? ''}
                    />
                  </Col>
                  <Col xs={12} md={6} lg={3}>
                    <LabelValue
                      labelWidth={110}
                      label={'Số điện thoại'}
                      value={orderValue?.specifyVote?.phoneBN ?? ''}
                    // value={''}
                    />
                  </Col>
                </Row>
                <Row>
                  <Col xs={12} md={6} lg={3}>
                    <LabelValue
                      labelWidth={110}
                      label={'Bác sĩ chỉ định'}
                      value={`${orderValue?.specifyVote?.lastNameBS ?? ''} ${orderValue?.specifyVote?.firstNameBS ?? ''
                        }`}
                    // value={resultXN?.BSChiDinh ?? ''}
                    />
                  </Col>
                  <Col xs={12} md={6} lg={3}>
                    <LabelValue
                      labelWidth={110}
                      label={'Nơi thu mẫu'}
                      value={orderValue?.specifyVote?.whereTM}
                    // value={''}
                    />
                  </Col>
                  <Col xs={12} md={6} lg={3}>
                    <LabelValue
                      labelWidth={110}
                      label={'Ngày thu mẫu'}
                      value={
                        orderValue?.specifyVote?.dateTM
                          ? moment(orderValue.specifyVote.dateTM).format(
                            'DD/MM/YYYY'
                          )
                          : ''
                      }
                    // value={''}
                    />
                  </Col>
                  <Col
                    xs={12}
                    md={6}
                    lg={3}
                    className={'d-flex align-items-start'}
                  >
                    <LabelCustom
                      isRequired={false}
                      label={'Cần hội chẩn:'}
                      id={''}
                      style={{
                        whiteSpace: 'nowrap',
                        margin: '0px 8px 0px 0px',
                      }}
                      styleLabel={{
                        minWidth: 125,
                      }}
                    />
                    <SwitchCustom
                      className="custom-toggle custom-toggle-primary"
                      value="update"
                      labelOff="Tắt"
                      labelOn="Bật"
                      checked={resultXN?.consultation ?? false}
                      onChange={(e) => {
                        handleUpdateOrder(
                          'consultation',
                          e.target.checked,
                          idxO
                        );
                      }}
                    />
                  </Col>
                </Row>
                <Row>
                  <Col xs={12} md={6} lg={4}>
                    <InputCustom
                      name="labCode"
                      label="Mã labcode"
                      type="text"
                      placeholder="Nhập mã labcode"
                      value={resultXN?.labCode ?? ''}
                      // value={resultXN?.Code ?? ''}
                      isRequired={true}
                      handleChange={(e) => {
                        handleUpdateOrder('labCode', e.target.value, idxO);
                      }}
                      handleBlur={() => { }}
                      error={false}
                      touched={false}
                      row
                      labelWidth={110}
                    />
                  </Col>
                </Row>
                <Table>
                  <thead>
                    <th
                      className="h3 font-weight-600 p-2"
                      style={{ paddingRight: 16, minWidth: 200 }}
                    >
                      NỘI DUNG XÉT NGHIỆM
                    </th>
                    <th
                      style={{ minWidth: 80 }}
                      className="h3 font-weight-600 p-2"
                    >
                      GIÁ TRỊ NGUY CƠ
                    </th>
                    {/* <th className="h3 font-weight-600 p-2 text-center">
                      BÌNH THƯỜNG
                    </th>
                    <th className="h3 font-weight-600 p-2 text-center">
                      NGHI NGỜ
                    </th>
                    <th className="h3 font-weight-600 p-2 text-center">
                      BẤT THƯỜNG
                    </th> */}
                    <th className="h3 font-weight-600 p-2">KẾT QUẢ</th>
                  </thead>
                  <tbody>
                    {_.get(resultXN, 'resultsDetail', []).map((item, index) => {
                      return (
                        <RowTable
                          key={index}
                          item={item}
                          idxR={index}
                          handleUpdateResultDetail={handleUpdateResultDetail}
                        // resultDetail={_.get(resultXN, 'resultDetail', [])}
                        />
                      );
                    })}
                  </tbody>
                </Table>
              </CardBody>
            </Card>
          </div>
        </Row>
      </Container>
    </div>
  );
}

export default memo(ResultsXN);
