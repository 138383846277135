import React, { useEffect, useRef, useState } from 'react';
import _ from 'lodash';
import queryString from 'query-string';
import { useHistory } from 'react-router';
import { useDispatch, useSelector } from 'react-redux';
import { Button, Col, Modal, Row } from 'reactstrap';
import { formActions, orderActions } from 'redux/actions';
import InputCustom from 'views/pages/components/InputCustom';
import { notificationAlertRef, notify } from 'common';
import CONSTANT from 'constant';

const ConfirmBarcode = () => {
  const refBarcodePcd = useRef();
  const refBarcode1 = useRef();
  const refBarcode2 = useRef();
  const refBarcode3 = useRef();
  const history = useHistory();
  const { isModalConfirmBarcodeVisible } = useSelector(
    (state) => state.formReducer
  );
  const { order } = useSelector((state) => state.orderReducer);
  const dispatch = useDispatch();
  const [barcodes, setBarcodes] = useState({
    barcodePcd: '',
    barcode1: '',
    barcode2: '',
    barcode3: '',
  });

  const handleClose = () => {
    dispatch(
      formActions.setValue({
        name: 'isModalConfirmBarcodeVisible',
        value: false,
      })
    );
    setBarcodes({
      barcodePcd: '',
      barcode1: '',
      barcode2: '',
      barcode3: '',
    });
  };

  const checkValidBarcodes = (position, specimens = []) => {
    const isValid =
      _.uniq(
        Object.keys(barcodes)
          .map((key) => barcodes[key])
          .filter((barcode) => barcode !== '')
      ).length === 1 ||
      _.uniq(
        Object.keys(barcodes)
          .map((key) => barcodes[key])
          .filter((barcode) => barcode !== '')
      ).length === 0;

    if (!isValid) {
      notify(
        notificationAlertRef,
        'danger',
        'Thông báo',
        `Mã barcode không khớp!`
      );
    } else {
      if (
        Object.keys(barcodes)
          .map((key) => barcodes[key])
          .filter((item) => item !== '').length ===
        specimens.length + 1
      ) {
        handleClose();
        history.push(
          `/admin/synthesis-sample-transfer/receive?barcode=${
            _.uniq(
              Object.keys(barcodes)
                .map((key) => barcodes[key])
                .filter((barcode) => barcode !== '')
            )[0]
          }`
        );
        return;
      }
    }

    if (!order || specimens.length <= position) return;
    switch (position + 1) {
      case 1:
        refBarcode1.current.focus();
        break;
      case 2:
        refBarcode2.current.focus();
        break;
      case 3:
        refBarcode3.current.focus();
        break;
      default:
        break;
    }
  };

  const handleGetOrderBy = (value) => {
    // Là đơn có barcode đã sử dụng, đơn đã thanh toán, khác đơn hủy và khác đơn đã trả
    dispatch(
      orderActions.getOrderBy(
        queryString.stringify({
          'filters[$or][1][barcode1][$eq]': value,
          // 'filters[$or][2][barcode2][$eq]': value,
          // 'filters[$or][3][barcode3][$eq]': value,
          // 'filters[$or][4][barcode4][$eq]': value,
          // 'filters[$or][5][barcode5][$eq]': value,
          'filters[$and][6][status][$ne]': CONSTANT.ORDER_STATUS[0].value,
          'filters[$and][7][status][$ne]': CONSTANT.ORDER_STATUS[11].value,
          'filters[$and][8][receptionStatus][$eq]': false,
          // 'filters[$and][4][bill][paymentStatus][$eq]':
          //   CONSTANT.PAYMENT_STATUS[1].value,
        }),
        {
          success: (data) => {
            if (_.isEmpty(data)) return;
            checkValidBarcodes(0, data.specifyVote.specimens);
          },
        }
      )
    );
  };

  useEffect(() => {
    if (refBarcodePcd?.current) {
      refBarcodePcd.current.focus();
    }
  }, [refBarcodePcd?.current?.focus, isModalConfirmBarcodeVisible]);

  return (
    <Modal
      onOpened={() => {
        if (refBarcodePcd?.current) {
          refBarcodePcd.current.focus();
        }
      }}
      size={'md'}
      isOpen={isModalConfirmBarcodeVisible}
      className="modal-dialog-centered modal-secondary"
      toggle={() => {
        handleClose();
      }}
    >
      <div className="modal-header border-bottom bg-white pb-2">
        <h2>Vui lòng quét các mã barcode phiếu xét nghiệm và mẫu</h2>
        <button
          aria-label="Close"
          className="close"
          data-dismiss="modal"
          type="button"
          onClick={() => {
            handleClose();
          }}
        >
          <span style={{ color: '#262626' }} aria-hidden={true}>
            ×
          </span>
        </button>
      </div>
      <div className="modal-body bg-white rounded">
        <Row>
          <Col xs={6}>
            <InputCustom
              ref={refBarcodePcd}
              name="barcodePcd"
              label="Mã barcode phiếu xét nghiệm"
              type="text"
              placeholder="Quét barcode phiếu xét nghiệm"
              value={barcodes.barcodePcd}
              handleChange={(e) => {
                setBarcodes({
                  ...barcodes,
                  barcodePcd: e.target.value,
                });
                handleGetOrderBy(e.target.value);
              }}
              onKeyDown={(e) => {
                if (e.key === 'Enter') {
                }
              }}
            />
          </Col>
          <Col xs={6}>
            <InputCustom
              ref={refBarcode1}
              name="barcode1"
              label="Mã barcode mẫu 1"
              type="text"
              placeholder="Quét barcode mẫu 1"
              value={barcodes.barcode1}
              onKeyDown={(e) => {
                if (e.key === 'Enter') {
                  checkValidBarcodes(1, order?.specifyVote?.specimens ?? []);
                }
              }}
              styleInput={{
                display:
                  order && (order?.specifyVote?.specimens ?? []).length > 0
                    ? 'block'
                    : 'none',
              }}
              styleLabel={{
                display:
                  order && (order?.specifyVote?.specimens ?? []).length > 0
                    ? 'block'
                    : 'none',
              }}
              handleChange={(e) => {
                setBarcodes({
                  ...barcodes,
                  barcode1: e.target.value,
                });
              }}
            />
          </Col>
        </Row>
        <Row>
          <Col xs={6}>
            {order && (order?.specifyVote?.specimens ?? []).length > 1 && (
              <InputCustom
                ref={refBarcode2}
                name="barcode2"
                label="Mã barcode mẫu 2"
                type="text"
                placeholder="Quét barcode mẫu 2"
                value={barcodes.barcode2}
                onKeyDown={(e) => {
                  if (e.key === 'Enter') {
                    checkValidBarcodes(2, order?.specifyVote?.specimens ?? []);
                  }
                }}
                handleChange={(e) => {
                  setBarcodes({
                    ...barcodes,
                    barcode2: e.target.value,
                  });
                }}
              />
            )}
          </Col>
          <Col xs={6}>
            {order && (order?.specifyVote?.specimens ?? []).length > 2 && (
              <InputCustom
                ref={refBarcode3}
                name="barcode3"
                label="Mã barcode mẫu 3"
                type="text"
                placeholder="Quét barcode mẫu 3"
                value={barcodes.barcode3}
                onKeyDown={(e) => {
                  if (e.key === 'Enter') {
                    checkValidBarcodes(3, order?.specifyVote?.specimens ?? []);
                  }
                }}
                handleChange={(e) => {
                  setBarcodes({
                    ...barcodes,
                    barcode3: e.target.value,
                  });
                }}
              />
            )}
          </Col>
        </Row>
        <Row>
          <Col xs={12} className="d-flex justify-content-end">
            <Button
              onClick={() => {
                handleClose();
              }}
            >
              <span>Huỷ</span>
            </Button>
          </Col>
        </Row>
      </div>
    </Modal>
  );
};

export default ConfirmBarcode;
