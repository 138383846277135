import React, { memo, useEffect, useState } from 'react';
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory from 'react-bootstrap-table2-paginator';
import filterFactory from 'react-bootstrap-table2-filter';
import ToolkitProvider from 'react-bootstrap-table2-toolkit';
import _ from 'lodash';
import {
  Badge,
  Card,
  CardHeader,
  Container,
  Input,
  Row,
  UncontrolledTooltip,
} from 'reactstrap';
import queryString from 'query-string';
import DatePicker from 'react-datepicker';

import { checkRole, notificationAlertRef, notify } from 'common';
import { promotionActions, formActions } from 'redux/actions';
import SimpleHeader from 'components/Headers/SimpleHeader.js';
import 'assets/css/custom-pages/react-bs-table.css';
import { useDispatch, useSelector } from 'react-redux';
import Loading from 'views/pages/components/Loading';
import CUPromotion from './components/CUPromotion';
import { useDidUpdateEffect } from 'utils/HookUtils';
import CONSTANT from 'constant';
import { currencyFormat } from 'common';
import ModalWarningCustom from 'views/pages/components/ModalWarningCustom';

import IconAdd from 'assets/svgs/add.svg';
import IconEdit from 'assets/svgs/edit.svg';
import IconEye from 'assets/svgs/eye.svg';
import moment from 'moment';
import TableCustom from '../components/TableCustom';
import LabelCustom from '../components/LabelCustom';
import CustomInputCalendar from '../components/CustomInputCalendar';

function Promotion() {
  const dispatch = useDispatch();
  const {
    page,
    limit,
    promotions,
    sort,
    isDeletingPromotion,
    startDate,
    endDate,
  } = useSelector((state) => state.promotionReducer);
  const { currentAccountExtend } = useSelector((state) => state.accountReducer);

  const results = _.get(promotions, 'results', []);
  const total = _.get(promotions, 'pagination.total', 0);

  const [promotionSearch, setPromotionSearch] = useState('');
  const [notificationModal, setNotificationModal] = useState(false);
  const [promotion, setPromotion] = useState(null);

  const columns = [
    {
      dataField: 'name',
      text: 'TÊN CHƯƠNG TRÌNH',
    },
    {
      dataField: 'form',
      text: 'HÌNH THỨC',
      formatter: (cell) => {
        const promotionFormValue = CONSTANT.PROMOTION_FORMS.find(
          (item) => item.value === cell
        ) ?? { label: '' };
        return promotionFormValue.label;
      },
    },
    {
      dataField: 'valuePromotion',
      text: 'GIÁ TRỊ',
      formatter: (cell, row) => {
        const promotionFormValue = CONSTANT.PROMOTION_FORMS.find(
          (item) => item.value === row.form
        ) ?? { label: '', value: -1 };
        switch (promotionFormValue.value) {
          case 0:
            return `${cell} %`;
          default:
            return currencyFormat(Number(cell));
        }
      },
      headerStyle: {
        textAlign: 'right',
      },
      style: {
        textAlign: 'right',
      },
    },
    {
      dataField: 'startDate',
      text: 'THỜI GIAN BẮT ĐẦU',
      formatter: (cell) => {
        return !!cell ? moment(cell).format('DD/MM/YYYY') : '';
      },
      headerStyle: {
        textAlign: 'center',
      },
      style: {
        textAlign: 'center',
      },
    },
    {
      dataField: 'endDate',
      text: 'THỜI GIAN KẾT THÚC',
      formatter: (cell) => {
        return !!cell ? moment(cell).format('DD/MM/YYYY') : '';
      },
      headerStyle: {
        textAlign: 'center',
      },
      style: {
        textAlign: 'center',
      },
    },
    {
      dataField: 'status',
      text: 'TRẠNG THÁI',
      formatter: (cell) => {
        if (cell) {
          return <Badge color="success">Hoạt động</Badge>;
        }
        return <Badge color="danger">Đóng</Badge>;
      },
      headerStyle: {
        textAlign: 'center',
      },
      style: {
        textAlign: 'center',
      },
    },
    {
      dataField: 'actions',
      text: 'HÀNH ĐỘNG',
      formatter: boxActions,
      headerStyle: {
        textAlign: 'center',
      },
      headerClasses: 'fixed-column',
      classes: 'fixed-column',
    },
  ];

  function boxActions(__, row) {
    return (
      <div className="d-flex justify-content-center">
        {/* <button
          id={'add' + row.id}
          onClick={() => {
            
          }}
          className="btn p-0 btn-no-shadow mr-0"
        >
          <img src={IconAdd} className="mr-1" />
        </button>
        <UncontrolledTooltip delay={1} placement="top" target={'add' + row.id}>
          Thêm chi tiết khuyến mại
        </UncontrolledTooltip>

        <button
          id={'view' + row.id}
          onClick={() => {
            
          }}
          className="btn p-0 btn-no-shadow mr-0"
        >
          <img src={IconEye} className="mr-1" />
        </button>
        <UncontrolledTooltip delay={1} placement="top" target={'view' + row.id}>
          Xem chi tiết chương trình khuyến mại
        </UncontrolledTooltip> */}

        {checkRole(currentAccountExtend, {
          roles: ['update-promotion'],
        }) && (
            <>
              <button
                id={'edit' + row.id}
                onClick={() => {
                  dispatch(
                    formActions.setValue({
                      name: 'modalInfo',
                      value: row,
                    })
                  );
                  dispatch(
                    formActions.setValue({
                      name: 'isModalVisible',
                      value: true,
                    })
                  );
                }}
                className="btn p-0 btn-no-shadow mr-0"
              >
                <img src={IconEdit} className="mr-1" />
              </button>
              <UncontrolledTooltip
                delay={1}
                placement="top"
                target={'edit' + row.id}
              >
                Cập nhật khuyến mại
              </UncontrolledTooltip>
            </>
          )}
      </div>
    );
  }

  const onSizePerPageChange = (e) => {
    dispatch(promotionActions.setValue({ name: 'page', value: 1 }));
    dispatch(
      promotionActions.setValue({ name: 'limit', value: e.target.value })
    );
  };

  const onPageChange = (value) => {
    dispatch(promotionActions.setValue({ name: 'page', value: value }));
  };

  const pagination = paginationFactory({
    page: page,
    sizePerPage: limit,
    totalSize: total,
    showTotal: false,
    withFirstAndLast: false,
    alwaysShowAllBtns: true,
    onPageChange: onPageChange,
    sizePerPageRenderer: () => {
      return (
        <div className="dataTables_length" id="datatable-basic_length">
          <label>
            Hiển thị{' '}
            {
              <select
                name="datatable-basic_length"
                aria-controls="datatable-basic"
                className="form-control form-control-sm"
                onChange={onSizePerPageChange}
              >
                <option value="10">10</option>
                <option value="25">25</option>
                <option value="50">50</option>
                <option value="100">100</option>
              </select>
            }{' '}
            dòng.
          </label>
        </div>
      );
    },
  });

  useDidUpdateEffect(() => {
    handleGetPromotions();
  }, [page, limit, startDate, endDate]);

  useDidUpdateEffect(() => {
    // Sau 500ms thì bắn api
    const delayDebounceFn = setTimeout(() => {
      handleGetPromotions();
    }, 500);
    return () => clearTimeout(delayDebounceFn);
  }, [promotionSearch]);

  useEffect(() => {
    if (!_.isEmpty(promotions)) return;
    handleGetPromotions();
  }, []);

  const handleGetPromotions = () => {
    const query = {
      pageSize: limit,
      page,
      sort,
    };
    if (promotionSearch !== '') {
      query['_q'] = promotionSearch;
    }

    if (!!startDate) {
      query['filters[$and][1][startDate][$gte]'] = new Date(
        startDate
      ).toISOString();
    }
    if (!!endDate) {
      query['filters[$and][2][endDate][$lte]'] = new Date(
        endDate
      ).toISOString();
    }
    dispatch(
      formActions.setValue({
        name: 'filterInfo',
        value: {
          startDate: !!startDate ? new Date(startDate).toISOString() : '',
          endDate: !!endDate ? new Date(endDate).toISOString() : '',
        },
      })
    );
    dispatch(promotionActions.getPromotions(queryString.stringify(query)));
  };

  const handleDelete = () => {
    if (promotion === null) return;

    if (
      moment().unix() > moment(promotion.startDate).unix() &&
      moment().unix() < moment(promotion.endDate).unix()
    )
      return notify(
        notificationAlertRef,
        'warning',
        'Thông báo',
        `Mã khuyến mại đang diễn ra, không được xóa!`
      );
    dispatch(
      promotionActions.updatePromotion(
        promotion.id,
        { deleted: true },
        {
          success: () => {
            setNotificationModal(false);
            notify(
              notificationAlertRef,
              'success',
              'Thông báo',
              `Xóa khuyến mại ${promotion?.name} thành công!`
            );
            handleGetPromotions();
          },
          failed: (mess) => {
            notify(
              notificationAlertRef,
              'danger',
              'Thông báo',
              `Xóa khuyến mại ${promotion?.name} thất bại!. Lỗi: ${mess}`
            );
          },
        }
      )
    );
  };

  const headerLeftComponent = () => {
    return (
      <div style={{ zIndex: 9999 }}>
        <div className="date-picker-custom w-200 d-flex">
          <LabelCustom
            label={'Từ:'}
            style={{ textAlign: 'end', margin: '0.5em 8px 0px 0px' }}
            styleLabel={{
              minWidth: 60,
            }}
          />
          <DatePicker
            customInput={<CustomInputCalendar />}
            wrapperClassName="icon-calendar"
            dateFormat={'dd/MM/yyyy'}
            selected={startDate}
            maxDate={endDate}
            onChange={(date) => {
              const startOfDate = moment(date).startOf('date').toDate();
              dispatch(
                promotionActions.setValue({
                  name: 'startDate',
                  value: startOfDate,
                })
              );
            }}
          />
          <LabelCustom
            label={'đến:'}
            style={{ textAlign: 'end', margin: '0.5em 8px 0px 0px' }}
            styleLabel={{
              minWidth: 60,
            }}
          />
          <DatePicker
            dateFormat={'dd/MM/yyyy'}
            customInput={<CustomInputCalendar />}
            wrapperClassName="icon-calendar"
            minDate={startDate}
            selected={endDate}
            onChange={(date) => {
              const endOfDate = moment(date).endOf('date').toDate();
              dispatch(
                promotionActions.setValue({
                  name: 'endDate',
                  value: endOfDate,
                })
              );
            }}
          />
        </div>
      </div>
    );
  };

  return (
    <>
      <SimpleHeader />
      <CUPromotion handleGetPromotions={handleGetPromotions} />
      <ModalWarningCustom
        notificationModal={notificationModal}
        setNotificationModal={setNotificationModal}
        name={`khuyến mại ${promotion?.name ?? ''}`}
        func={handleDelete}
        isDelete={isDeletingPromotion}
      />
      <Container className="mt--9" fluid>
        <Row>
          <div className="col">
            <TableCustom
              tableName="Danh sách chương trình khuyến mại"
              data={results}
              columns={columns}
              page={page}
              limit={limit}
              total={total}
              searchBy={'Tìm kiếm'}
              pagination={pagination}
              search={promotionSearch}
              onChange={(e) => {
                setPromotionSearch(e.target.value);
              }}
              headerLeftComponent={headerLeftComponent}
            />
          </div>
        </Row>
      </Container>
    </>
  );
}

export default memo(Promotion);
