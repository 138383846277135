import typeActions from 'redux/typeActions';
const setValuePatient = ({ name, value }) => {
  return {
    type: typeActions.SET_PATIENT_VALUE,
    name,
    value,
  };
};
const getPatients = (query, callback, isUpdateReducer = true) => {
  return {
    type: typeActions.GET_PATIENT_REQUEST,
    query,
    callback,
    isUpdateReducer,
  };
};
const getPatientById = (param, query, callback) => {
  return {
    type: typeActions.GET_PATIENT_BY_ID_REQUEST,
    param,
    query,
    callback,
  };
};
const createPatient = (params, callback) => {
  return {
    type: typeActions.CREATE_PATIENT_REQUEST,
    params,
    callback,
  };
};
const updatePatient = (params, body, callback) => {
  return {
    type: typeActions.UPDATE_PATIENT_REQUEST,
    params,
    body,
    callback,
  };
};
const deletePatient = (params, callback) => {
  return {
    type: typeActions.DELETE_PATIENT_REQUEST,
    params,
    callback,
  };
};

const exportPatient = (query, callback) => {
  return {
    type: typeActions.EXPORT_PATIENT_REQUEST,
    callback,
    query,
  };
};
export default {
  setValuePatient,
  getPatients,
  deletePatient,
  updatePatient,
  createPatient,
  getPatientById,
  exportPatient
};
