import typeActions from 'redux/typeActions';
import CONSTANT from 'constant';
import { returnValue } from 'common';

const initialState = {
  // loading
  isGettingServiceDetails: false,
  isGettingServiceDetail: false,
  isCreatingServiceDetail: false,
  isUpdatingServiceDetail: false,
  isDeletingServiceDetail: false,
  // data
  serviceDetails: {},
  serviceDetail: {},
  // error
  errors: {
    getServiceDetails: '',
    getServiceDetail: '',
    createServiceDetail: '',
    updateServiceDetail: '',
    deleteServiceDetail: '',
  },
  // query
  search: '',
  page: 1,
  limit: 10,
  startDate: null,
  endDate: null,
};

const serviceDetailReducer = (state = initialState, actions) => {
  switch (actions.type) {
    case typeActions.SET_SERVICE_DETAIL_VALUE:
      const { name, value } = actions;
      if (typeof name !== 'string') return { ...state };
      const obj = returnValue(state, name, value);

      return {
        ...state,
        ...obj,
      };

    case typeActions.GET_SERVICE_DETAILS_REQUEST:
      return {
        ...state,
        isGettingServiceDetails: true,
        errors: {
          ...state.errors,
          getServiceDetails: '',
        },
      };
    case typeActions.GET_SERVICE_DETAILS_SUCCESS:
      return {
        ...state,
        serviceDetails: actions.data ?? {},
        isGettingServiceDetails: false,
        errors: {
          ...state.errors,
          getServiceDetails: '',
        },
      };
    case typeActions.GET_SERVICE_DETAILS_FAILED:
      return {
        ...state,
        serviceDetails: {},
        isGettingServiceDetails: false,
        errors: {
          ...state.errors,
          getServiceDetails: actions.error,
        },
      };

    case typeActions.GET_SERVICE_DETAIL_REQUEST:
      return {
        ...state,
        isGettingServiceDetail: true,
        errors: {
          ...state.errors,
          getServiceDetail: '',
        },
      };
    case typeActions.GET_SERVICE_DETAIL_SUCCESS:
      return {
        ...state,
        serviceDetail: actions.data || {},
        isGettingServiceDetail: false,
        errors: {
          ...state.errors,
          getServiceDetail: '',
        },
      };
    case typeActions.GET_SERVICE_DETAIL_FAILED:
      return {
        ...state,
        serviceDetail: {},
        isGettingServiceDetail: false,
        errors: {
          ...state.errors,
          getServiceDetail: actions.error,
        },
      };

    case typeActions.CREATE_SERVICE_DETAIL_REQUEST:
      return {
        ...state,
        isCreatingServiceDetail: true,
        errors: {
          ...state.errors,
          createServiceDetail: '',
        },
      };
    case typeActions.CREATE_SERVICE_DETAIL_SUCCESS:
      return {
        ...state,
        isCreatingServiceDetail: false,
        errors: {
          ...state.errors,
          createServiceDetail: '',
        },
      };
    case typeActions.CREATE_SERVICE_DETAIL_FAILED:
      return {
        ...state,
        isCreatingServiceDetail: false,
        errors: {
          ...state.errors,
          createServiceDetail: actions.error,
        },
      };

    case typeActions.UPDATE_SERVICE_DETAIL_REQUEST:
      return {
        ...state,
        isUpdatingServiceDetail: true,
        errors: {
          ...state.errors,
          updateServiceDetail: '',
        },
      };
    case typeActions.UPDATE_SERVICE_DETAIL_SUCCESS:
      return {
        ...state,
        isUpdatingServiceDetail: false,
        errors: {
          ...state.errors,
          updateServiceDetail: '',
        },
      };
    case typeActions.UPDATE_SERVICE_DETAIL_FAILED:
      return {
        ...state,
        isUpdatingServiceDetail: false,
        errors: {
          ...state.errors,
          updateServiceDetail: actions.error,
        },
      };

    case typeActions.DELETE_SERVICE_DETAIL_REQUEST:
      return {
        ...state,
        isDeletingServiceDetail: true,
        errors: {
          ...state.errors,
          deleteServiceDetail: '',
        },
      };
    case typeActions.DELETE_SERVICE_DETAIL_SUCCESS:
      return {
        ...state,
        isDeletingServiceDetail: false,
        errors: {
          ...state.errors,
          deleteServiceDetail: '',
        },
      };
    case typeActions.DELETE_SERVICE_DETAIL_FAILED:
      return {
        ...state,
        isDeletingServiceDetail: false,
        errors: {
          ...state.errors,
          deleteServiceDetail: actions.error,
        },
      };

    default:
      return {
        ...state,
      };
  }
};
export default serviceDetailReducer;
