import typeActions from 'redux/typeActions';

const getStatistic = (query, callback, isUpdateReducer = true) => {
  return {
    type: typeActions.GET_STATISTIC_REQUEST,
    query,
    callback,
    isUpdateReducer,
  };
};

export default {
  getStatistic,
};
