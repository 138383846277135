import _ from 'lodash';
import { takeLatest, call, put, all, takeEvery } from 'redux-saga/effects';

import typeActions from 'redux/typeActions';
import ServiceURL, { BASE_URL } from 'services/ServiceURL';
import { DELETE, GET, GET_FILE, POST, PUT } from 'services/ServiceBase';
import { custom } from 'services/axiosClient';

export function* getOrders(data) {
  const url = `${ServiceURL.order}?filters[$and][0][deleted][$eq]=false&${
    data.query
  }${data.query.includes('sort=') ? '' : '&sort=createdAt:DESC'}`;
  const callback = data.callback;
  try {
    const res = yield call(GET, url);
    if (!!res.data?.error?.message) {
      yield put({
        type: typeActions.GET_ORDERS_FAILED,
        error: res.data?.error?.message,
      });
      !!callback?.failed && callback.failed(res.data.error.message);
    } else {
      if (data.isUpdateReducer) {
        yield put({
          type: typeActions.GET_ORDERS_SUCCESS,
          data: res.data,
        });
      }
      !!callback?.success && callback.success(res.data);
    }
  } catch (error) {
    yield put({
      type: typeActions.GET_ORDERS_FAILED,
      error: error?.response?.data?.error?.message ?? '',
    });
    !!callback?.failed &&
      callback.failed(error?.response?.data?.error?.message ?? '');
  }
}

export function* getOrder(data) {
  const url = `${ServiceURL.order}/${data.param}?${data.query}`;
  const callback = data.callback;
  try {
    const res = yield call(GET, url);
    if (!!res.data?.error?.message) {
      yield put({
        type: typeActions.GET_ORDER_FAILED,
        error: res.data.error.message,
      });
      !!callback?.failed && callback.failed(res.data.error.message);
    } else {
      let relationToCodeStaff1 = {};
      let relationToCodeStaff2 = {};
      let relationToCodeStaff3 = {};
      let relationToPromotionDetail = {};
      let relationToOrganization = {};
      let relationToSpecifyVote = {};
      let relationToBill = {};
      let relationToPatient = {};
      let relationToMedicalRecord = {};
      const orderId = _.get(res, 'data.id', -1);
      const orderCode = _.get(res, 'data.code', '');
      try {
        relationToCodeStaff1 = yield handleGetRelation(
          orderId,
          orderCode,
          'codeStaff1'
        );
        relationToCodeStaff2 = yield handleGetRelation(
          orderId,
          orderCode,
          'codeStaff2'
        );
        relationToCodeStaff3 = yield handleGetRelation(
          orderId,
          orderCode,
          'codeStaff3'
        );
        relationToPromotionDetail = yield handleGetRelation(
          orderId,
          orderCode,
          'promotionDetail'
        );
        relationToOrganization = yield handleGetRelation(
          orderId,
          orderCode,
          'organization'
        );
        relationToSpecifyVote = yield handleGetRelation(
          orderId,
          orderCode,
          'specifyVote'
        );
        relationToBill = yield handleGetRelation(orderId, orderCode, 'bill');
        const specifyVoteId = _.get(
          relationToSpecifyVote,
          'data.results[0].id',
          -1
        );
        relationToPatient = yield handleGetRelation(
          specifyVoteId,
          orderCode,
          'patient'
        );
        relationToMedicalRecord = yield handleGetRelation(
          specifyVoteId,
          orderCode,
          'medicalRecord'
        );
      } catch (error) {}
      const codeStaff1 = _.get(relationToCodeStaff1, 'data.results[0]', null);
      const codeStaff2 = _.get(relationToCodeStaff2, 'data.results[0]', null);
      const codeStaff3 = _.get(relationToCodeStaff3, 'data.results[0]', null);
      const promotionDetail = _.get(
        relationToPromotionDetail,
        'data.results[0]',
        null
      );
      const organization = _.get(
        relationToOrganization,
        'data.results[0]',
        null
      );
      const specifyVote = _.get(relationToSpecifyVote, 'data.results[0]', null);
      const bill = _.get(relationToBill, 'data.results[0]', null);
      const patient = _.get(relationToPatient, 'data.results[0]', null);
      const medicalRecord = _.get(
        relationToMedicalRecord,
        'data.results[0]',
        null
      );
      const resData = {
        ...res.data,
        codeStaff1,
        codeStaff2,
        codeStaff3,
        promotionDetail,
        organization,
        specifyVote: !!specifyVote
          ? {
              ...specifyVote,
              patient,
              medicalRecord,
            }
          : null,
        bill,
      };
      yield put({
        type: typeActions.GET_ORDER_SUCCESS,
        data: resData,
      });
      !!callback?.success && callback.success(resData);
    }
  } catch (error) {
    yield put({
      type: typeActions.GET_ORDER_FAILED,
      error: error?.response?.data?.error?.message ?? '',
    });
    !!callback?.failed &&
      callback.failed(error?.response?.data?.error?.message ?? '');
  }
}

export function* getOrderBy(data) {
  const url = `${ServiceURL.order}?${data.query}`;
  const callback = data.callback;
  try {
    const res = yield call(GET, url);
    if (!!res.data?.error?.message) {
      yield put({
        type: typeActions.GET_ORDER_FAILED,
        error: res.data.error.message,
      });
      !!callback?.failed && callback.failed(res.data.error.message);
    } else {
      const resData = _.get(res, 'data.results[0]', {});
      yield put({
        type: typeActions.GET_ORDER_SUCCESS,
        data: resData,
      });
      !!callback?.success && callback.success(resData);
    }
  } catch (error) {
    yield put({
      type: typeActions.GET_ORDER_FAILED,
      error: error?.response?.data?.error?.message ?? '',
    });
  }
}

export function* createOrder(data) {
  const url = `${ServiceURL.order}`;
  const callback = data.callback;
  try {
    const res = yield call(POST, url, data.body);
    if (!!res.data?.error?.message) {
      yield put({
        type: typeActions.CREATE_ORDER_FAILED,
        error: res.data.error.message,
      });
      !!callback?.failed && callback.failed(res.data.error.message);
    } else {
      yield put({
        type: typeActions.CREATE_ORDER_SUCCESS,
        data: res.data,
      });
      !!callback?.success && callback.success(res.data);
    }
  } catch (error) {
    yield put({
      type: typeActions.CREATE_ORDER_FAILED,
      error: error?.response?.data?.error?.message ?? '',
    });
    !!callback?.failed &&
      callback.failed(error?.response?.data?.error?.message ?? '');
  }
}

export function* updateOrder(data) {
  const url = `${ServiceURL.order}/${data.param}`;
  const callback = data.callback;
  try {
    const res = yield call(PUT, url, data.body);
    if (!!res.data?.error?.message) {
      yield put({
        type: typeActions.UPDATE_ORDER_FAILED,
        error: res.data.error.message,
      });
      !!callback?.failed && callback.failed(res.data.error.message);
    } else {
      yield put({
        type: typeActions.UPDATE_ORDER_SUCCESS,
        data: res.data,
      });

      !!callback?.success && callback.success(res.data);
    }
  } catch (error) {
    yield put({
      type: typeActions.UPDATE_ORDER_FAILED,
      error: error?.response?.data?.error?.message ?? '',
    });
    !!callback?.failed &&
      callback.failed(error?.response?.data?.error?.message ?? '');
  }
}

export function* deleteOrder(data) {
  const url = `${ServiceURL.order}/${data.param}`;
  const callback = data.callback;
  try {
    const res = yield call(DELETE, url);
    if (!!res.data?.error?.message) {
      yield put({
        type: typeActions.DELETE_ORDER_FAILED,
        error: res.data.error.message,
      });
      !!callback?.failed && callback.failed(res.data.error.message);
    } else {
      yield put({
        type: typeActions.DELETE_ORDER_SUCCESS,
        data: res.data,
      });
      !!callback?.success && callback.success(res.data);
    }
  } catch (error) {
    yield put({
      type: typeActions.DELETE_ORDER_FAILED,
      error: error?.response?.data?.error?.message ?? '',
    });
    !!callback?.failed &&
      callback.failed(error?.response?.data?.error?.message ?? '');
  }
}

function* handleGetRelation(id, code, relationTo) {
  try {
    // const urlRelation = `${ServiceURL.relation}/api::order.order/${orderId}/${relationTo}?pageSize=100`;
    // const response = yield call(GET, urlRelation);
    // const id = _.get(response, 'data.data.id', -1);
    // if (id === -1) return null;
    switch (relationTo) {
      case 'codeStaff1':
        const urlCodeStaff1 = `${ServiceURL.accountExtend}?filters[$and][0][order1s][id]=${id}&pageSize=99999`;
        return yield call(GET, urlCodeStaff1);
      case 'codeStaff2':
        const urlCodeStaff2 = `${ServiceURL.accountExtend}?filters[$and][0][order2s][id]=${id}&pageSize=99999`;
        return yield call(GET, urlCodeStaff2);
      case 'codeStaff3':
        const urlCodeStaff3 = `${ServiceURL.accountExtend}?filters[$and][0][order3s][id]=${id}&pageSize=99999`;
        return yield call(GET, urlCodeStaff3);
      case 'promotionDetail':
        const urlPromotionDetail = `${ServiceURL.promotionDetail}?filters[$and][0][orders][code]=${code}&pageSize=99999`;
        return yield call(GET, urlPromotionDetail);
      case 'organization':
        const urlOrganization = `${ServiceURL.organization}?filters[$and][0][orders][code]=${code}&pageSize=99999`;
        return yield call(GET, urlOrganization);
      case 'specifyVote':
        const urlSpecifyVote = `${ServiceURL.specifyvote}?filters[$and][0][order][id]=${id}&pageSize=99999`;
        return yield call(GET, urlSpecifyVote);
      case 'bill':
        const urlBill = `${ServiceURL.bill}?filters[$and][0][order][id]=${id}&pageSize=99999`;
        return yield call(GET, urlBill);
      case 'patient':
        const urlPatient = `${ServiceURL.patient}?filters[$and][0][specifyVotes][id]=${id}&pageSize=99999`;
        return yield call(GET, urlPatient);
      case 'medicalRecord':
        const urlMedicalRecord = `${ServiceURL.medicalRecord}?filters[$and][0][specifyVote][id]=${id}&pageSize=99999`;
        return yield call(GET, urlMedicalRecord);
      default:
        return null;
    }
  } catch (error) {
    return null;
  }
}

export function* exportOrder(data) {
  const url = `${ServiceURL.exportOrder}?${data.query}`;
  const callback = data.callback;
  try {
    const res = yield call(GET_FILE, url);
    if (!!res.data?.error?.message) {
      yield put({
        type: typeActions.EXPORT_ORDER_FAILED,
        error: res.data?.error?.message,
      });
      !!callback?.failed && callback.failed(res.data.error.message);
    } else {
      yield put({
        type: typeActions.EXPORT_ORDER_SUCCESS,
        data: res.data,
      });

      !!callback?.success && callback.success(res.data);
    }
  } catch (error) {
    yield put({
      type: typeActions.EXPORT_ORDER_FAILED,
      error: error?.response?.data?.error?.message ?? '',
    });
    !!callback?.failed &&
      callback.failed(error?.response?.data?.error?.message ?? '');
  }
}
export function* exportOrderNeedUpdate(data) {
  const url = `${ServiceURL.exportOrderNeedUpdate}?${data.query}`;
  const callback = data.callback;
  try {
    const res = yield call(GET_FILE, url);
    if (!!res.data?.error?.message) {
      yield put({
        type: typeActions.EXPORT_ORDER_NEED_UPDATE_FAILED,
        error: res.data?.error?.message,
      });
      !!callback?.failed && callback.failed(res.data.error.message);
    } else {
      yield put({
        type: typeActions.EXPORT_ORDER_NEED_UPDATE_SUCCESS,
        data: res.data,
      });

      !!callback?.success && callback.success(res.data);
    }
  } catch (error) {
    yield put({
      type: typeActions.EXPORT_ORDER_NEED_UPDATE_FAILED,
      error: error?.response?.data?.error?.message ?? '',
    });
    !!callback?.failed &&
      callback.failed(error?.response?.data?.error?.message ?? '');
  }
}
export function* exportOrderUnapproved(data) {
  const url = `${ServiceURL.exportOrderUnapproved}?${data.query}`;
  const callback = data.callback;
  try {
    const res = yield call(GET_FILE, url);
    if (!!res.data?.error?.message) {
      yield put({
        type: typeActions.EXPORT_ORDER_UNAPPROVED_FAILED,
        error: res.data?.error?.message,
      });
      !!callback?.failed && callback.failed(res.data.error.message);
    } else {
      yield put({
        type: typeActions.EXPORT_ORDER_UNAPPROVED_SUCCESS,
        data: res.data,
      });

      !!callback?.success && callback.success(res.data);
    }
  } catch (error) {
    yield put({
      type: typeActions.EXPORT_ORDER_UNAPPROVED_FAILED,
      error: error?.response?.data?.error?.message ?? '',
    });
    !!callback?.failed &&
      callback.failed(error?.response?.data?.error?.message ?? '');
  }
}
export function* exportOrderSynthesisSampleTransfer(data) {
  const url = `${ServiceURL.exportOrderSynthesisSampleTransfer}?${data.query}`;
  const callback = data.callback;
  try {
    const res = yield call(GET_FILE, url);
    if (!!res.data?.error?.message) {
      yield put({
        type: typeActions.EXPORT_ORDER_SYNTHESIS_SAMPLE_TRANSFER_FAILED,
        error: res.data?.error?.message,
      });
      !!callback?.failed && callback.failed(res.data.error.message);
    } else {
      yield put({
        type: typeActions.EXPORT_ORDER_SYNTHESIS_SAMPLE_TRANSFER_SUCCESS,
        data: res.data,
      });

      !!callback?.success && callback.success(res.data);
    }
  } catch (error) {
    yield put({
      type: typeActions.EXPORT_ORDER_SYNTHESIS_SAMPLE_TRANSFER_FAILED,
      error: error?.response?.data?.error?.message ?? '',
    });
    !!callback?.failed &&
      callback.failed(error?.response?.data?.error?.message ?? '');
  }
}
export function* exportOrderReceiveResult(data) {
  const url = `${ServiceURL.exportOrderReceiveResult}?${data.query}`;
  const callback = data.callback;
  try {
    const res = yield call(GET_FILE, url);
    if (!!res.data?.error?.message) {
      yield put({
        type: typeActions.EXPORT_ORDER_RECEIVE_RESULT_FAILED,
        error: res.data?.error?.message,
      });
      !!callback?.failed && callback.failed(res.data.error.message);
    } else {
      yield put({
        type: typeActions.EXPORT_ORDER_RECEIVE_RESULT_SUCCESS,
        data: res.data,
      });

      !!callback?.success && callback.success(res.data);
    }
  } catch (error) {
    yield put({
      type: typeActions.EXPORT_ORDER_RECEIVE_RESULT_FAILED,
      error: error?.response?.data?.error?.message ?? '',
    });
    !!callback?.failed &&
      callback.failed(error?.response?.data?.error?.message ?? '');
  }
}
export function* exportOrderApproveResult(data) {
  const url = `${ServiceURL.exportOrderApproveResult}?${data.query}`;
  const callback = data.callback;
  try {
    const res = yield call(GET_FILE, url);
    if (!!res.data?.error?.message) {
      yield put({
        type: typeActions.EXPORT_ORDER_APPROVE_RESULT_FAILED,
        error: res.data?.error?.message,
      });
      !!callback?.failed && callback.failed(res.data.error.message);
    } else {
      yield put({
        type: typeActions.EXPORT_ORDER_APPROVE_RESULT_SUCCESS,
        data: res.data,
      });

      !!callback?.success && callback.success(res.data);
    }
  } catch (error) {
    yield put({
      type: typeActions.EXPORT_ORDER_APPROVE_RESULT_FAILED,
      error: error?.response?.data?.error?.message ?? '',
    });
    !!callback?.failed &&
      callback.failed(error?.response?.data?.error?.message ?? '');
  }
}
export function* exportOrderPayResult(data) {
  const url = `${ServiceURL.exportOrderPayResult}?${data.query}`;
  const callback = data.callback;
  try {
    const res = yield call(GET_FILE, url);
    if (!!res.data?.error?.message) {
      yield put({
        type: typeActions.EXPORT_ORDER_PAY_RESULT_FAILED,
        error: res.data?.error?.message,
      });
      !!callback?.failed && callback.failed(res.data.error.message);
    } else {
      yield put({
        type: typeActions.EXPORT_ORDER_PAY_RESULT_SUCCESS,
        data: res.data,
      });

      !!callback?.success && callback.success(res.data);
    }
  } catch (error) {
    yield put({
      type: typeActions.EXPORT_ORDER_PAY_RESULT_FAILED,
      error: error?.response?.data?.error?.message ?? '',
    });
    !!callback?.failed &&
      callback.failed(error?.response?.data?.error?.message ?? '');
  }
}

export function* printPkq(data) {
  const url = `${ServiceURL.printPkq}/${data.param}`;
  const callback = data.callback;
  try {
    const res = yield call(GET_FILE, url);
    if (!!res.data?.error?.message) {
      yield put({
        type: typeActions.PRINT_PKQ_FAILED,
        error: res.data?.error?.message,
      });
      !!callback?.failed && callback.failed(res.data.error.message);
    } else {
      yield put({
        type: typeActions.PRINT_PKQ_SUCCESS,
        data: res.data,
      });

      !!callback?.success && callback.success(res.data);
    }
  } catch (error) {
    yield put({
      type: typeActions.PRINT_PKQ_FAILED,
      error: error?.response?.data?.error?.message ?? '',
    });
    !!callback?.failed &&
      callback.failed(error?.response?.data?.error?.message ?? '');
  }
}

export function* updateLabCodes(data) {
  const callback = data.callback;
  try {
    const formData = new FormData();
    formData.append(`files`, data.file);

    const res = yield custom('api/update-labcodes', formData, 'post', BASE_URL);
    if (!!res.data?.error?.message) {
      yield put({
        type: typeActions.UPDATE_LABCODES_FAILED,
        error: res.data?.error?.message,
      });
      !!callback?.failed && callback.failed(res.data.error.message);
    } else {
      yield put({
        type: typeActions.UPDATE_LABCODES_SUCCESS,
        data: res,
      });

      !!callback?.success && callback.success(res);
    }
  } catch (error) {
    yield put({
      type: typeActions.UPDATE_LABCODES_FAILED,
      error: error?.response?.data?.error?.message ?? '',
    });
    !!callback?.failed &&
      callback.failed(error?.response?.data?.error?.message ?? '');
  }
}

export function* sendToZalo(data) {
  const callback = data.callback;
  try {
    const res = yield custom(
      `api/send-to-zalo/${data.param}`,
      data.body,
      'post',
      BASE_URL
    );
    if (!!res.data?.error?.message) {
      !!callback?.failed && callback.failed(res.data.error.message);
    } else {
      !!callback?.success && callback.success(res);
    }
  } catch (error) {
    !!callback?.failed &&
      callback.failed(error?.response?.data?.error?.message ?? '');
  }
}

export default function* orderSaga() {
  yield takeLatest(typeActions.GET_ORDERS_REQUEST, getOrders);
  yield takeLatest(typeActions.GET_ORDER_REQUEST, getOrder);
  yield takeLatest(typeActions.CREATE_ORDER_REQUEST, createOrder);
  yield takeEvery(typeActions.UPDATE_ORDER_REQUEST, updateOrder);
  yield takeLatest(typeActions.DELETE_ORDER_REQUEST, deleteOrder);
  yield takeEvery(typeActions.GET_ORDER_BY_REQUEST, getOrderBy);
  yield takeLatest(typeActions.EXPORT_ORDER_REQUEST, exportOrder);
  yield takeLatest(
    typeActions.EXPORT_ORDER_NEED_UPDATE_REQUEST,
    exportOrderNeedUpdate
  );
  yield takeLatest(
    typeActions.EXPORT_ORDER_UNAPPROVED_REQUEST,
    exportOrderUnapproved
  );
  yield takeLatest(
    typeActions.EXPORT_ORDER_SYNTHESIS_SAMPLE_TRANSFER_REQUEST,
    exportOrderSynthesisSampleTransfer
  );
  yield takeLatest(
    typeActions.EXPORT_ORDER_RECEIVE_RESULT_REQUEST,
    exportOrderReceiveResult
  );
  yield takeLatest(
    typeActions.EXPORT_ORDER_APPROVE_RESULT_REQUEST,
    exportOrderApproveResult
  );
  yield takeLatest(
    typeActions.EXPORT_ORDER_PAY_RESULT_REQUEST,
    exportOrderPayResult
  );
  yield takeEvery(typeActions.PRINT_PKQ_REQUEST, printPkq);
  yield takeLatest(typeActions.UPDATE_LABCODES_REQUEST, updateLabCodes);
  yield takeLatest(typeActions.SEND_TO_ZALO_REQUEST, sendToZalo);
}
