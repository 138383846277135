import typeActions from '../typeActions';
import CONSTANT from 'constant';
import { returnValue } from 'common';
import _ from 'lodash';

const initialState = {
  // data
  roles: [],
  roleById: {},
  permissions: {},
  currentPermissions: {},
  rolePermissions: [],
  rolesExtend: [],
  roleExtend: {},
  // loading
  isGetRoles: false,
  isGetRoleById: false,
  isCreateRole: false,
  isUpdateRole: false,
  isDeleteRole: false,
  isGetPermissions: false,
  isGetCurrentPermissions: false,
  isUpdatePermission: false,
  isGetRolePermissions: false,
  //
  isGetRolesExtend: false,
  isGetRoleExtend: false,
  isCreateRoleExtend: false,
  isUpdateRoleExtend: false,
  isDeleteRoleExtend: false,
  // error
  errors: {
    getRoles: '',
    getRoleById: '',
    createRole: '',
    updateRole: '',
    deleteRole: '',
    getPermissions: '',
    //
    getRolesExtend: '',
    getRoleExtend: '',
    createRoleExtend: '',
    updateRoleExtend: '',
    deleteRoleExtend: '',
  },
  page: 1,
  limit: 10,
  query: {
    populate: '*',
  },
};

export const roleReducer = (state = initialState, actions) => {
  switch (actions.type) {
    case typeActions.SET_ROLE_VALUE:
      const { name, value } = actions;
      if (typeof name !== 'string') return { ...state };
      const obj = returnValue(state, name, value);
      return {
        ...state,
        ...obj,
      };
    case typeActions.GET_ROLES_REQUEST:
      return {
        ...state,
        isGetRoles: true,
        // roles: { results: [] },
        errors: {
          ...state.errors,
          isGetRoles: '',
        },
      };
    case typeActions.GET_ROLES_SUCCESS:
      return {
        ...state,
        roles: actions.data || { results: [] },
        isGetRoles: false,
        errors: {
          ...state.errors,
          getRoles: '',
        },
      };
    case typeActions.GET_ROLES_FAILED:
      return {
        ...state,
        isGetRoles: false,
        roles: { results: [] },
        errors: {
          ...state.errors,
          getRoles: actions.error,
        },
      };

    case typeActions.GET_ROLE_BY_ID_REQUEST:
      return {
        ...state,
        isGetRoleById: true,
        // roleById: {},
        errors: {
          ...state.errors,
          getRoleById: '',
        },
      };
    case typeActions.GET_ROLE_BY_ID_SUCCESS:
      return {
        ...state,
        roleById: actions.data || {},
        isGetRoleById: false,
        errors: {
          ...state.errors,
          getRoleById: '',
        },
      };
    case typeActions.GET_ROLE_BY_ID_FAILED:
      return {
        ...state,
        isGetRoleById: false,
        roleById: {},
        errors: {
          ...state.errors,
          getRoleById: actions.error,
        },
      };

    case typeActions.CREATE_ROLE_REQUEST:
      return {
        ...state,
        isCreateRole: true,
        errors: {
          ...state.errors,
          createRole: '',
        },
      };
    case typeActions.CREATE_ROLE_SUCCESS:
      return {
        ...state,
        isCreateRole: false,
        errors: {
          ...state.errors,
          createRole: '',
        },
      };
    case typeActions.CREATE_ROLE_FAILED:
      return {
        ...state,
        isCreateRole: false,
        errors: {
          ...state.errors,
          createRole: actions.error,
        },
      };

    case typeActions.UPDATE_ROLE_REQUEST:
      return {
        ...state,
        isUpdateRole: true,
        errors: {
          ...state.errors,
          updateRole: '',
        },
      };
    case typeActions.UPDATE_ROLE_SUCCESS:
      return {
        ...state,
        isUpdateRole: false,
        errors: {
          ...state.errors,
          updateRole: '',
        },
      };
    case typeActions.UPDATE_ROLE_FAILED:
      return {
        ...state,
        isUpdateRole: false,
        errors: {
          ...state.errors,
          updateRole: actions.error,
        },
      };

    case typeActions.DELETE_ROLE_REQUEST:
      return {
        ...state,
        isDeleteRole: true,
        errors: {
          ...state.errors,
          deleteRole: '',
        },
      };
    case typeActions.DELETE_ROLE_SUCCESS:
      return {
        ...state,
        isDeleteRole: false,
        errors: {
          ...state.errors,
          deleteRole: '',
        },
      };
    case typeActions.DELETE_ROLE_FAILED:
      return {
        ...state,
        isDeleteRole: false,
        errors: {
          ...state.errors,
          deleteRole: actions.error,
        },
      };

    case typeActions.GET_PERMISSIONS_REQUEST:
      return {
        ...state,
        isGetPermissions: true,
        errors: {
          ...state.errors,
          getPermissions: actions.error,
        },
      };
    case typeActions.GET_PERMISSIONS_SUCCESS:
      const tmpActions = _.get(
        actions.data,
        'sections.collectionTypes.actions',
        []
      );
      const subjects = _.get(
        actions.data,
        'sections.collectionTypes.subjects',
        []
      );
      const subUids = subjects.map((item) => item?.uid ?? '');
      tmpActions.push({
        actionId: CONSTANT.PLUGIN.import,
        applyToProperties: ['fields', 'locales'],
        label: 'Import',
        subjects: subUids,
      });

      tmpActions.push({
        actionId: CONSTANT.PLUGIN.export,
        applyToProperties: ['fields', 'locales'],
        label: 'Export',
        subjects: subUids,
      });
      return {
        ...state,
        permissions: {
          ...actions.data,
          sections: {
            ...actions.data?.sections,
            collectionTypes: {
              ...actions.data?.sections?.collectionTypes,
              actions: tmpActions,
            },
          },
        },
        isGetPermissions: false,
        errors: {
          ...state.errors,
          getPermissions: actions.error,
        },
      };
    case typeActions.GET_PERMISSIONS_FAILED:
      return {
        ...state,
        permissions: {},
        isGetPermissions: false,
        errors: {
          ...state.errors,
          getPermissions: actions.error,
        },
      };

    case typeActions.GET_CURRENT_PERMISSIONS_REQUEST:
      return {
        ...state,
        isGetCurrentPermissions: true,
        errors: {
          ...state.errors,
          getCurrentPermissions: actions.error,
        },
      };
    case typeActions.GET_CURRENT_PERMISSIONS_SUCCESS:
      return {
        ...state,
        currentPermissions: actions.data,
        isGetCurrentPermissions: false,
        errors: {
          ...state.errors,
          getCurrentPermissions: actions.error,
        },
      };
    case typeActions.GET_CURRENT_PERMISSIONS_FAILED:
      return {
        ...state,
        currentPermissions: {},
        isGetCurrentPermissions: false,
        errors: {
          ...state.errors,
          getCurrentPermissions: actions.error,
        },
      };

    case typeActions.CREATE_PERMISSION_REQUEST:
      return {
        ...state,
        isUpdatePermission: true,
        errors: {
          ...state.errors,
          getCurrentPermissions: actions.error,
        },
      };
    case typeActions.CREATE_PERMISSION_SUCCESS:
      return {
        ...state,
        isUpdatePermission: false,
        errors: {
          ...state.errors,
          getCurrentPermissions: actions.error,
        },
      };
    case typeActions.CREATE_PERMISSION_FAILED:
      return {
        ...state,
        isUpdatePermission: false,
        errors: {
          ...state.errors,
          getCurrentPermissions: actions.error,
        },
      };

    case typeActions.GET_ROLE_PERMISSIONS_REQUEST:
      return {
        ...state,
        isGetRolePermissions: true,
        errors: {
          ...state.errors,
          getCurrentPermissions: actions.error,
        },
      };
    case typeActions.GET_ROLE_PERMISSIONS_SUCCESS:
      return {
        ...state,
        isGetRolePermissions: false,
        rolePermissions: actions.data,
        errors: {
          ...state.errors,
          getCurrentPermissions: actions.error,
        },
      };
    case typeActions.GET_ROLE_PERMISSIONS_FAILED:
      return {
        ...state,
        isGetRolePermissions: false,
        rolePermissions: [],
        errors: {
          ...state.errors,
          getCurrentPermissions: actions.error,
        },
      };

    case typeActions.GET_ROLES_EXTEND_REQUEST:
      return {
        ...state,
        isGetRolesExtend: true,
        errors: {
          ...state.errors,
          rolesExtend: '',
        },
      };
    case typeActions.GET_ROLES_EXTEND_SUCCESS:
      return {
        ...state,
        isGetRolesExtend: false,
        rolesExtend: actions.data,
        errors: {
          ...state.errors,
          rolesExtend: '',
        },
      };
    case typeActions.GET_ROLES_EXTEND_FAILED:
      return {
        ...state,
        isGetRolesExtend: false,
        errors: {
          ...state.errors,
          rolesExtend: actions.error,
        },
      };

    case typeActions.GET_ROLE_EXTEND_REQUEST:
      return {
        ...state,
        isGetRoleExtend: true,
        errors: {
          ...state.errors,
          getRoleExtend: '',
        },
      };
    case typeActions.GET_ROLE_EXTEND_SUCCESS:
      return {
        ...state,
        isGetRoleExtend: false,
        roleExtend: actions.data,
        errors: {
          ...state.errors,
          getRoleExtend: '',
        },
      };
    case typeActions.GET_ROLE_EXTEND_FAILED:
      return {
        ...state,
        isGetRoleExtend: false,
        errors: {
          ...state.errors,
          getRoleExtend: actions.error,
        },
      };

    case typeActions.CREATE_ROLE_EXTEND_REQUEST:
      return {
        ...state,
        isCreateRoleExtend: true,
        errors: {
          ...state.errors,
          createRoleExtend: '',
        },
      };
    case typeActions.CREATE_ROLE_EXTEND_SUCCESS:
      return {
        ...state,
        isCreateRoleExtend: false,
        errors: {
          ...state.errors,
          createRoleExtend: '',
        },
      };
    case typeActions.CREATE_ROLE_EXTEND_FAILED:
      return {
        ...state,
        isCreateRoleExtend: false,
        errors: {
          ...state.errors,
          createRoleExtend: actions.error,
        },
      };

    case typeActions.UPDATE_ROLE_EXTEND_REQUEST:
      return {
        ...state,
        isUpdateRoleExtend: true,
        errors: {
          ...state.errors,
          updateRoleExtend: '',
        },
      };
    case typeActions.UPDATE_ROLE_EXTEND_SUCCESS:
      return {
        ...state,
        isUpdateRoleExtend: false,
        errors: {
          ...state.errors,
          updateRoleExtend: '',
        },
      };
    case typeActions.UPDATE_ROLE_EXTEND_FAILED:
      return {
        ...state,
        isUpdateRoleExtend: false,
        errors: {
          ...state.errors,
          updateRoleExtend: actions.error,
        },
      };

    case typeActions.DELETE_ROLE_EXTEND_REQUEST:
      return {
        ...state,
        isDeleteRoleExtend: true,
        errors: {
          ...state.errors,
          deleteRoleExtend: '',
        },
      };
    case typeActions.DELETE_ROLE_EXTEND_SUCCESS:
      return {
        ...state,
        isDeleteRoleExtend: false,
        errors: {
          ...state.errors,
          deleteRoleExtend: '',
        },
      };
    case typeActions.DELETE_ROLE_EXTEND_FAILED:
      return {
        ...state,
        isDeleteRoleExtend: false,
        errors: {
          ...state.errors,
          deleteRoleExtend: actions.error,
        },
      };

    default:
      return {
        ...state,
      };
  }
};

export default roleReducer;
