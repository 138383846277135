import React, { memo, useEffect, useState } from 'react';
import paginationFactory from 'react-bootstrap-table2-paginator';
import _ from 'lodash';
import {
  Container,
  FormGroup,
  Input,
  Label,
  Row,
  UncontrolledTooltip,
} from 'reactstrap';
import queryString from 'query-string';
import { useHistory } from 'react-router';
import { useDispatch, useSelector } from 'react-redux';

import { areaActions, filterActions, orderActions } from 'redux/actions';
import SimpleHeader from 'components/Headers/SimpleHeader.js';
import 'assets/css/custom-pages/react-bs-table.css';
import { useDidUpdateEffect } from 'utils/HookUtils';
import CONSTANT from 'constant';
import { checkRole, currencyFormat } from 'common';

import IconEdit from 'assets/svgs/edit.svg';
import IconEye from 'assets/svgs/eye.svg';
import TableCustom from '../components/TableCustom';
import classNames from 'classnames';

function OrderInit() {
  const dispatch = useDispatch();
  const history = useHistory();
  const { page, limit, orders, status } = useSelector(
    (state) => state.orderReducer
  );
  const {
    currentAccountExtend,
    isAdmin,
    isHasChildrent,
    isBusinessStaff,
    childrentByCurrentAccountExtend,

    isSampleCollectorStaff,
  } = useSelector((state) => state.accountReducer);
  const { areasByAccountExtend } = useSelector((state) => state.areaReducer);
  const results = _.get(orders, 'results', []);
  const total = _.get(orders, 'pagination.total', 0);

  const [orderSearch, setOrderSearch] = useState('');

  const columns = [
    {
      dataField: 'code',
      text: 'MÃ',
    },
    {
      dataField: 'name',
      text: 'TÊN ĐƠN HÀNG',
    },
    {
      dataField: 'organization.name',
      text: 'PHÒNG KHÁM/BỆNH VIỆN',
    },
    // {
    //   dataField: 'specifyVote.nameTest',
    //   text: 'DỊCH VỤ',
    // },
    {
      dataField: 'bill',
      text: 'TỔNG TIỀN(VNĐ)',
      formatter: (cell) => {
        return currencyFormat(
          Number(cell?.totalMoney ?? 0) - Number(cell?.reduce ?? 0)
        );
      },
      headerStyle: {
        textAlign: 'right',
      },
      style: {
        textAlign: 'right',
      },
    },

    {
      dataField: 'codeStaff2',
      text: 'THU MẪU',
      formatter: (cell) => {
        return `${cell?.fullname ?? ''} `;
      },
    },
    {
      dataField: 'actions',
      text: 'HÀNH ĐỘNG',
      formatter: boxActions,
      headerStyle: {
        textAlign: 'center',
      },
      headerClasses: 'fixed-column',
      classes: 'fixed-column',
    },
  ];

  function boxActions(__, row) {
    return (
      <div className="d-flex justify-content-center">
        <button
          id={'view' + row.id}
          onClick={() => {
            history.push(`/admin/order-detail/${row.id}?tab=order-init`);
          }}
          className="btn p-0 btn-no-shadow mr-0"
        >
          <img src={IconEye} className="mr-1" />
        </button>
        <UncontrolledTooltip delay={1} placement="top" target={'view' + row.id}>
          Chi tiết đơn hàng
        </UncontrolledTooltip>
        {checkRole(currentAccountExtend, {
          roles: ['update-order-all'],
        }) && (
          <>
            <button
              id={'edit' + row.id}
              onClick={() => {
                history.push(`/admin/order/${row.id}?tab=order-init`);
              }}
              className="btn p-0 btn-no-shadow mr-0"
            >
              <img src={IconEdit} className="mr-1" />
            </button>
            <UncontrolledTooltip
              delay={1}
              placement="top"
              target={'edit' + row.id}
            >
              Cập nhật đơn hàng
            </UncontrolledTooltip>
          </>
        )}
      </div>
    );
  }

  const onSizePerPageChange = (e) => {
    dispatch(orderActions.setValue({ name: 'page', value: 1 }));
    dispatch(orderActions.setValue({ name: 'limit', value: e.target.value }));
  };

  const onPageChange = (value) => {
    dispatch(orderActions.setValue({ name: 'page', value: value }));
  };

  const pagination = paginationFactory({
    page: page,
    sizePerPage: limit,
    totalSize: total,
    showTotal: false,
    withFirstAndLast: false,
    alwaysShowAllBtns: true,
    onPageChange: onPageChange,
    sizePerPageRenderer: () => {
      return (
        <div className="dataTables_length" id="datatable-basic_length">
          <label>
            Hiển thị{' '}
            {
              <select
                name="datatable-basic_length"
                aria-controls="datatable-basic"
                className="form-control form-control-sm"
                onChange={onSizePerPageChange}
              >
                <option value="10">10</option>
                <option value="25">25</option>
                <option value="50">50</option>
                <option value="100">100</option>
              </select>
            }{' '}
            dòng.
          </label>
        </div>
      );
    },
  });

  const headerLeftComponent = () => {
    return (
      <FormGroup
        onChange={(e) => {
          dispatch(
            orderActions.setValue({
              name: 'status',
              value:
                e.target.value === 'all'
                  ? null
                  : e.target.value === 'received'
                  ? 'received'
                  : Number(e.target.value),
            })
          );
          dispatch(orderActions.setValue({ name: 'page', value: 1 }));
          dispatch(orderActions.setValue({ name: 'limit', value: 10 }));
        }}
        className="d-flex align-items-center radio-button-custom"
      >
        {[CONSTANT.ORDER_STATUS[1], CONSTANT.ORDER_STATUS[2]].map(
          (item, index, self) => {
            return (
              <FormGroup
                check
                className={classNames({ 'mr-3': index !== self.length - 1 })}
              >
                <Label check style={{ fontSize: 13 }}>
                  <Input
                    checked={
                      (status ?? CONSTANT.ORDER_STATUS[1].value) === item.value
                    }
                    value={item.value}
                    type="radio"
                    name="status"
                  />{' '}
                  {item.label}
                </Label>
              </FormGroup>
            );
          }
        )}
      </FormGroup>
    );
  };

  useDidUpdateEffect(() => {
    handleGetOrders();
  }, [page, limit, status]);

  useDidUpdateEffect(() => {
    // Sau 500ms thì bắn api
    const delayDebounceFn = setTimeout(() => {
      handleGetOrders();
    }, 500);
    return () => clearTimeout(delayDebounceFn);
  }, [orderSearch]);

  // useEffect(() => {
  //   // if (!_.isEmpty(orders)) return;
  //   handleGetOrders();
  // }, []);
  useEffect(() => {
    // if (!_.isEmpty(orders)) return;
    handleGetOrders();
  }, [childrentByCurrentAccountExtend, areasByAccountExtend]);

  const handleGetOrders = () => {
    const query = {
      pageSize: limit,
      page,
      // 'filters[$and][1][status][$eq]': CONSTANT.ORDER_STATUS[1].value,
      // 'filters[$or][2][status][$eq]': CONSTANT.ORDER_STATUS[2].value,
    };

    if (isAdmin) {
      query['filters[$and][1][$or][1][status][$eq]'] =
        CONSTANT.ORDER_STATUS[1].value;
      query['filters[$and][1][$or][2][status][$eq]'] =
        CONSTANT.ORDER_STATUS[2].value;
    } else {
      query['filters[$and][1][status][$eq]'] =
        status ?? CONSTANT.ORDER_STATUS[1].value;
    }

    if (orderSearch !== '') {
      query['_q'] = orderSearch;
    }
    if (!isBusinessStaff) {
      areasByAccountExtend.forEach((item, index) => {
        query[`filters[$and][2][$or][${index}][areaName][$eq]`] = item.name;
      });
    }
    query[
      `filters[$and][2][$or][${areasByAccountExtend.length}][createdBy][id][$eq]`
    ] = currentAccountExtend.id;
    if (!isAdmin) {
      if (isSampleCollectorStaff) {
        query[
          `filters[$and][2][$or][${
            areasByAccountExtend.length + 1
          }][codeStaff2][id][$eq]`
        ] = currentAccountExtend?.id;
      } else if (isBusinessStaff) {
        query[
          `filters[$and][2][$or][${
            areasByAccountExtend.length + 1
          }][codeStaff1][id][$eq]`
        ] = currentAccountExtend?.id;
      }
      if (isHasChildrent) {
        if (isSampleCollectorStaff) {
          childrentByCurrentAccountExtend.forEach((item, index) => {
            query[
              `filters[$and][2][$or][${
                areasByAccountExtend.length + 2 + index
              }][codeStaff2][id][$eq]`
            ] = item.id;
          });
        } else if (isBusinessStaff) {
          childrentByCurrentAccountExtend.forEach((item, index) => {
            query[
              `filters[$and][2][$or][${
                areasByAccountExtend.length + 2 + index
              }][codeStaff1][id][$eq]`
            ] = item.id;
          });
        }
      }
    }

    dispatch(
      filterActions.setValueFilter({
        name: 'queryPage',
        value: query,
      })
    );
    dispatch(
      orderActions.getOrders(
        queryString.stringify(query, {
          skipEmptyString: true,
          skipNull: true,
        })
      )
    );
  };

  return (
    <>
      <SimpleHeader />
      <Container className="mt--9" fluid>
        <Row>
          <div className="col">
            <TableCustom
              headerLeftComponent={!isAdmin ? headerLeftComponent : undefined}
              tableName="Danh sách"
              data={results}
              columns={columns}
              page={page}
              limit={limit}
              total={total}
              searchBy={'Tìm kiếm'}
              pagination={pagination}
              search={orderSearch}
              onChange={(e) => {
                setOrderSearch(e.target.value);
              }}
            />
          </div>
        </Row>
      </Container>
    </>
  );
}

export default memo(OrderInit);
