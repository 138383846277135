import React, { memo, useEffect, useState } from 'react';
import paginationFactory from 'react-bootstrap-table2-paginator';
import _ from 'lodash';
import { Badge, Container, Row, UncontrolledTooltip } from 'reactstrap';
import queryString from 'query-string';
import { useHistory } from 'react-router';
import { useDispatch, useSelector } from 'react-redux';

import { filterActions, orderActions } from 'redux/actions';
import SimpleHeader from 'components/Headers/SimpleHeader.js';
import 'assets/css/custom-pages/react-bs-table.css';
import { useDidUpdateEffect } from 'utils/HookUtils';
import CONSTANT from 'constant';
import {
  checkRole,
  currencyFormat,
  notificationAlertRef,
  notify,
} from 'common';

import IconTransfer from 'assets/svgs/transfer.svg';
import IconEdit from 'assets/svgs/edit.svg';
import IconEye from 'assets/svgs/eye.svg';
import IconBin from 'assets/svgs/bin.svg';
import IconBinDisable from 'assets/svgs/bin_disable.svg';
import moment from 'moment';
import TableCustom from '../components/TableCustom';
import CFOrder from './components/CFOrder';
import ModalWarningCustom from '../components/ModalWarningCustom';
import Filter from './components/Filter';
import ExportOrder from '../ExportOrder';

function Order() {
  const dispatch = useDispatch();
  const history = useHistory();
  const { page, limit, orders, isDeletingOrder, querySynthetic } = useSelector(
    (state) => state.orderReducer
  );
  const { areasByAccountExtend } = useSelector((state) => state.areaReducer);
  const {
    isAdmin,
    isHasChildrent,
    isSampleCollectorStaff,
    isBusinessStaff,
    isAccountantStaff,
    currentAccountExtend,
    childrentByCurrentAccountExtend,
  } = useSelector((state) => state.accountReducer);

  const results = _.get(orders, 'results', []);
  const total = _.get(orders, 'pagination.total', 0);

  const [orderSearch, setOrderSearch] = useState('');
  const [order, setOrder] = useState(null);
  const [notificationModal, setNotificationModal] = useState(false);

  const cellStyle = (__, row) => {
    const style = { zIndex: 5 };
    const paymentStatus = _.get(row, 'bill.paymentStatus', false);
    if (!paymentStatus) {
      style.backgroundColor = '#F9E7E7';
    }
    return style;
  };
  const columns = [
    {
      dataField: 'code',
      text: 'MÃ',
    },
    {
      dataField: 'name',
      text: 'TÊN ĐƠN HÀNG',
    },
    {
      dataField: 'organization.name',
      text: 'PHÒNG KHÁM/BỆNH VIỆN',
    },
    {
      dataField: 'specifyVote.nameTest',
      text: 'DỊCH VỤ',
    },
    {
      dataField: 'bill',
      text: 'TỔNG TIỀN(VNĐ)',
      formatter: (cell) => {
        return currencyFormat(
          Number(cell?.totalMoney ?? 0) - Number(cell?.reduce ?? 0)
        );
      },
      headerStyle: {
        textAlign: 'right',
      },
      style: {
        textAlign: 'right',
      },
    },
    {
      dataField: 'areaName',
      text: 'KHU VỰC',
    },
    {
      dataField: 'codeStaff1',
      text: 'SALE',
      formatter: (cell) => {
        return `${cell?.fullname ?? ''} `;
      },
    },
    {
      dataField: 'codeStaff2',
      text: 'THU MẪU',
      formatter: (cell) => {
        return `${cell?.fullname ?? ''} `;
      },
    },

    {
      dataField: 'createdAt',
      text: 'NGÀY TẠO',
      formatter: (cell) => {
        return !!cell ? moment(cell).format('DD/MM/YYYY') : '';
      },
    },
    {
      dataField: 'status',
      text: 'TRẠNG THÁI',
      formatter: (cell) => {
        const statsV = CONSTANT.ORDER_STATUS.find(
          (item) => item.value === cell
        );
        return !!statsV ? (
          <Badge color={statsV.color}>{statsV.label}</Badge>
        ) : (
          ''
        );
      },
      headerStyle: {
        textAlign: 'center',
      },
      style: {
        textAlign: 'center',
      },
    },
    {
      dataField: 'actions',
      text: 'HÀNH ĐỘNG',
      formatter: boxActions,
      headerStyle: {
        textAlign: 'center',
        zIndex: 3,
      },
      style: cellStyle,
      cellStyle: cellStyle,
      headerClasses: 'fixed-column',
      classes: 'fixed-column',
    },
  ];

  function rowStyle(row, __) {
    const style = {};
    const paymentStatus = _.get(row, 'bill.paymentStatus', false);
    if (!paymentStatus) {
      style.backgroundColor = 'rgba(192, 6, 6, 0.1)';
      style.borderTop = '2px solid rgba(192, 6, 6, 0.4)';
      style.borderBottom = '2px solid rgba(192, 6, 6, 0.4)';
    }
    return style;
  }

  function boxActions(__, row) {
    return (
      <div className="d-flex justify-content-center">
        {isAccountantStaff ? (
          <>
            <button
              id={'send' + row.id}
              onClick={() => {
                handleSendZalo(row.id);
              }}
              className="btn p-0 btn-no-shadow mr-0"
            >
              <img src={IconTransfer} className="mr-1" />
            </button>
            <UncontrolledTooltip
              delay={1}
              placement="bottom"
              target={'send' + row.id}
            >
              Gửi Zalo xác nhận xét nghiệm
            </UncontrolledTooltip>
          </>
        ) : (
          <></>
        )}
        <button
          id={'view' + row.id}
          onClick={() => {
            history.push(`/admin/order-detail/${row.id}?tab=order-all`);
          }}
          className="btn p-0 btn-no-shadow mr-0"
        >
          <img src={IconEye} className="mr-1" />
        </button>
        <UncontrolledTooltip delay={1} placement="top" target={'view' + row.id}>
          Chi tiết đơn hàng
        </UncontrolledTooltip>
        {checkRole(currentAccountExtend, {
          roles: ['update-order-all'],
        }) && (
          <>
            <button
              id={'edit' + row.id}
              onClick={() => {
                history.push(`/admin/order/${row.id}?tab=order-all`);
              }}
              className="btn p-0 btn-no-shadow mr-0"
            >
              <img src={IconEdit} className="mr-1" />
            </button>
            <UncontrolledTooltip
              delay={1}
              placement="top"
              target={'edit' + row.id}
            >
              Cập nhật đơn hàng
            </UncontrolledTooltip>
          </>
        )}
        {row.status === CONSTANT.ORDER_STATUS[1].value &&
        checkRole(currentAccountExtend, {
          roles: ['delete-order-all'],
        }) ? (
          <>
            <button
              id={'delete' + row.id}
              onClick={() => {
                setOrder(row);
                setNotificationModal(true);
              }}
              className="btn p-0 btn-no-shadow mr-0"
            >
              <img src={IconBin} className="mr-1" />
            </button>
            <UncontrolledTooltip
              delay={1}
              placement="top"
              target={'delete' + row.id}
            >
              Xóa đơn hàng
            </UncontrolledTooltip>
          </>
        ) : checkRole(currentAccountExtend, {
            roles: ['delete-order-all'],
          }) ? (
          <>
            <button
              id={'delete' + row.id}
              onClick={() => {}}
              className="btn p-0 btn-no-shadow mr-0"
            >
              <img src={IconBinDisable} className="mr-1" />
            </button>
          </>
        ) : null}
      </div>
    );
  }

  const onSizePerPageChange = (e) => {
    dispatch(orderActions.setValue({ name: 'page', value: 1 }));
    dispatch(orderActions.setValue({ name: 'limit', value: e.target.value }));
  };

  const onPageChange = (value) => {
    dispatch(orderActions.setValue({ name: 'page', value: value }));
  };

  const pagination = paginationFactory({
    page: page,
    sizePerPage: limit,
    totalSize: total,
    showTotal: false,
    withFirstAndLast: false,
    alwaysShowAllBtns: true,
    onPageChange: onPageChange,
    sizePerPageRenderer: () => {
      return (
        <div className="dataTables_length" id="datatable-basic_length">
          <label>
            Hiển thị{' '}
            {
              <select
                name="datatable-basic_length"
                aria-controls="datatable-basic"
                className="form-control form-control-sm"
                onChange={onSizePerPageChange}
              >
                <option value="10">10</option>
                <option value="25">25</option>
                <option value="50">50</option>
                <option value="100">100</option>
              </select>
            }{' '}
            dòng.
          </label>
        </div>
      );
    },
  });

  function handleSendZalo(id) {
    dispatch(
      orderActions.sendToZalo(
        id,
        {},
        {
          success: () => {
            notify(
              notificationAlertRef,
              'success',
              'Thông báo',
              `Gửi thông báo Zalo thành công!`
            );
          },
          failed: (mess) => {
            notify(
              notificationAlertRef,
              'danger',
              'Thông báo',
              `Gửi thông báo Zalo thất bại!`
            );
          },
        }
      )
    );
  }

  useDidUpdateEffect(() => {
    handleGetOrders();
  }, [page, limit, querySynthetic]);

  useDidUpdateEffect(() => {
    // Sau 500ms thì bắn api
    const delayDebounceFn = setTimeout(() => {
      handleGetOrders();
    }, 500);
    return () => clearTimeout(delayDebounceFn);
  }, [orderSearch]);

  // useEffect(() => {
  //   // if (!_.isEmpty(orders)) return;
  //   handleGetOrders();
  // }, []);
  useEffect(() => {
    handleGetOrders();
  }, [childrentByCurrentAccountExtend, areasByAccountExtend]);

  const handleGetOrders = () => {
    const query = {
      pageSize: limit,
      page,
      ...querySynthetic,
      _q: orderSearch,
    };
    if (!isBusinessStaff) {
      areasByAccountExtend.forEach((item, index) => {
        query[`filters[$and][2][$or][${index}][areaName][$eq]`] = item.name;
      });
    }
    query[
      `filters[$and][2][$or][${areasByAccountExtend.length}][createdBy][id][$eq]`
    ] = currentAccountExtend.id;
    if (!isAdmin) {
      if (isSampleCollectorStaff) {
        query[
          `filters[$and][2][$or][${
            areasByAccountExtend.length + 1
          }][codeStaff2][id][$eq]`
        ] = currentAccountExtend?.id;
      } else if (isBusinessStaff) {
        query[
          `filters[$and][2][$or][${
            areasByAccountExtend.length + 1
          }][codeStaff1][id][$eq]`
        ] = currentAccountExtend?.id;
      }
      if (isHasChildrent) {
        if (isSampleCollectorStaff) {
          childrentByCurrentAccountExtend.forEach((item, index) => {
            query[
              `filters[$and][2][$or][${
                areasByAccountExtend.length + 2 + index
              }][codeStaff2][id][$eq]`
            ] = item.id;
          });
        } else if (isBusinessStaff) {
          childrentByCurrentAccountExtend.forEach((item, index) => {
            query[
              `filters[$and][2][$or][${
                areasByAccountExtend.length + 2 + index
              }][codeStaff1][id][$eq]`
            ] = item.id;
          });
        }
      }
    }

    dispatch(
      filterActions.setValueFilter({
        name: 'queryPage',
        value: query,
      })
    );
    dispatch(
      orderActions.getOrders(
        queryString.stringify(query, {
          skipEmptyString: true,
          skipNull: true,
        })
      )
    );
  };

  const handleDelete = () => {
    if (order === null) return;
    // if (!_.isEmpty(area?.children)) {
    //   return notify(
    //     notificationAlertRef,
    //     'warning',
    //     'Thông báo',
    //     `Nhóm dịch vụ còn các phần tử con. Không được xóa!`
    //   );
    // }
    dispatch(
      orderActions.deleteOrder(order.id, {
        success: () => {
          setNotificationModal(false);
          notify(
            notificationAlertRef,
            'success',
            'Thông báo',
            `Xóa đơn hàng ${order?.name} thành công!`
          );
          handleGetOrders();
        },
        failed: (mess) => {
          notify(
            notificationAlertRef,
            'danger',
            'Thông báo',
            `Xóa đơn hàng ${order?.name} thất bại!. Lỗi: ${mess}`
          );
        },
      })
    );
  };

  return (
    <>
      <SimpleHeader />
      <CFOrder handleGetOrders={handleGetOrders} />
      <Filter />
      <ExportOrder
        q={() => {
          const q = {
            _q: orderSearch,
          };
          if (!isBusinessStaff) {
            areasByAccountExtend.forEach((item, index) => {
              q[`filters[$and][2][$or][${index}][areaName][$eq]`] = item.name;
            });
          }
          q[
            `filters[$and][2][$or][${areasByAccountExtend.length}][createdBy][id][$eq]`
          ] = currentAccountExtend.id;
          if (!isAdmin) {
            if (isSampleCollectorStaff) {
              q[
                `filters[$and][2][$or][${
                  areasByAccountExtend.length + 1
                }][codeStaff2][id][$eq]`
              ] = currentAccountExtend?.id;
            } else if (isBusinessStaff) {
              q[
                `filters[$and][2][$or][${
                  areasByAccountExtend.length + 1
                }][codeStaff1][id][$eq]`
              ] = currentAccountExtend?.id;
            }
            if (isHasChildrent) {
              if (isSampleCollectorStaff) {
                childrentByCurrentAccountExtend.forEach((item, index) => {
                  q[
                    `filters[$and][2][$or][${
                      areasByAccountExtend.length + 2 + index
                    }][codeStaff2][id][$eq]`
                  ] = item.id;
                });
              } else if (isBusinessStaff) {
                childrentByCurrentAccountExtend.forEach((item, index) => {
                  q[
                    `filters[$and][2][$or][${
                      areasByAccountExtend.length + 2 + index
                    }][codeStaff1][id][$eq]`
                  ] = item.id;
                });
              }
            }
          }
          return q;
        }}
      />
      <ModalWarningCustom
        notificationModal={notificationModal}
        setNotificationModal={setNotificationModal}
        name={`đơn hàng ${order?.name ?? ''}`}
        func={handleDelete}
        isDelete={isDeletingOrder}
      />
      <Container className="mt--9" fluid>
        <Row>
          <div className="col">
            <TableCustom
              tableName="Danh sách"
              data={results}
              columns={columns}
              page={page}
              limit={limit}
              total={total}
              searchBy={'Tìm kiếm'}
              pagination={pagination}
              search={orderSearch}
              onChange={(e) => {
                setOrderSearch(e.target.value);
              }}
              rowStyle={rowStyle}
            />
          </div>
        </Row>
      </Container>
    </>
  );
}

export default memo(Order);
