import React, { memo, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Button, Col, Form, FormGroup, Input, Modal, Row } from 'reactstrap';
import * as yup from 'yup';
import { Formik } from 'formik';
import _ from 'lodash';
import Select from 'react-select';
import queryString from 'query-string';

import { notificationAlertRef, notify } from 'common';
import LabelCustom from 'views/pages/components/LabelCustom';
import { barcodeActions, formActions } from 'redux/actions';
import HelperText from 'views/pages/components/HelperText';
import LoadingButtonCustom from 'views/pages/components/LoadingButtonCustom';
import InputCustom from 'views/pages/components/InputCustom';
import CONSTANT from 'constant';
import SelectCustom from 'views/pages/components/SelectCustom';
import moment from 'moment';

const CBarcode = ({ handleGetBarcodes }) => {
  const dispatch = useDispatch();
  const { isModalVisible } = useSelector((state) => state.formReducer);
  const { isCreateBarcode } = useSelector((state) => state.barcodeReducer);

  const now = moment();

  const initInfo = {
    numOfGenerated: 0,
    originalCharacter: now.year().toString().slice(-2),
    status: false,
  };

  const infoSchema = yup.object().shape({
    originalCharacter: yup
      .string()
      .required('Chuỗi ký tự gốc không được để trống!'),
    numOfGenerated: yup
      .number('Số lượng sinh mã phải là số!')
      .min(1, 'Số lượng sinh mã phải lớn hơn 0!')
      .required('Số lượng sinh mã không được để trống!'),
  });

  function pad(d) {
    return d < 10 ? '000' + d.toString() : d.toString();
  }

  const onSubmit = (values) => {
    const r = _.random(0, 100000000);
    dispatch(
      barcodeActions.createBarcode(
        {
          originalCharacter: values.originalCharacter,
          barcode: `${values.originalCharacter}${String(r).padStart(8, '0')}`,
          status: values.status,
          numOfGenerated: values.numOfGenerated - 1,
        },
        {
          success: () => {
            handleGetBarcodes();
            notify(
              notificationAlertRef,
              'success',
              'Thông báo',
              `Thêm mới barcode thành công.`
            );
            handleClose();
          },
          failed: (mess) => {
            notify(
              notificationAlertRef,
              'danger',
              'Thông báo',
              `Thêm mới barcode thất bại!. Lỗi: ${mess}!`
            );
          },
        }
      )
    );
  };

  const handleClose = () => {
    dispatch(
      formActions.setValue({
        name: 'isModalVisible',
        value: false,
      })
    );
    dispatch(
      formActions.setValue({
        name: 'modalInfo',
        value: {},
      })
    );
  };

  useEffect(() => {}, [isModalVisible]);

  return (
    <Modal
      // size={'md'}
      isOpen={isModalVisible}
      className="modal-dialog-centered modal-secondary"
      style={{ maxWidth: 330 }}
      toggle={() => {
        handleClose();
      }}
    >
      <div className="modal-header border-bottom bg-white pb-2">
        <h2>Thêm mới Barcode</h2>
        <button
          aria-label="Close"
          className="close"
          data-dismiss="modal"
          type="button"
          onClick={() => {
            handleClose();
          }}
        >
          <span style={{ color: '#262626' }} aria-hidden={true}>
            ×
          </span>
        </button>
      </div>

      <div className="modal-body bg-white rounded">
        <Formik
          initialValues={initInfo}
          enableReinitialize
          onSubmit={onSubmit}
          validationSchema={infoSchema}
        >
          {({
            values,
            handleSubmit,
            errors,
            touched,
            handleBlur,
            handleChange,
            setFieldValue,
          }) => {
            return (
              <Form onSubmit={handleSubmit} className="">
                <Row>
                  <Col xs={12}>
                    <InputCustom
                      disabled={true}
                      name="originalCharacter"
                      label="Mã barcode gốc"
                      type="text"
                      placeholder="Nhập mã barcode gốc"
                      value={values.originalCharacter}
                      isRequired={true}
                      handleChange={handleChange}
                      handleBlur={handleBlur}
                      error={errors?.originalCharacter}
                      touched={touched?.originalCharacter}
                    />
                  </Col>
                </Row>

                <Row>
                  <Col xs={12}>
                    <InputCustom
                      name="numOfGenerated"
                      label="Số lượng mã sinh ra"
                      type="number"
                      placeholder="Nhập vào số lượng in"
                      value={values.numOfGenerated}
                      isRequired={true}
                      handleChange={handleChange}
                      handleBlur={handleBlur}
                      error={errors?.numOfGenerated}
                      touched={touched?.numOfGenerated}
                    />
                  </Col>
                  {/* <Col xs={6}>
                    <SelectCustom
                      name="status"
                      label="Trạng thái"
                      placeholder="Lựa chọn"
                      value={barcodeValue}
                      isRequired={true}
                      isClearable={false}
                      handleChange={(e) => {
                        setBarcodeValue(e);
                        setFieldValue('status', e?.value ?? false);
                      }}
                      handleBlur={handleBlur}
                      onInputChange={() => {}}
                      error={errors.status}
                      touched={touched.status}
                      options={CONSTANT.BARCODE_STATUS}
                    />
                  </Col> */}
                </Row>

                <div className="mb-0 pb-0 mt-4 d-flex justify-content-end">
                  <Button
                    className="text-nowrap mr-1"
                    onClick={() => {
                      handleClose();
                    }}
                  >
                    Hủy
                  </Button>
                  <LoadingButtonCustom
                    onClick={handleSubmit}
                    color="info"
                    className="text-nowrap "
                    type="submit"
                    loading={isCreateBarcode}
                  >
                    Thêm mới
                  </LoadingButtonCustom>
                </div>
              </Form>
            );
          }}
        </Formik>
      </div>
    </Modal>
  );
};

export default memo(CBarcode);
