import typeActions from 'redux/typeActions';

const convertFilePdf = (files, callback) => {
  return {
    type: typeActions.CONVERT_FILE_PDF_REQUEST,
    files,
    callback,
  };
};

export default {
  convertFilePdf,
};
