import React, { useRef } from 'react';
import { useLocation, Route, Switch, Redirect } from 'react-router-dom';

import 'assets/css/pages/auth.css';
import routes from 'routes.js';
import { setNotificationAlertRef } from 'common';
import ReactNotificationAlert from 'react-notification-alert';

function Auth() {
  const location = useLocation();
  const mainContentRef = React.useRef(null);
  const notificationAlertRef = useRef(null);
  setNotificationAlertRef(notificationAlertRef);

  React.useEffect(() => {
    document.documentElement.scrollTop = 0;
    document.scrollingElement.scrollTop = 0;
    mainContentRef.current.scrollTop = 0;
    document.body.classList.add('bg-color');
    // Specify how to clean up after this effect:
    return function cleanup() {
      document.body.classList.remove('bg-color');
    };
  });
  React.useEffect(() => {
    document.documentElement.scrollTop = 0;
    document.scrollingElement.scrollTop = 0;
    mainContentRef.current.scrollTop = 0;
  }, [location]);
  const getRoutes = (routes) => {
    return routes.map((prop, key) => {
      if (prop.collapse) {
        return getRoutes(prop.views);
      }
      if (prop.layout === '/auth') {
        return (
          <Route
            path={prop.layout + prop.path}
            component={prop.component}
            key={key}
          />
        );
      } else {
        return null;
      }
    });
  };

  return (
    <>
      <div className="main-content" ref={mainContentRef}>
        <div className="rna-wrapper">
          <ReactNotificationAlert ref={notificationAlertRef} />
        </div>
        <Switch>
          {getRoutes(routes.routesA)}
          <Redirect from="*" to="/auth/login" />
        </Switch>
      </div>
    </>
  );
}

export default Auth;
