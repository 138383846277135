import React, { useEffect, useState } from 'react';
import paginationFactory from 'react-bootstrap-table2-paginator';
import _ from 'lodash';
import { Badge, Container, FormGroup, Row } from 'reactstrap';
import moment from 'moment';

import { barcodeActions, filterActions } from 'redux/actions';
import SimpleHeader from 'components/Headers/SimpleHeader.js';
import 'assets/css/custom-pages/react-bs-table.css';
import { useDispatch, useSelector } from 'react-redux';
import CBarcode from './components/CBarcode';
import { useDidUpdateEffect } from 'utils/HookUtils';
import queryString from 'query-string';
import TableCustom from '../components/TableCustom';
import ModalPrintBarCode from './components/ModalPrintBarCode';
import SelectCustom from '../components/SelectCustom';

function Barcode() {
  const [barcodeSearch, setBarcodeSearch] = useState('');
  const { page, limit, barcodes } = useSelector(
    (state) => state.barcodeReducer
  );
  const [statusValue, setStatusValue] = useState({
    label: 'Tất cả',
    value: 0,
  });
  const results = _.get(barcodes, 'results', []);
  const total = _.get(barcodes, 'pagination.total', 0);
  const [rowsSelected, setRowSelected] = useState([]);

  const dispatch = useDispatch();

  const columns = [
    {
      dataField: 'stt',
      text: 'STT',
      formatter: (__, ___, index) => {
        return index + 1 + (page - 1) * limit;
      },
    },
    {
      dataField: 'originalCharacter',
      text: 'CHUỖI KÝ TỰ GỐC',
    },
    {
      dataField: 'barcode',
      text: 'BARCODE',
    },
    {
      dataField: 'createdAt',
      text: 'NGÀY TẠO',
      formatter: (cell) => {
        return !!cell ? moment(cell).format('DD/MM/YYYY') : '';
      },
    },
    {
      dataField: 'usedDate',
      text: 'NGÀY SỬ DỤNG',
      formatter: (cell) => {
        return !!cell ? moment(cell).format('DD/MM/YYYY') : '';
      },
    },
    {
      dataField: 'status',
      text: 'TRẠNG THÁI',
      formatter: (cell, row) => {
        if (cell) {
          return <Badge color="danger">Đã sử dụng</Badge>;
        } else if (row.isPrinted) {
          return <Badge color="info">Đã in</Badge>;
        }
        return <Badge color="success">Chưa in</Badge>;
      },
      headerStyle: {
        textAlign: 'center',
      },
      style: {
        textAlign: 'center',
      },
    },
  ];

  const onSizePerPageChange = (e) => {
    setRowSelected([]);
    dispatch(barcodeActions.setValue({ name: 'page', value: 1 }));
    dispatch(barcodeActions.setValue({ name: 'limit', value: e.target.value }));
  };

  const onPageChange = (value) => {
    setRowSelected([]);
    dispatch(barcodeActions.setValue({ name: 'page', value: value }));
  };

  const pagination = paginationFactory({
    page: page,
    sizePerPage: limit,
    totalSize: total,
    showTotal: false,
    withFirstAndLast: false,
    alwaysShowAllBtns: true,
    onPageChange: onPageChange,
    sizePerPageRenderer: () => {
      return (
        <div className="dataTables_length" id="datatable-basic_length">
          <label>
            Hiển thị{' '}
            {
              <select
                name="datatable-basic_length"
                aria-controls="datatable-basic"
                className="form-control form-control-sm"
                onChange={onSizePerPageChange}
              >
                <option value="10">10</option>
                <option value="25">25</option>
                <option value="50">50</option>
                <option value="100">100</option>
              </select>
            }{' '}
            dòng.
          </label>
        </div>
      );
    },
  });

  const selectRow = {
    mode: 'checkbox',
    selectColumnPosition: 'left',
    onSelect: (row, isSelect) => {
      try {
        if (row.status) {
          setRowSelected((prev) => _.uniqBy(prev, 'id'));
          return;
        }
        if (isSelect) {
          setRowSelected((prev) => _.uniqBy([...prev, row], 'id'));
        } else {
          const tmp = rowsSelected.filter((item) => item.id !== row.id);
          setRowSelected(_.uniqBy(tmp));
        }
      } catch (error) {}
    },
    onSelectAll: (isSelect, rows) => {
      if (isSelect) {
        setRowSelected(rows.filter((row) => !row.status));
      } else {
        setRowSelected([]);
      }
    },
    selectionHeaderRenderer: ({ indeterminate, ...rest }) => {
      return (
        <div className="" style={{ verticalAlign: 'inherit' }}>
          <input
            type="checkbox"
            style={{ height: 'unset', verticalAlign: 'inherit' }}
            ref={(input) => {
              if (input) input.indeterminate = indeterminate;
            }}
            {...rest}
          />
        </div>
      );
    },
    selectionRenderer: ({ mode, ...rest }) => {
      return (
        <div>
          <input type={mode} {...rest} />
        </div>
      );
    },
    selected: rowsSelected.map((item) => item.id),
  };

  useEffect(() => {
    if (_.isEmpty(barcodes?.results)) {
      handleGetBarcodes();
    }
  }, []);

  useDidUpdateEffect(() => {
    const delayDebounceFn = setTimeout(() => {
      handleGetBarcodes();
    }, 500);

    return () => clearTimeout(delayDebounceFn);
  }, [barcodeSearch]);

  useDidUpdateEffect(() => {
    handleGetBarcodes();
  }, [page, limit, statusValue]);

  const handleGetBarcodes = () => {
    setRowSelected([]);
    const q = { page, pageSize: limit };
    switch (statusValue.value) {
      case 1:
        q['filters[$and][0][isPrinted][$eq]'] = true;
        q['filters[$and][1][status][$eq]'] = false;
        break;
      case 2:
        q['filters[$and][0][$or][0][isPrinted][$eq]'] = false;
        q['filters[$and][0][$or][1][isPrinted][$null]'] = true;
        q['filters[$and][1][status][$eq]'] = false;
        break;
      case 3:
        q['filters[$and][0][status][$eq]'] = true;
        break;
      default:
        break;
    }
    console.log('q: ', q);
    if (barcodeSearch !== '') {
      q._q = barcodeSearch;
    }
    dispatch(
      filterActions.setValueFilter({
        name: 'queryPage',
        value: q,
      })
    );
    dispatch(barcodeActions.getBarcodes(queryString.stringify(q)));
  };

  const headerLeftComponent = () => {
    return (
      <div style={{ zIndex: 1000 }}>
        <FormGroup
          className="mb-1 flex"
          style={{
            display: 'flex',
          }}
        >
          <SelectCustom
            name="status"
            label="Chọn trạng thái"
            placeholder="Nhập để tìm kiếm"
            value={statusValue}
            isRequired={false}
            isClearable={false}
            stylesWrapper={{
              minWidth: 200,
            }}
            handleChange={(e) => {
              setStatusValue(e);
            }}
            options={[
              {
                label: 'Tất cả',
                value: 0,
              },
              {
                label: 'Đã in',
                value: 1,
              },
              {
                label: 'Chưa in',
                value: 2,
              },
              {
                label: 'Đã sử dụng',
                value: 3,
              },
            ]}
          />
        </FormGroup>
      </div>
    );
  };

  return (
    <>
      <SimpleHeader />
      <CBarcode handleGetBarcodes={handleGetBarcodes} />
      <ModalPrintBarCode
        data={rowsSelected}
        handleGetBarcodes={handleGetBarcodes}
      />
      <Container className="mt--9" fluid>
        <Row>
          <div className="col">
            <TableCustom
              tableName="Danh sách mã Barcode"
              data={results}
              columns={columns}
              page={page}
              limit={limit}
              total={total}
              searchBy={'Tìm kiếm'}
              pagination={pagination}
              search={barcodeSearch}
              headerLeftComponent={headerLeftComponent}
              onChange={(e) => {
                setBarcodeSearch(e.target.value);
              }}
              selectRow={selectRow}
            />
          </div>
        </Row>
      </Container>
    </>
  );
}

export default Barcode;
