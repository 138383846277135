import { returnValue } from 'common';
import typeActions from 'redux/typeActions';
const initialState = {
  templatePcds: {},
  isUpdatingTemplatePCD: false,
  // form show/delete
  isModalVisible: false,
  modalInfo: {},
  page: 1,
  limit: 10,
  query: {
    populate: '*',
  },
};
const templatePCDReducer = (state = initialState, actions) => {
  switch (actions.type) {
    case typeActions.SET_TEMPLATE_PCD_VALUE:
      const nameTemplatePCD = actions.name;
      const valueTemplatePCD = actions.value;
      if (typeof nameTemplatePCD !== 'string') return { ...state };
      const objTemplatePCD = returnValue(
        state,
        nameTemplatePCD,
        valueTemplatePCD
      );
      return {
        ...state,
        ...objTemplatePCD,
      };
    case typeActions.GET_TEMPLATE_PCDS_REQUEST:
      return {
        ...state,
        ...actions?.data,
      };
    case typeActions.GET_TEMPLATE_PCDS_SUCCESS:
      return {
        ...state,
        templatePcds: actions.data,
      };
    case typeActions.GET_TEMPLATE_PCDS_FAILED:
      return {
        ...state,
      };

    case typeActions.UPDATE_TEMPLATE_PCD_REQUEST:
      return {
        ...state,
        isUpdatingTemplatePCD: true,
      };
    case typeActions.UPDATE_TEMPLATE_PCD_SUCCESS:
      return {
        ...state,
        templatePcds: actions.data,
        isUpdatingTemplatePCD: false,
      };
    case typeActions.UPDATE_TEMPLATE_PCD_FAILED:
      return {
        ...state,
        isUpdatingTemplatePCD: false,
      };

    default:
      return {
        ...state,
      };
  }
};

export default templatePCDReducer;
