import typeActions from 'redux/typeActions';
import CONSTANT from 'constant';
import { returnValue } from 'common';

const initialState = {
  // loading
  isGettingRoleUI: false,

  // data
  roleUI: {},
  // error
  errors: {
    getRoleUI: '',
  },
};

const roleUIReducer = (state = initialState, actions) => {
  switch (actions.type) {
    case typeActions.GET_ROLE_UI_REQUEST:
      return {
        ...state,
        isGettingRoleUI: true,
        errors: {
          ...state.errors,
          getRoleUI: '',
        },
      };
    case typeActions.GET_ROLE_UI_SUCCESS:
      return {
        ...state,
        roleUI: actions.data || {},
        isGettingRoleUI: false,
        errors: {
          ...state.errors,
          getRoleUI: '',
        },
      };
    case typeActions.GET_ROLE_UI_FAILED:
      return {
        ...state,
        roleUI: {},
        isGettingRoleUI: false,
        errors: {
          ...state.errors,
          getRoleUI: actions.error,
        },
      };

    default:
      return {
        ...state,
      };
  }
};
export default roleUIReducer;
