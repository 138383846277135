import { returnValue } from 'common';
import typeActions from 'redux/typeActions';
const initialState = {
  notification: {},
  pagination: {},
  // form show/delete
  isModalVisible: false,
  modalInfo: {},
  page: 1,
  limit: 10,
  query: {
    populate: '*',
  },
};
const notificationReducer = (state = initialState, actions) => {
  switch (actions.type) {
    case typeActions.SET_NOTIFICATION_VALUE:
      const nameNotification = actions.name;
      const valueNotification = actions.value;
      if (typeof nameNotification !== 'string') return { ...state };
      const objNotification = returnValue(
        state,
        nameNotification,
        valueNotification
      );
      return {
        ...state,
        ...objNotification,
      };
    case typeActions.GET_NOTIFICATION_REQUEST:
      return {
        ...state,
        ...actions?.data,
      };
    case typeActions.GET_NOTIFICATION_SUCCESS:
      return {
        ...state,
        notification: actions.data.results,
        pagination: actions.data.pagination,
        page: actions.data.pagination.page,
        limit: actions.data.pagination.pageSize,
      };
    case typeActions.GET_NOTIFICATION_FAILED:
      return {
        ...state,
      };
    //delete
    case typeActions.DELETE_NOTIFICATION_REQUEST:
      return {
        ...state,
      };
    case typeActions.DELETE_NOTIFICATION_SUCCESS:
      return {
        ...state,
      };
    case typeActions.DELETE_NOTIFICATION_FAILED:
      return {
        ...state,
      };
    //set value
    case typeActions.OPEN_FORM_NOTIFICATION:
      const { name, value } = actions;
      if (typeof name !== 'string') return { ...state };
      const obj = returnValue(state, name, value);
      return {
        ...state,
        ...obj,
      };
    default:
      return {
        ...state,
      };
  }
};

export default notificationReducer;
