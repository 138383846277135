//core-componens
import React, { useEffect, useState } from 'react';
import { Col, Container, Row } from 'reactstrap';
//custom components
import LabelCustom from 'views/pages/components/LabelCustom';
import InputCustom from 'views/pages/components/InputCustom';
import SelectCustom from 'views/pages/components/SelectCustom';
//module
import CONSTANT from 'constant';
import _, { values } from 'lodash';
import queryString from 'query-string';
import { getListSelectOption } from 'common';
import { notificationAlertRef, notify } from 'common';
//styles
import 'assets/css/custom-pages/specify-votes.css';
import 'assets/css/custom-pages/create-specify-votes.css';
import { useDispatch, useSelector } from 'react-redux';
import { useDelayInputEffect } from 'utils/HookUtils';
import { patientActions } from 'redux/actions';
function GeneralInfomationLeft({
  setIsCreatePatient,
  formik,
  isUpdate,
  currentSP,
  isDisabled,
}) {
  const dispatch = useDispatch();
  const { isCreatingSpecifyvote } = useSelector(
    (state) => state.specifyvoteReducer
  );
  const [codePatientOptions, setCodeValueOptions] = useState([]);
  //state input values select box, input, v.v (IV: input value)
  // const [dateOfBirth, setDateOfBirth] = useState(new Date());
  // const [phoneContact, setPhoneContact] = useState("");
  // const [nameContact, setNameContact] = useState("");
  // const [gender, setGender] = useState(null);
  // const [email, setEmail] = useState("");
  // const [phone, setPhone] = useState("");
  // const [name, setName] = useState("");
  // const [job, setJob] = useState("");

  // values options {label: "",values: ""}
  const [codePatientValue, setCodeValue] = useState(null);
  const [patientValue, setPatientValue] = useState(null);
  //list options
  const [patientArr, setPatientArr] = useState([]);
  //redux
  const { query } = useSelector((state) => state.patientReducer);
  //get options code patient
  const patientAC = patientActions.getPatients(
    queryString.stringify({
      'filters[$or][0][code][$containsi]': codePatientValue?.label ?? '',
      'filters[$or][1][name][$containsi]': codePatientValue?.label ?? '',
      ...query,
      page: 1,
      pageSize: 10,
    }),
    {
      success: (data) => {
        setPatientArr(data?.results);
        setCodeValueOptions(
          getListSelectOption(data?.results, ['code+name'], ['code'])
        );
        // setAreaOptions(getListSelectOption(data?.results, ['name'], ['id']));
      },
      failed: (error) => {
        notify(
          notificationAlertRef,
          'failed',
          'Lỗi tìm dữ liệu bệnh nhân',
          `Lỗi: ${error}`
        );
      },
    },
    false
  );
  useEffect(() => {
    dispatch(patientAC);
  }, []);
  //search value after 700ms
  useDelayInputEffect(patientAC, codePatientValue, 700);
  //set field values when code BN change
  function setFieldValues(patientValue) {
    if (!patientValue) {
      formik.setFieldValue('codeBN', '');
      setCodeValue(null);
    }
    formik.setFieldValue('nameBN', patientValue?.name ?? '');
    formik.setFieldValue('dateOfBirthBN', patientValue?.dateOfBirth ?? '');
    formik.setFieldValue('idBN', patientValue?.id ?? '');
    formik.setFieldValue('genderBN', patientValue?.gender ?? '');
    formik.setFieldValue('emailBN', patientValue?.email ?? '');
    formik.setFieldValue('phoneBN', patientValue?.phone ?? '');
    formik.setFieldValue('nameContact', patientValue?.nameContact ?? '');
    formik.setFieldValue('phoneContact', patientValue?.phoneContact ?? '');
    formik.setFieldValue('addressBN', patientValue?.address ?? '');
    formik.setFieldValue('jobBN', patientValue?.job ?? '');
    if (patientValue)
      Object.keys(patientValue).map((key) => {
        delete formik.errors[`${key}`];
      });
  }
  useEffect(() => {
    if (patientValue) setIsCreatePatient(false);
    setFieldValues(patientValue);
  }, [patientValue]);
  useEffect(() => {
    if (!codePatientValue) {
      setPatientValue(null);
      setFieldValues(null);
    }
  }, [codePatientValue]);

  useEffect(() => {
    if (isUpdate && formik.values.codeBN && _.isEmpty(codePatientValue)) {
      setCodeValue({
        label: `${formik.values.codeBN}`,
        value: formik.values.codeBN,
      });
    }
  }, [formik]);
  useEffect(() => {
    if (!isUpdate) {
      setCodeValue(null);
    }
  }, [isUpdate, isCreatingSpecifyvote]);
  return (
    <Col
      className=" pr-0 col-xxl-6 "
      style={{
        borderRight: '1px solid rgba(0, 0, 0, 0.1)',
      }}
    >
      <Container>
        <Row>
          <Col xs={12} style={{ paddingLeft: 0, paddingRight: 24 }}>
            <Row>
              <Col xs={12} className="pr-0">
                <LabelCustom
                  label="Thông tin người làm xét nghiệm"
                  horizontal={true}
                  style={{
                    fontSize: '14px',
                    color: 'rgba(140, 151, 174, 1)',
                    fontWeight: 400,
                    textTransform: 'uppercase',
                  }}
                />
              </Col>
            </Row>
            <Row>
              <Col xs={12} className="disable-react-select__dropdown-indicator">
                <SelectCustom
                  label="Số điện thoại"
                  labelWidth={150}
                  name={'codeBN'}
                  isDisabled={isUpdate || isDisabled}
                  placeholder="Lựa chọn"
                  horizontal={true}
                  row={true}
                  lableTextAlign={'left'}
                  styles={{
                    control: (baseStyles, state) => ({
                      ...baseStyles,
                      fontSize: '0.875rem',
                    }),
                  }}
                  isClearable={true}
                  isRequired={true}
                  className="react-select-container"
                  classNamePrefix="react-select"
                  handleChange={(e) => {
                    if (!!e) {
                      const patient = patientArr.find((patient) => {
                        return patient.code === e.value;
                      });
                      formik.setFieldValue('codeBN', patient?.code ?? null);
                      formik.setFieldValue('phoneBN', patient?.phone ?? null);
                      setPatientValue(patient);
                      setCodeValue(e);
                      if (e.value) setIsCreatePatient(true);
                    }
                    if (!e) setPatientValue(null);
                  }}
                  onBlur={formik.handleBlur}
                  onInputChange={(e, a) => {
                    if (a.action === 'input-change') {
                      formik.setFieldValue('codeBN', e);
                      formik.setFieldValue('phoneBN', e);
                      setCodeValue({
                        label: e,
                        value: null,
                      });
                      setIsCreatePatient(true);
                    }
                    if (a.action === 'input-change' && !patientValue) {
                      formik.setFieldValue('codeBN', e);
                      formik.setFieldValue('phoneBN', e);
                    }
                  }}
                  error={formik.errors.codeBN}
                  touched={formik.touched.codeBN}
                  options={codePatientOptions}
                  value={codePatientValue}
                />
              </Col>
            </Row>
            <Row>
              <Col xs={12}>
                <InputCustom
                  label="Họ tên"
                  row
                  disabled={isDisabled}
                  labelWidth={150}
                  isRequired={true}
                  lableTextAlign={'left'}
                  className={'height-input'}
                  id={'nameBN'}
                  placeholder={'Nhập vào họ và tên'}
                  type={'text'}
                  name={'nameBN'}
                  handleChange={(e) => {
                    formik.setFieldValue('nameBN', e.target.value);
                  }}
                  handleBlur={formik.handleBlur}
                  value={formik.values.nameBN}
                  touched={formik.touched.nameBN}
                  error={formik.errors.nameBN}
                />
              </Col>
            </Row>
            <Row>
              <Col md={12} xl={6} className="custom-col-6-left">
                <InputCustom
                  name="dateOfBirthBN"
                  label="Ngày sinh"
                  type="text"
                  isRequired
                  placeholder="DD/MM/YYYY"
                  value={formik?.values?.dateOfBirthBN ?? ''}
                  handleChange={formik.handleChange}
                  handleBlur={formik.handleBlur}
                  error={formik.errors?.dateOfBirthBN}
                  touched={formik.touched?.dateOfBirthBN}
                  disabled={isDisabled}
                  row
                  labelWidth={150}
                />
              </Col>
              <Col md={12} xl={6} className="custom-col-6-right">
                <SelectCustom
                  name="genderBN"
                  label="Giới tính"
                  isRequired
                  lableTextAlign={'left'}
                  labelWidth={100}
                  row={true}
                  placeholder="Lựa chọn"
                  isMulti={false}
                  isDisabled={isDisabled}
                  options={CONSTANT.ACCOUNT_GENDER}
                  value={_.find(CONSTANT.ACCOUNT_GENDER, {
                    value: formik.values.genderBN,
                  })}
                  handleChange={(e) => {
                    formik.setFieldValue('genderBN', e.value);
                  }}
                  handleBlur={formik.handleBlur}
                  error={formik.errors.genderBN}
                  touched={formik.touched.genderBN}
                />
              </Col>
            </Row>
            <Row>
              <Col md={12} xl={12} className="custom-col-6-left">
                <InputCustom
                  label="Email"
                  row
                  labelWidth={150}
                  // isRequired={true}
                  lableTextAlign={'left'}
                  className={'height-input'}
                  id={'emailBN'}
                  placeholder={'Nhập email'}
                  type={'text'}
                  name={'emailBN'}
                  value={formik.values.emailBN}
                  handleChange={(e) => {
                    formik.setFieldValue('emailBN', e.target.value);
                  }}
                  disabled={isDisabled}
                  handleBlur={formik.handleBlur}
                  touched={formik.touched.emailBN}
                  error={formik.errors.emailBN}
                />
              </Col>
            </Row>
            <Row>
              <Col xs={12}>
                <InputCustom
                  row
                  label="Nghề nghiệp"
                  lableTextAlign={'left'}
                  labelWidth={150}
                  className={'height-input'}
                  id={'jobBN'}
                  placeholder={'Nhập vào nghề nghiệp'}
                  type={'text'}
                  disabled={isDisabled}
                  name={'jobBN'}
                  value={formik.values.jobBN}
                  handleChange={(e) => {
                    formik.setFieldValue('jobBN', e.target.value);
                  }}
                  touched={formik.touched.jobBN}
                  error={formik.errors.jobBN}
                />
              </Col>
            </Row>
            <Row>
              <Col md={12} xl={6} className="custom-col-6-left">
                <InputCustom
                  row
                  isRequired
                  label="Người liên hệ"
                  labelWidth={150}
                  disabled={isDisabled}
                  lableTextAlign={'left'}
                  className={'height-input'}
                  id={'nameContact'}
                  placeholder={'Nhập vào họ và tên '}
                  type={'text'}
                  name={'nameContact'}
                  value={formik.values.nameContact}
                  handleChange={(e) => {
                    formik.setFieldValue('nameContact', e.target.value);
                  }}
                  handleBlur={formik.handleBlur}
                  touched={formik.touched.nameContact}
                  error={formik.errors.nameContact}
                />
              </Col>
              <Col md={12} xl={6} className="custom-col-6-right">
                <InputCustom
                  row
                  disabled={isDisabled}
                  isRequired
                  className={'height-input'}
                  label={'Số điện thoại'}
                  lableTextAlign={'left'}
                  labelWidth={100}
                  id={'phoneContact'}
                  placeholder={`Nhập số điện thoại`}
                  type={'text'}
                  name={'phoneContact'}
                  value={formik.values.phoneContact}
                  handleChange={(e) => {
                    formik.setFieldValue('phoneContact', e.target.value);
                  }}
                  handleBlur={formik.handleBlur}
                  touched={formik.touched.phoneContact}
                  error={formik.errors.phoneContact}
                />
              </Col>
            </Row>
            <Row>
              <Col xs={12}>
                <InputCustom
                  row
                  label="Địa chỉ bệnh nhân"
                  disabled={isDisabled}
                  labelWidth={150}
                  isRequired
                  lableTextAlign={'left'}
                  className={'height-input'}
                  id={'addressBN'}
                  placeholder={'Nhập địa chỉ'}
                  type={'textarea'}
                  name={'addressBN'}
                  styleInput={{
                    minHeight: '90px',
                  }}
                  value={formik.values.addressBN}
                  handleChange={(e) => {
                    formik.setFieldValue('addressBN', e.target.value);
                  }}
                  handleBlur={formik.handleBlur}
                  touched={formik.touched.addressBN}
                  error={formik.errors.addressBN}
                />
              </Col>
            </Row>
            <Row
              style={{ marginTop: 24, display: isUpdate ? undefined : 'none' }}
            >
              <Col xs={12}>
                <SelectCustom
                  name="specifyVoteStatus"
                  label="Trạng thái"
                  isRequired
                  disabled={isDisabled}
                  lableTextAlign="left"
                  labelWidth={150}
                  row={true}
                  placeholder="Lựa chọn"
                  isMulti={false}
                  options={_.filter(CONSTANT.SPECIFY_VOTE_STATUS, (obj) => {
                    return (
                      obj.value === -1 ||
                      obj.value === currentSP?.specifyVoteStatus
                    );
                  })}
                  value={_.find(CONSTANT.SPECIFY_VOTE_STATUS, {
                    value: formik.values.specifyVoteStatus,
                  })}
                  handleChange={(e) => {
                    formik.setFieldValue('specifyVoteStatus', e.value);
                  }}
                  isDisabled={
                    parseInt(currentSP?.specifyVoteStatus ?? '') ===
                    CONSTANT.ORDER_STATUS[0].value
                  }
                  handleBlur={formik.handleBlur}
                  error={formik.errors.specifyVoteStatus}
                  touched={formik.touched.genderBN}
                />
              </Col>
            </Row>
          </Col>
        </Row>
      </Container>
    </Col>
  );
}

export default GeneralInfomationLeft;
