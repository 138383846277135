import _ from 'lodash';
import { takeLatest, call, put } from 'redux-saga/effects';

import typeActions from 'redux/typeActions';
import ServiceURL from 'services/ServiceURL';
import { DELETE, GET, POST, PUT, GET_FILE } from 'services/ServiceBase';
import { convertMessageError } from 'common';

export function* getPromotions(data) {
  const url = `${ServiceURL.promotion}?filters[$and][0][deleted][$eq]=false&sort=createdAt:DESC&${data.query}`;
  const callback = data.callback;
  try {
    const res = yield call(GET, url);
    if (!!res.data?.error?.message) {
      yield put({
        type: typeActions.GET_PROMOTIONS_FAILED,
        error: res.data?.error?.message,
      });
      !!callback?.failed && callback.failed(res.data.error.message);
    } else {
      if (data.isUpdateReducer) {
        yield put({
          type: typeActions.GET_PROMOTIONS_SUCCESS,
          data: res.data,
        });
      }

      !!callback?.success && callback.success(res.data);
    }
  } catch (error) {
    yield put({
      type: typeActions.GET_PROMOTIONS_FAILED,
      error: error?.response?.data?.error?.message ?? '',
    });
    !!callback?.failed &&
      callback.failed(error?.response?.data?.error?.message ?? '');
  }
}

export function* getPromotion(data) {
  const url = `${ServiceURL.promotion}/${data.param}?${data.query}`;
  const callback = data.callback;
  try {
    const res = yield call(GET, url);
    if (!!res.data?.error?.message) {
      yield put({
        type: typeActions.GET_PROMOTION_FAILED,
        error: res.data.error.message,
      });
      !!callback?.failed && callback.failed(res.data.error.message);
    } else {
      yield put({
        type: typeActions.GET_PROMOTION_SUCCESS,
        data: res.data,
      });
      !!callback?.success && callback.success(res.data);
    }
  } catch (error) {
    yield put({
      type: typeActions.GET_PROMOTION_FAILED,
      error: error?.response?.data?.error?.message ?? '',
    });
    !!callback?.failed &&
      callback.failed(error?.response?.data?.error?.message ?? '');
  }
}

export function* createPromotion(data) {
  const url = `${ServiceURL.promotion}`;
  const callback = data.callback;
  try {
    const res = yield call(POST, url, data.body);
    if (!!res.data?.error?.message) {
      yield put({
        type: typeActions.CREATE_PROMOTION_FAILED,
        error: res.data.error.message,
      });
      !!callback?.failed && callback.failed(res.data.error.message);
    } else {
      yield put({
        type: typeActions.CREATE_PROMOTION_SUCCESS,
        data: res.data?.data,
      });
      !!callback?.success && callback.success(res.data?.data);
    }
  } catch (error) {
    const message = convertMessageError(
      'c',
      error,
      ' chương trình khuyến mại '
    );
    yield put({
      type: typeActions.CREATE_PROMOTION_FAILED,
      error: message,
    });
    !!callback?.failed && callback.failed(message);
  }
}

export function* updatePromotion(data) {
  const url = `${ServiceURL.promotion}/${data.param}`;
  const callback = data.callback;
  try {
    const res = yield call(PUT, url, data.body);
    if (!!res.data?.error?.message) {
      yield put({
        type: typeActions.UPDATE_PROMOTION_FAILED,
        error: res.data.error.message,
      });
      !!callback?.failed && callback.failed(res.data.error.message);
    } else {
      yield put({
        type: typeActions.UPDATE_PROMOTION_SUCCESS,
        data: res.data,
      });

      !!callback?.success && callback.success(res.data);
    }
  } catch (error) {
    yield put({
      type: typeActions.UPDATE_PROMOTION_FAILED,
      error: error?.response?.data?.error?.message ?? '',
    });
    !!callback?.failed &&
      callback.failed(error?.response?.data?.error?.message ?? '');
  }
}

export function* deletePromotion(data) {
  const url = `${ServiceURL.promotion}/${data.param}`;
  const callback = data.callback;
  try {
    const res = yield call(DELETE, url);
    if (!!res.data?.error?.message) {
      yield put({
        type: typeActions.DELETE_PROMOTION_FAILED,
        error: res.data.error.message,
      });
      !!callback?.failed && callback.failed(res.data.error.message);
    } else {
      yield put({
        type: typeActions.DELETE_PROMOTION_SUCCESS,
        data: res.data,
      });
      !!callback?.success && callback.success(res.data);
    }
  } catch (error) {
    yield put({
      type: typeActions.DELETE_PROMOTION_FAILED,
      error: error?.response?.data?.error?.message ?? '',
    });
    !!callback?.failed &&
      callback.failed(error?.response?.data?.error?.message ?? '');
  }
}

export function* exportPromotion(data) {
  const url = `${ServiceURL.exportPromotion}?${data.query}`;
  const callback = data.callback;
  try {
    const res = yield call(GET_FILE, url);
    if (!!res.data?.error?.message) {
      yield put({
        type: typeActions.EXPORT_PROMOTION_FAILED,
        error: res.data?.error?.message,
      });
      !!callback?.failed && callback.failed(res.data.error.message);
    } else {
      yield put({
        type: typeActions.EXPORT_PROMOTION_SUCCESS,
        data: res.data,
      });

      !!callback?.success && callback.success(res.data);
    }
  } catch (error) {
    yield put({
      type: typeActions.EXPORT_PROMOTION_FAILED,
      error: error?.response?.data?.error?.message ?? '',
    });
    !!callback?.failed &&
      callback.failed(error?.response?.data?.error?.message ?? '');
  }
}
export function* exportPromotionDetail(data) {
  const url = `${ServiceURL.exportPromotionDetail}?${data.query}`;
  const callback = data.callback;
  try {
    const res = yield call(GET_FILE, url);
    if (!!res.data?.error?.message) {
      yield put({
        type: typeActions.EXPORT_PROMOTION_DETAIL_FAILED,
        error: res.data?.error?.message,
      });
      !!callback?.failed && callback.failed(res.data.error.message);
    } else {
      yield put({
        type: typeActions.EXPORT_PROMOTION_DETAIL_SUCCESS,
        data: res.data,
      });

      !!callback?.success && callback.success(res.data);
    }
  } catch (error) {
    yield put({
      type: typeActions.EXPORT_PROMOTION_DETAIL_FAILED,
      error: error?.response?.data?.error?.message ?? '',
    });
    !!callback?.failed &&
      callback.failed(error?.response?.data?.error?.message ?? '');
  }
}

export default function* promotionSaga() {
  yield takeLatest(typeActions.GET_PROMOTIONS_REQUEST, getPromotions);
  yield takeLatest(typeActions.GET_PROMOTION_REQUEST, getPromotion);
  yield takeLatest(typeActions.CREATE_PROMOTION_REQUEST, createPromotion);
  yield takeLatest(typeActions.UPDATE_PROMOTION_REQUEST, updatePromotion);
  yield takeLatest(typeActions.DELETE_PROMOTION_REQUEST, deletePromotion);
  yield takeLatest(typeActions.EXPORT_PROMOTION_REQUEST, exportPromotion);
  yield takeLatest(typeActions.EXPORT_PROMOTION_DETAIL_REQUEST, exportPromotionDetail);
}
