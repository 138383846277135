import _ from 'lodash';
import { all, call, put, takeLatest } from 'redux-saga/effects';

import typeActions from 'redux/typeActions';
import ServiceURL from 'services/ServiceURL';
import { DELETE, GET, GET_FILE, POST, PUT } from 'services/ServiceBase';


export function* exportSpecifyVote(data) {
  const url = `${ServiceURL.exportSpecifyVote}?${data.query}`;
  const callback = data.callback;
  try {
    const res = yield call(GET_FILE, url);
    if (!!res.data?.error?.message) {
      yield put({
        type: typeActions.EXPORT_SPECIFYVOTE_FAILED,
        error: res.data?.error?.message,
      });
      !!callback?.failed && callback.failed(res.data.error.message);
    } else {
      yield put({
        type: typeActions.EXPORT_SPECIFYVOTE_SUCCESS,
        data: res.data,
      });

      !!callback?.success && callback.success(res.data);
    }
  } catch (error) {
    yield put({
      type: typeActions.EXPORT_SPECIFYVOTE_FAILED,
      error: error?.response?.data?.error?.message ?? '',
    });
    !!callback?.failed &&
      callback.failed(error?.response?.data?.error?.message ?? '');
  }
}
/**
 *
 * @param {*} data
 * get list specifyvotes:
 * - failed -> call actions failed
 * - success ->
 *      + if (search in select-options) => return;
 *      + if (search in list specifyvotes) => call actions sucess
 */
export function* getSpecifyVotes(data) {
  const url = `${ServiceURL.specifyvote}?${data.query}&sort[0]=createdAt:DESC`;
  const callback = data.callback;
  const isUpdateReducer = data.isUpdateReducer;
  try {
    const res = yield call(GET, url);
    if (!!res.data?.error?.message) {
      yield put({
        type: typeActions.GET_SPECIFYVOTE_FAILED,
        error: res.data?.error?.message,
      });
      !!callback?.failed && callback.failed(res.data.error.message);
    } else {
      if (isUpdateReducer) {
        yield put({
          type: typeActions.GET_SPECIFYVOTE_SUCCESS,
          data: res.data,
        });
      }
      !!callback?.success && callback.success(res.data);
    }
  } catch (error) {
    yield put({
      type: typeActions.GET_SPECIFYVOTE_FAILED,
      error: error?.response?.data?.error?.message ?? '',
    });
    !!callback?.failed &&
      callback.failed(error?.response?.data?.error?.message ?? '');
  }
}
export function* getSpecifyVoteById(data) {
  const url = ServiceURL.specifyvote + '/' + data.param + '?' + data.query;
  const callback = data.callback;
  try {
    const res = yield call(GET, url);
    if (!!res.data?.error?.message) {
      yield put({
        type: typeActions.GET_SPECIFYVOTE_BY_ID_FAILED,
        error: res.data.error.message,
      });
      !!callback?.failed && callback.failed(res.data.error.message);
    } else {
      yield put({
        type: typeActions.GET_SPECIFYVOTE_BY_ID_SUCCESS,
        data: res.data,
      });
      !!callback?.success && callback.success(res.data);
      return res.data;
    }
  } catch (error) {
    yield put({
      type: typeActions.GET_SPECIFYVOTE_BY_ID_FAILED,
      error: error?.response?.data?.error?.message ?? '',
    });
    !!callback?.failed &&
      callback.failed(error?.response?.data?.error?.message ?? '');
  }
}

export function* createSpecifyVote(data) {
  const url = ServiceURL.specifyvote;
  const callback = data.callback;
  try {
    const res = yield call(POST, url, data.params);
    if (!!res.data?.error?.message) {
      yield put({
        type: typeActions.CREATE_SPECIFYVOTE_FAILED,
        error: res.data.error.message,
      });

      !!callback?.failed && callback.failed(res.data.error.message);
    } else {
      yield put({
        type: typeActions.CREATE_SPECIFYVOTE_SUCCESS,
        data: res.data,
      });
      !!callback?.success && callback.success(res.data);
    }
  } catch (error) {
    const details = error?.response?.data?.error?.details?.errors ?? '';
    const m = details
      ? details.map((_) => {
        return `Trường ${_.path.join(',')} : ${_.message}`;
      })
      : error?.response?.data?.error?.message ?? '' ?? '';
    yield put({
      type: typeActions.CREATE_SPECIFYVOTE_FAILED,
      error: m,
    });
    !!callback?.failed && callback.failed(m);
  }
}

export function* deleteSpecifyVote(data) {
  const url = ServiceURL.specifyvote + '/' + data.params;
  const callback = data.callback;
  try {
    const res = yield call(DELETE, url);
    if (!!res.data?.error?.message) {
      yield put({
        type: typeActions.DELETE_SPECIFYVOTE_FAILED,
        error: res.data.error.message,
      });
      !!callback?.failed && callback.failed(res.data.error.message);
    } else {
      yield put({
        type: typeActions.DELETE_SPECIFYVOTE_SUCCESS,
      });
      !!callback?.success && callback.success();
    }
  } catch (error) {
    yield put({
      type: typeActions.DELETE_SPECIFYVOTE_FAILED,
      error: error?.response?.data?.error?.message ?? '',
    });
    !!callback?.failed &&
      callback.failed(error?.response?.data?.error?.message ?? '');
  }
}
export function* updateSpecifyVote(data) {
  const url = ServiceURL.specifyvote + '/' + data.params;
  const callback = data.callback;
  try {
    const res = yield call(PUT, url, data.body);
    if (!!res.error?.message) {
      yield put({
        type: typeActions.UPDATE_SPECIFYVOTE_FAILED,
        error: res?.error?.message ?? '',
      });
      !!callback?.failed && callback.failed(res?.error?.message ?? '');
    } else {
      yield put({
        type: typeActions.UPDATE_SPECIFYVOTE_SUCCESS,
        data: res?.data,
      });
      !!callback?.success && callback.success(res?.data);
    }
  } catch (error) {
    const details = error?.response?.data?.error?.details?.errors ?? '';
    const m = details
      ? details.map((_) => {
        return `Trường ${_.path.join(',')} : ${_.message}`;
      })
      : error?.response?.data?.error?.message ?? '' ?? '';
    yield put({
      type: typeActions.UPDATE_SPECIFYVOTE_FAILED,
      error: m,
    });
    !!callback?.failed && callback.failed(m);
  }
}

export function* printSpecifyVote(data) {
  const url = `${ServiceURL.printPcd}/${data.param}`;
  const callback = data.callback;
  try {
    const res = yield call(GET_FILE, url);
    if (!!res.data?.error?.message) {
      yield put({
        type: typeActions.PRINT_SPECIFYVOTE_FAILED,
        error: res.data?.error?.message,
      });
      !!callback?.failed && callback.failed(res.data.error.message);
    } else {
      yield put({
        type: typeActions.PRINT_SPECIFYVOTE_SUCCESS,
        data: res.data,
      });

      !!callback?.success && callback.success(res.data);
    }
  } catch (error) {
    yield put({
      type: typeActions.PRINT_SPECIFYVOTE_FAILED,
      error: error?.response?.data?.error?.message ?? '',
    });
    !!callback?.failed &&
      callback.failed(error?.response?.data?.error?.message ?? '');
  }
}

export default function* specifyvotesSaga() {
  yield takeLatest(typeActions.GET_SPECIFYVOTE_REQUEST, getSpecifyVotes);
  yield takeLatest(
    typeActions.GET_SPECIFYVOTE_BY_ID_REQUEST,
    getSpecifyVoteById
  );
  yield takeLatest(typeActions.CREATE_SPECIFYVOTE_REQUEST, createSpecifyVote);
  yield takeLatest(typeActions.DELETE_SPECIFYVOTE_REQUEST, deleteSpecifyVote);
  yield takeLatest(typeActions.UPDATE_SPECIFYVOTE_REQUEST, updateSpecifyVote);
  yield takeLatest(typeActions.PRINT_SPECIFYVOTE_REQUEST, printSpecifyVote);
  yield takeLatest(typeActions.EXPORT_SPECIFYVOTE_REQUEST, exportSpecifyVote);
}
