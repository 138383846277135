import React, { useEffect, useState } from 'react';
import { FormGroup } from 'reactstrap';
import DatePicker from 'react-datepicker';
import queryString from 'query-string';
import _ from 'lodash';

import CustomInputCalendar from 'views/pages/components/CustomInputCalendar';
import FilterCustom from 'views/pages/components/FilterCustom';
import LabelCustom from 'views/pages/components/LabelCustom';
import { useDispatch, useSelector } from 'react-redux';
import { orderActions } from 'redux/actions';
import SelectCustom from 'views/pages/components/SelectCustom';
import CONSTANT from 'constant';
import moment from 'moment';

const Filter = () => {
  const dispatch = useDispatch();

  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  // const [statusValue, setStatusValue] = useState(null);

  const handleClear = () => {
    setStartDate(null);
    setEndDate(null);
    // setStatusValue(null);
    dispatch(orderActions.setValue({ name: 'queryReceivePay', value: {} }));
  };
  const handleClose = () => {};
  const handleFilter = () => {
    const query = {
      'filters[$and][6][createdAt][$gte]': !!startDate
        ? new Date(startDate).toISOString()
        : '',
      'filters[$and][7][createdAt][$lte]': !!endDate
        ? new Date(endDate).toISOString()
        : '',

      // 'filters[$and][6][status][$eq]': statusValue?.value ?? '',
    };
    dispatch(orderActions.setValue({ name: 'queryReceivePay', value: query }));
  };
  return (
    <FilterCustom
      handleClear={handleClear}
      handleClose={handleClose}
      handleFilter={handleFilter}
    >
      <FormGroup>
        <LabelCustom
          label={'Chọn thời gian'}
          style={{ marginBottom: 0 }}
          styleLabel={{}}
        />
        <div className="date-picker-custom  d-flex">
          <LabelCustom
            label={'Từ:'}
            style={{ textAlign: 'end', margin: '0.5em 8px 0px 0px' }}
            styleLabel={{}}
          />
          <DatePicker
            customInput={<CustomInputCalendar />}
            wrapperClassName="icon-calendar"
            dateFormat={'dd/MM/yyyy'}
            selected={startDate}
            maxDate={endDate}
            onChange={(date) => {
              const startOfDate = moment(date).startOf('date').toDate();
              setStartDate(startOfDate);
            }}
          />
          <LabelCustom
            label={'đến:'}
            style={{ textAlign: 'end', margin: '0.5em 8px 0px 0px' }}
            styleLabel={{
              minWidth: 60,
            }}
          />
          <DatePicker
            dateFormat={'dd/MM/yyyy'}
            customInput={<CustomInputCalendar />}
            wrapperClassName="icon-calendar"
            minDate={startDate}
            selected={endDate}
            onChange={(date) => {
              const endOfDate = moment(date).endOf('date').toDate();
              setEndDate(endOfDate);
            }}
          />
        </div>
      </FormGroup>
      {/* 
      <FormGroup>
        <SelectCustom
          name="status"
          label="Chọn trạng thái"
          placeholder="Lựa chọn"
          value={statusValue}
          isRequired={false}
          isClearable={true}
          handleChange={(e) => {
            setStatusValue(e);
          }}
          options={[
            CONSTANT.ORDER_STATUS[9],
            CONSTANT.ORDER_STATUS[10],
            CONSTANT.ORDER_STATUS[11],
          ]}
        />
      </FormGroup> */}
    </FilterCustom>
  );
};

export default Filter;
