//core-componens
import React, { useCallback, useEffect, useState } from 'react';
import { Card, CardBody, CardHeader, Col, Row } from 'reactstrap';
//custom components
import LabelCustom from 'views/pages/components/LabelCustom';
import InputCustom from 'views/pages/components/InputCustom';
//module
import _ from 'lodash';
import queryString from 'query-string';
import { getListSelectOption } from 'common';
import { notificationAlertRef, notify } from 'common';
//styles
import 'assets/css/custom-pages/specify-votes.css';
import 'assets/css/custom-pages/create-specify-votes.css';
import GeneralInfomationLeft from './GeneralInfomationLeft';

function ReadGeneralInfomation({ patient }) {
  return (
    <Card>
      <CardHeader className="py-3">
        <h3 className="mb-0">Thông tin chi tiết bệnh nhân</h3>
      </CardHeader>
      <CardBody className="px-3 py-3">
        <Row>
          <GeneralInfomationLeft patient={patient} />
          {/* <GeneralInfomationRight patient={patient} /> */}
        </Row>
      </CardBody>
    </Card>
  );
}

export default ReadGeneralInfomation;
