import React, { memo, useEffect, useState } from 'react';
import _ from 'lodash';
import { Card, Container, Row, Spinner } from 'reactstrap';
import SimpleHeader from 'components/Headers/SimpleHeader.js';
import 'assets/css/custom-pages/react-bs-table.css';
import { useDispatch, useSelector } from 'react-redux';
import queryString from 'query-string';
import * as yup from 'yup';

import { Formik } from 'formik';
import OrderInfo from './components/OrderInfo';
import PCD from './components/PCD';
import {
  // medicalRecordActions,
  orderActions,
  patientActions,
  specifyVotesActions,
  uploadActions,
} from 'redux/actions';
import CONSTANTS from 'constant';
import { useHistory, useParams } from 'react-router';
import {
  checkRole,
  getPregnancy,
  notificationAlertRef,
  notify,
  relationOneToOne,
} from 'common';

function Order() {
  const dispatch = useDispatch();
  const history = useHistory();
  const { id } = useParams();

  const {
    isBusinessStaff,
    isSampleCollectorStaff,
    isAdmin,
    currentAccountExtend,
  } = useSelector((state) => state.accountReducer);
  const { order, isGettingOrder } = useSelector((state) => state.orderReducer);
  const [accountValue, setAccountValue] = useState(null);
  const [specifyVoteValue, setSpecifyVoteValue] = useState(null);
  const testGR = _.get(specifyVoteValue, 'testGR', '');
  const specifyVote = _.get(order, 'specifyVote', null);
  const specifyVoteInit = {
    codePCD: specifyVote?.codePCD ?? '',
    addressBN: specifyVote?.addressBN ?? '',
    anamnesis: specifyVote?.anamnesis ?? '',
    anamnesisBA: specifyVote?.anamnesisBA ?? '',
    biopsyDay: !!specifyVote?.biopsyDay
      ? new Date(specifyVote.biopsyDay)
      : new Date(),
    biopsyPerson: specifyVote?.biopsyPerson ?? '',
    cellNucleus: specifyVote?.cellNucleus ?? false,
    cellSolution: specifyVote?.cellSolution ?? '',
    codeBN: specifyVote?.codeBN ?? '',
    codePKQ: specifyVote?.codePKQ ?? '',
    codeTest: specifyVote?.codeTest ?? '',
    dateOfBirthBN: specifyVote?.dateOfBirthBN ?? '',
    dateTM: !!specifyVote?.dateTM ? new Date(specifyVote.dateTM) : new Date(),
    ultrasoundDays: !!specifyVote?.ultrasoundDays
      ? new Date(specifyVote.ultrasoundDays)
      : null,
    diagnose: specifyVote?.diagnose ?? '',
    diseaseRecurrence: specifyVote?.diseaseRecurrence ?? '',
    drugResistance: specifyVote?.drugResistance ?? '',
    emailBN: specifyVote?.emailBN ?? '',
    embryoEvaluation: specifyVote?.embryoEvaluation ?? '',
    embryoState: specifyVote?.embryoState ?? '',
    familyHistory: specifyVote?.familyHistory ?? '',
    firstNameBS: specifyVote?.firstNameBS ?? '',
    idBS: specifyVote?.idBS ?? '',
    genderBN: specifyVote?.genderBN ?? -1,
    height: specifyVote?.height ?? 0,
    height1: specifyVote?.height1 ?? 0,
    // hourTM: specifyVote?.hourTM ?? '',
    imageAnalysation: specifyVote?.imageAnalysation ?? null,
    jobBN: specifyVote?.jobBN ?? '',
    lastNameBS: specifyVote?.lastNameBS ?? '',
    lifeStyle: specifyVote?.lifeStyle ?? '',
    // medicalRecord: specifyVote?.medicalRecord ?? null,
    nameBN: specifyVote?.nameBN ?? '',
    nameContact: specifyVote?.nameContact ?? '',
    nameTC: specifyVote?.nameTC ?? '',
    nameTest: specifyVote?.nameTest ?? '',
    content: specifyVote?.content ?? '',
    nappySkin: specifyVote?.nappySkin ?? 0,
    negativeControl: specifyVote?.negativeControl ?? '',
    noPhoi: specifyVote?.noPhoi ?? null,
    numOfEmployment: specifyVote?.numOfEmployment ?? 0,
    numOfEmbryos: specifyVote?.numOfEmbryos ?? 0,
    numOfEmbryos1: specifyVote?.numOfEmbryos1 ?? 0,
    numOfEmbryos2: specifyVote?.numOfEmbryos2 ?? 0,
    numOfEmbryos3: specifyVote?.numOfEmbryos3 ?? 0,
    numOfEmbryos4: specifyVote?.numOfEmbryos4 ?? 0,
    pathological1: specifyVote?.pathological1 ?? '',
    pathological2: specifyVote?.pathological2 ?? '',
    patient: specifyVote?.patient?.id ?? null,
    medicalRecord: specifyVote?.medicalRecord?.id ?? null,
    phoneBN: specifyVote?.phoneBN ?? '',
    phoneContact: specifyVote?.phoneContact ?? '',
    // result: specifyVote?.result ?? null,
    result1: specifyVote?.result1 ?? '',
    result2: specifyVote?.result2 ?? '',
    result3: specifyVote?.result3 ?? '',
    result4: specifyVote?.result4 ?? '',
    resultStatus: specifyVote?.resultStatus ?? false,
    specifyVoteStatus: specifyVote?.specifyVoteStatus ?? 0,
    specimens: specifyVote?.specimens ?? [],
    medicationsUsing: specifyVote?.medicationsUsing ?? [],
    symptom: specifyVote?.symptom ?? '',
    test: specifyVote?.test ?? '',
    testGR: specifyVote?.testGR ?? testGR,
    time: specifyVote?.time ?? 0,
    timeTreatment: specifyVote?.timeTreatment ?? 0,
    treatment: specifyVote?.treatment ?? '',
    typePregnancy: specifyVote?.typePregnancy ?? 0,
    weight: specifyVote?.weight ?? 0,
    weight1: specifyVote?.weight1 ?? 0,
    whereTM: specifyVote?.whereTM ?? '',
    weekPregnancy: !specifyVote ? '' : specifyVote?.weekPregnancy ?? 0,
    dayOfPregnancy: !specifyVote ? '' : specifyVote?.dayOfPregnancy ?? 0,
    // KM
    codePromotion: specifyVote?.codePromotion ?? '',
    codeCTKM: specifyVote?.codeCTKM ?? '',
    form: specifyVote?.form ?? 0,
    valuePromotion: specifyVote?.valuePromotion ?? 0,
    promotionDetailStartDate: specifyVote?.promotionDetailStartDate ?? null,
    promotionStatus: specifyVote?.promotionStatus ?? false,
    promotionDeleted: specifyVote?.promotionDeleted ?? true,
    unitPrice: specifyVote?.unitPrice ?? 0,
    id: specifyVote?.id ?? -1,
    timeTest: specifyVote?.timeTest ?? 0,
  };

  const initInfo = {
    id: 1,
    code: order?.code ?? '',
    name: order?.name ?? '',
    barcode1: order?.barcode1 ?? '',
    barcode2: order?.barcode2 ?? '',
    barcode3: order?.barcode3 ?? '',
    barcode4: order?.barcode4 ?? '',
    barcode5: order?.barcode5 ?? '',
    note: order?.note ?? '',
    amountMoney: order?.amountMoney ?? 0,
    image: order?.image?.url ?? '',
    imageUrl: order?.image?.url ?? '',
    imageId: order?.image?.id ?? '',
    codeStaff1: order?.codeStaff1?.id ?? null,
    codeStaff2: order?.codeStaff2?.id ?? null,
    codeStaff3: order?.codeStaff3?.id ?? null,
    promotionDetail: order?.promotionDetail?.id ?? null,
    organization: order?.organization?.id ?? null,
    method: order?.organization?.method ?? 1,
    codePromotion: order?.codePromotion ?? '',
    codeCTKM: order?.codeCTKM ?? '',

    areaName: order?.areaName ?? '',
    status: order?.status ?? 0,
    specifyVote: specifyVoteInit,
    sendResultForPatient: order?.sendResultForPatient ?? false,
  };

  const orderSchema = yup.object().shape({
    // code: yup.string().required('Mã đơn hàng không được để trống!').nullable(),
    name: yup.string().required('Tên đơn hàng không được để trống!').nullable(),
    // organization: yup
    //   .number()
    //   .required('Tổ chức không được để trống!')
    //   .nullable(),
    barcode1: yup
      .string()
      .required('Mã Barcode phiếu xét nghiệm không được để trống!')
      .nullable(),
    // barcode2: yup
    //   .string()
    //   .required('Mã Barcode mẫu 1 không được để trống!')
    //   .nullable(),
    codeStaff1: yup
      .string()
      .required('Nhân viên phụ trách không được để trống!')
      .nullable(),
    codeStaff2: yup
      .string()
      .required('Nhân viên thu mẫu không được để trống!')
      .nullable(),
    imageUrl: yup
      .string()
      .required('Vui lòng chọn ảnh phiếu xét nghiệm')
      .nullable(),
    // PCD
    specifyVote: yup.object().shape({
      id: yup
        .number()
        .min(1, 'Mã phiếu xét nghiệm không được để trống!')
        .required('Mã phiếu xét nghiệm không được để trống!')
        .nullable(),
      // patient: yup
      //   .number()
      //   .min(1, 'Id Mã bệnh nhân không được để trống!')
      //   .required('Id Mã bệnh nhân không được để trống!')
      //   .nullable(),
      codeBN: yup
        .string()
        .length(10, 'Số điện thoại bao gồm 10 số!')
        .required('Số điện thoại không được để trống!')
        .matches(
          '0[1-9][0-9]{8}',
          'Vui lòng nhập đúng định dạng số điện thoại!'
        ),
      nameBN: yup
        .string()
        .required('Tên bệnh nhân không được để trống!')
        .nullable(),
      dateOfBirthBN: yup
        .string()
        .required('Ngày sinh bệnh nhân không được để trống!'),
      genderBN: yup
        .number()
        .required('Giới tính bệnh nhân không được để trống!')
        .nullable(),
      // phoneBN: yup
      //   .string()
      //   .length(10, "Số điện thoại bao gồm 10 số!")
      //   .required("Số điện thoại không được để trống!")
      //   .matches(
      //     "0[1-9][0-9]{8}",
      //     "Vui lòng nhập đúng định dạng số điện thoại!"
      //   ),
      pathological1: yup
        .string()
        .required('Bệnh lý cấp tính không được để trống!')
        .nullable(),
      addressBN: yup
        .string()
        .required('Địa chỉ bệnh nhân không được để trống!')
        .nullable(),
      phoneContact: yup
        .string()
        .length(10, 'Số điện thoại bao gồm 10 số!')
        .matches(
          '0[1-9][0-9]{8}',
          'Vui lòng nhập đúng định dạng số điện thoại!'
        )
        .required('Số điện thoại người liên hệ không được để trống!'),
      emailBN: yup
        .string()
        .email('Vui lòng nhập đúng định dạng email!')
        .nullable(),
      nameContact: yup
        .string()
        .required('Người liên hệ không được để trống!')
        .nullable(),
      codeTest: yup
        .string()
        .required('Mã xét nghiệm không được để trống!')
        .nullable(),
      nameTest: yup
        .string()
        .required('Tên xét nghiệm không được để trống!')
        .nullable(),
      specimens: yup
        .array()
        .min(1, 'Mẫu xét nghiệm không được để trống!')
        .required('Mẫu xét nghiệm không được để trống!')
        .nullable(),
      medicationsUsing: yup
        .array()
        .min(1, 'Thuốc đang dùng không được để trống!')
        .required('Thuốc đang dùng không được để trống!')
        .nullable(),
      content: yup
        .string()
        .required('Nội dung xét nghiệm không được để trống!')
        .nullable(),
      typePregnancy: yup
        .string()
        .required('Số lượng thai không được để trống!')
        .nullable(),
      // nameTC: yup
      //   .string()
      //   .required('Phòng khám không được để trống!')
      //   .nullable(),
      firstNameBS: yup
        .string()
        .required('Bác sĩ chỉ định không được để trống!')
        .nullable(),
      whereTM: yup
        .string()
        .required('Nơi thu mẫu không được để trống!')
        .nullable(),
      dateTM: yup
        .date()
        .required('Ngày thu mẫu không được để trống!')
        .nullable(),
      // hourTM: yup
      //   .string()
      //   .required('Giờ thu mẫu không được để trống!')
      //   .nullable(),
      height: yup.lazy((height) => {
        switch (_.get(specifyVoteValue, 'testGR', '0').toString()) {
          case '2':
            return yup.string().nullable();
          default:
            return yup
              .number()
              .min(0.000001, 'Chiều cao phải lớn hơn 0!')
              .required('Chiều cao không được để trống!')
              .nullable();
        }
      }),
      weight: yup.lazy((weight) => {
        switch (_.get(specifyVoteValue, 'testGR', '0').toString()) {
          case '2':
            return yup.string().nullable();

          default:
            return yup
              .number()
              .min(0.000001, 'Cân nặng phải lớn hơn 0!')
              .required('Cân nặng không được để trống!')
              .nullable();
        }
      }),
      anamnesisBA: yup
        .string()
        .required('Tiền sử bệnh nhân không được để trống!')
        .nullable(),

      // not require
      height1: yup.number().min(0, 'Chiều cao phải lớn hơn 0!').nullable(),
      weight1: yup.number().min(0, 'Cân nặng phải lớn hơn 0!').nullable(),
      numOfEmbryos: yup.number().min(0, 'Số lượng phải lớn hơn 0!').nullable(),
      numOfEmbryos1: yup
        .number()
        .min(0, 'Số phôi tạo ra phải lớn hơn 0!')
        .nullable(),
      // time: yup.number().min(0, 'Thời gian bệnh phải lớn hơn 0!').nullable(),
      nappySkin: yup.number().min(0, 'Độ mờ da gáy phải lớn hơn 0!').nullable(),
      weekPregnancy: yup.lazy((str) => {
        switch (!!testGR ? testGR.toString() : '0') {
          case '0': //Nhóm sản
            return yup
              .string()
              .test(
                'check-weekPregnancy',
                'Tuần thai phải là số nguyên dương!',
                (weekPregnancy) => {
                  try {
                    const week = parseInt(weekPregnancy);
                    return week >= 0;
                  } catch (error) {
                    return false;
                  }
                }
              )
              .required('Tuần thai không được để trống!');
          default:
            return yup.string().nullable();
        }
      }),
      dayOfPregnancy: yup.lazy((str) => {
        switch (!!testGR ? testGR.toString() : '0') {
          case '0': //Nhóm sản
            return yup
              .string()
              .test(
                'check-dayOfPregnancy',
                'Ngày thai phải là số nguyên dương!',
                (dayOfPregnancy) => {
                  try {
                    const day = parseInt(dayOfPregnancy);
                    return day >= 0;
                  } catch (error) {
                    return false;
                  }
                }
              )
              .required('Ngày thai không được để trống!');
          default:
            return yup.string().nullable();
        }
      }),
    }),
  });
  const handleGetOrder = () => {
    if (!id) return;
    dispatch(orderActions.getOrder(id, queryString.stringify({})));
  };

  useEffect(() => {
    handleGetOrder();
  }, [id]);

  const handleUploadAvatar = (values) => {
    const formData = new FormData();
    formData.append('files', values.image.file, values.image.file.name);
    dispatch(
      uploadActions.uploadFiles(formData, {
        success: (data) => {
          try {
            handleCUOrder(values, data[0].id ?? '');
          } catch (error) {}
        },
        failed: (mess) => {
          notify(
            notificationAlertRef,
            'danger',
            'Thông báo',
            `Tải ảnh lên thất bại!. Lỗi: ${mess}!`
          );
        },
      })
    );
  };

  const onSubmit = (values) => {
    if (_.isEqual(initInfo, values)) return history.goBack();
    handleCUPatient(values);
    // handleCUMedicalRecord(values);
    // handleUSpecifyVote(values);
  };

  const handleCUPatient = (values) => {
    if (!values.specifyVote.emailBN) values.specifyVote.emailBN = null;
    // return;
    const { specifyVote } = values;
    const body = {
      code: specifyVote.codeBN,
      name: specifyVote.nameBN,
      dateOfBirth: specifyVote?.dateOfBirthBN ?? '',
      phoneContact: specifyVote.phoneContact,
      nameContact: specifyVote.nameContact,
      gender: specifyVote.genderBN,
      address: specifyVote.addressBN,
      email: specifyVote.emailBN,
      phone: specifyVote.phoneBN,
      job: specifyVote.jobBN,
      idBS: specifyVote.idBS,
      specifyVotes: relationOneToOne(specifyVote?.id ?? -1, null),
    };

    if (!values.specifyVote.patient) {
      dispatch(
        patientActions.createPatient(
          Object.assign(body, {
            doctorAppointeds: relationOneToOne(specifyVote.idBS, null),
          }),
          {
            success: (data) => {
              notify(
                notificationAlertRef,
                'success',
                'Thông báo',
                `Thêm mới bệnh nhân thành công.`
              );
              handleUSpecifyVote(values, data?.id ?? -1);
            },
            failed: (mess) => {
              notify(
                notificationAlertRef,
                'danger',
                'Thông báo',
                `Thêm mới bệnh nhân thất bại!. Lỗi: ${mess}!`
              );
            },
          }
        )
      );
    } else {
      dispatch(
        patientActions.updatePatient(
          values.specifyVote.patient,
          Object.assign(body, {
            doctorAppointeds: relationOneToOne(values.specifyVote.idBS, null),
          }),
          {
            success: (data) => {
              notify(
                notificationAlertRef,
                'success',
                'Thông báo',
                `Cập nhật bệnh nhân thành công.`
              );
              handleUSpecifyVote(values, data?.id ?? -1);
            },
            failed: (mess) => {
              notify(
                notificationAlertRef,
                'danger',
                'Thông báo',
                `Cập nhật bệnh nhân thất bại!. Lỗi: ${mess}!`
              );
            },
          }
        )
      );
    }
  };

  const handleUSpecifyVote = (values, patientId) => {
    const body = {
      ...values.specifyVote,
      height: !!values.specifyVote.height ? values.specifyVote.height : null,
      weight: !!values.specifyVote.weight ? values.specifyVote.weight : null,
      specimens: JSON.stringify(values.specifyVote.specimens),
      medicationsUsing: JSON.stringify(values.specifyVote.medicationsUsing),
      dayOfPregnancy: getPregnancy(
        values.specifyVote.weekPregnancy,
        values.specifyVote.dayOfPregnancy,
        'day'
      ),
      weekPregnancy: getPregnancy(
        values.specifyVote.weekPregnancy,
        values.specifyVote.dayOfPregnancy,
        'week'
      ),
      // patient: relationOneToOne(values.specifyVote.patient, null),
      patient: relationOneToOne(patientId, null),
    };

    dispatch(
      specifyVotesActions.updateSpecifyVote(
        values?.specifyVote?.id ?? -1,
        body,
        {
          success: (data) => {
            handleUpload(values, data.id);
            notify(
              notificationAlertRef,
              'success',
              'Thông báo',
              `Cập nhật phiếu xét nghiệm thành công!`
            );
          },
          failed: (mess) => {
            notify(
              notificationAlertRef,
              'danger',
              'Thông báo',
              `Cập nhật phiếu xét nghiệm thất bại. Lỗi: ${mess}`
            );
          },
        }
      )
    );
  };

  const handleUpload = (values) => {
    if (typeof values.image === 'object') {
      if (values.image !== '' && id !== '-1')
        return dispatch(
          uploadActions.deleteFile(values.imageId, {
            success: () => {
              handleUploadAvatar(values);
            },
            failed: (__) => {
              handleUploadAvatar(values);
            },
          })
        );
      return handleUploadAvatar(values);
    } else {
      return handleCUOrder(values, values.imageId);
    }
  };

  const handleCUOrder = (values, imageId) => {
    const {
      name,
      barcode1,
      barcode2,
      barcode3,
      barcode4,
      barcode5,
      note,
      amountMoney,
      codeStaff1,
      codeStaff2,
      organization,
      codeStaff3,
      areaName,
      status,
      promotionDetail,
      codePromotion,
      codeCTKM,
      form,
      valuePromotion,
      promotionDetailStartDate,
      promotionDetailEndDate,
      promotionStatus,
      promotionDeleted,
      specifyVote,
      sendResultForPatient,
    } = values;

    const receptionStatus =
      order.status === CONSTANTS.ORDER_STATUS[7].value &&
      status === CONSTANTS.ORDER_STATUS[1].value
        ? false
        : order.receptionStatus;

    const body = {
      name,
      barcode1,
      barcode2,
      barcode3,
      barcode4,
      barcode5,
      note,
      amountMoney,
      areaName,
      image: imageId,
      status:
        status === CONSTANTS.ORDER_STATUS[3].value &&
        order?.receptionStatus &&
        order?.approveStatus &&
        (_.get(values, 'method', 1) !== 1 || order?.bill?.paymentStatus)
          ? CONSTANTS.ORDER_STATUS[4].value
          : status,
      codePromotion,
      codeCTKM,
      form,
      valuePromotion,
      promotionDetailStartDate,
      promotionDetailEndDate,
      promotionStatus,
      promotionDeleted,
      promotionDetail: relationOneToOne(promotionDetail, null),
      codeStaff1: relationOneToOne(codeStaff1, null),
      codeStaff2: relationOneToOne(codeStaff2, null),
      codeStaff3: relationOneToOne(codeStaff3, null),
      organization: relationOneToOne(organization, null),
      receptionStatus,
      sendResultForPatient,
    };

    if (id === '-1') {
      body.specifyVote = relationOneToOne(specifyVote.id, null);

      dispatch(
        orderActions.createOrder(
          { code: 'Default', ...body },
          {
            success: (data) => {
              dispatch(
                orderActions.updateOrder(data?.id, {
                  image: imageId,
                })
              );
              setTimeout(() => {
                history.goBack();
              }, 2000);

              notify(
                notificationAlertRef,
                'success',
                'Thông báo',
                `Thêm mới đơn hàng thành công!`
              );
            },
            failed: (mess) => {
              notify(
                notificationAlertRef,
                'danger',
                'Thông báo',
                `Thêm mới đơn hàng thất bại. Lỗi: ${mess}!`
              );
            },
          }
        )
      );
      return;
    }
    dispatch(
      orderActions.updateOrder(
        order.id,
        { ...body, isUpdated: true },
        {
          success: () => {
            setTimeout(() => {
              history.goBack();
            }, 2000);
            notify(
              notificationAlertRef,
              'success',
              'Thông báo',
              `Cập nhật đơn hàng thành công!`
            );
          },
          failed: (mess) => {
            notify(
              notificationAlertRef,
              'danger',
              'Thông báo',
              `Cập nhật đơn hàng thất bại. Lỗi: ${mess}!`
            );
          },
        }
      )
    );
  };

  useEffect(() => {
    if (
      !isSampleCollectorStaff &&
      !isBusinessStaff &&
      !isAdmin &&
      (checkRole(currentAccountExtend, {
        roles: ['update-order-all'],
      }) ||
        checkRole(currentAccountExtend, {
          roles: ['create-order-all'],
        }))
    ) {
      history.goBack();
    }
  }, [isBusinessStaff]);

  return (
    <>
      <SimpleHeader />
      <Container className="mt--9" fluid>
        <Row className="position-relative">
          <div style={{ minHeight: 500 }} className="col">
            {!isGettingOrder && (
              <Formik
                initialValues={initInfo}
                onSubmit={onSubmit}
                validationSchema={orderSchema}
              >
                {({ values, errors }) => {
                  return (
                    <>
                      <OrderInfo
                        accountValue={accountValue}
                        setAccountValue={setAccountValue}
                        setSpecifyVoteValue={setSpecifyVoteValue}
                      />
                      <PCD
                        specifyVoteValue={specifyVoteValue}
                        setSpecifyVoteValue={setSpecifyVoteValue}
                        accountValue={accountValue}
                        setAccountValue={setAccountValue}
                      />
                    </>
                  );
                }}
              </Formik>
            )}
          </div>
          {isGettingOrder && (
            <Card
              className="position-absolute d-flex w-100 justify-content-center rounded"
              style={{
                top: 0,
                height: '100%',
                // background: 'rgba(0,0,0,.1)',
              }}
            >
              <div className="d-flex w-100 justify-content-center rounded">
                <Spinner className="mt-5" color="info">
                  Loading...
                </Spinner>
              </div>
            </Card>
          )}
        </Row>
      </Container>
    </>
  );
}

export default memo(Order);
