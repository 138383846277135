import React, { memo, useEffect, useState } from 'react';
import paginationFactory from 'react-bootstrap-table2-paginator';
import _ from 'lodash';
import {
  Card,
  CardHeader,
  Container,
  Input,
  Row,
  UncontrolledTooltip,
} from 'reactstrap';
import queryString from 'query-string';

import { checkRole, notificationAlertRef, notify } from 'common';
import { promotionDetailActions, formActions } from 'redux/actions';
import SimpleHeader from 'components/Headers/SimpleHeader.js';
import 'assets/css/custom-pages/react-bs-table.css';
import { useDispatch, useSelector } from 'react-redux';
import CUPromotionDetail from './components/CUPromotionDetail';
import { useDidUpdateEffect } from 'utils/HookUtils';
import ModalWarningCustom from 'views/pages/components/ModalWarningCustom';
import SwitchCustom from 'views/pages/components/SwitchCustom';

import IconEdit from 'assets/svgs/edit.svg';
import TableCustom from '../components/TableCustom';
import moment from 'moment';

function PromotionDetail() {
  const dispatch = useDispatch();
  const { page, limit, promotionDetails, isDeletingPromotionDetail } =
    useSelector((state) => state.promotionDetailReducer);
  const { currentAccountExtend } = useSelector((state) => state.accountReducer);

  const results = _.get(promotionDetails, 'results', []);
  const total = _.get(promotionDetails, 'pagination.total', 0);

  const [promotionDetailSearch, setPromotionDetailSearch] = useState('');
  const [notificationModal, setNotificationModal] = useState(false);
  const [promotionDetail, setPromotionDetail] = useState(null);

  const columns = [
    {
      dataField: 'code',
      text: 'MÃ KHUYẾN MẠI',
    },
    {
      dataField: 'promotion.name',
      text: 'CHƯƠNG TRÌNH KHUYẾN MÃI',
    },
    {
      dataField: 'services',
      text: 'DỊCH VỤ ÁP DỤNG',
      formatter: (cell) => {
        return cell.map((item, index, arr) => {
          return index === arr.length - 1
            ? `${item?.name ?? ''}(${item?.code ?? ''}).`
            : `${item?.name ?? ''}(${item?.code ?? ''}), `;
        });
      },
    },
    {
      dataField: 'organization',
      text: 'MÃ TỔ CHỨC',
      formatter: (cell) => {
        if (!cell?.code) return '';
        return `${cell?.name ?? ''} (${cell?.code ?? ''})`;
      },
    },
    {
      dataField: 'amount',
      text: 'TỔNG SỐ LƯỢNG',
      style: {
        textAlign: 'center',
      },
      headerStyle: {
        textAlign: 'center',
      },
    },
    {
      dataField: 'amountUsed',
      text: 'SỐ LƯỢNG ĐÃ DÙNG',
      style: {
        textAlign: 'center',
      },
      headerStyle: {
        textAlign: 'center',
      },
    },
    {
      dataField: 'startDate',
      text: 'THỜI GIAN BẮT ĐẦU',
      formatter: (cell) => {
        return !!cell ? moment(cell).format('DD/MM/YYYY') : '';
      },
      style: {
        textAlign: 'center',
      },
      headerStyle: {
        textAlign: 'center',
      },
    },
    {
      dataField: 'endDate',
      text: 'THỜI GIAN KẾT THÚC',
      formatter: (cell) => {
        return !!cell ? moment(cell).format('DD/MM/YYYY') : '';
      },
      style: {
        textAlign: 'center',
      },
      headerStyle: {
        textAlign: 'center',
      },
    },
    {
      dataField: 'status',
      text: 'TRẠNG THÁI',
      formatter: (cell, row) => {
        return (
          <SwitchCustom
            className="custom-toggle custom-toggle-primary mr-1"
            value="status"
            labelOff="Tắt"
            labelOn="Bật"
            checked={cell}
            onChange={(e) => {
              dispatch(
                promotionDetailActions.updatePromotionDetail(
                  row.id,
                  { status: e.target.checked },
                  {
                    success: () => {
                      handleGetPromotionDetails();
                    },
                    failed: (mess) => {
                      notify(
                        notificationAlertRef,
                        'danger',
                        'Thông báo',
                        `Cập nhật trạng thái thất bại!. Lỗi: ${mess}`
                      );
                    },
                  }
                )
              );
            }}
          />
        );
      },
      style: {
        textAlign: 'center',
        display: 'flex',
        justifyContent: 'center',
      },
      headerStyle: {
        textAlign: 'center',
      },
    },
    {
      dataField: 'actions',
      text: 'HÀNH ĐỘNG',
      formatter: boxActions,
      headerStyle: {
        textAlign: 'center',
      },
      headerClasses: 'fixed-column',
      classes: 'fixed-column',
    },
  ];

  function boxActions(__, row) {
    return (
      <div className="d-flex justify-content-center">
        {checkRole(currentAccountExtend, {
          roles: ['update-promotion'],
        }) && (
          <>
            <button
              id={'edit' + row.id}
              onClick={() => {
                dispatch(
                  formActions.setValue({
                    name: 'modalInfo',
                    value: row,
                  })
                );
                dispatch(
                  formActions.setValue({
                    name: 'isModalVisible',
                    value: true,
                  })
                );
              }}
              className="btn p-0 btn-no-shadow mr-0"
            >
              <img src={IconEdit} className="mr-1" />
            </button>
            <UncontrolledTooltip
              delay={1}
              placement="top"
              target={'edit' + row.id}
            >
              Cập nhật chi tiết khuyến mại
            </UncontrolledTooltip>
          </>
        )}
        {/* <button
          id={'lock' + row.id}
          onClick={() => {
            setPromotionDetail(row);
            setNotificationModal(true);
          }}
          className="btn p-0 btn-no-shadow mr-0"
        >
          <img src={IconBin} />
        </button>
        <UncontrolledTooltip delay={1} placement="top" target={'lock' + row.id}>
          Xóa chi tiết khuyến mại
        </UncontrolledTooltip> */}
      </div>
    );
  }

  const onSizePerPageChange = (e) => {
    dispatch(promotionDetailActions.setValue({ name: 'page', value: 1 }));
    dispatch(
      promotionDetailActions.setValue({ name: 'limit', value: e.target.value })
    );
  };

  const onPageChange = (value) => {
    dispatch(promotionDetailActions.setValue({ name: 'page', value: value }));
  };

  const pagination = paginationFactory({
    page: page,
    sizePerPage: limit,
    totalSize: total,
    showTotal: false,
    withFirstAndLast: false,
    alwaysShowAllBtns: true,
    onPageChange: onPageChange,
    sizePerPageRenderer: () => {
      return (
        <div className="dataTables_length" id="datatable-basic_length">
          <label>
            Hiển thị{' '}
            {
              <select
                name="datatable-basic_length"
                aria-controls="datatable-basic"
                className="form-control form-control-sm"
                onChange={onSizePerPageChange}
              >
                <option value="10">10</option>
                <option value="25">25</option>
                <option value="50">50</option>
                <option value="100">100</option>
              </select>
            }{' '}
            dòng.
          </label>
        </div>
      );
    },
  });

  useDidUpdateEffect(() => {
    handleGetPromotionDetails();
  }, [page, limit]);

  useDidUpdateEffect(() => {
    // Sau 500ms thì bắn api
    const delayDebounceFn = setTimeout(() => {
      handleGetPromotionDetails();
    }, 500);
    return () => clearTimeout(delayDebounceFn);
  }, [promotionDetailSearch]);

  useEffect(() => {
    if (!_.isEmpty(promotionDetails)) return;
    handleGetPromotionDetails();
  }, []);

  const handleGetPromotionDetails = () => {
    const query = {
      pageSize: limit,
      page,
    };
    if (promotionDetailSearch !== '') {
      query['_q'] = promotionDetailSearch;
    }
    dispatch(
      promotionDetailActions.getPromotionDetails(queryString.stringify(query))
    );
  };

  const handleDelete = () => {
    if (promotionDetail === null) return;
    dispatch(
      promotionDetailActions.updatePromotionDetail(
        promotionDetail.id,
        { deleted: true },
        {
          success: () => {
            setNotificationModal(false);
            notify(
              notificationAlertRef,
              'success',
              'Thông báo',
              `Xóa chi tiết khuyến mại ${promotionDetail?.name} thành công!`
            );
            handleGetPromotionDetails();
          },
          failed: (mess) => {
            notify(
              notificationAlertRef,
              'danger',
              'Thông báo',
              `Xóa chi tiết khuyến mại ${promotionDetail?.name} thất bại!. Lỗi: ${mess}`
            );
          },
        }
      )
    );
  };

  return (
    <>
      <SimpleHeader />
      <CUPromotionDetail
        handleGetPromotionDetails={handleGetPromotionDetails}
      />
      <ModalWarningCustom
        notificationModal={notificationModal}
        setNotificationModal={setNotificationModal}
        name={`chi tiết khuyến mại ${promotionDetail?.name ?? ''}`}
        func={handleDelete}
        isDelete={isDeletingPromotionDetail}
      />
      <Container className="mt--9" fluid>
        <Row>
          <div className="col">
            <TableCustom
              tableName="Danh sách chi tiết khuyến mại"
              data={results}
              columns={columns}
              page={page}
              limit={limit}
              total={total}
              searchBy={'Tìm kiếm'}
              pagination={pagination}
              search={promotionDetailSearch}
              onChange={(e) => {
                setPromotionDetailSearch(e.target.value);
              }}
            />
          </div>
        </Row>
      </Container>
    </>
  );
}

export default memo(PromotionDetail);
