import React, { memo, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Button, Col, Form, FormGroup, Input, Modal, Row } from 'reactstrap';
import * as yup from 'yup';
import { Formik } from 'formik';
import _ from 'lodash';

import {
  currencyFormat,
  notificationAlertRef,
  notify,
  relationOneToOne,
  isNumeric,
} from 'common';
import LabelCustom from 'views/pages/components/LabelCustom';
import { billActions, formActions, paymentHistoryActions } from 'redux/actions';
import LoadingButtonCustom from 'views/pages/components/LoadingButtonCustom';
import CONSTANT from 'constant';
import InputCustom from 'views/pages/components/InputCustom';
import SelectCustom from 'views/pages/components/SelectCustom';
import LabelValue from 'views/pages/components/LabelValue';
import { BASE_URL_IMAGE } from 'services/ServiceURL';
import CustomInputCalendar from 'views/pages/components/CustomInputCalendar';
import DatePicker from 'react-datepicker';

const UPayment = ({ handleGetOrders }) => {
  const dispatch = useDispatch();

  // reducers
  const { isModalVisible, modalInfo, formType } = useSelector(
    (state) => state.formReducer
  );
  const { isCreatingOrder } = useSelector((state) => state.orderReducer);
  // Khởi tạo dữ liêu
  const initInfo = {
    code: modalInfo?.code ?? '',
    name: modalInfo?.name ?? '',
    organization: modalInfo?.organization?.name ?? '',
    amountMoney: 0,
    payer: -1,
    paymentMethod: 0,
    paymentDate: new Date(),
    bankAccount: 'TIEN_MAT',
  };

  const [paymentHistories, setPaymentHistories] = useState([]);

  const [payerValue, setPayerValue] = useState(null);
  const [bankAccountValue, setBankAccountValue] = useState({
    label: '0123456789',
    value: -1,
  });

  // validate dữ liêu
  const infoSchema = yup.object().shape({
    code: yup.string().required('Mã đơn hàng không được để trống!'),
    name: yup.string().required('Tên đơn hàng không được để trống!'),
    amountMoney: yup
      .number()
      .min(0.0000000001, 'Số tiền hoàn trả phải lớn hơn 0!')
      .required('Số tiền hoàn trả không được để trống!')
      .nullable(),
    payer: yup
      .number()
      .min(0, 'Người nộp không được để trống')
      .required('Người nộp không được để trống!')
      .nullable(),
    paymentMethod: yup
      .number()
      .min(0, 'Hình thức thanh toán không được để trống')
      .required('Hình thức thanh toán không được để trống!')
      .nullable(),
  });

  const remain = currencyFormat(
    Number(modalInfo?.bill?.totalMoney ?? 0) -
      Number(modalInfo?.bill?.reduce ?? 0) -
      Number(modalInfo?.bill?.paid ?? 0)
  );

  // Xử lý submit
  const onSubmit = (values, { resetForm }) => {
    if (remain < 0) return;
    const body = values;
    dispatch(
      paymentHistoryActions.createPaymentHistory(body, {
        success: (data) => {
          // notify(
          //   notificationAlertRef,
          //   'success',
          //   'Thông báo',
          //   `Thêm mới thanh toán thành công!`
          // );
          handleUpdateBill(data.id, resetForm);
        },
        failed: (mess) => {
          notify(
            notificationAlertRef,
            'danger',
            'Thông báo',
            `Thêm mới thanh toán thất bại. Lỗi: ${mess}`
          );
        },
      })
    );
  };

  const handleUpdateBill = (idPaymentHistory, resetForm) => {
    const body = {
      paymentHistories: relationOneToOne(idPaymentHistory, null),
    };
    dispatch(
      billActions.updateBill(modalInfo.bill.id, body, {
        success: (data) => {
          resetForm();
          handleGetOrders();
          notify(
            notificationAlertRef,
            'success',
            'Thông báo',
            `Thêm mới thanh toán thành công!`
          );
          handleClose();
        },
        failed: (mess) => {
          notify(
            notificationAlertRef,
            'danger',
            'Thông báo',
            `Cập nhật hóa đơn thất bại. Lỗi: ${mess}`
          );
        },
      })
    );
  };

  // Xử lý đóng form
  const handleClose = () => {
    setPaymentHistories([]);
    setPayerValue(null);
    setBankAccountValue({
      label: '0123456789',
      value: -1,
    });
    dispatch(
      formActions.setValue({
        name: 'isModalVisible',
        value: false,
      })
    );
    dispatch(
      formActions.setValue({
        name: 'modalInfo',
        value: {},
      })
    );
  };

  useEffect(() => {
    if (!modalInfo?.bill?.id) return;
    handleGetRelation(modalInfo.bill.id);
  }, [modalInfo]);

  const handleGetRelation = (id) => {
    dispatch(
      billActions.getBillRelation(id, 'paymentHistories', {
        success: (data) => {
          setPaymentHistories(data);
        },
        failed: () => {},
      })
    );
  };
  let image = `${BASE_URL_IMAGE}${modalInfo?.imagePayment?.url}`;
  if (!modalInfo?.imagePayment?.url)
    image = require('assets/img/default/pcd_default.png').default;

  return (
    <Modal
      size={'lg'}
      isOpen={isModalVisible && formType === 1}
      className="modal-dialog-centered modal-secondary"
      toggle={() => {
        handleClose();
      }}
    >
      <div className="modal-header border-bottom bg-white pb-2">
        <h2>Cập nhật đơn hàng chưa thanh toán</h2>
        <button
          aria-label="Close"
          className="close"
          data-dismiss="modal"
          type="button"
          onClick={() => {
            handleClose();
          }}
        >
          <span style={{ color: '#262626' }} aria-hidden={true}>
            ×
          </span>
        </button>
      </div>
      <div className="modal-body bg-white rounded">
        <Formik
          initialValues={initInfo}
          // enableReinitialize
          onSubmit={onSubmit}
          validationSchema={infoSchema}
        >
          {({
            values,
            errors,
            touched,
            handleSubmit,
            handleBlur,
            handleChange,
            setFieldValue,
          }) => {
            return (
              <Row>
                <Col xs={7}>
                  <Form onSubmit={handleSubmit} className="">
                    <Row>
                      <Col xs={12}>
                        <LabelValue
                          label="Mã đơn hàng"
                          value={values?.code ?? ''}
                          labelWidth={180}
                        />
                      </Col>
                    </Row>
                    <Row>
                      <Col xs={12}>
                        <LabelValue
                          label="Tên đơn hàng"
                          value={values?.name ?? ''}
                          labelWidth={180}
                        />
                      </Col>
                    </Row>

                    <Row>
                      <Col xs={12}>
                        <LabelValue
                          label="Phòng khám/Bệnh viện"
                          value={values?.organization ?? ''}
                          labelWidth={180}
                        />
                      </Col>
                    </Row>
                    <Row>
                      <Col xs={12}>
                        <LabelValue
                          label="Số tiền cần hoàn của thu mẫu"
                          value={`${currencyFormat(
                            Number(modalInfo?.amountMoney) ?? 0
                          )} VNĐ`}
                          labelWidth={180}
                        />
                      </Col>
                    </Row>
                    <Row>
                      <Col xs={12}>
                        <LabelValue
                          label="Số tiền cần hoàn"
                          value={`${remain} VNĐ`}
                          labelWidth={180}
                        />
                      </Col>
                    </Row>
                    <Row>
                      <Col xs={6}>
                        <InputCustom
                          name="amountMoney"
                          label="Số tiền hoàn trả"
                          type="number"
                          placeholder="Nhập"
                          value={values.amountMoney}
                          isRequired={true}
                          handleChange={(e) => {
                            if (isNumeric(e.target.value)) {
                              setFieldValue('amountMoney', e.target.value);
                            }
                          }}
                          handleBlur={handleBlur}
                          error={errors?.amountMoney}
                          touched={touched?.amountMoney}
                        />
                      </Col>
                      <Col xs={6}>
                        <SelectCustom
                          name="payer"
                          label="Người nộp"
                          placeholder="Lựa chọn"
                          value={payerValue}
                          isRequired={true}
                          isClearable={false}
                          handleChange={(e) => {
                            setFieldValue('payer', e?.value ?? null);
                            setPayerValue(e);
                          }}
                          handleBlur={handleBlur}
                          error={errors.payer}
                          touched={touched.payer}
                          options={CONSTANT.SUBMITTER}
                        />
                      </Col>
                    </Row>
                    <Row>
                      <Col xs={12}>
                        <LabelCustom
                          isRequired={true}
                          label={'Hình thức thanh toán'}
                        />
                        <FormGroup
                          onChange={(e) => {
                            setFieldValue(
                              'paymentMethod',
                              Number(e.target.value)
                            );
                            if (
                              Number(e.target.value) ===
                              CONSTANT.HISTORY_PAYMENT_METHOD[1].value
                            ) {
                              setFieldValue(
                                'bankAccount',
                                bankAccountValue.label
                              );
                            } else {
                              setFieldValue('bankAccount', 'TIEN_MAT');
                            }
                          }}
                          className="d-flex mb-0"
                        >
                          <div className="mx-3">
                            <FormGroup check>
                              <Input
                                checked={
                                  values.paymentMethod ===
                                  CONSTANT.HISTORY_PAYMENT_METHOD[0].value
                                }
                                value={CONSTANT.HISTORY_PAYMENT_METHOD[0].value}
                                name="history-payment-method"
                                id="cash"
                                type="radio"
                              />
                            </FormGroup>
                            <LabelCustom
                              isRequired={false}
                              label={'Tiền mặt'}
                              id={'cash'}
                              styleLabel={{
                                marginLeft: 16,
                              }}
                            />
                          </div>
                          <div className="mx-3">
                            <FormGroup check>
                              <Input
                                checked={
                                  values.paymentMethod ===
                                  CONSTANT.HISTORY_PAYMENT_METHOD[1].value
                                }
                                value={CONSTANT.HISTORY_PAYMENT_METHOD[1].value}
                                name="history-payment-method"
                                id="transfer"
                                type="radio"
                              />
                            </FormGroup>
                            <LabelCustom
                              isRequired={false}
                              label={'Chuyển khoản'}
                              id={'transfer'}
                              styleLabel={{
                                marginLeft: 16,
                              }}
                            />
                          </div>
                        </FormGroup>
                      </Col>
                    </Row>
                    <Row>
                      {values.paymentMethod ===
                        CONSTANT.HISTORY_PAYMENT_METHOD[1].value && (
                        <Col xs={6}>
                          <SelectCustom
                            name="codeStaff2"
                            label="Tài khoản nhận"
                            placeholder="Lựa chọn"
                            value={bankAccountValue}
                            isRequired={true}
                            isClearable={false}
                            handleChange={(e) => {
                              setFieldValue('bankAccount', e?.value ?? null);
                              setBankAccountValue(e);
                            }}
                            handleBlur={handleBlur}
                            error={errors.bankAccount}
                            touched={touched.bankAccount}
                            options={[]}
                          />
                        </Col>
                      )}
                      <Col xs={6}>
                        {/* <LabelValue
                          label="Ngày thu"
                          value={moment(values.paymentDate).format(
                            'DD/MM/YYYY'
                          )}
                          labelWidth={
                            values.paymentMethod ===
                            CONSTANT.HISTORY_PAYMENT_METHOD[1].value
                              ? 110
                              : 60
                          }
                        /> */}
                        <LabelCustom
                          label={'Ngày thu:'}
                          style={{
                            textAlign: 'end',
                            margin: '0.5em 8px 0px 0px',
                          }}
                          styleLabel={{}}
                        />
                        <DatePicker
                          customInput={<CustomInputCalendar />}
                          wrapperClassName="icon-calendar"
                          dateFormat={'dd/MM/yyyy'}
                          selected={values?.paymentDate ?? new Date()}
                          maxDate={new Date()}
                          onChange={(date) => {
                            setFieldValue('paymentDate', date);
                          }}
                        />
                      </Col>
                    </Row>

                    <div className="mb-0 pb-0 mt-4 d-flex justify-content-end">
                      <Button
                        className="text-nowrap mr-1"
                        onClick={() => {
                          handleClose();
                        }}
                      >
                        Hủy
                      </Button>
                      <LoadingButtonCustom
                        color="info"
                        className="text-nowrap "
                        type="submit"
                        loading={isCreatingOrder}
                      >
                        Cập nhật
                      </LoadingButtonCustom>
                    </div>
                  </Form>
                </Col>
                <Col xs={5}>
                  <LabelValue
                    label="Hình ảnh chuyển khoản"
                    value={''}
                    labelWidth={180}
                  />
                  <img
                    alt="..."
                    className="shadow"
                    src={image}
                    style={{ width: '100%', height: 'auto', borderRadius: 4 }}
                  />
                </Col>
              </Row>
            );
          }}
        </Formik>
      </div>
    </Modal>
  );
};

export default memo(UPayment);
