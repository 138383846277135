import _ from "lodash";
import { all, call, put, takeEvery, takeLatest } from "redux-saga/effects";

import typeActions from "redux/typeActions";
import ServiceURL from "services/ServiceURL";
import { DELETE, GET, GET_FILE, POST, PUT } from "services/ServiceBase";
import { convertMessageError } from "common";

/**
 *
 * @param {*} data
 * get list organizations:
 * - failed -> call actions failed
 * - success ->
 *      + if (search in select-options) => return;
 *      + if (search in list organizations) => call actions sucess
 */
export function* getOrganizations(data) {
  const url = `${ServiceURL.organization}?filters[$and][0][deleted][$eq]=false&sort[0]=createdAt:DESC&${data.query}`;
  const callback = data.callback;
  const isUpdateReducer = data.isUpdateReducer;
  try {
    const res = yield call(GET, url);
    if (!!res?.error?.message) {
      yield put({
        type: typeActions.GET_ORGANIZATION_FAILED,
        error: res.data?.error?.message,
      });
      !!callback?.failed && callback.failed(res.data.error.message);
    } else {
      if (isUpdateReducer) {
        yield put({
          type: typeActions.GET_ORGANIZATION_SUCCESS,
          data: res.data,
        });
      }
      !!callback?.success && callback.success(res.data.results);
    }
  } catch (error) {
    yield put({
      type: typeActions.GET_ORGANIZATION_FAILED,
      error: error?.response?.data?.error?.message ?? "",
    });
    !!callback?.failed &&
      callback.failed(error?.response?.data?.error?.message ?? "");
  }
}
export function* getOrganizationById(data) {
  const url = `${ServiceURL.organization}/${data.param}`;
  const callback = data.callback;
  try {
    const res = yield call(GET, url);
    if (!!res?.error?.message) {
      yield put({
        type: typeActions.GET_ORGANIZATION_BY_ID_FAILED,
        error: res.data?.error?.message,
      });
      !!callback?.failed && callback.failed(res.data.error.message);
    } else {
      yield put({
        type: typeActions.GET_ORGANIZATION_BY_ID_SUCCESS,
        data: res.data,
      });
      !!callback?.success && callback.success(res);
    }
  } catch (error) {
    yield put({
      type: typeActions.GET_ORGANIZATION_BY_ID_FAILED,
      error: error?.response?.data?.error?.message ?? "",
    });
    !!callback?.failed &&
      callback.failed(error?.response?.data?.error?.message ?? "");
  }
}

export function* createOrganization(data) {
  const url = ServiceURL.organization;
  const callback = data.callback;
  try {
    const res = yield call(POST, url, data.params);
    if (!!res.data?.error?.message) {
      yield put({
        type: typeActions.CREATE_ORGANIZATION_FAILED,
        error: res.data.error.message,
      });

      !!callback?.failed && callback.failed(res.data.error.message);
    } else {
      yield put({
        type: typeActions.CREATE_ORGANIZATION_SUCCESS,
        data: res.data,
      });
      !!callback?.success && callback.success(res.data);
    }
  } catch (error) {
    const message = convertMessageError("c", error, " tổ chức ");
    yield put({
      type: typeActions.CREATE_ORGANIZATION_FAILED,
      error: message,
    });
    !!callback?.failed && callback.failed(message);
  }
}

export function* deleteOrganization(data) {
  const url = ServiceURL.organization + "/" + data.params;
  const callback = data.callback;
  try {
    const res = yield call(DELETE, url);
    if (!!res.data?.error?.message) {
      yield put({
        type: typeActions.DELETE_ORGANIZATION_FAILED,
        error: res.data.error.message,
      });
      !!callback?.failed && callback.failed(res.data.error.message);
    } else {
      yield put({
        type: typeActions.DELETE_ORGANIZATION_SUCCESS,
      });
      !!callback?.success && callback.success();
    }
  } catch (error) {
    yield put({
      type: typeActions.DELETE_ORGANIZATION_FAILED,
      error: error?.response?.data?.error?.message ?? "",
    });
    !!callback?.failed &&
      callback.failed(error?.response?.data?.error?.message ?? "");
  }
}
export function* updateOrganization(data) {
  const url = ServiceURL.organization + "/" + data.params;
  const callback = data.callback;
  try {
    const res = yield call(PUT, url, data.body);
    if (!!res?.error?.message) {
      yield put({
        type: typeActions.UPDATE_ORGANIZATION_FAILED,
        error: res.data.error.message,
      });
      !!callback?.failed && callback.failed(res.data.error.message);
    } else {
      yield put({
        type: typeActions.UPDATE_ORGANIZATION_SUCCESS,
        data: res.data?.data,
      });
      !!callback?.success && callback.success(res?.data?.data);
    }
  } catch (error) {
    yield put({
      type: typeActions.UPDATE_ORGANIZATION_FAILED,
      error: error.message,
    });
    !!callback?.failed && callback.failed(error.message);
  }
}

export function* getOrganizationsByAccountExtend({ param, callback }) {
  try {
    const urlRelation = `${ServiceURL.relation}/api::account-extend.account-extend/${param}/organizations?pageSize=100`;
    const res = yield call(GET, urlRelation);
    const results = _.get(res, "data.results", []);
    const apiOrganizations =
      results.map((item) => {
        const url = `${ServiceURL.organization}?filters[$and][0][deleted][$eq]=false&filters[$and][1][status][$eq]=0&filters[$and][2][code][$eq]=${item.code}&sort[0]=createdAt:DESC`;
        return call(GET, url);
      }) ?? [];
    let resOrganizations = [];
    try {
      resOrganizations = yield all(apiOrganizations);
    } catch (error) {
      //
    }
    !!callback?.success &&
      callback.success(
        resOrganizations
          .map((item) => _.get(item, "data.results[0]", null))
          .filter((item) => item !== null)
      );
  } catch (error) {
    // !!callback?.failed && callback.failed(error);
  }
}
export function* getOrganizationsByAccountBS({ param, query, callback }) {
  try {
    const urlRelation = `${ServiceURL.relation}/api::account-extend.account-extend/${param}/organizations?pageSize=100`;
    const res = yield call(GET, urlRelation);
    const results = _.get(res, "data.results", []);
    const apiOrganizations =
      results.map((item) => {
        const url = `${ServiceURL.organization}?filters[$and][0][deleted][$eq]=false&filters[$and][1][status][$eq]=0&filters[$and][2][code][$eq]=${item.code}&${query}&sort[0]=createdAt:DESC`;
        return call(GET, url);
      }) ?? [];
    let resOrganizations = [];
    try {
      resOrganizations = yield all(apiOrganizations);
    } catch (error) {
      //
    }
    !!callback?.success &&
      callback.success(
        resOrganizations
          .map((item) => _.get(item, "data.results[0]", null))
          .filter((item) => item !== null)
      );
  } catch (error) {
    !!callback?.failed && callback.failed(error);
  }
}

export function* exportOrganizations(data) {
  const url = `${ServiceURL.exportOrganizations}?${data.query}`;
  const callback = data.callback;
  try {
    const res = yield call(GET_FILE, url);
    if (!!res.data?.error?.message) {
      yield put({
        type: typeActions.EXPORT_ORGANIZATION_FAILED,
        error: res.data?.error?.message,
      });
      !!callback?.failed && callback.failed(res.data.error.message);
    } else {
      yield put({
        type: typeActions.EXPORT_ORGANIZATION_SUCCESS,
        data: res.data,
      });

      !!callback?.success && callback.success(res.data);
    }
  } catch (error) {
    yield put({
      type: typeActions.EXPORT_ORGANIZATION_FAILED,
      error: error?.response?.data?.error?.message ?? '',
    });
    !!callback?.failed &&
      callback.failed(error?.response?.data?.error?.message ?? '');
  }
}
export default function* organizarionSaga() {
  yield takeEvery(typeActions.GET_ORGANIZATION_REQUEST, getOrganizations);
  yield takeLatest(typeActions.CREATE_ORGANIZATION_REQUEST, createOrganization);
  yield takeLatest(typeActions.DELETE_ORGANIZATION_REQUEST, deleteOrganization);
  yield takeLatest(typeActions.UPDATE_ORGANIZATION_REQUEST, updateOrganization);
  yield takeLatest(
    typeActions.GET_ORGANIZATION_BY_ID_REQUEST,
    updateOrganization
  );

  yield takeLatest(
    typeActions.GET_ORGANIZATIONS_BY_ACCOUNT_EXTEND_REQUEST,
    getOrganizationsByAccountExtend
  );
  yield takeLatest(
    typeActions.GET_ORGANIZATIONS_BY_ACCOUNT_BS_REQUEST,
    getOrganizationsByAccountBS
  );
  yield takeLatest(typeActions.EXPORT_ORGANIZATION_REQUEST, exportOrganizations);
}
