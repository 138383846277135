import React, { memo, useEffect, useState } from 'react';
import _ from 'lodash';
import {
  Button,
  Card,
  CardBody,
  CardHeader,
  Col,
  Container,
  Row,
} from 'reactstrap';
import queryString from 'query-string';
import SimpleHeader from 'components/Headers/SimpleHeader.js';
import 'assets/css/custom-pages/react-bs-table.css';
import { useDispatch, useSelector } from 'react-redux';
import LabelCustom from '../components/LabelCustom';
import { notificationAlertRef, notify, relationOneToOne } from 'common';
import LoadingButtonCustom from '../components/LoadingButtonCustom';
import { useHistory, useLocation } from 'react-router';
import ResultsXN from './components/ResultsXN';
import {
  additionalSampleActions,
  convertFileActions,
  orderActions,
  resultActions,
  uploadActions,
} from 'redux/actions';
import CONSTANT from '../../../constant';
import SelectCustom from '../components/SelectCustom';

function ReceiveResults() {
  const history = useHistory();
  const dispatch = useDispatch();
  const location = useLocation();
  const { listType } = queryString.parse(location.search);

  const { isConverting } = useSelector((state) => state.convertFileReducer);
  const { isUploading } = useSelector((state) => state.uploadReducer);
  const { isUpdatingOrder, isGettingOrder } = useSelector(
    (state) => state.orderReducer
  );

  const [orderTypeValue, setOrderTypeValue] = useState(CONSTANT.ORDER_TYPE[0]);
  const [resultsXN, setResultsXN] = useState([]);
  const [filePdfs, setFilePdfs] = useState([]);

  const handleDeleteOrder = (index) => {
    setResultsXN([...resultsXN.slice(0, index), ...resultsXN.slice(index + 1)]);
  };

  const handleUpdateOrder = (name, value, idxO, idxR = -1, nameR) => {
    try {
      let tmp = _.cloneDeep(resultsXN[idxO]);
      if (name === '') {
        tmp = value;
      } else if (idxR === -1) {
        tmp[name] = value;
      } else {
        tmp[name][idxR][nameR] = value;
        if (nameR === 'value' && value > tmp[name][idxR]['abnormal']) {
          tmp[name][idxR]['result'] = 'Phát hiện bất thường';
        } else if (nameR === 'value' && value < tmp[name][idxR]['normal']) {
          tmp[name][idxR]['result'] = 'Không phát hiện bất thường';
        }
      }

      setResultsXN([
        ...resultsXN.slice(0, idxO),
        tmp,
        ...resultsXN.slice(idxO + 1),
      ]);
    } catch (error) { }
  };

  const handleCreateResults = () => {
    const data = _.uniqBy(resultsXN, 'order').filter(
      (item) => item.order !== -1 && !_.isEmpty(item?.labCode)
    );

    const tmp = data.map((item) => ({
      order: relationOneToOne(item.order, null),
      labCode: item?.labCode ?? '',
      CDNA: item?.CDNA ?? '',
      resultsDetail: JSON.stringify(item.resultsDetail),
      consultation: item.consultation,
    }));
    if (tmp.length === 0)
      return notify(
        notificationAlertRef,
        'warning',
        'Thông báo',
        `Vui lòng điền đủ thông tin tối thiểu một đơn hàng xét nghiệm để tiếp nhận!`
      );
    let isEmptyValue = false;
    let isEmptyResult = false;
    data.forEach((item) => {
      if (!_.isEmpty(item?.resultsDetail)) {
        item.resultsDetail.every((r) => {
          if (r?.value === '') {
            isEmptyValue = true;
            return true;
          }
          return false;
        });
      }
    });
    if (isEmptyValue)
      return notify(
        notificationAlertRef,
        'warning',
        'Thông báo',
        `Vui lòng nhập đầy đủ giá trị nguy cơ đơn hàng đã chọn!`
      );
    data.forEach((item) => {
      if (!_.isEmpty(item?.resultsDetail)) {
        item.resultsDetail.every((r) => {
          if (r?.result === '') {
            isEmptyResult = true;
            return true;
          }
          return false;
        });
      }
    });
    if (isEmptyResult)
      return notify(
        notificationAlertRef,
        'warning',
        'Thông báo',
        `Vui lòng nhập đầy đủ mục kết quả đơn hàng đã chọn!`
      );
    const body = {
      ...tmp[0],
      others: tmp.slice(1),
    };

    handleUploadFiles(data);
    if (listType === 'order') {
      dispatch(
        resultActions.createResult(body, {
          success: () => {
            notify(
              notificationAlertRef,
              'success',
              'Thông báo',
              `Lưu thành công!`
            );
            setTimeout(() => {
              history.push('/admin/receive-result');
            }, 2000);
          },
          failed: (mess) => {
            notify(
              notificationAlertRef,
              'warning',
              'Thông báo',
              `Lưu thất bại. Lỗi ${mess}!`
            );
          },
        })
      );
    }
  };

  const handleConvertFile = () => {
    if (filePdfs.length === 0) return;
    dispatch(
      convertFileActions.convertFilePdf(filePdfs, {
        success: (data) => {
          try {
            const tmp = data.map((item, index) => {
              return {
                orderCode: item?.Tel ?? '',
                BSChiDinh: item?.BSChiDinh ?? '',
                KQ: item?.KQ ?? '',
                MaSo: item?.MaSo ?? '',
                Name: item?.Name ?? '',
                NgaySinh: item?.NgaySinh ?? '',
                NgayXN: item?.NgayXN ?? '',
                ThuMau: item?.ThuMau ?? '',
                TuoiThai: item?.TuoiThai ?? '',
                key: index,
                order: null,
                labCode: item?.Code ?? '',
                consultation: false,
                file: filePdfs[index],
                resultsDetail: [
                  {
                    content: 'Tam bội NST 21 (Trisomy 21)',
                    value: item?.Z21 ?? '',
                    normal: 2,
                    suspect: '2 - 3',
                    abnormal: 3,
                    result:
                      (item?.Z21 ?? 0) < 3
                        ? 'Không phát hiện bất thường'
                        : 'Phát hiện bất thường',
                  },
                  {
                    content: 'Tam bội NST 18 (Trisomy 18)',
                    value: item?.Z18 ?? '',
                    normal: 2,
                    suspect: '2 - 3',
                    abnormal: 3,
                    result:
                      (item?.Z18 ?? 0) < 3
                        ? 'Không phát hiện bất thường'
                        : 'Phát hiện bất thường',
                  },
                  {
                    content: 'Tam bội NST 13 (Trisomy 13)',
                    value: item?.Z13 ?? '',
                    normal: 2,
                    suspect: '2 - 3',
                    abnormal: 3,
                    result:
                      (item?.Z13 ?? 0) < 3
                        ? 'Không phát hiện bất thường'
                        : 'Phát hiện bất thường',
                  },
                  {
                    content: 'cfDNA thai (%)',
                    value: (item?.CDNA ?? '').replace('%', ''),
                    normal: '',
                    suspect: '',
                    abnormal: '',
                    result: '',
                  },
                ],
              };
            });
            setResultsXN(tmp);
          } catch (error) { }
        },
        failed: (mess) => {
          notify(
            notificationAlertRef,
            'danger',
            'Thông báo',
            `Cập nhật kết quả xét nghiệm thất bại. Lỗi: ${mess}!`
          );
        },
      })
    );
  };

  const handleUploadFiles = (data = []) => {
    let orders = [];
    if (orderTypeValue.value === CONSTANT.ORDER_TYPE[0].value) {
      orders = data.map((d) => {
        return {
          code: d.orderCode,
          file: d.file,
          consultation: d.consultation,
        };
      });
    } else {
      orders = filePdfs.map((file) => {
        const fileName = file.name;
        const code = fileName.split('_')[0];
        return {
          code,
          file,
          consultation: false,
        };
      });
    }

    const groupOrderFiles = _.chain(orders)
      .groupBy('code')
      .map((value, key) => ({
        code: key,
        files: value.map((v) => v.file),
        consultation: _.get(value, '[0].consultation', false),
      }))
      .value();
    if (listType === 'additional-sample') {
      const query = groupOrderFiles
        .map((groupOrderFile, index) => {
          const tmp = {};
          tmp[`filters[$and][2][$or][${index}][order][code][$eq]`] =
            groupOrderFile.code;
          return tmp;
        })
        .reduce((prev, curr) => ({ ...prev, ...curr }), {});
      dispatch(
        additionalSampleActions.getAdditionalSamples(
          queryString.stringify({
            'filters[$and][1][$or][0][status][$eq]':
              CONSTANT.ORDER_STATUS[6].value,
            'filters[$and][1][$or][1][status][$eq]':
              CONSTANT.ORDER_STATUS[9].value,
            'filters[$and][1][$or][2][status][$eq]':
              CONSTANT.ORDER_STATUS[10].value,
            'filters[$and][1][$or][3][status][$eq]':
              CONSTANT.ORDER_STATUS[11].value,
            'filters[$and][1][$or][4][status][$eq]':
              CONSTANT.ORDER_STATUS[12].value,
            'filters[$and][1][$or][5][status][$eq]':
              CONSTANT.ORDER_STATUS[13].value,
            ...query,
          }),
          {
            success: (obj) => {
              if (_.isEmpty(obj.results)) {
                notify(
                  notificationAlertRef,
                  'danger',
                  'Thông báo',
                  `Không có mẫu bổ sung thoả mãn điều kiện!`
                );
                return;
              }

              groupOrderFiles.forEach((order) => {
                const additionalSample = obj.results.find(
                  (result) => result.order.code === order.code
                );
                if (additionalSample) {
                  handleUploadFilesApi(additionalSample, order);
                }
              });

              if (orderTypeValue.value === 0) {
                const tmp = data
                  .map((item) => {
                    const additionalSample = obj.results.find(
                      (result) => result.order.id === item.order
                    );
                    if (additionalSample === undefined) return null;
                    return {
                      additionalSample: relationOneToOne(
                        additionalSample.id,
                        null
                      ),
                      labCode: item?.labCode ?? '',
                      CDNA: item?.CDNA ?? '',
                      resultsDetail: JSON.stringify(item.resultsDetail),
                      consultation: item.consultation,
                    };
                  })
                  .filter((val) => !_.isEmpty(val));
                const body = {
                  ...tmp[0],
                  others: tmp.slice(1),
                };
                dispatch(
                  resultActions.createResult(body, {
                    success: () => {
                      notify(
                        notificationAlertRef,
                        'success',
                        'Thông báo',
                        `Lưu thành công!`
                      );
                      setTimeout(() => {
                        history.push('/admin/receive-result');
                      }, 2000);
                    },
                    failed: (mess) => {
                      notify(
                        notificationAlertRef,
                        'warning',
                        'Thông báo',
                        `Lưu thất bại. Lỗi ${mess}!`
                      );
                    },
                  })
                );
              }
            },
          },
          false
        )
      );
    } else {
      const query = groupOrderFiles
        .map((groupOrderFile, index) => {
          const tmp = {};
          tmp[`filters[$and][2][$or][${index}][code][$eq]`] =
            groupOrderFile.code;
          return tmp;
        })
        .reduce((prev, curr) => ({ ...prev, ...curr }), {});
      dispatch(
        orderActions.getOrders(
          queryString.stringify({
            'filters[$and][1][$or][0][status][$eq]':
              CONSTANT.ORDER_STATUS[6].value,
            'filters[$and][1][$or][1][status][$eq]':
              CONSTANT.ORDER_STATUS[9].value,
            'filters[$and][1][$or][2][status][$eq]':
              CONSTANT.ORDER_STATUS[10].value,
            'filters[$and][1][$or][3][status][$eq]':
              CONSTANT.ORDER_STATUS[11].value,
            'filters[$and][1][$or][4][status][$eq]':
              CONSTANT.ORDER_STATUS[12].value,
            'filters[$and][1][$or][5][status][$eq]':
              CONSTANT.ORDER_STATUS[13].value,
            ...query,
          }),
          {
            success: (obj) => {
              if (_.isEmpty(obj.results)) {
                notify(
                  notificationAlertRef,
                  'danger',
                  'Thông báo',
                  `Không có đơn hàng thoả mãn điều kiện!`
                );
                return;
              }

              groupOrderFiles.forEach((order) => {
                const o = obj.results.find(
                  (result) => result.code === order.code
                );
                if (o) {
                  handleUploadFilesApi(o, order);
                }
              });
              if (orderTypeValue.value === 0) {
                const tmp = data
                  .map((item) => {
                    const o = obj.results.find(
                      (result) => result.id === item.order
                    );
                    if (o === undefined) return null;
                    return {
                      order: relationOneToOne(o.id, null),
                      labCode: item?.labCode ?? '',
                      CDNA: item?.CDNA ?? '',
                      resultsDetail: JSON.stringify(item.resultsDetail),
                      consultation: item.consultation,
                    };
                  })
                  .filter((val) => !_.isEmpty(val));
                const body = {
                  ...tmp[0],
                  others: tmp.slice(1),
                };
                dispatch(
                  resultActions.createResult(body, {
                    success: () => {
                      notify(
                        notificationAlertRef,
                        'success',
                        'Thông báo',
                        `Lưu thành công!`
                      );
                      setTimeout(() => {
                        history.push('/admin/receive-result');
                      }, 2000);
                    },
                    failed: (mess) => {
                      notify(
                        notificationAlertRef,
                        'warning',
                        'Thông báo',
                        `Lưu thất bại. Lỗi ${mess}!`
                      );
                    },
                  })
                );
              }
            },
          },
          false
        )
      );
    }
  };

  const handleUploadFilesApi = (value, orderImport) => {
    const formData = new FormData();
    orderImport.files.forEach((item) => {
      formData.append('files', item, item.name);
    });
    dispatch(
      uploadActions.uploadFiles(formData, {
        success: (data) => {
          handleUpdateOrderApi(value, data, orderImport.consultation);
        },
        failed: (mess) => {
          notify(
            notificationAlertRef,
            'danger',
            'Thông báo',
            `Upload file đơn hàng ${orderImport.code} lỗi. Lỗi: ${mess}!`
          );
        },
      })
    );
  };

  const handleUpdateOrderApi = (value, files, consultation) => {
    try {
      let body = {
        fileResult: files[0].id,
        status: consultation
          ? CONSTANT.ORDER_STATUS[10].value
          : CONSTANT.ORDER_STATUS[12].value,
        timeReceiveResult: new Date(),
        orderType: 'nipt',
      };

      if (orderTypeValue.value === CONSTANT.ORDER_TYPE[1].value) {
        // check tên file uniq labcode xem đã đủ số lượng labcode chưa, yes => status = 10
        // const labcodes = getLabcodes([...(value?.fileAppendixs ?? []), ...files]);
        let labcode = '';
        getLabcodes(files).every((code) => {
          if (
            !(value?.labCode ?? '')
              .split(',')
              .map((lab) => lab.trim())
              .includes(code.trim())
          ) {
            labcode = code;
            return false;
          }
          return true;
        });
        if (!_.isEmpty(labcode)) {
          notify(
            notificationAlertRef,
            'danger',
            'Thông báo',
            `Đơn hàng không được xét nghiệm tại ${labcode}!`
          );
          files.forEach((file) => {
            dispatch(
              uploadActions.deleteFile(file.id, {
                success: () => { },
                failed: () => { },
              })
            );
          });
          return;
        }
        body = {
          fileAppendixs: [...(value?.fileAppendixs ?? []), ...files],
          status: CONSTANT.ORDER_STATUS[12].value,
          timeReceiveResult: new Date(),
          orderType: 'appendix',
          // labcodes.length == (value?.labCode ?? '').split(',').length &&
          // (value?.labCode ?? '') !== ''
          //   ? CONSTANT.ORDER_STATUS[11].value
          //   : value.status,
        };
      }
      if (listType === 'order') {
        dispatch(
          orderActions.updateOrder(value.id, body, {
            success: () => {
              notify(
                notificationAlertRef,
                'success',
                'Thông báo',
                `Cập nhật đơn hàng ${value.code} thành công!`
              );
            },
            failed: (mess) => {
              notify(
                notificationAlertRef,
                'danger',
                'Thông báo',
                `Đơn hàng ${value.code} cập nhật lỗi. Error: ${mess}!`
              );
            },
          })
        );
      } else {
        dispatch(
          additionalSampleActions.updateAdditionalSample(value.id, body, {
            success: () => {
              notify(
                notificationAlertRef,
                'success',
                'Thông báo',
                `Cập nhật mẫu bổ sung ${value.order.code} thành công!`
              );
            },
            failed: (mess) => {
              notify(
                notificationAlertRef,
                'danger',
                'Thông báo',
                `Mẫu bổ sung ${value.order.code} cập nhật lỗi. Error: ${mess}!`
              );
            },
          })
        );
      }
    } catch (error) { }
  };

  const getLabcodes = (files) => {
    return _.uniqBy(
      files.map((file) => {
        try {
          return {
            labcode: file.name.split('_')[1].trim(),
          };
        } catch (error) {
          return {
            labcode: '',
          };
        }
      }),
      'labcode'
    )
      .filter((file) => file.labcode !== '')
      .map((file) => file.labcode);
  };

  return (
    <div className="position-relative">
      <SimpleHeader />

      <Container className="mt--9" fluid>
        <Row>
          <div className="col">
            <Card>
              <CardHeader className="pb-2 d-flex rounded align-items-center">
                <h3>
                  {listType === 'order' ? 'Đơn hàng' : 'Mẫu bổ sung'} cần tiếp
                  nhận
                </h3>
                &emsp;
                <SelectCustom
                  name="orderType"
                  label={`Chọn loại   ${listType === 'order' ? 'đơn hàng' : 'mẫu bổ sung'
                    }`}
                  placeholder="Lựa chọn"
                  value={orderTypeValue}
                  isRequired={true}
                  isClearable={false}
                  handleChange={(e) => {
                    setOrderTypeValue(e);
                  }}
                  options={CONSTANT.ORDER_TYPE}
                />
              </CardHeader>
              <CardBody>
                <Row>
                  <Col xs={12} md={12} lg={12} className={'d-flex'}>
                    <LabelCustom
                      isRequired={false}
                      label={'Tải file lên'}
                      styleLabel={{ minWidth: 110 }}
                      style={{
                        textAlign: 'end',
                        margin: '0.5em 8px 0px 0px',
                      }}
                    />
                    <div className="ml-2 d-flex align-items-center">
                      <input
                        style={{ fontSize: 12 }}
                        type="file"
                        multiple
                        accept=".pdf"
                        onChange={async (e) => {
                          try {
                            if (_.isEmpty(e)) return;
                            const files = await Promise.all([
                              ...e.target.files,
                            ]);
                            setFilePdfs(
                              files.map((file) => Object.assign(file))
                            );
                          } catch (error) {
                            //
                          }
                        }}
                      />
                    </div>
                    <LoadingButtonCustom
                      onClick={() => {
                        if (orderTypeValue.value === 0) {
                          handleConvertFile();
                        } else {
                          handleUploadFiles();
                        }
                      }}
                      color="info"
                      className="text-nowrap "
                      // type="submit"
                      loading={
                        isConverting ||
                        isUploading ||
                        isUpdatingOrder ||
                        isGettingOrder
                      }
                    >
                      Cập nhật
                    </LoadingButtonCustom>
                  </Col>
                </Row>
              </CardBody>
            </Card>
          </div>
        </Row>
      </Container>

      {resultsXN.map((item, index) => (
        <ResultsXN
          key={index}
          idxO={index}
          resultXN={item}
          listType={listType}
          handleDeleteOrder={handleDeleteOrder}
          handleUpdateOrder={handleUpdateOrder}
        />
      ))}

      <div
        style={{ bottom: 10, left: 0 }}
        className="position-fixed bg-white w-100 shadow-lg rounded"
      >
        <div className="m-2 d-flex justify-content-end">
          <Button
            className="text-nowrap mr-1"
            onClick={() => {
              history.push('/admin/receive-result');
            }}
          >
            Hủy
          </Button>

          {orderTypeValue.value === 0 && (
            <LoadingButtonCustom
              onClick={handleCreateResults}
              color="info"
              className="text-nowrap "
              type="submit"
              loading={false}
            >
              Lưu và thoát
            </LoadingButtonCustom>
          )}
        </div>
      </div>
    </div>
  );
}

export default memo(ReceiveResults);
