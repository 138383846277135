import React, { useState } from 'react';
// nodejs library to set properties for components
import PropTypes from 'prop-types';
// reactstrap components
import {
  Card,
  CardBody,
  CardTitle,
  Container,
  Row,
  Col,
  Input,
} from 'reactstrap';
import moment from 'moment';
import LabelCustom from 'views/pages/components/LabelCustom';
import IconXnError from 'assets/svgs/dashboard_xn_error.svg';
import IconXn from 'assets/svgs/dashboard_xn.svg';
import IconReceived from 'assets/svgs/dashboard_received.svg';
import IconResult from 'assets/svgs/dashboard_result.svg';
function CardsHeader({}) {
  const [time, setTime] = useState(0);
  return (
    <>
      <div className="header bg-info pb-6">
        <Container fluid>
          <div className="header-body">
            <Row className="align-items-center py-4">
              <Col lg="6" xs="7">
                {/* <h6 className="h2 text-white d-inline-block mb-0">{name}</h6>{' '}
                <Breadcrumb
                  className="d-none d-md-inline-block ml-md-4"
                  listClassName="breadcrumb-links breadcrumb-dark"
                >
                  <BreadcrumbItem>
                    <a href="#pablo" onClick={(e) => e.preventDefault()}>
                      <i className="fas fa-home" />
                    </a>
                  </BreadcrumbItem>
                  <BreadcrumbItem>
                    <a href="#pablo" onClick={(e) => e.preventDefault()}>
                      {parentName}
                    </a>
                  </BreadcrumbItem>
                  <BreadcrumbItem aria-current="page" className="active">
                    {name}
                  </BreadcrumbItem>
                </Breadcrumb> */}
              </Col>
              <Col className="d-flex justify-content-end" lg="6" xs="3">
                <LabelCustom
                  label={'Thời gian:'}
                  id="time"
                  style={{ textAlign: 'end', margin: '0.5em 8px 0px 0px' }}
                  styleLabel={{
                    minWidth: 60,
                  }}
                />
                <Input
                  style={{ maxWidth: 160, paddingBottom: 0, paddingTop: 0 }}
                  type="select"
                  name="select"
                  className="height-input"
                  id="time"
                  value={time}
                  onChange={(e) => {
                    setTime(e.target.value);
                  }}
                >
                  <option className="py-2" value={0}>
                    Hôm nay
                  </option>
                  <option className="py-2" value={1}>
                    Tháng này
                  </option>
                </Input>
              </Col>
            </Row>

            <Row>
              <Col sm={12} md={6}>
                <Card className="card-stats">
                  <CardBody style={{ paddingBottom: 3, paddingTop: 3 }}>
                    <Row>
                      <div className="col d-flex flex-column justify-content-center align-items-center">
                        <CardTitle
                          tag="h1"
                          className="text-uppercase text-muted mb-0"
                        >
                          Tổng số
                        </CardTitle>
                        <span className="h1 font-weight-bold mb-0">
                          350,897
                        </span>
                      </div>

                      <div className="col d-flex align-items-center">
                        <div
                          className="border-left"
                          style={{ height: '80%' }}
                        />
                        <CardBody className="">
                          <div className="border-bottom py-3">
                            <Row>
                              <div className="col">
                                <CardTitle
                                  tag="h5"
                                  className="text-uppercase text-muted mb-0"
                                >
                                  Đơn hàng chưa thanh toán
                                  <br />
                                  <i>(Trả trước)</i>
                                </CardTitle>
                                <div className="d-flex justify-content-between align-items-end mt-2">
                                  <span className="h2 font-weight-bold mb-0">
                                    350,897
                                  </span>
                                  <span
                                    style={{
                                      lineHeight: 1.5,
                                      color: '#0a66b2',
                                    }}
                                    className="text-nowrap text-underline"
                                  >
                                    Chi tiết {'>>'}
                                  </span>
                                </div>
                              </div>
                            </Row>
                          </div>
                          <div className="py-3">
                            <Row>
                              <div className="col">
                                <CardTitle
                                  tag="h5"
                                  className="text-uppercase text-muted mb-0"
                                >
                                  Đơn hàng chưa thanh toán
                                  <br />
                                  <i>(Trả sau)</i>
                                </CardTitle>
                                <div className="d-flex justify-content-between align-items-end mt-2">
                                  <span className="h2 font-weight-bold mb-0">
                                    350,897
                                  </span>
                                  <span
                                    style={{
                                      lineHeight: 1.5,
                                      color: '#0a66b2',
                                    }}
                                    className="text-nowrap text-underline"
                                  >
                                    Chi tiết {'>>'}
                                  </span>
                                </div>
                              </div>
                            </Row>
                          </div>
                        </CardBody>
                      </div>
                    </Row>
                  </CardBody>
                </Card>
              </Col>
              <Col md={12} lg={3}>
                <Card className="card-stats">
                  <CardBody>
                    <Row>
                      <div className="col">
                        <div className="pt-2">
                          <Row>
                            <div className="col">
                              <CardTitle
                                tag="h5"
                                className="text-uppercase text-muted mb-4"
                              >
                                Đơn hàng đang xét nghiệm lỗi
                              </CardTitle>

                              <span className="h2 font-weight-bold mb-0">
                                350,897
                              </span>
                            </div>
                          </Row>
                        </div>
                      </div>
                      <Col className="col-auto d-flex flex-column justify-content-between align-items-center">
                        <div className="icon icon-shape bg-gradient-red text-white rounded-circle shadow">
                          <img style={{ zoom: 0.8 }} src={IconXnError} />
                        </div>
                        <span
                          style={{ lineHeight: 1.5, color: '#0a66b2' }}
                          className="text-nowrap text-underline"
                        >
                          Chi tiết {'>>'}
                        </span>
                      </Col>
                    </Row>
                  </CardBody>
                </Card>
                <Card className="card-stats">
                  <CardBody>
                    <Row>
                      <div className="col">
                        <div className="pt-2">
                          <Row>
                            <div className="col">
                              <CardTitle
                                tag="h5"
                                className="text-uppercase text-muted mb-4"
                              >
                                Tổng hợp/tiếp nhận
                              </CardTitle>

                              <span className="h2 font-weight-bold mb-0">
                                350,897
                              </span>
                            </div>
                          </Row>
                        </div>
                      </div>
                      <Col className="col-auto d-flex flex-column justify-content-between align-items-center">
                        <div className="icon icon-shape bg-gradient-info text-white rounded-circle shadow">
                          <img style={{ zoom: 0.8 }} src={IconReceived} />
                        </div>
                        <span
                          style={{ lineHeight: 1.5, color: '#0a66b2' }}
                          className="text-nowrap text-underline"
                        >
                          Chi tiết {'>>'}
                        </span>
                      </Col>
                    </Row>
                  </CardBody>
                </Card>
              </Col>
              <Col md={12} lg={3}>
                <Card className="card-stats">
                  <CardBody>
                    <Row>
                      <div className="col">
                        <div className="pt-2">
                          <Row>
                            <div className="col">
                              <CardTitle
                                tag="h5"
                                className="text-uppercase text-muted mb-4"
                              >
                                Đang xét nghiệm
                              </CardTitle>

                              <span className="h2 font-weight-bold mb-0">
                                350,897
                              </span>
                            </div>
                          </Row>
                        </div>
                      </div>
                      <Col className="col-auto d-flex flex-column justify-content-between align-items-center">
                        <div className="icon icon-shape bg-gradient-success text-white rounded-circle shadow">
                          <img style={{ zoom: 0.8 }} src={IconXn} />
                        </div>
                        <span
                          style={{ lineHeight: 1.5, color: '#0a66b2' }}
                          className="text-nowrap text-underline"
                        >
                          Chi tiết {'>>'}
                        </span>
                      </Col>
                    </Row>
                  </CardBody>
                </Card>
                <Card className="card-stats">
                  <CardBody>
                    <Row>
                      <div className="col">
                        <div className="pt-2">
                          <Row>
                            <div className="col">
                              <CardTitle
                                tag="h5"
                                className="text-uppercase text-muted mb-4"
                              >
                                Đã trả kết quả
                              </CardTitle>

                              <span className="h2 font-weight-bold mb-0">
                                350,897
                              </span>
                            </div>
                          </Row>
                        </div>
                      </div>
                      <Col className="col-auto d-flex flex-column justify-content-between align-items-center">
                        <div className="icon icon-shape bg-gradient-success text-white rounded-circle shadow">
                          <img style={{ zoom: 0.8 }} src={IconResult} />
                        </div>
                        <span
                          style={{ lineHeight: 1.5, color: '#0a66b2' }}
                          className="text-nowrap text-underline mt-3"
                        >
                          Chi tiết {'>>'}
                        </span>
                      </Col>
                    </Row>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </div>
        </Container>
      </div>
    </>
  );
}

CardsHeader.propTypes = {
  name: PropTypes.string,
  parentName: PropTypes.string,
};

export default CardsHeader;
