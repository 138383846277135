import React from 'react';
import PropTypes from 'prop-types';
import { Container, Row, Col } from 'reactstrap';
import logo from 'assets/img/logo/logoBlue.png';

function AuthHeader({ title, lead }) {
  return (
    <>
      <Container>
        <div className="header-body">
          <Row className="d-flex  justify-content-center ">
            <Col
              className="mt-1 d-flex  justify-content-center"
              lg="3"
              md="3"
              xl="3"
            >
              <img src={logo} alt="Logo Hightech Genetics" height={'100px'} />
            </Col>
          </Row>
          <Row className="justify-content-center mt-1">
            <Col className="" lg="12" md="12" xl="12">
              {title ? (
                <h1 className="color-blue display-3 text-center">{title}</h1>
              ) : null}
              {lead ? <p className="text-lead text-white">{lead}</p> : null}
            </Col>
          </Row>
        </div>
      </Container>
    </>
  );
}

AuthHeader.propTypes = {
  title: PropTypes.string,
  lead: PropTypes.string,
};

export default AuthHeader;
