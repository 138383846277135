import React, { memo, useEffect, useState } from "react";
import _ from "lodash";
import { CardHeader, Col, Row } from "reactstrap";
import DatePicker from "react-datepicker";

import "assets/css/custom-pages/react-bs-table.css";
import {isNumeric } from "common";
import InputCustom from "views/pages/components/InputCustom";
import SelectCustom from "views/pages/components/SelectCustom";
import LabelCustom from "views/pages/components/LabelCustom";
import CONSTANT from "constant";
import HelperText from "views/pages/components/HelperText";
import CustomInputCalendar from "views/pages/components/CustomInputCalendar";
import { useFormikContext } from "formik";
import { useSelector } from "react-redux";
import DatePickerCustom from "views/pages/components/DatePickerCustom";

const NXNInfo = ({ isDisabled, specifyVoteValue }) => {
  const { setFieldValue, values, errors, touched, handleBlur, handleChange } =
    useFormikContext();

  const { order } = useSelector((state) => state.orderReducer);

  const [typePregnancyValue, setTypePregnancy] = useState(null);
  const [isCellNucleus, setIsCellNucleus] = useState(false);
  const [noPhoiValue, setNoPhoiValue] = useState(null);

  useEffect(() => {
    if (!order?.specifyVote?.typePregnancy) return setTypePregnancy(null);
    const typePregnancyV = CONSTANT.TYPE_PREGNANCY.find(
      (item) => item.value === order?.specifyVote?.typePregnancy
    );
    setTypePregnancy(typePregnancyV ?? null);
  }, [order?.specifyVote?.typePregnancy]);

  useEffect(() => {
    if (!order?.specifyVote?.noPhoi) return setNoPhoiValue(null);
    setNoPhoiValue({
      label: order?.specifyVote?.noPhoi + 1 ?? 1,
      value: -1,
    });
  }, [order?.specifyVote?.noPhoi]);

  useEffect(() => {
    setIsCellNucleus(order?.specifyVote?.cellNucleus ?? false);
  }, [order?.specifyVote?.cellNucleus]);

  useEffect(() => {
    if (!!specifyVoteValue) {
      const typePregnancyV = CONSTANT.TYPE_PREGNANCY.find(
        (item) => item.value === specifyVoteValue.typePregnancy
      );

      setTypePregnancy(typePregnancyV ?? null);
      setNoPhoiValue(
        !!specifyVoteValue?.noPhoi
          ? {
              label: specifyVoteValue?.noPhoi + 1 ?? 1,
              value: specifyVoteValue?.noPhoi ?? 0,
            }
          : null
      );
      setIsCellNucleus(specifyVoteValue?.cellNucleus ?? false);
    } else {
      setTypePregnancy(null);
      setNoPhoiValue(null);
      setIsCellNucleus(null);
    }
  }, [specifyVoteValue]);

  const renderTestGroup = () => {
    switch (values?.specifyVote?.testGR) {
      case 0:
        return (
          <>
            <Row>
              <Col xs={12} md={12} lg={6}>
                <InputCustom
                  name="specifyVote.weekPregnancy"
                  label="Tuần thai"
                  type="number"
                  placeholder="Nhập số"
                  value={_.get(
                    values,
                    "specifyVote.weekPregnancy",
                    ""
                  ).toString()}
                  isRequired={true}
                  handleChange={(e) => {
                    if (isNumeric(e.target.value)) {
                      setFieldValue(
                        "specifyVote.weekPregnancy",
                        e.target.value
                      );
                    }
                  }}
                  handleBlur={handleBlur}
                  error={
                    errors?.specifyVote?.weekPregnancy
                  }
                  touched={touched?.specifyVote?.weekPregnancy}
                  disabled={isDisabled}
                  row
                  labelWidth={180}
                />
              </Col>
              <Col xs={12} md={12} lg={6}>
                <InputCustom
                  name="specifyVote.dayOfPregnancy"
                  label="Ngày thai"
                  type="number"
                  placeholder="Nhập số"
                  value={_.get(
                    values,
                    "specifyVote.dayOfPregnancy",
                    ""
                  ).toString()}
                  isRequired={true}
                  handleChange={(e) => {
                    if (isNumeric(e.target.value)) {
                      setFieldValue(
                        "specifyVote.dayOfPregnancy",
                        e.target.value
                      );
                    }
                  }}
                  handleBlur={handleBlur}
                  error={
                    errors?.specifyVote?.dayOfPregnancy
                  }
                  touched={touched?.specifyVote?.dayOfPregnancy}
                  disabled={isDisabled}
                  row
                  labelWidth={160}
                />
              </Col>
            </Row>
            <Row>
              <Col xs={12} md={12} lg={6}>
                <InputCustom
                  // labelWrap={true}
                  name="specifyVote.height1"
                  label="Chiều dài đầu mông (mm)"
                  type="number"
                  placeholder="Nhập chiều cao "
                  value={values?.specifyVote?.height1}
                  isRequired={false}
                  handleChange={(e) => {
                    if (isNumeric(e.target.value)) {
                      setFieldValue("specifyVote.height1", e.target.value);
                    }
                  }}
                  handleBlur={handleBlur}
                  error={errors?.specifyVote?.height1}
                  touched={touched?.specifyVote?.height1}
                  disabled={isDisabled}
                  row
                  labelWidth={180}
                />
              </Col>
              {/* <Col xs={12} md={12} lg={6}>
                <InputCustom
                  name="specifyVote.weight1"
                  label="Cân nặng (g)"
                  type="number"
                  placeholder="Nhập cân nặng"
                  value={values?.specifyVote?.weight1}
                  isRequired={false}
                  handleChange={(e) => {
                    if (isNumeric(e.target.value)) {
                      setFieldValue("specifyVote.weight1", e.target.value);
                    }
                  }}
                  handleBlur={handleBlur}
                  error={errors?.specifyVote?.weight1}
                  touched={touched?.specifyVote?.weight1}
                  disabled={isDisabled}
                  row
                  labelWidth={160}
                />
              </Col> */}
              <Col xs={12} md={12} lg={6}>
                <DatePickerCustom
                  label="Ngày siêu âm"
                  lableTextAlign={"left"}
                  labelWidth={160}
                  row
                  dateFormat={"dd/MM/yyyy"}
                  selected={
                    values?.specifyVote?.ultrasoundDays
                      ? new Date(values?.specifyVote?.ultrasoundDays)
                      : null
                  }
                  disabled={isDisabled}
                  maxDate={new Date()}
                  handleChange={(e) => {
                    setFieldValue(
                      "specifyVote.ultrasoundDays",
                      new Date(e).toISOString()
                    );
                  }}
                  error={errors?.specifyVote?.ultrasoundDays}
                  touched={touched?.specifyVote?.ultrasoundDays}
                />
              </Col>
            </Row>
            <Row>
              <Col xs={12} md={12} lg={6}>
                <SelectCustom
                  name="specifyVote.typePregnancy"
                  label="Số lượng thai"
                  placeholder="Chọn"
                  value={typePregnancyValue}
                  isRequired={true}
                  isClearable={false}
                  handleChange={(e) => {
                    setTypePregnancy(e);
                    setFieldValue(
                      "specifyVote.typePregnancy",
                      e?.value ?? null
                    );
                  }}
                  handleBlur={handleBlur}
                  error={errors.typePregnancy}
                  touched={touched.typePregnancy}
                  options={CONSTANT.TYPE_PREGNANCY}
                  isDisabled={isDisabled}
                  row
                  labelWidth={180}
                />
              </Col>
              <Col xs={12} md={12} lg={6}>
                <InputCustom
                  name="specifyVote.nappySkin"
                  label="Độ mờ da gáy"
                  type="number"
                  placeholder="Nhập độ mờ da gáy"
                  value={values?.specifyVote?.nappySkin}
                  isRequired={false}
                  handleChange={(e) => {
                    // if (isNumeric(e.target.value)) {
                    setFieldValue("specifyVote.nappySkin", e.target.value);
                    // }
                  }}
                  handleBlur={handleBlur}
                  error={errors?.specifyVote?.nappySkin}
                  touched={touched?.specifyVote?.nappySkin}
                  disabled={isDisabled}
                  row
                  labelWidth={160}
                />
              </Col>
            </Row>
            <Row>
              <Col xs={12}>
                <InputCustom
                  name="specifyVote.result3"
                  label="Kết quả nguy cơ của combined test"
                  type="textarea"
                  placeholder="Nhập kết quả nguy cơ của combined test"
                  value={values?.specifyVote?.result3}
                  isRequired={false}
                  handleChange={handleChange}
                  handleBlur={handleBlur}
                  error={errors?.specifyVote?.result3}
                  touched={touched?.specifyVote?.result3}
                  disabled={isDisabled}
                />
              </Col>
            </Row>
            <Row>
              <Col xs={12}>
                <InputCustom
                  name="specifyVote.result4"
                  label="Kết quả siêu âm"
                  type="textarea"
                  placeholder="Nhập kết quả siêu âm"
                  value={values?.specifyVote?.result4}
                  isRequired={false}
                  handleChange={handleChange}
                  handleBlur={handleBlur}
                  error={errors?.specifyVote?.result4}
                  touched={touched?.specifyVote?.result4}
                  disabled={isDisabled}
                />
              </Col>
            </Row>
          </>
        );
      case 1:
        return (
          <>
            <Row>
              <Col xs={12} md={12} lg={6}>
                <InputCustom
                  label="Người sinh thiết"
                  row
                  labelWidth={160}
                  disabled={isDisabled}
                  placeholder="Nhập họ tên"
                  value={values.specifyVote.biopsyPerson}
                  handleChange={(e) => {
                    setFieldValue("specifyVote.biopsyPerson", e.target.value);
                  }}
                  error={errors?.specifyVote?.biopsyPerson}
                  touched={touched?.specifyVote?.biopsyPerson}
                />
              </Col>
              <Col xs={12} md={12} lg={6}>
                <div className="d-flex">
                  <LabelCustom
                    label={"Ngày sinh thiết"}
                    style={{
                      margin: "0.5em 8px 0px 0px",
                    }}
                    styleLabel={{
                      minWidth: 120,
                    }}
                    isRequired={false}
                  />
                  <div className="date-picker-custom row-date-picker-custom d-flex flex-column">
                    <DatePicker
                      wrapperClassName="icon-calendar"
                      customInput={<CustomInputCalendar />}
                      disabled={isDisabled}
                      dateFormat={"dd/MM/yyyy"}
                      selected={values?.specifyVote?.biopsyDay}
                      maxDate={new Date()}
                      onChange={(date) => {
                        setFieldValue("specifyVote.biopsyDay", date);
                      }}
                    />
                    {errors.biopsyDay && !!touched.biopsyDay && (
                      <HelperText message={errors.biopsyDay} />
                    )}
                  </div>
                </div>
              </Col>
            </Row>
            <Row>
              <Col xs={12} md={12} lg={10}>
                <InputCustom
                  name="specifyVote.cellSolution"
                  label="Dung dịch chứa tế bào"
                  type="text"
                  placeholder="Nhập vào dung dịch"
                  value={values?.specifyVote?.cellSolution}
                  isRequired={false}
                  handleChange={handleChange}
                  handleBlur={handleBlur}
                  error={errors?.specifyVote?.cellSolution}
                  touched={touched?.specifyVote?.cellSolution}
                  disabled={isDisabled}
                  row
                  labelWidth={160}
                />
              </Col>
            </Row>
            <Row>
              <Col xs={12} md={12} lg={6}>
                <InputCustom
                  name="specifyVote.numOfEmbryos1"
                  label="Số phôi tạo ra"
                  type="number"
                  placeholder="Nhập số lượng"
                  value={values?.specifyVote?.numOfEmbryos1}
                  isRequired={false}
                  handleChange={(e) => {
                    if (isNumeric(e.target.value)) {
                      setFieldValue(
                        "specifyVote.numOfEmbryos1",
                        e.target.value
                      );
                    }
                  }}
                  handleBlur={handleBlur}
                  error={errors?.specifyVote?.numOfEmbryos1}
                  touched={touched?.specifyVote?.numOfEmbryos1}
                  disabled={isDisabled}
                  row
                  labelWidth={160}
                />
              </Col>
              <Col xs={12} md={12} lg={6}>
                <SelectCustom
                  name="specifyVote.noPhoi"
                  label="Số thứ tự"
                  placeholder="Chọn"
                  value={noPhoiValue}
                  isRequired={false}
                  isClearable={false}
                  handleChange={(e) => {
                    setNoPhoiValue(e);
                    setFieldValue("specifyVote.noPhoi", e?.value ?? null);
                  }}
                  handleBlur={handleBlur}
                  error={errors.noPhoi}
                  touched={touched.noPhoi}
                  options={Array.apply(null, Array(5)).map((__, index) => {
                    return {
                      label: index + 1,
                      value: index,
                    };
                  })}
                  isDisabled={isDisabled}
                  row
                  labelWidth={120}
                />
              </Col>
            </Row>
            <Row>
              <Col xs={12} md={10}>
                <InputCustom
                  name="specifyVote.embryoState"
                  label="Trạng thái phôi"
                  type="text"
                  placeholder="Nhập vào trạng thái"
                  value={values?.specifyVote?.embryoState}
                  isRequired={false}
                  handleChange={handleChange}
                  handleBlur={handleBlur}
                  error={errors?.specifyVote?.embryoState}
                  touched={touched?.specifyVote?.embryoState}
                  disabled={isDisabled}
                  row
                  labelWidth={160}
                />
              </Col>
            </Row>
            <Row>
              <Col xs={12} md={6}>
                <InputCustom
                  name="specifyVote.embryoEvaluation"
                  label="Đánh giá hình thái phôi"
                  type="text"
                  placeholder="Nhập vào hình thái"
                  value={values?.specifyVote?.embryoEvaluation}
                  isRequired={false}
                  handleChange={handleChange}
                  handleBlur={handleBlur}
                  error={errors?.specifyVote?.embryoEvaluation}
                  touched={touched?.specifyVote?.embryoEvaluation}
                  disabled={isDisabled}
                  row
                  labelWidth={160}
                />
              </Col>
            </Row>
            <Row>
              <Col xs={12} className={"d-flex align-items-center"}>
                <LabelCustom
                  label="Nhân tế bào"
                  isRequired={false}
                  styleLabel={{ minWidth: 160 }}
                  style={{
                    margin: "0 8px 0px 0px",
                  }}
                />
                <div className={"d-flex align-items-center pl-5"}>
                  <InputCustom
                    name="cellNucleus"
                    id={"cellNucleus1"}
                    type="radio"
                    label="Không"
                    value={false}
                    row={true}
                    checked={!isCellNucleus}
                    onClick={(_) => {
                      if (isDisabled) return;
                      setIsCellNucleus(false);
                      setFieldValue("specifyVote.cellNucleus", false);
                    }}
                    disabled={isDisabled}
                  />
                  <InputCustom
                    name="cellNucleus"
                    id={"cellNucleus2"}
                    type="radio"
                    label="Có"
                    row={true}
                    value={true}
                    checked={isCellNucleus}
                    onClick={(_) => {
                      if (isDisabled) return;
                      setIsCellNucleus(true);
                      setFieldValue("specifyVote.cellNucleus", true);
                    }}
                    disabled={isDisabled}
                  />
                </div>
              </Col>
            </Row>
            <Row>
              <Col xs={12} md={10}>
                <InputCustom
                  name="specifyVote.negativeControl"
                  label="Đối chứng âm"
                  type="text"
                  placeholder="Nhập vào đối chứng"
                  value={values?.specifyVote?.negativeControl}
                  isRequired={false}
                  handleChange={handleChange}
                  handleBlur={handleBlur}
                  error={errors?.specifyVote?.negativeControl}
                  touched={touched?.specifyVote?.negativeControl}
                  disabled={isDisabled}
                  row
                  labelWidth={160}
                />
              </Col>
            </Row>
          </>
        );
      case 2:
        return (
          <>
            <Row>
              <Col xs={12} md={12} lg={10}>
                <InputCustom
                  name="specifyVote.symptom"
                  label="Triệu chứng"
                  type="textarea"
                  placeholder="Nhập triệu chứng"
                  value={values?.specifyVote?.symptom}
                  isRequired={false}
                  handleChange={handleChange}
                  handleBlur={handleBlur}
                  error={errors?.specifyVote?.symptom}
                  touched={touched?.specifyVote?.symptom}
                  disabled={isDisabled}
                  row
                  labelWidth={160}
                />
              </Col>
            </Row>
            <Row>
              <Col xs={12} md={12} lg={10}>
                <InputCustom
                  name="specifyVote.diagnose"
                  label="Chẩn đoán"
                  type="textarea"
                  placeholder="Nhập chẩn đoán"
                  value={values?.specifyVote?.diagnose}
                  isRequired={false}
                  handleChange={handleChange}
                  handleBlur={handleBlur}
                  error={errors?.specifyVote?.diagnose}
                  touched={touched?.specifyVote?.diagnose}
                  disabled={isDisabled}
                  row
                  labelWidth={160}
                />
              </Col>
            </Row>
            <Row>
              <Col xs={12} md={12} lg={10}>
                <InputCustom
                  name="specifyVote.imageAnalysation"
                  label="Chẩn đoán hình ảnh"
                  type="textarea"
                  placeholder="Nhập chẩn đoán"
                  value={values?.specifyVote?.imageAnalysation}
                  isRequired={false}
                  handleChange={handleChange}
                  handleBlur={handleBlur}
                  error={errors?.specifyVote?.imageAnalysation}
                  touched={touched?.specifyVote?.imageAnalysation}
                  disabled={isDisabled}
                  row
                  labelWidth={160}
                />
              </Col>
            </Row>
            <Row>
              <Col xs={12} md={12} lg={10}>
                <InputCustom
                  name="specifyVote.test"
                  label="Xét nghiệm liên quan"
                  type="textarea"
                  placeholder="Nhập xét nghiệm"
                  value={values?.specifyVote?.test}
                  isRequired={false}
                  handleChange={handleChange}
                  handleBlur={handleBlur}
                  error={errors?.specifyVote?.test}
                  touched={touched?.specifyVote?.test}
                  disabled={isDisabled}
                  row
                  labelWidth={160}
                />
              </Col>
            </Row>
            <Row>
              <Col xs={12} md={12} lg={10}>
                <InputCustom
                  name="specifyVote.treatment"
                  label="Cách thức điều trị"
                  type="textarea"
                  placeholder="Nhập cách thức"
                  value={values?.specifyVote?.treatment}
                  isRequired={false}
                  handleChange={handleChange}
                  handleBlur={handleBlur}
                  error={errors?.specifyVote?.treatment}
                  touched={touched?.specifyVote?.treatment}
                  disabled={isDisabled}
                  row
                  labelWidth={160}
                />
              </Col>
            </Row>
            <Row>
              <Col xs={12} md={12} lg={10}>
                <InputCustom
                  name="specifyVote.timeTreatment"
                  label="T.Gian điều trị (ngày)"
                  type="number"
                  placeholder="Nhập số ngày"
                  value={values?.specifyVote?.timeTreatment}
                  isRequired={false}
                  handleChange={handleChange}
                  handleBlur={handleBlur}
                  error={errors?.specifyVote?.timeTreatment}
                  touched={touched?.specifyVote?.timeTreatment}
                  disabled={isDisabled}
                  row
                  labelWidth={160}
                />
              </Col>
            </Row>
            <Row>
              <Col xs={12} md={12} lg={10}>
                <InputCustom
                  name="specifyVote.drugResistance"
                  label="Dấu hiệu kháng thuốc"
                  type="textarea"
                  placeholder="Nhập dấu hiệu"
                  value={values?.specifyVote?.drugResistance}
                  isRequired={false}
                  handleChange={handleChange}
                  handleBlur={handleBlur}
                  error={errors?.specifyVote?.drugResistance}
                  touched={touched?.specifyVote?.drugResistance}
                  disabled={isDisabled}
                  row
                  labelWidth={160}
                />
              </Col>
            </Row>
            <Row>
              <Col xs={12} md={12} lg={8}>
                <InputCustom
                  name="specifyVote.diseaseRecurrence"
                  label="Tái phát bệnh"
                  type="text"
                  placeholder="Nhập"
                  value={values?.specifyVote?.diseaseRecurrence}
                  isRequired={false}
                  handleChange={handleChange}
                  handleBlur={handleBlur}
                  error={errors?.specifyVote?.diseaseRecurrence}
                  touched={touched?.specifyVote?.diseaseRecurrence}
                  disabled={isDisabled}
                  row
                  labelWidth={160}
                />
              </Col>
            </Row>
          </>
        );
    }
  };

  return (
    <>
      <Row>
        <CardHeader className="pb-2 d-flex justify-content-between rounded border-bottom w-100">
          <h3>Thông tin nhóm xét nghiệm</h3>
        </CardHeader>
      </Row>
      <Row className="">
        <Col xs={12} className={"d-flex align-items-center"}>
          <LabelCustom
            label="Xét nghiệm"
            isRequired={false}
            styleLabel={{ minWidth: 160 }}
            style={{
              textAlign: "end",
              margin: "0 8px 0px 0px",
            }}
          />
          <div className={"d-flex align-items-center pl-3"}>
            <InputCustom
              name="testRadio"
              id={"testRadio1"}
              type="radio"
              label="Nhóm sản"
              value={0}
              row={true}
              checked={values?.specifyVote?.testGR === 0}
              onClick={(index) => {
                // if (
                //   parseInt(index) === values?.specifyVote?.testGR ||
                //   isDisabled ||
                //   id !== '-1'
                // )
                //   return;
                // setFieldValue('specifyVote.testGR', 0);
              }}
              disabled={isDisabled}
            />
            <InputCustom
              name="testRadio"
              id={"testRadio2"}
              type="radio"
              label="Nhóm phôi"
              row={true}
              value={1}
              checked={values?.specifyVote?.testGR === 1}
              onClick={(index) => {
                // if (
                //   parseInt(index) === values?.specifyVote?.testGR ||
                //   isDisabled ||
                //   id !== '-1'
                // )
                //   return;
                // setFieldValue('specifyVote.testGR', 1);
              }}
              disabled={isDisabled}
            />
            <InputCustom
              name="testRadio"
              id={"testRadio3"}
              type="radio"
              label="Nhóm bệnh lý"
              row={true}
              value={2}
              checked={values?.specifyVote?.testGR === 2}
              onClick={(index) => {
                // if (
                //   parseInt(index) === values?.specifyVote?.testGR ||
                //   isDisabled ||
                //   id !== '-1'
                // )
                //   return;
                // setFieldValue('specifyVote.testGR', 2);
              }}
              disabled={isDisabled}
            />
          </div>
        </Col>
      </Row>
      {renderTestGroup()}
    </>
  );
};

export default memo(NXNInfo);
