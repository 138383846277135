import { returnValue } from "common";
import typeActions from "redux/typeActions";
const initialState = {
  provinces: null,
  pagination: {},
  // form show/delete
  isModalVisible: false,
  modalInfo: {},
  page: 1,
  limit: 10,
  query: {
    populate: "*",
  },
};
const provinceReducer = (state = initialState, actions) => {
  switch (actions.type) {
    case typeActions.SET_PROVINCE_VALUE:
      const nameProvince = actions.name;
      const valueProvince = actions.value;
      if (typeof nameProvince !== "string") return { ...state };
      const objProvince = returnValue(state, nameProvince, valueProvince);
      return {
        ...state,
        ...objProvince,
      };
    case typeActions.GET_PROVINCE_REQUEST:
      return {
        ...state,
        ...actions?.data,
      };
    case typeActions.GET_PROVINCE_SUCCESS:
      return {
        ...state,
        provinces: actions.data.results,
        pagination: actions.data.pagination,
        page: actions.data.pagination.page,
        limit: actions.data.pagination.pageSize
      };
    case typeActions.GET_PROVINCE_FAILED:
      return {
        ...state,
      };
    // create
    
    case typeActions.CREATE_PROVINCE_REQUEST:
      return {
        ...state,
        errors: {
          ...state.errors,
          createAccount: '',
        },
      };
    case typeActions.CREATE_PROVINCE_SUCCESS:
      return {
        ...state,
        errors: {
          ...state.errors,
        },
      };
    case typeActions.CREATE_PROVINCE_FAILED:
      return {
        ...state,
        errors: {
          ...state.errors,
          createAccount: actions.error,
        },
      };

    //delete
    case typeActions.DELETE_PROVINCE_REQUEST:
      return {
        ...state,
      };
    case typeActions.DELETE_PROVINCE_SUCCESS:
      return {
        ...state,
      };
    case typeActions.DELETE_PROVINCE_FAILED:
      return {
        ...state,
      };
    default:
      return {
        ...state,
      };
  }
};

export default provinceReducer;
