import typeActions from 'redux/typeActions';

const setValue = ({ name, value }) => {
  return {
    type: typeActions.SET_PROMOTION_DETAIL_VALUE,
    name,
    value,
  };
};

const getRelation = (id, relationTo, callback) => {
  return {
    type: typeActions.GET_PROMOTION_DETAIL_RELATION_REQUEST,
    id,
    relationTo,
    callback,
  };
};

const getPromotionDetails = (query, callback, isUpdateReducer = true) => {
  return {
    type: typeActions.GET_PROMOTION_DETAILS_REQUEST,
    query,
    callback,
    isUpdateReducer,
  };
};

const getPromotionDetail = (param, query, callback) => {
  return {
    type: typeActions.GET_PROMOTION_DETAIL_REQUEST,
    param,
    query,
    callback,
  };
};

const createPromotionDetail = (body, callback) => {
  return {
    type: typeActions.CREATE_PROMOTION_DETAIL_REQUEST,
    body,
    callback,
  };
};
const updatePromotionDetail = (param, body, callback) => {
  return {
    type: typeActions.UPDATE_PROMOTION_DETAIL_REQUEST,
    param,
    body,
    callback,
  };
};
const deletePromotionDetail = (param, callback) => {
  return {
    type: typeActions.DELETE_PROMOTION_DETAIL_REQUEST,
    param,
    callback,
  };
};

export default {
  setValue,
  getRelation,
  getPromotionDetails,
  getPromotionDetail,
  createPromotionDetail,
  updatePromotionDetail,
  deletePromotionDetail,
};
