import React, { useEffect, useState } from 'react';
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory from 'react-bootstrap-table2-paginator';
import filterFactory from 'react-bootstrap-table2-filter';
import ToolkitProvider from 'react-bootstrap-table2-toolkit';
import _ from 'lodash';
import {
  Card,
  CardHeader,
  Container,
  Row,
  UncontrolledTooltip,
  Col,
  Input,
} from 'reactstrap';

import {
  checkRole,
  notificationAlertRef,
  notify,
  paginationTitle,
} from 'common';
// custom components
import SimpleHeader from 'components/Headers/SimpleHeader.js';
import ModalWarningCustom from 'views/pages/components/ModalWarningCustom';
import Loading from 'views/pages/components/Loading';
import CRUDPartner from './components/CRUDPartner';
// css
import 'assets/css/custom-pages/react-bs-table.css';
// actions,hook - redux
import { useDispatch, useSelector } from 'react-redux';
import { partnerActions, formActions, filterActions } from 'redux/actions';
// custom hook
import { useDelayInputEffect, useDidUpdateEffect } from 'utils/HookUtils';
// icon
import IconEdit from 'assets/svgs/edit.svg';
import IconBin from 'assets/svgs/bin.svg';
import IconEye from 'assets/svgs/eye.svg';
//other
import queryString from 'query-string';
function Partner() {
  const [notificationModal, setNotificationModal] = useState(false);
  const [partner, setPartner] = useState(null);
  const [searchTerm, setSearchTerm] = useState('');
  const [searchOption, setSearchOption] = useState('');
  const [isUpdateReducer, setIsUpdateReducer] = useState(true);
  const [dataOptions, setDataOptions] = useState([]);
  const { page, pagination, search, limit, query, partners, isDeletePartner } =
    useSelector((state) => state.partnerReducer);
  const { currentAccountExtend } = useSelector((state) => state.accountReducer);
  const dispatch = useDispatch();
  const nameTypeSearch = 'Tìm kiếm';

  const columns = [
    {
      dataField: 'code',
      text: 'MÃ ĐỐI TÁC',
    },
    {
      dataField: 'name',
      text: 'TÊN ĐỐI TÁC XÉT NGHIỆM',
    },
    {
      dataField: 'phone',
      text: 'SỐ ĐIỆN THOẠI',
    },
    {
      dataField: 'email',
      text: 'EMAIL',
    },
    {
      dataField: 'address',
      text: 'ĐỊA CHỈ ĐỐI TÁC',
    },
    {
      dataField: 'nameRepresentative',
      text: 'NGƯỜI ĐẠI DIỆN',
    },
    {
      dataField: 'nameContact',
      text: 'NGƯỜI LIÊN HỆ',
    },
    {
      dataField: 'actions',
      text: 'HÀNH ĐỘNG',
      formatter: boxActions,
      headerStyle: {
        textAlign: 'center',
      },
      headerClasses: 'fixed-column',
      classes: 'fixed-column',
    },
  ];
  function boxActions(__, row) {
    return (
      <div className="d-flex justify-content-center">
        <button
          id={'view' + row.id}
          onClick={() => {
            dispatch(
              formActions.setValue({
                name: 'modalInfo',
                value: { ...row, isView: true },
              })
            );
            dispatch(
              formActions.setValue({
                name: 'isModalVisible',
                value: true,
              })
            );
          }}
          className="btn p-0 btn-no-shadow mr-0"
        >
          <img src={IconEye} className="mr-1" />
        </button>
        <UncontrolledTooltip delay={1} placement="top" target={'view' + row.id}>
          Xem chi tiết
        </UncontrolledTooltip>

        {checkRole(currentAccountExtend, {
          roles: ['update-partner'],
        }) && (
            <>
              <button
                id={'edit' + row.id}
                onClick={() => {
                  dispatch(
                    formActions.setValue({
                      name: 'modalInfo',
                      value: row,
                    })
                  );
                  dispatch(
                    formActions.setValue({
                      name: 'isModalVisible',
                      value: true,
                    })
                  );
                }}
                className="btn p-0 btn-no-shadow mr-0"
              >
                <img src={IconEdit} className="mr-1" />
              </button>
              <UncontrolledTooltip
                delay={1}
                placement="top"
                target={'edit' + row.id}
              >
                Cập nhật đối tác
              </UncontrolledTooltip>
            </>
          )}
        {checkRole(currentAccountExtend, {
          roles: ['delete-partner'],
        }) && (
            <>
              <button
                id={'lock' + row.id}
                onClick={() => {
                  setPartner(row);
                  setNotificationModal(true);
                }}
                className="btn p-0 btn-no-shadow mr-0"
              >
                <img src={IconBin} />
              </button>
              <UncontrolledTooltip
                delay={1}
                placement="top"
                target={'lock' + row.id}
              >
                Xóa đối tác
              </UncontrolledTooltip>
            </>
          )}
      </div>
    );
  }

  const onSizePerPageChange = (e) => {
    setIsUpdateReducer(true);
    dispatch(partnerActions.setValuePartner({ name: 'page', value: 1 }));
    dispatch(
      partnerActions.setValuePartner({
        name: 'limit',
        value: e.target.value,
      })
    );
  };

  const onPageChange = (value) => {
    setIsUpdateReducer(true);
    dispatch(partnerActions.setValuePartner({ name: 'page', value: value }));
  };
  const paginations = paginationFactory({
    page: page,
    sizePerPage: limit,
    totalSize: pagination?.total,
    showTotal: false,
    withFirstAndLast: false,
    alwaysShowAllBtns: true,
    onPageChange: onPageChange,
    sizePerPageRenderer: () => {
      return (
        <div className="dataTables_length" id="datatable-basic_length">
          <label>
            Hiển thị{' '}
            {
              <select
                name="datatable-basic_length"
                aria-controls="datatable-basic"
                className="form-control form-control-sm"
                onChange={onSizePerPageChange}
              >
                <option value="10">10</option>
                <option value="25">25</option>
                <option value="50">50</option>
                <option value="100">100</option>
              </select>
            }{' '}
            dòng.
          </label>
        </div>
      );
    },
  });

  const handleDelete = () => {
    if (partner === null) return;
    if ((partner?.orders?.count ?? 0) > 0) {
      return notify(
        notificationAlertRef,
        'warning',
        'Thông báo',
        `Đối tác đang được hợp tác. Không được xóa!`
      );
    }
    dispatch(
      partnerActions.deletePartner(partner.id, {
        success: () => {
          setNotificationModal(false);
          notify(
            notificationAlertRef,
            'success',
            'Thông báo',
            `Xóa đối tác ${partner?.name} thành công!`
          );
          callListPartner();
        },
        failed: () => {
          notify(
            notificationAlertRef,
            'danger',
            'Thông báo',
            `Xóa đối tác ${partner.name} thất bại!`
          );
        },
      })
    );
  };
  /**
   *  - Get the list of partners on the first render
   *  - Call api when pagination or search values change
   */
  function callListPartner() {
    const q = !!search
      ? {
        ...query,
        page: page,
        pageSize: limit,
        _q: search,
      }
      : {
        ...query,
        page: page,
        pageSize: limit,
      }
    dispatch(filterActions.setValueFilter({
      name: 'queryPage',
      value: q
    }))
    dispatch(
      partnerActions.getPartners(
        queryString.stringify(
          q
        ),
        {
          success: (data) => {
            if (!isUpdateReducer) {
            }
          },
          failed: () => { },
        },
        isUpdateReducer
      )
    );
  }
  useEffect(() => {
    // if (_.isEmpty(partners)) {
    callListPartner();
    // }
  }, []);

  useDidUpdateEffect(() => {
    const q = !!search
      ? {
        ...query,
        page: isUpdateReducer ? page : 1,
        pageSize: isUpdateReducer ? limit : 10,
        _q: search,
      }
      : {
        ...query,
        page: isUpdateReducer ? page : 1,
        pageSize: isUpdateReducer ? limit : 10,
      }
    dispatch(filterActions.setValueFilter({
      name: 'queryPage',
      value: q
    }))
    dispatch(
      partnerActions.getPartners(
        queryString.stringify(
          q
        ),
        {
          success: (dataPartner) => {
            if (!isUpdateReducer) {
              setDataOptions(dataPartner.data);
            }
          },
          failed: () => { },
        },
        isUpdateReducer
      )
    );
  }, [page, limit, search]);
  /**
   * Handle the search when the user finishes typing after 500 milliseconds
   */
  useDelayInputEffect(
    partnerActions.setValuePartner({
      name: 'search',
      value: searchTerm,
    }),
    searchTerm,
    700
  );
  useDelayInputEffect(
    partnerActions.setValuePartner({
      name: 'search',
      value: searchOption,
    }),
    searchOption,
    700
  );
  /**
   * Initial option in select box
   * @returns
   */
  function handleGetValuesOption() {
    let arr = Array.isArray(partners) ? partners.slice(0, 10) : [];
    if (Array.isArray(dataOptions) && dataOptions.length != 0)
      arr = dataOptions;
    const options = arr.map((item) => {
      return {
        label: item.name,
        value: item.id + '',
      };
    });
    return options;
  }
  const optionNameParents = handleGetValuesOption();

  return (
    <>
      <SimpleHeader />
      <CRUDPartner
        optionNameParent={optionNameParents}
        callListPartner={callListPartner}
      />
      <ModalWarningCustom
        notificationModal={notificationModal}
        setNotificationModal={setNotificationModal}
        name={`Đối tác xét nghiệm ${partner?.name ?? ''}`}
        func={handleDelete}
        isDelete={isDeletePartner}
      />
      <Container className="mt--9" fluid>
        <Row>
          <Col xs={12}>
            <Card>
              <CardHeader className="pb-2">
                <h3>Danh sách</h3>
              </CardHeader>
              <ToolkitProvider
                data={_.isEmpty(partners) ? [] : partners}
                keyField="id"
                columns={columns}
                search={{
                  searchFormatted: true,
                }}
              >
                {(props) => (
                  <div className="py-4 table-responsive">
                    <div className="d-flex ml-4 justify-content-between mb-4">
                      <div className="d-flex align-items-center">
                        {paginationTitle(page, limit, pagination)}
                      </div>
                      <div
                        id="datatable-basic_filter"
                        className="dataTables_filter px-4 pb-1 d-flex align-items-center"
                      >
                        <h4 className="m-0 p-0">{nameTypeSearch}</h4>
                        <Input
                          type="text"
                          placeholder="Nhập từ cần tìm"
                          onChange={(e) => {
                            if (searchTerm !== e) {
                              setIsUpdateReducer(true);
                              setSearchTerm(e.target.value);
                            }
                          }}
                          style={{
                            height: '34px',
                            paddingTop: '8px',
                            paddingBottom: '8px',
                          }}
                        />
                      </div>
                    </div>

                    <BootstrapTable
                      {...props.baseProps}
                      noDataIndication={() => {
                        return (
                          <span className="font-weight-bold text-danger">
                            Không có dữ liệu!
                          </span>
                        );
                      }}
                      onTableChange={() => {
                        return <Loading />;
                      }}
                      remote
                      hover
                      bootstrap4={true}
                      pagination={paginations}
                      bordered={false}
                      filter={filterFactory()}
                      rowStyle={{
                        cursor: 'pointer',
                      }}
                      keyField="id"
                    />
                  </div>
                )}
              </ToolkitProvider>
            </Card>
          </Col>
        </Row>
      </Container>
    </>
  );
}

export default Partner;
