import React, { memo } from 'react';
import { Input, Label } from 'reactstrap';
import { PropTypes } from 'prop-types';
import HelperText from './HelperText';
import LabelCustom from './LabelCustom';
const InputCustom = React.forwardRef(
  (
    {
      name = '',
      label = '',
      row = false,
      type = 'text',
      placeholder = '',
      value = '',
      id = '',
      maxWidth = '',
      isRequired = false,
      styleLabel = {},
      disabled = false,
      alignRow = false,
      defaultChecked = false,
      labelWidth = 120,
      lableTextAlign = 'start',
      labelWrap = false,
      handleChange = () => {},
      handleBlur = () => {},
      onClick = () => {},
      styleInput = {},
      error,
      checked,
      touched,
      ...rest
    },
    ref
  ) => {
    const { onKeyDown } = rest;
    if (row && type === 'radio') {
      return (
        <div
          style={{
            cursor: 'pointer',
          }}
          className={'mr-4'}
        >
          <Label check style={{ cursor: 'pointer', marginRight: 12 }}>
            <Input
              type={'radio'}
              id={id}
              name={name}
              style={{ marginTop: 6, ...styleInput }}
              checked={checked}
              value={value}
              onClick={(e) => {
                onClick(e.target.value);
              }}
              defaultChecked={defaultChecked}
            />
            {label}
          </Label>
        </div>
      );
    }
    return (
      <div
        style={
          row
            ? {
                display: 'flex',
                alignItems: alignRow ? 'center' : undefined,
              }
            : {}
        }
      >
        <LabelCustom
          isRequired={isRequired}
          label={label}
          id={name}
          style={{
            whiteSpace: labelWrap ? 'normal' : 'nowrap',
            margin: row ? '0.5em 8px 0px 0px' : undefined,
            textAlign: lableTextAlign,
          }}
          styleLabel={{
            minWidth: row ? labelWidth : undefined,
            maxWidth: row ? labelWidth : undefined,
            ...styleLabel,
          }}
        />
        <div
          className="w-100"
          style={{
            paddingLeft: row ? 16 : undefined,
          }}
        >
          <Input
            className={type === 'textarea' ? '' : 'height-input'}
            style={{
              color: '#000',
              ...styleInput,
            }}
            id={name}
            placeholder={placeholder}
            disabled={disabled}
            type={type}
            name={name}
            value={value === 0 ? '' : value}
            onChange={handleChange}
            onBlur={handleBlur}
            invalid={!!error && !!touched}
            innerRef={ref}
            onKeyDown={onKeyDown}
            // {...rest}
          />
          {!!error && !!touched && <HelperText message={error} />}
        </div>
      </div>
    );
  }
);
InputCustom.propTypes = {
  row: PropTypes.bool,
};
export default memo(InputCustom);
