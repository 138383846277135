import React, { useEffect, useMemo, useState } from 'react';
import _ from 'lodash';
import {
  Button,
  Card,
  CardBody,
  CardHeader,
  CardImg,
  Col,
  Container,
  Row,
} from 'reactstrap';
import { TabContent, TabPane, Nav, NavItem, NavLink } from 'reactstrap';

import { notificationAlertRef, notify } from 'common';
import { accountActions, formActions } from 'redux/actions';
import SimpleHeader from 'components/Headers/SimpleHeader.js';
import 'assets/css/custom-pages/react-bs-table.css';
import { useDispatch, useSelector } from 'react-redux';
import { Field, Form, Formik } from 'formik';
import classNames from 'classnames';
import CONSTANT from 'constant';
import IconChangePass from 'assets/img/todo/changePass.svg';
import IconInfo from 'assets/img/todo/info.svg';
import UAccountInfo from './components/UAccountInfo';
import ImgBack from '../../../assets/img/theme/bg-sub.png';
import { BASE_URL_IMAGE } from 'services/ServiceURL';
import ChangePassword from './components/ChangePassword';
import LabelValue from '../components/LabelValue';
import moment from 'moment';

function AccountInfo() {
  const dispatch = useDispatch();
  const { currentAccountExtend } = useSelector((state) => state.accountReducer);
  const avatar = _.get(currentAccountExtend, 'avatar.url', '');
  const [activeTab, setActiveTab] = useState('1');
  const { gender, channel, isMarried } = currentAccountExtend;

  const genderV = CONSTANT.ACCOUNT_GENDER.find((item) => item.value === gender);

  const channelV = CONSTANT.CHANNEL.find((item) => item.value === channel);

  const isMarriedV = CONSTANT.MARRIED.find((item) => item.value === isMarried);

  function handleOpenFormUpdate() {
    dispatch(
      formActions.setValue({
        name: 'isModalVisible',
        value: true,
      })
    );
  }

  return (
    <>
      <SimpleHeader />
      <UAccountInfo />
      <Container className="mt--9" fluid>
        <Row>
          <div className="col">
            <Card className="mb-0" style={{ minHeight: '100%' }}>
              <CardHeader className="border-0">
                <Nav tabs>
                  <NavItem>
                    <NavLink
                      className={classNames({
                        active: activeTab === '1',
                      })}
                      onClick={() => {
                        setActiveTab('1');
                      }}
                      style={{
                        border: 'none',
                        paddingLeft: '0px',
                        paddingRight: '0px',
                        borderBottom:
                          activeTab === '1' ? '2px solid #FB6340' : 'none',
                        backgroundColor: 'transparent',
                      }}
                    >
                      <button
                        className="d-flex align-items-center pb-2 border-0 px-0 "
                        style={{ backgroundColor: 'transparent' }}
                      >
                        <img src={IconInfo} />
                        <h3 className="mb-0 ml-2">Thông tin chung</h3>
                      </button>
                    </NavLink>
                  </NavItem>
                  <NavItem>
                    <NavLink
                      className={classNames({
                        active: activeTab === '2',
                      })}
                      onClick={() => {
                        setActiveTab('2');
                      }}
                      style={{
                        marginLeft: '32px',
                        border: 'none',
                        paddingLeft: '0px',
                        paddingRight: '0px',
                        borderBottom:
                          activeTab === '2' ? '2px solid #FB6340' : 'none',
                        backgroundColor: 'transparent',
                      }}
                    >
                      <button
                        className="d-flex align-items-center pb-2  border-0 px-0"
                        style={{ backgroundColor: 'transparent' }}
                      >
                        <img src={IconChangePass} />
                        <h3 className="mb-0 ml-2">Đổi mật khẩu</h3>
                      </button>
                    </NavLink>
                  </NavItem>
                </Nav>
              </CardHeader>
              <CardBody>
                <Row>
                  <Col md={5} className="">
                    <Card className="card-profile">
                      <CardImg alt="..." src={ImgBack} height={'240px'} top />
                      <Row className="justify-content-center ">
                        <Col className="order-lg-2" lg="3">
                          <div className="card-profile-image ">
                            <a
                              className="rounded-circle"
                              href="#pablo"
                              onClick={(e) => e.preventDefault()}
                            >
                              <img
                                alt="..."
                                className="rounded-circle  shadow-lg "
                                style={{
                                  borderWidth: '25px',
                                  minWidth: '200px',
                                  height: '200px',
                                }}
                                src={
                                  avatar !== ''
                                    ? `${BASE_URL_IMAGE}${avatar}`
                                    : require('assets/img/icons/cards/avatar.jpg')
                                        .default
                                }
                              />
                            </a>
                          </div>
                        </Col>
                      </Row>
                      <CardHeader className="text-center border-0 pt-8 pt-md-4 pb-0 pb-md-4"></CardHeader>
                      <CardBody className="pt-0">
                        <Row>
                          <Col className="mt-6">
                            <div className="text-center">
                              <h5 className="h3">{`${
                                currentAccountExtend?.fullname ?? ''
                              }`}</h5>
                              <div className="h5 font-weight-300">
                                <i className="ni location_pin mr-2" />
                                {currentAccountExtend?.email ?? ''}
                              </div>
                            </div>
                          </Col>
                        </Row>
                      </CardBody>
                    </Card>
                  </Col>
                  <Col className="">
                    <Card className="card-profile">
                      <TabContent activeTab={activeTab}>
                        <TabPane tabId="1">
                          <CardHeader>
                            <div className="d-flex justify-content-between align-items-center">
                              <h2>Thông tin cá nhân</h2>
                              <Button
                                style={{
                                  backgroundColor: '#4690CD',
                                  color: 'white',
                                }}
                                onClick={() => {
                                  handleOpenFormUpdate();
                                }}
                              >
                                Cập nhật thông tin cá nhân
                              </Button>
                            </div>
                          </CardHeader>
                          <CardBody className="px-5">
                            <Row className="">
                              <Col xs={12} md={6}>
                                <LabelValue
                                  labelWidth={160}
                                  label="Họ và tên đệm"
                                  value={`${
                                    currentAccountExtend?.adminUser?.lastname ??
                                    ''
                                  }`}
                                />
                              </Col>
                              <Col xs={12} md={6}>
                                <LabelValue
                                  labelWidth={160}
                                  label="Chưa kết hôn"
                                  value={isMarriedV?.label}
                                />
                              </Col>
                            </Row>
                            <Row className="">
                              <Col xs={12} md={6}>
                                <LabelValue
                                  labelWidth={160}
                                  label="Tên người dùng"
                                  value={`${
                                    currentAccountExtend?.adminUser
                                      ?.firstname ?? ''
                                  }`}
                                />
                              </Col>
                              <Col xs={12} md={6}>
                                <LabelValue
                                  labelWidth={160}
                                  label="Số CCCD"
                                  value={currentAccountExtend?.citizenId ?? ''}
                                />
                              </Col>
                            </Row>
                            <Row className="">
                              <Col xs={12} md={6}>
                                <LabelValue
                                  labelWidth={160}
                                  label="Giới tính"
                                  value={genderV?.label ?? ''}
                                />
                              </Col>
                              <Col xs={12} md={6}>
                                <LabelValue
                                  labelWidth={160}
                                  label="Ngày cấp"
                                  value={
                                    !!currentAccountExtend?.licenseDate
                                      ? moment(
                                          currentAccountExtend.licenseDate
                                        ).format('DD/MM/YYYY')
                                      : ''
                                  }
                                />
                              </Col>
                            </Row>
                            <Row className="">
                              <Col xs={12} md={6}>
                                <LabelValue
                                  labelWidth={160}
                                  label="Số điện thoại"
                                  value={currentAccountExtend?.phone ?? ''}
                                />
                              </Col>
                              <Col xs={12} md={6}>
                                <LabelValue
                                  labelWidth={160}
                                  label="Nơi cấp"
                                  value={
                                    currentAccountExtend?.licenseWhere ?? ''
                                  }
                                />
                              </Col>
                            </Row>
                            <Row className="">
                              <Col xs={12} md={12}>
                                <LabelValue
                                  labelWidth={160}
                                  label="Địa chỉ"
                                  value={currentAccountExtend?.address ?? ''}
                                />
                              </Col>
                            </Row>
                            <Row className="">
                              <Col xs={12} md={6}>
                                <LabelValue
                                  labelWidth={160}
                                  label="Nơi làm việc"
                                  value={currentAccountExtend?.workWhere ?? ''}
                                />
                              </Col>
                              <Col xs={12} md={6}>
                                <LabelValue
                                  labelWidth={160}
                                  label="Chuyên khoa"
                                  value={currentAccountExtend?.specialist ?? ''}
                                />
                              </Col>
                            </Row>
                            <Row className="">
                              <Col xs={12} md={6}>
                                <LabelValue
                                  labelWidth={160}
                                  label="Kênh"
                                  value={channelV?.label ?? ''}
                                />
                              </Col>
                              <Col xs={12} md={6}>
                                <LabelValue
                                  labelWidth={160}
                                  label="Bằng cấp"
                                  value={currentAccountExtend?.degree ?? ''}
                                />
                              </Col>
                            </Row>
                            <Row className="">
                              <Col xs={12} md={12}>
                                <LabelValue
                                  labelWidth={160}
                                  label="Địa chỉ làm việc"
                                  value={
                                    currentAccountExtend?.workAddress ?? ''
                                  }
                                />
                              </Col>
                            </Row>
                          </CardBody>
                        </TabPane>
                      </TabContent>
                      <TabContent activeTab={activeTab}>
                        <TabPane tabId="2">
                          <ChangePassword />
                        </TabPane>
                      </TabContent>
                    </Card>
                  </Col>
                </Row>
              </CardBody>
            </Card>
          </div>
        </Row>
      </Container>
    </>
  );
}

export default AccountInfo;
