import React, { memo, useEffect, useState } from 'react';
import paginationFactory from 'react-bootstrap-table2-paginator';
import _ from 'lodash';
import {
  Badge,
  Button,
  Card,
  CardBody,
  CardHeader,
  Col,
  Container,
  Row,
} from 'reactstrap';
import 'assets/css/custom-pages/react-bs-table.css';
import { useDispatch, useSelector } from 'react-redux';
import { Formik } from 'formik';
import queryString from 'query-string';
import { useHistory } from 'react-router';
import * as yup from 'yup';

import BNInfo from './BNInfo';
import XNInfo from './XNInfo';
import LSInfo from './LSInfo';
import KQInfo from './KQInfo';
import NXNInfo from './NXNInfo';
import LoadingButtonCustom from 'views/pages/components/LoadingButtonCustom';
import {
  accountActions,
  organizationActions,
  specifyVotesActions,
} from 'redux/actions';
import SelectCustom from 'views/pages/components/SelectCustom';
import { notificationAlertRef, notify, relationOneToOne } from 'common';
import CONSTANT from 'constant';
import { BASE_URL_IMAGE } from 'services/ServiceURL';
import LabelCustom from 'views/pages/components/LabelCustom';
import LabelValue from 'views/pages/components/LabelValue';

function PCD({}) {
  const dispatch = useDispatch();
  const history = useHistory();

  const { order, isUpdatingOrder } = useSelector((state) => state.orderReducer);

  let image = `${BASE_URL_IMAGE}${order?.image?.url}`;
  if (!order?.image?.url)
    image = require('assets/img/default/pcd_default.png').default;
  return (
    <>
      <Row className="position-relative">
        <div className="col">
          <Row>
            <Col xs={12} md={5}>
              <Card>
                <CardHeader className="pb-2 d-flex justify-content-between rounded border-bottom">
                  <h3>Hình ảnh phiếu xét nghiệm</h3>
                </CardHeader>
                <CardBody>
                  <img
                    alt="..."
                    className="shadow"
                    src={image}
                    style={{ width: '100%', height: 'auto', borderRadius: 4 }}
                  />
                </CardBody>
              </Card>
            </Col>
            {/* Thông tin phiếu xét nghiệm */}
            <Col xs={12} md={7}>
              <Card>
                <CardHeader className="pb-2 d-flex justify-content-between rounded border-bottom">
                  <h3>Thông tin phiếu xét nghiệm</h3>
                </CardHeader>
                <CardBody>
                  <Row>
                    <Col xs={12} md={12}>
                      <LabelValue
                        labelWidth={160}
                        label="Mã phiếu xét nghiệm"
                        value={order?.specifyVote?.codePCD ?? ''}
                      />
                    </Col>
                  </Row>
                  <BNInfo />
                  <XNInfo />
                  <LSInfo />
                  <KQInfo />
                  <NXNInfo />
                  <div
                    style={{ bottom: 10, left: 0 }}
                    className="position-fixed bg-white w-100 shadow-lg rounded"
                  >
                    <div className="m-2 d-flex justify-content-end">
                      <Button
                        className="text-nowrap mr-1"
                        onClick={() => {
                          history.goBack();
                        }}
                      >
                        Thoát
                      </Button>
                    </div>
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </div>
      </Row>
    </>
  );
}

export default memo(PCD);
