import React, { memo, useEffect, useState } from 'react';
import _ from 'lodash';
import {
  Button,
  Card,
  CardBody,
  CardHeader,
  Col,
  Container,
  Input,
  Row,
  Table,
} from 'reactstrap';
import queryString from 'query-string';
import { useHistory } from 'react-router';

import SimpleHeader from 'components/Headers/SimpleHeader.js';
import 'assets/css/custom-pages/react-bs-table.css';
import { useDispatch, useSelector } from 'react-redux';
import LabelCustom from '../../components/LabelCustom';
import { notificationAlertRef, notify } from 'common';
import LoadingButtonCustom from '../../components/LoadingButtonCustom';
import InputCustom from '../../components/InputCustom';
import LabelValue from '../../components/LabelValue';
import SelectCustom from '../../components/SelectCustom';
import SwitchCustom from '../../components/SwitchCustom';
import RowTable from './RowTable';
import { orderActions } from 'redux/actions';
import CONSTANT from 'constant';
import moment from 'moment';

const resultsDetail = [
  {
    content: 'Tam bội NST 21 (Trisomy 21)',
    value: '',
    normal: '< 2',
    suspect: '2 - 3',
    abnormal: '>3',
    result: '',
  },
  {
    content: 'Tam bội NST 18 (Trisomy 18)',
    value: '',
    normal: '< 2',
    suspect: '2 - 3',
    abnormal: '>3',
    result: '',
  },
  {
    content: 'Tam bội NST 13 (Trisomy 13)',
    value: '',
    normal: '< 2',
    suspect: '2 - 3',
    abnormal: '>3',
    result: '',
  },
  {
    content: 'Monosomy X',
    value: '',
    normal: '< 2',
    suspect: '2 - 3',
    abnormal: '>3',
    result: '',
  },
];

function ResultsXN({ resultXN, handleResultChange, disable }) {
  return (
    <div>
      <Container className="" fluid>
        <Row>
          <div className="col">
            <Card>
              <CardBody>
                <Table>
                  <thead>
                    <th
                      className="h3 font-weight-600 p-2"
                      style={{ paddingRight: 16, maxWidth: 100 }}
                    >
                      NỘI DUNG XÉT NGHIỆM
                    </th>
                    <th
                      style={{ maxWidth: 80 }}
                      className="h3 font-weight-600 p-2 text-center"
                    >
                      GIÁ TRỊ NGUY CƠ
                    </th>

                    <th className="h3 font-weight-600 p-2">KẾT QUẢ</th>
                  </thead>
                  <tbody>
                    {_.get(resultXN, 'resultsDetail', []).map((item, index) => {
                      return (
                        <RowTable
                          key={index}
                          item={item}
                          idxR={index}
                          disable={disable}
                          handleResultChange={handleResultChange}
                        />
                      );
                    })}
                  </tbody>
                </Table>
              </CardBody>
            </Card>
          </div>
        </Row>
      </Container>
    </div>
  );
}

export default memo(ResultsXN);
