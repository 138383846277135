import typeActions from 'redux/typeActions';

const setValue = ({ name, value }) => {
  return {
    type: typeActions.SET_ORDER_VALUE,
    name,
    value,
  };
};

const getOrders = (query, callback, isUpdateReducer = true) => {
  return {
    type: typeActions.GET_ORDERS_REQUEST,
    query,
    callback,
    isUpdateReducer,
  };
};

const getOrder = (param, query, callback) => {
  return {
    type: typeActions.GET_ORDER_REQUEST,
    param,
    query,
    callback,
  };
};

const createOrder = (body, callback) => {
  return {
    type: typeActions.CREATE_ORDER_REQUEST,
    body,
    callback,
  };
};
const updateOrder = (param, body, callback) => {
  return {
    type: typeActions.UPDATE_ORDER_REQUEST,
    param,
    body,
    callback,
  };
};
const deleteOrder = (param, callback) => {
  return {
    type: typeActions.DELETE_ORDER_REQUEST,
    param,
    callback,
  };
};

const getOrderBy = (query, callback) => {
  return {
    type: typeActions.GET_ORDER_BY_REQUEST,
    query,
    callback,
  };
};

const exportOrder = (query, callback) => {
  return {
    type: typeActions.EXPORT_ORDER_REQUEST,
    callback,
    query,
  };
};
const exportOrderNeedUpdate = (query, callback) => {
  return {
    type: typeActions.EXPORT_ORDER_NEED_UPDATE_REQUEST,
    callback,
    query,
  };
};
const exportOrderUnapproved = (query, callback) => {
  return {
    type: typeActions.EXPORT_ORDER_UNAPPROVED_REQUEST,
    callback,
    query,
  };
};
const exportOrderSynthesisSampleTransfer = (query, callback) => {
  return {
    type: typeActions.EXPORT_ORDER_SYNTHESIS_SAMPLE_TRANSFER_REQUEST,
    callback,
    query,
  };
};
const exportOrderReceiveResult = (query, callback) => {
  return {
    type: typeActions.EXPORT_ORDER_RECEIVE_RESULT_REQUEST,
    callback,
    query,
  };
};
const exportOrderApproveResult = (query, callback) => {
  return {
    type: typeActions.EXPORT_ORDER_APPROVE_RESULT_REQUEST,
    callback,
    query,
  };
};
const exportOrderPayResult = (query, callback) => {
  return {
    type: typeActions.EXPORT_ORDER_PAY_RESULT_REQUEST,
    callback,
    query,
  };
};

const printPkq = (param, callback) => {
  return {
    type: typeActions.PRINT_PKQ_REQUEST,
    callback,
    param,
  };
};

const uploadFiles = (order, callback) => {
  return {
    type: typeActions.UPLOAD_FILES_ORDER_REQUEST,
    callback,
    order,
  };
};

const updateLabCodes = (file, callback) => {
  return {
    type: typeActions.UPDATE_LABCODES_REQUEST,
    callback,
    file,
  };
};

const sendToZalo = (param, body, callback) => {
  return {
    type: typeActions.SEND_TO_ZALO_REQUEST,
    callback,
    param,
    body,
  };
};

export default {
  setValue,
  getOrders,
  getOrder,
  createOrder,
  updateOrder,
  deleteOrder,
  getOrderBy,
  exportOrder,
  printPkq,
  uploadFiles,
  updateLabCodes,
  exportOrderNeedUpdate,
  exportOrderUnapproved,
  exportOrderSynthesisSampleTransfer,
  exportOrderReceiveResult,
  exportOrderApproveResult,
  exportOrderPayResult,
  sendToZalo,
};
