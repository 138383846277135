import React, { useEffect, useState } from 'react';
// nodejs library that concatenates classes
import classnames from 'classnames';
// nodejs library to set properties for components
import PropTypes from 'prop-types';
// reducer
import {
  additionalSampleActions,
  areaActions,
  barcodeActions,
  filterActions,
  orderActions,
  organizationActions,
  partnerActions,
  patientActions,
  promotionActions,
  serviceActions,
  specifyVotesActions,
} from 'redux/actions';

// reactstrap components
import {
  Collapse,
  DropdownMenu,
  DropdownItem,
  UncontrolledDropdown,
  DropdownToggle,
  Media,
  Navbar,
  NavItem,
  NavLink,
  Nav,
  Container,
  Button,
  Col,
  Row,
} from 'reactstrap';
import { useHistory, useLocation, useParams } from 'react-router';
import { useDispatch, useSelector } from 'react-redux';
import _ from 'lodash';
import FileDownload from 'js-file-download';
import queryString from 'query-string';

import { accountActions } from 'redux/actions';
import { formActions } from 'redux/actions';
import iconList from 'assets/img/icons/categories/list2.svg';
import IconUser from 'assets/img/icons/common/user_2.svg';
import IconLogOut from 'assets/img/icons/common/logout.svg';
import { BASE_URL_IMAGE } from 'services/ServiceURL';
import 'assets/css/custom-pages/admin-navbar.css';
import IconEye from 'assets/svgs/filter.svg';
import { notificationAlertRef, notify } from 'common';
import LoadingButtonCustom from 'views/pages/components/LoadingButtonCustom';
import moment from 'moment';
import CONSTANT from 'constant';

function AdminNavbar({ theme, sidenavOpen, toggleSidenav }) {
  const history = useHistory();
  const { id } = useParams();
  const { filterInfo } = useSelector((state) => state.formReducer);
  const { queryFilter, queryPage } = useSelector(
    (state) => state.filterReducer
  );
  const { currentAccountExtend, isBusinessStaff } = useSelector(
    (state) => state.accountReducer
  );
  const { isPrintSpecifyVote } = useSelector(
    (state) => state.specifyvoteReducer
  );
  const {
    querySynthesisSampleTransferOrigin,
    querySynthesisSampleTransfer,
    isExportingOrder,
    status,
    listType,
    page,
    limit,
  } = useSelector((state) => state.orderReducer);
  const { areasByAccountExtend } = useSelector((state) => state.areaReducer);

  const additionalSampleReducer = useSelector(
    (state) => state.additionalSampleReducer
  );
  const promotionReducer = useSelector((state) => state.promotionReducer);
  const { isExportingPromotion } = useSelector(
    (state) => state.promotionReducer
  );

  const avatar = _.get(currentAccountExtend, 'avatar.url', '');
  const [accountInfo, setAccountInfo] = useState();
  const location = useLocation();
  const {
    title,
    subTitle,
    isDisplayAddBtn,
    isDisplayFilterBtn,
    isDisplayCancel,
    isDisplayPrintListBtn,
    isDisplaySaveAndPrint,
    isDisplaySaveAndExit,
    isDisplayAddFastBtn,
    filterTitle,
    isReceiveBtn,
    isDisplayGoBackBtn,
    isDisplayPrintBtn,
    isDisplayExportBtn,
    isDisplayImportBtn,
    isDisplayApproveBtn,
    isDisplayCreateAdditionalSampleBtn,
  } = useSelector((state) => state.timelineReducer);
  const dispatch = useDispatch();

  // get user info
  useEffect(() => {
    let account = {};
    account = JSON.parse(localStorage.getItem('user'));
    account = { ...account, token: JSON.parse(localStorage.getItem('token')) };
    setAccountInfo(account);
  }, []);
  // function that on mobile devices makes the search open
  const openSearch = () => {
    document.body.classList.add('g-navbar-search-showing');
    setTimeout(function () {
      document.body.classList.remove('g-navbar-search-showing');
      document.body.classList.add('g-navbar-search-show');
    }, 150);
    setTimeout(function () {
      document.body.classList.add('g-navbar-search-shown');
    }, 300);
  };
  // function that on mobile devices makes the search close
  const closeSearch = () => {
    document.body.classList.remove('g-navbar-search-shown');
    setTimeout(function () {
      document.body.classList.remove('g-navbar-search-show');
      document.body.classList.add('g-navbar-search-hiding');
    }, 150);
    setTimeout(function () {
      document.body.classList.remove('g-navbar-search-hiding');
      document.body.classList.add('g-navbar-search-hidden');
    }, 300);
    setTimeout(function () {
      document.body.classList.remove('g-navbar-search-hidden');
    }, 500);
  };

  function handleLogOut() {
    dispatch(accountActions.accountLogOut());
    localStorage.clear();
  }

  // const handleExportOrder = () => {
  //   const query = _.cloneDeep(querySynthetic);
  //   for (const key in query) {
  //     if (query[key] === null || query[key] === '') {
  //       delete query[key];
  //     }
  //   }

  //   if (!isAdmin && isSampleCollectorStaff) {
  //     query['filters[$and][11][codeStaff2][id][$eq]'] =
  //       currentAccountExtend?.id;
  //   } else if (!isAdmin && isBusinessStaff) {
  //     query['filters[$and][11][codeStaff1][id][$eq]'] =
  //       currentAccountExtend?.id;
  //   }

  //   dispatch(
  //     orderActions.exportOrder(
  //       queryString.stringify({
  //         ...query,
  //         'sort[0]': 'createdAt:DESC',
  //         type: 'order-all',
  //       }),
  //       {
  //         success: (data) => {
  //           handleDownloadFile(
  //             data,
  //             `Danh_sach_don_hang_${moment().unix()}.xlsx`
  //           );
  //         },
  //         failed: handleExportFailed,
  //       }
  //     )
  //   );
  // };

  // querySynthesisSampleTransfer
  const handleExportOrderSynthesisSampleTransfer = () => {
    const dontShowStatus = [
      CONSTANT.ORDER_STATUS[0].value,
      CONSTANT.ORDER_STATUS[1].value,
      CONSTANT.ORDER_STATUS[2].value,
      CONSTANT.ORDER_STATUS[3].value,
      CONSTANT.ORDER_STATUS[4].value,
      CONSTANT.ORDER_STATUS[9].value,
      CONSTANT.ORDER_STATUS[10].value,
      CONSTANT.ORDER_STATUS[11].value,
    ];
    const query = _.cloneDeep(querySynthesisSampleTransfer);
    query.pageSize = limit;
    query.page = page;

    // querySynthesisSampleTransferOrigin
    if (status === null) {
      dontShowStatus.forEach((item, index) => {
        if (
          item !== CONSTANT.ORDER_STATUS[3].value &&
          item !== CONSTANT.ORDER_STATUS[2].value &&
          item !== CONSTANT.ORDER_STATUS[1].value
        ) {
          query[`filters[$and][${index + 1}][status][$ne]`] = item;
        }
      });
      query['filters[$and][4][receptionStatus][$eq]'] = true;
    } else if (status === 'received') {
      // receptionStatus
      query['filters[$and][2][receptionStatus][$eq]'] = true;
      query['filters[$and][3][$or][0][status][$eq]'] =
        CONSTANT.ORDER_STATUS[4].value;
      query['filters[$and][3][$or][1][status][$eq]'] =
        CONSTANT.ORDER_STATUS[3].value;
      query['filters[$and][3][$or][2][status][$eq]'] =
        CONSTANT.ORDER_STATUS[2].value;
      query['filters[$and][3][$or][3][status][$eq]'] =
        CONSTANT.ORDER_STATUS[1].value;
    } else {
      query['filters[$and][1][status][$eq]'] = status;
    }
    if (!isBusinessStaff) {
      areasByAccountExtend.forEach((item, index) => {
        query[`filters[$and][4][$or][${index}][areaName][$eq]`] = item.name;
      });
    }

    dispatch(
      orderActions.exportOrder(
        queryString.stringify(
          {
            ...query,
            ...querySynthesisSampleTransferOrigin,
            'sort[0]': 'createdAt:DESC',
            'filters[$and][20][status][$eq]': status,
            type: 'order-testing',
          },
          {
            skipEmptyString: true,
            skipNull: true,
          }
        ),
        {
          success: (data) => {
            handleDownloadFile(
              data,
              `Danh_sach_don_hang_da_chuyen_xn_${moment().unix()}.xlsx`
            );
          },
          failed: handleExportFailed,
        }
      )
    );
  };

  const handleExportAdditionalSampleSynthesisSampleTransfer = () => {
    const query = _.cloneDeep(
      additionalSampleReducer.querySynthesisSampleTransfer
    );

    query.pageSize = additionalSampleReducer.limit;
    query.page = additionalSampleReducer.page;

    if (!isBusinessStaff) {
      areasByAccountExtend.forEach((item, index) => {
        query[`filters[$and][1][$or][${20 + index}][areaName][$eq]`] =
          item.name;
      });
    }

    if (additionalSampleReducer.status === null) {
    } else if (additionalSampleReducer.status === 'received') {
      // receptionStatus
      query['filters[$and][3][receptionStatus][$eq]'] = true;
      query['filters[$and][4][$or][1][status][$eq]'] =
        CONSTANT.ORDER_STATUS[3].value;
      query['filters[$and][4][$or][2][status][$eq]'] =
        CONSTANT.ORDER_STATUS[2].value;
      query['filters[$and][4][$or][3][status][$eq]'] =
        CONSTANT.ORDER_STATUS[1].value;
    } else {
      query['filters[$and][1][status][$eq]'] = additionalSampleReducer.status;
    }
    dispatch(
      additionalSampleActions.exportAdditionalSample(
        queryString.stringify({
          ...query,
          'sort[0]': 'createdAt:DESC',
          'filters[$and][20][status][$eq]': additionalSampleReducer.status,
          type: 'additional-sample-testing',
        }),
        {
          success: (data) => {
            handleDownloadFile(
              data,
              `Danh_sach_mau_bo_sung_dang_chuyen_xn_${moment().unix()}.xlsx`
            );
          },
          failed: handleExportFailed,
        }
      )
    );
  };

  const handleExportPromotion = () => {
    const { startDate, endDate } = filterInfo;
    const query = {
      'filters[deleted][$eq]': false,
    };
    if (!!startDate) {
      query['filters[startDate][$gte]'] = startDate;
    }
    if (!!endDate) {
      query['filters[endDate][$lte]'] = endDate;
    }
    query.page = promotionReducer.page;
    query.pageSize = promotionReducer.limit;
    dispatch(
      promotionActions.exportPromotion(queryString.stringify(query), {
        success: (data) => {
          handleDownloadFile(
            data,
            `Danh_sach_chuong_trinh_khuyen_mai_${moment().unix()}.xlsx`
          );
        },
        failed: handleExportFailed,
      })
    );
  };

  const handleExportFailed = (mess) => {
    notify(
      notificationAlertRef,
      'danger',
      'Thông báo',
      `Xuất danh sách thất bại. Lỗi: ${mess}`
    );
  };

  const handleDownloadFile = (data, filename) => {
    FileDownload(data, filename);
    notify(
      notificationAlertRef,
      'success',
      'Thông báo',
      `Xuất danh sách thành công!`
    );
  };

  const handlePrintPCD = () => {
    const arrPath = location.pathname.split('/');
    const id = arrPath[arrPath.length - 1] ?? -1;

    if (id === -1) return;
    dispatch(
      specifyVotesActions.printSpecifyVote(id, {
        success: (data) => {
          var blobURL = URL.createObjectURL(data);
          window.open(blobURL);
        },
        failed: (mess) => {
          notify(
            notificationAlertRef,
            'danger',
            'Thông báo',
            `In phiếu xét nghiệm lỗi!`
          );
        },
      })
    );
  };

  const handleExportByFilterValue = () => {
    const query = {
      ...queryFilter,
      ...queryPage,
    };
    delete query['page'];
    delete query['limit'];
    console.log(query);
    //Danh sách phiếu chỉ định
    if (location.pathname.includes('/admin/specify-vote-all')) {
      dispatch(
        specifyVotesActions.exportSpecifyVote(
          queryString.stringify(query, {
            skipEmptyString: true,
            skipNull: true,
          }),
          {
            success: (data) => {
              handleDownloadFile(
                data,
                `Danh_sach_phieu_chi_dinh_${moment().unix()}.xlsx`
              );
            },
            failed: handleExportFailed,
          }
        )
      );
    }
    //Danh sách bệnh nhân
    if (location.pathname.includes('/admin/patient')) {
      dispatch(
        patientActions.exportPatient(
          queryString.stringify(query, {
            skipEmptyString: true,
            skipNull: true,
          }),
          {
            success: (data) => {
              handleDownloadFile(
                data,
                `Danh_sach_benh_nhan_${moment().unix()}.xlsx`
              );
            },
            failed: handleExportFailed,
          }
        )
      );
    }
    console.log(location.pathname, additionalSampleReducer.status);
    // Quản lý đơn hàng
    if (location.pathname.includes('/admin/order-all')) {
      dispatch(
        formActions.setValue({
          name: 'isModalVisible',
          value: true,
        })
      );
      return;
    }
    if (location.pathname.includes('/admin/order-unpaid')) {
      dispatch(
        formActions.setValue({
          name: 'formType',
          value: 0,
        })
      );
      dispatch(
        formActions.setValue({
          name: 'isModalVisible',
          value: true,
        })
      );
      // handleExportOrder();
    }
    if (location.pathname === '/admin/order-unapproved') {
      dispatch(
        orderActions.exportOrderUnapproved(
          queryString.stringify(query, {
            skipEmptyString: true,
            skipNull: true,
          }),
          {
            success: (data) => {
              handleDownloadFile(
                data,
                `Danh_sach_don_hang_cho_duyet_${moment().unix()}.xlsx`
              );
            },
            failed: handleExportFailed,
          }
        )
      );
      return;
    }
    // Quản lý đơn hàng - cần cập nhật
    if (location.pathname === '/admin/order-init') {
      dispatch(
        orderActions.exportOrderNeedUpdate(
          queryString.stringify(query, {
            skipEmptyString: true,
            skipNull: true,
          }),
          {
            success: (data) => {
              handleDownloadFile(
                data,
                `Danh_sach_don_hang_can_cap_nhat_${moment().unix()}.xlsx`
              );
            },
            failed: handleExportFailed,
          }
        )
      );
      return;
    }
    // Quản lý đơn hàng mẫu bổ sung
    console.log(additionalSampleReducer.status);
    if (
      location.pathname.includes('/admin/additional-sample') &&
      additionalSampleReducer.status !== CONSTANT.ORDER_STATUS[5].value
    ) {
      let fileName = 'Danh_sach_don_hang_mau_bo_sung';
      switch (additionalSampleReducer.status) {
        case 'received':
        case CONSTANT.ORDER_STATUS[1].value:
        case CONSTANT.ORDER_STATUS[2].value:
        case CONSTANT.ORDER_STATUS[3].value:
        case CONSTANT.ORDER_STATUS[4].value:
          fileName = 'Danh_sach_don_hang_da_tiep_nhan';
          break;
        case CONSTANT.ORDER_STATUS[5].value:
          fileName = 'Danh_sach_don_hang_da_chuyen_xn';
          break;
        case CONSTANT.ORDER_STATUS[6].value:
          fileName = 'Danh_sach_don_hang_dang_xn';
          break;
        case CONSTANT.ORDER_STATUS[7].value:
          fileName = 'Danh_sach_don_hang_dang_xn_mau_loi';
          break;
        case CONSTANT.ORDER_STATUS[8].value:
          fileName = 'Danh_sach_don_hang_dang_xn_chay_lai';
          break;
        case CONSTANT.ORDER_STATUS[12].value:
          fileName = 'Danh_sach_don_hang_cho_duyet_kq';
          break;
        case CONSTANT.ORDER_STATUS[13].value:
          fileName = 'Danh_sach_don_hang_duyet_kq_loi';
          break;

        default:
          break;
      }
      dispatch(
        additionalSampleActions.exportAdditionalSampleAll(
          queryString.stringify(query, {
            skipEmptyString: true,
            skipNull: true,
          }),
          {
            success: (data) => {
              handleDownloadFile(data, `${fileName}_${moment().unix()}.xlsx`);
            },
            failed: handleExportFailed,
          }
        )
      );
    }

    //Tổng hợp và chuyển mẫu
    console.log(location.pathname, status);
    if (
      location.pathname.includes('/admin/synthesis-sample-transfer') &&
      status !== CONSTANT.ORDER_STATUS[5].value
    ) {
      let fileName = 'Danh_sach_don_hang_tong_hop_va_chuyen_mau';
      switch (status) {
        case 'received':
        case CONSTANT.ORDER_STATUS[1].value:
        case CONSTANT.ORDER_STATUS[2].value:
        case CONSTANT.ORDER_STATUS[3].value:
        case CONSTANT.ORDER_STATUS[4].value:
          fileName = 'Danh_sach_don_hang_da_tiep_nhan';
          break;
        case CONSTANT.ORDER_STATUS[6].value:
          fileName = 'Danh_sach_don_hang_dang_xn';
          break;
        case CONSTANT.ORDER_STATUS[7].value:
          fileName = 'Danh_sach_don_hang_dang_xn_mau_loi';
          break;
        case CONSTANT.ORDER_STATUS[8].value:
          fileName = 'Danh_sach_don_hang_dang_xn_chay_lai';
          break;
        case CONSTANT.ORDER_STATUS[12].value:
          fileName = 'Danh_sach_don_hang_cho_duyet_kq';
          break;
        case CONSTANT.ORDER_STATUS[13].value:
          fileName = 'Danh_sach_don_hang_duyet_kq_loi';
          break;

        default:
          break;
      }
      dispatch(
        orderActions.exportOrderSynthesisSampleTransfer(
          queryString.stringify(query, {
            skipEmptyString: true,
            skipNull: true,
          }),
          {
            success: (data) => {
              handleDownloadFile(data, `${fileName}_${moment().unix()}.xlsx`);
            },
            failed: handleExportFailed,
          }
        )
      );
      return;
    }
    //Tổng hợp và chuyển mẫu - Đã chuyển xét nghiệm
    if (
      // (!location.pathname.includes(
      //   '/admin/synthesis-sample-transfer'
      // ) &&
      //   isDisplayExportBtn &&
      //   !id) ||
      (location.pathname.includes('/admin/synthesis-sample-transfer') &&
        status === CONSTANT.ORDER_STATUS[5].value) ||
      (location.pathname.includes('/admin/additional-sample') &&
        additionalSampleReducer.status === CONSTANT.ORDER_STATUS[5].value)
    ) {
      handleExportOrderSynthesisSampleTransfer();
    }
    //Danh sách đơn hàng nhập và trả kết quả - tiếp nhận
    if (location.pathname.includes('/admin/receive-result')) {
      if (listType === 'order') {
        dispatch(
          orderActions.exportOrderReceiveResult(
            queryString.stringify(query, {
              skipEmptyString: true,
              skipNull: true,
            }),
            {
              success: (data) => {
                handleDownloadFile(
                  data,
                  `Danh_sach_don_hang_tiep_nhan_ket_qua_${moment().unix()}.xlsx`
                );
              },
              failed: handleExportFailed,
            }
          )
        );
      } else {
        dispatch(
          additionalSampleActions.exportAdditionalSampleAll(
            queryString.stringify(query, {
              skipEmptyString: true,
              skipNull: true,
            }),
            {
              success: (data) => {
                handleDownloadFile(
                  data,
                  `Danh_sach_don_hang_tiep_nhan_ket_qua_mau_bs_${moment().unix()}.xlsx`
                );
              },
              failed: handleExportFailed,
            }
          )
        );
      }
    }
    //Danh sách đơn hàng nhập và trả kết quả - chờ duyệt
    if (location.pathname.includes('/admin/approve-result')) {
      if (listType === 'order') {
        dispatch(
          orderActions.exportOrderApproveResult(
            queryString.stringify(query, {
              skipEmptyString: true,
              skipNull: true,
            }),
            {
              success: (data) => {
                handleDownloadFile(
                  data,
                  `Danh_sach_don_hang_cho_duyet_ket_qua_${moment().unix()}.xlsx`
                );
              },
              failed: handleExportFailed,
            }
          )
        );
      } else {
        dispatch(
          additionalSampleActions.exportAdditionalSampleAll(
            queryString.stringify(query, {
              skipEmptyString: true,
              skipNull: true,
            }),
            {
              success: (data) => {
                handleDownloadFile(
                  data,
                  `Danh_sach_don_hang_cho_duyet_ket_qua_mau_bs_${moment().unix()}.xlsx`
                );
              },
              failed: handleExportFailed,
            }
          )
        );
      }
    }
    //Danh sách đơn hàng nhập và trả kết quả - trả kết quả
    if (location.pathname.includes('/admin/pay-result')) {
      if (listType === 'order') {
        dispatch(
          orderActions.exportOrderPayResult(
            queryString.stringify(query, {
              skipEmptyString: true,
              skipNull: true,
            }),
            {
              success: (data) => {
                handleDownloadFile(
                  data,
                  `Danh_sach_don_hang_da_tra_ket_qua_${moment().unix()}.xlsx`
                );
              },
              failed: handleExportFailed,
            }
          )
        );
      } else {
        dispatch(
          additionalSampleActions.exportAdditionalSampleAll(
            queryString.stringify(query, {
              skipEmptyString: true,
              skipNull: true,
            }),
            {
              success: (data) => {
                handleDownloadFile(
                  data,
                  `Danh_sach_don_hang_tra_ket_qua_mau_bs_${moment().unix()}.xlsx`
                );
              },
              failed: handleExportFailed,
            }
          )
        );
      }
    }
    //Danh sách khu vực
    if (location.pathname.includes('/admin/area')) {
      dispatch(
        areaActions.exportAreas(
          queryString.stringify(query, {
            skipEmptyString: true,
            skipNull: true,
          }),
          {
            success: (data) => {
              handleDownloadFile(
                data,
                `Danh_sach_khu_vuc_${moment().unix()}.xlsx`
              );
            },
            failed: handleExportFailed,
          }
        )
      );
    }
    //Danh sách tổ chức
    if (location.pathname.includes('/admin/organization')) {
      dispatch(
        organizationActions.exportOrganizations(
          queryString.stringify(query, {
            skipEmptyString: true,
            skipNull: true,
          }),
          {
            success: (data) => {
              handleDownloadFile(
                data,
                `Danh_sach_to_chuc_${moment().unix()}.xlsx`
              );
            },
            failed: handleExportFailed,
          }
        )
      );
    }
    //Danh sách nhóm dịch vụ
    if (location.pathname === '/admin/service-group') {
      dispatch(
        serviceActions.exportServiceGroups(
          queryString.stringify(query, {
            skipEmptyString: true,
            skipNull: true,
          }),
          {
            success: (data) => {
              handleDownloadFile(
                data,
                `Danh_sach_nhom_dich_vu_${moment().unix()}.xlsx`
              );
            },
            failed: handleExportFailed,
          }
        )
      );
    }
    //Danh sách dịch vụ
    if (location.pathname === '/admin/service') {
      dispatch(
        serviceActions.exportServices(
          queryString.stringify(query, {
            skipEmptyString: true,
            skipNull: true,
          }),
          {
            success: (data) => {
              handleDownloadFile(
                data,
                `Danh_sach_dich_vu_${moment().unix()}.xlsx`
              );
            },
            failed: handleExportFailed,
          }
        )
      );
    }
    //Danh sách chương trình
    if (location.pathname === '/admin/promotion') {
      handleExportPromotion();
    }
    //Danh sách chi tiết khuyến mại
    if (location.pathname === '/admin/promotion-detail') {
      dispatch(
        promotionActions.exportPromotionDetail(
          queryString.stringify(query, {
            skipEmptyString: true,
            skipNull: true,
          }),
          {
            success: (data) => {
              handleDownloadFile(
                data,
                `Danh_sach_chi_tiet_khuyen_mai_${moment().unix()}.xlsx`
              );
            },
            failed: handleExportFailed,
          }
        )
      );
    }
    //Danh sách đối tác xét nghiệm
    if (location.pathname.includes('/admin/partner')) {
      dispatch(
        partnerActions.exportPartners(
          queryString.stringify(query, {
            skipEmptyString: true,
            skipNull: true,
          }),
          {
            success: (data) => {
              handleDownloadFile(
                data,
                `Danh_sach_doi_tac_${moment().unix()}.xlsx`
              );
            },
            failed: handleExportFailed,
          }
        )
      );
    }
    //Danh sách barcode
    if (location.pathname.includes('/admin/barcode')) {
      dispatch(
        barcodeActions.exportBarcodes(
          queryString.stringify(query, {
            skipEmptyString: true,
            skipNull: true,
          }),
          {
            success: (data) => {
              handleDownloadFile(
                data,
                `Danh_sach_barcode_${moment().unix()}.xlsx`
              );
            },
            failed: handleExportFailed,
          }
        )
      );
    }
    //Danh sách tài khoản
    if (location.pathname.includes('/admin/account')) {
      dispatch(
        accountActions.exportAccounts(
          queryString.stringify(query, {
            skipEmptyString: true,
            skipNull: true,
          }),
          {
            success: (data) => {
              handleDownloadFile(
                data,
                `Danh_sach_tai_khoan_${moment().unix()}.xlsx`
              );
            },
            failed: handleExportFailed,
          }
        )
      );
    }
  };
  const routeAllowExports = [
    '/admin/specify-vote-all',
    '/admin/patient',
    '/admin/synthesis-sample-transfer',
    '/admin/receive-result',
    '/admin/approve-result',
    '/admin/pay-result',
    '/admin/area',
    '/admin/organization',
    '/admin/service-group',
    '/admin/service',
    '/admin/promotion',
    '/admin/promotion-detail',
    '/admin/partner',
    '/admin/barcode',
    '/admin/account',
    '/admin/order-all',
    '/admin/order-init',
    '/admin/order-unpaid',
    '/admin/order-unapproved',
    '/admin/additional-sample',
  ];

  function handleExportFile(pathname) {
    console.log(pathname);
    if (pathname.includes('/admin/order-unpaid')) {
      // handleExportOrderUnpaid();
      dispatch(
        formActions.setValue({
          name: 'formType',
          value: 0,
        })
      );
      dispatch(
        formActions.setValue({
          name: 'isModalVisible',
          value: true,
        })
      );
    } else if (
      pathname.includes('/admin/additional-sample') &&
      additionalSampleReducer.status === CONSTANT.ORDER_STATUS[5].value
    ) {
      handleExportAdditionalSampleSynthesisSampleTransfer();
    } else if (routeAllowExports.some((item) => pathname.includes(item))) {
      // Màn phiếu xét nghiệm
      // Màn danh sách bệnh nhân
      // Màn đơn hàng cần cập nhật
      handleExportByFilterValue();
    }
  }
  function isDisplayExport(pathname) {
    console.log(additionalSampleReducer.status);
    // Màn phiếu xét nghiệm
    // Màn danh sách bệnh nhân
    // Màn đơn hàng cần cập nhật
    if (routeAllowExports.some((item) => pathname.includes(item)))
      return 'block';

    return 'none';
  }
  return (
    <>
      <div className="container-timeline">
        <Navbar
          className={classnames(
            'navbar-top navbar-expand border-bottom py-0 ',
            { 'navbar-dark ': theme === 'dark' },
            { 'navbar-light bg-secondary': theme === 'light' }
          )}
        >
          <Container fluid>
            <Collapse navbar isOpen={true}>
              <div className="d-none d-md-block">
                <div className="d-flex align-middle w-100 ">
                  <img src={iconList} alt={'list'} className={'mr-2'} />
                  <h1 className="text-white mb-0 py-3">
                    HỆ THỐNG QUẢN LÝ DỊCH VỤ XÉT NGHIỆM
                  </h1>
                </div>
              </div>
              <Nav className="align-items-center ml-md-auto" navbar>
                <NavItem className="d-xl-none">
                  <div
                    className={classnames(
                      'pr-3 sidenav-toggler',
                      { active: sidenavOpen },
                      { 'sidenav-toggler-dark': theme === 'dark' }
                    )}
                    onClick={toggleSidenav}
                  >
                    <div className="sidenav-toggler-inner">
                      <i className="sidenav-toggler-line" />
                      <i className="sidenav-toggler-line" />
                      <i className="sidenav-toggler-line" />
                    </div>
                  </div>
                </NavItem>
                <NavItem className="d-sm-none">
                  <NavLink onClick={openSearch}>
                    <i className="ni ni-zoom-split-in" />
                  </NavLink>
                </NavItem>
              </Nav>
              <Nav
                className="align-items-center ml-auto ml-md-0 custom-navbar-user"
                navbar
              >
                <UncontrolledDropdown nav>
                  <DropdownToggle
                    className="nav-link pr-0 "
                    style={{ cursor: 'pointer' }}
                    color=""
                    tag="a"
                  >
                    <Media className="align-items-center">
                      <span className="avatar avatar-sm rounded-circle">
                        <img
                          className="avatar-dropdown-toggle"
                          alt="..."
                          src={
                            avatar === ''
                              ? require('assets/img/icons/cards/avatar.jpg')
                                  .default
                              : `${BASE_URL_IMAGE}${avatar}`
                          }
                        />
                      </span>
                      <Media className="mx-2 d-none d-lg-block">
                        <span className="mb-0 text-sm font-weight-bold">
                          {`${currentAccountExtend?.fullname ?? ''}`}
                        </span>
                      </Media>
                    </Media>
                  </DropdownToggle>
                  <DropdownMenu bottom={true} className="mr-4 mt-2">
                    <DropdownItem
                      href="#pablo"
                      onClick={(e) => {
                        history.push('/admin/accountInfo');
                      }}
                      className={'d-flex align-items-center'}
                    >
                      <img src={IconUser} alt={'user'} />
                      <span>Thông tin tài khoản</span>
                    </DropdownItem>
                    <DropdownItem divider />
                    <DropdownItem
                      href="/auth/login"
                      onClick={() => {
                        handleLogOut();
                      }}
                      className={'d-flex align-items-center'}
                    >
                      <img src={IconLogOut} alt={'logout'} />
                      <span>Đăng xuất</span>
                    </DropdownItem>
                  </DropdownMenu>
                </UncontrolledDropdown>
              </Nav>
            </Collapse>
          </Container>
        </Navbar>
        <div className="container-timeline-sub d-md-none">
          <div className="d-flex align-middle w-100 d-md-none">
            <h1 className="text-white mb-0">
              HỆ THỐNG QUẢN LÝ DỊCH VỤ XÉT NGHIỆM
            </h1>
          </div>
        </div>
        <Row className={classnames('align-items-center pt-4 px-4', 'pb-8')}>
          <Col
            xs={12}
            lg={
              location.pathname.includes('synthesis-sample-transfer/receive')
                ? 8
                : 6
            }
            md={
              location.pathname.includes('synthesis-sample-transfer/receive')
                ? 6
                : 4
            }
            style={{
              display: 'flex',
              flexDirection: 'column',
            }}
          >
            <h2 className="text-white pt-2 ml-1">{title}</h2>
            <p
              className="text-white m-0 ml-1"
              style={{ fontSize: 16, fontWeight: '500' }}
            >
              {subTitle}
            </p>
            {filterTitle.filter((item) => item.value !== '').length > 0 && (
              <div
                className="ml-1"
                style={{
                  color: 'white',
                  fontSize: 12,
                  fontWeight: '600',
                }}
              >
                Đang lọc theo :{' '}
                {filterTitle
                  .filter((item) => item.value !== '')
                  .map((item, index, self) => {
                    return (
                      <span key={index}>
                        <span>{item?.title ?? ''}: </span> &nbsp;
                        <span>{item?.value ?? ''}</span>
                        {index !== self.length - 1 ? `; ` : `.`}
                      </span>
                    );
                  })}{' '}
              </div>
            )}
          </Col>
          <Col
            xs={12}
            lg={
              location.pathname.includes('synthesis-sample-transfer/receive')
                ? 4
                : 6
            }
            md={
              location.pathname.includes('synthesis-sample-transfer/receive')
                ? 6
                : 8
            }
            className="d-flex justify-content-end"
            style={{
              flexWrap: 'wrap',
              alignItems: 'center',
            }}
          >
            <Button
              className="mr-2 my-1 text-info"
              onClick={() => {
                history.goBack();
              }}
              style={{
                display: isDisplayGoBackBtn ? 'block' : 'none',
                backgroundColor: 'transparent',
                color: 'white',
                width: '130px',
              }}
            >
              <span style={{ color: 'white' }}>Quay lại</span>
            </Button>
            <Button
              className="mr-2 my-1 text-info"
              onClick={() => {
                history.push('/admin/specify-vote-all');
              }}
              style={{
                display: isDisplayCancel ? 'block' : 'none',
                backgroundColor: 'transparent',
                color: 'white',
                width: '130px',
              }}
            >
              <span style={{ color: 'white' }}>Huỷ</span>
            </Button>
            <Button
              className="mr-2 my-1 text-info"
              onClick={() => {
                dispatch(
                  formActions.setValue({
                    name: 'formType',
                    value: 0,
                  })
                );
                dispatch(
                  formActions.setValue({
                    name: 'isModalVisible',
                    value: true,
                  })
                );
              }}
              style={{
                display: isDisplayImportBtn ? 'block' : 'none',
              }}
            >
              Import danh sách
            </Button>

            <Button
              className="mr-2 my-1 text-info"
              onClick={() => {
                dispatch(
                  formActions.setValue({
                    name: 'isModalPrintVisible',
                    value: true,
                  })
                );
              }}
              style={{
                display: isDisplayPrintListBtn && !id ? 'block' : 'none',
              }}
            >
              In ra danh sách
            </Button>
            <LoadingButtonCustom
              className="text-nowrap my-1 mr-2 text-info "
              type="submit"
              loading={false}
              onClick={() => {
                dispatch(
                  formActions.setValue({
                    name: 'isModalApproveVisible',
                    value: true,
                  })
                );
              }}
              style={{
                display: isDisplayApproveBtn ? 'block' : 'none',
              }}
            >
              {location.pathname.includes('order-unpaid')
                ? 'Cập nhật thanh toán'
                : 'Duyệt'}
            </LoadingButtonCustom>
            <LoadingButtonCustom
              className="text-nowrap my-1 mr-2 text-info "
              type="submit"
              loading={isPrintSpecifyVote}
              onClick={() => {
                handlePrintPCD();
              }}
              style={{
                display: isDisplayPrintBtn && !id ? 'block' : 'none',
              }}
            >
              In phiếu
            </LoadingButtonCustom>
            <Button
              className="mr-2 my-1 text-info"
              onClick={() => {
                dispatch(
                  formActions.setValue({
                    name: 'isModalCreateAdditionalSampleVisible',
                    value: true,
                  })
                );
              }}
              style={{
                display: isDisplayCreateAdditionalSampleBtn ? 'block' : 'none',
              }}
            >
              Thêm mẫu bổ sung
            </Button>
            <Button
              className="mr-2 my-1 text-info"
              onClick={() => {
                if (
                  location.pathname.includes(
                    '/admin/synthesis-sample-transfer'
                  ) ||
                  location.pathname.includes('/admin/additional-sample')
                ) {
                  // history.push('/admin/synthesis-sample-transfer/receive');
                  dispatch(
                    formActions.setValue({
                      name: 'isModalConfirmBarcodeVisible',
                      value: true,
                    })
                  );
                } else if (
                  location.pathname.includes('/admin/receive-result')
                ) {
                  history.push(
                    `/admin/receive-results?tab=receive-result&listType=${listType}`
                  );
                }
              }}
              style={{
                display: isReceiveBtn ? 'block' : 'none',
              }}
            >
              Tiếp nhận
            </Button>
            <Button
              className="mr-2 my-1 text-info"
              onClick={() => {
                if (location.pathname.includes('specify-vote-all')) {
                  dispatch(
                    specifyVotesActions.setValueSpecifyVote({
                      name: 'isSubmitForm',
                      value: false,
                    })
                  );
                  history.push(
                    '/admin/specify-votes-create?tab=specify-vote-all'
                  );
                  return;
                }
                if (location.pathname.includes('order')) {
                  history.push('/admin/order/-1?tab=order-all');
                  return;
                }
                dispatch(
                  formActions.setValue({
                    name: 'formType',
                    value: 1,
                  })
                );
                dispatch(
                  formActions.setValue({
                    name: 'isModalVisible',
                    value: true,
                  })
                );
              }}
              style={{
                display: isDisplayAddBtn ? 'block' : 'none',
              }}
            >
              Thêm mới
            </Button>

            <Button
              className="mr-2 my-1 text-info"
              onClick={() => {
                if (location.pathname.includes('specify-votes')) {
                  history.push(
                    '/admin/specify-votes-create?tab=specify-vote-all'
                  );
                  return;
                }
                dispatch(
                  formActions.setValue({
                    name: 'isModalAddFastVisible',
                    value: true,
                  })
                );
              }}
              style={{
                display: isDisplayAddFastBtn ? 'block' : 'none',
              }}
            >
              Thêm nhanh
            </Button>

            <LoadingButtonCustom
              onClick={() => {
                handleExportFile(location.pathname);
              }}
              // color="info"
              className="text-nowrap my-1 mr-2 text-info "
              type="submit"
              loading={
                isExportingOrder ||
                isExportingPromotion ||
                additionalSampleReducer.isExportAdditionalSample
              }
              style={{
                display: isDisplayExport(location.pathname),
              }}
            >
              Xuất danh sách
            </LoadingButtonCustom>

            <Button
              className="mr-2 my-1 text-info"
              onClick={() => {
                if (
                  location.pathname.includes('/admin/specify-votes-create') ||
                  location.pathname.includes('/admin/specify-votes-update')
                ) {
                  dispatch(
                    specifyVotesActions.setValueSpecifyVote({
                      name: 'isSubmitForm',
                      value: {
                        name: 'submitAndPrint',
                        value: true,
                      },
                    })
                  );
                }
              }}
              style={{
                display: isDisplaySaveAndPrint ? 'block' : 'none',
              }}
            >
              Lưu và in phiếu
            </Button>
            <Button
              className="mr-2 my-1 text-info"
              onClick={() => {
                if (
                  location.pathname.includes('/admin/specify-votes-create') ||
                  location.pathname.includes('/admin/specify-votes-update')
                ) {
                  dispatch(
                    specifyVotesActions.setValueSpecifyVote({
                      name: 'isSubmitForm',
                      value: {
                        name: 'submitAndExit',
                        value: true,
                      },
                    })
                  );
                }
              }}
              style={{
                display: isDisplaySaveAndExit ? 'block' : 'none',
              }}
            >
              Lưu và thoát
            </Button>
            <Button
              className="mr-2 my-1 text-info"
              style={{
                display: isDisplayFilterBtn ? 'block' : 'none',
                lineHeight: '24px',
              }}
              onClick={() => {
                dispatch(
                  filterActions.setValueFilter({
                    name: 'isModalVisible',
                    value: true,
                  })
                );

                dispatch(
                  formActions.setValue({
                    name: 'isFilterVisible',
                    value: true,
                  })
                );
              }}
            >
              <img src={IconEye} className="mr-1 my-1" />
              Lọc thông tin
            </Button>
          </Col>
        </Row>
      </div>
    </>
  );
}

AdminNavbar.propTypes = {
  toggleSidenav: PropTypes.func,
  sidenavOpen: PropTypes.bool,
  theme: PropTypes.oneOf(['dark', 'light']),
};

export default AdminNavbar;
