import classNames from "classnames";
import React, { memo, useState } from "react";
import Select from "react-select";
import makeAnimated from "react-select/animated";

import HelperText from "./HelperText";
import LabelCustom from "./LabelCustom";

const animatedComponents = makeAnimated();

const SelectCustom = ({
  name = "",
  formik,
  label = "",
  placeholder = "",
  value = null,
  isRequired = false,
  isClearable = false,
  row = false,
  labelWidth = 120,
  lableTextAlign = "start",
  handleChange = () => { },
  handleBlur = () => { },
  onInputChange = () => { },
  isDisabled = false,
  inputValue,
  error,
  touched,
  options = [],
  style,
  isMulti = false,
  mutiLineLabel = false,
  stylesSelect = {},
  stylesWrapper = {},
  ...rest
}) => {
  return (
    <div
      style={
        row
          ? {
            display: "flex",
            justifyContent: "space-between",
            ...stylesWrapper
          }
          : stylesWrapper
      }
    >
      {!!label && (
        <LabelCustom
          isRequired={isRequired}
          label={label}
          id={name}
          style={{
            whiteSpace: !mutiLineLabel ? "nowrap" : "pre-wrap",
            margin: row && !mutiLineLabel ? "12px 8px 0px 0px" : undefined,
            textAlign: lableTextAlign,
            lineHeight: "16.5px",
          }}
          styleLabel={{
            minWidth: row ? labelWidth : undefined,
            maxWidth: row ? labelWidth : undefined,
          }}
        />
      )}
      <div
        style={{
          paddingLeft: row ? 16 : undefined,
          position: "relative",
        }}
        className={classNames({
          "w-100": true,
          "d-flex flex-column": true,
          "react-select__control--custom": !!error && !!touched,
        })}
      >
        <Select
          placeholder={placeholder}
          id={name}
          styles={{
            control: (baseStyles, state) => ({
              ...baseStyles,
              fontSize: "0.875rem",
              width: "100%",
              border:
                !!error && !!touched ? "1px solid red !important" : undefined,
              whiteSpace: "nowrap",
            }),
            valueContainer: (baseStyles, state) => ({
              ...baseStyles,
              maxWidth: "100%",
              overflowX: "auto",
            }),
            ...stylesSelect,

          }}
          isDisabled={isDisabled}
          name={name}
          value={value}
          isClearable={isClearable}
          onBlur={handleBlur}
          onChange={handleChange}
          inputValue={inputValue}
          options={options}
          backspaceRemovesValue={false}
          onInputChange={onInputChange}
          isMulti={isMulti}
          components={animatedComponents}
          {...rest}
        />
        <div
          style={{
            // position: "absolute",
            // top: "100%",
          }}
        >
          {error && !!touched && <HelperText message={error} />}
        </div>
      </div>
    </div>
  );
};

export default memo(SelectCustom);
