import React, { memo, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Button, Col, Form, Modal, Row } from 'reactstrap';
import * as yup from 'yup';
import { Formik } from 'formik';
import _ from 'lodash';
import queryString from 'query-string';

import { notificationAlertRef, notify, relationOneToOne } from 'common';
import {
  serviceActions,
  serviceGroupActions,
  formActions,
} from 'redux/actions';
import LoadingButtonCustom from 'views/pages/components/LoadingButtonCustom';
import CONSTANT from 'constant';
import InputCustom from 'views/pages/components/InputCustom';
import SelectCustom from 'views/pages/components/SelectCustom';
import { relationOneToMany } from 'common';

const CService = ({ handleGetServices }) => {
  const dispatch = useDispatch();

  // reducers
  const { isModalVisible, modalInfo } = useSelector(
    (state) => state.formReducer
  );
  const { isCreatingService, isUpdatingService } = useSelector(
    (state) => state.serviceReducer
  );
  const { serviceGroups } = useSelector((state) => state.serviceGroupReducer);

  const resultsServiceGroup = _.get(serviceGroups, 'results', []);
  const isUpdate = !_.isEmpty(modalInfo);

  // Khởi tạo dữ liêu
  const initInfo = {
    code: modalInfo?.code ?? '',
    name: modalInfo?.name ?? '',
    note: modalInfo?.note ?? '',
    content: modalInfo?.content ?? '',
    time: modalInfo?.time ?? 0,
    unitPrice: modalInfo?.unitPrice ?? 0,
    serviceGroup: modalInfo?.serviceGroup?.id ?? null,
    specimens: modalInfo?.specimens ?? null,
  };
  const [serviceGroupValue, setServiceGroupValue] = useState(null);
  const [specimensValue, setSpecimensValue] = useState(null);

  const [serviceGroupSearch, setServiceGroupSearch] = useState('');

  // validate dữ liêu
  const infoSchema = yup.object().shape({
    code: yup.string().required('Mã dịch vụ không được để trống!'),
    name: yup.string().required('Tên dịch vụ không được để trống!'),
    time: yup
      .number('Thời gian làm việc phải là số!')
      .min(0, 'Thời gian làm việc lớn hơn hoặc bằng 0!')
      .required('Thời gian làm việc không được để trống!'),
    unitPrice: yup
      .number('Đơn giá phải là số!')
      .min(0, 'Đơn giá lớn hơn hoặc bằng 0!')
      .required('Đơn giá không được để trống!'),
    content: yup.string().required('Nội dung xét nghiệm không được để trống!'),
    // contentXns: yup.string().required('Loại xét nghiệm không được để trống!'),
  });

  // Xử lý submit
  const onSubmit = (values) => {
    const body = {
      code: values.code,
      name: values.name,
      note: values.note,
      content: values.content,
      time: values.time,
      unitPrice: values.unitPrice,
      specimens:
        values.specimens !== null ? JSON.stringify(values.specimens) : '',
      serviceGroup: relationOneToOne(
        values.serviceGroup,
        values.serviceGroup !== modalInfo?.serviceGroup?.id
          ? modalInfo?.serviceGroup?.id ?? null
          : null
      ),
      // contentXns: relationOneToMany(
      //   values.contentXns,
      //   !!modalInfo?.contentXns
      //     ? modalInfo.contentXns
      //         .map((item) => item.id)
      //         .filter((item) => !values.contentXns.includes(item))
      //     : null
      // ),
    };

    for (const key in body) {
      if (body[key] === '') {
        delete body[key];
      }
    }

    if (isUpdate) {
      // update
      dispatch(
        serviceActions.updateService(modalInfo.id, body, {
          success: () => {
            handleGetServices();
            notify(
              notificationAlertRef,
              'success',
              'Thông báo',
              `Cập nhật dịch vụ thành công.`
            );
            handleClose();
          },
          failed: (mess) => {
            notify(
              notificationAlertRef,
              'danger',
              'Thông báo',
              `Cập nhật dịch vụ thất bại!. Lỗi: ${mess}!`
            );
          },
        })
      );
    } else {
      // create
      dispatch(
        serviceActions.createService(body, {
          success: () => {
            handleGetServices();
            notify(
              notificationAlertRef,
              'success',
              'Thông báo',
              `Thêm mới dịch vụ thành công.`
            );
            handleClose();
          },
          failed: (mess) => {
            notify(
              notificationAlertRef,
              'danger',
              'Thông báo',
              `Thêm mới dịch vụ thất bại!. Lỗi: ${mess}!`
            );
          },
        })
      );
    }
  };

  // Xử lý đóng form
  const handleClose = () => {
    setServiceGroupValue(null);
    setSpecimensValue(null);
    dispatch(
      formActions.setValue({
        name: 'isModalVisible',
        value: false,
      })
    );
    dispatch(
      formActions.setValue({
        name: 'modalInfo',
        value: {},
      })
    );
  };

  // Lấy danh sách service groups
  const handleGetServiceGroups = () => {
    if (serviceGroupSearch !== '') {
      dispatch(
        serviceGroupActions.getServiceGroups(
          queryString.stringify({
            'filters[$and][0][code][$containsi]': serviceGroupSearch,
          })
        )
      );
    } else {
      dispatch(serviceGroupActions.getServiceGroups(queryString.stringify({})));
    }
  };

  useEffect(() => {
    // Sau 500ms thì bắn api
    const delayDebounceFn = setTimeout(() => {
      handleGetServiceGroups();
    }, 500);
    return () => clearTimeout(delayDebounceFn);
  }, [serviceGroupSearch]);

  useEffect(() => {
    if (isUpdate) {
      setServiceGroupValue(
        !!modalInfo?.serviceGroup
          ? {
              label: `${modalInfo.serviceGroup?.name ?? ''}(${
                modalInfo.serviceGroup?.code ?? ''
              })`,
              value: modalInfo.serviceGroup?.id ?? -1,
            }
          : null
      );
      if (!!modalInfo?.specimens) {
        const specimensV = CONSTANT.SPECIMENS.filter((item) =>
          (modalInfo?.specimens ?? []).includes(item.value)
        );

        setSpecimensValue(specimensV);
      } else {
        setSpecimensValue(null);
      }
    } else {
      setServiceGroupValue(null);
      setSpecimensValue(null);
    }
  }, [isUpdate]);

  useEffect(() => {}, []);

  return (
    <Modal
      size={'md'}
      isOpen={isModalVisible}
      className="modal-dialog-centered modal-secondary"
      toggle={() => {
        handleClose();
      }}
    >
      <div className="modal-header border-bottom bg-white pb-2">
        <h2>{isUpdate ? 'Cập nhật' : 'Thêm mới'} dịch vụ</h2>
        <button
          aria-label="Close"
          className="close"
          data-dismiss="modal"
          type="button"
          onClick={() => {
            handleClose();
          }}
        >
          <span style={{ color: '#262626' }} aria-hidden={true}>
            ×
          </span>
        </button>
      </div>
      <div className="modal-body bg-white rounded">
        <Formik
          initialValues={initInfo}
          // enableReinitialize
          onSubmit={onSubmit}
          validationSchema={infoSchema}
        >
          {({
            values,
            handleSubmit,
            errors,
            touched,
            handleBlur,
            handleChange,
            setFieldValue,
          }) => {
            return (
              <Form onSubmit={handleSubmit} className="">
                <Row>
                  <Col xs={6}>
                    <InputCustom
                      name="code"
                      label="Mã dịch vụ"
                      type="text"
                      placeholder="Nhập mã dịch vụ"
                      value={values.code}
                      isRequired={true}
                      handleChange={handleChange}
                      handleBlur={handleBlur}
                      error={errors?.code}
                      touched={touched?.code}
                      disabled={isUpdate}
                    />
                  </Col>
                  <Col xs={6}>
                    <SelectCustom
                      name="serviceGroup"
                      label="Nhóm dịch vụ"
                      placeholder="Lựa chọn"
                      value={serviceGroupValue}
                      isRequired={false}
                      isClearable={true}
                      handleChange={(e) => {
                        setServiceGroupValue(e);
                        setFieldValue('serviceGroup', e?.value ?? null);
                      }}
                      handleBlur={handleBlur}
                      error={errors.serviceGroup}
                      touched={touched.serviceGroup}
                      options={resultsServiceGroup.map((item) => ({
                        label: `${item?.name ?? ''}(${item?.code ?? ''})`,
                        value: item?.id,
                      }))}
                      onInputChange={(value) => {
                        setServiceGroupSearch(value);
                      }}
                    />
                  </Col>
                </Row>

                <Row>
                  <Col xs={12}>
                    <InputCustom
                      name="name"
                      label="Tên dịch vụ"
                      type="text"
                      placeholder="Nhập vào tên dịch vụ"
                      value={values.name}
                      isRequired={true}
                      handleChange={handleChange}
                      handleBlur={handleBlur}
                      error={errors?.name}
                      touched={touched?.name}
                    />
                  </Col>
                </Row>

                <Row>
                  <Col xs={6}>
                    <InputCustom
                      name="time"
                      label="Thời gian làm việc (ngày)"
                      type="number"
                      placeholder="Nhập thời gian làm việc"
                      value={values.time}
                      isRequired={true}
                      handleChange={handleChange}
                      handleBlur={handleBlur}
                      error={errors?.time}
                      touched={touched?.time}
                    />
                  </Col>
                  <Col xs={6}>
                    <InputCustom
                      name="unitPrice"
                      label="Đơn giá (VNĐ)"
                      type="number"
                      placeholder="Nhập đơn giá"
                      value={values.unitPrice}
                      isRequired={true}
                      handleChange={handleChange}
                      handleBlur={handleBlur}
                      error={errors?.unitPrice}
                      touched={touched?.unitPrice}
                    />
                  </Col>
                </Row>
                <Row>
                  <Col xs={12}>
                    <SelectCustom
                      name="specimens"
                      label="Mẫu xét nghiệm"
                      placeholder="Chọn mẫu"
                      value={specimensValue}
                      isRequired={false}
                      isClearable={false}
                      handleChange={(e, remove) => {
                        if (_.isEmpty(remove?.removedValue)) {
                          setSpecimensValue(e);
                          setFieldValue(
                            'specimens',
                            e.map((item) => item.value)
                          );
                        } else {
                          const tmp = specimensValue.filter(
                            (item) => item.value !== remove.removedValue.value
                          );
                          setSpecimensValue(tmp);
                          const speciments = values.specimens.filter(
                            (item) => item !== remove.removedValue.value
                          );

                          setFieldValue('specimens', speciments);
                        }
                      }}
                      handleBlur={handleBlur}
                      onInputChange={(value) => {}}
                      error={errors.specimens}
                      touched={touched.specimens}
                      options={CONSTANT.SPECIMENS}
                      isMulti={true}
                    />
                  </Col>
                </Row>

                <Row>
                  <Col xs={12}>
                    <InputCustom
                      name="content"
                      label="Nội dung xét nghiệm"
                      type="textarea"
                      rows={4}
                      placeholder="Nhập nội dung"
                      value={values.content}
                      isRequired={true}
                      handleChange={handleChange}
                      handleBlur={handleBlur}
                      error={errors?.content}
                      touched={touched?.content}
                    />
                  </Col>
                </Row>
                {/* <Row>
                  <Col xs={12}>
                    <SelectCustom
                      name="contentXns"
                      label="Loại xét nghiệm"
                      placeholder="Chọn loại"
                      value={specimensValue}
                      isRequired={false}
                      isClearable={false}
                      handleChange={(e, remove) => {
                        if (_.isEmpty(remove?.removedValue)) {
                          setSpecimensValue(e);
                          setFieldValue(
                            'contentXns',
                            e.map((item) => item.value)
                          );
                        } else {
                          const tmp = specimensValue.filter(
                            (item) => item.value !== remove.removedValue.value
                          );
                          setSpecimensValue(tmp);
                          const speciments = values.contentXns.filter(
                            (item) => item !== remove.removedValue.value
                          );

                          setFieldValue('contentXns', speciments);
                        }
                      }}
                      handleBlur={handleBlur}
                      onInputChange={(value) => {}}
                      error={errors.contentXns}
                      touched={touched.contentXns}
                      options={[]}
                      isMulti={true}
                    />
                  </Col>
                </Row> */}
                <Row>
                  <Col xs={12}>
                    <InputCustom
                      name="note"
                      label="Mô tả"
                      type="textarea"
                      rows={4}
                      placeholder="Nhập mô tả"
                      value={values.note}
                      isRequired={false}
                      handleChange={handleChange}
                      handleBlur={handleBlur}
                      error={errors?.note}
                      touched={touched?.note}
                    />
                  </Col>
                </Row>

                <div className="mb-0 pb-0 mt-4 d-flex justify-content-end">
                  <Button
                    className="text-nowrap mr-1"
                    onClick={() => {
                      handleClose();
                    }}
                  >
                    Hủy
                  </Button>
                  <LoadingButtonCustom
                    onClick={handleSubmit}
                    color="info"
                    className="text-nowrap "
                    type="submit"
                    loading={isCreatingService || isUpdatingService}
                  >
                    {isUpdate ? 'Cập nhật' : 'Thêm mới'}
                  </LoadingButtonCustom>
                </div>
              </Form>
            );
          }}
        </Formik>
      </div>
    </Modal>
  );
};

export default memo(CService);
