import classNames from 'classnames';
import _ from 'lodash';
import React, { memo } from 'react';
import * as uuid from 'uuid';

import SwitchCustom from 'views/pages/components/SwitchCustom';

const TableRow = ({
  item = {},
  index = -1,
  indexP = 0,
  body,
  handleChecked = () => {},
  handleCheckedMulti = () => {},
  isChild = false,
}) => {
  const codes = item.codes;
  const child = _.get(item, 'Child', []);
  return (
    <tr
      style={{
        background: indexP % 2 === 0 ? '#f8f9fe' : 'white',
      }}
    >
      <td
        className={classNames({
          'pl-4': isChild,
        })}
      >
        {isChild ? `${indexP + 1}.${index + 1}` : index + 1}
      </td>
      <td
        className={classNames({
          'pl-5': isChild,
          'text-capitalize': true,
        })}
      >
        {item?.name ?? ''}
      </td>

      {codes.map((v) => {
        if (v === '') return <td key={uuid.v4().toString()} />;
        const values = child.map((c) => `${v}-${c?.code ?? ''}`);
        const isChecked = _.isEmpty(body)
          ? false
          : _.difference(values, body).length === 0;
        return (
          <td key={uuid.v4().toString()}>
            <div className="d-flex justify-content-center">
              <SwitchCustom
                className="custom-toggle custom-toggle-primary mr-1"
                value={v}
                labelOn="Bật"
                labelOff={'Tắt'}
                checked={
                  isChild || _.isEmpty(child)
                    ? body.includes(`${v}-${item?.code ?? ''}`)
                    : isChecked
                }
                onChange={(e) => {
                  if (isChild || _.isEmpty(child)) {
                    handleChecked(`${v}-${item?.code ?? ''}`, e.target.checked);
                  } else {
                    handleCheckedMulti(values, e.target.checked);
                  }
                }}
              />
            </div>
          </td>
        );
      })}
    </tr>
  );
};

export default memo(TableRow);
