import { combineReducers } from 'redux';
import accountReducer from './accountReducer';
import roleReducer from './roleReducer';
import formReducer from './formReducer';
import timelineReducer from './timelineReducer';
import notificationReducer from './notificationReducer';
import uploadReducer from './uploadReducer';
import areaReducer from './areaReducer';
import barcodeReducer from './barcodeReducer';
import organizationReducer from './organizationReducer';
import staffReducer from './staffReducer';
import serviceReducer from './serviceReducer';
import serviceGroupReducer from './serviceGroupReducer';
import promotionReducer from './promotionReducer';
import partnerReducer from './partnerReducer';
import provinceReducer from './provinceReducer';
import specifyvoteReducer from './specifyVoteReducer';
import filterReducer from './filterReducer';
import promotionDetailReducer from './promotionDetailReducer';
import patientReducer from './patientReducer';
import relationReducer from './relationReducer';
import orderReducer from './orderReducer';
import medicalRecordReducer from './medicalRecordReducer';
import paymentHistoryReducer from './paymentHistoryReducer';
import billReducer from './billReducer';
import resultReducer from './resultReducer';
import convertFileReducer from './convertFileReducer';
import templatePCDReducer from './templatePCDReducer';
import serviceDetailReducer from './serviceDetailReducer';
import authorReducer from './authorReducer';
import contentXnReducer from './contentXnReducer';
import statisticReducer from './statisticReducer';
import roleUIReducer from './roleUIReducer';
import additionalSampleReducer from './additionalSampleReducer';

const rootReducers = combineReducers({
  accountReducer,
  roleReducer,
  formReducer,
  timelineReducer,
  notificationReducer,
  uploadReducer,
  areaReducer,
  barcodeReducer,
  organizationReducer,
  staffReducer,
  serviceReducer,
  serviceGroupReducer,
  promotionReducer,
  partnerReducer,
  provinceReducer,
  specifyvoteReducer,
  filterReducer,
  promotionDetailReducer,
  patientReducer,
  relationReducer,
  orderReducer,
  medicalRecordReducer,
  paymentHistoryReducer,
  billReducer,
  resultReducer,
  convertFileReducer,
  templatePCDReducer,
  serviceDetailReducer,
  authorReducer,
  contentXnReducer,
  statisticReducer,
  roleUIReducer,
  additionalSampleReducer,
});
export default rootReducers;
