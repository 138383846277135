import typeActions from 'redux/typeActions';

const uploadFiles = (body, callback) => {
  return {
    type: typeActions.UPLOAD_AVATAR_REQUEST,
    body,
    callback,
  };
};

const deleteFile = (param, callback) => {
  return {
    type: typeActions.DELETE_AVATAR_REQUEST,
    param,
    callback,
  };
};

export default {
  uploadFiles,
  deleteFile,
};
