//core-componens
import React, { useCallback, useEffect, useState } from 'react';
import {
  Button,
  Card,
  CardBody,
  CardHeader,
  Col,
  Container,
  Input,
  Row,
} from 'reactstrap';
import Select from 'react-select';
import DatePicker from 'react-datepicker';
//custom components
import LabelCustom from 'views/pages/components/LabelCustom';
import InputCustom from 'views/pages/components/InputCustom';
import SelectCustom from 'views/pages/components/SelectCustom';
//module
import CONSTANT from 'constant';
import classNames from 'classnames';
import * as yup from 'yup';
import _ from 'lodash';
import queryString from 'query-string';
import { getListSelectOption } from 'common';
import { notificationAlertRef, notify } from 'common';
//styles
import 'assets/css/custom-pages/specify-votes.css';
import 'assets/css/custom-pages/create-specify-votes.css';
import { Formik, useFormikContext } from 'formik';
import { useDispatch, useSelector } from 'react-redux';
import { useDelayInputEffect } from 'utils/HookUtils';
import {
  accountActions,
  organizationActions,
  patientActions,
  relationActions,
  serviceActions,
} from 'redux/actions';
import HelperText from 'views/pages/components/HelperText';
import LabelValue from 'views/pages/components/LabelValue';
import moment from 'moment';
const rightAlignText = {
  textAlign: 'right',
  paddingRight: 0,
};
function GeneralInfomationRight({ specifyVotes }) {
  return (
    <Col lg={6} xs={12} className="pl-4">
      <Row className="mb-0">
        <Col xs={12}>
          <Row>
            <Col xs={12}>
              <LabelCustom
                label="Thông tin xét nghiệm"
                horizontal={true}
                style={{
                  fontSize: '17px',
                  fontSize: '14px',
                  fontWeight: 400,
                  lineHeight: '19px',
                  textTransform: 'uppercase',
                }}
              />
            </Col>
          </Row>
          <Row>
            <Col xs={12} className="customSelect">
              <LabelValue
                lableTextAlign={'right'}
                label={'Mã xét nghiệm '}
                labelWidth={120}
                value={specifyVotes?.codeTest ?? ''}
                row={true}
              />
            </Col>
          </Row>
          <Row>
            <Col xs={12}>
              <LabelValue
                lableTextAlign={'right'}
                label={'Tên xét nghiệm '}
                labelWidth={120}
                value={specifyVotes?.nameTest ?? ''}
                row={true}
              />
            </Col>
          </Row>
          <Row>
            <Col xs={12}>
              <LabelValue
                lableTextAlign={'right'}
                label={'Nội dung xét nghiệm '}
                labelWidth={120}
                value={specifyVotes?.content ?? ''}
                row={true}
              />
            </Col>
          </Row>
          <Row>
            <Col xs={12} className="customSelect pr-0 ">
              <LabelValue
                lableTextAlign={'right'}
                label={'Mẫu xét nghiệm '}
                labelWidth={120}
                value={
                  specifyVotes?.specimens
                    ? CONSTANT.SPECIMENS.filter((spe) => {
                        const specimensId = specifyVotes.specimens ?? [];
                        return specimensId.includes(spe.value);
                      })?.map(
                        (_, index, arr) =>
                          _.label + (index === arr.length - 1 ? '.' : ', ')
                      )
                    : ''
                }
                row={true}
              />
            </Col>
          </Row>
          <Row>
            <Col xs={12} className="customSelect pr-0 ">
              <LabelValue
                lableTextAlign={'right'}
                label={'Số lượng phôi'}
                labelWidth={120}
                value={specifyVotes?.numOfEmbryos ?? ''}
                row={true}
              />
            </Col>
          </Row>
          <Row>
            <Col xs={12} className="customSelect">
              <div>
                <LabelValue
                  lableTextAlign={'right'}
                  label={'Phòng khám'}
                  labelWidth={120}
                  value={specifyVotes?.nameTC ?? ''}
                  row={true}
                />
              </div>
            </Col>
          </Row>
          <Row>
            <Col xs={12} className="customSelect">
              <div>
                <LabelValue
                  lableTextAlign={'right'}
                  label={'Bác sĩ chỉ định'}
                  labelWidth={120}
                  value={
                    (specifyVotes?.lastNameBS ?? '') +
                    ' ' +
                    (specifyVotes?.firstNameBS ?? '')
                  }
                  row={true}
                />
              </div>
            </Col>
          </Row>
          <Row>
            <Col xs={12}>
              <LabelValue
                lableTextAlign={'right'}
                label={'Nơi thu mẫu'}
                labelWidth={120}
                value={specifyVotes?.whereTM ?? ''}
                row={true}
              />
            </Col>
          </Row>
          <Row>
            <Col xs={6}>
              <LabelValue
                lableTextAlign={'right'}
                label={'Ngày thu mẫu'}
                labelWidth={120}
                value={moment(
                  new Date(specifyVotes?.dateTM ?? new Date())
                ).format('DD/MM/YYYY')}
                row={true}
              />
            </Col>
            <Col xs={6}>
              <LabelValue
                lableTextAlign={'right'}
                label={'Giờ thu mẫu'}
                labelWidth={80}
                value={moment(
                  new Date(specifyVotes?.dateTM ?? new Date())
                ).format('HH:mm')}
                row={true}
              />
            </Col>
          </Row>
        </Col>
      </Row>
    </Col>
  );
}

export default GeneralInfomationRight;
