import React, { memo, useEffect, useRef, useState } from 'react';
import paginationFactory from 'react-bootstrap-table2-paginator';
import ReactBSAlert from 'react-bootstrap-sweetalert';
import _ from 'lodash';
import {
  Badge,
  Container,
  Row,
  UncontrolledTooltip,
  FormGroup,
  Label,
  Input,
} from 'reactstrap';
import queryString from 'query-string';
import { useDispatch, useSelector } from 'react-redux';
import classNames from 'classnames';

import {
  orderActions,
  formActions,
  specifyVotesActions,
  areaActions,
  filterActions,
} from 'redux/actions';
import SimpleHeader from 'components/Headers/SimpleHeader.js';
import 'assets/css/custom-pages/react-bs-table.css';
import { useDidUpdateEffect } from 'utils/HookUtils';
import CONSTANT from 'constant';

import IconChecked from 'assets/svgs/checked.svg';
import IconPrintDisable from 'assets/svgs/print_disable.svg';
import IconPrintPCD from 'assets/svgs/print_pcd.svg';
import moment from 'moment';
import TableCustom from '../components/TableCustom';
import TransferXN from './components/TransferXN';
import LoadingButtonCustom from '../components/LoadingButtonCustom';
import { checkRole, notificationAlertRef, notify } from 'common';
import UpdateStatusXN from './components/UpdateStatusXN';
import Filter from './components/Filter';
import ConfirmBarcode from './components/ConfirmBarcode';
import TransferOrder from './components/TransferOrder';

function SynthesisSampleTransfer() {
  const inputRef = useRef();
  const dispatch = useDispatch();
  const {
    page,
    limit,
    orders,
    isUpdatingOrder,
    isGettingOrders,
    status,
    querySynthesisSampleTransfer,
  } = useSelector((state) => state.orderReducer);
  const { isPrintSpecifyVote } = useSelector(
    (state) => state.specifyvoteReducer
  );
  const { areasByAccountExtend } = useSelector((state) => state.areaReducer);
  const { currentAccountExtend, isBusinessStaff } = useSelector(
    (state) => state.accountReducer
  );
  const [alert, setAlert] = useState(null);
  // const [status, setStatus] = useState(null);
  const [rowsSelected, setRowSelected] = useState([]);
  const results = _.get(orders, 'results', []);
  const total = _.get(orders, 'pagination.total', 0);
  const dontShowStatus = [
    CONSTANT.ORDER_STATUS[0].value,
    CONSTANT.ORDER_STATUS[1].value,
    CONSTANT.ORDER_STATUS[2].value,
    CONSTANT.ORDER_STATUS[3].value,
    CONSTANT.ORDER_STATUS[4].value,
    CONSTANT.ORDER_STATUS[9].value,
    CONSTANT.ORDER_STATUS[10].value,
    CONSTANT.ORDER_STATUS[11].value,
  ];
  const dontShowSelectedItems = [
    null,
    CONSTANT.ORDER_STATUS[2].value,
    CONSTANT.ORDER_STATUS[5].value,
    CONSTANT.ORDER_STATUS[7].value,
  ];
  const [orderSearch, setOrderSearch] = useState('');

  function rowStyle(row, __) {
    if (status !== CONSTANT.ORDER_STATUS[6].value || !row.approveDate) {
      return {};
    }

    const diff = moment()
      .startOf('day')
      .diff(moment(row.expectedPay).startOf('day'), 'days');

    const style = {};
    if (diff > 0) {
      style.backgroundColor = '#F9E7E7'; // quá hạn
    } else if (diff <= 0 && diff >= -2) {
      style.backgroundColor = 'rgb(255 218 76)';
    }
    return style;
  }

  function cellStyle(__, row) {
    if (status !== CONSTANT.ORDER_STATUS[6].value || !row?.approveDate) {
      return {};
    }

    const diff = moment()
      .startOf('day')
      .diff(moment(row.expectedPay).startOf('day'), 'days');

    const style = { zIndex: 5 };
    if (diff > 0) {
      style.backgroundColor = '#F9E7E7'; // quá hạn
    } else if (diff <= 0 && diff >= -2) {
      style.backgroundColor = 'rgb(255 218 76)';
    }
    return style;
  }

  const columns = [
    {
      dataField: 'status',
      text: '',
      formatter: (cell, row) => {
        return cell === CONSTANT.ORDER_STATUS[4].value && row.approveStatus ? (
          <img src={IconChecked} className="" />
        ) : null;
      },
      headerStyle: {
        textAlign: 'center',
      },
      style: {
        textAlign: 'center',
      },
    },
    {
      dataField: 'code',
      text: 'MÃ',
      formatter: (cell, row) => {
        return CONSTANT.ORDER_STATUS[6].value === status
          ? `${cell}_${row.labCode}`
          : cell;
      },
    },
    {
      dataField: 'name',
      text: 'TÊN ĐƠN HÀNG',
    },
    {
      dataField: 'organization.name',
      text: 'PHÒNG KHÁM/ BỆNH VIỆN',
    },
    {
      dataField: 'specifyVote.nameTest',
      text: 'DỊCH VỤ',
    },
    {
      dataField: 'specifyVote',
      text: 'BÁC SĨ',
      formatter: (cell) => {
        return `${cell?.lastNameBS ?? ''} ${cell?.firstNameBS ?? ''} `;
      },
    },
    {
      dataField: 'areaName',
      text: 'KHU VỰC',
    },
    {
      dataField: 'codeStaff1',
      text: 'SALE',
      formatter: (cell) => {
        return `${cell?.fullname ?? ''} `;
      },
    },
    {
      dataField: 'codeStaff2',
      text: 'THU MẪU',
      formatter: (cell) => {
        return `${cell?.fullname ?? ''} `;
      },
    },
    {
      dataField: 'specifyVote',
      text: 'NGÀY CHỈ ĐỊNH',
      style: {
        textAlign: 'center',
      },
      formatter: (cell) => {
        return !!cell.createdAt
          ? moment(cell.createdAt).format('DD/MM/YYYY')
          : '';
      },
    },
    status === CONSTANT.ORDER_STATUS[6].value
      ? {
          dataField: 'approveDate',
          text: 'NGÀY DỰ KIẾN TRẢ KẾT QUẢ',
          style: {
            textAlign: 'center',
          },
          formatter: (cell, row) => {
            return !!cell
              ? moment(cell)
                  .add(row?.specifyVote?.timeTest ?? 0, 'days')
                  .format('DD/MM/YYYY')
              : '';
          },
        }
      : null,

    {
      dataField: 'bill.paymentStatus',
      text: 'THANH TOÁN',
      formatter: (cell) => {
        const paymentStatusV = CONSTANT.PAYMENT_STATUS.find(
          (item) => item.value === cell
        );

        return paymentStatusV?.label ?? '';
      },
      headerStyle: {
        textAlign: 'center',
      },
      style: {
        textAlign: 'center',
      },
    },
    {
      dataField: 'status',
      text: 'TRẠNG THÁI',
      formatter: (cell) => {
        const statsV = CONSTANT.ORDER_STATUS.find(
          (item) => item.value === cell
        );
        return !!statsV ? (
          <Badge color={statsV.color}>{statsV.label}</Badge>
        ) : (
          ''
        );
      },
      headerStyle: {
        textAlign: 'center',
      },
      style: {
        textAlign: 'center',
      },
    },
    {
      dataField: 'actions',
      text: 'HÀNH ĐỘNG',
      headerClasses: 'fixed-column',
      formatter: boxActions,
      headerStyle: {
        textAlign: 'center',
        zIndex: 3,
      },
      style: cellStyle,
      cellStyle: cellStyle,
      classes: 'fixed-column',
    },
  ].filter((item) => item !== null);

  function boxActions(__, row) {
    return (
      <div className="d-flex justify-content-center">
        {row?.status === CONSTANT.ORDER_STATUS[5].value ||
        row?.status === CONSTANT.ORDER_STATUS[6].value ||
        row?.status === CONSTANT.ORDER_STATUS[7].value ||
        row?.status === CONSTANT.ORDER_STATUS[8].value ? (
          <>
            <button
              id={'print' + row.id}
              onClick={() => {
                handlePrintPCD(row?.specifyVote?.id ?? -1);
              }}
              className="btn p-0 btn-no-shadow mr-0"
            >
              <img src={IconPrintPCD} className="mr-1" />
            </button>
            <UncontrolledTooltip
              delay={1}
              placement="bottom"
              target={'print' + row.id}
            >
              In phiếu xét nghiệm
            </UncontrolledTooltip>
          </>
        ) : (
          <button
            id={'print' + row.id}
            onClick={() => {}}
            className="btn p-0 btn-no-shadow mr-0"
          >
            <img src={IconPrintDisable} className="mr-1" />
          </button>
        )}
      </div>
    );
  }

  const handlePrintPCD = (id) => {
    if (id === -1) return;
    dispatch(
      specifyVotesActions.printSpecifyVote(id, {
        success: (data) => {
          var blobURL = URL.createObjectURL(data);
          window.open(blobURL);
        },
        failed: (mess) => {
          notify(
            notificationAlertRef,
            'danger',
            'Thông báo',
            `In phiếu xét nghiệm lỗi!`
          );
        },
      })
    );
  };

  const onSizePerPageChange = (e) => {
    dispatch(orderActions.setValue({ name: 'page', value: 1 }));
    dispatch(orderActions.setValue({ name: 'limit', value: e.target.value }));
  };

  const onPageChange = (value) => {
    dispatch(orderActions.setValue({ name: 'page', value: value }));
  };

  const pagination = paginationFactory({
    page: page,
    sizePerPage: limit,
    totalSize: total,
    showTotal: false,
    withFirstAndLast: false,
    alwaysShowAllBtns: true,
    onPageChange: onPageChange,
    sizePerPageRenderer: () => {
      return (
        <div className="dataTables_length" id="datatable-basic_length">
          <label>
            Hiển thị{' '}
            {
              <select
                name="datatable-basic_length"
                aria-controls="datatable-basic"
                className="form-control form-control-sm"
                onChange={onSizePerPageChange}
              >
                <option value="10">10</option>
                <option value="25">25</option>
                <option value="50">50</option>
                <option value="100">100</option>
              </select>
            }{' '}
            dòng.
          </label>
        </div>
      );
    },
  });

  useDidUpdateEffect(() => {
    handleGetOrders();
  }, [page, limit]);

  useDidUpdateEffect(() => {
    // Sau 500ms thì bắn api
    const delayDebounceFn = setTimeout(() => {
      handleGetOrders();
    }, 500);
    return () => clearTimeout(delayDebounceFn);
  }, [orderSearch, status, querySynthesisSampleTransfer]);

  useEffect(() => {
    handleGetOrders();
  }, [areasByAccountExtend]);

  const handleGetOrders = () => {
    setRowSelected([]);
    const query = Object.assign(
      {
        pageSize: limit,
        page,
        ...querySynthesisSampleTransfer,
        _q: orderSearch,
      },
      status === CONSTANT.ORDER_STATUS[6].value
        ? { sort: 'expectedPay:ASC' }
        : {}
    );
    // querySynthesisSampleTransferOrigin
    if (status === null) {
      dontShowStatus.forEach((item, index) => {
        if (
          item !== CONSTANT.ORDER_STATUS[3].value &&
          item !== CONSTANT.ORDER_STATUS[2].value &&
          item !== CONSTANT.ORDER_STATUS[1].value
        ) {
          query[`filters[$and][${index + 1}][status][$ne]`] = item;
        }
      });
      query['filters[$and][4][receptionStatus][$eq]'] = true;
    } else if (status === 'received') {
      // receptionStatus
      query['filters[$and][2][receptionStatus][$eq]'] = true;
      query['filters[$and][3][$or][0][status][$eq]'] =
        CONSTANT.ORDER_STATUS[4].value;
      query['filters[$and][3][$or][1][status][$eq]'] =
        CONSTANT.ORDER_STATUS[3].value;
      query['filters[$and][3][$or][2][status][$eq]'] =
        CONSTANT.ORDER_STATUS[2].value;
      query['filters[$and][3][$or][3][status][$eq]'] =
        CONSTANT.ORDER_STATUS[1].value;
    } else {
      query['filters[$and][1][status][$eq]'] = status;
    }
    if (!isBusinessStaff) {
      areasByAccountExtend.forEach((item, index) => {
        query[`filters[$and][4][$or][${index}][areaName][$eq]`] = item.name;
      });
    }
    dispatch(
      filterActions.setValueFilter({
        name: 'queryPage',
        value: query,
      })
    );
    dispatch(
      orderActions.getOrders(
        queryString.stringify(query, {
          skipEmptyString: true,
          skipNull: true,
        }),
        {
          success: () => {},
        }
      )
    );
  };
  const selectRow = {
    mode: 'checkbox',
    selectColumnPosition: 'left',
    onSelect: (row, isSelect) => {
      try {
        if (isSelect) {
          setRowSelected((prev) => _.uniqBy([...prev, row], 'id'));
        } else {
          const tmp = rowsSelected.filter((item) => item.id !== row.id);
          setRowSelected(_.uniqBy(tmp));
        }
      } catch (error) {}
    },
    onSelectAll: (isSelect, rows) => {
      if (isSelect) {
        setRowSelected(rows);
      } else {
        setRowSelected([]);
      }
    },
    selectionHeaderRenderer: ({ indeterminate, ...rest }) => (
      <div className="" style={{ verticalAlign: 'inherit' }}>
        <input
          type="checkbox"
          style={{ height: 'unset', verticalAlign: 'inherit' }}
          ref={(input) => {
            if (input) input.indeterminate = indeterminate;
          }}
          {...rest}
        />
      </div>
    ),
    selectionRenderer: ({ mode, ...rest }) => (
      <div>
        <input type={mode} {...rest} />
      </div>
    ),
    selected: rowsSelected.map((item) => item.id),
  };

  const headerLeftComponent = () => {
    return (
      <FormGroup
        onChange={(e) => {
          dispatch(
            orderActions.setValue({
              name: 'status',
              value:
                e.target.value === 'all'
                  ? null
                  : e.target.value === 'received'
                  ? 'received'
                  : Number(e.target.value),
            })
          );
          dispatch(orderActions.setValue({ name: 'page', value: 1 }));
          dispatch(orderActions.setValue({ name: 'limit', value: 10 }));
        }}
        className="d-flex align-items-center radio-button-custom"
      >
        <FormGroup check className={classNames({ 'mr-3': true })}>
          <Label check style={{ fontSize: 13 }}>
            <Input
              checked={status === null}
              value={'all'}
              type="radio"
              name="status"
            />{' '}
            Tất cả
          </Label>
        </FormGroup>
        <FormGroup check className={classNames({ 'mr-3': true })}>
          <Label check style={{ fontSize: 13 }}>
            <Input
              checked={status === 'received'}
              value={'received'}
              type="radio"
              name="status"
            />{' '}
            Đã tiếp nhận
          </Label>
        </FormGroup>
        {CONSTANT.ORDER_STATUS.filter(
          (item) => !dontShowStatus.includes(item.value)
        ).map((item, index, self) => {
          return (
            <FormGroup
              check
              className={classNames({ 'mr-3': index !== self.length - 1 })}
            >
              <Label check style={{ fontSize: 13 }}>
                <Input
                  checked={status === item.value}
                  value={item.value}
                  type="radio"
                  name="status"
                />{' '}
                {item.label}
              </Label>
            </FormGroup>
          );
        })}
      </FormGroup>
    );
  };
  const headerSubComponent = () => {
    if (
      !checkRole(currentAccountExtend, {
        roles: ['update-synthesis-sample-transfer'],
      })
    ) {
      return;
    }
    switch (status) {
      case CONSTANT.ORDER_STATUS[2].value:
        return;
      case 'received':
        return (
          <LoadingButtonCustom
            onClick={() => {
              if (rowsSelected.length === 0)
                return notify(
                  notificationAlertRef,
                  'warning',
                  'Thông báo',
                  `Vui lòng chọn mẫu bổ sung cần chuyển xét nghiệm!`
                );
              const uniqStatus = _.uniq(
                rowsSelected.map((item) => item.status)
              );
              if (
                uniqStatus.includes(CONSTANT.ORDER_STATUS[4].value) &&
                uniqStatus.length > 1
              )
                return notify(
                  notificationAlertRef,
                  'warning',
                  'Thông báo',
                  `Vui lòng chọn toàn bộ là đơn hàng đủ điều kiện chuyển đến lap hoặc toàn bộ đơn hàng không đủ điều kiện chuyển đến lap!`
                );
              dispatch(
                formActions.setValue({
                  name: 'isModalTranferOrderVisible',
                  value: true,
                })
              );
              dispatch(
                formActions.setValue({
                  name: 'modalInfo',
                  value: { ids: rowsSelected.map((item) => item.id) },
                })
              );
            }}
            color="info"
            className="text-nowrap "
            type="button"
            loading={false}
          >
            Điều chuyển
          </LoadingButtonCustom>
        );
      case CONSTANT.ORDER_STATUS[3].value:
        return (
          <LoadingButtonCustom
            onClick={() => {
              if (rowsSelected.length === 0)
                return notify(
                  notificationAlertRef,
                  'warning',
                  'Thông báo',
                  `Vui lòng chọn đơn hàng cần chuyển xét nghiệm!`
                );
              dispatch(
                formActions.setValue({
                  name: 'isModalVisible',
                  value: true,
                })
              );
              dispatch(
                formActions.setValue({
                  name: 'modalInfo',
                  value: { ids: rowsSelected.map((item) => item.id) },
                })
              );
            }}
            color="info"
            className="text-nowrap "
            type="button"
            loading={isUpdatingOrder}
          >
            Chuyển xét nghiệm
          </LoadingButtonCustom>
        );
      // case CONSTANT.ORDER_STATUS[4].value:
      //   return (
      //     <LoadingButtonCustom
      //       onClick={() => {
      //         if (rowsSelected.length === 0)
      //           return notify(
      //             notificationAlertRef,
      //             'warning',
      //             'Thông báo',
      //             `Vui lòng chọn đơn hàng cần chuyển xét nghiệm!`
      //           );
      //         dispatch(
      //           formActions.setValue({
      //             name: 'isModalVisible',
      //             value: true,
      //           })
      //         );
      //         dispatch(
      //           formActions.setValue({
      //             name: 'modalInfo',
      //             value: { ids: rowsSelected.map((item) => item.id) },
      //           })
      //         );
      //       }}
      //       color="info"
      //       className="text-nowrap "
      //       type="button"
      //       loading={isUpdatingOrder}
      //     >
      //       Chuyển xét nghiệm
      //     </LoadingButtonCustom>
      //   );

      case CONSTANT.ORDER_STATUS[5].value:
        return (
          <>
            <input
              ref={inputRef}
              type="file"
              accept=".xlsx"
              style={{ display: 'none' }}
              onChange={async (e) => {
                try {
                  if (_.isEmpty(e)) return;
                  handleUpdateLabCodes(e.target.files[0]);
                } catch (error) {
                  //
                }
              }}
            />

            <LoadingButtonCustom
              onClick={() => {
                inputRef.current.click();
              }}
              color="info"
              className="text-nowrap "
              type="button"
            >
              Upload file
            </LoadingButtonCustom>
          </>
        );
      case CONSTANT.ORDER_STATUS[6].value:
        return (
          <LoadingButtonCustom
            onClick={() => {
              if (rowsSelected.length === 0)
                return notify(
                  notificationAlertRef,
                  'warning',
                  'Thông báo',
                  `Vui lòng chọn đơn hàng cần cập nhật trạng thái!`
                );
              dispatch(
                formActions.setValue({
                  name: 'isModalVisible',
                  value: true,
                })
              );
              dispatch(
                formActions.setValue({
                  name: 'modalInfo',
                  value: { ids: rowsSelected.map((item) => item.id) },
                })
              );
            }}
            color="info"
            className="text-nowrap "
            type="button"
            loading={isUpdatingOrder}
          >
            Cập nhật trạng thái
          </LoadingButtonCustom>
        );
      case CONSTANT.ORDER_STATUS[7].value:
        return;
      // return (
      //   <LoadingButtonCustom
      //     onClick={() => {
      //       if (rowsSelected.length === 0)
      //         return notify(
      //           notificationAlertRef,
      //           'warning',
      //           'Thông báo',
      //           `Vui lòng chọn đơn hàng đã nhận mẫu!`
      //         );
      //       infoAlert2();
      //     }}
      //     color="info"
      //     className="text-nowrap "
      //     type="button"
      //     loading={isUpdatingOrder}
      //   >
      //     Đã nhận mẫu
      //   </LoadingButtonCustom>
      // );
      case CONSTANT.ORDER_STATUS[8].value:
        return (
          <LoadingButtonCustom
            onClick={() => {
              if (rowsSelected.length === 0)
                return notify(
                  notificationAlertRef,
                  'warning',
                  'Thông báo',
                  `Vui lòng chọn đơn hàng đã nhận mẫu!`
                );
              infoAlert1();
            }}
            color="info"
            className="text-nowrap "
            type="button"
            loading={isUpdatingOrder}
          >
            Cập nhật trạng thái
          </LoadingButtonCustom>
        );
    }
  };

  const handleConfirm1 = () => {
    const body = {
      status: CONSTANT.ORDER_STATUS[6].value,
      ids: rowsSelected.slice(1).map((item) => item.id),
    };
    dispatch(
      orderActions.updateOrder(rowsSelected[0].id, body, {
        success: () => {
          notify(
            notificationAlertRef,
            'success',
            'Thông báo',
            `Cập nhật đơn hàng thành công!`
          );
          handleGetOrders();
          setAlert(null);
        },
        failed: (mess) => {
          notify(
            notificationAlertRef,
            'danger',
            'Thông báo',
            `Cập nhật đơn hàng thất bại. Lỗi: ${mess}!`
          );
          setAlert(null);
        },
      })
    );
  };

  const infoAlert1 = () => {
    setAlert(
      <ReactBSAlert
        info
        style={{ display: 'block', marginTop: '-100px' }}
        title="Thông báo"
        onConfirm={() => {
          handleConfirm1();
        }}
        onCancel={() => setAlert(null)}
        confirmBtnBsStyle="info"
        confirmBtnText="Xác nhận"
        cancelBtnText="Huỷ"
        btnSize=""
      >
        XÁC NHẬN ĐỐI TÁC ĐÃ TIẾP NHẬN {rowsSelected.length} MẪU XÉT NGHIỆM
      </ReactBSAlert>
    );
  };

  const handleConfirm2 = () => {
    const body = {
      status: CONSTANT.ORDER_STATUS[1].value,
      ids: rowsSelected.slice(1).map((item) => item.id),
    };
    dispatch(
      orderActions.updateOrder(rowsSelected[0].id, body, {
        success: () => {
          notify(
            notificationAlertRef,
            'success',
            'Thông báo',
            `Cập nhật đơn hàng thành công!`
          );
          handleGetOrders();
          setAlert(null);
        },
        failed: (mess) => {
          notify(
            notificationAlertRef,
            'danger',
            'Thông báo',
            `Cập nhật đơn hàng thất bại. Lỗi: ${mess}!`
          );
          setAlert(null);
        },
      })
    );
  };

  // const infoAlert2 = () => {
  //   setAlert(
  //     <ReactBSAlert
  //       info
  //       style={{ display: 'block', marginTop: '-100px' }}
  //       title="Thông báo"
  //       onConfirm={() => {
  //         handleConfirm2();
  //       }}
  //       onCancel={() => setAlert(null)}
  //       confirmBtnBsStyle="info"
  //       confirmBtnText="Xác nhận"
  //       cancelBtnText="Huỷ"
  //       btnSize=""
  //     >
  //       XÁC NHẬN ĐỐI TÁC ĐÃ TIẾP NHẬN {rowsSelected.length} MẪU XÉT NGHIỆM
  //     </ReactBSAlert>
  //   );
  // };

  const handleUpdateLabCodes = (file) => {
    dispatch(
      orderActions.updateLabCodes(file, {
        success: () => {
          notify(
            notificationAlertRef,
            'success',
            'Thông báo',
            `Đã cập nhật labcodes`
          );
        },
        failed: (mess) => {
          notify(
            notificationAlertRef,
            'danger',
            'Thông báo',
            `Tải file lên lỗi: File không hợp lệ!`
          );
        },
      })
    );
  };

  return (
    <>
      <SimpleHeader />
      <ConfirmBarcode />
      <TransferOrder
        handleGetOrders={handleGetOrders}
        isDepartment={
          !_.uniq(rowsSelected.map((item) => item.status)).includes(
            CONSTANT.ORDER_STATUS[4].value
          )
        }
      />
      <Filter />
      {(status === CONSTANT.ORDER_STATUS[3].value ||
        status === CONSTANT.ORDER_STATUS[4].value ||
        status === 'received') && (
        <TransferXN handleGetOrders={handleGetOrders} />
      )}
      {CONSTANT.ORDER_STATUS[6].value === status && (
        <UpdateStatusXN handleGetOrders={handleGetOrders} />
      )}
      {alert}
      <Container className="mt--9" fluid>
        <Row>
          <div className="col">
            <TableCustom
              tableName="Danh sách"
              data={results}
              columns={columns}
              page={page}
              limit={limit}
              total={total}
              searchBy={'Tìm kiếm'}
              pagination={pagination}
              search={orderSearch}
              headerLeftComponent={headerLeftComponent}
              headerSubComponent={headerSubComponent}
              isHeaderSub={true}
              selectRow={selectRow}
              isHideSelectItems={dontShowSelectedItems.includes(status)}
              onChange={(e) => {
                setOrderSearch(e.target.value);
              }}
              rowStyle={rowStyle}
              isLoading={isPrintSpecifyVote || isGettingOrders}
            />
          </div>
        </Row>
      </Container>
    </>
  );
}

export default memo(SynthesisSampleTransfer);
