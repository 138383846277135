import React, { memo, useEffect, useState } from 'react';
import _ from 'lodash';
import {
  Button,
  Card,
  CardBody,
  CardHeader,
  Col,
  Container,
  Input,
  Row,
  Table,
} from 'reactstrap';
import queryString from 'query-string';

import SimpleHeader from 'components/Headers/SimpleHeader.js';
import 'assets/css/custom-pages/react-bs-table.css';
import { useDispatch, useSelector } from 'react-redux';
import LabelCustom from '../components/LabelCustom';
import {
  handleDownloadLink,
  notificationAlertRef,
  notify,
  relationOneToOne,
} from 'common';
import LoadingButtonCustom from '../components/LoadingButtonCustom';
import { useHistory, useParams } from 'react-router';
import ResultsXN from './components/ResultsXN';
import {
  accountActions,
  additionalSampleActions,
  authorActions,
  orderActions,
  resultActions,
  uploadActions,
} from 'redux/actions';
import LabelValue from '../components/LabelValue';
import moment from 'moment';
import InputCustom from '../components/InputCustom';
import CONSTANT from 'constant';
import { BASE_URL_IMAGE } from 'services/ServiceURL';
import IconArrowDown from 'assets/svgs/arrowdown.svg';
import IconBin from 'assets/svgs/bin.svg';
import SelectCustom from '../components/SelectCustom';
import ModalWarningCustom from '../components/ModalWarningCustom';

function ReturnResults({ location }) {
  const history = useHistory();
  const dispatch = useDispatch();
  const { id } = useParams();
  const { currentAccountExtend } = useSelector((state) => state.accountReducer);
  const { order, listType, fromPage } = location.state;
  const disable =
    fromPage === '/admin/approve-result' && order.orderType == 'appendix';

  const { authors } = useSelector((state) => state.authorReducer);
  const results = _.get(authors, 'results', []);
  const author = results.find((item) => item.code === 'BS_SIGNATURE') ?? {};
  const authorTech =
    results.find((item) => item.code === 'TECH_SIGNATURE') ?? {};
  let idSignatureAuthor = author?.author?.id ?? null;
  let idSignatureTechAuthor = authorTech?.author?.id ?? null;
  const [resultXN, setResultXN] = useState({});
  const [accountValue, setAccountValue] = useState(null);
  const [accountSearch, setAccountSearch] = useState('');
  const [accountResults, setAccountResults] = useState([]);
  const [conclusionFormValue, setConclusionFormValue] = useState(null);
  const [note, setNote] = useState('');
  const [notificationModal, setNotificationModal] = useState(false);
  const [fileResults, setFileResults] = useState([]);
  const [touched, setTouched] = useState({
    conclude: false,
    nameBS: false,
  });
  const [errors, setErrors] = useState({
    // conclude: '',
    // nameBS: '',
  });

  const handleGetOrder = () => {
    dispatch(orderActions.getOrder(id, queryString.stringify({})));
  };

  useEffect(() => {
    if (!id) return;
    handleGetOrder();
  }, [id]);
  useEffect(() => {
    if (!order) return;
    const resultsDetail = _.get(order, 'result', {});

    if (order?.orderType === 'nipt') {
      if (currentAccountExtend.accountType === CONSTANT.ACCOUNT_TYPE[4].value) {
        setResultXN({
          ...resultsDetail,
          author: {
            url: `${BASE_URL_IMAGE}${author?.author?.url ?? ''}`,
            id: idSignatureAuthor ?? null,
          },
          authorTech: {
            url: `${BASE_URL_IMAGE}${authorTech?.author?.url ?? ''}`,
            id: idSignatureTechAuthor ?? null,
          },
          nameBS: `(${currentAccountExtend?.code ?? ''})${
            currentAccountExtend?.fullname ?? ''
          }`,
        });
      } else {
        setResultXN({
          ...resultsDetail,
          author: {
            url: `${BASE_URL_IMAGE}${author?.author?.url ?? ''}`,
            id: idSignatureAuthor ?? null,
          },
          authorTech: {
            url: `${BASE_URL_IMAGE}${authorTech?.author?.url ?? ''}`,
            id: idSignatureTechAuthor ?? null,
          },
        });
      }
    }
  }, [order, author, authorTech]);

  const handleResultChange = (value, idx) => {
    try {
      const resultsDetail = resultXN.resultsDetail;
      if (_.isEmpty(resultsDetail)) return;
      resultsDetail[idx]['result'] = value;

      setResultXN({
        ...resultXN,
        resultsDetail,
      });
    } catch (error) {
      //
    }
  };
  const handleDeleteFile = () => {
    if (fileResults.length === 0) return;
    const fileResult = fileResults[fileResults.length - 1];

    dispatch(
      uploadActions.deleteFile(fileResult.id, {
        success: () => {
          setNotificationModal(false);
          setFileResults(
            _.uniqBy(
              [
                ...fileResults.slice(0, fileResults.length - 1),
                { ...fileResult, deleted: true },
              ],
              'id'
            )
          );
          notify(
            notificationAlertRef,
            'success',
            'Thông báo',
            `Đã xoá file: ${fileResult.name}!`
          );
        },
        failed: (mess) => {
          notify(
            notificationAlertRef,
            'danger',
            'Thông báo',
            `Xoá file ${fileResult.name} thất bại ${mess}!`
          );
        },
      })
    );
  };

  const handleReject = () => {
    if (order.orderType === 'nipt') {
      dispatch(uploadActions.deleteFile(order?.fileResult?.id));
      dispatch(resultActions.deleteResult(order?.result?.id));
    }
    handleUpdateOA(
      {
        status: CONSTANT.ORDER_STATUS[13].value,
        note,
      },
      { success: 'Từ chối thành công' }
    );
  };

  const handleSubmit = () => {
    if (order.orderType === 'appendix') {
      handleUpdateOA(
        {
          status: CONSTANT.ORDER_STATUS[9].value,
          note,
        },
        { success: 'Duyệt đơn hàng thành công!' }
      );
      return;
    }
    setTouched({
      conclude: true,
      nameBS: true,
    });
    const errorConclude = validateConclude(resultXN?.conclude);
    const errorNameBS = validateBS(resultXN?.nameBS);

    if (errorConclude || errorNameBS) return;
    if (
      !!resultXN?.author?.file &&
      resultXN?.author?.id === -1 &&
      !!resultXN?.authorTech?.file &&
      resultXN?.authorTech?.id === -1
    ) {
      // Thay đổi 2 chữ ký
      return handleUploadFiles([resultXN.author, resultXN.authorTech], 2);
    } else if (!!resultXN?.author?.file && resultXN?.author?.id === -1) {
      return handleUploadFiles([resultXN.author], 1);
    } else if (
      !!resultXN?.authorTech?.file &&
      resultXN?.authorTech?.id === -1
    ) {
      return handleUploadFiles([resultXN.authorTech], 0);
    }
    try {
      let isEmptyResult = false;
      if (!_.isEmpty(resultXN?.resultsDetail)) {
        resultXN.resultsDetail.every((r) => {
          if (r?.result === '') {
            isEmptyResult = true;
            return true;
          }
          return false;
        });
      }
      if (isEmptyResult)
        return notify(
          notificationAlertRef,
          'warning',
          'Thông báo',
          `Vui lòng nhập đầy đủ mục kết quả đơn hàng đã chọn!`
        );
    } catch (error) {
      return notify(
        notificationAlertRef,
        'warning',
        'Thông báo',
        `Lỗi dữ liệu!`
      );
    }
    handleUResult();
  };

  const handleUploadFiles = (files, type) => {
    const formData = new FormData();
    files.forEach((item) => {
      formData.append('files', item.file, item.file.name);
    });
    dispatch(
      uploadActions.uploadFiles(formData, {
        success: (data) => {
          switch (type) {
            case 0: // tech
              idSignatureTechAuthor = data[0].id ?? null;
              handleUResult();

              break;
            case 1: // bs
              idSignatureAuthor = data[0].id ?? null;
              handleUResult();
              break;
            case 2: // both
              idSignatureAuthor = data[0].id ?? null;
              idSignatureTechAuthor = data[1].id ?? null;
              handleUResult();

              break;
          }
        },
        failed: (mess) => {
          notify(
            notificationAlertRef,
            'danger',
            'Thông báo',
            `Tải ảnh lên thất bại!. Lỗi: ${mess}!`
          );
        },
      })
    );
  };

  const handleUResult = () => {
    dispatch(
      resultActions.updateResult(
        resultXN?.id ?? -1,
        {
          ...resultXN,
          author: idSignatureAuthor,
          authorTech: idSignatureTechAuthor,
        },
        {
          success: () => {
            notify(
              notificationAlertRef,
              'success',
              'Thông báo',
              `Lưu thành công!`
            );
            handleUpdateOA({
              status: CONSTANT.ORDER_STATUS[9].value,
            });
          },
          failed: (mess) => {
            notify(
              notificationAlertRef,
              'danger',
              'Thông báo',
              `Lưu thất bại. Lỗi ${mess}!`
            );
          },
        }
      )
    );
  };

  const handleUpdateOA = (body, messages) => {
    if (listType === 'order') {
      dispatch(
        orderActions.updateOrder(order.id, body, {
          success: () => {
            !!messages.success &&
              notify(
                notificationAlertRef,
                'success',
                'Thông báo',
                messages.success
              );
            setTimeout(() => {
              history.goBack();
            }, 2000);
          },
        })
      );
    } else {
      dispatch(
        additionalSampleActions.updateAdditionalSample(order.id, body, {
          success: () => {
            !!messages.success &&
              notify(
                notificationAlertRef,
                'success',
                'Thông báo',
                messages.success
              );
            setTimeout(() => {
              history.goBack();
            }, 2000);
          },
        })
      );
    }
  };

  const validateConclude = (value = '') => {
    if (value === '' || value === null) {
      setErrors((prev) => ({
        ...prev,
        conclude: 'Kết luận xét nghiệm không được để trống!',
      }));
      return true;
    } else {
      setErrors((prev) => ({
        ...prev,
        conclude: '',
      }));
      return false;
    }
  };

  const validateBS = (value = '') => {
    if (value === '' || value === null) {
      setErrors((prev) => ({
        ...prev,
        nameBS: 'Tên bác sĩ không được để trống!',
      }));
      return true;
    } else {
      setErrors((prev) => ({
        ...prev,
        nameBS: '',
      }));
      return false;
    }
  };

  useEffect(() => {
    handleGetAuthors();
  }, [order]);

  const handleGetAuthors = () => {
    dispatch(authorActions.getAuthors(queryString.stringify({})));
  };

  useEffect(() => {
    const delayDebounceFn = setTimeout(() => {
      handleGetAccountBS();
    }, 500);

    return () => clearTimeout(delayDebounceFn);
  }, [accountSearch, order]);

  const handleGetAccountBS = () => {
    const query = {
      'filters[$and][1][organization][id][$eq]': CONSTANT.HTGEN_ID,
      'filters[$and][2][accountType][$eq]': CONSTANT.ACCOUNT_TYPE[4].value,
      'filters[$and][3][isActive][$eq]': true,
    };

    if (accountSearch !== '') {
      query[`filters[$and][4][fullname][$containsi]`] = accountSearch;
    }

    dispatch(
      accountActions.getAccountsExtend(
        queryString.stringify(query),
        {
          success: (data) => {
            const results = _.get(data, 'results', []);
            setAccountResults(results);
          },
          failed: () => {},
        },
        false
      )
    );
  };

  useEffect(() => {
    if (currentAccountExtend.accountType === CONSTANT.ACCOUNT_TYPE[4].value) {
      setAccountValue({
        label: `(${currentAccountExtend?.code ?? ''})${
          currentAccountExtend?.fullname ?? ''
        }`,
      });
    }
  }, [currentAccountExtend]);
  console.log('order?.result: ', order);
  return (
    <div className="position-relative">
      <SimpleHeader />
      <Container className="mt--9" fluid>
        <Row>
          <div className="col">
            <Card>
              <CardHeader className="pb-2 d-flex justify-content-between rounded">
                <h3>Phiếu trả kết quả</h3>
              </CardHeader>
              <CardBody>
                <Row>
                  <Col xs={12} md={6} className="border-right">
                    <Row>
                      <CardHeader className="pb-2 pt-0 d-flex justify-content-between rounded border-bottom w-100">
                        <h3>Thông tin xét nghiệm</h3>
                      </CardHeader>
                    </Row>
                    <Row>
                      <Col xs={12} md={6}>
                        <LabelValue
                          labelWidth={110}
                          label={'Mã đơn hàng'}
                          value={order?.order?.code ?? order?.code ?? ''}
                        />
                      </Col>
                      <Col xs={12} md={6}>
                        <LabelValue
                          labelWidth={110}
                          label={'Mã labcode'}
                          value={order?.labCode}
                        />
                      </Col>
                    </Row>
                    <Row>
                      <Col xs={12} md={12}>
                        <LabelValue
                          labelWidth={110}
                          label={'Tên dịch vụ xét nghiệm'}
                          value={order?.specifyVote?.nameTest ?? ''}
                        />
                      </Col>
                    </Row>
                    <Row>
                      <Col xs={12} md={6}>
                        <LabelValue
                          labelWidth={110}
                          label={'Họ tên BN'}
                          value={order?.specifyVote?.nameBN ?? ''}
                        />
                      </Col>
                      <Col xs={12} md={6}>
                        <LabelValue
                          labelWidth={110}
                          label={'Ngày sinh'}
                          value={order?.specifyVote?.dateOfBirthBN ?? ''}
                        />
                      </Col>
                    </Row>
                    <Row>
                      <Col xs={12} md={6}>
                        <LabelValue
                          labelWidth={110}
                          label={'Số điện thoại'}
                          value={order?.specifyVote?.phoneBN ?? ''}
                        />
                      </Col>
                      <Col xs={12} md={6}>
                        <LabelValue
                          labelWidth={110}
                          label={'Bác sĩ chỉ định'}
                          value={`${order?.specifyVote?.lastNameBS ?? ''} ${
                            order?.specifyVote?.firstNameBS ?? ''
                          }`}
                        />
                      </Col>
                    </Row>
                    <Row>
                      <Col xs={12} md={6}>
                        <LabelValue
                          labelWidth={110}
                          label={'Ngày thu mẫu'}
                          value={
                            !!order?.specifyVote?.dateTM
                              ? moment(order.specifyVote.dateTM).format(
                                  'DD/MM/YYYY'
                                )
                              : ''
                          }
                        />
                      </Col>
                      <Col xs={12} md={6}>
                        <LabelValue
                          labelWidth={110}
                          label={'Nơi thu mẫu'}
                          value={order?.specifyVote?.whereTM ?? ''}
                        />
                      </Col>

                      <Col
                        xs={12}
                        md={12}
                        className={'d-flex align-items-start'}
                      >
                        <LabelValue
                          labelWidth={110}
                          label={'Nội dung xét nghiệm'}
                          value={order?.specifyVote?.content ?? ''}
                        />
                      </Col>
                    </Row>
                  </Col>
                  {order?.orderType === 'appendix' ? (
                    <Col xs={12} md={6}>
                      <ModalWarningCustom
                        notificationModal={notificationModal}
                        setNotificationModal={setNotificationModal}
                        name={`file kết quả ${
                          fileResults[fileResults.length - 1]?.name ?? ''
                        }`}
                        func={handleDeleteFile}
                        isDelete={false}
                      />
                      <Row>
                        <CardHeader className="pb-2 pt-0 d-flex justify-content-between rounded border-bottom w-100">
                          <h3>File kết quả</h3>
                        </CardHeader>
                      </Row>
                      {(order?.fileAppendixs ?? [])
                        .filter(
                          (file) =>
                            !fileResults
                              .filter((f) => f.deleted)
                              .map((f) => f.id)
                              .includes(file.id)
                        )
                        .map((file, index) => (
                          <Row>
                            <Col xs={7}>
                              <label
                                style={{
                                  fontSize: 15,
                                  color: '#32325D',
                                }}
                              >
                                <h4
                                  style={{
                                    whiteSpace: 'nowrap',
                                  }}
                                >
                                  {index + 1}. {file.name}
                                </h4>
                              </label>
                            </Col>
                            <Col
                              xs={5}
                              className="d-flex justify-content-end align-items-center"
                            >
                              <Button
                                onClick={() => {
                                  handleDownloadLink(file);
                                }}
                                style={{
                                  backgroundColor: ' #4690CD',
                                  color: 'white',
                                  borderRadius: '12px',
                                  padding: '3px 12px',
                                  fontSize: '12px',
                                  display: 'flex',
                                  alignItems: 'center',
                                  border: 'none',
                                }}
                              >
                                <span style={{ paddingRight: '8px' }}>
                                  Mở file
                                </span>
                                <img src={IconArrowDown} />
                              </Button>
                              <Button
                                onClick={() => {
                                  setFileResults(
                                    _.uniqBy(
                                      [
                                        ...fileResults,
                                        { ...file, deleted: false },
                                      ],
                                      'id'
                                    )
                                  );
                                  setNotificationModal(true);
                                }}
                                style={{
                                  backgroundColor: '#E9E9F1',
                                  color: 'white',
                                  borderRadius: '12px',
                                  padding: '3px 12px',
                                  fontSize: '12px',
                                  display: 'flex',
                                  alignItems: 'center',
                                  border: 'none',
                                }}
                              >
                                <span
                                  style={{
                                    paddingRight: '8px',
                                    color: '#ED4C5C',
                                  }}
                                >
                                  Huỷ KQ
                                </span>
                                <img src={IconBin} />
                              </Button>
                            </Col>
                          </Row>
                        ))}
                      <InputCustom
                        name="note"
                        label="Lý do từ chối"
                        type="textarea"
                        placeholder="Nhập lý do từ chối"
                        value={note}
                        isRequired={false}
                        handleChange={(e) => {
                          setNote(e.target.value);
                        }}
                        handleBlur={() => {
                          // setTouched({ ...touched, conclude: true });
                        }}
                        error={errors?.note}
                        touched={touched?.note}
                        row
                        labelWidth={160}
                      />
                    </Col>
                  ) : (
                    <Col xs={12} md={6}>
                      <Row>
                        <CardHeader className="pb-2 pt-0 d-flex justify-content-between rounded border-bottom w-100">
                          <h3>Kết luận</h3>
                        </CardHeader>
                      </Row>
                      <Row>
                        <Col xs={12}>
                          {!disable && (
                            <SelectCustom
                              name="conclusionForm"
                              label="Mẫu kết luận"
                              placeholder="Lựa chọn"
                              value={conclusionFormValue}
                              isRequired={false}
                              isClearable={false}
                              handleChange={(e) => {
                                setResultXN({
                                  ...resultXN,
                                  conclude: e.label,
                                });
                                setConclusionFormValue(e);
                              }}
                              options={CONSTANT.CONCLUSION_FORMS}
                              row
                              labelWidth={160}
                            />
                          )}
                        </Col>
                      </Row>
                      <Row>
                        <Col xs={12}>
                          {disable ? (
                            <LabelValue
                              labelWidth={160}
                              label="Kết luận xét nghiệm"
                              value={order?.result?.conclude ?? ''}
                            />
                          ) : (
                            <>
                              <InputCustom
                                name="conclude"
                                label="Kết luận xét nghiệm"
                                type="textarea"
                                placeholder="Nhập kết luận xét nghiệm"
                                value={resultXN?.conclude ?? ''}
                                isRequired={true}
                                handleChange={(e) => {
                                  setResultXN({
                                    ...resultXN,
                                    conclude: e.target.value,
                                  });
                                  validateConclude(e.target.value);
                                }}
                                handleBlur={() => {
                                  setTouched({ ...touched, conclude: true });
                                }}
                                error={errors?.conclude}
                                touched={touched?.conclude}
                                row
                                labelWidth={160}
                              />
                            </>
                          )}
                        </Col>
                      </Row>
                      <Row className="align-items-center">
                        <Col xs={12} md={8} lg={8} className={'d-flex'}>
                          <LabelCustom
                            isRequired={true}
                            label={'Chữ ký kỹ thuật'}
                            styleLabel={{ minWidth: 160 }}
                            style={{
                              margin: '0.5em 8px 0px 0px',
                            }}
                          />
                          {!disable && (
                            <div className="ml-2 d-flex align-items-center">
                              <input
                                style={{ fontSize: 12, marginLeft: 8 }}
                                type="file"
                                accept="image/*"
                                onChange={(e) => {
                                  try {
                                    if (_.isEmpty(e?.target?.files)) return;

                                    setResultXN({
                                      ...resultXN,
                                      authorTech: {
                                        url: URL.createObjectURL(
                                          e.target.files[0]
                                        ),
                                        id: -1,
                                        file: e.target.files[0],
                                      },
                                    });
                                  } catch (error) {}
                                }}
                              />
                            </div>
                          )}
                        </Col>
                        <Col xs={12} md={4} lg={4} className={'d-flex'}>
                          {!!resultXN?.authorTech?.url &&
                            resultXN?.authorTech?.url !== '' && (
                              <img
                                alt="..."
                                className="shadow"
                                src={resultXN.authorTech.url}
                                style={{
                                  width: '100%',
                                  height: 'auto',
                                  borderRadius: 4,
                                }}
                              />
                            )}
                        </Col>
                      </Row>
                      <Row className="align-items-center">
                        <Col xs={12} md={8} lg={8} className={'d-flex'}>
                          <LabelCustom
                            isRequired={true}
                            label={'Chữ ký bác sĩ'}
                            styleLabel={{ minWidth: 160 }}
                            style={{
                              margin: '0.5em 8px 0px 0px',
                            }}
                          />
                          {!disable && (
                            <div className="ml-2 d-flex align-items-center">
                              <input
                                style={{ fontSize: 12, marginLeft: 8 }}
                                type="file"
                                accept="image/*"
                                onChange={(e) => {
                                  try {
                                    if (_.isEmpty(e?.target?.files)) return;

                                    setResultXN({
                                      ...resultXN,
                                      author: {
                                        url: URL.createObjectURL(
                                          e.target.files[0]
                                        ),
                                        id: -1,
                                        file: e.target.files[0],
                                      },
                                    });
                                  } catch (error) {}
                                }}
                              />
                            </div>
                          )}
                        </Col>
                        <Col xs={12} md={4} lg={4} className={'d-flex'}>
                          {!!resultXN?.author?.url &&
                            resultXN?.author?.url !== '' && (
                              <img
                                alt="..."
                                className="shadow"
                                src={resultXN.author.url}
                                style={{
                                  width: '100%',
                                  height: 'auto',
                                  borderRadius: 4,
                                }}
                              />
                            )}
                        </Col>
                      </Row>
                      <Row>
                        <Col xs={12} md={12} lg={8}>
                          {/* <InputCustom
                          name="nameBS"
                          label="Tên bác sĩ"
                          type="text"
                          placeholder="Nhập tên bác sĩ"
                          value={resultXN?.nameBS ?? ''}
                          isRequired={true}
                          handleChange={(e) => {
                            setResultXN({
                              ...resultXN,
                              nameBS: e.target.value,
                            });
                            validateBS(e.target.value);
                          }}
                          handleBlur={() => {
                            setTouched({ ...touched, nameBS: true });
                          }}
                          error={errors?.nameBS}
                          touched={touched?.nameBS}
                          row
                          labelWidth={160}
                        /> */}
                          {disable ? (
                            <LabelValue
                              labelWidth={160}
                              label="Bác sĩ"
                              value={order?.result?.nameBS ?? ''}
                            />
                          ) : (
                            <SelectCustom
                              name="specifyVote.firstNameBS"
                              label="Bác sĩ"
                              placeholder="Lựa chọn"
                              value={accountValue}
                              isRequired={true}
                              isClearable={false}
                              handleChange={(e) => {
                                setAccountValue(e);
                                setResultXN({
                                  ...resultXN,
                                  nameBS: e?.label,
                                });
                                validateBS(e?.label ?? '');
                              }}
                              onInputChange={(value) => {
                                setAccountSearch(value);
                              }}
                              options={accountResults.map((item) => ({
                                value: item.id,
                                label: `(${item?.code ?? ''})${
                                  item?.fullname ?? ''
                                }`,
                                ...item,
                              }))}
                              handleBlur={() => {
                                setTouched({ ...touched, nameBS: true });
                              }}
                              error={errors?.nameBS}
                              touched={touched?.nameBS}
                              row
                              labelWidth={160}
                            />
                          )}
                        </Col>
                        <Col xs={12} md={12} lg={4}>
                          {disable ? (
                            !!order?.result?.createdAt ? (
                              moment(order.result.createdAt).format(
                                'DD/MM/YYYY'
                              )
                            ) : (
                              ''
                            )
                          ) : (
                            <LabelValue
                              labelWidth={110}
                              label={'Ngày tạo phiếu'}
                              value={moment().format('DD/MM/YYYY')}
                            />
                          )}
                        </Col>
                      </Row>
                    </Col>
                  )}
                </Row>
              </CardBody>
            </Card>
          </div>
        </Row>
      </Container>

      {!_.isEmpty(resultXN) && order?.orderType === 'nipt' && (
        <ResultsXN
          idxO={0}
          resultXN={resultXN}
          handleResultChange={handleResultChange}
          disable={disable}
        />
      )}
      <div
        style={{ bottom: 10, left: 0 }}
        className="position-fixed bg-white w-100 shadow-lg rounded"
      >
        <div className="m-2 d-flex justify-content-end">
          <Button
            className="text-nowrap mr-1"
            onClick={() => {
              history.goBack();
            }}
          >
            Hủy bỏ
          </Button>

          <LoadingButtonCustom
            onClick={handleReject}
            color="danger"
            className="text-nowrap mr-1"
            type="submit"
            loading={false}
          >
            Từ chối
          </LoadingButtonCustom>
          <LoadingButtonCustom
            onClick={handleSubmit}
            color="info"
            className="text-nowrap mr-1"
            type="submit"
            loading={false}
          >
            Duyệt
          </LoadingButtonCustom>
        </div>
      </div>
    </div>
  );
}

export default memo(ReturnResults);
