import React, { memo, useEffect, useState } from 'react';
import _ from 'lodash';
import { Card, Container, Row, Spinner } from 'reactstrap';
import SimpleHeader from 'components/Headers/SimpleHeader.js';
import 'assets/css/custom-pages/react-bs-table.css';
import { useDispatch, useSelector } from 'react-redux';
import queryString from 'query-string';

import OrderInfo from './components/OrderInfo';
import PCD from './components/PCD';
import { orderActions } from 'redux/actions';
import { useHistory, useParams } from 'react-router';

function OrderDetail() {
  const dispatch = useDispatch();
  const history = useHistory();
  const { id } = useParams();
  const { order, isGettingOrder } = useSelector((state) => state.orderReducer);

  const handleGetOrder = () => {
    if (!id) return;
    dispatch(orderActions.getOrder(id, queryString.stringify({})));
  };

  useEffect(() => {
    handleGetOrder();
  }, [id]);

  return (
    <>
      <SimpleHeader />
      <Container className="mt--9" fluid>
        <Row className="position-relative">
          <div className="col">
            <OrderInfo />

            <PCD />
          </div>
          {isGettingOrder && (
            <Card
              className="position-absolute d-flex w-100 justify-content-center rounded"
              style={{
                top: 0,
                height: '100%',
                // background: 'rgba(0,0,0,.1)',
              }}
            >
              <div className="d-flex w-100 justify-content-center rounded">
                <Spinner className="mt-5" color="info">
                  Loading...
                </Spinner>
              </div>
            </Card>
          )}
        </Row>
      </Container>
    </>
  );
}

export default memo(OrderDetail);
