import React from 'react';

const LabelValue = ({
  label,
  value,
  labelWidth = 120,
  textAlign = 'start',
}) => {
  return (
    <div>
      <label
        style={{
          fontSize: 15,
          color: '#32325D',
          minWidth: labelWidth,
        }}
      >
        <h4
          style={{
            whiteSpace: 'nowrap',
            textAlign,
          }}
        >
          {label}:
        </h4>
      </label>
      <span
        className="pl-3"
        style={{ fontSize: 13, fontWeight: 300, color: '#151515' }}
      >
        {value}
      </span>
    </div>
  );
};

export default LabelValue;
