import typeActions from 'redux/typeActions';

const setValue = ({ name, value }) => {
  return {
    type: typeActions.SET_BARCODE_VALUE,
    name,
    value,
  };
};

const getBarcodes = (query, callback, isUpdateReducer = true) => {
  return {
    type: typeActions.GET_BARCODES_REQUEST,
    query,
    callback,
    isUpdateReducer,
  };
};

const getBarcode = (param, query, callback) => {
  return {
    type: typeActions.GET_BARCODE_REQUEST,
    param,
    query,
    callback,
  };
};

const createBarcode = (body, callback) => {
  return {
    type: typeActions.CREATE_BARCODE_REQUEST,
    body,
    callback,
  };
};
const updateBarcode = (param, body, callback) => {
  return {
    type: typeActions.UPDATE_BARCODE_REQUEST,
    param,
    body,
    callback,
  };
};
const deleteBarcode = (param, callback) => {
  return {
    type: typeActions.DELETE_BARCODE_REQUEST,
    param,
    callback,
  };
};
const exportBarcodes = (query, callback) => {
  return {
    type: typeActions.EXPORT_BARCODE_REQUEST,
    callback,
    query,
  };
};

const printBarcodes = (param, body, callback) => {
  return {
    type: typeActions.PRINT_BARCODES_REQUEST,
    param,
    body,
    callback,
  };
};
export default {
  setValue,
  getBarcodes,
  getBarcode,
  createBarcode,
  updateBarcode,
  deleteBarcode,
  exportBarcodes,
  printBarcodes,
};
