import typeActions from 'redux/typeActions';

const setValue = ({ name, value }) => {
  return {
    type: typeActions.SET_AUTHOR_VALUE,
    name,
    value,
  };
};

const getAuthors = (query, callback, isUpdateReducer = true) => {
  return {
    type: typeActions.GET_AUTHORS_REQUEST,
    query,
    callback,
    isUpdateReducer,
  };
};

const getAuthor = (param, query, callback) => {
  return {
    type: typeActions.GET_AUTHOR_REQUEST,
    param,
    query,
    callback,
  };
};

const createAuthor = (body, callback) => {
  return {
    type: typeActions.CREATE_AUTHOR_REQUEST,
    body,
    callback,
  };
};
const updateAuthor = (param, body, callback) => {
  return {
    type: typeActions.UPDATE_AUTHOR_REQUEST,
    param,
    body,
    callback,
  };
};
const deleteAuthor = (param, callback) => {
  return {
    type: typeActions.DELETE_AUTHOR_REQUEST,
    param,
    callback,
  };
};

export default {
  setValue,
  getAuthors,
  getAuthor,
  createAuthor,
  updateAuthor,
  deleteAuthor,
};
