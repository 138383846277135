import React, { useState } from 'react';
import { Card, CardBody, CardHeader, Row, Col, Button } from 'reactstrap';
import { useDispatch, useSelector } from 'react-redux';
import { filterActions, timelineActions } from 'redux/actions';
import { specifyVotesActions } from 'redux/actions';
import { Style } from './style';
import InputCustom from 'views/pages/components/InputCustom';
import _ from 'lodash';
import IconArrowRight from 'assets/svgs/arrowright.svg';
import SelectCustom from 'views/pages/components/SelectCustom';
import CONSTANT from 'constant';
import { Formik } from 'formik';
import moment from 'moment';
import HelperText from 'views/pages/components/HelperText';
import DatePickerCustom from 'views/pages/components/DatePickerCustom';
import { isDoctorKH } from 'common';
const FilterSpecifyVote = () => {
  const [statusSpecifyVote, setStatusSpecifyVote] = useState(null);
  const timelineReducer = useSelector((state) => state.timelineReducer);
  const { currentAccountExtend } = useSelector((state) => state.accountReducer);
  const dispatch = useDispatch();
  const { isModalVisible } = useSelector((state) => state.filterReducer);

  const handleFilter = ({
    id,
    nameTC,
    createdAtFrom,
    createdAtTo,
    specifyVoteStatus,
  }) => {
    const object = {
      'filters[$and][2][codePCD][$containsi]': id,
      'filters[$and][3][nameTC][$containsi]': nameTC,
      'filters[$and][4][createdAt][$gte]': createdAtFrom,
      'filters[$and][5][createdAt][$lte]': createdAtTo,
      'filters[$and][6][specifyVoteStatus][$eq]': specifyVoteStatus,
      'filters[$and][7][deleted][$eq]': false,
      // 'filters[$and][8][idBS][$containsi]': [4, 5].includes(
      //   currentAccountExtend?.accountType
      // )
      //   ? currentAccountExtend?.id ?? ''
      //   : '',
    };
    // if (value==null) delete key:value
    const filterTitle = [
      {
        name: 'filters[$and][0][codePCD][$containsi]',
        title: 'Mã phiếu xét nghiệm',
        value: id,
      },
      {
        name: 'filters[$and][1][nameTC][$containsi]',
        title: 'Tên khách hàng',
        value: nameTC,
      },
      {
        name: 'filters[$and][2][createdAt][$gte]',
        title: 'Thời gian từ',
        value:
          moment(new Date(createdAtFrom)).format('DD-MM-YYYY') +
          ' - ' +
          moment(new Date(createdAtTo)).format('DD-MM-YYYY'),
      },
      {
        name: 'filters[$and][4][specifyVoteStatus][$containsi]',
        title: 'Trạng thái chỉ định',
        value:
          _.find(CONSTANT.SPECIFY_VOTE_RESULT_TYPE, {
            value: specifyVoteStatus,
          })?.label ?? '',
      },
    ];
    if (
      !object['filters[$and][2][createdAt][$gte]'] ||
      !object['filters[$and][3][createdAt][$lte]']
    ) {
      delete object['filters[$and][2][createdAt][$gte]'];
      delete object['filters[$and][3][createdAt][$lte]'];
      _.remove(filterTitle, {
        name: 'filters[$and][2][createdAt][$gte]',
      });
    }
    Object.keys(object).map((key) => {
      if (object[`${key}`].toString().trim() === '') {
        delete object[key];
        _.remove(filterTitle, {
          name: key,
        });
      }
    });

    console.log({ specifyVoteStatus })
    if (isDoctorKH(currentAccountExtend) && specifyVoteStatus === -2) {
      object['filters[$and][6][specifyVoteStatus][$eq]'] = '';
      object['filters[$and][6][$or][0][specifyVoteStatus][$eq]'] = '0';
      object['filters[$and][6][$or][1][specifyVoteStatus][$eq]'] = '1';
    } else {
      object['filters[$and][6][$or][0][specifyVoteStatus][$eq]'] = '';
      object['filters[$and][6][$or][1][specifyVoteStatus][$eq]'] = '';
    }
    dispatch(
      filterActions.setValueFilter({
        name: 'queryFilter',
        value: object,
      })
    );
    if (Object.keys(object).length > 0) {
      dispatch(
        timelineActions.setValueTimelineReducer({
          name: 'filterTitle',
          value: filterTitle,
        })
      );
      dispatch(
        specifyVotesActions.setValueSpecifyVote({
          name: 'query',
          value: object,
        })
      );
    } else if (timelineReducer.filterTitle.length > 0) {
      dispatch(
        timelineActions.setValueTimelineReducer({
          name: 'filterTitle',
          value: [],
        })
      );
      dispatch(
        specifyVotesActions.setValueSpecifyVote({
          name: 'query',
          value: {
            populate: '*',
          },
        })
      );
    }
  };
  const initFilterInfo = {
    id: '',
    nameTC: '',
    createdAtFrom: '',
    createdAtTo: '',
    specifyVoteStatus: '',
  };
  function validateForm(values) {
    if (
      new Date(values.createdAtTo).getTime() -
      new Date(values.createdAtFrom).getTime() <
      0
    ) {
      return {
        createdAtTo: 'Ngày không hợp lệ!',
      };
    }
    return {};
  }
  function getOptionStatus() {
    if (isDoctorKH(currentAccountExtend)) {
      return [
        { value: -1, label: 'Hủy' },
        { value: -2, label: 'Đã thu mẫu' },
        { value: 2, label: 'Mẫu lỗi' },
        { value: 3, label: 'Đã có kết quả' },
        {
          label: 'Tất cả',
          value: '',
        },
      ];
    }
    return [
      ...CONSTANT.SPECIFY_VOTE_STATUS,
      {
        label: 'Tất cả',
        value: '',
      },
    ];
  }
  return (
    <Style>
      <Card
        className={`filter ${!isModalVisible && `hidden`} `}
        style={{
          zIndex: '9999',
        }}
      >
        <CardHeader className=" px-3 py-2">
          <Row>
            <Col xs={3} className="pr-0">
              <h4
                className="mb-0"
                style={{
                  color: '#32325D',
                  cursor: 'pointer',
                }}
                onClick={() => {
                  dispatch(
                    filterActions.setValueFilter({
                      name: 'isModalVisible',
                      value: false,
                    })
                  );
                }}
              >
                Đóng{' '}
                <img
                  style={{
                    height: '11px',
                    marginLeft: 2,
                  }}
                  src={IconArrowRight}
                  alt={'arrow right'}
                />
              </h4>
            </Col>
          </Row>
        </CardHeader>
        <CardBody className="px-3 py-2">
          <Formik
            initialValues={initFilterInfo}
            onSubmit={(values) => {
              dispatch(
                filterActions.setValueFilter({
                  name: 'isModalVisible',
                  value: false,
                })
              );
              dispatch(
                filterActions.setValueFilter({
                  name: 'modalInfo',
                  value: values,
                })
              );
              handleFilter(values);
            }}
            validate={validateForm}
          >
            {({
              values,
              setFieldValue,
              errors,
              touched,
              handleBlur,
              handleSubmit,
              resetForm,
            }) => {
              return (
                <Row>
                  <Col xs={12}>
                    <Row className="mb-0">
                      <Col xs={12}>
                        <InputCustom
                          label="Chọn mã phiếu xét nghiệm"
                          value={values.id}
                          handleChange={(e) => {
                            setFieldValue('id', e.target.value);
                          }}
                          placeholder="Nhập để tìm kiếm"
                        />
                      </Col>
                    </Row>
                    {![4, 5].includes(currentAccountExtend?.accountType) && (
                      <Row>
                        <Col xs={12} className="mt-2">
                          <InputCustom
                            label="Chọn khách hàng"
                            value={values.nameTC}
                            handleChange={(e) => {
                              setFieldValue('nameTC', e.target.value);
                            }}
                            placeholder="Nhập để tìm kiếm theo tên tổ chức"
                          />
                        </Col>
                      </Row>
                    )}
                    <Row>
                      <Col xs={12} className="mt-2">
                        <h4 className="mb-0">Chọn thời gian</h4>
                      </Col>
                    </Row>
                    <Row>
                      <Col lg={6}>
                        <DatePickerCustom
                          dateFormat={'dd/MM/yyyy'}
                          selected={
                            values?.createdAtFrom
                              ? new Date(values.createdAtFrom)
                              : null
                          }
                          label="Từ"
                          row
                          onChange={(date) => {
                            if (!!date) {
                              let temp = moment(date.toISOString()).set({
                                hour: 0,
                                minute: 0,
                              });
                              setFieldValue(
                                'createdAtFrom',
                                temp.toISOString()
                              );
                            } else setFieldValue('createdAtFrom', null);
                          }}
                          labelWidth={20}
                        />
                        {errors.createdAtFrom && !!touched.createdAtFrom && (
                          <HelperText message={errors.createdAtFrom} />
                        )}
                      </Col>
                      <Col lg={6}>
                        <DatePickerCustom
                          dateFormat={'dd/MM/yyyy'}
                          selected={
                            values?.createdAtTo
                              ? new Date(values.createdAtTo)
                              : null
                          }
                          row
                          label={'Đến'}
                          labelWidth={20}
                          onChange={(date) => {
                            if (!!date) {
                              let temp = moment(date.toISOString()).set({
                                hour: 23,
                                minute: 59,
                              });
                              setFieldValue('createdAtTo', temp.toISOString());
                            } else setFieldValue('createdAtTo', null);
                          }}
                        />
                        {errors.createdAtTo && !!touched.createdAtTo && (
                          <HelperText message={errors.createdAtTo} />
                        )}
                      </Col>
                    </Row>
                    <Row>
                      <Col xs={12} className="mt-2">
                        <h4 className="mb-0">Chọn trạng thái</h4>
                      </Col>
                    </Row>
                    <Row>
                      <Col xs={6} className=" ">
                        <div>
                          <SelectCustom
                            value={statusSpecifyVote}
                            placeholder={'Chọn trạng thái'}
                            handleChange={(e) => {
                              setStatusSpecifyVote(e);
                              setFieldValue(
                                'specifyVoteStatus',
                                e?.value ?? ''
                              );
                            }}
                            handleBlur={handleBlur}
                            error={errors.type}
                            touched={touched.type}
                            isRequired={true}
                            isClearable={true}
                            options={getOptionStatus()}
                          />
                        </div>
                      </Col>
                    </Row>
                    <Row className="mt-4">
                      <Col xs={12} className="mt-2">
                        <Button
                          style={{ color: '#4690CD', width: '100%' }}
                          onClick={() => {
                            setStatusSpecifyVote(null);
                            resetForm();
                            handleSubmit();
                          }}
                        >
                          Xoá bộ lọc
                        </Button>
                      </Col>
                    </Row>
                    <Row>
                      <Col xs={12}>
                        <Button
                          onClick={handleSubmit}
                          style={{
                            width: '100%',
                            color: 'white',
                            backgroundColor: '#4690CD',
                          }}
                        >
                          Áp dụng bộ lọc
                        </Button>
                      </Col>
                    </Row>
                  </Col>
                </Row>
              );
            }}
          </Formik>
        </CardBody>
      </Card>
    </Style>
  );
};

export default FilterSpecifyVote;
