import typeActions from 'redux/typeActions';
import _ from 'lodash';

const initialState = {
  isModalVisible: false,
  isModalPrintVisible: false,
  isModalAddFastVisible: false,
  isFilterVisible: false,
  isModalDVisible: false,
  isModalApproveVisible: false,
  isModalConfirmBarcodeVisible: false,
  isModalCreateAdditionalSampleVisible: false,
  isModalTranferAdditionalSampleVisible: false,
  isModalTranferOrderVisible: false,
  modalInfo: {},
  filterInfo: {},
  formType: 0,
};

const returnValue = (obj, name, value) => {
  const tmp = _.cloneDeep(obj);
  tmp[name] = value;
  return tmp;
};

const formReducer = (state = initialState, actions) => {
  switch (actions.type) {
    case typeActions.SET_VALUE_GENERAL:
      const { name, value } = actions;
      if (typeof name !== 'string') return { ...state };
      const obj = returnValue(state, name, value);
      return {
        ...state,
        ...obj,
      };

    default:
      return {
        ...state,
      };
  }
};
export default formReducer;
