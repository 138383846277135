import React, { memo, useEffect, useState } from 'react';
import paginationFactory from 'react-bootstrap-table2-paginator';
import _ from 'lodash';
import { Badge, Container, Row, UncontrolledTooltip } from 'reactstrap';
import queryString from 'query-string';
import { useHistory } from 'react-router';
import { useDispatch, useSelector } from 'react-redux';

import {
  orderActions,
  formActions,
  templatePCDActions,
  uploadActions,
} from 'redux/actions';
import SimpleHeader from 'components/Headers/SimpleHeader.js';
import 'assets/css/custom-pages/react-bs-table.css';
import { useDidUpdateEffect } from 'utils/HookUtils';
import CONSTANT from 'constant';

import IconDownload from 'assets/svgs/download.svg';
import IconUpload from 'assets/svgs/upload.svg';
import moment from 'moment';
import TableCustom from '../components/TableCustom';
import { BASE_URL_STRAPI } from 'services/ServiceURL';
import { checkRole, notificationAlertRef, notify } from 'common';

function Template() {
  const dispatch = useDispatch();
  const { page, limit, templatePcds } = useSelector(
    (state) => state.templatePCDReducer
  );
  const { currentAccountExtend } = useSelector((state) => state.accountReducer);

  const results = _.get(templatePcds, 'results', []);
  const total = _.get(templatePcds, 'pagination.total', 0);

  const [templateSearch, setTemplateSearch] = useState('');

  const columns = [
    {
      dataField: 'code',
      text: 'MÃ MẪU IN',
    },
    {
      dataField: 'name',
      text: 'TÊN MẪU IN',
    },

    {
      dataField: 'createdAt',
      text: 'NGÀY TẠO',
      formatter: (cell) => {
        return !!cell ? moment(cell).format('DD/MM/YYYY') : '';
      },
    },

    {
      dataField: 'actions',
      text: 'HÀNH ĐỘNG',
      formatter: boxActions,
      headerStyle: {
        textAlign: 'center',
      },
      headerClasses: 'fixed-column',
      classes: 'fixed-column',
    },
  ];
  function boxActions(__, row) {
    return (
      <div className="d-flex justify-content-center">
        <button
          id={'download' + row.id}
          onClick={() => {
            if (!row?.template?.url) return;
            window.open(
              `${BASE_URL_STRAPI}${row.template.url}`,
              '_blank',
              'noopener,noreferrer'
            );
          }}
          className="btn p-0 btn-no-shadow mr-0"
        >
          <img src={IconDownload} className="mr-1" />
        </button>
        <UncontrolledTooltip
          delay={1}
          placement="top"
          target={'download' + row.id}
        >
          Tải mẫu xuống
        </UncontrolledTooltip>
        {checkRole(currentAccountExtend, {
          roles: ['update-template'],
        }) && (
          <>
            <button
              id={'upload' + row.id}
              onClick={() => {
                if (!!row?.template?.id)
                  document.getElementById(`input-${row.id}`).click();
              }}
              className="btn p-0 btn-no-shadow mr-0"
            >
              <img src={IconUpload} className="" />
            </button>
            <UncontrolledTooltip
              delay={1}
              placement="top"
              target={'upload' + row.id}
            >
              Tải mẫu lên
            </UncontrolledTooltip>
            <input
              style={{ fontSize: 12, marginLeft: 8, display: 'none' }}
              type="file"
              accept="file"
              id={`input-${row.id}`}
              onChange={(e) => {
                try {
                  if (_.isEmpty(e)) return;
                  const arrNameFile = e.target.files[0]?.name.split('.');
                  if (['docx'].includes(arrNameFile[arrNameFile.length - 1])) {
                    dispatch(
                      uploadActions.deleteFile(row.template.id, {
                        success: () => {
                          handleUploadFile(e, row.id);
                        },
                        failed: (__) => {
                          handleUploadFile(e, row.id);
                        },
                      })
                    );
                  } else {
                    notify(
                      notificationAlertRef,
                      'danger',
                      'Thông báo',
                      `Vui lòng chọn đúng định dạng file docx!`
                    );
                  }
                } catch (error) {
                  //
                }
              }}
            />
          </>
        )}
      </div>
    );
  }

  const handleUploadFile = (e, id) => {
    const formData = new FormData();
    formData.append('files', e.target.files[0], e.target.files[0].name);
    dispatch(
      uploadActions.uploadFiles(formData, {
        success: (data) => {
          handleUpdateTemplatePCD(data[0].id, id);
        },
        failed: (mess) => {
          notify(
            notificationAlertRef,
            'danger',
            'Thông báo',
            `Tải flie lên thất bại!. Lỗi: ${mess}!`
          );
        },
      })
    );
  };

  const handleUpdateTemplatePCD = (idTemplate, id) => {
    const body = {
      template: idTemplate,
    };
    dispatch(
      templatePCDActions.updateTemplatePCDs(id, body, {
        success: () => {
          handleGetTemplatePCDs();
          notify(
            notificationAlertRef,
            'success',
            'Thông báo',
            `Cập nhật mẫu PCD thành công!`
          );
        },
        failed: (mess) => {
          notify(
            notificationAlertRef,
            'danger',
            'Thông báo',
            `Cập nhật mẫu PCD thất bại!. Lỗi: ${mess}!`
          );
        },
      })
    );
  };

  const onSizePerPageChange = (e) => {
    dispatch(orderActions.setValue({ name: 'page', value: 1 }));
    dispatch(orderActions.setValue({ name: 'limit', value: e.target.value }));
  };

  const onPageChange = (value) => {
    dispatch(orderActions.setValue({ name: 'page', value: value }));
  };

  const pagination = paginationFactory({
    page: page,
    sizePerPage: limit,
    totalSize: total,
    showTotal: false,
    withFirstAndLast: false,
    alwaysShowAllBtns: true,
    onPageChange: onPageChange,
    sizePerPageRenderer: () => {
      return (
        <div className="dataTables_length" id="datatable-basic_length">
          <label>
            Hiển thị{' '}
            {
              <select
                name="datatable-basic_length"
                aria-controls="datatable-basic"
                className="form-control form-control-sm"
                onChange={onSizePerPageChange}
              >
                <option value="10">10</option>
                <option value="25">25</option>
                <option value="50">50</option>
                <option value="100">100</option>
              </select>
            }{' '}
            dòng.
          </label>
        </div>
      );
    },
  });

  useDidUpdateEffect(() => {
    handleGetTemplatePCDs();
  }, [page, limit]);

  useDidUpdateEffect(() => {
    // Sau 500ms thì bắn api
    const delayDebounceFn = setTimeout(() => {
      handleGetTemplatePCDs();
    }, 500);
    return () => clearTimeout(delayDebounceFn);
  }, [templateSearch]);

  useEffect(() => {
    // if (!_.isEmpty(orders)) return;
    handleGetTemplatePCDs();
  }, []);

  const handleGetTemplatePCDs = () => {
    const query = {
      pageSize: limit,
      page,
      _q: templateSearch,
    };

    dispatch(templatePCDActions.getTemplatePCDs(queryString.stringify(query)));
  };

  return (
    <>
      <SimpleHeader />

      <Container className="mt--9" fluid>
        <Row>
          <div className="col">
            <TableCustom
              tableName="Danh sách"
              data={results}
              columns={columns}
              page={page}
              limit={limit}
              total={total}
              searchBy={'Tìm kiếm'}
              pagination={pagination}
              search={templateSearch}
              onChange={(e) => {
                setTemplateSearch(e.target.value);
              }}
            />
          </div>
        </Row>
      </Container>
    </>
  );
}

export default memo(Template);
