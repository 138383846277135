import React, { memo, useEffect, useState } from 'react';
import paginationFactory from 'react-bootstrap-table2-paginator';
import _ from 'lodash';
import { Badge, Container, Row, UncontrolledTooltip } from 'reactstrap';
import queryString from 'query-string';
import { useHistory } from 'react-router';
import { useDispatch, useSelector } from 'react-redux';

import {
  areaActions,
  filterActions,
  formActions,
  orderActions,
} from 'redux/actions';
import SimpleHeader from 'components/Headers/SimpleHeader.js';
import 'assets/css/custom-pages/react-bs-table.css';
import { useDidUpdateEffect } from 'utils/HookUtils';
import CONSTANT from 'constant';
import { checkRole, currencyFormat } from 'common';

import IconEdit from 'assets/svgs/edit.svg';
import IconEye from 'assets/svgs/eye.svg';
import TableCustom from '../components/TableCustom';
import UPayment from './components/UPayment';
import Filter from './components/Filter';
import DPayment from './components/DPayment';
import ExportOrder from '../ExportOrder';
import UPayments from './components/UPayments';

function OrderUnpaid() {
  const dispatch = useDispatch();
  const history = useHistory();
  const { page, limit, orders, queryUnpaid } = useSelector(
    (state) => state.orderReducer
  );
  const {
    currentAccountExtend,
    childrentByCurrentAccountExtend,
    isHasChildrent,
    isAdmin,
    isSampleCollectorStaff,
    isBusinessStaff,
  } = useSelector((state) => state.accountReducer);
  const { areasByAccountExtend } = useSelector((state) => state.areaReducer);
  const results = _.get(orders, 'results', []);
  const total = _.get(orders, 'pagination.total', 0);

  const [orderSearch, setOrderSearch] = useState('');

  const [rowsSelected, setRowSelected] = useState([]);

  const selectRow = {
    mode: 'checkbox',
    selectColumnPosition: 'left',
    onSelect: (row, isSelect) => {
      try {
        if (isSelect) {
          setRowSelected((prev) => _.uniqBy([...prev, row], 'id'));
        } else {
          const tmp = rowsSelected.filter((item) => item.id !== row.id);
          setRowSelected(_.uniqBy(tmp));
        }
      } catch (error) {}
    },
    onSelectAll: (isSelect, rows) => {
      if (isSelect) {
        setRowSelected(rows);
      } else {
        setRowSelected([]);
      }
    },
    selectionHeaderRenderer: ({ indeterminate, ...rest }) => {
      return (
        <div className="" style={{ verticalAlign: 'inherit' }}>
          <input
            type="checkbox"
            style={{ height: 'unset', verticalAlign: 'inherit' }}
            ref={(input) => {
              if (input) input.indeterminate = indeterminate;
            }}
            {...rest}
          />
        </div>
      );
    },
    selectionRenderer: ({ mode, ...rest }) => {
      return (
        <div>
          <input type={mode} {...rest} />
        </div>
      );
    },
    selected: rowsSelected.map((item) => item.id),
  };

  const columns = [
    {
      dataField: 'code',
      text: 'MÃ',
    },
    {
      dataField: 'name',
      text: 'TÊN ĐƠN HÀNG',
    },
    {
      dataField: 'organization.name',
      text: 'PHÒNG KHÁM/BỆNH VIỆN',
    },
    {
      dataField: 'specifyVote.nameTest',
      text: 'DỊCH VỤ',
    },
    {
      dataField: 'bill',
      text: 'TỔNG TIỀN(VNĐ)',
      formatter: (cell) => {
        return currencyFormat(
          Number(cell?.totalMoney ?? 0) - Number(cell?.reduce ?? 0)
        );
      },
      headerStyle: {
        textAlign: 'right',
      },
      style: {
        textAlign: 'right',
      },
    },
    {
      dataField: 'specifyVote',
      text: 'BÁC SĨ',
      formatter: (cell) => {
        return `${cell?.lastNameBS ?? ''} ${cell?.firstNameBS ?? ''} `;
      },
    },
    {
      dataField: 'codeStaff1',
      text: 'SALE',
      formatter: (cell) => {
        return `${cell?.fullname ?? ''} `;
      },
    },
    {
      dataField: 'codeStaff2',
      text: 'THU MẪU',
      formatter: (cell) => {
        return `${cell?.fullname ?? ''} `;
      },
    },
    {
      dataField: 'bill',
      text: 'ĐÃ THANH TOÁN',
      formatter: (cell) => {
        return currencyFormat(Number(cell?.paid ?? 0));
      },
      headerStyle: {
        textAlign: 'right',
      },
      style: {
        textAlign: 'right',
      },
    },
    {
      dataField: 'organization.method',
      text: 'HÌNH THỨC',
      formatter: (cell) => {
        const paymentMethodV = CONSTANT.ORGANIZATION_METHOD_TYPE.find(
          (item) => item.value === cell
        );
        return !!paymentMethodV ? paymentMethodV.label : '';
      },
      headerStyle: {
        textAlign: 'center',
      },
      style: {
        textAlign: 'center',
      },
    },

    {
      dataField: 'actions',
      text: 'HÀNH ĐỘNG',
      formatter: boxActions,
      headerStyle: {
        textAlign: 'center',
        zIndex: 3,
      },
      headerClasses: 'fixed-column',
      classes: 'fixed-column',
    },
  ];

  function boxActions(__, row) {
    return (
      <div className="d-flex justify-content-center">
        <button
          id={'view' + row.id}
          onClick={() => {
            dispatch(
              formActions.setValue({
                name: 'isModalDVisible',
                value: true,
              })
            );
            dispatch(
              formActions.setValue({
                name: 'modalInfo',
                value: row,
              })
            );
          }}
          className="btn p-0 btn-no-shadow mr-0"
        >
          <img src={IconEye} className="mr-1" />
        </button>
        <UncontrolledTooltip delay={1} placement="top" target={'view' + row.id}>
          Chi tiết đơn hàng
        </UncontrolledTooltip>
        {checkRole(currentAccountExtend, {
          roles: ['update-order-unpaid'],
        }) && (
          <>
            <button
              id={'edit' + row.id}
              onClick={() => {
                dispatch(
                  formActions.setValue({
                    name: 'formType',
                    value: 1,
                  })
                );
                dispatch(
                  formActions.setValue({
                    name: 'isModalVisible',
                    value: true,
                  })
                );
                dispatch(
                  formActions.setValue({
                    name: 'modalInfo',
                    value: row,
                  })
                );
              }}
              className="btn p-0 btn-no-shadow mr-0"
            >
              <img src={IconEdit} className="mr-1" />
            </button>
            <UncontrolledTooltip
              delay={1}
              placement="top"
              target={'edit' + row.id}
            >
              Cập nhật đơn hàng
            </UncontrolledTooltip>
          </>
        )}
      </div>
    );
  }

  const onSizePerPageChange = (e) => {
    dispatch(orderActions.setValue({ name: 'page', value: 1 }));
    dispatch(orderActions.setValue({ name: 'limit', value: e.target.value }));
  };

  const onPageChange = (value) => {
    dispatch(orderActions.setValue({ name: 'page', value: value }));
  };

  const pagination = paginationFactory({
    page: page,
    sizePerPage: limit,
    totalSize: total,
    showTotal: false,
    withFirstAndLast: false,
    alwaysShowAllBtns: true,
    onPageChange: onPageChange,
    sizePerPageRenderer: () => {
      return (
        <div className="dataTables_length" id="datatable-basic_length">
          <label>
            Hiển thị{' '}
            {
              <select
                name="datatable-basic_length"
                aria-controls="datatable-basic"
                className="form-control form-control-sm"
                onChange={onSizePerPageChange}
              >
                <option value="10">10</option>
                <option value="25">25</option>
                <option value="50">50</option>
                <option value="100">100</option>
              </select>
            }{' '}
            dòng.
          </label>
        </div>
      );
    },
  });

  useDidUpdateEffect(() => {
    handleGetOrders();
  }, [page, limit, queryUnpaid]);

  useDidUpdateEffect(() => {
    // Sau 500ms thì bắn api
    const delayDebounceFn = setTimeout(() => {
      handleGetOrders();
    }, 500);
    return () => clearTimeout(delayDebounceFn);
  }, [orderSearch]);

  useEffect(() => {
    // if (!_.isEmpty(orders)) return;
    handleGetOrders();
  }, [childrentByCurrentAccountExtend, areasByAccountExtend]);

  const handleGetOrders = () => {
    const query = {
      pageSize: limit,
      page,
      'filters[$and][1][bill][paymentStatus]': CONSTANT.PAYMENT_STATUS[0].value,
      ...queryUnpaid,
      _q: orderSearch,
    };
    if (!isBusinessStaff) {
      areasByAccountExtend.forEach((item, index) => {
        query[`filters[$and][2][$or][${index}][areaName][$eq]`] = item.name;
      });
    }
    query[
      `filters[$and][2][$or][${areasByAccountExtend.length}][createdBy][id][$eq]`
    ] = currentAccountExtend.id;
    if (!isAdmin) {
      if (isSampleCollectorStaff) {
        query[
          `filters[$and][2][$or][${
            areasByAccountExtend.length + 1
          }][codeStaff2][id][$eq]`
        ] = currentAccountExtend?.id;
      } else if (isBusinessStaff) {
        query[
          `filters[$and][2][$or][${
            areasByAccountExtend.length + 1
          }][codeStaff1][id][$eq]`
        ] = currentAccountExtend?.id;
      }
      if (isHasChildrent) {
        if (isSampleCollectorStaff) {
          childrentByCurrentAccountExtend.forEach((item, index) => {
            query[
              `filters[$and][2][$or][${
                areasByAccountExtend.length + 2 + index
              }][codeStaff2][id][$eq]`
            ] = item.id;
          });
        } else if (isBusinessStaff) {
          childrentByCurrentAccountExtend.forEach((item, index) => {
            query[
              `filters[$and][2][$or][${
                areasByAccountExtend.length + 2 + index
              }][codeStaff1][id][$eq]`
            ] = item.id;
          });
        }
      }
    }
    dispatch(
      filterActions.setValueFilter({
        name: 'queryPage',
        value: query,
      })
    );
    dispatch(
      orderActions.getOrders(
        queryString.stringify(query, {
          skipEmptyString: true,
          skipNull: true,
        })
      )
    );
  };
  return (
    <>
      <ExportOrder
        q={() => {
          const q = {
            'filters[$and][1][bill][paymentStatus]':
              CONSTANT.PAYMENT_STATUS[0].value,
            ...queryUnpaid,
            _q: orderSearch,
          };
          if (!isBusinessStaff) {
            areasByAccountExtend.forEach((item, index) => {
              q[`filters[$and][2][$or][${index}][areaName][$eq]`] = item.name;
            });
          }
          q[
            `filters[$and][2][$or][${areasByAccountExtend.length}][createdBy][id][$eq]`
          ] = currentAccountExtend.id;
          if (!isAdmin) {
            if (isSampleCollectorStaff) {
              q[
                `filters[$and][2][$or][${
                  areasByAccountExtend.length + 1
                }][codeStaff2][id][$eq]`
              ] = currentAccountExtend?.id;
            } else if (isBusinessStaff) {
              q[
                `filters[$and][2][$or][${
                  areasByAccountExtend.length + 1
                }][codeStaff1][id][$eq]`
              ] = currentAccountExtend?.id;
            }
            if (isHasChildrent) {
              if (isSampleCollectorStaff) {
                childrentByCurrentAccountExtend.forEach((item, index) => {
                  q[
                    `filters[$and][2][$or][${
                      areasByAccountExtend.length + 2 + index
                    }][codeStaff2][id][$eq]`
                  ] = item.id;
                });
              } else if (isBusinessStaff) {
                childrentByCurrentAccountExtend.forEach((item, index) => {
                  q[
                    `filters[$and][2][$or][${
                      areasByAccountExtend.length + 2 + index
                    }][codeStaff1][id][$eq]`
                  ] = item.id;
                });
              }
            }
          }
          return q;
        }}
      />
      <SimpleHeader />
      <UPayment handleGetOrders={handleGetOrders} />
      <DPayment />
      <UPayments
        data={rowsSelected}
        setData={setRowSelected}
        handleGetOrders={handleGetOrders}
      />
      <Filter />
      <Container className="mt--9" fluid>
        <Row>
          <div className="col">
            <TableCustom
              tableName="Danh sách"
              data={results}
              columns={columns}
              page={page}
              limit={limit}
              total={total}
              searchBy={'Tìm kiếm'}
              pagination={pagination}
              search={orderSearch}
              onChange={(e) => {
                setOrderSearch(e.target.value);
              }}
              selectRow={selectRow}
            />
          </div>
        </Row>
      </Container>
    </>
  );
}

export default memo(OrderUnpaid);
