import React, { memo, useEffect, useState } from 'react';
import _ from 'lodash';
import { Button, Card, CardBody, CardHeader, Col, Row } from 'reactstrap';
import 'assets/css/custom-pages/react-bs-table.css';
import { useDispatch, useSelector } from 'react-redux';
import { useFormikContext } from 'formik';
import queryString from 'query-string';
import { useHistory, useParams } from 'react-router';
import BNInfo from './BNInfo';
import XNInfo from './XNInfo';
import LSInfo from './LSInfo';
import KQInfo from './KQInfo';
import NXNInfo from './NXNInfo';
import LoadingButtonCustom from 'views/pages/components/LoadingButtonCustom';
import { specifyVotesActions } from 'redux/actions';
import SelectCustom from 'views/pages/components/SelectCustom';
import CONSTANT from 'constant';
import { BASE_URL_IMAGE } from 'services/ServiceURL';
import LabelCustom from 'views/pages/components/LabelCustom';
import HelperText from 'views/pages/components/HelperText';
import CFPcd from './CFPcd';

function PCD({
  setAccountValue,
  accountValue,
  specifyVoteValue,
  setSpecifyVoteValue,
}) {
  const { id } = useParams();
  const dispatch = useDispatch();
  const history = useHistory();
  const { childrentByCurrentAccountExtend, isHasChildrent, isAdmin } =
    useSelector((state) => state.accountReducer);
  const {
    handleSubmit,
    setFieldValue,
    values,
    errors,
    touched,
    handleBlur,
    setValues,
  } = useFormikContext();

  const { isCreatingOrder, isUpdatingOrder, order } = useSelector(
    (state) => state.orderReducer
  );

  const [isFPcd, setIsFPcd] = useState(false);

  const [specifyVoteSearch, setSpecifyVoteSearch] = useState('');

  const [specifyVoteResults, setSpecifyVoteResults] = useState([]);

  // get specify votes
  useEffect(() => {
    const delayDebounceFn = setTimeout(() => {
      handleGetSpecifyVotes();
    }, 500);

    return () => clearTimeout(delayDebounceFn);
  }, [specifyVoteSearch, accountValue]);

  const handleGetSpecifyVotes = () => {
    if (!accountValue) return;
    const query = {
      'filters[$and][1][order][code][$null]': true,
      'filters[$and][2][$or][0][idBS][$eq]': accountValue?.value,
      'filters[$and][2][$or][1][createdBy][id][$eq]': accountValue?.value,
      'filters[$and][3][specifyVoteStatus][$ne]': -1,
    };
    if (specifyVoteSearch !== '') {
      query['filters[$and][4][codePCD][$containsi]'] = specifyVoteSearch;
    }
    // if (!isAdmin && isHasChildrent) {
    //   getQueryByChildrentRelationAccountExtendSP(
    //     query,
    //     childrentByCurrentAccountExtend
    //   );
    // }

    dispatch(
      specifyVotesActions.getSpecifyVotes(
        queryString.stringify(query),
        {
          success: (data) => {
            const results = _.get(data, 'results', []);
            setSpecifyVoteResults(results);
          },
          failed: () => {},
        },
        false
      )
    );
  };
  useEffect(() => {
    if (!order?.specifyVote) return setSpecifyVoteValue(null);
    setSpecifyVoteValue({
      ...order.specifyVote,
      value: order.specifyVote?.id,
      label: order.specifyVote?.codePCD ?? '',
    });
  }, [order?.specifyVote]);

  const callback = (data) => {
    setAccountValue({
      value: data.idBS,
      label: `${data?.lastNameBS ?? ''} ${data?.firstNameBS ?? ''}`,
    });
    setFieldValue('specifyVote.codePCD', data?.codePCD ?? '');
    setSpecifyVoteValue({
      ...data,
      label: data?.codePCD ?? '',
      value: data?.id,
    });
    handleSpecifyVoteChange(data);
  };

  const handleSpecifyVoteChange = (e) => {
    setValues(
      {
        ...values,
        specifyVote: {
          ...values.specifyVote,
          codePCD: e?.codePCD ?? '',
          addressBN: e?.addressBN ?? '',
          anamnesis: e?.anamnesis ?? '',
          anamnesisBA: e?.anamnesisBA ?? '',
          biopsyDay: !!e?.biopsyDay ? new Date(e.biopsyDay) : new Date(),
          biopsyPerson: e?.biopsyPerson ?? '',
          cellSolution: e?.cellSolution ?? '',
          codeBN: e?.codeBN ?? '',
          codePKQ: e?.codePKQ ?? '',
          codeTest: e?.codeTest ?? '',
          dateOfBirthBN: e?.dateOfBirthBN ?? '',
          diagnose: e?.diagnose ?? '',
          diseaseRecurrence: e?.diseaseRecurrence ?? '',
          drugResistance: e?.drugResistance ?? '',
          emailBN: e?.emailBN ?? '',
          embryoEvaluation: e?.embryoEvaluation ?? '',
          embryoState: e?.embryoState ?? '',
          familyHistory: e?.familyHistory ?? '',
          firstNameBS: e?.firstNameBS ?? '',
          genderBN: e?.genderBN ?? -1,
          height: e?.height ?? 0,
          height1: e?.height1 ?? 0,
          imageAnalysation: e?.imageAnalysation ?? null,
          jobBN: e?.jobBN ?? '',
          lastNameBS: e?.lastNameBS ?? '',
          lifeStyle: e?.lifeStyle ?? '',
          nameBN: e?.nameBN ?? '',
          nameContact: e?.nameContact ?? '',
          nameTest: e?.nameTest ?? '',
          nappySkin: e?.nappySkin ?? 0,
          negativeControl: e?.negativeControl ?? '',
          noPhoi: e?.noPhoi ?? null,
          numOfEmployment: e?.numOfEmployment ?? 0,
          numOfEmbryos1: e?.numOfEmbryos1 ?? 0,
          numOfEmbryos2: e?.numOfEmbryos2 ?? 0,
          numOfEmbryos3: e?.numOfEmbryos3 ?? 0,
          numOfEmbryos4: e?.numOfEmbryos4 ?? 0,
          pathological1: e?.pathological1 ?? '',
          pathological2: e?.pathological2 ?? '',
          patient: e?.patient?.id ?? null,
          phoneBN: e?.phoneBN ?? '',
          phoneContact: e?.phoneContact ?? '',
          result1: e?.result1 ?? '',
          result2: e?.result2 ?? '',
          result3: e?.result3 ?? '',
          result4: e?.result4 ?? '',
          resultStatus: e?.resultStatus ?? false,
          specifyVoteStatus: e?.specifyVoteStatus ?? 0,
          specimens: e?.specimens ?? [],
          symptom: e?.symptom ?? '',
          test: e?.test ?? '',
          testGR: e?.testGR ?? 0,
          cellNucleus: e?.cellNucleus ?? false,
          time: e?.time ?? 0,
          timeTreatment: e?.timeTreatment ?? 0,
          treatment: e?.treatment ?? '',
          typePregnancy: e?.typePregnancy ?? 0,
          weekPregnancy: e?.weekPregnancy ?? 0,
          weight: e?.weight ?? 0,
          weight1: e?.weight1 ?? 0,
          codeCTKM: e?.codeCTKM ?? '',
          unitPrice: e?.unitPrice ?? 0,
          dayOfPregnancy: e?.dayOfPregnancy ?? 0,
          whereTM: e?.whereTM ?? '',
          id: e?.id ?? -1,
          content: e?.content ?? '',
          timeTest: e?.timeTest,
        },
      },
      true
    );
  };

  async function downloadImage(imageSrc, nameOfDownload = 'pcd.png') {
    const response = await fetch(imageSrc);

    const blobImage = await response.blob();

    const href = URL.createObjectURL(blobImage);

    const anchorElement = document.createElement('a');
    anchorElement.href = href;
    anchorElement.download = nameOfDownload;

    document.body.appendChild(anchorElement);
    anchorElement.click();

    document.body.removeChild(anchorElement);
    window.URL.revokeObjectURL(href);
  }

  const handleZoomPcd = () => {
    const modal = document.getElementById('modal-pcd');
    const img = document.getElementById('pcd');
    const modalImg = document.getElementById('zoom-pcd');
    img.onclick = function () {
      modal.style.display = 'block';
      modalImg.src = this.src;
      modalImg.alt = this.alt;
    };

    // When the user clicks on <span> (x), close the modal
    modal.onclick = function () {
      modalImg.className += ' out';
      setTimeout(function () {
        modal.style.display = 'none';
        modalImg.className = 'modal-content-pcd';
      }, 400);
    };
  };

  const isDisabled =
    order?.status === CONSTANT.ORDER_STATUS[0].value ||
    order?.status === CONSTANT.ORDER_STATUS[3].value ||
    order?.status === CONSTANT.ORDER_STATUS[4].value ||
    order?.status === CONSTANT.ORDER_STATUS[5].value ||
    order?.status === CONSTANT.ORDER_STATUS[6].value ||
    order?.status === CONSTANT.ORDER_STATUS[7].value ||
    order?.status === CONSTANT.ORDER_STATUS[8].value ||
    order?.status === CONSTANT.ORDER_STATUS[9].value ||
    order?.status === CONSTANT.ORDER_STATUS[10].value ||
    order?.status === CONSTANT.ORDER_STATUS[11].value;

  let image = !values.image?.fileUrl
    ? `${BASE_URL_IMAGE}${values.image}`
    : values.image.fileUrl;
  if (values.image === '')
    image = require('assets/img/default/pcd_default.png').default;

  return (
    <>
      <Row className="position-relative">
        <div className="col">
          <Row>
            <Col xs={12} md={5}>
              <Card>
                <CardHeader className="pb-2 d-flex justify-content-between rounded border-bottom align-items-center">
                  <h3>Hình ảnh phiếu xét nghiệm</h3>{' '}
                  {values.image !== '' && (
                    <Button
                      onClick={() => {
                        downloadImage(image, `PCD_${values.code}.png`);
                      }}
                      style={{ fontSize: 10 }}
                      color="info"
                      size="sm"
                    >
                      Tải xuống ảnh
                    </Button>
                  )}
                </CardHeader>
                <CardBody>
                  <img
                    id="pcd"
                    alt="..."
                    className="shadow"
                    onClick={handleZoomPcd}
                    src={image}
                    style={{
                      width: '100%',
                      height: 'auto',
                      borderRadius: 4,
                      cursor: 'pointer',
                    }}
                  />
                  <div id="modal-pcd" class="modal-pcd">
                    <img
                      src={undefined}
                      alt="zoom-pcd"
                      class="modal-content-pcd"
                      id="zoom-pcd"
                    />
                  </div>
                </CardBody>
              </Card>
            </Col>
            {/* Thông tin phiếu xét nghiệm */}
            <CFPcd
              isFPcd={isFPcd}
              setIsFPcd={setIsFPcd}
              callback={callback}
              aV={accountValue}
            />
            <Col xs={12} md={7}>
              <Card>
                <CardHeader className="pb-2 d-flex justify-content-between rounded border-bottom">
                  <h3>Thông tin phiếu xét nghiệm</h3>
                </CardHeader>
                <CardBody>
                  <Row>
                    <Col xs={12} md={12} lg={10}>
                      <SelectCustom
                        name="specifyVote"
                        label="Mã phiếu xét nghiệm"
                        placeholder="Lựa chọn"
                        value={specifyVoteValue}
                        isRequired={true}
                        isClearable={true}
                        handleChange={(e) => {
                          setFieldValue('specifyVote.codePCD', e?.label ?? '');
                          setSpecifyVoteValue(e);
                          handleSpecifyVoteChange(e);
                        }}
                        isDisabled={id !== '-1' || !accountValue?.value}
                        onInputChange={(value) => {
                          setSpecifyVoteSearch(value);
                          // if (action === 'input-change') {
                          //   setSpecifyVoteValue({
                          //     label: value ?? '',
                          //     value: -1,
                          //   });
                          //   setFieldValue('specifyVote', -1);
                          //   setFieldValue('codePCD', value);
                          // }
                        }}
                        handleBlur={handleBlur}
                        error={errors?.specifyVote?.id}
                        touched={touched?.specifyVote?.id}
                        options={specifyVoteResults.map((item) => ({
                          value: item?.id,
                          label: item?.codePCD ?? '',
                          ...item,
                        }))}
                        row
                        labelWidth={160}
                      />
                    </Col>
                    <Col
                      className="d-flex align-items-center"
                      xs={12}
                      md={12}
                      lg={2}
                    >
                      {id === '-1' && (
                        <Button
                          onClick={() => {
                            setIsFPcd(true);
                          }}
                          style={{ fontSize: 10 }}
                          color="info"
                          size="sm"
                        >
                          Tạo nhanh phiếu xét nghiệm
                        </Button>
                      )}
                    </Col>
                  </Row>
                  {(id === '-1' ||
                    order.status === CONSTANT.ORDER_STATUS[7].value) && (
                    <Row>
                      <Col xs={12} md={12} className="d-flex">
                        <LabelCustom
                          isRequired={true}
                          label={'Ảnh phiếu xét nghiệm và mẫu'}
                          styleLabel={{ minWidth: 160 }}
                          style={{
                            margin: '0.5em 8px 0px 0px',
                          }}
                        />
                        <div className="ml-2">
                          <input
                            style={{ fontSize: 12, marginLeft: 8 }}
                            type="file"
                            accept="image/*"
                            onChange={(e) => {
                              try {
                                if (_.isEmpty(e)) return;

                                setFieldValue('image', {
                                  fileUrl: URL.createObjectURL(
                                    e.target.files[0]
                                  ),
                                  file: e.target.files[0],
                                });
                                setFieldValue(
                                  'imageUrl',
                                  URL.createObjectURL(e.target.files[0])
                                );
                              } catch (error) {
                                //
                              }
                            }}
                          />
                          {errors.imageUrl && touched.imageUrl && (
                            <div style={{ paddingLeft: 8 }}>
                              <HelperText message={errors.imageUrl} />
                            </div>
                          )}
                        </div>
                      </Col>
                    </Row>
                  )}
                  <BNInfo
                    specifyVoteValue={specifyVoteValue}
                    isDisabled={isDisabled}
                  />
                  <XNInfo
                    specifyVoteValue={specifyVoteValue}
                    isDisabled={isDisabled}
                  />
                  <LSInfo
                    isDisabled={isDisabled}
                    specifyVoteValue={specifyVoteValue}
                  />
                  <KQInfo isDisabled={isDisabled} />
                  <NXNInfo
                    specifyVoteValue={specifyVoteValue}
                    isDisabled={isDisabled}
                  />
                  <div
                    style={{ bottom: 10, left: 0 }}
                    className="position-fixed bg-white w-100 shadow-lg rounded"
                  >
                    <div className="m-2 d-flex justify-content-end">
                      <Button
                        className="text-nowrap mr-1"
                        onClick={() => {
                          history.goBack();
                        }}
                      >
                        Hủy
                      </Button>
                      <LoadingButtonCustom
                        onClick={handleSubmit}
                        color="info"
                        className="text-nowrap "
                        type="submit"
                        loading={isCreatingOrder || isUpdatingOrder}
                      >
                        Lưu và thoát
                      </LoadingButtonCustom>
                    </div>
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </div>
      </Row>
    </>
  );
}

export default memo(PCD);
